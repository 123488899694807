import { FC, useCallback } from 'react'
import { Flex, Typography, Checkbox, ColorPicker } from '@fto/ui'

import commonStyles from '../../common.module.scss'
import { useTranslation } from 'react-i18next'

type Value = {
    isActive?: boolean
    color: string
    opacity?: number
}

type Props = {
    isOptional?: boolean
    value: Value
    label: string
    onChange: ({ color, isActive }: Value) => void
    withOpacity?: boolean
}

const FillColor: FC<Props> = ({ isOptional = false, value, label, onChange, withOpacity = false }) => {
    const { t } = useTranslation()

    const updateColor = useCallback(
        (colorValue: string) => {
            onChange({ ...value, color: colorValue })
        },
        [onChange]
    )

    const onToggle = useCallback(
        (checked: boolean) => {
            onChange({ ...value, isActive: checked })
        },
        [onChange]
    )

    const onOpacityChange = useCallback(
        (opacityValue: number) => {
            onChange({ ...value, opacity: opacityValue })
        },
        [onChange]
    )

    return (
        <Flex alignItems='center' gap={16}>
            <Flex alignItems='center' gap={8} className={commonStyles.LeftPart}>
                {isOptional && <Checkbox checked={value.isActive} onChange={onToggle} />}
                <Typography type='interface-regular' color='gray-1000'>
                    {t(label)}
                </Typography>
            </Flex>
            <ColorPicker
                opacity={value.opacity}
                onOpacityChange={onOpacityChange}
                color={value.color}
                onColorChange={updateColor}
                withOpacity={withOpacity}
            />
        </Flex>
    )
}

export default FillColor
