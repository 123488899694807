import { useCallback, useState } from 'react'

import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { Flex, Modal, Typography, Checkbox, removeModal, InputField } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { Icon } from '@fto/icons'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import { showErrorToast } from '@root/utils/toasts'


const RestartProjectModal = () => {
    const { t } = useTranslation()

    const [shouldChangeInitialBalance, setShouldChangeInitialBalance] = useState(false)
    const [newInitialBalance, setNewInitialBalance] = useState(10000)

    const restart = useCallback(async () => {
        try {
            if(shouldChangeInitialBalance) {
                await GlobalProjectJSONAdapter.Instance.changeInitialBalance(newInitialBalance)
            }

            const charts = GlobalChartsController.Instance.getAllCharts()
            if (!charts) return
            GlobalChartsController.Instance.RestartProject()
            charts.forEach((chart) => {
                chart.Repaint()
            })
            removeModal(MODAL_NAMES.chart.restartModal)
        } catch(e: any) {
            showErrorToast({title: t('restartModal.title'), message: e.message})
        }
    }, [shouldChangeInitialBalance, newInitialBalance])

    return (
        <Modal 
            size='xs' 
            name={MODAL_NAMES.chart.restartModal}
            withClickOutside={false}
            additionalClassNames={{
                container: styles.container
            }}
        >
            <Modal.Header>
                <Flex direction='column' alignItems='center' gap={16}>
                    <Icon name='restart-colored' size={56} />
                    <Typography align='center'>{t('restartModal.title')}</Typography>
                </Flex>
            </Modal.Header>
            <Modal.Content className={styles.content}>
                <Flex direction='column' alignItems='center' gap={16}>
                    <Typography align='center'>{t('restartModal.disclaimer')}</Typography>
                    <Flex alignItems='center' justifyContent='center' block>
                        <Checkbox
                            className={styles.checkbox}
                            label={
                                <Typography
                                    className={styles.checkboxLabel}
                                    type='text-regular'
                                >
                                    {t('restartModal.changeInitialBalanceCheckbox')}
                                </Typography>
                            }
                            block
                            inputControlVariant={'row-reverse'}
                            checked={shouldChangeInitialBalance}
                            onChange={setShouldChangeInitialBalance}
                        ></Checkbox>
                    </Flex>

                    {shouldChangeInitialBalance && (
                        <Flex alignItems='center' justifyContent='center' block>
                            <InputField
                                className={styles.input}
                                label={t('restartModal.changeInitialBalanceInput')}
                                step={1}
                                value={newInitialBalance}
                                onChange={(value) => setNewInitialBalance(Number(value))}
                                type={'number'}
                                max={999999999}
                                min={10}
                                block
                            />
                        </Flex>
                    )}
                </Flex>
            </Modal.Content>
            <Modal.Controls onSubmit={restart} submitText={t('restartModal.restart')} />
        </Modal>
    )
}

export default RestartProjectModal
