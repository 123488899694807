import { FC, useCallback } from 'react'
import styles from './index.module.scss'
import { Flex, Typography } from '@fto/ui'
import { useTranslation } from 'react-i18next'
import useChangesObserver from '../../hooks/useChangesObserver'
import { AvailableOptionsSettings } from '../../components/types/options.types'
import getOptionSettingByConfig from '../../components/utils/getOptionSettingByConfig'
import globalChartsStore from '@fto/lib/store/globalChartsStore'
import OptionsConfig from './config'

interface Props {}

const CommissionsSettingsTab: FC<Props> = () => {
    const { t } = useTranslation()

    const { data } = globalChartsStore
    const chart = data.activeChart
    if (!chart) return <></>

    const { onDifferentSettingsUpdate } = useChangesObserver(chart, {
        mixPanelEventName: 'commissions_settings_updated',
        optionsConfig: OptionsConfig
    })

    const optionSettingByKey = useCallback(
        (option: AvailableOptionsSettings) => {
            return getOptionSettingByConfig({
                tab: 'commissions',
                chart,
                option: option,
                applyToAll: false,
                onDifferentSettingsUpdate
            })
        },
        [chart, onDifferentSettingsUpdate]
    )

    return (
        <Flex className={styles.scrollableContainer}>
            <Flex direction='column' gap={16} className={styles.content} block>
                <Typography type='tiny-regular' color='gray-600' className={styles.blockTitle}>
                    {t('settings.commissions.titles.commissionsTitle')}
                </Typography>

                {optionSettingByKey(OptionsConfig['CommPerLot'])}
                {optionSettingByKey(OptionsConfig['CommissionApplyType'])}
            </Flex>
        </Flex>
    )
}

export default CommissionsSettingsTab
