import { Flex, Typography } from '@fto/ui'
import styles from './index.module.scss'
import { useEffect, useState } from 'react'

type WarningBlockMessageProps<T> = {
    text?: string
    observableValue: T
    resetObservableItem?: any
}

function WarningBlockMessage<T>({ text, observableValue, resetObservableItem }: WarningBlockMessageProps<T>) {
    if (!text) return null

    const [initValue, setInitValue] = useState(observableValue)
    const [shouldShowWarning, setShouldShowWarning] = useState(false)

    useEffect(() => {
        setShouldShowWarning(observableValue !== initValue)
    }, [observableValue])

    useEffect(() => {
        setInitValue(observableValue)
        setShouldShowWarning(false)
    }, [resetObservableItem])

    if (!shouldShowWarning) return null

    return (
        <Flex className={styles.root}>
            <Typography type='subtext-regular' color='orange-500'>
                {text}
            </Typography>
        </Flex>
    )
}

export default WarningBlockMessage
