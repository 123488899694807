type TranslateType = (path: string) => string
export const TREND_INDICATORS_LIST = (t: TranslateType) => [
    { label: t('indicators.simpleMA'), value: 'SMA' },
    { label: t('indicators.exponentialMA'), value: 'EMA' },
    { label: t('indicators.smoothedMA'), value: 'SSMA' },
    { label: t('indicators.weightedMA'), value: 'WMA' },
    { label: t('indicators.bollingerBands'), value: 'Bollinger Bands' },
    { label: t('indicators.ichimoku'), value: 'Ichimoku' },
    { label: t('indicators.insideBar'), value: 'Inside Bar' },
    { label: t('indicators.alligator'), value: 'Alligator (B. Williams)' }
    // 'Pivot Points',
    // 'ZigZag'
]

export const VOLUME_INDICATORS_LIST = (t: TranslateType) => [
    { label: t('indicators.volume'), value: 'Volume' },
    { label: t('indicators.obv'), value: 'OBV' }
]

export const OSCILLATORS_INDICATORS_LIST = (t: TranslateType) => [
    { label: t('indicators.macd'), value: 'MACD' },
    { label: t('indicators.stochastic'), value: 'Stochastic' },
    { label: t('indicators.adx'), value: 'ADX' },
    { label: t('indicators.atr'), value: 'ATR' },
    { label: t('indicators.deMarker'), value: 'DeMarker' },
    { label: t('indicators.cci'), value: 'CCI' },
    { label: t('indicators.momentum'), value: 'Momentum' },
    { label: t('indicators.rsi'), value: 'RSI' },
    { label: t('indicators.williamsPercentRange'), value: 'Williams Percent Range' },
    { label: t('indicators.mfi'), value: 'MFI' },
    { label: t('indicators.ac'), value: 'Accelerator Oscillator (B. Williams)' },
    { label: t('indicators.awersomeOscillator'), value: 'Awesome Oscillator (B. Williams)' },
    { label: t('indicators.bollingerBandsRatio'), value: 'Bollinger Bands Ratio' },
    { label: t('indicators.aroon'), value: 'Aroon' }
]

export const ADAPTIVE_INDICATORS_LIST = (t: TranslateType) => [
    { label: t('indicators.tradingSessions'), value: 'Trading Sessions' },
    { label: t('indicators.fairValueGap'), value: 'Fair Value Gap' }
    // { label: t('indicators.previousLevels'), value: 'Previous levels' }
]

export const VOLATILITY_INDICATORS_LIST = [
    // 'Parabolic SAR'
]

export const ALL_INDICATORS_LIST = (t: TranslateType) => [
    ...TREND_INDICATORS_LIST(t),
    ...VOLUME_INDICATORS_LIST(t),
    ...OSCILLATORS_INDICATORS_LIST(t),
    ...ADAPTIVE_INDICATORS_LIST(t)
]
