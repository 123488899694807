import { Button, Flex, TriggerOverlay } from '@fto/ui'
import styles from './index.module.scss'
import { FC, useCallback } from 'react'
import SymbolPair from '@root/components/SymbolPair'
import { Icon } from '@fto/icons'
import cx from 'classnames'

type Props = {
    activeSymbol: string
    symbols: string[]
    setActiveSymbol: (symbol: string) => void
    updateSymbols: () => void
}

const SymbolDropdownContainer: FC<Props> = ({ activeSymbol, symbols, setActiveSymbol, updateSymbols }) => {
    const dropdownRenderer = useCallback(
        (closeOverlay: () => void) => {
            return (
                <Flex direction='column' className={styles.dropdownContainer}>
                    {symbols.map((symbol) => (
                        <Button
                            key={symbol}
                            label={symbol}
                            type='tetriatry-white'
                            classNames={{
                                content: cx(styles.dropdownItem, {
                                    [styles.activeItem]: activeSymbol === symbol
                                }),
                                label: styles.dropdownItemLabel
                            }}
                            block
                            before={<SymbolPair symbolSize={15} symbol={symbol} />}
                            onClick={() => {
                                setActiveSymbol(symbol)
                                closeOverlay()
                            }}
                        ></Button>
                    ))}
                </Flex>
            )
        },
        [activeSymbol, symbols]
    )

    return (
        <Flex direction='column'>
            <TriggerOverlay
                disableBackdropChartActivity={true}
                optionsRenderer={dropdownRenderer}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Button
                    type='tetriatry-gray'
                    label={activeSymbol}
                    before={<SymbolPair symbolSize={15} symbol={activeSymbol} />}
                    after={<Icon name='chevron-down' size={14} color='var(--color-gray-900)' />}
                    onClick={updateSymbols}
                    block
                />
            </TriggerOverlay>
        </Flex>
    )
}

export default SymbolDropdownContainer
