import { TDateTime } from '../delphi_compatibility/DateUtils'

export enum TCursorMode {
    cm_Default,
    cm_CrossHair,
    cm_CrossHairRooler
}

export interface TChartScale {
    BarWidth2: number // Half of the bar width
    PixBetweenBars: number // distance between centers of 2 bars
}

export class TCrossHair {
    public DateTime!: TDateTime
    public Price!: number
    public roolerDateTime!: TDateTime
    public roolerPrice!: number
    public RoundRoolerV!: boolean
}

export class TPosMarker {
    public DateTime!: TDateTime
    public price!: number

    constructor() {
        this.DateTime = -1
        this.price = -1
    }
}
