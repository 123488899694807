import { FC, useCallback, useState, useMemo, Dispatch, SetStateAction } from 'react'
import { t } from 'i18next'

import { Icon, IconButton } from '@fto/icons'
import { Flex, Typography, InputField, Button, InputRadio, EmptyState } from '@fto/ui'

import Row from './components/Row'

import { ALL_COUNTRIES, EURO_ZONE_COUNTRIES } from '@root/constants/countries'

import styles from './index.module.scss'
import { ParamsType } from '@fto/lib/store/news/types'

type Props = {
    selectedCountries: ParamsType['filters']['selectedCountries']
    setFilters: Dispatch<SetStateAction<ParamsType['filters']>>
}

const Table: FC<Props> = ({ selectedCountries, setFilters }) => {
    const [searchValue, setSearchValue] = useState('')

    const euroZoneCountries = useMemo(() => EURO_ZONE_COUNTRIES.map((country) => country.name), [])
    const allCountries = useMemo(() => ALL_COUNTRIES.map((country) => country.name), [])

    const toggleAllCountries = useCallback(() => {
        setFilters((prevState) => {
            return {
                ...prevState,
                selectedCountries: allCountries.every((item) => prevState.selectedCountries.includes(item))
                    ? []
                    : allCountries
            }
        })
    }, [allCountries])

    const areAllCountriesSelected = useMemo(
        () => allCountries.every((item) => selectedCountries.includes(item)),
        [selectedCountries, allCountries]
    )

    const toggleEuroZone = useCallback(() => {
        setFilters((prevState) => {
            return {
                ...prevState,
                selectedCountries:
                    !areAllCountriesSelected &&
                    euroZoneCountries.every((item) => prevState.selectedCountries.includes(item)) &&
                    selectedCountries.length === euroZoneCountries.length
                        ? []
                        : euroZoneCountries
            }
        })
    }, [euroZoneCountries, areAllCountriesSelected, selectedCountries])

    const isEuroZoneSelected = useMemo(
        () =>
            !areAllCountriesSelected &&
            euroZoneCountries.every((item) => selectedCountries.includes(item)) &&
            selectedCountries.length === euroZoneCountries.length,
        [selectedCountries, euroZoneCountries, areAllCountriesSelected]
    )

    const handleSearch = useCallback((value: string) => setSearchValue(value), [])

    const countriesList = useMemo(() => {
        const filteredCountries = ALL_COUNTRIES.filter((country) =>
            country.name.toLowerCase().includes(searchValue.toLowerCase())
        )

        return [...filteredCountries].sort((a, b) => {
            if (a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }
            return 0
        })
    }, [searchValue, ALL_COUNTRIES])

    const clearALl = useCallback(() => {
        setFilters((prev) => ({ ...prev, selectedCountries: [] }))
    }, [setFilters])

    const toggleCountry = useCallback(
        (countryName: string) => {
            setFilters((prevState) => ({
                ...prevState,
                selectedCountries: prevState.selectedCountries.includes(countryName)
                    ? prevState.selectedCountries.filter((item) => item !== countryName)
                    : [...prevState.selectedCountries, countryName]
            }))
        },
        [setFilters]
    )

    return (
        <Flex direction='column' gap={8} className={styles.Table}>
            <Flex direction='column' gap={8}>
                <Flex alignItems='center' justifyContent='space-between' gap={8} className={styles.Wrapper}>
                    <Typography type='interface-medium' color='gray-1000'>
                        {t('news.header.filter.selectCountries')}
                    </Typography>
                    {selectedCountries.length > 0 && (
                        <Button
                            onClick={clearALl}
                            classNames={{ content: styles.ClearButtonRoot }}
                            size='tiny'
                            type='tetriatry-white'
                            label={
                                <Typography color='primary-500'>
                                    {t('news.header.filter.clearButton')} ({selectedCountries.length})
                                </Typography>
                            }
                        />
                    )}
                </Flex>
                <Flex alignItems='center' gap={8} className={styles.MultiSelect} onClick={toggleAllCountries}>
                    <InputRadio checked={areAllCountriesSelected} />
                    <Icon name='country-entire-world' size={18} />
                    <Typography type='interface-medium' color='gray-1000'>
                        {t('news.header.filter.entireWorld')}
                    </Typography>
                </Flex>
                <Flex alignItems='center' gap={8} className={styles.MultiSelect} onClick={toggleEuroZone}>
                    <InputRadio checked={isEuroZoneSelected} />
                    <Icon name='country-europe' size={18} />
                    <Typography type='interface-medium' color='gray-1000'>
                        {t('news.header.filter.euroZone')}
                    </Typography>
                </Flex>
            </Flex>

            <InputField
                value={searchValue}
                onChange={handleSearch}
                block
                prefix={<Icon name='search' size={13} />}
                suffix={searchValue ? <IconButton name='close' size={10} onClick={() => setSearchValue('')} /> : null}
                placeholder={t('news.header.filter.euroZone')}
            />

            <Flex direction='column' gap={4} className={styles.CountriesList}>
                {countriesList.length === 0 ? (
                    <EmptyState description={t('news.header.filter.noCountriesFound')} />
                ) : (
                    countriesList.map((country) => (
                        <Row
                            countryName={country.name}
                            toggleCountry={toggleCountry}
                            isSelected={selectedCountries.includes(country.name)}
                            key={country.name}
                            iconName={country.iconName}
                            localeKey={country.localeKey}
                            searchValue={searchValue}
                        />
                    ))
                )}
            </Flex>
        </Flex>
    )
}

export default Table
