import React, { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'

import cx from 'classnames'
import dayjs from 'dayjs'

import { Flex, InputRadio, Tooltip, TriggerOverlay, Typography } from '@fto/ui'

import { Icon } from '@fto/icons'

import AppCalendar from '@root/components/Common/AppCalendar/AppCalendar'
import FilterBlock from '@root/pages/ChartPage/components/Terminal/components/Toolbar/components/FilterBlock'
import FilterDropdown from '@root/pages/ChartPage/components/Terminal/components/Toolbar/components/FilterDropdown'
import { FILTER_BY_DATE_OPTIONS } from '@root/pages/ChartPage/components/Terminal/components/Toolbar/constants/filtersOptions'
import {
    DateFilterParamsType,
    DateIncludesType,
    FilterOptionType
} from '@root/pages/ChartPage/components/Terminal/types'
import { getAppliedFiltersLabel } from '@root/pages/ChartPage/components/Terminal/components/Toolbar/components/HistoryRange/utils'

import RangesRenderer from './components/RangesRenderer'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
    dateFilterParams: DateFilterParamsType
    setDateFitterParams: Dispatch<SetStateAction<DateFilterParamsType>>
    filterByDateParamsIncludes: DateIncludesType
    setFilerByDateParamsIncludes: (value: DateIncludesType) => void
    isFiltersVisible: boolean
    setIsFiltersVisible: Dispatch<SetStateAction<boolean>>
}

const HistoryRange: FC<Props> = ({
    dateFilterParams,
    setDateFitterParams,
    filterByDateParamsIncludes,
    setFilerByDateParamsIncludes,
    isFiltersVisible,
    setIsFiltersVisible
}) => {
    const { t } = useTranslation()

    const { rangeType, selectedHole, rangeData } = dateFilterParams

    const options = useMemo(() => {
        return [
            { label: t('terminal.toolbar.periodFilter.options.all'), value: 'all' },
            { label: t('terminal.toolbar.periodFilter.options.today'), value: 'today' },
            { label: t('terminal.toolbar.periodFilter.options.yesterday'), value: 'yesterday' },
            { label: t('terminal.toolbar.periodFilter.options.lastWeek'), value: 'lastWeek' },
            { label: t('terminal.toolbar.periodFilter.options.lastMonth'), value: 'lastMonth' },
            { label: t('terminal.toolbar.periodFilter.options.lastYear'), value: 'lastYear' }
        ]
    }, [])

    const renderer = useCallback(
        (onClose: () => void) => {
            return (
                <RangesRenderer
                    options={options as FilterOptionType}
                    onClose={onClose}
                    selectedPeriod={selectedHole}
                    setSelectedPeriod={(value) =>
                        setDateFitterParams((prev) => ({
                            ...prev,
                            selectedHole: value
                        }))
                    }
                />
            )
        },
        [options, selectedHole, setDateFitterParams]
    )

    const selectedFilterByHoleLabel = useMemo(() => {
        return options.find((option) => option.value === selectedHole)?.label
    }, [options, selectedHole])

    const handleDataChange = (date: Date, key: 'from' | 'to') => {
        setDateFitterParams((prevState) => ({
            ...prevState,
            rangeData: {
                ...prevState.rangeData,
                [key]: date
            }
        }))
    }

    const handleHoleSelection = useCallback(() => {
        setDateFitterParams((prev) => ({ ...prev, rangeType: 'hole' }))
    }, [setDateFitterParams])

    const handleRangeSelection = useCallback(() => {
        setDateFitterParams((prev) => ({ ...prev, rangeType: 'range' }))
    }, [setDateFitterParams])

    const isHoleSelection = useMemo(() => rangeType === 'hole', [rangeType])

    const symbolSelectionRenderer = useCallback(
        (onClose: () => void) => {
            const onChange = (value: DateIncludesType) => {
                onClose()
                setFilerByDateParamsIncludes(value)
            }

            return (
                <FilterDropdown
                    options={FILTER_BY_DATE_OPTIONS}
                    onChange={onChange}
                    currentValue={filterByDateParamsIncludes}
                />
            )
        },
        [setFilerByDateParamsIncludes, filterByDateParamsIncludes]
    )

    const toggleFiltersVisibility = useCallback(() => {
        setIsFiltersVisible((prev) => !prev)
    }, [setIsFiltersVisible])

    if (!isFiltersVisible) {
        return (
            <Flex alignItems='center' gap={4} flexShrink={0}>
                <Tooltip content={t('terminal.tooltips.showFilters')}>
                    <Typography
                        type='interface-regular'
                        className={styles.TriggerFiltersVisibility}
                        color='primary-500'
                        onClick={toggleFiltersVisibility}
                    >
                        {t('terminal.toolbar.showFiltersTitle')}
                    </Typography>
                </Tooltip>
                <Typography type='subtext-regular' color='gray-600'>
                    {t(getAppliedFiltersLabel(dateFilterParams, filterByDateParamsIncludes))}
                </Typography>
            </Flex>
        )
    }

    return (
        <Flex gap={8} alignItems='center' flexShrink={0}>
            <Flex alignItems='center' gap={4}>
                <Typography type='interface-regular' color='gray-1000'>
                    {t('terminal.toolbar.dateFilter.title')}
                </Typography>
                <TriggerOverlay
                    optionsRenderer={symbolSelectionRenderer}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <FilterBlock title={t(`terminal.toolbar.dateFilter.${filterByDateParamsIncludes}`)} />
                </TriggerOverlay>
            </Flex>
            <Flex gap={8} alignItems='center'>
                <InputRadio checked={rangeType === 'hole'} onChange={handleHoleSelection} />
                <div className={cx({ [styles.disabled]: !isHoleSelection })}>
                    <TriggerOverlay
                        optionsRenderer={(onclose) => renderer(onclose)}
                        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Flex
                            gap={8}
                            alignItems='center'
                            justifyContent='space-between'
                            className={styles.selectedHoleKeeper}
                        >
                            <Flex alignItems='center' flexShrink={0} gap={8}>
                                <Icon
                                    name='new-order'
                                    size={14}
                                    color={!isHoleSelection ? 'var(--color-gray-500)' : 'var(--color-gray-1000)'}
                                />

                                <Typography type='interface-medium' color={!isHoleSelection ? 'gray-500' : 'gray-1000'}>
                                    {selectedFilterByHoleLabel}
                                </Typography>
                            </Flex>
                            <Icon
                                name='chevron-down'
                                size={12}
                                color={!isHoleSelection ? 'var(--color-gray-500)' : 'var(--color-gray-1000)'}
                            />
                        </Flex>
                    </TriggerOverlay>
                </div>
            </Flex>
            <Flex gap={8} alignItems='center'>
                <Flex gap={8} alignItems='center'>
                    <InputRadio checked={rangeType === 'range'} onChange={handleRangeSelection} />
                    <div className={cx({ [styles.disabled]: isHoleSelection })}>
                        <TriggerOverlay
                            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            optionsRenderer={
                                <AppCalendar
                                    className={styles.CalendarWrapper}
                                    key='calendar'
                                    initialDate={rangeData.from}
                                    setCalendarValue={(value) => handleDataChange(value as Date, 'from')}
                                    maxDate={rangeData.to}
                                />
                            }
                        >
                            <Flex
                                gap={8}
                                alignItems='center'
                                justifyContent='space-between'
                                className={styles.selectedHoleKeeper}
                            >
                                <Icon
                                    name='date'
                                    size={16}
                                    color={isHoleSelection ? 'var(--color-gray-500)' : 'var(--color-gray-1000)'}
                                />

                                <Typography type='interface-medium' color={isHoleSelection ? 'gray-500' : 'gray-1000'}>
                                    {dayjs(rangeData.from).format('MMM D, YYYY')}
                                </Typography>
                            </Flex>
                        </TriggerOverlay>
                    </div>
                </Flex>

                <Typography type='subtext-light' color='gray-1000'>
                    —
                </Typography>

                <Flex gap={8} alignItems='center'>
                    <div className={cx({ [styles.disabled]: isHoleSelection })}>
                        <TriggerOverlay
                            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            optionsRenderer={
                                <AppCalendar
                                    className={styles.CalendarWrapper}
                                    key='calendar'
                                    initialDate={rangeData.to}
                                    setCalendarValue={(value) => handleDataChange(value as Date, 'to')}
                                    minDate={rangeData.from}
                                    //TODO: adjust min\max to project range (start and end of project)
                                />
                            }
                        >
                            <Flex
                                gap={8}
                                alignItems='center'
                                justifyContent='space-between'
                                className={styles.selectedHoleKeeper}
                            >
                                <Icon
                                    name='date'
                                    size={16}
                                    color={isHoleSelection ? 'var(--color-gray-500)' : 'var(--color-gray-1000)'}
                                />

                                <Typography type='interface-medium' color={isHoleSelection ? 'gray-500' : 'gray-1000'}>
                                    {dayjs(rangeData.to).format('MMM D, YYYY')}
                                </Typography>
                            </Flex>
                        </TriggerOverlay>
                    </div>
                </Flex>

                <Typography
                    type='interface-regular'
                    className={styles.TriggerFiltersVisibility}
                    color='primary-500'
                    onClick={toggleFiltersVisibility}
                >
                    {t('terminal.toolbar.hideFiltersTitle')}
                </Typography>
            </Flex>
        </Flex>
    )
}

export default HistoryRange
