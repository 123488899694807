import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Separator, Toggle, Typography, useTheme } from '@fto/ui'
import { THEMES } from '@fto/ui/lib/theme/ThemeProvider'

import { Icon } from '@fto/icons'
import { useAppDispatch } from '@root/hooks/useStoreHook'
import { CHANGE_THEME } from '@root/store/appSettings/slice'

import styles from './index.module.scss'
import { useChartsThemeUpdate } from './hooks/useChartsThemeUpdate'

type Props = {
    onQuestionAsking?: () => void
}

const DarkModeToggle: FC<Props> = ({ onQuestionAsking }) => {
    const { t } = useTranslation()
    const { toggle: toggleTheme, isDark } = useTheme()
    const dispatch = useAppDispatch()
    const { askAboutChartsTheme } = useChartsThemeUpdate()

    const changeTheme = useCallback(
        (forceUpdateChartsTheme: boolean) => {
            const theme = isDark ? THEMES.LIGHT : THEMES.DARK
            toggleTheme()
            dispatch(CHANGE_THEME({ theme, forceUpdateChartsTheme }))
        },
        [isDark, toggleTheme]
    )

    const handleToggle = useCallback(() => {
        onQuestionAsking && onQuestionAsking()
        askAboutChartsTheme(!isDark, (forceUpdateChartsTheme: boolean) => {
            changeTheme(forceUpdateChartsTheme)
        })
    }, [isDark, onQuestionAsking, askAboutChartsTheme, changeTheme])

    return (
        <Flex direction='column' gap={4}>
            <Flex alignItems='center' justifyContent='space-between' className={styles.DarkMode}>
                <Flex alignItems='center' gap={12}>
                    <Icon name='moon' size={18} />
                    <Typography type='interface-regular' color='gray-1000'>
                        {t('general.darkMode')}
                    </Typography>
                </Flex>
                <Toggle checked={isDark} onChange={handleToggle} />
            </Flex>
            <Separator width='100%' />
        </Flex>
    )
}

export default DarkModeToggle
