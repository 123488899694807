import { EDebugLoggingLevel, ELoggingTopics } from './DebugEnums'

/* eslint-disable no-console */
const DEFAULT_DEBUG_MODE_SETTING = false
export class DebugUtils {
    private static _instance: DebugUtils

    private _debugMode

    private _loggingCounter = 0
    private _topicsToLog: ELoggingTopics[] = []
    private _topicsToIgnore: ELoggingTopics[] = []
    private _logToString = true
    private _logString = ''

    public LoggingLevel = EDebugLoggingLevel.All
    //sometimes we want to skip a bunch of errors and stop at specific ones in debug
    public EnableStrangeErrorBreakpoints = false
    public EnableStrangeErrorToasts = false
    //throwing errors in events is disabled by default because if an error happens in one of the event listeners, we don't want to stop the execution of the other listeners
    //however, if you want to debug the error, you can enable this flag
    public AllowEventsToThrowErrors = false
    public IgnoreTimeoutInTestingManager = false

    private ignoredErrorMessages: string[] = []
    public UseBoundClassDiagnostic = false

    public static get DebugMode(): boolean {
        return this.Instance._debugMode
    }

    public static set DebugMode(value: boolean) {
        this._instance = new DebugUtils(value)
    }

    public set TopicsToLog(value: ELoggingTopics[]) {
        this._topicsToLog = value
    }

    private setDebugModeSettings() {
        this.IgnoreTimeoutInTestingManager = true
        this.LoggingLevel = EDebugLoggingLevel.All
        this.ignoredErrorMessages = ['Observer already attached']
        this._topicsToLog = [
            // ELoggingTopics.lt_All,
            // ELoggingTopics.lt_Loading,
            // ELoggingTopics.lt_ChunkLoading,
            // ELoggingTopics.lt_Painting
            // ELoggingTopics.lt_Seek,
            // // ELoggingTopics.lt_ChunkLoadingCompleted,
            // ELoggingTopics.lt_Maps,
            //ELoggingTopics.lt_TestingSpeed,
            // ELoggingTopics.lt_TestingControl,
            //ELoggingTopics.lt_ScrollAndZoom,
            // ELoggingTopics.lt_Temp
            //ELoggingTopics.lt_BarBuilding,
            //ELoggingTopics.lt_MouseEvents,
            ELoggingTopics.lt_Significant
        ]

        // this._topicsToIgnore = [ELoggingTopics.lt_Painting]
        //[ELoggingTopics.lt_Seek, ELoggingTopics.lt_ChunkLoading, ELoggingTopics.lt_Events]
        this._logToString = true
        this.AllowEventsToThrowErrors = true
        this.EnableStrangeErrorBreakpoints = true
        this.EnableStrangeErrorToasts = true
        this.UseBoundClassDiagnostic = true
    }

    private constructor(debugMode = DEFAULT_DEBUG_MODE_SETTING) {
        this._debugMode = debugMode
        if (debugMode || this._debugMode) {
            this.setDebugModeSettings()
        } else {
            this._topicsToLog = [
                // ELoggingTopics.lt_Events,
                // ELoggingTopics.lt_Seek
            ]
        }
    }

    public static get Instance(): DebugUtils {
        if (!DebugUtils._instance) {
            DebugUtils._instance = new DebugUtils()
        }
        return DebugUtils._instance
    }

    private logToString(...args: any[]): void {
        if (this._logToString) {
            this._logString += `${this.customFormat(...args)}\n`
        }
    }

    private customFormat(...args: any[]): string {
        return args
            .map((arg) => {
                let formattedArg: string
                if (typeof arg === 'object' && arg !== null) {
                    if ('Name' in arg) {
                        const { Name, ...rest } = arg
                        formattedArg = JSON.stringify({ Name, ...rest })
                    } else {
                        formattedArg = JSON.stringify(arg)
                    }
                } else {
                    formattedArg = String(arg)
                }

                if (formattedArg.length > 100) {
                    formattedArg = `${formattedArg.slice(0, 100)}...`
                }
                return formattedArg
            })
            .join(' ')
    }

    private getTimeStamp(): string {
        const date = new Date()
        return `${date.getMinutes()}:${date.getSeconds()}`
    }

    public log(...args: any[]): void {
        if (this.LoggingLevel >= EDebugLoggingLevel.All) {
            console.log(this._loggingCounter, this.getTimeStamp(), ...args)
            this.logToString(this._loggingCounter, ...args)
            this._loggingCounter++
        }
    }

    public error(...args: any[]): void {
        if (this.LoggingLevel >= EDebugLoggingLevel.Error) {
            console.error(this._loggingCounter, this.getTimeStamp(), ...args)
            this.logToString(this._loggingCounter, ...args)
            this._loggingCounter++
        }
    }

    public warn(...args: any[]): void {
        if (this.LoggingLevel >= EDebugLoggingLevel.Warning) {
            console.warn(this._loggingCounter, this.getTimeStamp(), ...args)
            this.logToString(this._loggingCounter, ...args)
            this._loggingCounter++
        }
    }

    private isSingleTopicValid(topic: ELoggingTopics): boolean {
        return (
            !this._topicsToIgnore.includes(topic) &&
            (this._topicsToLog.includes(ELoggingTopics.lt_All) || this._topicsToLog.includes(topic))
        )
    }

    private isValidTopic(topic: ELoggingTopics | ELoggingTopics[]): boolean {
        if (Array.isArray(topic)) {
            for (const singleTopic of topic) {
                if (this.isSingleTopicValid(singleTopic)) {
                    return true
                }
            }
            return false
        } else {
            // Assuming ELoggingTopics is an object type
            return this.isSingleTopicValid(topic)
        }
    }

    public logTopic(topic: ELoggingTopics | ELoggingTopics[], ...args: any[]): void {
        if (this.isValidTopic(topic)) {
            this.log(`<${topic}>`, ...args)
        }
    }

    public errorTopic(topic: ELoggingTopics | ELoggingTopics[], ...args: any[]): void {
        if (this.isValidTopic(topic)) {
            this.error(`<${topic}>`, ...args)
        }
    }

    public warnTopic(topic: ELoggingTopics | ELoggingTopics[], ...args: any[]): void {
        if (this.isValidTopic(topic)) {
            this.warn(`<${topic}>`, ...args)
        }
    }

    public getLogString(): string {
        return this._logString
    }

    public copyLogStringToClipboard(): void {
        this.copyToClipboard(this._logString)
    }

    public copyToClipboard(text: string): void {
        if (navigator.clipboard) {
            // eslint-disable-next-line promise/catch-or-return
            navigator.clipboard.writeText(text).then(
                // eslint-disable-next-line promise/always-return
                () => {
                    alert('Text copied to clipboard')
                },
                (error) => {
                    console.error('Failed to copy text to clipboard', error)
                }
            )
        } else {
            console.error('Clipboard API is not available')
        }
    }

    public static IsAmongIgnoredErrors(messageOrError: string | Error): boolean {
        const message = messageOrError instanceof Error ? messageOrError.message : messageOrError
        return DebugUtils.Instance.ignoredErrorMessages.includes(message)
    }

    //----static logging methods for convenience
    public static log(...args: any[]): void {
        DebugUtils.Instance.log(...args)
    }

    // public static debugAndLog(...args: any[]): void {
    //     this.log(...args)
    // }

    public static error(...args: any[]): void {
        DebugUtils.Instance.error(...args)
    }

    public static warn(...args: any[]): void {
        DebugUtils.Instance.warn(...args)
    }

    public static logTopic(topic: ELoggingTopics | ELoggingTopics[], ...args: any[]): void {
        DebugUtils.Instance.logTopic(topic, ...args)
    }

    public static errorTopic(topic: ELoggingTopics | ELoggingTopics[], ...args: any[]): void {
        DebugUtils.Instance.errorTopic(topic, ...args)
    }

    public static warnTopic(topic: ELoggingTopics | ELoggingTopics[], ...args: any[]): void {
        DebugUtils.Instance.warnTopic(topic, ...args)
    }
}
