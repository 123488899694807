import { ChartControl, chartControlEvent, ChartControlId } from '@fto/chart_components/ChartControl'
import { ChartControlParams } from './ChartControlParams'
import { TGdiPlusCanvas, TRoundRectType } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TRect } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { IGPFont, IGPPen, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { TMkFontStyle } from '@fto/lib/drawing_interface/VCLCanvas/TMkFontStyle'
import { StylingHelper } from '@fto/lib/drawing_interface/StylingHelper'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { ImageControl } from '@fto/chart_components/ImageControl'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import GlobalImageManager from '@fto/lib/globals/GlobalImageManager'
import StrangeError from '../lib/common/common_errors/StrangeError'
import { CanvasLayer } from '@fto/lib/charting/auxiliary_classes_charting/Layers/Layers'
import { IVisibleIndexBuffer } from '@fto/lib/extension_modules/indicators/api/ITVisibleIndexBuffer'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/RuntimeIndicator'
import { IndicatorTitles } from '@fto/lib/extension_modules/indicators/IndicatorTitles'
import DataNotDownloadedYetError from '@fto/lib/ft_types/data/data_errors/DataUnavailableError'

export class IndicatorConfigurationControl extends ChartControl {
    private indicator!: TRuntimeIndicator
    private ownerChart!: TChartWindow
    private ownerLayer!: CanvasLayer

    private indicatorNameStyle!: TMkFontStyle
    private indicatorNameFont!: IGPFont
    private nameWidth = 0

    private indicatorLastValue!: TMkFontStyle
    private indicatorLastValueFont!: IGPFont
    private indicatorValuesMap: Map<string, { rect: TRect; buff: IVisibleIndexBuffer; valueStr: string }> = new Map()
    private lastValueBrush!: IGPSolidBrush
    private static valuesWidthByContext: Map<number, number> = new Map<number, number>()

    private visibilyControl!: ImageControl
    private indicatorNameLocation = new TRect(0, 0, 0, 0)
    private isIndicatorSelected = false

    private indicatorControls: ChartControl[] = []
    private title = ''

    constructor(
        indicator: TRuntimeIndicator,
        ownerChart: TChartWindow,
        ownerLayer: CanvasLayer,
        font: TMkFontStyle,
        tmkFontStyleLastValue: TMkFontStyle,
        chartControlParams: ChartControlParams
    ) {
        super(chartControlParams)
        this.indicator = indicator
        this.ownerChart = ownerChart
        this.ownerLayer = ownerLayer
        this.indicatorNameStyle = font
        this.indicatorLastValue = tmkFontStyleLastValue

        this.lastValueBrush = new IGPSolidBrush(tmkFontStyleLastValue.color)

        this.indicatorNameFont = new IGPFont(
            new TGPFontFamily(this.indicatorNameStyle.name),
            this.indicatorNameStyle.size,
            StylingHelper.ConvertFontStyle(this.indicatorNameStyle.style)
        )

        this.indicatorLastValueFont = new IGPFont(
            new TGPFontFamily(this.indicatorLastValue.name),
            this.indicatorLastValue.size,
            StylingHelper.ConvertFontStyle(this.indicatorLastValue.style)
        )

        const chartControlParamsImage = chartControlParams.clone()
        const chartControlParamsImageRect = chartControlParamsImage.getLocation()
        chartControlParamsImageRect.Right = chartControlParamsImageRect.Left + 24
        chartControlParamsImageRect.Bottom = chartControlParamsImageRect.Top + 24
        chartControlParamsImage.setLocation(chartControlParamsImageRect)

        if (GlobalImageManager.Instance.eyeImage) {
            const eyeImageControl = new ImageControl(
                this.ownerChart,
                chartControlParamsImage,
                GlobalImageManager.Instance.eyeImage
            )
            eyeImageControl.enableBorder()
            eyeImageControl.controlId = ChartControlId.INDICATOR_VISIBILITY
            this.indicatorControls.push(eyeImageControl)
            this.visibilyControl = eyeImageControl
            this.visibilyControl.attachObserver(this.ownerChart)
        } else {
            throw new StrangeError('GlobalImageManager.Instance.eyeImage is null')
        }

        if (GlobalImageManager.Instance.settingsImage) {
            const settingsImageControl = new ImageControl(
                this.ownerChart,
                chartControlParamsImage.clone(),
                GlobalImageManager.Instance.settingsImage
            )
            settingsImageControl.enableBorder()
            settingsImageControl.controlId = ChartControlId.INDICATOR_SETTINGS
            this.indicatorControls.push(settingsImageControl)
        } else {
            throw new StrangeError('GlobalImageManager.Instance.settingsImage is null')
        }

        if (GlobalImageManager.Instance.closeImage) {
            const closeImageControl = new ImageControl(
                this.ownerChart,
                chartControlParamsImage.clone(),
                GlobalImageManager.Instance.closeImage
            )
            closeImageControl.enableBorder()
            closeImageControl.controlId = ChartControlId.INDICATOR_DELETE
            this.indicatorControls.push(closeImageControl)
        } else {
            throw new StrangeError('GlobalImageManager.Instance.closeImage is null')
        }

        this.title = IndicatorTitles[this.indicator.FileName] || this.indicator.ShortName
    }

    protected getColors() {
        return {
            mouseInsideBrush: new IGPSolidBrush(this.ChartWindow.ChartOptions.ControlsColorScheme.infoBackground, 0.85),
            mainTextBrush: new IGPSolidBrush(this.ChartWindow.ChartOptions.ControlsColorScheme.infoText),
            mainTextHiddenBrush: new IGPSolidBrush(this.ChartWindow.ChartOptions.ControlsColorScheme.infoTextHidden),
            indicatorNameBackground: new IGPSolidBrush(
                this.ChartWindow.ChartOptions.ControlsColorScheme.infoTextBackground
            ),
            indicatorNameBorders: {
                hovered: new IGPPen(this.ChartWindow.ChartOptions.ControlsColorScheme.infoBorder, 1),
                selected: new IGPPen(this.ChartWindow.ChartOptions.ControlsColorScheme.infoBorderSelected, 1)
            }
        }
    }

    public get Indicator(): TRuntimeIndicator {
        return this.indicator
    }

    public OnIndicatorParamsChange(): void {
        this.nameWidth = this.indicatorNameFont.getTextWidthByContext(
            this.indicator.DisplayParamsOnIndicatorConfigurationControl()
                ? this.indicator.GetNameWithParams()
                : this.title,
            this.ownerLayer.context
        )
    }

    public adjustControlWidth(isAdditionalButtonsVisible = false): void {
        try {
            if (this.nameWidth === 0) {
                this.OnIndicatorParamsChange()
            }
            const indicatorNameWidth = this.nameWidth + 8

            this.indicatorValuesMap.clear()

            const step = 6

            const locationControl = this.getLocation()

            let rightEdge = locationControl.Left + indicatorNameWidth + step

            // update Control location
            this.indicatorNameLocation.Left = locationControl.Left
            this.indicatorNameLocation.Right = rightEdge - step
            this.indicatorNameLocation.Top = locationControl.Top
            this.indicatorNameLocation.Bottom = locationControl.Bottom

            locationControl.Right = rightEdge
            this.setLocation(locationControl)
            if (isAdditionalButtonsVisible || this.isIndicatorSelected) {
                for (let i = 0; i < this.indicatorControls.length; i++) {
                    const control = this.indicatorControls[i]
                    const controlLocation = control.getLocation()
                    controlLocation.Left = rightEdge
                    controlLocation.Right = controlLocation.Left + control.getWidth()
                    controlLocation.Top = this.getLocation().Top
                    controlLocation.Bottom = this.getLocation().Bottom

                    control.setLocation(controlLocation)

                    rightEdge += control.getWidth() + step
                }
            } else {
                if (this.indicator.IsVisible()) {
                    //
                } else {
                    const visibilyControlLocation = this.visibilyControl.getLocation()
                    visibilyControlLocation.Left = rightEdge
                    visibilyControlLocation.Right = visibilyControlLocation.Left + this.visibilyControl.getWidth()
                    visibilyControlLocation.Top = this.getLocation().Top
                    visibilyControlLocation.Bottom = this.getLocation().Bottom
                    this.visibilyControl.setLocation(visibilyControlLocation)

                    rightEdge += this.visibilyControl.getWidth() + step
                }
            }

            locationControl.Right = rightEdge
            this.setLocation(locationControl)
            if (this.indicator.IsVisible()) {
                for (let j = 0; j < this.indicator.VisibleBuffers.length; j++) {
                    const buff: IVisibleIndexBuffer = this.indicator.VisibleBuffers[j]
                    if (buff && buff.buffer && buff.IsVisible()) {
                        if (!buff.buffer.HasSomeValues() || buff.buffer.LastItemInTestingIndex < 0) {
                            continue
                        }
                        const value: number = buff.buffer.GetValue(buff.buffer.LastItemInTestingIndex)
                        if (value && value !== buff.EmptyValue) {
                            const valueStr = value.toFixed(this.indicator.Digits)
                            let valueWidth = IndicatorConfigurationControl.valuesWidthByContext.get(valueStr.length)
                            if (!valueWidth) {
                                valueWidth = this.indicatorLastValueFont.getTextWidthByContext(
                                    valueStr.replaceAll(/./g, '0'),
                                    this.ownerLayer.context
                                )
                                IndicatorConfigurationControl.valuesWidthByContext.set(valueStr.length, valueWidth)
                            }

                            const rect = new TRect(
                                rightEdge,
                                this.getLocation().Top,
                                rightEdge + valueWidth + step,
                                this.getLocation().Bottom
                            )
                            this.indicatorValuesMap.set(buff.name, { rect, buff, valueStr })

                            rightEdge += valueWidth + step
                        }
                    }
                }
            }
            // update Control location
            locationControl.Right = rightEdge
            this.setLocation(locationControl)
        } catch (error) {
            this.nameWidth = 0

            if (error instanceof DataNotDownloadedYetError) {
                throw error
            }

            throw new StrangeError('IndicatorConfigurationControl.adjustControlWidth', error)
        }
    }

    public draw(canvas: TGdiPlusCanvas): void {
        if (!this.IsVisible()) {
            return
        }

        const _rect = this.getLocation()

        const colors = this.getColors()

        canvas.FillRectRounded(_rect, colors.mouseInsideBrush, 5)

        if (this.isMouseInside() || this.isIndicatorSelected) {
            if (this.isIndicatorSelected) {
                canvas.strokeRect(
                    this.indicatorNameLocation,
                    TRoundRectType.BOTH,
                    5,
                    true,
                    colors.indicatorNameBackground,
                    colors.indicatorNameBorders.selected
                )
            } else {
                canvas.strokeRect(
                    this.indicatorNameLocation,
                    TRoundRectType.BOTH,
                    5,
                    true,
                    colors.indicatorNameBackground,
                    colors.indicatorNameBorders.hovered
                )
            }

            for (const control of this.indicatorControls) {
                control.draw(canvas)
            }
        } else {
            if (!this.indicator.IsVisible()) {
                this.visibilyControl.draw(canvas)
            }
        }

        const indicatorLabel = this.indicator.DisplayParamsOnIndicatorConfigurationControl()
            ? this.indicator.GetNameWithParams()
            : this.title

        if (this.indicator.IsVisible()) {
            canvas.textOut(_rect.Left + 4, _rect.Top + 17, indicatorLabel, this.indicatorNameFont, colors.mainTextBrush)

            if (this.indicator.DisplayValuesOnIndicatorConfigurationControl()) {
                for (const [name, { rect, buff, valueStr }] of this.indicatorValuesMap.entries()) {
                    let colorValue = this.lastValueBrush
                    if (buff && buff.style && buff.style.color) {
                        colorValue = new IGPSolidBrush(buff.style.color)
                    }
                    canvas.textOut(rect.Left, rect.Top + 17, valueStr, this.indicatorLastValueFont, colorValue)
                }
            }
        } else {
            canvas.textOut(
                _rect.Left + 5,
                _rect.Top + 17,
                indicatorLabel,
                this.indicatorNameFont,
                colors.mainTextHiddenBrush
            )
        }
    }

    private processVisibilyChange(): void {
        if (this.indicator.IsVisible()) {
            this.indicator.Hide()
        } else {
            this.indicator.Show()
        }
        this.ownerLayer.draw()
    }

    public onOwnerIndicatorHide(): void {
        if (GlobalImageManager.Instance.eyeCloseImage) {
            this.visibilyControl.changeImage(GlobalImageManager.Instance.eyeCloseImage)
        }
    }

    public onOwnerIndicatorShow(): void {
        if (GlobalImageManager.Instance.eyeImage) {
            this.visibilyControl.changeImage(GlobalImageManager.Instance.eyeImage)
        }
    }

    public onIndicatorSelect(): void {
        this.isIndicatorSelected = true
    }

    public onIndicatorDeselect(): void {
        this.isIndicatorSelected = false
    }

    public onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        let result = false
        if (super.onMouseDown(event, sender) !== null) {
            for (let i = 0; i < this.indicatorControls.length; i++) {
                const on = this.indicatorControls[i].onMouseDown(event, sender)
                if (on) {
                    switch (on.controlId) {
                        case ChartControlId.INDICATOR_DELETE: {
                            this.notify(chartControlEvent.INDICATOR_DELETE)

                            break
                        }
                        case ChartControlId.INDICATOR_VISIBILITY: {
                            this.processVisibilyChange()
                            this.notify(chartControlEvent.INDICATOR_VISIBILITY_CHANGE)

                            break
                        }
                        case ChartControlId.INDICATOR_SETTINGS: {
                            this.notify(chartControlEvent.INDICATOR_SHOW_INDICATOR_SETTINGS)

                            break
                        }
                    }
                    result = true
                    break
                }
            }
            result = true
        }

        if (result) {
            this.isIndicatorSelected = true
            this.notify(chartControlEvent.INDICATOR_SELECTED_BY_CONF_CONTROL)
            return this
        }

        return null
    }

    public onMouseMove(event: MouseEvent, sender: TChart): ChartControl | null {
        if (super.onMouseMove(event, sender) !== null) {
            this.adjustControlWidth(true)
            for (let i = 0; i < this.indicatorControls.length; i++) {
                const on = this.indicatorControls[i].onMouseMove(event, sender)
                if (on) {
                    this.ownerLayer.draw()
                    return this
                }
            }
            return this
        }

        return null
    }

    public onMouseLeave(event: MouseEvent): ChartControl | null {
        this.onMouseLeaveControl()
        this.adjustControlWidth(false)
        for (const control of this.indicatorControls) {
            control.onMouseLeave(event)
        }
        return null
    }

    public onBrowserWndSizing(): void {
        IndicatorConfigurationControl.valuesWidthByContext.clear()
        this.OnIndicatorParamsChange()
    }
}
