import { t } from 'i18next'
import { TIndexBuffer } from '../api/IIndicatorApi'

import { IndicatorImplementation } from '../api/IndicatorImplementation'
import {
    E_MAType,
    TOptionType,
    TOptValue_number,
    TOutputWindow,
    TPriceType
} from '@fto/lib/extension_modules/common/CommonTypes'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { INT_MAX } from '../../common/CommonConstantsForExternalModules'

export default class BollingerBandsRatio extends IndicatorImplementation {
    public period: TOptValue_number = new TOptValue_number(20)
    private Shift: TOptValue_number = new TOptValue_number(0)
    public Deviation: TOptValue_number = new TOptValue_number(2.0)
    private MAType: TOptValue_number = new TOptValue_number(E_MAType.ma_SMA)
    private ApplyToPrice: TOptValue_number = new TOptValue_number(TPriceType.pt_Close)
    private MA!: TIndexBuffer
    private UpBand!: TIndexBuffer
    private DownBand!: TIndexBuffer
    public Ratio!: TIndexBuffer

    Init(): void {
        this.api.IndicatorShortName(t('indicators.bollingerBandsRatio'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.general.generalFields.period'), TOptionType.ot_Integer, this.period)
        this.api.SetOptionRange(t('indicatorModal.general.generalFields.period'), 1, INT_MAX)

        this.api.RegOption(t('indicatorModal.general.generalFields.deviation'), TOptionType.ot_double, this.Deviation)
        this.api.SetOptionRange(t('indicatorModal.general.generalFields.deviation'), 0.1, 200)

        this.MA = this.api.CreateIndexBuffer()
        this.UpBand = this.api.CreateIndexBuffer()
        this.DownBand = this.api.CreateIndexBuffer()
        this.Ratio = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(1)

        this.api.SetIndexBuffer(0, this.Ratio)
        this.api.SetIndexLabel(0, t('indicatorModal.bollingerBandsRatio.fields.ratio'))

        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#ff9a21')

        this.api.SetIndexDrawBegin(0, this.period.value - 1 + this.Shift.value)
    }

    Calculate(index: number): void {
        if (index + this.period.value >= this.api.Bars()) {
            return
        }

        const value = this.api.GetMA(
            index,
            0,
            this.period.value,
            this.MAType.value,
            this.ApplyToPrice.value,
            this.MA.getValue(index + 1)
        )
        this.MA.setValue(index, value)

        let sum = 0
        for (let i = index; i <= index + this.period.value - 1; i++) {
            sum += Math.pow(Math.abs(this.api.GetPrice(i, this.ApplyToPrice.value) - value), 2)
        }
        const sd = Math.sqrt(sum / this.period.value) * this.Deviation.value
        const upBandValue = value + sd
        const downBandValue = value - sd

        this.UpBand.setValue(index, upBandValue)
        this.DownBand.setValue(index, downBandValue)

        const ratioValue = (upBandValue - downBandValue) / value
        this.Ratio.setValue(index, ratioValue)
    }

    OnParamsChange() {
        this.api.SetBufferShift(0, this.Shift.value)
        this.api.SetBufferShift(1, this.Shift.value)
        this.api.SetBufferShift(2, this.Shift.value)
        this.api.SetBufferShift(3, this.Shift.value)
    }
}
