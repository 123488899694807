import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react'

import { Icon } from '@fto/icons'
import { Button, Flex } from '@fto/ui'

import HistoricalMomentSelector from './components/HistoricalMomentSelector'
import { HistoricalEvent } from '../../types'

import styles from './index.module.scss'

type Props = {
    label: string
    events: HistoricalEvent[]
    activeEvent: string
    setActiveEvent: (eventName: string) => void
    groupsOpen: Record<string, boolean>
    setGroupsOpen: Dispatch<SetStateAction<Record<string, boolean>>>
    searchValue: string
}

const CategoryDropdown: FC<Props> = ({
    label,
    events,
    activeEvent,
    setActiveEvent,
    groupsOpen,
    setGroupsOpen,
    searchValue
}) => {
    const toggle = useCallback(() => {
        setGroupsOpen((prevGroupsOpen: Record<string, boolean>) => ({
            ...prevGroupsOpen,
            [label]: !prevGroupsOpen[label]
        }))
    }, [label, setGroupsOpen])

    return (
        <Flex direction='column' gap={8}>
            <Button
                block
                before={<Icon size={16} name={groupsOpen[label] ? 'minus' : 'plus'} />}
                label={label}
                type='tetriatry-white'
                size='compact'
                classNames={{ content: styles.dropdownButton }}
                onClick={toggle}
            />
            {groupsOpen[label] &&
                events.map((event) => (
                    <HistoricalMomentSelector
                        key={event.key}
                        historicalMoment={event}
                        isActive={event.key === activeEvent}
                        setActiveEvent={setActiveEvent}
                        searchValue={searchValue}
                    />
                ))}
        </Flex>
    )
}

export default CategoryDropdown
