import { BasicPaintToolJSON } from '@fto/lib/ProjectAdapter/Types'
import { PaintToolManager } from '@fto/lib/charting/paint_tools/PaintToolManager'
import { TBasicPaintTool } from '../../../charting/paint_tools/BasicPaintTool'
import { CustomCursorPointers } from '../../../ft_types/common/CursorPointers'
import { PaintToolNames } from '../PaintToolNames'
import { TPaintToolType } from '../PaintToolsAuxiliaryClasses'
import GraphToolStore from '@fto/lib/charting/tool_storages/graphToolStore'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TPoint } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { IChart } from '../../chart_classes/IChart'
import { LastPaintToolStyleManager } from '@fto/lib/charting/paint_tools/LastPaintToolStyleManager'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TPtRay extends TBasicPaintTool {
    constructor(aChart: IChart) {
        super(aChart, PaintToolNames.ptRay)
        this.ShortName = 'Ray' // Preserved the case as in Delphi
        this.fToolType = TPaintToolType.tt_Ray // Assuming tt_Ray is an already defined constant or enum
        this.fMaxPoints = 2
        this.fClosedPolygon = false
        this.CursorStyle = CustomCursorPointers.crCursorRay
        this.icon = 41
        // TODO: Implement the fEditDialog initialization when the RayFrm form is available
        // If RayFrm is not yet implemented, this line should be commented out or handled appropriately.
        // this.fEditDialog = RayFrm; // Uncomment and implement when RayFrm is available
        this.applySettings()
    }

    private applySettings() {
        let styles = LastPaintToolStyleManager.loadToolProperties(PaintToolNames.ptRay)
        if (!styles) {
            styles = GlobalTemplatesManager.Instance.getToolDefaultTemplate(PaintToolNames.ptRay)
        }

        if (!styles) throw new StrangeError('Default styles for Ray are not found')

        this.fLineStyle = TLineStyle.fromSerialized(styles.lineStyle)
    }

    clone(): TPtRay {
        const cloneObj = new TPtRay(this.fChart)
        const baseClone = super.clone()
        Object.assign(cloneObj, baseClone)
        return cloneObj
    }

    public toJson(): BasicPaintToolJSON {
        return super.toJson()
    }

    fromJSON(json: BasicPaintToolJSON) {
        super.fromJSON(json)
    }

    protected GetCoords(): [number, number, number, number] {
        let x1 = this.fPoints[0].x
        let y1 = this.fPoints[0].y
        let x2 = this.fPoints[1].x
        let y2 = this.fPoints[1].y
        const coords = this.chart.GetRayCoords(x1, y1, x2, y2, this.fPoints[0].price, this.fPoints[1].price) as unknown
        if (coords) {
            ;[x1, y1, x2, y2] = coords as [number, number, number, number]
        }
        return [x1, y1, x2, y2]
    }

    public EdgeUnderMouse(x: number, y: number): number {
        let x1: number, y1: number, x2: number, y2: number
        ;[x1, y1, x2, y2] = this.GetCoords()
        if (this.PointAboveTheLine(x, y, x1, y1, x2, y2)) {
            return 0
        } else {
            return -1
        }
    }

    public Paint(): void {
        if (this.fPoints.Count < 2) {
            return
        }
        if (this.fHighlighted) {
            const points = [
                new TPoint(this.fPoints[0].x, this.fPoints[0].y),
                new TPoint(this.fPoints[1].x, this.fPoints[1].y)
            ]
            this.PaintHoverLine(points)
        }

        // Paint the ray and text
        this.PaintRayWithText(this.fPoints[0], this.fPoints[1], this.fText)

        this.PaintMarkers()
    }

    public get isVisibleInChartFrame(): boolean {
        // Assuming fPoints is an array of TPointRec or similar structure
        // and chart has a method IsRayVisible that takes four numbers representing two points
        return this.chart.IsRayVisible(this.fPoints[0].x, this.fPoints[0].y, this.fPoints[1].x, this.fPoints[1].y)
    }

    ExportToDialog(): void {
        super.ExportToDialog()

        const { updateToolSettings } = GraphToolStore // Use the store/context

        const data = {
            fText: {
                value: this.fText,
                label: 'toolsModal.fields.description',
                type: 'text',
                key: 'fText',
                disabled: false
            },

            lineStyle: {
                key: 'lineStyle',
                value: this.fLineStyle,
                label: 'toolsModal.fields.line',
                type: 'style',
                disabled: false
            }
        }

        // Populate the modal with existing data
        updateToolSettings(data)

        addModal(MODAL_NAMES.chart.graphTools, { toolType: PaintToolNames.ptRay, toolName: 'ray' })
    }

    ImportFromDialog(): void {
        this.chart.ChartWindow.saveStateWithNotify()


        super.ImportFromDialog()

        const { getKeyValueData, resetToolSettings } = GraphToolStore // Use the store/context
        const formattedToolSettings = getKeyValueData()

        this.fText = formattedToolSettings.fText
        this.fLineStyle = formattedToolSettings.lineStyle

        this.saveToManager()
        resetToolSettings()
    }

    override setLineStylesParams(styles: {
        color: TLineStyle['color']
        style: TLineStyle['style']
        width: TLineStyle['width']
        byKey: 'color' | 'style' | 'width'
    }) {
        super.setLineStylesParams(styles)
        this.saveToManager()
    }

    override setFontStyles(color: string, fontSize: number) {
        super.setFontStyles(color, fontSize)
        this.saveToManager()
    }

    override setFillColorParams(color: string, opacity: number) {
        super.setFillColorParams(color, opacity)
        this.saveToManager()
    }

    private saveToManager() {
        LastPaintToolStyleManager.saveToolProperties(PaintToolNames.ptRay, {
            lineStyle: this.fLineStyle.getSerialized(),
            toolName: PaintToolNames.ptRay,
            fText: this.fText
        })
    }
}

PaintToolManager.RegisterPaintTool(PaintToolNames.ptRay, TPtRay)
