import { TSymbolInfo } from '@fto/lib/ft_types/common/BasicClasses/SymbolInfo'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { TSymbolList } from '@fto/lib/ft_types/data/SymbolList'

export interface IMockSymbolInfoDictionary {
    [key: string]: TSymbolInfo
}
export default class MockSymbolList extends TSymbolList {
    constructor() {
        super()
        this.DName = `MockSymbolList ID=${Math.random().toString()}`
    }

    public MockSymbolInfos: IMockSymbolInfoDictionary = {}

    public CreateSymbol(SymbolName: string, performSeek: boolean): TSymbolData {
        //if mock symbol info exists, return it
        let symbolInfo
        if (this.MockSymbolInfos[SymbolName]) {
            symbolInfo = this.MockSymbolInfos[SymbolName]
        } else {
            symbolInfo = new TSymbolInfo(SymbolName)
        }

        const result = new TSymbolData(symbolInfo)

        this.AddNewSymbol(result, performSeek)
        return result
    }
}
