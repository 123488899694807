import { FC, useMemo } from 'react'
import cx from 'classnames'

import { IconButton } from '@fto/icons'
import { Flex, Typography, useTheme } from '@fto/ui'

import styles from './index.module.scss'
import { t } from 'i18next'

type Props = {
    selectedTemplate: string
    templates: string[]
    onSelect: (name: string) => void
    onDelete: (name: string) => void
    closeHandler: () => void
}

export const Content: FC<Props> = ({ selectedTemplate, templates, onSelect, onDelete, closeHandler }) => {
    const { isLight } = useTheme()

    const optionsElements = useMemo(() => {
        if (templates.length === 0) return null

        return (
            <div className={styles.DropdownBody}>
                {templates.map((name: string) => {
                    const selectedIconColor = isLight ? 'white' : 'gray-1000'
                    return (
                        <Flex
                            alignItems='center'
                            justifyContent='flex-start'
                            className={cx(styles.Option, {
                                [styles.selected]: selectedTemplate === name
                            })}
                            onClick={() => {
                                onSelect(name)
                                closeHandler()
                            }}
                            key={name}
                            gap={4}
                        >
                            <Typography
                                type='interface-regular'
                                color={selectedTemplate === name ? selectedIconColor : 'gray-1000'}
                                title={name}
                                truncate
                            >
                                {name}
                            </Typography>

                            <IconButton
                                className={cx(styles.deleteButton, {
                                    [styles.isSelectedOption]: selectedTemplate === name
                                })}
                                name='delete'
                                size={18}
                                color={isLight ? 'gray-1000' : 'white'}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onDelete(name)
                                    closeHandler()
                                }}
                            />
                        </Flex>
                    )
                })}
            </div>
        )
    }, [selectedTemplate, templates, onSelect, onDelete, closeHandler])

    return (
        <>
            {optionsElements}

            {templates.length === 0 && (
                <Flex justifyContent='center' alignItems='center' className={styles.EmptyPlaceholderContainer}>
                    <Typography color='gray-800' type='interface-regular' align='center'>
                        {t('templates.dropdown.emptyPlaceholder')}
                    </Typography>
                </Flex>
            )}
        </>
    )
}
