import { FC, ReactNode, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, Tooltip, Typography, Separator, HoverElement } from '@fto/ui'

import { Icon } from '@fto/icons'

import styles from './index.module.scss'
import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { TabsSettingsType } from '@fto/chart_components/ProjectInterface/types'
import TAB_NAMES from '@fto/chart_components/ProjectInterface/constants/tabsNames'

const WithSeparator: FC<{ children: ReactNode; gap: 16 | 8 }> = ({ children, gap }) => {
    return (
        <Flex alignItems='center' gap={gap}>
            {children}
            <Separator height='24px' color='gray-500' />
        </Flex>
    )
}

type Props = {
    balance: number
    equity: number
    pnl: number
    time: TDateTime
    openTab: ({ isOpen, tabName }: TabsSettingsType) => void
}

const InfoPanel: FC<Props> = ({ balance, equity, pnl, time, openTab }) => {
    const { t } = useTranslation()

    const handleAnalytics = useCallback(() => {
        openTab({ isOpen: true, tabName: TAB_NAMES.ANALYTICS })
    }, [openTab])

    const handleNewsOpen = useCallback(() => {
        openTab({ isOpen: true, tabName: TAB_NAMES.NEWS })
    }, [openTab])

    const handleWatchlistOpen = useCallback(() => {
        openTab({ isOpen: true, tabName: TAB_NAMES.WATCH_LIST })
    }, [openTab])

    const formattedTime = useMemo(() => {
        return DateUtils.FormatDateTime('(DDD) YYYY-MM-DD HH:nn:ss', time)
    }, [time])

    return (
        <Flex alignItems='center' gap={8}>
            <Flex alignItems='center' className={styles.InfoWrapper} gap={16}>
                <WithSeparator gap={16}>
                    <Tooltip content={t('terminal.tooltips.time')} placement='top'>
                        <Flex alignItems='center' gap={8}>
                            <Icon name='time' size={18} color='var(--color-gray-800)' />
                            <Flex alignItems='center' gap={4}>
                                <Typography type='interface-semibold' color='gray-1000'>
                                    {t('terminal.infoPanel.time')}:
                                </Typography>
                                <Typography type='interface-regular' color='gray-1000'>
                                    {formattedTime}
                                </Typography>
                            </Flex>
                        </Flex>
                    </Tooltip>
                </WithSeparator>
                <WithSeparator gap={16}>
                    <Tooltip content={t('terminal.tooltips.balance')} placement='top'>
                        <Flex alignItems='center' gap={8}>
                            <Icon name='wallet' size={18} color='var(--color-gray-800)' />
                            <Flex alignItems='center' gap={4}>
                                <Typography type='interface-semibold' color='gray-1000'>
                                    {t('terminal.infoPanel.balance')}:
                                </Typography>
                                <Typography type='interface-regular' color='gray-1000'>
                                    {balance}
                                </Typography>
                            </Flex>
                        </Flex>
                    </Tooltip>
                </WithSeparator>
                <WithSeparator gap={16}>
                    <Tooltip content={t('terminal.tooltips.equity')} placement='top'>
                        <Flex alignItems='center' gap={8}>
                            <Icon name='scale' size={18} color='var(--color-gray-800)' />
                            <Flex alignItems='center' gap={4}>
                                <Typography type='interface-semibold' color='gray-1000'>
                                    {t('terminal.infoPanel.equity')}:
                                </Typography>
                                <Typography type='interface-regular' color='gray-1000'>
                                    {equity}
                                </Typography>
                            </Flex>
                        </Flex>
                    </Tooltip>
                </WithSeparator>
                <Tooltip content={t('terminal.tooltips.profitLoss')} placement='top'>
                    <Flex alignItems='center' gap={8}>
                        <Icon name='pl' size={18} color='var(--color-gray-800)' />
                        <Flex alignItems='center' gap={4}>
                            <Typography type='interface-semibold' color='gray-1000'>
                                {t('terminal.infoPanel.pnl')}:
                            </Typography>
                            <Typography type='interface-regular' color='gray-1000'>
                                {pnl}
                            </Typography>
                        </Flex>
                    </Flex>
                </Tooltip>
            </Flex>

            <Flex alignItems='center' gap={8}>
                <WithSeparator gap={8}>
                    <Tooltip placement='top' color='dark' content={t('terminal.tooltips.notes')}>
                        <HoverElement size={32}>
                            <Icon name='notes' size={18} color='var(--color-gray-800)' />
                        </HoverElement>
                    </Tooltip>
                </WithSeparator>

                <WithSeparator gap={8}>
                    <Tooltip placement='top' color='dark' content={t('terminal.tooltips.news')}>
                        <HoverElement size={32} onClick={handleNewsOpen}>
                            <Icon name='language' size={18} color='var(--color-gray-800)' />
                        </HoverElement>
                    </Tooltip>
                </WithSeparator>
                <Tooltip placement='top' color='dark' content={t('terminal.tooltips.watchList')}>
                    <HoverElement size={32} onClick={handleWatchlistOpen}>
                        <Icon name='watchlist' size={18} color='var(--color-gray-800)' />
                    </HoverElement>
                </Tooltip>
            </Flex>

            <Button
                withTooltip
                tooltipPlacement='top'
                tooltipText={t('terminal.tooltips.analytics')}
                before={<Icon name='statistics' color='currentColor' size={18} />}
                label={t('general.analytics')}
                size='small'
                onClick={handleAnalytics}
            />
        </Flex>
    )
}

export default InfoPanel
