/* eslint-disable @typescript-eslint/ban-types */
import StrangeSituationNotifier from '../common/StrangeSituationNotifier'
import { DebugUtils } from './DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import { INamed } from './INamed'

export class EventParams {
    public stopPropagationCondition: Function | undefined = undefined

    constructor(stopPropagationCondition?: Function) {
        this.stopPropagationCondition = stopPropagationCondition || undefined
    }
}

export default class TEventsFunctionality implements INamed {
    private _eventListeners: { [event: string]: Function[] } = {}

    private _boundClasses_useForDiagnosticsOnly: { [event: string]: any[] } = {}

    private _name: string
    public SetName(name: string): void {
        this._name = `${name}_Events`
    }
    public get DName(): string {
        return this._name
    }

    public toString(): string {
        return this.DName
    }

    constructor(name: string) {
        this._name = name
    }

    public on(eventName: string, listener: Function, boundClass?: any): void {
        // DebugUtils.logTopic(ELoggingTopics.lt_Events, this._name, `Subscribing to event ${eventName}`)
        if (listener.name === '') {
            //try to avoid using anonymous functions as event listeners because it is possible to subscribe to several identical anonymous functions at the same time
            DebugUtils.error(`Using anonymous function as event listener for event ${eventName} in ${this._name}`)
            // StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
            //     `Using anonymous function as event listener for event ${eventName}`
            // )
        }

        if (!this._eventListeners[eventName]) {
            this._eventListeners[eventName] = []
            if (DebugUtils.Instance.UseBoundClassDiagnostic) {
                this._boundClasses_useForDiagnosticsOnly[eventName] = []
            }
        }
        if (!this.isSubscribed(eventName, listener)) {
            this._eventListeners[eventName].push(listener)
            if (DebugUtils.Instance.UseBoundClassDiagnostic) {
                this._boundClasses_useForDiagnosticsOnly[eventName].push(boundClass)
            }
        }
        const numberOfListeners = this._eventListeners[eventName].length
        if (numberOfListeners > 10) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `More than 10 listeners (${numberOfListeners}) for event ${eventName}`
            )
        }
        const numberOfKeys = Object.keys(this._eventListeners).length
        if (numberOfKeys > 10) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `More than 10 keys (${numberOfKeys}) for event ${eventName}`
            )
        }
    }

    public off(eventName: string, listener: Function): void {
        // DebugUtils.logTopic(ELoggingTopics.lt_Events, this._name, `Unsubscribing from event ${eventName})`)
        const listeners = this._eventListeners[eventName]
        if (listeners) {
            this._eventListeners[eventName] = listeners.filter((l) => l !== listener)
            if (DebugUtils.Instance.UseBoundClassDiagnostic) {
                //clear just in case
                this._boundClasses_useForDiagnosticsOnly[eventName] = []
            }
        }
    }

    public isSubscribed(eventName: string, listener: Function): boolean {
        const listeners = this._eventListeners[eventName]
        if (listeners) {
            return listeners.includes(listener)
        }
        return false
    }

    public EmitEvent(theEvent: string, ...args: any[]): void {
        const eventParams = this.getEventParams(...args)
        DebugUtils.logTopic(ELoggingTopics.lt_Events, this._name, `Emitting event ${theEvent}`, args)

        if (this._eventListeners[theEvent]) {
            // We create a copy of the listeners array before iterating.
            // This prevents issues if a listener modifies the original array (e.g., by unsubscribing)
            // during iteration, ensuring that all intended listeners are still called.
            const listeners = this._eventListeners[theEvent] ? [...this._eventListeners[theEvent]] : []

            for (let i = 0; i < listeners.length; i++) {
                const listener = listeners[i]
                DebugUtils.logTopic(
                    ELoggingTopics.lt_Events,
                    this._name,
                    `Calling listener #${i} for event ${theEvent}`
                )
                if (eventParams && eventParams.stopPropagationCondition && eventParams.stopPropagationCondition()) {
                    DebugUtils.logTopic(
                        ELoggingTopics.lt_Events,
                        this._name,
                        `Stopping propagation for event ${theEvent}`
                    )
                    break
                }
                if (DebugUtils.Instance.AllowEventsToThrowErrors) {
                    //only use this in debug. It will prevent other listeners from being called if an exception occurs in one of them
                    listener(...args)
                } else {
                    try {
                        listener(...args)
                    } catch (error) {
                        //We are not preventing other listeners from being called if an exception occurs in one of them
                        StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                            `Error in event listener (event: ${theEvent} listener: ${listener} args: ${args}). Error: ${error}`
                        )
                    }
                }
            }
        }
    }

    private getEventParams(...args: any[]): EventParams | undefined {
        for (const arg of args) {
            if (arg instanceof EventParams) {
                return arg
            }
        }
        return undefined
    }

    public releaseAllEvents(): void {
        DebugUtils.logTopic(ELoggingTopics.lt_Events, this._name, 'Releasing all events')
        this._eventListeners = {}
        this._boundClasses_useForDiagnosticsOnly = {}
    }
}
