import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { CanvasLayer } from '../charting/auxiliary_classes_charting/Layers/Layers'
import { t } from 'i18next'
import { ColorHelperFunctions } from './ColorHelperFunctions'

export class VisualLoader {
    private context: CanvasRenderingContext2D
    private animationStart = 0
    private loaderFrameId: number | null = null
    private loaderSettings = {
        radius: 25,
        color: '#2F80ED',
        lineWidth: 4
    }
    private priceBorderWidth = 50
    private loaderPosition = { x: 0, y: 0 }
    public mainChart: TMainChart | null = null

    constructor(canvas: HTMLCanvasElement) {
        this.context = canvas.getContext('2d')!
    }

    public drawLoaderForChunkDownloading(
        width: number,
        height: number,
        priceBorderWidth: number,
        lastBarX: number
    ): void {
        const chart = this.mainChart?.ChartWindow as TChartWindow
        const colors = {
            light: chart.ChartOptions.ControlsColorScheme.chunkLoaderBackground,
            transparent: ColorHelperFunctions.MakeColor(chart.ChartOptions.ColorScheme.BackgroundColor, 0)
        }

        const { width: canvasWidth, height: canvasHeight } = this.context.canvas
        this.priceBorderWidth = priceBorderWidth

        const gradient = this.context.createLinearGradient(
            canvasWidth - priceBorderWidth,
            0,
            canvasWidth - width - priceBorderWidth,
            0
        )

        gradient.addColorStop(0, colors.light)
        gradient.addColorStop(1, colors.transparent)

        this.context.fillStyle = gradient
        this.context.fillRect(canvasWidth - width - priceBorderWidth, 0, width, Math.max(height, canvasHeight))

        this.context.font = '16px Roboto Flex'
        this.context.fillStyle = chart.ChartOptions.ControlsColorScheme.chunkLoaderText
        const text = t('charting.mainChart.loadingData')
        const textWidth = this.context.measureText(text).width
        const textX = Math.max(
            lastBarX + priceBorderWidth,
            canvasWidth - width - priceBorderWidth + (width - textWidth) / 2
        )
        const textY = Math.min(height / 1.75, canvasHeight / 2)

        const loaderRadius = 16
        let loaderX = textX + textWidth / 2
        const loaderY = (textY + loaderRadius + 5) / 1.5

        if (textX + textWidth > canvasWidth - priceBorderWidth) {
            // Draw text in two lines
            const firstLine = text.slice(0, Math.floor(text.length / 2))
            const secondLine = text.slice(Math.floor(text.length / 2))
            this.context.fillText(firstLine, textX, textY - 10)
            this.context.fillText(secondLine, textX, textY + 10)

            loaderX = textX + this.context.measureText(firstLine).width / 2
        } else {
            this.context.fillText(text, textX, textY)
        }

        this.loaderPosition = { x: loaderX, y: loaderY }
        this.loaderSettings.radius = loaderRadius

        this.startLoaderAnimation()
    }

    public startLoaderAnimation(): void {
        if (this.loaderFrameId !== null) return

        const animate = () => {
            const elapsedTime = performance.now() - this.animationStart
            const rotation = (elapsedTime / 1000) * 2 * Math.PI

            ;(this.mainChart?.ChartWindow as TChartWindow)?.Repaint()

            this.context.save()
            this.context.beginPath()
            this.context.rect(0, 0, this.context.canvas.width - this.priceBorderWidth, this.context.canvas.height)
            this.context.clip()

            this.context.beginPath()
            this.context.arc(
                this.loaderPosition.x,
                this.loaderPosition.y,
                this.loaderSettings.radius,
                rotation,
                rotation + Math.PI * 0.5
            )
            this.context.strokeStyle = this.loaderSettings.color
            this.context.lineWidth = this.loaderSettings.lineWidth
            this.context.stroke()

            this.context.restore()
            this.loaderFrameId = requestAnimationFrame(animate)
        }

        this.animationStart = performance.now()
        this.loaderFrameId = requestAnimationFrame(animate)
    }

    public stopLoaderAnimation(): void {
        if (this.loaderFrameId !== null) {
            cancelAnimationFrame(this.loaderFrameId)
            this.loaderFrameId = null
        }
    }
}

export class VisualLoaderCentered {
    private context: CanvasRenderingContext2D
    private animationStart = 0
    private loaderFrameId: number | null = null
    private settings = {
        radius: 32,
        color: '#2F80ED',
        lineWidth: 4
    }
    private layer: CanvasLayer

    constructor(layer: CanvasLayer) {
        this.layer = layer
        this.context = layer.canvas.getContext('2d')!
    }

    public startLoaderAnimation(backgroundColor = ''): void {
        if (this.loaderFrameId !== null) return

        const animate = () => {
            this.clearCanvas()

            const canvasWidth = this.context.canvas.width
            const canvasHeight = this.context.canvas.height

            if (backgroundColor) {
                this.context.fillStyle = backgroundColor
                this.context.fillRect(0, 0, canvasWidth, canvasHeight)
            }

            const elapsedTime = performance.now() - this.animationStart
            const rotation = (elapsedTime / 1000) * 2 * Math.PI

            this.context.save()
            this.context.beginPath()

            this.context.arc(
                (canvasWidth - 60) / 2 - this.settings.radius / 2,
                canvasHeight / 2 - this.settings.radius / 2,
                this.settings.radius,
                rotation,
                rotation + Math.PI * 0.5
            )
            this.context.strokeStyle = this.settings.color
            this.context.lineWidth = this.settings.lineWidth
            this.context.stroke()

            this.context.restore()
            this.loaderFrameId = requestAnimationFrame(animate)
        }

        this.animationStart = performance.now()
        this.loaderFrameId = requestAnimationFrame(animate)
    }

    public stopLoaderAnimation(): void {
        if (this.loaderFrameId !== null) {
            cancelAnimationFrame(this.loaderFrameId)
            this.loaderFrameId = null
        }
        this.clearCanvas()
    }

    private clearCanvas() {
        this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height)
    }

    private drawBackground() {
        const colors = {
            light: '#DCEBFF',
            dark: 'rgba(122, 132, 149, 0)',
            transparent: 'rgba(255, 255, 255, 0)'
        }

        const { width: canvasWidth, height: canvasHeight } = this.context.canvas

        const gradient = this.context.createLinearGradient(0, 0, canvasWidth, 0)
        gradient.addColorStop(0, colors.light)
        gradient.addColorStop(0.4, colors.transparent)
        gradient.addColorStop(1, colors.light)

        this.context.fillStyle = gradient
        this.context.fillRect(0, 0, canvasWidth, canvasHeight)

        // this.context.font = '16px Roboto Flex'
        // this.context.fillStyle = '#101828'

        // const text = 'Loading data...'
        // const textWidth = this.context.measureText(text).width
        // const textX = (canvasWidth - textWidth) / 2
        // const textY = canvasHeight / 2
        //
        // this.context.fillText(text, textX, textY)
    }
}
