import { initialData } from './constants'
import { makeAutoObservable } from 'mobx'
import { ProjectInfoData } from './types'

class ProjectInfoStore {
    data: ProjectInfoData

    constructor() {
        makeAutoObservable(this)

        this.data = initialData

        this.updateData = this.updateData.bind(this)
        this.resetStore = this.resetStore.bind(this)
    }

    updateData(updater: ((prevData: ProjectInfoData) => ProjectInfoData) | ProjectInfoData) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current info to get the updates
            this.data = {
                ...this.data,
                ...updater(this.data)
            }
        } else {
            // If updater is an object, directly apply the updates
            this.data = {
                ...this.data,
                ...updater
            }
        }
    }

    resetStore() {
        this.data = initialData
    }
}

export default new ProjectInfoStore()
