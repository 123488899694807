import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { TDataFormat } from '@fto/lib/ft_types/data/DataEnums'
import TDownloadableChunk from '@fto/lib/ft_types/data/chunks/DownloadableChunk/DownloadableChunk'
import { TBaseTickChunk } from '@fto/lib/ft_types/data/chunks/TickChunks/BaseTickChunk'
import { DownloadTaskQueue } from '@fto/lib/ft_types/data/data_downloading/DownloadTaskQueue'
import IDownloadController from './IDownloadController'
import MockDownloadController from '@fto/lib/mocks/MocksForDownloading/MockDownloadController'
import { TBarChunk } from '@fto/lib/ft_types/data/chunks/BarChunk'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TDataRecordWithDate } from '../DataClasses/TDataRecordWithDate'
import DownloadUtils from './DownloadUtils'
import { TRealTickChunk } from '../chunks/TickChunks/RealTickChunk'
import { TPseudoTickChunk } from '../chunks/TickChunks/PseudoTickChunk'

export class DownloadController implements IDownloadController {
    private _downloadTaskQueues: DownloadTaskQueue
    private _tickLoadingFormat: TDataFormat = TDataFormat.df_Binary
    private _barLoadingFormat: TDataFormat = TDataFormat.df_Binary

    private static instance: IDownloadController

    public static get Instance(): IDownloadController {
        if (!DownloadController.instance) {
            DownloadController.instance = new DownloadController()
        }

        return DownloadController.instance
    }

    public onLastTickTimeChanged(date: TDateTime): void {
        this._downloadTaskQueues.onLastTickTimeChanged(date)
    }

    public onVisibleRangeChanged(startVisibleDate: TDateTime, endVisibleDate: TDateTime): void {
        this._downloadTaskQueues.onVisibleRangeChanged(startVisibleDate, endVisibleDate)
    }

    public onTimeframeChanged(newTimeframe: number): void {
        this._downloadTaskQueues.onTimeframeChanged(newTimeframe)
    }

    public onProjectClosing() {
        this._downloadTaskQueues.onProjectClosing()
    }

    public onProjectLoading() {
        this._downloadTaskQueues.onProjectLoading()
    }

    public loadHistoryIfNecessary(chunk: TDownloadableChunk<TDataRecordWithDate>, forceLoading = false): void {
        DebugUtils.logTopic(
            ELoggingTopics.lt_ChunkLoading,
            'Downloading chunk',
            chunk.FirstDate,
            chunk.DName,
            chunk.DataDescriptor
        )

        this.throwErrorIfChunkDateIs_NotInRange(chunk)

        if (chunk instanceof TRealTickChunk) {
            chunk.LoadingDataFormat = this._tickLoadingFormat
            this._downloadTaskQueues.addTaskIfNecessary(chunk, forceLoading)
        } else if (chunk instanceof TPseudoTickChunk) {
            chunk.EnsureDataIsPresentOrDownloading()
        } else if (chunk instanceof TBarChunk) {
            //we are loading bar data
            this.checkIfDownloadable(chunk as TBarChunk)
            chunk.LoadingDataFormat = this._barLoadingFormat
            this._downloadTaskQueues.addTaskIfNecessary(chunk, forceLoading)
        } else {
            throw new StrangeError('Unknown chunk type')
        }
    }

    private checkIfDownloadable(chunk: TBarChunk) {
        if (!DownloadUtils.CanBeDownloadedDirectly(chunk)) {
            throw new StrangeError('Trying to download chunk that cannot be downloaded directly', chunk)
        }
    }

    private throwErrorIfChunkDateIs_NotInRange(chunk: TDownloadableChunk<TDataRecordWithDate>): void {
        const symbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(
            chunk.DataDescriptor.symbolName
        )

        if (
            !symbolData.isDateInAvailableRange(chunk.FirstDate) &&
            !symbolData.isDateInAvailableRange(chunk.LastPossibleDate)
        ) {
            throw new StrangeError('Trying to load data that is out of range')
        }
        //data is in range, everything is ok, carry on
    }

    public isAllDataLoaded(): boolean {
        return this._downloadTaskQueues.isEmpty()
    }
    public getLastLoadedChunk(): TBaseTickChunk | TBarChunk | null {
        return this._downloadTaskQueues.getLastLoadedChunk()
    }

    private constructor() {
        this._downloadTaskQueues = new DownloadTaskQueue()
    }

    public static ReplaceWithMocks(): void {
        DownloadController.instance = new MockDownloadController()
    }
}
