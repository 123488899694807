import { ChartControl, chartControlEvent } from '@fto/chart_components/ChartControl'
import { ChartControlParams } from './ChartControlParams'
import { TGdiPlusCanvas, TRoundRectType } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { IGPPen, IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { changeImageColor } from '@fto/lib/utils/common_utils'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'

export class ImageControl extends ChartControl {
    protected image!: HTMLImageElement
    protected ownerChart: TChartWindow
    protected isNeedDrawBorder: boolean = false

    constructor(
        ownerChart: TChartWindow,
        chartControlParams: ChartControlParams,
        image: HTMLImageElement,
        tooltip: string = ''
    ) {
        super(chartControlParams)
        this.ownerChart = ownerChart
        this.image = image
    }

    protected get borderPen(): IGPPen {
        if (this.isMouseInside()) {
            return new IGPPen(this.ownerChart.ChartOptions.ControlsColorScheme.buttonHoveredBorder)
        } else {
            return new IGPPen(this.ownerChart.ChartOptions.ControlsColorScheme.buttonBorder)
        }
    }

    protected get imageColor(): string {
        return this.ownerChart.ChartOptions.ControlsColorScheme.buttonText
    }

    protected get backgroundColorBrush(): IGPSolidBrush {
        return new IGPSolidBrush(this.ownerChart.ChartOptions.ControlsColorScheme.buttonBackground)
    }

    protected get backgroundHoveredColorBrush(): IGPSolidBrush {
        return new IGPSolidBrush(this.ownerChart.ChartOptions.ControlsColorScheme.buttonHoveredBackground)
    }

    protected get coloredImage(): HTMLCanvasElement {
        const dpr = window.devicePixelRatio || 1
        return changeImageColor(this.image, this.imageColor, this.image.width * dpr, this.image.height * dpr)
    }

    protected getColoredImage(): HTMLCanvasElement {
        const dpr = window.devicePixelRatio || 1
        return changeImageColor(this.image, this.imageColor, this.image.width * dpr, this.image.height * dpr)
    }

    disableBorder(): void {
        this.isNeedDrawBorder = false
    }

    enableBorder(): void {
        this.isNeedDrawBorder = true
    }

    draw(canvas: TGdiPlusCanvas): void {
        if (!this.IsVisible()) {
            return
        }

        const location = this.getLocation()

        const pen = this.isNeedDrawBorder ? this.borderPen : undefined
        const brush = this.isMouseInside() ? this.backgroundHoveredColorBrush : this.backgroundColorBrush

        canvas.strokeRect(location, TRoundRectType.BOTH, 5, true, brush, pen)

        if (this.isMouseInside() && this.tooltip) {
            this.showTooltip(canvas)
        }

        const centerX = location.Left + location.Width / 2
        const centerY = location.Top + location.Height / 2
        const imageX = centerX - this.image.width / 2
        const imageY = centerY - this.image.height / 2

        const coloredImage = this.getColoredImage()

        canvas.graphics.Context.drawImage(coloredImage, imageX, imageY, this.image.width, this.image.height)
    }

    changeImage(image: HTMLImageElement): void {
        this.image = image
    }

    onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        if (super.onMouseDown(event, sender)) {
            this.notify(chartControlEvent.BUTTON_PRESSED)
            return this
        }
        return null
    }
}
