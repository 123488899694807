import { FC, useCallback, useMemo } from 'react'
import { Button, Flex, Separator, Typography, HoverElement, useTheme } from '@fto/ui'

import styles from './index.module.scss'

import { Icon } from '@fto/icons'
import GeneralSettingsTab from './tabs/general'
import ProjectSettingsTab from './tabs/project'
import ChartSettingsTab from './tabs/chart'
import LeverageSettingsTab from './tabs/leverage'
import AllSettingsTab from './tabs/all'
import SymbolSettingsTab from './tabs/symbol'
import CommissionsSettingsTab from './tabs/commissions'
import { AVAILABLE_TABS, TABS_LIST } from './types/tabs.types'
import { useTranslation } from 'react-i18next'
import TAB_NAMES from '@fto/chart_components/ProjectInterface/constants/tabsNames'
import { TabsSettingsType } from '@fto/chart_components/ProjectInterface/types'

type Props = {
    tab: AVAILABLE_TABS
    openTab: (tabName: TabsSettingsType) => void
    closeTabs: () => void
}

const SettingsTab: FC<Props> = ({ tab = 'all', openTab, closeTabs }) => {
    const { t } = useTranslation()
    const { isLight } = useTheme()

    const activeTab = useMemo(() => {
        return TABS_LIST[tab as AVAILABLE_TABS]
    }, [tab])

    const openSettingsPage = useCallback(
        (page: AVAILABLE_TABS | null) => {
            if (page === null) {
                closeTabs()
            } else {
                openTab({ tabName: TAB_NAMES.SETTINGS, isOpen: true, settingsPage: page })
            }
        },
        [openTab, closeTabs]
    )

    const content = useMemo<{ [key: string]: JSX.Element }>(
        () => ({
            all: <AllSettingsTab tabsList={TABS_LIST} openSettingsPage={openSettingsPage} />,
            general: <GeneralSettingsTab />,
            project: <ProjectSettingsTab />,
            chart: <ChartSettingsTab />,
            leverage: <LeverageSettingsTab />,
            symbol: <SymbolSettingsTab />,
            commissions: <CommissionsSettingsTab />
        }),
        [tab]
    )

    const handleClose = useCallback(() => {
        closeTabs()
    }, [closeTabs])

    return (
        <Flex direction='column' className={styles.container} gap={16}>
            <Flex className={styles.header} justifyContent='space-between' alignItems='center'>
                <Typography type='h4-medium'>{t(activeTab.titleKey)}</Typography>
                <HoverElement onClick={handleClose}>
                    <Icon name='close' size={16} color='var(--color-gray-900)' />
                </HoverElement>
            </Flex>
            <Separator width='100%' className={styles.headerSeparator} />

            {'backTo' in activeTab && (
                <Button
                    label={t('global.back')}
                    onClick={() => openSettingsPage(activeTab.backTo as AVAILABLE_TABS)}
                    type={isLight ? 'tetriatry-gray' : 'tetriatry-white'}
                    size='compact'
                    shouldShowTooltipArrow
                    before={<Icon name='arrow-long-left' size={24} color='var(--color-gray-900)' />}
                    classNames={{ content: styles.backButtonContent }}
                />
            )}

            <Flex direction='column' className={styles.content}>
                {content[tab]}
            </Flex>
        </Flex>
    )
}

export default SettingsTab
