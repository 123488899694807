import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { PaintToolNames } from './PaintToolNames'
import { SupportedPaintTools, ToolPropertiesMap } from './SpecificTools/types'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { TLevelsList } from '@fto/lib/drawing_interface/GraphicObjects/TLevelsList'

export default class PaintToolDefaultUtils {
    constructor() {}

    static formatLevelsArrayToDialogableData(levelsStr: string) {
        const levels = new TLevelsList()
        levels.ImportFromStr(levelsStr)
        return levels.map((level) => ({
            id: level.id,
            value: level.value,
            text: level.text,
            isActive: level.isActive,
            style: level.pen.getPenStyleFromPattern(),
            color: level.pen.color,
            width: level.pen.width,
            opacity: level.pen.opacity
        }))
    }

    static getNewStorageDataForToolByTemplate<T extends SupportedPaintTools>(
        toolName: T,
        template: ToolPropertiesMap[T]
    ): { [key: string]: any } {
        console.log('getNewStorageDataForToolByTemplate', toolName, template)

        if (toolName == PaintToolNames.ptHLine) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptHLine]
            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style'
                },
                fText: {
                    key: 'fText',
                    value: temp.fText,
                    label: 'toolsModal.fields.text',
                    type: 'text',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptVLine) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptVLine]
            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style'
                },
                fText: {
                    key: 'fText',
                    value: temp.fText,
                    label: 'toolsModal.fields.text',
                    type: 'text',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptTrendLine) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptTrendLine]
            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style',
                    disabled: false
                },
                fText: {
                    key: 'fText',
                    value: temp.fText,
                    label: 'toolsModal.fields.text',
                    type: 'text',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptText) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptText]
            return {
                textStyle: {
                    key: 'textStyle',
                    value: {
                        color: temp.text.color,
                        size: temp.text.size,
                        style: temp.text.style,
                        weight: temp.text.weight
                    },
                    label: 'toolsModal.fields.textStyle',
                    type: 'textStyle',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptRay) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptRay]
            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style',
                    disabled: false
                },
                fText: {
                    key: 'fText',
                    value: temp.fText,
                    label: 'toolsModal.fields.text',
                    type: 'text',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptPolyline) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptPolyline]
            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptAndrewsPitchfork) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptAndrewsPitchfork]
            return {
                baseLineStyle: {
                    key: 'baseLineStyle',
                    value: new TLineStyle(temp.baseLine.color, temp.baseLine.style, temp.baseLine.width),
                    label: 'toolsModal.fields.pitchforkBaseLine',
                    type: 'style',
                    disabled: false
                },
                forkLine1: {
                    key: 'forkLine1',
                    value: new TLineStyle(temp.line1.color, temp.line1.style, temp.line1.width),
                    label: 'toolsModal.fields.pitchforkForkLine1',
                    type: 'style',
                    disabled: false,
                    withLabel: false
                },
                forkLine2: {
                    key: 'forkLine2',
                    value: new TLineStyle(temp.line2.color, temp.line2.style, temp.line2.width),
                    label: 'toolsModal.fields.pitchforkForkLine2',
                    type: 'style',
                    disabled: false,
                    withLabel: false
                },
                mediumLine: {
                    key: 'mediumLine',
                    value: new TLineStyle(temp.medianLine.color, temp.medianLine.style, temp.medianLine.width),
                    label: 'toolsModal.fields.pitchforkMediumLine',
                    type: 'style',
                    disabled: false,
                    withLabel: false
                }
            }
        }

        if (toolName == PaintToolNames.ptRectangle) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptRectangle]
            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style',
                    disabled: false
                },
                fillInsideColor: {
                    key: 'fillInsideColor',
                    value: {
                        color: ColorHelperFunctions.BasicColor(temp.fillingStyle.color),
                        isActive: temp.fShouldFillInside,
                        opacity: temp.fillingStyle.opacity
                    },
                    label: 'toolsModal.fields.background',
                    withOpacity: true,
                    type: 'fillColor',
                    isOptional: true
                },
                middleLineStyle: {
                    key: 'middleLineStyle',
                    value: temp.middleLineStyle,
                    label: 'toolsModal.fields.middleLine',
                    type: 'style',
                    isOptional: true,
                    isActive: temp.drawMiddleLine
                },
                extendLeft: {
                    key: 'extendLeft',
                    value: temp.extendLeft,
                    label: 'toolsModal.fields.extendLeft',
                    type: 'checkbox',
                    disabled: false,
                    isOptional: true
                },
                extendRight: {
                    key: 'extendRight',
                    value: temp.extendRight,
                    label: 'toolsModal.fields.extendRight',
                    type: 'checkbox',
                    disabled: false,
                    isOptional: true
                },
                fText: {
                    key: 'fText',
                    value: temp.fText,
                    label: 'toolsModal.fields.text',
                    type: 'text',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptEllipse) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptEllipse]
            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style',
                    disabled: false
                },
                fillInsideColor: {
                    key: 'fillInsideColor',
                    value: {
                        color: ColorHelperFunctions.BasicColor(temp.fillingStyle.color),
                        isActive: temp.fShouldFillInside,
                        opacity: temp.fillingStyle.opacity
                    },
                    label: 'background',
                    withOpacity: true,
                    type: 'fillColor',
                    isOptional: true
                }
            }
        }

        if (toolName == PaintToolNames.ptTriangle) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptTriangle]
            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style',
                    disabled: false
                },
                fillInsideColor: {
                    key: 'fillInsideColor',
                    value: {
                        color: ColorHelperFunctions.BasicColor(temp.fillingStyle.color),
                        isActive: temp.fShouldFillInside,
                        opacity: temp.fillingStyle.opacity
                    },
                    label: 'background',
                    withOpacity: true,
                    type: 'fillColor',
                    isOptional: true
                }
            }
        }

        if (
            toolName == PaintToolNames.ptRiskReward ||
            toolName == PaintToolNames.ptRiskRewardBuy ||
            toolName == PaintToolNames.ptRiskRewardSell
        ) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptRiskReward]
            return {
                profitFillColor: {
                    key: 'profitFillColor',
                    label: 'riskReward.profitFillColor',
                    type: 'fillColor',
                    widthOpacity: true,
                    isOptional: false,
                    disabled: false,
                    value: {
                        color: ColorHelperFunctions.BasicColor(temp.profit.fill.color),
                        opacity: temp.profit.fill.opacity
                    }
                },
                profitLineStyle: {
                    key: 'profitLineStyle',
                    label: 'toolsModal.fields.rrProfitLine',
                    type: 'style',
                    disabled: false,
                    value: new TLineStyle(temp.profit.line.color, temp.profit.line.style, temp.profit.line.width)
                },
                lossFillColor: {
                    key: 'lossFillColor',
                    label: 'riskReward.lossFillColor',
                    type: 'fillColor',
                    widthOpacity: true,
                    isOptional: false,
                    disabled: false,
                    value: {
                        color: ColorHelperFunctions.BasicColor(temp.loss.fill.color),
                        opacity: temp.loss.fill.opacity
                    }
                },
                lossLineStyle: {
                    key: 'lossLineStyle',
                    label: 'toolsModal.fields.rrLossLine',
                    type: 'style',
                    disabled: false,
                    value: new TLineStyle(temp.loss.line.color, temp.loss.line.style, temp.loss.line.width)
                },
                textStyle: {
                    key: 'textStyle',
                    label: 'toolsModal.fields.textStyle',
                    type: 'textStyle',
                    value: {
                        color: temp.text.color,
                        size: temp.text.size,
                        style: temp.text.style,
                        weight: temp.text.weight
                    }
                },
                riskPercent: {
                    key: 'riskPercent',
                    label: 'toolsModal.fields.riskPercent',
                    max: 100,
                    min: 0,
                    step: 1,
                    type: 'number',
                    value: temp.risk
                },
                calculateFrom: {
                    key: 'calculateFrom',
                    label: 'toolsModal.fields.calculateLotFrom',
                    type: 'calculateLot',
                    value: temp.calculateFrom
                },
                alwaysShowTextLabel: {
                    key: 'alwaysShowTextLabel',
                    value: temp.showText,
                    label: 'toolsModal.fields.alwaysShowText',
                    type: 'checkbox',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptFiboChannel) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptFiboChannel]

            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style',
                    disabled: false
                },
                levels: {
                    key: 'levels',
                    value: PaintToolDefaultUtils.formatLevelsArrayToDialogableData(temp.levels),
                    label: 'levels',
                    type: 'levels',
                    disabled: false
                },
                extendLeft: {
                    key: 'extendLeft',
                    value: temp.extendLeft,
                    label: 'Extend to the left',
                    type: 'checkbox',
                    disabled: false
                },
                extendRight: {
                    key: 'extendRight',
                    value: temp.extendRight,
                    label: 'Extend to the right',
                    type: 'checkbox',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptRegressionChannel) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptRegressionChannel]
            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style',
                    disabled: false
                },
                shouldShowRays: {
                    key: 'shouldShowRays',
                    value: temp.rays,
                    label: 'toolsModal.fields.rays',
                    type: 'checkbox'
                }
            }
        }

        if (toolName == PaintToolNames.ptFiboRetracement) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptFiboRetracement]

            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style',
                    disabled: false
                },
                levels: {
                    key: 'levels',
                    value: PaintToolDefaultUtils.formatLevelsArrayToDialogableData(temp.levels),
                    label: 'levels',
                    type: 'levels',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptFiboArc) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptFiboArc]

            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style',
                    disabled: false
                },
                levels: {
                    key: 'levels',
                    value: PaintToolDefaultUtils.formatLevelsArrayToDialogableData(temp.levels),
                    label: 'levels',
                    type: 'levels',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptFiboFan) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptFiboFan]

            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style',
                    disabled: false
                },
                levels: {
                    key: 'levels',
                    value: PaintToolDefaultUtils.formatLevelsArrayToDialogableData(temp.levels),
                    label: 'levels',
                    type: 'levels',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptFiboTimeZones) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptFiboTimeZones]

            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style',
                    disabled: false
                },
                levels: {
                    key: 'levels',
                    value: PaintToolDefaultUtils.formatLevelsArrayToDialogableData(temp.levels),
                    label: 'levels',
                    type: 'levels',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptFiboExtension) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptFiboExtension]

            return {
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.lineStyle.color, temp.lineStyle.style, temp.lineStyle.width),
                    label: 'toolsModal.fields.line',
                    type: 'style',
                    disabled: false
                },
                levels: {
                    key: 'levels',
                    value: PaintToolDefaultUtils.formatLevelsArrayToDialogableData(temp.levels),
                    label: 'levels',
                    type: 'levels',
                    disabled: false
                }
            }
        }

        if (toolName == PaintToolNames.ptExtPriceLabel) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptExtPriceLabel]
            return {
                textStyle: {
                    key: 'textStyle',
                    value: {
                        color: temp.text.color,
                        size: temp.text.size,
                        style: temp.text.style,
                        weight: temp.text.weight
                    },
                    label: 'toolsModal.fields.textStyle',
                    type: 'textStyle'
                },
                fillBackground: {
                    key: 'fillBackground',
                    value: {
                        color: ColorHelperFunctions.BasicColor(temp.fill.color),
                        opacity: temp.fill.opacity,
                        isActive: temp.fShouldFillInside
                    },
                    label: 'background',
                    withOpacity: true,
                    type: 'fillColor',
                    isOptional: true
                },
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.line.color, temp.line.style, temp.line.width),
                    label: 'toolsModal.fields.line',
                    type: 'style'
                }
            }
        }

        if (toolName == PaintToolNames.ptPriceLabelLeft || toolName == PaintToolNames.ptPriceLabelRight) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptPriceLabelLeft] // same as ptPriceLabelRight
            return {
                textStyle: {
                    key: 'textStyle',
                    value: {
                        color: temp.text.color,
                        size: temp.text.size,
                        style: temp.text.style,
                        weight: temp.text.weight
                    },
                    label: 'toolsModal.fields.textStyle',
                    type: 'textStyle'
                },
                fillBackground: {
                    key: 'fillBackground',
                    value: {
                        color: ColorHelperFunctions.BasicColor(temp.fill.color),
                        opacity: temp.fill.opacity,
                        isActive: temp.fShouldFillInside
                    },
                    label: 'background',
                    withOpacity: true,
                    type: 'fillColor',
                    isOptional: true
                },
                lineStyle: {
                    key: 'lineStyle',
                    value: new TLineStyle(temp.line.color, temp.line.style, temp.line.width),
                    label: 'toolsModal.fields.line',
                    type: 'style'
                }
            }
        }

        if (toolName == PaintToolNames.ptSign) {
            const temp = template as ToolPropertiesMap[PaintToolNames.ptSign]
            return {
                size: {
                    value: temp.size,
                    label: 'toolsModal.fields.size',
                    type: 'number',
                    min: 10,
                    max: 9999,
                    step: 1,
                    key: 'size'
                },
                fillInsideColor: {
                    key: 'fillInsideColor',
                    value: ColorHelperFunctions.BasicColor(temp.fill.color),
                    label: 'toolsModal.fields.color',
                    type: 'color',
                    disabled: false,
                    withOpacity: false
                },
                fText: {
                    key: 'fText',
                    value: temp.fText,
                    label: 'toolsModal.fields.text',
                    type: 'text',
                    disabled: false
                }
            }
        }

        return {}
    }
}
