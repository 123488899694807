import store from '@root/store'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DateUtils, TDateTime } from '../delphi_compatibility/DateUtils'
import CommonUtils from '../ft_types/common/BasicClasses/CommonUtils'
import MockerForServerSymbols from '../mocks/MocksForDownloading/MockerForServerSymbols'
import DataNotDownloadedYetError from '../ft_types/data/data_errors/DataUnavailableError'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import TEventsFunctionality from '@fto/lib/utils/EventsFunctionality'
import { ProjectEvents } from '@fto/lib/ProjectAdapter/ProjectEvents'

export class ServerSymbolInfo {
    public Broker: string
    public Symbol: string
    public SymbolCode: string
    public Category: string
    public Subscription: number
    public IsAvailable: boolean
    public StartDate: TDateTime
    public EndDate: TDateTime
    public BaseCurrency: string
    public QuoteCurrency: string

    constructor(data: any) {
        this.Broker = data.Broker
        this.Symbol = data.Symbol
        this.SymbolCode = data.SymbolCode
        this.Category = data.Category
        this.Subscription = data.Subscription
        this.IsAvailable = data.IsAvailable
        this.StartDate = DateUtils.FromString(data.Start)
        this.EndDate = DateUtils.FromString(data.End)
        this.BaseCurrency = data.BaseCurrency
        this.QuoteCurrency = data.QuoteCurrency
    }
}

class GlobalServerSymbolInfo {
    private static instance: GlobalServerSymbolInfo
    private symbols: ServerSymbolInfo[] = []
    private isSymbolsLoaded = false
    public Events: TEventsFunctionality = new TEventsFunctionality('GlobalServerSymbolInfo')

    public static get Instance(): GlobalServerSymbolInfo {
        if (!GlobalServerSymbolInfo.instance) {
            GlobalServerSymbolInfo.instance = new GlobalServerSymbolInfo()
        }
        return GlobalServerSymbolInfo.instance
    }

    private constructor() {}

    public isDataReady(): boolean {
        return this.isSymbolsLoaded
    }

    public initData(): void {
        this.prepareSymbols()
    }
    public getSymbolsCodeOrThrow(name: string): string {
        const code = this.symbols.find((sym) => sym.Symbol === name)

        if (!code) {
            throw new StrangeError(`GlobalServerSymbolInfo: Symbol ${name} not found`)
        }
        return code.SymbolCode
    }

    public isAmongAvailableSymbols(symbolName: string, brokerName: string): boolean {
        this.checkSymbolStore()

        const upperSymbolName = symbolName.toUpperCase()

        return this.symbols.some(
            (symbol) =>
                symbol.Symbol.toUpperCase() === upperSymbolName &&
                (brokerName === '' || symbol.Broker.toUpperCase() === brokerName.toUpperCase())
        )
    }

    private getExistingSymbolForConversion(
        symbol: string,
        brokerName: string
    ): { symbolName: string; isUSDBaseCurrency: boolean } {
        const firstCurrency = symbol.slice(0, 3)
        const secondCurrency = symbol.slice(3, 6)
        const currencies = [
            { currency: secondCurrency, isOriginalFirst: false },
            { currency: firstCurrency, isOriginalFirst: true }
        ]
        const usdVariants = ['USD', 'USDT', 'USDC']

        for (const { currency, isOriginalFirst } of currencies) {
            for (const usdVariant of usdVariants) {
                const possibleSymbols = [
                    { symbolName: `${currency}${usdVariant}`, isUSDBaseCurrency: false },
                    { symbolName: `${usdVariant}${currency}`, isUSDBaseCurrency: true }
                ]

                for (const { symbolName, isUSDBaseCurrency } of possibleSymbols) {
                    if (this.isAmongAvailableSymbols(symbolName, brokerName)) {
                        return { symbolName, isUSDBaseCurrency }
                    }
                }
            }
        }

        throw new StrangeError(`Not found symbol for conversion for ${symbol}`)
    }

    public getConversationSymbolIfNecessary(symbol: string): string {
        this.checkSymbolStore()
        const symbolInfo = this.symbols.find((s) => s.Symbol === symbol)
        if (symbolInfo) {
            if (symbolInfo.Category === 'Crosses' || symbolInfo.Category === 'Exotic') {
                return this.getExistingSymbolForConversion(symbol, symbolInfo.Broker).symbolName
            } else {
                return ''
            }
        } else {
            throw new StrangeError('GlobalServerSymbolInfo: symbol not found')
        }
    }

    private checkSymbolStore(): void {
        if (!this.isSymbolsLoaded) {
            throw new StrangeError('GlobalServerSymbolInfo: Data is not loaded')
        }
    }

    private prepareSymbols() {
        if (CommonUtils.IsInUnitTest) {
            this.symbols = []
            this.populateSymbolsWithMocks()
            this.Events.EmitEvent(ProjectEvents.SERVER_SYMBOL_INFO_LOADED)
            return
        }

        const result = store.getState().symbols

        if (result) {
            if (result.hasOwnProperty('loading')) {
                if (result.loading === 'succeeded') {
                    this.processSymbols(result.symbols)
                } else {
                    this.subscribeToSymbolsLoading()
                }
            } else {
                throw new StrangeError('GlobalServerSymbolInfo: invalid symbols state')
            }
        } else {
            throw new StrangeError('GlobalServerSymbolInfo: store.getState().symbols is undefined')
        }
    }

    private processSymbols(symbolsData: any[]) {
        if (symbolsData && symbolsData.length > 0) {
            for (const symbol of symbolsData) {
                this.makeSureNoDuplicates(symbol)
                this.symbols.push(new ServerSymbolInfo(symbol))
            }
            this.isSymbolsLoaded = true
            const range = this.getFullAvailableRangeByAllSymbols()
            GlobalTimezoneDSTController.Instance.updateDST_ByDateRange(range[0], range[1])
            this.Events.EmitEvent(ProjectEvents.SERVER_SYMBOL_INFO_LOADED)
        } else {
            throw new StrangeError('GlobalServerSymbolInfo: symbols data is empty or undefined')
        }
    }

    private subscribeToSymbolsLoading() {
        const unsubscribe = store.subscribe(() => {
            const result = store.getState().symbols
            if (result.loading === 'succeeded') {
                this.processSymbols(result.symbols)
                unsubscribe()
            } else if (result.loading === 'failed') {
                throw new StrangeError('GlobalServerSymbolInfo: symbols loading failed')
                unsubscribe()
            }
        })
    }

    private processNotSucceededStateForSymbols(result: any) {
        switch (result.loading) {
            case 'pending': {
                throw new DataNotDownloadedYetError(
                    'GlobalServerSymbolInfo: store.getState().symbols.loading is loading'
                )
            }
            case 'failed': {
                throw new StrangeError('GlobalServerSymbolInfo: store.getState().symbols.loading failed')
            }
            case 'idle': {
                throw new StrangeError('GlobalServerSymbolInfo: store.getState().symbols.loading is idle')
            }
            // No default
        }
    }

    private populateSymbolsWithMocks() {
        const mockData = MockerForServerSymbols.symbols

        for (const mockSymbol of mockData) {
            this.makeSureNoDuplicates(mockSymbol)
            this.symbols.push(new ServerSymbolInfo(mockSymbol))
        }

        this.isSymbolsLoaded = true
    }

    private makeSureNoDuplicates(newSymbol: any) {
        const newSymbolName = newSymbol.Symbol
        const existingSymbolsWithThisName = this.findSymbolByName_returnUndefinedIfNotFound(newSymbolName)
        if (existingSymbolsWithThisName) {
            throw new StrangeError(`GlobalServerSymbolInfo: Symbol ${newSymbolName} already exists`)
        }
    }

    public getSymbolsByDate(date: TDateTime): ServerSymbolInfo[] {
        this.checkSymbolStore()
        return this.symbols.filter((symbol) => {
            //TODO: Merge question to Oleksii Piun: should we use UTC date here?
            const startDate = symbol.StartDate
            const endDate = symbol.EndDate
            return DateUtils.MoreOrEqual(date, startDate) && DateUtils.LessOrEqual(date, endDate)
        })
    }

    public getSymbolCategory(symbol: string): string {
        this.checkSymbolStore()
        const symbolInfo = this.symbols.find((s) => s.Symbol === symbol)
        if (symbolInfo) {
            return symbolInfo.Category
        } else {
            throw new StrangeError('GlobalServerSymbolInfo: symbol not found')
        }
    }

    private findSymbolByName_returnUndefinedIfNotFound(symbolName: string): ServerSymbolInfo | undefined {
        return this.symbols.find((symb) => symb.Symbol === symbolName)
    }

    public getServerSymbolInfo(symbolName: string): ServerSymbolInfo {
        this.checkSymbolStore()
        const symbol = this.findSymbolByName_returnUndefinedIfNotFound(symbolName)
        if (!symbol) {
            throw new StrangeError(`GlobalServerSymbolInfo: Symbol ${symbolName} not found`)
        }
        return symbol
    }

    public getInitialDepositDate(): TDateTime {
        const fullRangeForAllSymbols = this.getFullAvailableRangeByAllSymbols()
        return fullRangeForAllSymbols[0]
    }

    private getFullAvailableRangeByAllSymbols(): [number, number] {
        this.checkSymbolStore()
        let minStartDate = Number.MAX_VALUE
        let maxEndDate = Number.MIN_VALUE
        for (const symbol of this.symbols) {
            if (DateUtils.LessOrEqual(symbol.StartDate, minStartDate)) {
                minStartDate = symbol.StartDate
            }
            if (DateUtils.MoreOrEqual(symbol.EndDate, maxEndDate)) {
                maxEndDate = symbol.EndDate
            }
        }
        return [minStartDate, maxEndDate]
    }
}

export default GlobalServerSymbolInfo
