import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { SupportedPaintTools, ToolPropertiesMap } from './SpecificTools/types'

/**
 * Stores and retrieves tool properties in local storage only(!).
 * If in future it will be needed to use different storage -
 * extend current class using dependency inversion.
 */
export class LastPaintToolStyleManager {
    private constructor() {}
    private static STORAGE_KEY_PREFIX = 'graphToolProperties_'

    static saveToolProperties<T extends SupportedPaintTools>(toolName: T, properties: ToolPropertiesMap[T]): void {
        const tools = this.loadAllToolProperties()
        tools[toolName] = {
            ...tools[toolName],
            ...properties
        }
        this.saveAllToolProperties(tools)
    }

    static loadToolProperties<T extends SupportedPaintTools>(toolName: T): ToolPropertiesMap[T] | null {
        const tools = this.loadAllToolProperties()
        return tools[toolName] || null
    }

    private static loadAllToolProperties(): Partial<ToolPropertiesMap> {
        const projectId = this.getProjectId()
        const toolsJson = localStorage.getItem(this.getStorageKey(projectId))
        return toolsJson ? JSON.parse(toolsJson) : {}
    }

    private static saveAllToolProperties(tools: Partial<ToolPropertiesMap>): void {
        const projectId = this.getProjectId()
        localStorage.setItem(this.getStorageKey(projectId), JSON.stringify(tools))
    }

    public static deleteToolProperties<T extends SupportedPaintTools>(toolName: T): void {
        const tools = this.loadAllToolProperties()
        delete tools[toolName]
        this.saveAllToolProperties(tools)
    }

    public static deleteAllToolProperties(): void {
        const projectId = this.getProjectId()
        localStorage.removeItem(this.getStorageKey(projectId))
    }

    private static getStorageKey(projectId: string): string {
        return `${this.STORAGE_KEY_PREFIX}${projectId}`
    }

    private static getProjectId(): string {
        const params = new URLSearchParams(window.location.search)
        const projectId = params.get('projectId')
        if (!projectId) {
            throw new StrangeError('Project id is not found in URL')
        }
        return projectId
    }
}
