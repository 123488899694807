import { OrderLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/OrderLevel'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { MarketValues, OrderValues, TakeprofitValues } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import { TPoint, TRect } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { ChartControl } from '@fto/chart_components/ChartControl'
import { TakeprofitCalculationStrategy } from '@fto/lib/OrderModalClasses/TakeProfitCalculationStrategies/TakeprofitCalculationStrategy'
import { MarketLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/MarketLevel'
import { getTextWidth } from '@fto/lib/globals/GlobalTextSizeCache'
import { formatCurrency } from '@fto/lib/utils/ordersUtils'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { PendingLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/PendingLevel'
import { showErrorToast, showSuccessToast } from '@root/utils/toasts'
import { t } from 'i18next'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class TakeProfitLevel extends OrderLevel {
    public takeProfitValues: TakeprofitValues | null = null
    private lastCorrectValue: TakeprofitValues | null = null

    draw(canvas: TGdiPlusCanvas): void {
        const dpr = window.devicePixelRatio || 1
        const isActive = this.IsCaptured() || this.isMouseInside() || this.isSelected

        const levels = this.owner.orderLevels.get(this.chartWindow)

        if (!levels) {
            throw new StrangeError('Levels are not defined for TakeProfitLevel')
        }

        const openPriceOrderLevel = levels.openPriceOrderLevel

        if (!openPriceOrderLevel) {
            throw new StrangeError('OpenPriceOrderLevel is not defined for TakeProfitLevel')
        }

        if (
            this.owner.tpos.TakeProfit === 0 &&
            (openPriceOrderLevel.isSelected ||
                openPriceOrderLevel.IsCaptured() ||
                openPriceOrderLevel.isMouseInside()) &&
            !this.IsCaptured()
        ) {
            this.tooltip = null
            this.drawEmptyState(canvas)
            return
        }

        const ctx = canvas.graphics.Context
        const { Top } = this.getLocation()
        const baseColor = this.isValid ? this.chartWindow.ChartOptions.ColorScheme.TakeProfitColor : this.invalidColor
        const labelText = 'TP'
        let valueText = Number(this.owner.getTakeProfitUSD().toFixed(2))
        let pointsText = `${formatCurrency(this.owner.getTakeProfitPoints(), '', 0)} points`

        if ((this.IsCaptured() || openPriceOrderLevel.IsCaptured()) && this.takeProfitValues) {
            let currentOpenPrice: number | undefined = undefined

            if (openPriceOrderLevel instanceof PendingLevel) {
                if (openPriceOrderLevel.currentOpenPrice) {
                    currentOpenPrice = this.owner.tpos.OpenPrice
                }
            }

            valueText = Number(this.owner.getTakeProfitUSD(this.takeProfitValues.price, currentOpenPrice!))
            pointsText = `${formatCurrency(this.takeProfitValues.points, '', 0)} points`
        }

        const ticketText = `#${this.owner.tpos.ticket}`

        const fontSize = 12 * dpr
        const boxHeight = 16 * dpr

        const font = `${fontSize}px Roboto Flex`

        this.drawCommonElements(
            canvas,
            this.getXCoordinateToDrawLevel(ctx),
            Top,
            labelText,
            formatCurrency(Number(valueText)),
            pointsText,
            ticketText,
            isActive,
            baseColor,
            boxHeight,
            font
        )

        const percentFromBalance =
            ((this.takeProfitValues?.usd || this.owner.getTakeProfitUSD()) * 100) /
            GlobalProcessingCore.ProcessingCore.Balance

        this.tooltip = `Take Profit +${percentFromBalance.toFixed(2)}%`
    }

    public recalculateLocationByY(price: number, shouldValidate: boolean = true, atPrice: number | null = null): void {
        super.recalculateLocationByY(price, shouldValidate)

        let newValues: TakeprofitValues | null = null
        const mainChart = this.chartWindow.MainChart

        if (this.calculationStrategy && this.calculationStrategy instanceof TakeprofitCalculationStrategy) {
            const takeProfitValues: TakeprofitValues = new TakeprofitValues()
            takeProfitValues.price = price
            takeProfitValues.points = this.owner.getTakeProfitPoints()

            const orderValues: OrderValues = new OrderValues()
            orderValues.lot = this.owner.tpos.lot
            orderValues.atPrice = atPrice || this.owner.tpos.OpenPrice

            const marketValues = new MarketValues()
            marketValues.minimumDistanceToPrice = mainChart.marketValues.minimumDistanceToPrice
            marketValues.minimumPriceChange = mainChart.marketValues.minimumPriceChange
            marketValues.pointValueForOneStandardLot = mainChart.marketValues.pointValueForOneStandardLot
            marketValues.equity = mainChart.marketValues.equity

            if (
                [TTradePositionType.tp_Buy, TTradePositionType.tp_BuyStop, TTradePositionType.tp_BuyLimit].includes(
                    this.owner.PosType
                )
            ) {
                marketValues.ask = atPrice || this.owner.tpos.OpenPrice
            } else {
                marketValues.bid = atPrice || this.owner.tpos.OpenPrice
            }

            // values that should be shown when dragging the stoploss line
            newValues = this.calculationStrategy.onChangeTakeprofitPrice(takeProfitValues, marketValues, orderValues)

            if (newValues) {
                for (const [chartWindow, levels] of this.owner.orderLevels) {
                    if (
                        levels.takeProfitOrderLevel &&
                        this.type === 'Takeprofit' &&
                        levels.takeProfitOrderLevel instanceof TakeProfitLevel
                    ) {
                        levels.takeProfitOrderLevel.takeProfitValues = newValues
                    }
                }
            }
        }
    }

    onMouseDown(event: MouseEvent, sender: TMainChart): ChartControl | null {
        this.updLastCorrectValue()
        return super.onMouseDown(event, sender)
    }

    public updLastCorrectValue(): void {
        this.lastCorrectValue = this.takeProfitValues
            ? { ...this.takeProfitValues }
            : {
                  price: this.owner.tpos.TakeProfit,
                  points: this.owner.getTakeProfitPoints(),
                  usd: this.owner.getTakeProfitUSD(),
                  percent: 0
              }
    }

    close(): void {
        this.owner.resetTakeProfit()
    }

    drawEmptyState(canvas: TGdiPlusCanvas): void {
        // paint only drag dots and label under the line if owner has buy pos and над the line if owner has sell pos
        const ctx = canvas.graphics.Context
        const levels = this.owner.orderLevels.get(this.chartWindow)
        if (!levels) {
            return
        }
        const { Left, Top, Bottom } = (levels.openPriceOrderLevel as MarketLevel).infoBoxLocation
        const dpr = window.devicePixelRatio || 1

        const labelText = 'TP'
        const baseColor = this.chartWindow.ChartOptions.ColorScheme.TakeProfitColor
        const fontSize = 12 * dpr
        const boxHeight = Bottom - Top
        const borderRadius = boxHeight / 8
        const boxPadding = 8 * dpr

        const font = `${fontSize}px Roboto Flex`
        ctx.font = font

        // Measure text widths
        const labelTextWidth = getTextWidth(ctx, labelText, font)
        const maxLabelTextWidth = Math.max(labelTextWidth, getTextWidth(ctx, 'SL', font)) // check if SL label is wider

        // Calculate box width
        const boxWidth = maxLabelTextWidth + boxPadding * 2
        let boxX = Left - boxWidth - 2 * dpr // 2*dpr is a padding between the boxes
        if (this.owner.tpos.StopLoss === 0) {
            boxX -= 2 * dpr + boxWidth
        }

        // Draw rounded rect
        this.drawRoundedRect(ctx, boxX, Top, boxWidth, boxHeight, borderRadius, baseColor)

        // Draw dots for drag
        this.drawDragDots(ctx, boxX + 2.5 * dpr, Top + boxHeight / 2 - 3.5 * dpr, baseColor, false)

        // Draw title inside the box
        const labelX = boxX + 10 * dpr
        const labelY = Top + boxHeight / 2

        this.drawText(ctx, labelText, labelX, labelY, baseColor, font, false)

        this.infoBoxLocation = new TRect(boxX, Top, boxX + boxWidth, Top + boxHeight)

        this.setLocation(this.infoBoxLocation)
    }

    changePoints(newPoints: number, atPrice: number | null): void {
        const mainChart = this.chartWindow.MainChart

        if (this.calculationStrategy && this.calculationStrategy instanceof TakeprofitCalculationStrategy) {
            const takeProfitValues: TakeprofitValues = new TakeprofitValues()
            takeProfitValues.points = newPoints

            const orderValues: OrderValues = new OrderValues()
            orderValues.lot = this.owner.tpos.lot
            orderValues.atPrice = atPrice || this.owner.tpos.OpenPrice
            const marketValues = new MarketValues()
            marketValues.minimumDistanceToPrice = mainChart.marketValues.minimumDistanceToPrice
            marketValues.minimumPriceChange = mainChart.marketValues.minimumPriceChange
            marketValues.pointValueForOneStandardLot = mainChart.marketValues.pointValueForOneStandardLot
            marketValues.equity = mainChart.marketValues.equity

            if (
                [TTradePositionType.tp_Buy, TTradePositionType.tp_BuyStop, TTradePositionType.tp_BuyLimit].includes(
                    this.owner.PosType
                )
            ) {
                marketValues.ask = atPrice || this.owner.tpos.OpenPrice
            } else {
                marketValues.bid = atPrice || this.owner.tpos.OpenPrice
            }

            const newValues = this.calculationStrategy.onChangeTakeprofitPoints(
                takeProfitValues,
                marketValues,
                orderValues
            )

            if (newValues) {
                this.takeProfitValues = newValues
                this.owner.tpos.TakeProfit = newValues.price
            }
        }

        this.recalculateLocationByY(this.owner.tpos.TakeProfit, true, atPrice)
    }

    onMouseUp(event: MouseEvent, sender: TMainChart): void {
        const dpr = window.devicePixelRatio || 1
        const x = event.offsetX * dpr
        const y = event.offsetY * dpr
        const isCurrentControl = this.isMouseInsideInfoBox(new TPoint(x, y)) || this.isPointInside(x, y)

        if (this.isValid) {
            if (this.takeProfitValues && this.IsCaptured()) {
                if (this.takeProfitValues.price === this.owner.tpos.StopLoss) {
                    return
                }

                if (isCurrentControl && this.lastCorrectValue?.price !== this.takeProfitValues.price) {
                    const decimals = this.owner.symbol?.symbolInfo.decimals
                    showSuccessToast({
                        title: t('orders.modal.toasts.takeProfitUpdated', {
                            value: this.takeProfitValues.price.toFixed(decimals || 5)
                        })
                    })
                }
                this.owner.tpos.TakeProfit = this.takeProfitValues.price
                this.recalculateLocationByY(this.owner.tpos.TakeProfit)
            }
        } else {
            this.processNotValidLevel()
            this.isValid = true
            this.recalculateLocationByY(this.owner.tpos.TakeProfit, false)
        }

        GlobalProcessingCore.ProcessingCore.refreshOrdersInTerminalAndOrderModal()
    }

    public processNotValidLevel(): void {
        if (this.isValid || this.owner.tpos.TakeProfit === 0) return

        this.takeProfitValues = this.lastCorrectValue
        this.owner.tpos.TakeProfit = this.lastCorrectValue?.price || 0

        const validBuyTypes = [TTradePositionType.tp_Buy, TTradePositionType.tp_BuyLimit, TTradePositionType.tp_BuyStop]

        const validSellTypes = [
            TTradePositionType.tp_Sell,
            TTradePositionType.tp_SellLimit,
            TTradePositionType.tp_SellStop
        ]

        if (validBuyTypes.includes(this.owner.tpos.PosType)) {
            showErrorToast({
                title: t('orders.modal.toasts.invalidTakeProfitBuyTitle'),
                message: t('orders.modal.toasts.invalidTakeProfitBuyText')
            })
        } else if (validSellTypes.includes(this.owner.tpos.PosType)) {
            showErrorToast({
                title: t('orders.modal.toasts.invalidTakeProfitSellTitle'),
                message: t('orders.modal.toasts.invalidTakeProfitSellText')
            })
        }
    }
}
