import { useState, useEffect, RefObject } from 'react'

const useAdjustContextMenuPosition = (
    open: boolean,
    anchorX: number,
    anchorY: number,
    contextMenuRef: RefObject<HTMLDivElement>,
    direction: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' = 'bottom-right'
) => {
    const [adjustedPosition, setAdjustedPosition] = useState<{ x: number; y: number }>({ x: anchorX, y: anchorY })

    useEffect(() => {
        if (!open || !contextMenuRef.current) return

        const updatePosition = () => {
            setAdjustedPosition(getCoordinatesForDirection(anchorX, anchorY, direction, contextMenuRef))
        }

        // Initial adjustment
        updatePosition()

        // Observe size changes
        const resizeObserver = new ResizeObserver(() => {
            updatePosition()
        })
        resizeObserver.observe(contextMenuRef.current)

        return () => {
            resizeObserver.disconnect()
        }
    }, [open, anchorX, anchorY, direction, contextMenuRef])

    return adjustedPosition
}

const EXTRA_OFFSET = 10

const getCoordinatesForDirection = (
    anchorX: number,
    anchorY: number,
    direction: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right',
    contextMenuRef: RefObject<HTMLDivElement>
) => {
    const element = contextMenuRef.current as HTMLElement
    const { offsetWidth, offsetHeight } = element
    const { innerWidth, innerHeight } = window

    let x = direction === 'top-left' || direction === 'bottom-left' ? anchorX - offsetWidth : anchorX
    let y = direction === 'top-left' || direction === 'top-right' ? anchorY - offsetHeight : anchorY

    if (x + offsetWidth > innerWidth) {
        x = innerWidth - offsetWidth - EXTRA_OFFSET
    }

    if (y + offsetHeight > innerHeight) {
        y = innerHeight - offsetHeight - EXTRA_OFFSET
    }

    if (y < 0) {
        y = 0
    }

    return { x, y }
}

export default useAdjustContextMenuPosition
