import { t } from 'i18next'
import { TIndexBuffer } from '../api/IIndicatorApi'

import { IndicatorImplementation } from '../api/IndicatorImplementation'
import { TOptionType, TOptValue_number, TOutputWindow } from '@fto/lib/extension_modules/common/CommonTypes'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { INT_MAX } from '../../common/CommonConstantsForExternalModules'

export default class Aroon extends IndicatorImplementation {
    public period: TOptValue_number = new TOptValue_number(14)

    // Buffers
    public UpLine!: TIndexBuffer
    public DownLine!: TIndexBuffer

    Init(): void {
        this.api.IndicatorShortName(t('indicators.aroon'))
        this.api.SetOutputWindow(TOutputWindow.ow_SeparateWindow)
        this.api.SetFixedMinMaxValues(0, 100)
        this.api.SetEmptyValue(-1)
        this.api.AddLevel(30, TPenStyle.psDot, 1, '#D3D3D3', 1)
        this.api.AddLevel(70, TPenStyle.psDot, 1, '#D3D3D3', 1)

        this.api.AddSeparator('Common')

        this.api.RegOption(t('indicatorModal.general.generalFields.period'), TOptionType.ot_Integer, this.period)
        this.api.SetOptionRange(t('indicatorModal.general.generalFields.period'), 1, INT_MAX)

        this.UpLine = this.api.CreateIndexBuffer()
        this.DownLine = this.api.CreateIndexBuffer()

        this.api.IndicatorBuffers(2)
        this.api.SetIndexBuffer(0, this.UpLine)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#cccc43')
        this.api.SetIndexLabel(0, t('indicatorModal.aroon.fields.aroonUp'))
        this.api.SetIndexBuffer(1, this.DownLine)
        this.api.SetIndexStyle(1, TDrawStyle.ds_Line, TPenStyle.psSolid, 1, '#1E90FF')
        this.api.SetIndexLabel(1, t('indicatorModal.aroon.fields.aroonDown'))
    }

    Calculate(index: number): void {
        if (index + this.period.value >= this.api.Bars()) {
            this.DownLine.setValue(index, -1)
            this.UpLine.setValue(index, -1)
            return
        }

        let max = -10000000
        let min = 10000000
        let nHigh = -1
        let nLow = -1

        for (let i = index; i <= index + this.period.value; i++) {
            const high = this.api.High(i)
            const low = this.api.Low(i)

            if (high > max) {
                max = high
                nHigh = i
            }
            if (low < min) {
                min = low
                nLow = i
            }
        }

        this.UpLine.setValue(index, (100.0 * (this.period.value - (nHigh - index))) / this.period.value)
        this.DownLine.setValue(index, (100.0 * (this.period.value - (nLow - index))) / this.period.value)
    }
}
