import { APIHelperFunctions } from '@fto/lib/extension_modules/common/ApiHelperFunctions'
import { E_MAType, TPriceType } from '@fto/lib/extension_modules/common/CommonTypes'
import IFMBarsArray from '@fto/lib/ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import { GlobalMovingAverageCache, WmaInfo } from '@fto/lib/globals/GlobalMovingAverageCache'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import StrangeError from '../common_errors/StrangeError'

export default class CommonMACalculations {
    public static calculateMA(
        symbol: string,
        timeframe: number,
        period: number,
        ma_shift: number,
        maMethod: E_MAType,
        applyTo: TPriceType,
        index: number,
        prev = 0
    ): number {
        const bars = GlobalSymbolList.SymbolList.GetOrCreateBarArray(symbol, timeframe)

        let i: number
        let k: number
        switch (maMethod) {
            case E_MAType.ma_SMA: {
                // SMA

                if (index + ma_shift + period > bars.LastItemInTestingIndex || index + ma_shift < 0) {
                    return 0
                }

                let sum = 0
                const prevSum = GlobalMovingAverageCache.getInstance().getPrevSum(
                    symbol,
                    timeframe,
                    index,
                    ma_shift,
                    period,
                    applyTo,
                    bars.LastItemInTestingIndex
                )

                if (prevSum) {
                    sum =
                        prevSum -
                        APIHelperFunctions.GetPrice(index + ma_shift + period, applyTo, bars) +
                        APIHelperFunctions.GetPrice(index + ma_shift, applyTo, bars)
                } else {
                    for (let local_i = index + ma_shift; local_i < index + ma_shift + period; local_i++) {
                        sum += APIHelperFunctions.GetPrice(local_i, applyTo, bars)
                    }
                }

                GlobalMovingAverageCache.getInstance().saveCurrentSum(
                    symbol,
                    timeframe,
                    index,
                    ma_shift,
                    period,
                    applyTo,
                    bars.LastItemInTestingIndex,
                    sum
                )

                return sum / period
            }
            case E_MAType.ma_EMA: {
                // EMA
                k = 2 / (period + 1)
                i = index + ma_shift
                if (i >= bars.LastItemInTestingIndex + 1 || i < 0) {
                    return 0
                } else {
                    if (prev === 0) {
                        return APIHelperFunctions.GetPrice(i, applyTo, bars)
                    } else {
                        return prev + k * (APIHelperFunctions.GetPrice(i, applyTo, bars) - prev)
                    }
                }
            }
            case E_MAType.ma_SMMA: {
                // SSMA
                if (index + ma_shift + period > bars.LastItemInTestingIndex || index + ma_shift < 0) {
                    return 0
                } else {
                    if (prev === 0) {
                        // Recursive call with ma_SMA to initialize SSMA
                        return this.calculateMA(
                            symbol,
                            timeframe,
                            period,
                            ma_shift,
                            E_MAType.ma_SMA,
                            applyTo,
                            index,
                            prev
                        )
                    } else {
                        return (
                            (prev * (period - 1) + APIHelperFunctions.GetPrice(index + ma_shift, applyTo, bars)) /
                            period
                        )
                    }
                }
            }
            case E_MAType.ma_LWMA: {
                // WMA
                if (index + ma_shift + period > bars.LastItemInTestingIndex || index + ma_shift < 0) {
                    return 0
                }

                const currWeight = (period / 2) * (1 + period)
                if (index === 0) {
                    return this.getWmaValueForCurrentTestingIndex(bars, ma_shift, period, applyTo, currWeight)
                } else {
                    return this.getWmaValue(bars, index, ma_shift, period, applyTo, currWeight)
                }
            }
            default: {
                throw new StrangeError('Unsupported MA type')
            }
        }
    }

    private static calcWMASum(
        bars: IFMBarsArray,
        startIndex: number,
        period: number,
        shift: number,
        applyTo: TPriceType
    ): number {
        let sum = 0
        for (let i = 0; i < period; i++) {
            sum += APIHelperFunctions.GetPrice(startIndex + i + shift, applyTo, bars) * (period - i)
        }
        return sum
    }

    private static getWmaValueForCurrentTestingIndex(
        bars: IFMBarsArray,
        shift: number,
        period: number,
        applyTo: TPriceType,
        weightForPeriod: number
    ): number {
        const currentValueInCache = GlobalMovingAverageCache.getInstance().getCurrWmaValue(
            bars.DataDescriptor.symbolName,
            bars.DataDescriptor.timeframe,
            0,
            shift,
            period,
            applyTo,
            bars.LastItemInTestingIndex
        )

        let sum: number
        const lastWeightedPrice: number = APIHelperFunctions.GetPrice(0, applyTo, bars) * period

        if (currentValueInCache) {
            sum = currentValueInCache.value - currentValueInCache.lastWeightedPrice + lastWeightedPrice
        } else {
            sum = this.calcWMASum(bars, 0, period, shift, applyTo)
        }

        const wmaInfo: WmaInfo = {
            value: sum,
            lastWeightedPrice: lastWeightedPrice
        }
        GlobalMovingAverageCache.getInstance().saveWmaSum(
            bars.DataDescriptor.symbolName,
            bars.DataDescriptor.timeframe,
            0,
            shift,
            period,
            applyTo,
            bars.LastItemInTestingIndex,
            wmaInfo
        )

        return sum / weightForPeriod
    }

    private static getWmaValue(
        bars: IFMBarsArray,
        index: number,
        shift: number,
        period: number,
        applyTo: TPriceType,
        weightForPeriod: number
    ): number {
        const currentValueInCache = GlobalMovingAverageCache.getInstance().getCurrWmaValue(
            bars.DataDescriptor.symbolName,
            bars.DataDescriptor.timeframe,
            index,
            shift,
            period,
            applyTo,
            bars.LastItemInTestingIndex
        )

        if (currentValueInCache) {
            return currentValueInCache.value / weightForPeriod
        } else {
            const wmaInfo: WmaInfo = {
                value: this.calcWMASum(bars, index, period, shift, applyTo),
                lastWeightedPrice: APIHelperFunctions.GetPrice(index, applyTo, bars) * period
            }
            GlobalMovingAverageCache.getInstance().saveWmaSum(
                bars.DataDescriptor.symbolName,
                bars.DataDescriptor.timeframe,
                index,
                shift,
                period,
                applyTo,
                bars.LastItemInTestingIndex,
                wmaInfo
            )
            return wmaInfo.value / weightForPeriod
        }
    }
}
