import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { addModal, Flex, ModalTabs } from '@fto/ui'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'

import styles from './index.module.scss'
import CanvasSettings from './SettingsTabs/CanvasSettings'
import ViewsSettings from './SettingsTabs/ViewsSettings'
import { SettingsTabRef } from './types'
import { observer } from 'mobx-react-lite'
import globalChartsStore from '@fto/lib/store/globalChartsStore'
import { TemplateManager } from '@root/pages/ChartPage/components/TemplateManager'
import { GlobalTemplatesManager } from '@fto/lib/globals/TemplatesManager/GlobalTemplatesManager'

import CanvasConfig from './SettingsTabs/CanvasSettings/config'
import ViewsConfig from './SettingsTabs/ViewsSettings/config'

import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { TabType } from '@fto/ui/lib/components/ModalTabs/types'
import { parseNestedJSON } from '@fto/lib/utils/common_utils'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'
import { DONT_SHOW_APPLY_TO_ALL_WARNING } from '@root/constants/localStorageKeys'
import { usePersistedState } from '@root/hooks/usePersistedState'
import { getSeparatedOptionsStates, setOptionState } from '../../components/utils/OptionStates'

const TABS: TabType[] = [
    { value: 'canvas', localeKey: 'settings.chart.tabs.canvas' },
    { value: 'views', localeKey: 'settings.chart.tabs.views' },
]

const ChartSettingsTab = observer(() => {
    const { data } = globalChartsStore
    const chart = data.activeChart
    if (!chart) return <></>

    const { userId } = useAppSelector($getUser)

    const globalOptions = GlobalOptions.Options
    const [activeTab, setActiveTab] = useState(TABS[0].value)

    const [templates, setTemplates] = useState<string[]>([])
    const [selectedTemplate, setSelectedTemplate] = useState<string>('')
    const [applyToAll, setApplyToAll] = useState(false)

    const localStorageKey = useMemo(() => {
        const params = new URLSearchParams(window.location.search)
        const projectId = params.get('projectId')

        return `${userId}_${projectId}_${DONT_SHOW_APPLY_TO_ALL_WARNING}`
    }, [userId])

    const [dontShowApplyToAllWarning, setDontShowApplyToAllWarning] = usePersistedState(localStorageKey, false)

    useEffect(() => {
        if (!applyToAll) return
        if (dontShowApplyToAllWarning) return

        addModal(MODAL_NAMES.chart.applyToAllWarning, {
            withDontShowAgainCheckbox: true,
            onCancel: () => {
                setApplyToAll(false)
            },
            onApply: (dontShowAgain: boolean) => {
                setDontShowApplyToAllWarning(dontShowAgain)
            }
        })
    }, [applyToAll])

    const canvasRef = useRef<SettingsTabRef>(null)
    const viewsRef = useRef<SettingsTabRef>(null)
    const generalRef = useRef<SettingsTabRef>(null)

    const content = useMemo<{ [key: string]: JSX.Element }>(
        () => ({
            canvas: (
                <CanvasSettings
                    chart={chart}
                    applyToAll={applyToAll}
                    ref={canvasRef}
                />
            ),
            views: (
                <ViewsSettings
                    chart={chart}
                    applyToAll={applyToAll}
                    ref={viewsRef}
                />
            ),
        }),
        [chart, globalOptions, applyToAll]
    )

    const activeTabContent = useMemo(() => {
        return content[activeTab as string]
    }, [content, activeTab])

    const updateTemplates = useCallback(() => {
        setTemplates(GlobalTemplatesManager.Instance.getChartTemplatesNames())
    }, [])

    const loadTemplateIntoTabs = useCallback((template: { [key: string]: any }, shouldUpdateConfig = true) => {
        canvasRef.current && canvasRef.current.loadOptionsSetting(template.canvas, shouldUpdateConfig)
        viewsRef.current && viewsRef.current.loadOptionsSetting(template.views, shouldUpdateConfig)
        generalRef.current && generalRef.current.loadOptionsSetting(template.general, shouldUpdateConfig)
    }, [])

    const selectTemplateForChart = useCallback((chart: TChartWindow, template: { [key: string]: any }) => {
        for (const optionKey in template.canvas) {
            setOptionState(chart, CanvasConfig[optionKey], template.canvas[optionKey])
        }

        for (const optionKey in template.views) {
            setOptionState(chart, ViewsConfig[optionKey], template.views[optionKey])
        }

        loadTemplateIntoTabs(template, false)

        chart.Repaint()
    }, [])

    const selectTemplate = useCallback(
        (template: { [key: string]: any }) => {
            const chartsToApply = applyToAll ? GlobalChartsController.Instance.getAllCharts() : [chart]
            chartsToApply.forEach((chart) => selectTemplateForChart(chart, template))
        },
        [chart, applyToAll, selectTemplateForChart]
    )

    const selectTemplateByName = useCallback(
        (templateName: string) => {
            let template = GlobalTemplatesManager.Instance.getChartTemplate(templateName)
            if (!template) throw new StrangeError('Template not found')

            template = parseNestedJSON(JSON.parse(template.templateJSON))

            if (!template) throw new StrangeError('Template format is incorrect')

            selectTemplate(template)
            setSelectedTemplate(templateName)
        },
        [chart, selectTemplate]
    )

    const calcCurrentTemplate = useCallback((): { [key: string]: any } => {
        return {
            canvas: getSeparatedOptionsStates(CanvasConfig, chart),
            views: getSeparatedOptionsStates(ViewsConfig, chart)
        }
    }, [chart])

    const saveTemplate = useCallback(
        async (templateName: string, isRewrite: boolean = false) => {
            const template = calcCurrentTemplate()
            await GlobalTemplatesManager.Instance.addChartTemplate(templateName, JSON.stringify(template), isRewrite)

            updateTemplates()
            setSelectedTemplate(templateName)
        },
        [updateTemplates, calcCurrentTemplate]
    )

    const deleteTemplate = useCallback(
        async (templateName: string) => {
            await GlobalTemplatesManager.Instance.removeChartTemplate(templateName)
            updateTemplates()
        },
        [updateTemplates]
    )

    const applyDefault = useCallback(() => {
        chart.ChartOptions.reset()
        GlobalOptions.Options.reset()

        const template = calcCurrentTemplate()
        selectTemplate(template)

        chart.Repaint()
        setSelectedTemplate('')
    }, [chart, selectTemplate])

    useEffect(() => {
        const template = calcCurrentTemplate()
        loadTemplateIntoTabs(template, false)
        setSelectedTemplate('')
    }, [chart, loadTemplateIntoTabs])

    useEffect(() => {
        updateTemplates()
    }, [])

    return (
        <Flex direction='column' className={styles.container} gap={12}>
            <Flex className={styles.tabsWrapper} alignItems='center'>
                <ModalTabs tabsList={TABS} handleTabChange={setActiveTab} activeTab={activeTab} />
            </Flex>
            <Flex className={styles.settingsWrapper}>{activeTabContent}</Flex>
            <Flex className={styles.templateManagerContainer} block>
                <TemplateManager
                    changeMainModalVisibleState={() => {}}
                    onSaveTemplate={saveTemplate}
                    onSelectTemplate={selectTemplateByName}
                    onDeleteTemplate={deleteTemplate}
                    onApplyDefault={applyDefault}
                    templates={templates}
                    selectedTemplate={selectedTemplate}
                    applyToAll={applyToAll}
                    setApplyToAll={setApplyToAll}
                />
            </Flex>
        </Flex>
    )
})

export default ChartSettingsTab
