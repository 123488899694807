import CommonUtils from '../ft_types/common/BasicClasses/CommonUtils'

export default class TUserJournal {
    public AddMessage(aMessage: string): void {
        if (!CommonUtils.IsInUnitTest) {
            // eslint-disable-next-line no-console
            console.log(aMessage)
        }
    }
}
