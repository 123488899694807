import { FC, useCallback } from 'react'

import styles from './index.module.scss'
import { Flex } from '@fto/ui'
import { AvailableOptionsSettings } from '../../components/types/options.types'
import getOptionSettingByConfig from '../../components/utils/getOptionSettingByConfig'
import globalChartsStore from '@fto/lib/store/globalChartsStore'
import OptionsConfig from './config'
import useChangesObserver from '../../hooks/useChangesObserver'

interface Props {}

const LeverageSettingsTab: FC<Props> = () => {
    const { data } = globalChartsStore
    const chart = data.activeChart
    if (!chart) return <></>

    const { onDifferentSettingsUpdate } = useChangesObserver(chart, {
        mixPanelEventName: 'leverage_settings_updated',
        optionsConfig: OptionsConfig
    })

    const optionSettingByKey = useCallback(
        (option: AvailableOptionsSettings) => {
            return getOptionSettingByConfig({
                tab: 'leverage',
                chart,
                option: option,
                applyToAll: false,
                onDifferentSettingsUpdate
            })
        },
        [chart, onDifferentSettingsUpdate]
    )

    return (
        <Flex className={styles.scrollableContainer}>
            <Flex className={styles.content} block direction='column' gap={12}>
                {optionSettingByKey(OptionsConfig['Leverage'])}
            </Flex>
        </Flex>
    )
}

export default LeverageSettingsTab
