import { OSCIndicatorJSON, OscIndicatorsJSON } from '@fto/lib/ProjectAdapter/Types'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { OscInfo } from '../charting/chart_windows/Oscillators/OscInfo'
import { TPaintBox } from '../delphi_compatibility/TPaintBox'
import { TMyObjectList } from '../ft_types/common/Common'
import { TCursor } from '../ft_types/common/CursorPointers'
import GlobalOptions from '../globals/GlobalOptions'
import { TOscChart } from './chart_classes/OscChartUnit'
import { TChartWindow } from './chart_windows/ChartWindow'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { GraphicalUtils } from '@fto/lib/utils/graphicalUtils/graphicalUtils'
import StrangeError from '../common/common_errors/StrangeError'

export class TOscWinSplitter extends TPaintBox {
    private fOscWin: TOscWindow
    private fWinList: TOscWinsList
    private fLocked: boolean
    public owner: TChartWindow
    private oscInfo: OscInfo
    private isHovered = false

    constructor(owner: TChartWindow, OscWin: TOscWindow, WinList: TOscWinsList, oscInfo: OscInfo) {
        super()
        this.fOscWin = OscWin
        this.fWinList = WinList
        this.fLocked = false
        this.oscInfo = oscInfo
        this.owner = owner
        this.heightInPercent = GlobalOptions.Options.DefaultOscWindowHeightInPercent
        OscWin.oscInfo = oscInfo
        this.SetName(`OscWinSplitter of ${owner.DName}`)
    }

    private get shouldRenderActiveView(): boolean {
        return this.isHovered || this.fLocked
    }

    public Paint(): void {
        const ctx = this.oscInfo.splitterCanvas.getContext('2d')

        const ownersPaintRect = this.fOscWin.chart.GetPaintRect()

        if (ctx) {
            const { BackgroundColor, OscSplitterHoverColor, OscSplitterDefaultColor } =
                this.owner.ChartOptions.ColorScheme
            const canvasWidth = this.oscInfo.splitterCanvas.width
            const canvasHeight = this.oscInfo.splitterCanvas.height
            const fillColor = this.shouldRenderActiveView ? OscSplitterHoverColor : BackgroundColor

            this.clear()

            if (this.shouldRenderActiveView) {
                ctx.fillStyle = ColorHelperFunctions.MakeColor(fillColor)
                ctx.globalAlpha = 0.2
                ctx.fillRect(0, 0, canvasWidth, canvasHeight)
                ctx.globalAlpha = 1.0
            }

            ctx.strokeStyle = ColorHelperFunctions.MakeColor(OscSplitterDefaultColor)
            ctx.beginPath()

            ctx.moveTo(0, GraphicalUtils.alignToPixel(canvasHeight / 2))
            ctx.lineTo(canvasWidth, GraphicalUtils.alignToPixel(canvasHeight / 2))
            ctx.stroke()
            ctx.closePath()

            ctx.beginPath()
            ctx.strokeStyle = this.owner.ChartOptions.ColorScheme.FrameAndTextColor

            ctx.moveTo(ownersPaintRect.Right, 0)
            ctx.lineTo(ownersPaintRect.Right, canvasHeight)
            ctx.stroke()
            ctx.closePath()
        }
    }

    private clear() {
        const ctx = this.oscInfo.splitterCanvas.getContext('2d')
        const { BackgroundColor } = this.owner.ChartOptions.ColorScheme
        const canvasWidth = this.oscInfo.splitterCanvas.width
        const canvasHeight = this.oscInfo.splitterCanvas.height
        if (!ctx) return

        ctx.fillStyle = ColorHelperFunctions.MakeColor(BackgroundColor)
        ctx.fillRect(0, 0, canvasWidth, canvasHeight)
    }

    public IsMouseInside(event: MouseEvent): boolean {
        const splitterCanvas = this.oscInfo.oscSplitter
        const rect = splitterCanvas.getBoundingClientRect()

        return (
            event.clientX >= rect.left &&
            event.clientX <= rect.right &&
            event.clientY >= Math.round(rect.top) &&
            event.clientY <= Math.round(rect.bottom)
        )
    }

    public MouseDown(event: MouseEvent): void {
        if (event.button === 0) {
            // Left mouse button
            this.fLocked = true
        }
    }

    public MouseUp(event: MouseEvent): void {
        this.fLocked = false
    }

    public MouseMove(event: MouseEvent): void {
        const isMouseInside = this.IsMouseInside(event)
        if (this.isHovered !== isMouseInside) {
            this.isHovered = isMouseInside
        }

        if (!this.fLocked) {
            this.oscInfo.splitterCanvas.style.cursor = TCursor.crSizeNS
        }

        if (this.fLocked) {
            const splitterHeight = GlobalOptions.Options.DefaultOscSplitterHeightInPixels
            const minimumMainChartHeight = Math.max(
                (this.oscInfo.totalChartHeight * GlobalOptions.Options.MinChartHeightInPercent) / 100,
                10
            )
            const minimumOscHeight = Math.max(
                (this.oscInfo.totalChartHeight * GlobalOptions.Options.MinOscWindowHeightInPercent) / 100,
                splitterHeight + 1
            )
            const osc1 = this.fOscWin
            const osc2 = this.fWinList.GetNextWindow(this)
            const dpr = window.devicePixelRatio || 1

            if (osc2) {
                let mouseDiffY: number = event.movementY

                const bottomOscContainer = this.oscInfo.oscContainer
                const bottomSplitter = this.oscInfo.oscSplitter
                const bottomOscWindow = this.oscInfo.oscWindow

                const topOscContainer = osc2.splitter.oscInfo.oscContainer
                const topOscSplitter = osc2.splitter.oscInfo.oscSplitter
                const topOscWindow = osc2.splitter.oscInfo.oscWindow

                if (
                    bottomSplitter &&
                    bottomOscWindow &&
                    bottomOscContainer &&
                    topOscContainer &&
                    topOscSplitter &&
                    topOscWindow
                ) {
                    const currTopOscContainerHeight = topOscContainer.clientHeight
                    let newTopOscContainerHeight = currTopOscContainerHeight + mouseDiffY
                    if (newTopOscContainerHeight < minimumOscHeight) {
                        mouseDiffY = -(currTopOscContainerHeight - minimumOscHeight)
                        newTopOscContainerHeight = minimumOscHeight
                    }

                    const currBottomOscContainerHeight = bottomOscContainer.clientHeight
                    let newBottomOscContainerHeight = currBottomOscContainerHeight + -mouseDiffY
                    if (newBottomOscContainerHeight < minimumOscHeight) {
                        mouseDiffY = -(currBottomOscContainerHeight - minimumOscHeight)
                        newBottomOscContainerHeight = minimumOscHeight
                        newTopOscContainerHeight = currTopOscContainerHeight + -mouseDiffY
                    }

                    const chartContainerId = `#chart-container-${this.owner.getID()}`
                    const fullChartContainer = document.querySelector(chartContainerId) as HTMLElement

                    osc1.chart.heightInPercent = (newBottomOscContainerHeight / fullChartContainer.clientHeight) * 100
                    osc2.chart.heightInPercent = (newTopOscContainerHeight / fullChartContainer.clientHeight) * 100

                    this.fOscWin.onBrowserWndSizing()
                    osc2.splitter.fOscWin.onBrowserWndSizing()

                    TChart.SelIndValue = null
                    TChart.SelIndValue = null

                    osc2.chart.Paint()
                    osc1.chart.Paint()
                }
            } else {
                let mouseDiffY: number = event.movementY
                const mainChartCanvas = this.oscInfo.mainChartCanvasElement
                const mainOscContainer = this.oscInfo.mainOscContainer

                if (mainChartCanvas && mainOscContainer) {
                    const oscContainer = this.oscInfo.oscContainer
                    const splitter = this.oscInfo.oscSplitter
                    const oscWindow = this.oscInfo.oscWindow

                    if (splitter && oscWindow && oscContainer) {
                        const currMainChartHeight = mainChartCanvas.clientHeight
                        let newMainchartHeight = currMainChartHeight + mouseDiffY
                        if (newMainchartHeight < minimumMainChartHeight) {
                            mouseDiffY = -(currMainChartHeight - minimumMainChartHeight)
                            newMainchartHeight = minimumMainChartHeight
                        }

                        const currOscContainerHeight = oscContainer.clientHeight
                        let newOscContainerHeight = currOscContainerHeight + -mouseDiffY
                        if (newOscContainerHeight < minimumOscHeight) {
                            mouseDiffY = -(currOscContainerHeight - minimumOscHeight)
                            newOscContainerHeight = minimumOscHeight
                            newMainchartHeight = currMainChartHeight + -mouseDiffY
                        }

                        const chartContainerId = `#chart-container-${this.owner.getID()}`
                        const fullChartContainer = document.querySelector(chartContainerId) as HTMLElement

                        this.fOscWin.lastHeightInPercent = this.fOscWin.chart.heightInPercent =
                            (newOscContainerHeight / fullChartContainer.clientHeight) * 100
                        this.owner.MainChart.heightInPercent =
                            (newMainchartHeight / fullChartContainer.clientHeight) * 100
                        this.fOscWin.onBrowserWndSizing()
                        this.fOscWin.chart.Paint()
                        TChart.SelIndValue = null
                    }
                }
            }
        }
    }
}

export class TOscWindow {
    public chart!: TOscChart
    public splitter!: TOscWinSplitter
    public oscInfo!: OscInfo
    private static instanceNumber = 0
    private idContainer: number
    private _lastHeightInPercent = 10

    constructor() {
        this.idContainer = TOscWindow.instanceNumber++
    }

    public static resetInstanceNumber(): void {
        TOscWindow.instanceNumber = 0
    }

    public getID(): number {
        return this.idContainer
    }

    public onBrowserWndSizing(): void {
        const chartContainerId = `#chart-container-${this.splitter.owner.getID()}`
        const chartContainer = document.querySelector(chartContainerId) as HTMLElement

        if (chartContainer) {
            const splitterHeight = GlobalOptions.Options.DefaultOscSplitterHeightInPixels

            this.oscInfo.mainOscContainer.style.width = '100%'
            this.oscInfo.oscContainer.style.width = '100%'
            this.oscInfo.oscWindow.style.width = '100%'
            this.oscInfo.oscSplitter.style.width = '100%'

            const rect = this.oscInfo.oscCanvas.getBoundingClientRect()
            const dpr = window.devicePixelRatio || 1
            this.oscInfo.oscCanvas.width = rect.width * dpr
            this.oscInfo.splitterCanvas.width = rect.width * dpr

            if (this.chart.heightInPercent !== null) {
                const newOscContainerHeight = (chartContainer.clientHeight * this.chart.heightInPercent) / 100
                this.oscInfo.oscContainer.style.height = `${newOscContainerHeight}px`

                this.oscInfo.oscWindow.style.height = `${newOscContainerHeight - splitterHeight}px`

                const newCanvasHeight = newOscContainerHeight - splitterHeight
                this.oscInfo.oscCanvas.height = newCanvasHeight * dpr
                this.oscInfo.splitterCanvas.height = splitterHeight * dpr
            }
        } else {
            throw new StrangeError('not found chartContainer')
        }
    }

    public updateCollapsedHeight(): void {
        const dpr = window.devicePixelRatio || 1
        const defaultHeightOfOscControls = 24
        const OscControlsTopPos = 4
        this.setHeightOscWindow(
            (defaultHeightOfOscControls * dpr + OscControlsTopPos * 2) / dpr + OscControlsTopPos,
            'px'
        )
    }

    private setHeightOscWindow(height: number, metrics: 'px' | '%' = 'px'): void {
        const chartContainerId = `#chart-container-${this.splitter.owner.getID()}`
        const chartContainer = document.querySelector(chartContainerId) as HTMLElement

        if (chartContainer) {
            const splitterHeight = GlobalOptions.Options.DefaultOscSplitterHeightInPixels
            const dpr = window.devicePixelRatio || 1
            let calculatedHeight: number

            if (metrics === '%') {
                const containerHeight = chartContainer.clientHeight
                calculatedHeight = (height / 100) * containerHeight
            } else {
                calculatedHeight = height
            }

            const rect = this.oscInfo.oscCanvas.getBoundingClientRect()
            rect.height = calculatedHeight - splitterHeight

            this.oscInfo.oscCanvas.width = rect.width * dpr
            this.oscInfo.splitterCanvas.width = rect.width * dpr

            this.oscInfo.oscContainer.style.height = `${calculatedHeight}px`
            this.oscInfo.oscWindow.style.height = `${calculatedHeight - splitterHeight}px`

            this.chart.heightInPercent = (this.oscInfo.oscContainer.clientHeight / chartContainer.clientHeight) * 100

            this.oscInfo.oscCanvas.height = (calculatedHeight - splitterHeight) * dpr
            this.oscInfo.splitterCanvas.height = splitterHeight * dpr
        } else {
            throw new StrangeError('not found chartContainer')
        }
    }

    public collapseOscWin(): void {
        this._lastHeightInPercent = this.chart.heightInPercent ?? 10
        this.updateCollapsedHeight()
    }

    public expandOscWin(): void {
        const chartContainerId = `#chart-container-${this.splitter.owner.getID()}`
        const chartContainer = document.querySelector(chartContainerId) as HTMLElement
        const containerHeight = chartContainer.clientHeight
        const calculatedHeight = (this._lastHeightInPercent / 100) * containerHeight
        if (calculatedHeight === 36) {
            this.setHeightOscWindow(12, '%')
        } else {
            this.setHeightOscWindow(this._lastHeightInPercent, '%')
        }
    }

    public get lastHeightInPercent(): number {
        return this._lastHeightInPercent
    }

    public set lastHeightInPercent(value: number) {
        this._lastHeightInPercent = value
    }
}

//TODO: Refactor for IOscWindowList
export class TOscWinsList extends TMyObjectList<TOscWindow> {
    private _chartWindow: TChartWindow
    public nextOscChartID = 0

    constructor(owner: TChartWindow) {
        super()
        this._chartWindow = owner
    }

    toJSON(): OscIndicatorsJSON {
        const result: OscIndicatorsJSON = []

        for (const oscWin of this) {
            const res: OSCIndicatorJSON[] = oscWin.chart.indicators.toJSON()
            result.push(
                res.map((ind) => {
                    return { ...ind, paintTools: oscWin.chart.PaintTools.toJson() }
                })
            )
        }

        return result
    }

    public RemoveWindows(): void {
        this.Clear()
    }

    public DeleteWindow(win: TOscWindow): void {
        const index = this.IndexOf(win)
        if (index !== -1) this.Delete(index)
    }

    public GetWindow(splitter: TOscWinSplitter): TOscWindow | null {
        for (let i = 0; i < this.count; i++) {
            if (this.GetItem(i).splitter === splitter) {
                return this.GetItem(i)
            }
        }
        return null
    }

    public GetNextWindow(splitter: TOscWinSplitter): TOscWindow | null {
        for (let i = 0; i < this.count - 1; i++) {
            if (this.GetItem(i).splitter === splitter) {
                return this.GetItem(i + 1)
            }
        }
        return null
    }

    RelaxWindows(): void {
        for (let i = this.count - 1; i >= 0; i--) {
            this.GetItem(i).chart.align = 'none'
            this.GetItem(i).splitter.align = 'none'
        }
    }

    RealignWindows(): void {
        for (let i = 0; i < this.count; i++) {
            this.GetItem(i).chart.align = 'bottom'
            this.GetItem(i).splitter.align = 'bottom'
        }

        this._chartWindow.Repaint()
    }

    //TODO: remove this??
    ResizeWindows(): void {
        //if (this.count === 0) return;
        //this.RelaxWindows();
        //for (let i = 0; i < this.count; i++) {
        //    this.GetItem(i).chart.Height = this.GetPixelsFromPercent(this.GetItem(i).HeightPercent);
        //}
        //this.RealignWindows();
    }

    public GetWindowHeight(ExtraPixels = 0): number {
        const tb = this._chartWindow.TimeBar /* FindComponent('TimeBar') as TChart;*/
        let result = this._chartWindow.MainChart.ClientRect.Height - tb.ClientRect.Height - ExtraPixels
        for (let i = 0; i < this.count; i++) {
            result -= this.GetItem(i).splitter.Height
        }
        return result
    }

    public GetPercentFromPixels(height: number, ExtraPixels = 0): number {
        return height / this.GetWindowHeight(ExtraPixels)
    }

    public GetPixelsFromPercent(percent: number, ExtraPixels = 0): number {
        let result: number = Math.round(this.GetWindowHeight(ExtraPixels) * percent)
        if (result < 1) {
            result = 1
        }
        return result
    }

    public getFreeClientHeight(): number {
        const chart = this._chartWindow.MainChart
        return chart.getBoundingClientRect().height - 5
    }

    //TODO: remove this??
    public SetWinHeight(win: TOscWindow, value: number): void {
        //this.RelaxWindows();
        //win.HeightPercent = value;
        //win.chart.Height = this.GetPixelsFromPercent(value);
        //this.RealignWindows();
    }

    //TODO: remove this??
    public UpdateHeights(): void {
        //this.RelaxWindows();
        //for (let i = 0; i < this.count; i++) {
        //    this.GetItem(i).chart.Height = this.GetPixelsFromPercent(this.GetItem(i).HeightPercent);
        //}
        //this.RealignWindows();
    }

    public ClearDateCache(): void {
        for (let i = 0; i < this.count; i++) {
            this.GetItem(i).chart.ClearDateCache()
        }
    }
}
