import { FC, useEffect, ReactNode, useRef } from 'react'
import cx from 'classnames'

import { Flex } from '@fto/ui'

import { Icon } from '@fto/icons'

import useDraggablePanel, { Position } from '@root/hooks/useDraggablePanel/useDraggablePanel'

import { SELECTORS } from '@fto/ui/lib/constants/controledNodes'

import styles from './index.module.scss'

type Props = {
    children: ReactNode
    onPositionChange?: (position: Position) => void
    initialCoords?: Position
    defaultPosition?: Position
}

const Panel: FC<Props> = ({ children, onPositionChange, initialCoords, defaultPosition }) => {
    const modalRef = useRef(null)
    const { onMouseDown, onMouseMove, onMouseUp, position, isDragging } = useDraggablePanel({
        ref: modalRef,
        onPositionChange,
        initialCoords,
        defaultPosition
    })

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', onMouseMove)
            window.addEventListener('mouseup', onMouseUp)

            return () => {
                window.removeEventListener('mousemove', onMouseMove)
                window.removeEventListener('mouseup', onMouseUp)
            }
        }
    }, [isDragging, onMouseMove, onMouseUp])

    return (
        <Flex className={cx(SELECTORS.TESTING_PANEL.className, styles.wrapper)}>
            <Flex
                className={styles.panel}
                ref={modalRef}
                style={{
                    left: position.x,
                    top: position.y
                }}
                gap={4}
                alignItems='center'
            >
                <div className={styles.drag} onMouseDown={onMouseDown}>
                    <Icon name='drag-n-drop' color='var(--color-gray-600)' size={12} />
                </div>
                {children}
            </Flex>
        </Flex>
    )
}

export default Panel
