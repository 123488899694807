import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'
import { ToolPropertiesMap } from '@fto/lib/charting/paint_tools/SpecificTools/types'
import GlobalChartsController from '../../GlobalChartsController'
import { ColorTheme } from '@fto/lib/charting/auxiliary_classes_charting/TColorScheme'

import LightDefaultTemplate from './LightDefaultTemplates'
import DarkDefaultTemplate from './DarkDefaultTemplates'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export type SupportedPaintTools = keyof ToolProperties

export type ToolProperties = Omit<
    ToolPropertiesMap,
    PaintToolNames.ptMeasure | PaintToolNames.ptRiskRewardBuy | PaintToolNames.ptRiskRewardSell
>

export function getDefaultTemplateByToolName(toolName: SupportedPaintTools): ToolProperties[SupportedPaintTools] {
    const theme = GlobalChartsController.Instance.currentColorTheme
    const template = theme == ColorTheme.Light ? LightDefaultTemplate[toolName] : DarkDefaultTemplate[toolName]

    if (!template) {
        throw new StrangeError(`Template for ${toolName} not found`)
    }

    return template
}
