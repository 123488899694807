import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { TSymbolInfo } from '@fto/lib/ft_types/common/BasicClasses/SymbolInfo'

export abstract class SwapsCalculationStrategy {
    protected symbolInfo: TSymbolInfo

    public getSwapValueForPosType(operationType: TTradePositionType): number {
        return operationType === TTradePositionType.tp_Buy ? this.symbolInfo.SwapLong : this.symbolInfo.SwapShort
    }

    public abstract calculateSwapsUnitsForDateRange(
        previousDate: TDateTime,
        currentDate: TDateTime,
        posType: TTradePositionType,
        positionOpenedDate: TDateTime
    ): number

    constructor(symbolInfo: TSymbolInfo) {
        this.symbolInfo = symbolInfo
    }
}
