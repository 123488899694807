import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { Flex, SimpleDropdown, Typography } from '@fto/ui'
import { DropdownSettingType, OptionSettingRef } from '../../types/options.types'

import styles from './index.module.scss'
import { t } from 'i18next'
import { getNumberValue, setNumberValue, executeMethod } from '../../utils'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

const DropdownSettings = forwardRef<OptionSettingRef, DropdownSettingType>(
    ({ tab, chart, option, applyToAll, shouldBeDisabled, onDifferentSettingsUpdate }, ref) => {
        const [value, setValue] = useState<number>(() => getNumberValue(chart, option.valueConfigType, option.valueKey))

        const updateValue = useCallback(
            (value: number, redraw = true, shouldUpdateConfig = true, shouldSendNewSettingtoServer = true) => {
                setValue(value)

                if (shouldUpdateConfig) {
                    if (option.valueKeyMethod) {
                        executeMethod(
                            chart,
                            option.valueConfigType,
                            option.valueKeyMethod,
                            value,
                            shouldSendNewSettingtoServer
                        )
                    } else {
                        setNumberValue(
                            chart,
                            option.valueConfigType,
                            option.valueKey,
                            value,
                            applyToAll,
                            shouldSendNewSettingtoServer
                        )
                    }
                }

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [chart, option, applyToAll]
        )

        const handleChange = useCallback(
            (newValue: number) => {
                onDifferentSettingsUpdate?.(tab, newValue, value, option.valueKey)
                updateValue(newValue)
            },
            [tab, option, value, updateValue, onDifferentSettingsUpdate]
        )

        const loadOptionSetting = useCallback(
            (data: any, shouldUpdateConfig = true) => {
                updateValue(data.value, false, shouldUpdateConfig, false)
            },
            [updateValue]
        )

        useImperativeHandle(ref, () => ({ name: option.key, loadOptionSetting }), [option.key, loadOptionSetting])

        return (
            <Flex gap={4} alignItems='center' justifyContent='space-between'>
                <Typography type='interface-regular' color={shouldBeDisabled ? 'gray-600' : 'gray-1000'}>
                    {t(option.name)}
                </Typography>
                <Flex alignItems='center'>
                    <SimpleDropdown
                        classNames={{ header: styles.dropdown }}
                        options={option.options}
                        value={value}
                        onChange={(value) => handleChange(Number(value))}
                        disabled={shouldBeDisabled}
                    />
                </Flex>
            </Flex>
        )
    }
)

export default DropdownSettings
