import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Tooltip, Typography } from '@fto/ui'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import { Icon } from '@fto/icons'

import styles from './index.module.scss'

type Props = {
    title: string
    value: string
    tooltip: string
}

export const AnalyticsInfoRow: FC<Props> = ({ title, value, tooltip }) => {
    const { t } = useTranslation()

    const isRelevantTitle = useMemo(
        () => title === t('analytics.DaysProcessed') || title === t('analytics.MonthsProcessed'),
        [title]
    )
    const anyOrdersBeforeStartDate =
        isRelevantTitle && GlobalProcessingCore.ProcessingCore.anyOrdersBeforeProjectStartDate()

    const isBeforeStartDate = useMemo(
        () => GlobalProcessingCore.ProcessingCore.CurrTime < GlobalProjectInfo.ProjectInfo.StartDate && isRelevantTitle,
        [GlobalProcessingCore.ProcessingCore.CurrTime, GlobalProjectInfo.ProjectInfo.StartDate, isRelevantTitle]
    )

    const isWarning = useMemo(
        () => isBeforeStartDate || anyOrdersBeforeStartDate,
        [isBeforeStartDate, anyOrdersBeforeStartDate]
    )

    const beforeStartDateText = useMemo(
        () =>
            title === t('analytics.DaysProcessed')
                ? t('analytics.tooltips.BeforeStartDateDays')
                : t('analytics.tooltips.BeforeStartDateMonths'),
        [title]
    )

    const warningTooltip = useMemo(
        () => (anyOrdersBeforeStartDate ? t('analytics.tooltips.OrderBeforeStart') : beforeStartDateText),
        [anyOrdersBeforeStartDate, beforeStartDateText]
    )

    return (
        <Flex justifyContent='space-between' className={styles.text}>
            <Flex gap={8} alignItems='center'>
                <Tooltip content={isWarning ? warningTooltip : tooltip} placement='left'>
                    <Icon
                        className={isWarning ? styles.iconWarning : styles.icon}
                        size={16}
                        color={isWarning ? 'var(--color-orange-400)' : 'var(--color-gray-500)'}
                        name='info-filled'
                    />
                </Tooltip>
                <Typography type='interface-regular'>{title}</Typography>
            </Flex>
            <Typography type='interface-regular'>{value}</Typography>
        </Flex>
    )
}
