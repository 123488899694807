import { ChartInfoType } from '@fto/chart_components/types/chartTypes'
import { LAYOUTS } from '@fto/chart_components/constants/layouts'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

const layoutReductionMap: { [key in ChartInfoType['layout']]: ChartInfoType['layout'] } = {
    'one-chart': 'one-chart',
    'two-charts-horizontally': 'one-chart',
    'two-charts-vertically': 'one-chart',
    'three-charts-horizontally': 'two-charts-horizontally',
    'three-charts-vertically': 'two-charts-vertically',
    'one-top-two-bottom': 'two-charts-vertically',
    'two-top-one-bottom': 'two-charts-vertically',
    'one-left-two-right': 'two-charts-horizontally',
    'two-left-one-right': 'two-charts-horizontally',
    'four-charts-quadrant': 'three-charts-horizontally',
    'four-charts-horizontal': 'three-charts-horizontally',
    'four-charts-vertical': 'three-charts-vertically',
    'four-charts-one-top-three-bottom': 'three-charts-vertically',
    'four-charts-three-rows': 'three-charts-vertically',
    'five-charts-one-top-four-bottom': 'four-charts-quadrant',
    'five-charts-one-left-four-right': 'four-charts-quadrant',
    'six-charts-grid-2-rows-3-cols': 'five-charts-one-top-four-bottom',
    'six-charts-grid-3-rows-2-cols': 'five-charts-one-left-four-right',
    'seven-charts-1-extra-col': 'six-charts-grid-2-rows-3-cols',
    'seven-charts-1-extra-row': 'six-charts-grid-3-rows-2-cols',
    'eight-charts-grid-2-rows-4-cols': 'seven-charts-1-extra-col',
    'eight-charts-grid-4-rows-2-cols': 'seven-charts-1-extra-row'
}

export function reduceLayout(currentLayout: ChartInfoType): ChartInfoType {
    const newLayoutName = layoutReductionMap[currentLayout.layout]

    const newLayout = LAYOUTS.find((layout) => layout.value.layout === newLayoutName)

    if (!newLayout) {
        throw new StrangeError('Layout not found')
    }
    return {
        ...newLayout.value,
        symbol: currentLayout.symbol
    }
}
