import { useContextMenuState } from '@fto/ui'
import { FC } from 'react'
import IndicatorContextMenu from './IndicatorContextMenu'
import ChartContextMenu from './ChartContextMenu'
import ToolContextMenu from './ToolContextMenu'
import NewsContextMenu from './NewsContextMenu'
import TerminalContextMenu from './TerminalContextMenu'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'
import TimeframeContextMenu from './TimeframeContextMenu'
import OneClickTradingContextMenu from './OneClickTradingContextMenu'
import TimeZoneChangeContextMenu from './TimeZoneChangeContextMenu'
import { TabsSettingsType } from '@fto/chart_components/ProjectInterface/types'

type Props = {
    openTab: (val: TabsSettingsType ) => void
}

const ContextMenuWrapper: FC<Props> = ({ openTab }) => {
    const { open: isIndicatorContextMenuOpen, props: contextMenuProps } = useContextMenuState(
        CONTEXT_MENU_NAMES.indicator
    )
    const { open: isChartContextMenuOpen, props: chartContextMenuProps } = useContextMenuState(CONTEXT_MENU_NAMES.chart)
    const { open: isToolContextMenuOpen, props: toolContextMenuProps } = useContextMenuState(CONTEXT_MENU_NAMES.tool)
    const { open: isNewsContextMenuOpen, props: newsContextMenuProps } = useContextMenuState(CONTEXT_MENU_NAMES.news)
    const { open: isTerminalContextMenuOpen, props: terminalContextMenuProps } = useContextMenuState(
        CONTEXT_MENU_NAMES.terminal
    )
    const { open: isTimeframeChangeContextMenuOpen, props: timeframeChangeContextMenuProps } = useContextMenuState(
        CONTEXT_MENU_NAMES.timeframeChange
    )
    const { open: isOneClickTradingContextMenuOpen, props: oneClickTradingContextMenuProps } = useContextMenuState(
        CONTEXT_MENU_NAMES.oneClickTrading
    )

    const { open: isTimeZoneMenuChangeOpen, props: timeZonesProps } = useContextMenuState(CONTEXT_MENU_NAMES.timeZones)

    return (
        <>
            {isIndicatorContextMenuOpen && <IndicatorContextMenu {...contextMenuProps} />}
            {isChartContextMenuOpen && <ChartContextMenu openTab={openTab} {...chartContextMenuProps} />}
            {isToolContextMenuOpen && <ToolContextMenu {...toolContextMenuProps} />}
            {isNewsContextMenuOpen && <NewsContextMenu openTab={openTab} {...newsContextMenuProps} />}
            {isTerminalContextMenuOpen && <TerminalContextMenu {...terminalContextMenuProps} />}
            {isTimeframeChangeContextMenuOpen && <TimeframeContextMenu {...timeframeChangeContextMenuProps} />}
            {isOneClickTradingContextMenuOpen && <OneClickTradingContextMenu {...oneClickTradingContextMenuProps} />}
            {isTimeZoneMenuChangeOpen && <TimeZoneChangeContextMenu {...timeZonesProps} />}
        </>
    )
}

export default ContextMenuWrapper
