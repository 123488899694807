import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { Checkbox, Flex, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'
import { OptionSettingRef, CheckboxSettingType } from '../../types/options.types'

import styles from './index.module.scss'
import { getBooleanValue, setBooleanValue } from '../../utils'
import { t } from 'i18next'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

const CheckboxSetting = forwardRef<OptionSettingRef, CheckboxSettingType>(
    ({ tab, chart, option, applyToAll, shouldBeDisabled, onDifferentSettingsUpdate, setChildsDisabled }, ref) => {
        const [checked, setChecked] = useState<boolean>(() => {
            const value = getBooleanValue(chart, option.booleanConfigType, option.booleanKey)
            return option.invertValue ? !value : value
        })

        const setCheckboxValue = useCallback(
            (value: boolean, redraw = true, shouldUpdateConfig = true, shouldSendNewSettingtoServer = true) => {
                setChecked(value)
                setChildsDisabled(!value)

                shouldUpdateConfig &&
                    setBooleanValue(
                        chart,
                        option.booleanConfigType,
                        option.booleanKey,
                        option.invertValue ? !value : value, // invert value if needed
                        applyToAll,
                        shouldSendNewSettingtoServer
                    )

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [option, chart, applyToAll, setChildsDisabled]
        )

        const handleCheckboxChange = useCallback(
            (value: boolean) => {
                onDifferentSettingsUpdate?.(tab, value, option.invertValue ? !checked : checked, option.booleanKey)
                setCheckboxValue(value)
            },
            [tab, option, checked, setCheckboxValue, onDifferentSettingsUpdate]
        )

        const loadOptionSetting = useCallback(
            (data: any, shouldUpdateConfig = true) => {
                setCheckboxValue(data.checked, false, shouldUpdateConfig, false)
            },
            [setCheckboxValue]
        )

        useImperativeHandle(ref, () => ({ name: option.key, loadOptionSetting }), [option.key, loadOptionSetting])

        return (
            <Flex>
                <Flex direction='column' alignItems='flex-start' block>
                    <Flex alignItems='center' justifyContent='space-between' block>
                        <Checkbox
                            className={styles.checkbox}
                            label={
                                <Typography
                                    className={styles.checkboxLabel}
                                    type='interface-regular'
                                    color={shouldBeDisabled ? 'gray-600' : 'gray-1000'}
                                >
                                    {t(option.name)}
                                </Typography>
                            }
                            block
                            inputControlVariant={'row-reverse'}
                            checked={checked}
                            onChange={handleCheckboxChange}
                            disabled={shouldBeDisabled}
                        ></Checkbox>

                        {option.image && <Icon className={styles.hintImage} name={option.image} />}
                    </Flex>
                    {option.description && (
                        <Typography
                            className={styles.description}
                            type='subtext-regular'
                            color={shouldBeDisabled ? 'gray-600' : 'gray-800'}
                            onClick={() => {
                                handleCheckboxChange(!checked)
                            }}
                            block
                        >
                            {t(option.description)}
                        </Typography>
                    )}
                </Flex>
            </Flex>
        )
    }
)

export default CheckboxSetting
