import React, { useCallback, useState, useEffect, RefObject } from 'react'

import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

import { calculatePosition, getElementWindowPositionData, MIN_INDENTS } from './utils'

export interface Position {
    x: number
    y: number
}

type Props = {
    ref: RefObject<HTMLDivElement>
    initialCoords?: Position
    defaultPosition?: Position
    onPositionChange?: (position: Position) => void
}

const DEFAULT_POSITION = { x: window.innerWidth / 4, y: 60 }

const useDraggablePanel = ({ ref, initialCoords, onPositionChange, defaultPosition = DEFAULT_POSITION }: Props) => {
    const initialPosition = initialCoords || defaultPosition

    const [isDragging, setIsDragging] = useState(false)
    const [position, setPosition] = useState<Position>(initialPosition)
    const [startPosition, setStartPosition] = useState<Position>(initialPosition)

    const onMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setIsDragging(true)
            document.body.style.userSelect = 'none' // Disable text selection on the entire body
            setStartPosition({
                x: e.clientX - position.x,
                y: e.clientY - position.y
            })
            GlobalChartsController.Instance.disableMouseEvents()
        },
        [position]
    )

    useEffect(() => {
        const refValue = ref.current

        const handleResize = () => {
            if (!refValue) return
            const { isOutOfRightSide, isOutOfBottomSide, isOutOfLeftSide, isOutOfTopSide } =
                getElementWindowPositionData(ref)

            setPosition((prevPosition) => {
                if (isOutOfTopSide || isOutOfLeftSide) {
                    return {
                        x: isOutOfLeftSide ? MIN_INDENTS.left : prevPosition.x,
                        y: isOutOfTopSide ? MIN_INDENTS.top : prevPosition.y
                    }
                }

                return {
                    ...prevPosition,
                    x:
                        isOutOfRightSide && !isOutOfLeftSide
                            ? window.innerWidth - refValue.clientWidth - 10
                            : prevPosition.x,
                    y:
                        isOutOfBottomSide && !isOutOfTopSide
                            ? window.innerHeight - refValue.clientHeight - 40
                            : prevPosition.y
                }
            })
        }

        window.addEventListener('resize', handleResize)

        let resizeObserver: ResizeObserver | null = null

        if (refValue) {
            resizeObserver = new ResizeObserver(() => {
                handleResize() // Update positions when size changes
            })
            resizeObserver.observe(refValue)
        }

        return () => {
            window.removeEventListener('resize', handleResize)

            if (resizeObserver && refValue) {
                resizeObserver.unobserve(refValue)
                resizeObserver.disconnect()
            }
        }
    }, [])

    useEffect(() => {
        onPositionChange?.(position)
    }, [position])

    const onMouseMove = useCallback(
        (e: MouseEvent) => {
            if (!isDragging || !ref.current) return

            const boundaries = ref.current.getBoundingClientRect()
            const defaultLeftCornerXPosition = window.innerWidth
            const defaultLeftCornerYPosition = window.innerHeight

            const newPosition = calculatePosition(
                e.clientX,
                e.clientY,
                startPosition,
                defaultLeftCornerXPosition,
                defaultLeftCornerYPosition,
                boundaries
            )

            setPosition(newPosition)
        },
        [isDragging, startPosition]
    )

    const onMouseUp = useCallback(() => {
        setIsDragging(false)
        document.body.style.userSelect = '' // Re-enable text selection
        GlobalChartsController.Instance.enableMouseEvents()
    }, [])

    return { position, onMouseDown, onMouseMove, onMouseUp, isDragging }
}

export default useDraggablePanel
