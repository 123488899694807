import { FC, useState, useCallback, FocusEvent, ReactElement } from 'react'
import cx from 'classnames'

import { InputField, InputFieldProps, useTheme, Typography } from '@fto/ui'

import styles from './index.module.scss'

type Props = {
    hasPseudoFocus?: boolean
    hasPseudoError?: boolean
    errorMessage?: string | ReactElement
}

type AggregatedProps = Props & InputFieldProps

const InputCreator: FC<AggregatedProps> = ({
    hasPseudoFocus,
    hasPseudoError,
    errorMessage = '',
    onFocus,
    onBlur,
    value,
    onChange,
    type,
    label,
    step,
    className,
    block,
    disabled
}) => {
    const { isLight } = useTheme()
    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            onFocus?.(event)
            setIsFocused(true)
        },
        [onFocus]
    )

    const handleBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            onBlur?.(event)
            setIsFocused(false)
        },
        [onBlur]
    )

    return (
        <div className={styles.InputWrapper}>
            <InputField
                className={cx(styles.Input, className, {
                    [styles.PseudoFocus]: hasPseudoFocus,
                    [styles.PseudoError]: hasPseudoError
                })}
                onFocus={handleFocus}
                onBlur={handleBlur}
                step={step}
                value={value}
                onChange={onChange}
                type={type}
                label={label}
                block={block}
                disabled={disabled}
            />
            {!!(hasPseudoError && isFocused) && (
                <div className={styles.TooltipMessage}>
                    <Typography color={isLight ? 'white' : 'gray-1000'} type='subtext-regular' block align='center'>
                        {errorMessage}
                    </Typography>
                </div>
            )}
        </div>
    )
}

export default InputCreator
