import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { Flex, InputField, Tooltip, Typography } from '@fto/ui'
import { InputSettingType, OptionSettingRef } from '../../types/options.types'

import styles from './index.module.scss'

import { t } from 'i18next'
import { getNumberValue, setNumberValue } from '../../utils'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { Icon } from '@fto/icons'
import WarningBlockMessage from '../../WarningBlockMessage'
import { tryStopTesting } from '../../../components/utils/tryStopTesting'

const InputSetting = forwardRef<OptionSettingRef, InputSettingType>(
    ({ tab, chart, option, maxValue, minValue, applyToAll, shouldBeDisabled, onDifferentSettingsUpdate }, ref) => {
        const [value, setValue] = useState<number>(getNumberValue(chart, option.valueConfigType, option.valueKey))

        const setNewValue = useCallback(
            (value: number, redraw = true, shouldUpdateConfig = true, shouldSendNewSettingtoServer = true) => {
                setValue(value)

                shouldUpdateConfig &&
                    setNumberValue(
                        chart,
                        option.valueConfigType,
                        option.valueKey,
                        value,
                        applyToAll,
                        shouldSendNewSettingtoServer
                    )

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [chart, option, applyToAll]
        )

        const onFocus = useCallback(() => {
            option.stopTestingOnChange ?? tryStopTesting()
        }, [option])

        const changeValue = useCallback(
            (newValue: number) => {
                onDifferentSettingsUpdate?.(tab, newValue, value, option.valueKey)
                setNewValue(newValue)
            },
            [tab, option, value, setNewValue, onDifferentSettingsUpdate]
        )

        const loadOptionSetting = useCallback(
            (data: any, shouldUpdateConfig = true) => {
                setNewValue(data.value, false, shouldUpdateConfig, false)
            },
            [setNewValue]
        )

        useImperativeHandle(ref, () => ({ name: option.key, loadOptionSetting }), [option.key, loadOptionSetting])

        return (
            <Flex direction='column'>
                <Flex gap={4} alignItems='center' justifyContent='space-between'>
                    <Typography type='interface-regular' color={shouldBeDisabled ? 'gray-600' : 'gray-1000'}>
                        {t(option.name)}
                    </Typography>
                    <Flex alignItems='center'>
                        {option.warningTooltip && (
                            <Tooltip
                                content={t(option.warningTooltip)}
                                placement='top'
                                classNames={{ content: styles.WarningTooltip }}
                            >
                                <Icon
                                    name='error'
                                    size={16}
                                    color={shouldBeDisabled ? 'var(--color-gray-500)' : 'var(--color-orange-400)'}
                                />
                            </Tooltip>
                        )}
                        <InputField
                            className={styles.input}
                            step={1}
                            value={value}
                            onChange={(value) => changeValue(Number(value))}
                            onFocus={onFocus}
                            type={'number'}
                            max={maxValue}
                            min={minValue}
                            disabled={shouldBeDisabled}
                        />
                    </Flex>
                </Flex>
                <WarningBlockMessage text={t(option.warningMessage)} observableValue={value} />
            </Flex>
        )
    }
)

export default InputSetting
