import IFMBarsArray from '@fto/lib/ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import { TPriceType, TValueType } from '../CommonTypes'
import { ICurrentSymbolAndTFInfoProcRec } from '../CommonProcRecInterfaces/ICurrentSymbolInfoProcRec'
import ISymbolData from '@fto/lib/ft_types/data/ISymbolData'
import { APIHelperFunctions } from '../ApiHelperFunctions'
import { GlobalHighestLowestPriceCache } from '@fto/lib/globals/HighestLowestCache/GlobalHighestLowestPriceCache'
import { API_InvalidInitialization } from '../errors/API_InvalidInitialization'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'
import { IProcRecsEveryImplementationNeeds } from './IProcRecsEveryImplementationNeeds'
import { FTODate, TimeUnit, TimeZoneMode } from '@fto/lib/FTODate/FTODate'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class CurrentSymbolAndTFInfoProcRecImplementation
    extends BasicProcRecImplementation
    implements ICurrentSymbolAndTFInfoProcRec
{
    public GetImplementation(): ICurrentSymbolAndTFInfoProcRec {
        return {
            // Current Symbol and Timeframe Info
            Symbol: this.Symbol.bind(this),
            Timeframe: this.Timeframe.bind(this),
            Bid: this.Bid.bind(this),
            Ask: this.Ask.bind(this),
            Digits: this.Digits.bind(this),
            Point: this.Point.bind(this),
            Open: this.Open.bind(this),
            Close: this.Close.bind(this),
            High: this.High.bind(this),
            Low: this.Low.bind(this),
            Volume: this.Volume.bind(this),
            Time: this.Time.bind(this),
            Bars: this.Bars.bind(this),
            GetPrice: this.GetPrice.bind(this),
            GetHighestValue: this.GetHighestValue.bind(this),
            GetLowestValue: this.GetLowestValue.bind(this)
        }
    }

    protected override generateDName(): string {
        return `API_CurrentSymbolAndTF_${super.generateDName()}`
    }

    private barsArrayGetter: () => IFMBarsArray
    private symbolDataGetter: () => ISymbolData

    private get barsArray() {
        if (!this.barsArrayGetter) {
            throw new API_InvalidInitialization('barsArrayGetter is not assigned.')
        }
        const bars = this.barsArrayGetter()
        if (!bars) {
            throw new API_InvalidInitialization('barsArrayGetter returned null.')
        }
        return bars
    }

    private get symbolData() {
        if (!this.symbolDataGetter) {
            throw new API_InvalidInitialization('symbolDataGetter is not assigned.')
        }
        const symbolData = this.symbolDataGetter()
        if (!symbolData) {
            throw new API_InvalidInitialization('symbolDataGetter returned null.')
        }
        return symbolData
    }

    constructor(
        procRecsEveryoneNeeds: IProcRecsEveryImplementationNeeds,
        barsArrayGetter: () => IFMBarsArray,
        symbolDataGetter: () => ISymbolData
    ) {
        super(procRecsEveryoneNeeds)
        this.barsArrayGetter = barsArrayGetter
        this.symbolDataGetter = symbolDataGetter
    }

    public Bid(): number {
        return this.symbolData.bid
    }
    public Ask(): number {
        return this.symbolData.ask
    }
    public Symbol(): string {
        return this.symbolData.symbolInfo.SymbolName
    }
    public Digits(): number {
        return this.symbolData.symbolInfo.decimals
    }
    public Point(): number {
        return this.symbolData.symbolInfo.MinPoint
    }
    public Timeframe(): number {
        return this.barsArray.DataDescriptor.timeframe
    }
    public Bars(): number {
        return this.barsArray.LastItemInTestingIndex + 1
    }

    public Low(lastItem0_based_index: number): number {
        return APIHelperFunctions.Low(lastItem0_based_index, this.barsArray)
    }

    public Open(lastItem0_based_index: number): number {
        return APIHelperFunctions.Open(lastItem0_based_index, this.barsArray)
    }

    public High(lastItem0_based_index: number): number {
        return APIHelperFunctions.High(lastItem0_based_index, this.barsArray)
    }

    public Time(lastItem0_based_index: number, timeZoneMode?: TimeZoneMode): FTODate {
        let timezoneToUse = TimeZoneMode.PROJECT
        if (timeZoneMode !== undefined) {
            timezoneToUse = timeZoneMode
        }
        const libTime = APIHelperFunctions.Time(lastItem0_based_index, this.barsArray)
        if (timezoneToUse === TimeZoneMode.UTC) {
            const libTimeInMs = DateUtils.toUnixTimeMilliseconds(libTime)
            return new FTODate(libTimeInMs, TimeUnit.MILLISECONDS, timezoneToUse)
        } else if (timezoneToUse === TimeZoneMode.PROJECT) {
            const projectTimeInMs = DateUtils.toUnixTimeMilliseconds(
                GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(libTime)
            )
            return new FTODate(projectTimeInMs, TimeUnit.MILLISECONDS, timezoneToUse)
        } else {
            throw new StrangeError('Unknown TimeZoneMode')
        }
    }

    public Close(lastItem0_based_index: number): number {
        return APIHelperFunctions.Close(lastItem0_based_index, this.barsArray)
    }

    public Volume(lastItem0_based_index: number): number {
        return APIHelperFunctions.Volume(lastItem0_based_index, this.barsArray)
    }

    public GetPrice(lastItem_0_based_index: number, PriceType: TPriceType): number {
        return APIHelperFunctions.GetPrice(lastItem_0_based_index, PriceType, this.barsArray)
    }

    private GetValueByType(lastItem0_based_index: number, valueType: TValueType): number {
        switch (valueType) {
            case TValueType.vt_Open: {
                return this.Open(lastItem0_based_index)
            }
            case TValueType.vt_High: {
                return this.High(lastItem0_based_index)
            }
            case TValueType.vt_Low: {
                return this.Low(lastItem0_based_index)
            }
            case TValueType.vt_Close: {
                return this.Close(lastItem0_based_index)
            }
            default: {
                return this.Volume(lastItem0_based_index)
            }
        }
    }

    public GetHighestValue(valueType: TValueType, startIndex_lastItem0_based: number, count: number): number {
        //TODO: why this check? why can't StartIndex be 0?
        if (startIndex_lastItem0_based !== 0) {
            const reversedIndex = APIHelperFunctions.Reverse_from_lastItem0_to_firstItem0(
                this.barsArray,
                startIndex_lastItem0_based
            )
            const highestPrice = GlobalHighestLowestPriceCache.Instance.getHighestValue(
                this.barsArray.DataDescriptor,
                reversedIndex,
                valueType,
                count
            )

            if (highestPrice) {
                return highestPrice
            }
        }

        let result = this.GetValueByType(startIndex_lastItem0_based, valueType)
        for (let i = 1; i < count; i++) {
            const v = this.GetValueByType(startIndex_lastItem0_based + i, valueType)
            if (v > result) {
                result = v
            }
        }

        //TODO: why this check? why can't StartIndex be 0?
        if (startIndex_lastItem0_based !== 0) {
            const reversedIndex = APIHelperFunctions.Reverse_from_lastItem0_to_firstItem0(
                this.barsArray,
                startIndex_lastItem0_based
            )
            GlobalHighestLowestPriceCache.Instance.setHighestValue(
                this.barsArray.DataDescriptor,
                reversedIndex,
                valueType,
                count,
                result
            )
        }

        return result
    }

    public GetLowestValue(valueType: TValueType, startIndex_lastItem0_based: number, count: number): number {
        //TODO: why this check? why can't StartIndex be 0?
        if (startIndex_lastItem0_based !== 0) {
            const reversedIndex = APIHelperFunctions.Reverse_from_lastItem0_to_firstItem0(
                this.barsArray,
                startIndex_lastItem0_based
            )
            const highestPrice = GlobalHighestLowestPriceCache.Instance.getLowestValue(
                this.barsArray.DataDescriptor,
                reversedIndex,
                valueType,
                count
            )

            if (highestPrice) {
                return highestPrice
            }
        }

        let result = this.GetValueByType(startIndex_lastItem0_based, valueType)
        for (let i = 1; i < count; i++) {
            const v = this.GetValueByType(startIndex_lastItem0_based + i, valueType)
            if (v < result) {
                result = v
            }
        }

        //TODO: why this check? why can't StartIndex be 0?
        if (startIndex_lastItem0_based !== 0) {
            const reversedIndex = APIHelperFunctions.Reverse_from_lastItem0_to_firstItem0(
                this.barsArray,
                startIndex_lastItem0_based
            )
            GlobalHighestLowestPriceCache.Instance.setLowestValue(
                this.barsArray.DataDescriptor,
                reversedIndex,
                valueType,
                count,
                result
            )
        }

        return result
    }
}
