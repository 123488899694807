import { FC, MouseEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import Fade from '@mui/material/Fade'
import Menu from '@mui/material/Menu'
import cx from 'classnames'

import { Flex, Typography } from '@fto/ui'
import { SELECTORS } from '@fto/ui/lib/constants/controledNodes'

import styles from './index.module.scss'

type Option = {
    value: string
    label: string
    icon?: ReactNode
    disabled?: boolean
}

type Position = {
    vertical: 'top' | 'center' | 'bottom'
    horizontal: 'left' | 'center' | 'right'
}

export type TriggerOverlayProps = {
    transformOrigin?: Position
    anchorOrigin?: Position
    onChange?: (value: string) => void
    options?: Option[]
    children: ReactNode

    currentValue?: string
    disabled?: boolean
    onClose?: () => void
    classNames?: {
        option?: string
        list?: string
        paper?: string
    }
    optionsRenderer?: ReactNode | ((handleClose: () => void) => ReactNode)
    outStyledList?: boolean
    isOpen?: boolean | null
    className?: string
    setIsOpened?: (value: boolean) => void
    disableBackdropChartActivity?: boolean
}

export const TriggerOverlay: FC<TriggerOverlayProps> = ({
    children,
    // NOTE: that prop added to define selected option. Now we do not have this state
    currentValue,
    disabled,
    onClose,
    onChange,
    options,
    classNames = {},
    className,
    transformOrigin = { vertical: 'top', horizontal: 'right' },
    anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
    optionsRenderer,
    outStyledList = false,
    setIsOpened,
    isOpen = null,
    disableBackdropChartActivity = false,
    ...props
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = useMemo(() => {
        return isOpen === null ? Boolean(anchorEl) : isOpen
    }, [isOpen, anchorEl])

    useEffect(() => {
        if (isOpen === false) {
            handleClose()
        }
    }, [isOpen])

    const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }, [])

    const handleClose = useCallback(
        (e?: MouseEvent<HTMLElement>) => {
            setAnchorEl(null)
            onClose?.()
            e?.stopPropagation()
            setIsOpened?.(false)
        },
        [onClose, setIsOpened]
    )

    const handleOptionSelect = useCallback(
        (value: Option['value'], event: MouseEvent<HTMLElement>) => {
            onChange?.(value)
            handleClose(event)
        },
        [onChange, handleClose]
    )

    const defaultOptionsRenderer = useMemo(() => {
        if (!options) {
            return null
        }
        return (
            <Flex direction='column' gap={4} block>
                {options.map(({ icon, value, label, disabled }) => (
                    <Flex
                        alignItems='center'
                        gap={8}
                        key={value}
                        className={cx(styles.Option, classNames.list, { [styles.DisabledOption]: disabled })}
                        onClick={(e) => handleOptionSelect(value, e)}
                    >
                        {icon && icon}
                        <Typography
                            color={disabled ? 'gray-500' : 'gray-1000'}
                            type='interface-medium'
                            truncate
                            title={label}
                        >
                            {label}
                        </Typography>
                    </Flex>
                ))}
            </Flex>
        )
    }, [options, disabled, classNames.list, handleOptionSelect])

    const content = useMemo(() => {
        if (optionsRenderer) {
            return typeof optionsRenderer === 'function' ? optionsRenderer(handleClose) : optionsRenderer
        }

        return defaultOptionsRenderer
    }, [optionsRenderer, defaultOptionsRenderer, handleClose])

    useEffect(() => {
        if (setIsOpened) {
            setIsOpened(open)
        }
    }, [open])

    return (
        <div {...props} className={cx({ [styles.Disabled]: disabled })}>
            <div onClick={handleOpen} className={cx({ [styles.DisabledEvent]: disabled })}>
                {children}
            </div>
            <Menu
                id='fade-menu'
                MenuListProps={{
                    'aria-labelledby': 'fade-button'
                }}
                anchorEl={anchorEl}
                open={open}
                //@ts-ignore
                onClose={handleClose}
                TransitionComponent={Fade}
                transformOrigin={transformOrigin}
                anchorOrigin={anchorOrigin}
                classes={{
                    list: cx(SELECTORS.TRIGGER_OVERLAY.className, styles.MenuList, classNames?.list, {
                        [styles.outStyledList]: outStyledList
                    }),
                    paper: cx(styles.Paper, classNames?.paper),
                    root: cx({ [SELECTORS.TRIGGER_OVERLAY_BACK_DROP.className]: disableBackdropChartActivity })
                }}
            >
                {content}
            </Menu>
        </div>
    )
}
