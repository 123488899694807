import { FC, useCallback, useMemo } from 'react'

import { Button, Flex } from '@fto/ui'
import { Icon } from '@fto/icons'

import HistoricalMomentsButton from './components/HistoricalMomentsButton'
import AdditionalJumpToDropdownOptions from '../../JumpToDropdown/components/AdditionalJumpToDropdownOptions'
import { RibbonOptionType } from '../../../types'

import styles from './index.module.scss'

type Props = {
    close: () => void
    options: RibbonOptionType[]
    dropdownVisibleOptions: any
    setDropdownVisibleOptions: (value: any) => void
}

const JumpToDropdownContent: FC<Props> = ({ close, options, dropdownVisibleOptions, setDropdownVisibleOptions }) => {
    const handleClick = useCallback((option: RibbonOptionType) => {
        option.action && option.action()
        close()
    }, [])

    const historicalMomentsVisible = useMemo(() => {
        const foundItem = dropdownVisibleOptions.general.find(
            (item: { value: string; isShown: boolean }) => item.value === 'historicalMoments'
        )
        return foundItem ? foundItem.isShown : false
    }, [dropdownVisibleOptions])

    return (
        <Flex direction='column' justifyContent='flex-start' className={styles.dropdown} gap={4}>
            {historicalMomentsVisible && <HistoricalMomentsButton close={close} />}
            <Flex direction='column' gap={4}>
                {options.map((option) => {
                    return (
                        <Button
                            key={option.value}
                            disabled={option.action === null}
                            block
                            classNames={{ content: styles.buttonContent }}
                            before={option.icon && <Icon name={option.icon} size={18} color={'gray-1000'} />}
                            type='tetriatry-white'
                            size='compact'
                            label={option.name}
                            onClick={() => handleClick(option)}
                        />
                    )
                })}
                <AdditionalJumpToDropdownOptions
                    close={close}
                    dropdownVisibleOptions={dropdownVisibleOptions}
                    setDropdownVisibleOptions={setDropdownVisibleOptions}
                />
            </Flex>
        </Flex>
    )
}

export default JumpToDropdownContent
