import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import styles from './index.module.scss'
import { Flex, Typography } from '@fto/ui'
import SymbolDropdownContainer from './components/SymbolDropdownContainer'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { useTranslation } from 'react-i18next'
import OptionContainer from '../../components/OptionContainer'
import { symbolsRowsConfig } from './constants'
import InputOption from './components/InputOption'
import CheckboxOption from './components/CheckboxOption'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import { TMyObjectList } from '@fto/lib/ft_types/common/Common'

interface Props {}

const SymbolSettingsTab: FC<Props> = () => {
    const { t } = useTranslation()

    const [activeSymbolName, setActiveSymbolName] = useState<string>('')
    const [activeSymbol, setActiveSymbol] = useState<TSymbolData | null>(null)
    const [symbolData, setSymbolData] = useState<{ [key: string]: any } | null>(null)
    const [defaultSymbolData, setDefaultSymbolData] = useState<{ [key: string]: any } | null>(null)
    const [symbolObjectsList, setSymbolObjectsList] = useState<TMyObjectList<TSymbolData>>(
        GlobalSymbolList.SymbolList.Symbols
    )
    const [symbolsList, setSymbolsList] = useState<string[]>(
        GlobalSymbolList.SymbolList.Symbols.map((symbol) => symbol.symbolInfo.SymbolName)
    )

    const updateSymbolObjectsList = useCallback(() => {
        setSymbolObjectsList(GlobalSymbolList.SymbolList.Symbols)
        setSymbolsList(GlobalSymbolList.SymbolList.Symbols.map((symbol) => symbol.symbolInfo.SymbolName))
    }, [])

    const setNewActiveSymbol = useCallback(
        (symbolName: string) => {
            if (!symbolName || symbolName === '') throw new StrangeError('Symbol name is not correct')

            const symbol = symbolObjectsList.find((symbol) => symbol.symbolInfo.SymbolName === symbolName)

            if (!symbol) throw new StrangeError('Symbol object is not found')

            setActiveSymbolName(symbolName)
            setActiveSymbol(symbol)
            setSymbolData({
                Lot: symbol.symbolInfo.lot,
                UseFloatingSpread: symbol.symbolInfo.UseFloatingSpread,
                Spread: symbol.symbolInfo.getSpreadInPoints(),
                Decimals: symbol.symbolInfo.decimals,
                MinDistToPrice: symbol.symbolInfo.MinDistToPrice,
                SwapLong: symbol.symbolInfo.SwapLong,
                SwapShort: symbol.symbolInfo.SwapShort
            })
            setDefaultSymbolData({
                ...symbol.symbolInfo.serverData,
                Spread: symbol.symbolInfo.getSpreadInPoints()
            })
        },
        [symbolObjectsList]
    )

    const updateSymbolVariable = useCallback(
        (key: string, value: any) => {
            if (!activeSymbol) throw new StrangeError('activeSymbol is not defined')

            let data: { [key: string]: any } = {}

            switch (key) {
                case 'Lot':
                    activeSymbol.symbolInfo.lot = value
                    data = { ...symbolData, Lot: value }
                    break
                case 'UseFloatingSpread':
                    activeSymbol.symbolInfo.UseFloatingSpread = value
                    data = { ...symbolData, UseFloatingSpread: value }
                    activeSymbol.clearTickData()
                    break
                case 'Spread':
                    activeSymbol.symbolInfo.setSpreadInPoints(value)
                    activeSymbol.clearTickData()
                    data = { ...symbolData, Spread: value }
                    break
                case 'Decimals':
                    activeSymbol.symbolInfo.decimals = value
                    data = { ...symbolData, Decimals: value }
                    break
                case 'MinDistToPrice':
                    activeSymbol.symbolInfo.MinDistToPrice = value
                    data = { ...symbolData, MinDistToPrice: value }
                    break
                case 'SwapLong':
                    activeSymbol.symbolInfo.SwapLong = value
                    data = { ...symbolData, SwapLong: value }
                    break
                case 'SwapShort':
                    activeSymbol.symbolInfo.SwapShort = value
                    data = { ...symbolData, SwapShort: value }
                    break
                default:
                    throw new StrangeError('Unknown key while editing symbol data')
            }

            setSymbolData((prevData) => ({
                ...prevData,
                ...data
            }))

            activeSymbol.updateSymbolEditableData(data)
            GlobalChartsController.Instance.getActiveChart()?.Repaint()
        },
        [activeSymbol, activeSymbolName, symbolData]
    )

    useEffect(() => {
        const activeChart = GlobalChartsController.Instance.getActiveChart()
        if (activeChart) {
            setNewActiveSymbol(activeChart.SelectedSymbolName)
        }
    }, [])

    return (
        <Flex className={styles.scrollableContainer}>
            <Flex direction='column' gap={16} className={styles.content} block>
                <SymbolDropdownContainer
                    activeSymbol={activeSymbolName}
                    symbols={symbolsList}
                    setActiveSymbol={setNewActiveSymbol}
                    updateSymbols={updateSymbolObjectsList}
                />

                {symbolData && defaultSymbolData && (
                    <>
                        <Typography type='tiny-regular' color='gray-600' className={styles.blockTitle}>
                            {t('settings.symbol.titles.commonOptions')}
                        </Typography>

                        <InputOption
                            value={symbolData.Lot}
                            defaultValue={defaultSymbolData.Lot}
                            optionConfig={symbolsRowsConfig['Lot']}
                            activeSymbolName={activeSymbolName}
                            title={t('settings.symbol.rows.lotSize')}
                            warningTooltip={t('settings.symbol.warnings.lotSizeTooltip')}
                            onInput={(value) => {
                                setSymbolData((prev) => ({ ...prev, Lot: value }))
                            }}
                            onChanged={(value) => {
                                updateSymbolVariable('Lot', value)
                            }}
                        />

                        <CheckboxOption
                            value={symbolData.UseFloatingSpread}
                            activeSymbolName={activeSymbolName}
                            title={t('settings.symbol.rows.floatingSpread')}
                            warningBlockMessage={t('settings.symbol.warnings.floatingSpreadMessage')}
                            onChanged={(value) => {
                                updateSymbolVariable('UseFloatingSpread', value)
                            }}
                        />

                        <OptionContainer level={1}>
                            <InputOption
                                value={symbolData.Spread}
                                defaultValue={defaultSymbolData.Spread}
                                optionConfig={symbolsRowsConfig['Spread']}
                                disabled={symbolData.UseFloatingSpread}
                                activeSymbolName={activeSymbolName}
                                title={t('settings.symbol.rows.spread')}
                                warningTooltip={t('settings.symbol.warnings.spreadTooltip')}
                                warningBlockMessage={t('settings.symbol.warnings.spreadMessage')}
                                onInput={(value) => {
                                    setSymbolData((prev) => ({ ...prev, Spread: value }))
                                }}
                                onChanged={(value) => {
                                    updateSymbolVariable('Spread', value)
                                }}
                            />
                        </OptionContainer>

                        <InputOption
                            value={symbolData.Decimals}
                            defaultValue={defaultSymbolData.Decimals}
                            optionConfig={symbolsRowsConfig['Decimals']}
                            activeSymbolName={activeSymbolName}
                            title={t('settings.symbol.rows.postDecimals')}
                            onInput={(value) => {
                                setSymbolData((prev) => ({ ...prev, Decimals: value }))
                            }}
                            onChanged={(value) => {
                                updateSymbolVariable('Decimals', value)
                            }}
                        />

                        <InputOption
                            value={symbolData.MinDistToPrice}
                            defaultValue={defaultSymbolData.MinDistToPrice}
                            optionConfig={symbolsRowsConfig['MinDistToPrice']}
                            activeSymbolName={activeSymbolName}
                            title={t('settings.symbol.rows.minDistanceToPrice')}
                            warningTooltip={t('settings.symbol.warnings.minDistanceToPrice')}
                            onInput={(value) => {
                                setSymbolData((prev) => ({ ...prev, MinDistToPrice: value }))
                            }}
                            onChanged={(value) => {
                                updateSymbolVariable('MinDistToPrice', value)
                            }}
                        />

                        <Typography type='tiny-regular' color='gray-600' className={styles.blockTitle}>
                            {t('settings.symbol.titles.swaps')}
                        </Typography>

                        <InputOption
                            value={symbolData.SwapLong}
                            defaultValue={defaultSymbolData.SwapLong}
                            optionConfig={symbolsRowsConfig['SwapLong']}
                            activeSymbolName={activeSymbolName}
                            title={t('settings.symbol.rows.swapLong')}
                            warningTooltip={t('settings.symbol.warnings.swapsTooltip')}
                            warningBlockMessage={t('settings.symbol.warnings.swapsMessage')}
                            onInput={(value) => {
                                setSymbolData((prev) => ({ ...prev, SwapLong: value }))
                            }}
                            onChanged={(value) => {
                                updateSymbolVariable('SwapLong', value)
                            }}
                        />

                        <InputOption
                            value={symbolData.SwapShort}
                            defaultValue={defaultSymbolData.SwapShort}
                            optionConfig={symbolsRowsConfig['SwapShort']}
                            activeSymbolName={activeSymbolName}
                            title={t('settings.symbol.rows.swapShort')}
                            warningTooltip={t('settings.symbol.warnings.swapsTooltip')}
                            warningBlockMessage={t('settings.symbol.warnings.swapsMessage')}
                            onInput={(value) => {
                                setSymbolData((prev) => ({ ...prev, SwapShort: value }))
                            }}
                            onChanged={(value) => {
                                updateSymbolVariable('SwapShort', value)
                            }}
                        />
                    </>
                )}
            </Flex>
        </Flex>
    )
}

export default SymbolSettingsTab
