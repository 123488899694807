import { FC, useCallback } from 'react'
import { Checkbox, Flex, Tooltip, Typography } from '@fto/ui'
import styles from './index.module.scss'
import { Icon } from '@fto/icons'
import { tryStopTesting } from '../../../../components/utils/tryStopTesting'
import WarningBlockMessage from '../../../../components/WarningBlockMessage'

export type CheckboxOptionProps = {
    value: boolean
    title: string
    disabled?: boolean
    warningTooltip?: string
    warningBlockMessage?: string
    activeSymbolName?: string
    onChanged: (value: boolean) => void
}

const CheckboxOption: FC<CheckboxOptionProps> = ({
    value,
    title,
    disabled = false,
    warningTooltip,
    warningBlockMessage,
    activeSymbolName,
    onChanged
}) => {
    const onChangeValue = useCallback(
        (value: boolean) => {
            tryStopTesting()
            onChanged(value)
        },
        [onChanged]
    )

    return (
        <Flex direction='column'>
            <Flex alignItems='center'>
                <Checkbox
                    className={styles.checkbox}
                    label={
                        <Typography className={styles.checkboxLabel} type='interface-regular'>
                            {title}
                        </Typography>
                    }
                    block
                    inputControlVariant={'row-reverse'}
                    checked={value}
                    onChange={(value) => {
                        onChangeValue(value)
                    }}
                    disabled={disabled}
                ></Checkbox>
                {warningTooltip && (
                    <Tooltip content={warningTooltip} placement='top' classNames={{ content: styles.WarningTooltip }}>
                        <Icon
                            name='error'
                            size={16}
                            color={disabled ? 'var(--color-gray-500)' : 'var(--color-orange-400)'}
                        />
                    </Tooltip>
                )}
            </Flex>

            <WarningBlockMessage
                text={warningBlockMessage}
                observableValue={value}
                resetObservableItem={activeSymbolName}
            />
        </Flex>
    )
}

export default CheckboxOption
