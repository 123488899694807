import { FC, HTMLProps, RefCallback, useCallback, useState } from 'react'
import ReactSlider from 'react-slider'

import { Flex, Typography } from '@fto/ui'
import ChartSettingsStore from '@fto/lib/store/chartSettings'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'

import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { MAX_SPEED } from './constants'
import { getTestingSpeedDescription } from './utils/testingSpeedDescriptions'

interface HTMLPropsWithRefCallback<T> extends HTMLProps<T> {
    ref: RefCallback<T>
}

const Slider: FC = () => {
    const { t } = useTranslation()

    const { setSettings, updateChartSettings } = ChartSettingsStore
    const [testingSpeed, setTestingSpeed] = useState(
        GlobalProjectJSONAdapter.Instance.getTestingSettings().SliderPosition
    )
    const handleChange = useCallback((value: number) => {
        setTestingSpeed(value)
        setSettings((settings) => ({ ...settings, TestingSpeed: value }))
        updateChartSettings()
        GlobalProjectJSONAdapter.Instance.TestingsSettings.SliderPosition = value
    }, [])

    const renderThumb = useCallback(
        ({ key, ...restProps }: HTMLPropsWithRefCallback<HTMLDivElement>) => <div key={key} {...restProps} />,
        []
    )

    return (
        <Flex direction='column' justifyContent='center' className={styles.container}>
            <Typography className={styles.text} type='subtext-regular' color='gray-800'>
                {t('testingPanel.speed', { value: getTestingSpeedDescription(testingSpeed) })}
            </Typography>
            <div className={styles.sliderWrap}>
                <ReactSlider
                    max={MAX_SPEED}
                    min={1}
                    onChange={handleChange}
                    className={styles.slider}
                    thumbClassName={styles.thumb}
                    trackClassName={styles.track}
                    renderThumb={renderThumb}
                    value={testingSpeed}
                />
                <div className={styles.rangeMarks}>
                    {Array.from({ length: MAX_SPEED }, (_, index) => (
                        <Typography color='gray-600' key={index}>
                            |
                        </Typography>
                    ))}
                </div>
            </div>
        </Flex>
    )
}

export default Slider
