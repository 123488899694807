import ForceSchemesUpdateHelper from '@fto/lib/utils/ForceSchemesUpdateHelper'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useCallback } from 'react'

export const useChartsThemeUpdate = () => {
    const askAboutChartsTheme = useCallback(
        (changToDark: boolean, callBack: (forceUpdateChartsTheme: boolean) => void) => {
            const execute = (shouldChangeChartsColorSchemes: boolean) => {
                callBack(shouldChangeChartsColorSchemes)
                ForceSchemesUpdateHelper.processUpdateCurrentChartsSchemes(changToDark, shouldChangeChartsColorSchemes)
            }

            // Add modal
            addModal(MODAL_NAMES.all.applyThemeChanging, {
                onCancel: () => {
                    execute(false)
                },
                onApply: () => {
                    execute(true)
                }
            })
        },
        []
    )

    return {
        askAboutChartsTheme
    }
}
