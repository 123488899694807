import { FC } from 'react'

import { Flex, InputField, Tooltip, Typography } from '@fto/ui'

import { orderModelType } from '@fto/lib/store/createOrder/types'
import CreateOrderStore from '@fto/lib/store/createOrder'

import styles from './index.module.scss'
import InputCreator from '@root/pages/ChartPage/components/OrderModal/components/ContentBlock/components/InputCreator'
import { useTranslation } from 'react-i18next'
import TextWithHint from '../TextWithHint'

type Props = {
    showRisks?: boolean
    disabled?: boolean
    lot: orderModelType['m_lot']
    riskInUsd: orderModelType['m_riskInUsd']
    riskInPercent: orderModelType['m_riskInPercent']
    priceForLotPoint: orderModelType['m_priceForLotPoint']
    pseudoFocuses: orderModelType['pseudoFocuses']
}
const LotRiskPanel: FC<Props> = ({
    showRisks = false,
    lot,
    riskInUsd,
    riskInPercent,
    priceForLotPoint,
    pseudoFocuses,
    disabled = false
}) => {
    const { t } = useTranslation()

    const { onChange, onInputFocus, onInputBlur } = CreateOrderStore

    if (!showRisks) {
        return (
            <Flex direction='column' gap={4}>
                <Flex alignItems='flex-end' justifyContent='space-between' gap={4} flexGrow={1}>
                    <Typography type='interface-medium' color='gray-900'>
                        {t('orders.modal.lotRisk.lotLabel')}
                    </Typography>
                    <Tooltip content={t('orders.modal.tooltips.lotRequiredMargin')}>
                        <Typography type='interface-regular' color='gray-700'>
                            ${priceForLotPoint}
                        </Typography>
                    </Tooltip>
                </Flex>
                <Tooltip content={t('orders.modal.tooltips.lotInput')} arrow={false} placement='top'>
                    <InputCreator
                        errorMessage={t(`orders.modal.errors.${lot.error.key}`, {
                            value: lot.error.value
                        })}
                        onChange={(value) => {
                            onChange(value === '' ? '' : Number(value), 'm_lot')
                        }}
                        onFocus={(event) => {
                            onInputFocus(Number(event.target.value), 'm_lot')
                        }}
                        onBlur={(event) => {
                            onInputBlur(event.target.value, 'm_lot')
                            if (event.target.value === '') {
                                onChange(0.1, 'm_lot')
                            }
                        }}
                        step={0.01}
                        value={lot.value}
                        block
                        type='number'
                        hasPseudoFocus={pseudoFocuses['m_lot']}
                        hasPseudoError={!!lot.error.key}
                        disabled={disabled}
                    />
                </Tooltip>
            </Flex>
        )
    }

    return (
        <div className={styles.LotRiskPanel}>
            <Flex direction='column' gap={4}>
                <Flex alignItems='flex-end' gap={4}>
                    <TextWithHint
                        label='orders.modal.lotRisk.lotLabel'
                        tooltipText='orders.modal.tooltips.lotInput'
                        type='interface-medium'
                        gap={4}
                        classNames={{ text: styles.hint }}
                    ></TextWithHint>
                    <Tooltip content={t('orders.modal.tooltips.lotRequiredMargin')}>
                        <Typography type='interface-regular' color='gray-700'>
                            ${priceForLotPoint}
                        </Typography>
                    </Tooltip>
                </Flex>
                <Tooltip content={t('orders.modal.tooltips.lotInput')} arrow={false} placement='top'>
                    <InputCreator
                        errorMessage={t(`orders.modal.errors.${lot.error.key}`, {
                            value: lot.error.value
                        })}
                        hasPseudoFocus={pseudoFocuses['m_lot']}
                        hasPseudoError={!!lot.error.key}
                        onFocus={(event) => {
                            onInputFocus(Number(event.target.value), 'm_lot')
                        }}
                        onBlur={(event) => {
                            onInputBlur(Number(event.target.value), 'm_lot')
                        }}
                        step={0.01}
                        value={lot.value}
                        onChange={(value) => {
                            onChange(Number(value), 'm_lot')
                        }}
                        type='number'
                        disabled={disabled}
                    />
                </Tooltip>
            </Flex>
            <Flex direction='column' gap={4}>
                <TextWithHint
                    label='orders.modal.lotRisk.currencyRisk'
                    tooltipText='orders.modal.tooltips.currencyRiskHint'
                    type='interface-medium'
                    gap={4}
                    classNames={{ text: styles.hint }}
                ></TextWithHint>
                <Tooltip content={t('orders.modal.tooltips.currencyRiskInput')} arrow={false} placement='top'>
                    <InputCreator
                        hasPseudoFocus={pseudoFocuses['m_riskInUsd']}
                        hasPseudoError={!!riskInUsd.error.key}
                        errorMessage={t(`orders.modal.errors.${riskInUsd.error.key}`, {
                            value: riskInUsd.error.value
                        })}
                        step={1}
                        onChange={(value) => {
                            onChange(Number(value), 'm_riskInUsd')
                        }}
                        onFocus={(event) => {
                            onInputFocus(Number(event.target.value), 'm_riskInUsd')
                        }}
                        onBlur={(event) => {
                            onInputBlur(Number(event.target.value), 'm_riskInUsd')
                        }}
                        value={riskInUsd.value}
                        type='number'
                        disabled={disabled}
                    />
                </Tooltip>
            </Flex>
            <Flex direction='column' gap={4}>
                <TextWithHint
                    label='orders.modal.lotRisk.percentRisk'
                    tooltipText='orders.modal.tooltips.percentRiskHint'
                    type='interface-medium'
                    gap={4}
                    classNames={{ text: styles.hint }}
                ></TextWithHint>
                <Tooltip content={t('orders.modal.tooltips.percentRiskInput')} arrow={false} placement='top'>
                    <InputCreator
                        step={1}
                        hasPseudoFocus={pseudoFocuses['m_riskInPercent']}
                        hasPseudoError={!!riskInPercent.error.key}
                        errorMessage={t(`orders.modal.errors.${riskInPercent.error.key}`, {
                            value: riskInPercent.error.value
                        })}
                        onFocus={(event) => {
                            onInputFocus(Number(event.target.value), 'm_riskInPercent')
                        }}
                        onBlur={(event) => {
                            onInputBlur(Number(event.target.value), 'm_riskInPercent')
                        }}
                        onChange={(value) => {
                            onChange(Number(value), 'm_riskInPercent')
                        }}
                        value={riskInPercent.value}
                        type='number'
                        disabled={disabled}
                    />
                </Tooltip>
            </Flex>
        </div>
    )
}

export default LotRiskPanel
