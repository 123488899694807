import ProjectsTable from '@root/pages/Dashboard/components/ProjectsTable/ProjectsTable'
import { Flex } from '@fto/ui'
import styles from './ProjectsTable/projects-table.module.scss'
import { CreateProjectModal } from '@root/pages/Dashboard/components/Modals/CreateProjectModal/CreateProjectModal'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { $getProjectQueryParams, $getProjects, $getProjectsStatus } from '@root/store/projects/projects.selector'
import { useEffect } from 'react'
import { setProjectQueryStorage } from '@root/utils/localStorage'
import { GET_PROJECTS_ACTION } from '@root/store/projects/project.actions'
import ProjectsDashboard from './ProjectsDashboard/ProjectsDashboard'

const ProjectsTableWrap = () => {
    const query = useAppSelector($getProjectQueryParams)
    const user = useAppSelector((state) => state.auth.user)
    const projects = useAppSelector($getProjects)

    const status = useAppSelector($getProjectsStatus)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (projects !== null && status === 'idle') {
            dispatch(GET_PROJECTS_ACTION())
        }
        if (user) {
            setProjectQueryStorage({ userId: user.userId, query })
        }
    }, [query])

    return (
        <Flex className={styles.contentContainer} direction={'column'}>
            <ProjectsDashboard query={query} status={status} />
            <CreateProjectModal />
        </Flex>
    )
}

export default ProjectsTableWrap
