import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './components/App/App'
import './i18n'
import './sentryInit'
import '../index.d.ts'

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement)

root.render(<App />)
