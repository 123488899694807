import { FC, useCallback, useState, useEffect } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { Tooltip } from '@fto/ui'

import GraphToolPanelStore from '@fto/lib/store/graphToolPanelStore'

import styles from './index.module.scss'

type Props = {
    tools: any[]
}

const Locker: FC<Props> = ({ tools = [] }) => {
    const [isLocked, setIsLocked] = useState(false)

    const { t } = useTranslation()

    useEffect(() => {
        setIsLocked(GraphToolPanelStore.areToolsLocked())
    }, [tools])

    const handleLockToggle = useCallback(() => {
        const lockedValue = GraphToolPanelStore.toggleLockTools(isLocked, tools)
        setIsLocked(lockedValue)
    }, [tools, isLocked])

    return (
        <Tooltip content={t(`graphToolPanel.tooltips.${isLocked ? 'unlock' : 'lock'}`)} placement='top' color='dark'>
            <div className={cx(styles.root, { [styles.locked]: isLocked })} onClick={handleLockToggle}>
                <Icon
                    size={18}
                    color={isLocked ? 'var(--color-white)' : 'var(--color-gray-900)'}
                    name={isLocked ? 'locked' : 'unlocked'}
                />
            </div>
        </Tooltip>
    )
}

export default Locker
