import { AvailableOptionsSettings, ConfigType } from '../../components/types/options.types'
import { OneClickTradingMode } from '../../../../../../lib/ft_types/common/OptionsUnit'

const dropdownOptions = [
    { value: 0, localeKey: 'settings.chart.values.leftBar' },
    { value: 1, localeKey: 'settings.chart.values.rightBar' },
    { value: 2, localeKey: 'settings.chart.values.center' }
]

const fontSizeOptions = [
    { value: 10, label: '10' },
    { value: 12, label: '12' },
    { value: 14, label: '14' },
    { value: 16, label: '16' },
    { value: 18, label: '18' },
    { value: 20, label: '20' },
    { value: 22, label: '22' },
    { value: 26, label: '26' },
    { value: 30, label: '30' }
]

const GeneralConfig: { [key: string]: AvailableOptionsSettings } = {
    ShowBidAskPanel: {
        key: 'ShowBidAskPanel',
        type: 'checkboxSetting',
        name: 'settings.general.rows.showBidAskPanel',
        booleanKey: 'showBidAskPanel',
        booleanConfigType: ConfigType.GlobalOptions,
        image: 'bidask-panel-hint-colored',
        subOptions: {
            EnableBidAskOrderPlacement: {
                key: 'ShowBidAskPanel',
                type: 'checkboxSetting',
                name: 'settings.general.rows.enableBidAskOrderPlacement',
                description: 'settings.general.rows.enableBidAskOrderPlacement_description',
                booleanKey: 'enableBidAskOrderPlacement',
                booleanConfigType: ConfigType.GlobalOptions,
                ignoreSuboptionLevel: true,
                invertValue: true
            }
        }
    },
    OrderPlacementMode: {
        key: 'OrderPlacementMode',
        type: 'radioSetting',
        valueKey: 'orderPlacementMode',
        valueConfigType: ConfigType.GlobalOptions,
        options: [
            {
                value: OneClickTradingMode.Instant,
                titleKey: 'settings.general.rows.orderPlacementMode_Instant',
                descriptionKey: 'settings.general.rows.orderPlacementMode_Instant_description'
            },
            {
                value: OneClickTradingMode.Confirmation,
                titleKey: 'settings.general.rows.orderPlacementMode_Confirmation',
                descriptionKey: 'settings.general.rows.orderPlacementMode_Confirmation_description'
            }
        ]
    },
    ShowCloseOrderMarker: {
        key: 'ShowCloseOrderMarker',
        type: 'checkboxSetting',
        name: 'settings.chart.rows.showCloseOrderMarker',
        booleanKey: 'showCloseOrderMarker',
        booleanConfigType: ConfigType.GlobalOptions
    },
    ShowOpenOrderMarker: {
        key: 'ShowOpenOrderMarker',
        type: 'checkboxSetting',
        name: 'settings.chart.rows.showOpenOrderMarker',
        booleanKey: 'showOpenOrderMarker',
        booleanConfigType: ConfigType.GlobalOptions
    },
    ScrollingSpeed: {
        key: 'ScrollingSpeed',
        type: 'sliderSetting',
        name: 'settings.chart.rows.scrollSpeed',
        valueConfigType: ConfigType.GlobalOptions,
        valueKey: 'scrollSpeed',
        maxValue: 10,
        divisionFactor: 1,
        withInputField: false
    },
    MouseSensitivity: {
        key: 'MouseSensitivity',
        type: 'inputSetting',
        name: 'settings.chart.rows.mouseSensitivity',
        valueKey: 'mouseSensitivity',
        valueConfigType: ConfigType.GlobalOptions
    },
    MagnetSensitivity: {
        key: 'MagnetSensitivity',
        type: 'inputSetting',
        name: 'settings.chart.rows.magnetSensitivity',
        valueKey: 'magnetSensitivity',
        valueConfigType: ConfigType.GlobalOptions
    },
    MagnetCrossHair: {
        key: 'MagnetCrossHair',
        type: 'checkboxSetting',
        name: 'settings.chart.rows.magnetCrosshair',
        booleanKey: 'magnetCrossHair',
        booleanConfigType: ConfigType.GlobalOptions
    },
    LockTo: {
        key: 'LockTo',
        type: 'dropdownSetting',
        name: 'settings.chart.rows.lockTo',
        valueKey: 'onTFChangeLockTo',
        valueConfigType: ConfigType.GlobalOptions,
        options: dropdownOptions
    },
    MultichartLockTo: {
        key: 'MultichartLockTo',
        type: 'dropdownSetting',
        name: 'settings.chart.rows.multichartLockTo',
        valueKey: 'onScrollLockTo',
        valueConfigType: ConfigType.GlobalOptions,
        options: dropdownOptions
    },
    AxisFontSize: {
        key: 'AxisFontSize',
        type: 'dropdownSetting',
        name: 'settings.chart.rows.axisFontSize',
        valueKey: 'axisFontSize',
        valueKeyMethod: 'setAxisFontSize',
        valueConfigType: ConfigType.GlobalOptions,
        options: fontSizeOptions
    }
}

export default GeneralConfig
