import { t } from 'i18next'

// Now has translations in settings project tab. TODO - add dedicated translations
const UtcCitiesMap: { [key: string]: string } = {
    'UTC-6': 'settings.project.sessionCloseTime_Sydney',
    'UTC-8': 'settings.project.sessionCloseTime_Tokyo',
    'UTC+7': 'settings.project.sessionCloseTime_London',
    'UTC+2': 'settings.project.sessionCloseTime_NewYork'
}

export const getSessionCloseTimeString = (timezone: string) => {
    if (!(timezone in UtcCitiesMap)) return t('settings.project.sessionCloseTime_Custom')
    return t(UtcCitiesMap[timezone]) + ' ' + t('settings.project.sessionCloseTime_Close')
}
