import { IChart } from '@fto/lib/charting/chart_classes/IChart'
import { TestStrategy_MACross } from '@fto/lib/extension_modules/startegies/built_in_strategies/TestStrategy_MACross'
import { Strategy } from '@fto/lib/extension_modules/startegies/Strategy'
import { SimpleBuyStrategy } from '@fto/lib/extension_modules/startegies/tests/benchmarkStrategies/superSimpleStrategy'
import { TTickRec } from '@fto/lib/ft_types/data/TickRec'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

class StrategyInfo {
    public strategy: Strategy
    public fileName: string
    public isRunning = false

    constructor(strategy: Strategy, fileName: string, isRunning: boolean) {
        this.strategy = strategy
        this.fileName = fileName
        this.isRunning = isRunning
    }
}

export default class GlobalStrategiesManager {
    public static Reset(): void {
        this.Instance.clearStrategies()
        this._instance = undefined
    }

    public clearStrategies(): void {
        this._strategies = []
    }

    private static _instance: GlobalStrategiesManager | undefined = undefined
    private _isExecutionAllowed = true
    private _strategies: StrategyInfo[] = []
    private _isTradeAllowed = true

    public get areStrategiesRunning(): boolean {
        return this._strategies.some((strategyInfo: StrategyInfo) => strategyInfo.isRunning)
    }

    public static get Instance(): GlobalStrategiesManager {
        if (!GlobalStrategiesManager._instance) {
            GlobalStrategiesManager._instance = new GlobalStrategiesManager()
        }

        return GlobalStrategiesManager._instance
    }

    private constructor() {}

    public addStrategy(strategy: Strategy, fileName: string): void {
        this._strategies.push(new StrategyInfo(strategy, fileName, false))
    }

    public onTick(trec: TTickRec): void {
        if (this._isExecutionAllowed) {
            this._strategies.forEach((strategyInfo: StrategyInfo) => {
                if (strategyInfo.isRunning) {
                    strategyInfo.strategy.onTick(trec)
                }
            })
        }
    }

    public runStrategy(fileName: string): void {
        this._strategies.forEach((strategyInfo: StrategyInfo) => {
            if (strategyInfo.fileName === fileName && !strategyInfo.isRunning) {
                strategyInfo.isRunning = true
                strategyInfo.strategy.init()
            }
        })
    }

    public stopStrategy(fileName: string): void {
        this._strategies.forEach((strategyInfo: StrategyInfo) => {
            if (strategyInfo.fileName === fileName && strategyInfo.isRunning) {
                strategyInfo.isRunning = false
                strategyInfo.strategy.done()
            }
        })
    }

    public get isTradeAllowed(): boolean {
        return this._isTradeAllowed
    }

    public set isTradeAllowed(value: boolean) {
        this._isTradeAllowed = value
    }
}
