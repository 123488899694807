import { FC, useCallback, MouseEvent } from 'react'
import cx from 'classnames'

import { Flex, Typography, HoverElement, useTheme } from '@fto/ui'
import { Icon } from '@fto/icons'

import TimeframeStore from '@fto/lib/store/timeframe'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import { SyncProjectManager } from '@fto/lib/ProjectAdapter/ProjectSync/SyncProjectManager'

import styles from './index.module.scss'

type Props = {
    isSelected?: boolean
    isTimeFramePreselected?: boolean
    name: string
    isCustom?: boolean
    onTimeframeChange: (val: number) => void
    value: number
    setPreselectedTimeFrame: (value: number) => void
    id?: number
    removePreselectedTimeFrame?: (value: number) => void
}

const Option: FC<Props> = ({
    isSelected,
    isTimeFramePreselected = false,
    name = '',
    isCustom = false,
    onTimeframeChange,
    setPreselectedTimeFrame,
    value,
    id,
    removePreselectedTimeFrame
}) => {
    const { isLight } = useTheme()

    const handleFavoritePreset = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            event.stopPropagation()

            setPreselectedTimeFrame(value)
        },
        [value, setPreselectedTimeFrame]
    )

    const deleteCustomTimeframe = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            event.stopPropagation()

            id && GlobalProjectJSONAdapter.Instance.removeCustomTimeFrame(id)

            TimeframeStore.updateCustomTimeFrames((prev) => prev.filter((tf) => tf.id !== id))
            onTimeframeChange(1)

            removePreselectedTimeFrame?.(value)

            SyncProjectManager.Instance.SaveProject()
        },
        [id, onTimeframeChange, value, removePreselectedTimeFrame]
    )

    const handleTimeFrameChange = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            event.stopPropagation()

            onTimeframeChange(value)
        },
        [onTimeframeChange, value]
    )

    const selectedColor = isLight ? 'white' : 'gray-1000'
    const selectedIconColor = isLight ? 'var(--color-white)' : 'var(--color-gray-1000'

    return (
        <Flex
            justifyContent='space-between'
            gap={8}
            className={cx(styles.Option, {
                [styles.OptionWithOutStar]: !isTimeFramePreselected,
                [styles.Selected]: isSelected
            })}
            alignItems='center'
            onClick={handleTimeFrameChange}
        >
            <Flex gap={8} alignItems='center'>
                <HoverElement
                    onClick={handleFavoritePreset}
                    isSelected={isSelected}
                    withSelectedState
                    classNames={{ root: cx(styles.StarIcon, { [styles.Hide]: !isTimeFramePreselected }) }}
                >
                    <Icon
                        name={isTimeFramePreselected ? 'star' : 'star-empty'}
                        size={20}
                        color={isTimeFramePreselected ? 'var(--color-orange-400)' : 'var(--color-gray-1000'}
                    />
                </HoverElement>
                <Typography
                    truncate
                    title={name}
                    type='interface-regular'
                    color={isSelected ? selectedColor : 'gray-1000'}
                >
                    {name}
                </Typography>
            </Flex>

            {isCustom && (
                <HoverElement
                    onClick={deleteCustomTimeframe}
                    isSelected={isSelected}
                    classNames={{ root: styles.RemoveIcon }}
                >
                    <Icon size={18} name='delete' color={isSelected ? selectedIconColor : 'var(--color-gray-1000'} />
                </HoverElement>
            )}
        </Flex>
    )
}

export default Option
