import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex, InputField, TriggerOverlay, addModal } from '@fto/ui'
import { Icon } from '@fto/icons'
import OrdersStore from '@fto/lib/store/ordersStore'
import { MODAL_NAMES } from '@root/constants/modalNames'
import FilterBlock from '@root/pages/ChartPage/components/Terminal/components/Toolbar/components/FilterBlock'
import FilterDropdown from '@root/pages/ChartPage/components/Terminal/components/Toolbar/components/FilterDropdown'
import { FILTER_BY_SYMBOL_OPTIONS } from '@root/pages/ChartPage/components/Terminal/components/Toolbar/constants/filtersOptions'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { TTradePositionStatus } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import Slider from '@root/components/Slider'

import {
    DateFilterParamsType,
    DateIncludesType,
    FilterBySymbolType,
    OrdersListType,
    SearchBySymbolValueType,
    TabType
} from '../../types'
import ExportSpreadsheetDropdown from '../../../Common/ExportSpreadsheetDropdown'
// import PendingOrdersSelection from './components/PendingOrdersSelection'
import HistoryRange from './components/HistoryRange'

import styles from './index.module.scss'

type Props = {
    activeTab: TabType
    searchValue: string
    setSearchValue: (val: string) => void
    dateFilterParams: DateFilterParamsType
    setDateFitterParams: Dispatch<SetStateAction<DateFilterParamsType>>
    searchBySymbol: FilterBySymbolType
    setSearchByFilter: Dispatch<SetStateAction<FilterBySymbolType>>
    filterByDateParamsIncludes: DateIncludesType
    setFilerByDateParamsIncludes: (value: DateIncludesType) => void
    dataToExport: OrdersListType
}

const Toolbar: FC<Props> = ({
    activeTab,
    searchValue,
    setSearchValue,
    dateFilterParams,
    setDateFitterParams,
    setSearchByFilter,
    searchBySymbol,
    filterByDateParamsIncludes,
    setFilerByDateParamsIncludes,
    dataToExport
}) => {
    const { t } = useTranslation()
    const [isFiltersVisible, setIsFiltersVisible] = useState(false)

    const openOrder = useCallback(() => {
        addModal(MODAL_NAMES.chart.orderModal, { source: 'terminal' })
    }, [])

    const { closeAllOpenOrders, closeAllPendingOrders } = OrdersStore

    const symbolSelectionRenderer = useCallback(
        (onClose: () => void) => {
            const onChange = (value: SearchBySymbolValueType) => {
                onClose()
                setSearchByFilter((prevState) => ({ ...prevState, [activeTab]: value }))
            }

            return (
                <FilterDropdown
                    options={FILTER_BY_SYMBOL_OPTIONS}
                    onChange={onChange}
                    currentValue={searchBySymbol[activeTab]}
                />
            )
        },
        [setSearchByFilter, activeTab, searchBySymbol]
    )

    const processedData = useMemo(() => {
        if (activeTab !== 'history') return []

        const data = dataToExport.map((item) => {
            return {
                ...item,
                openTime: new Date(item.openTime).toLocaleString(),
                type: TTradePositionType[item.type].split('_')[1],
                closeTime: 'closeTime' in item && new Date(item.closeTime).toLocaleString(),
                orderStatus: 'orderStatus' in item && TTradePositionStatus[item.orderStatus].split('_')[1]
            }
        })

        data.sort((a, b) => {
            return a.id - b.id
        })

        return [
            {
                name: 'Trade History',
                data: data
            }
        ]
    }, [dataToExport, activeTab])

    const isHistoryTab = useMemo(() => activeTab === 'history', [activeTab])

    return (
        <Slider
            dependencyListToCheckScroll={[isHistoryTab, isFiltersVisible]}
            classNames={{ root: styles.SliderRoot }}
            step={150}
        >
            <Flex
                gap={8}
                alignItems='center'
                justifyContent='space-between'
                className={styles.Toolbar}
                flexShrink={0}
                flexGrow={1}
            >
                <Flex alignItems='center' gap={8} block={isHistoryTab}>
                    <div className={styles.InputWrapper}>
                        <InputField
                            value={searchValue}
                            onChange={setSearchValue}
                            prefix={<Icon name='search' size={12} />}
                            placeholder={t('terminal.toolbar.inputPlaceholder')}
                            block
                        />
                    </div>
                    <TriggerOverlay
                        optionsRenderer={symbolSelectionRenderer}
                        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <FilterBlock title={t(`terminal.toolbar.symbolFilter.${searchBySymbol[activeTab]}`)} />
                    </TriggerOverlay>
                    {/*{activeTab === 'pending' && <PendingOrdersSelection orderType='all' />}*/}
                    {isHistoryTab && (
                        <Flex justifyContent={'space-between'} flexGrow={1} alignItems='center' gap={16}>
                            <HistoryRange
                                isFiltersVisible={isFiltersVisible}
                                setIsFiltersVisible={setIsFiltersVisible}
                                dateFilterParams={dateFilterParams}
                                setDateFitterParams={setDateFitterParams}
                                setFilerByDateParamsIncludes={setFilerByDateParamsIncludes}
                                filterByDateParamsIncludes={filterByDateParamsIncludes}
                            />
                            <ExportSpreadsheetDropdown data={processedData} filename='Terminal' />
                        </Flex>
                    )}
                </Flex>
                {!isHistoryTab && (
                    <Flex alignItems='center' gap={4}>
                        <Button
                            withTooltip
                            tooltipText={t('terminal.tooltips.closeAll')}
                            type='secondary'
                            label={t('terminal.toolbar.controls.closeAll')}
                            size='tiny'
                            onClick={activeTab === 'open' ? closeAllOpenOrders : closeAllPendingOrders}
                        />
                        <Button
                            withTooltip
                            tooltipText={t('terminal.tooltips.newOrder')}
                            type='primary'
                            label={t('terminal.toolbar.controls.newOrder')}
                            size='tiny'
                            onClick={openOrder}
                        />
                    </Flex>
                )}
            </Flex>
        </Slider>
    )
}

export default Toolbar
