import { createContext, useContext, useMemo, useState, useCallback, useEffect, ReactNode } from 'react'

import { updateRootAttribute, ATTRIBUTE_NAMES } from '../../utils/updateRootAttribute'

export const THEME_KEY = 'FTO_THEME'

export const THEMES = Object.freeze({
    LIGHT: 'light',
    DARK: 'dark'
})

export type ThemeType = (typeof THEMES)[keyof typeof THEMES]

const initialValues = Object.freeze({
    theme: THEMES.LIGHT,
    toggle: () => {},
    isDark: false,
    isLight: true
})

interface ThemeContextType {
    theme: ThemeType
    toggle: () => void
    isDark: boolean
    isLight: boolean
}

const ThemeContext = createContext<ThemeContextType>(initialValues)

export const initTheme = (): ThemeType => {
    const theme = localStorage.getItem(THEME_KEY)
    return theme === THEMES.LIGHT || theme === THEMES.DARK ? theme : THEMES.LIGHT
}

const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const [theme, setTheme] = useState<ThemeType>(initTheme)

    useEffect(() => {
        updateRootAttribute(ATTRIBUTE_NAMES.THEME_MODE, theme)
    }, [theme])

    const toggle = useCallback(() => {
        const newTheme = theme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT
        updateRootAttribute(ATTRIBUTE_NAMES.THEME_MODE, newTheme)
        localStorage.setItem(THEME_KEY, newTheme)
        setTheme(newTheme)
    }, [theme])

    const isDark = useMemo(() => theme === THEMES.DARK, [theme])
    const isLight = useMemo(() => theme === THEMES.LIGHT, [theme])

    const themeAPI = useMemo(() => ({ theme, toggle, isDark, isLight }), [isDark, isLight, theme, toggle])

    return <ThemeContext.Provider value={themeAPI}>{children}</ThemeContext.Provider>
}

export const useTheme = () => useContext(ThemeContext)

export default ThemeProvider
