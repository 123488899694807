import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { DateUtils, TDateTime } from '../../../delphi_compatibility/DateUtils'
import { ChunkBuilder } from '../BarBuilding/chunk_building/ChunkBuilder'
import { TDataFormat } from '../DataEnums'
import { TDataDescriptor } from '../data_arrays/DataDescriptionTypes'
import Bar_JSON_converter from '../data_converters/Bars_converter'
import Bars_converter from '../data_converters/Bars_converter'
import { TDataArrayEvents } from '../data_downloading/DownloadRelatedEnums'
import { ServerDayChunkDataMSGPACK } from '../data_downloading/ServerDataClasses'
import InvalidDataError from '../data_errors/InvalidDataError'
import { TChunkStatus, TNoExactMatchBehavior } from './ChunkEnums'
import TDownloadableChunk from './DownloadableChunk/DownloadableChunk'
import { BASE_URL } from '@fto/lib/ft_types/data/data_downloading/URLConfig'
import { TBarRecord } from '../DataClasses/TBarRecord'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import { DateTransitionStrategy } from '@fto/lib/ft_types/data/chunks/DateTransitionStrategies/DateTransitionStrategy'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DefaultDateTransitionStrategy } from '@fto/lib/ft_types/data/chunks/DateTransitionStrategies/DefaultDateTransitionStrategy'
import { HighTfDateTransitionStrategy } from '@fto/lib/ft_types/data/chunks/DateTransitionStrategies/HighTfDateTransitionStrategy'
import { EMPTY_MIN_MAX_BID_VALUES, IMinMaxBidValues } from '../DataUtils/IMinMaxValues'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { TimeframeUtils } from '../../common/TimeframeUtils'

export class DateTransitionInfo {
    public dt: TDateTime
    public index: number

    constructor(dt: TDateTime, index: number) {
        this.dt = dt
        this.index = index
    }
}

export class TBarChunk extends TDownloadableChunk<TBarRecord> {
    protected fCount: number
    private fFirstGlobalIndex: number
    private fLastDate: TDateTime

    private _ignoreBarCountComparison = false
    private _dateTransitionStrategy: DateTransitionStrategy | null = null

    constructor(
        aDataDescriptor: TDataDescriptor,
        aFirstDate: TDateTime,
        aLastDate: TDateTime,
        aCount: number,
        aFirstGlobalIndex: number
    ) {
        super(aDataDescriptor, aFirstDate)
        this.fLastDate = aLastDate
        this.fCount = aCount
        this.fFirstGlobalIndex = aFirstGlobalIndex
        this._status = TChunkStatus.cs_Empty
        if (aDataDescriptor.timeframe < 1400) {
            this._dateTransitionStrategy = new DefaultDateTransitionStrategy(this)
        } else {
            this._dateTransitionStrategy = new HighTfDateTransitionStrategy(this)
        }
    }

    protected get ChunkSize(): TDateTime {
        return this._dataDescriptor.timeframe * DateUtils.OneMinute
    }

    public onTimezoneorDstChanged() {
        if (this._dateTransitionStrategy) {
            this._dateTransitionStrategy.onTimezoneOrDstChanged()
        } else {
            throw new StrangeError('DateTransitionStrategy is not set')
        }
    }

    public updateTransitionsByPrevChunk(prevChunk: TBarChunk): void {
        if (this._dateTransitionStrategy) {
            this._dateTransitionStrategy.updateTransitionsByPrevChunk(prevChunk)
        } else {
            throw new StrangeError('DateTransitionStrategy is not set')
        }
    }

    public calculateTransitionItems(): void {
        if (this._dateTransitionStrategy) {
            this._dateTransitionStrategy.calculateTransitionItems()
        } else {
            throw new StrangeError('DateTransitionStrategy is not set')
        }
    }

    public getYearTransitionItems(): DateTransitionInfo[] {
        if (this._dateTransitionStrategy) {
            return this._dateTransitionStrategy.getYearTransitionItems()
        } else {
            throw new StrangeError('DateTransitionStrategy is not set')
        }
    }

    public getMonthTransitionItems(): DateTransitionInfo[] {
        if (this._dateTransitionStrategy) {
            return this._dateTransitionStrategy.getMonthTransitionItems()
        } else {
            throw new StrangeError('DateTransitionStrategy is not set')
        }
    }

    public getDayTransitionItems(): DateTransitionInfo[] {
        if (this._dateTransitionStrategy) {
            return this._dateTransitionStrategy.getDayTransitionItems()
        } else {
            throw new StrangeError('DateTransitionStrategy is not set')
        }
    }

    public getTimeTransitionItems(): DateTransitionInfo[] {
        if (this._dateTransitionStrategy) {
            return this._dateTransitionStrategy.getTimeTransitionItems()
        } else {
            throw new StrangeError('DateTransitionStrategy is not set')
        }
    }

    public get Count(): number {
        return this.fCount
    }

    public get FirstGlobalIndex(): number {
        return this.fFirstGlobalIndex
    }

    public get LastPossibleDate(): TDateTime {
        return this.fLastDate //this is equal to the first date of next chunk because of LinkChunk in BarsArray
        // return this.FirstDate + this.ChunkSize - DateUtils.OneMillisecond;
    }

    public set LastPossibleDate(value: TDateTime) {
        this.fLastDate = value
    }

    public set IgnoreBarCountComparison(value: boolean) {
        this._ignoreBarCountComparison = value
    }

    protected StartLoadingData(): void {
        if (this.Status === TChunkStatus.cs_Empty) {
            ChunkBuilder.Instance.BuildChunk(this)
        }
    }

    public GetChunkUrl(): string {
        // Assuming this method returns a URL for the chunk
        let url = `${BASE_URL}data/api/Metadata/bars/chunked?Broker=${this.DataDescriptor.broker}&SymbolCode=${
            this.DataDescriptor.symbolCode
        }&Timeframe=${this.DataDescriptor.timeframe}&Start=${this.FirstGlobalIndex}&End=${
            this.FirstGlobalIndex + this.Count - 1
        }`

        if (this.LoadingDataFormat === TDataFormat.df_MSGPPACK) {
            url += '&UseMessagePack=true'
        } else if (this.LoadingDataFormat === TDataFormat.df_Binary) {
            url += '&UseZip=true&UseMessagePack=false&PriceType=float'
        }

        return url
    }

    public ImportChunkData(data: any, dataFormat: TDataFormat): TChunkStatus {
        DebugUtils.logTopic(
            ELoggingTopics.lt_ChunkLoading,
            'Trying to import BAR chunk data',
            this.DName,
            this.DataDescriptor,
            data.length
        )
        if (data.length === 0) {
            // throw new StrangeError('No data were downloaded for the chunk, all the bar chunks should have data')
            //it is quite possible that there is no data for the requested period, but we still need to mark the chunk as loaded
            this.ValidateDataLength_throwIfInvalid(0)
            this.Status = TChunkStatus.cs_Loaded
            this.saferEmitChunkLoadedEvent()
            return this.Status
        }

        const convertedData = this.ConvertChunkData(dataFormat, data)

        // this.ApplyTimezoneShift(convertedData, GlobalProjectInfo.ProjectInfo.TimeZone)

        this._data = convertedData

        DebugUtils.logTopic(
            [ELoggingTopics.lt_ChunkLoading, ELoggingTopics.lt_ChunkLoadingCompleted],
            'Bar chunk loaded with',
            convertedData.length,
            'bars. Chunk date:',
            DateUtils.DF(this.FirstDate),
            this.DName,
            this.DataDescriptor
        )

        if (this._data.length > 0) {
            this._firstDate = this._data[0].DateTime
        }

        this.Status = TChunkStatus.cs_Loaded

        this.calculateTransitionItems()
        this.saferEmitChunkLoadedEvent()
        this.__debugCheckBarsConsistency()

        return this.Status
    }

    private __debugCheckBarsConsistency() {
        if (DebugUtils.DebugMode) {
            for (let i = 0; i < this._data.length - 1; i++) {
                if (this._data[i].DateTime >= this._data[i + 1].DateTime) {
                    DebugUtils.errorTopic(
                        ELoggingTopics.lt_ChunkLoading,
                        'Bar chunk loaded with inconsistent bars. Chunk date:',
                        DateUtils.DF(this.FirstDate),
                        this.DName,
                        this.DataDescriptor,
                        'Index:',
                        i
                    )
                    break
                }
            }
        }
    }

    private ConvertChunkData(dataFormat: TDataFormat, data: any) {
        let convertedData

        switch (dataFormat) {
            case TDataFormat.df_Json: {
                convertedData = Bar_JSON_converter.ConvertFromJSONToBars(data)
                break
            }
            case TDataFormat.df_MSGPPACK: {
                convertedData = this.GetChunkDataFromMessagePack(data)
                break
            }
            case TDataFormat.df_Binary: {
                convertedData = Bars_converter.ConvertServerBinaryBarsToBarRecords(data)
                break
            }
            case TDataFormat.df_MockObjects: {
                convertedData = Bars_converter.ConvertFromMocksToBars(data)
                break
            }
            case TDataFormat.df_ActualRecords: {
                convertedData = data
                break
            }
            default: {
                this.Status = TChunkStatus.cs_InvalidDataOnServer
                throw new InvalidDataError('Unknown data format')
            }
        }

        this.ValidateDataLength_throwIfInvalid(convertedData.length)

        return convertedData
    }

    private ValidateDataLength_throwIfInvalid(actualDataLength: number) {
        if (actualDataLength !== this.Count) {
            const message = `Incorrect number of bars in chunk. We got ${actualDataLength} bars, but expected ${
                this.Count
            } bars. Chunk TF: ${this.DataDescriptor.timeframe}, first date: ${DateUtils.DF(this.FirstDate)}, Name: ${
                this.DName
            }`
            DebugUtils.error(message)
            if (this._ignoreBarCountComparison) {
                this.fCount = actualDataLength
            } else {
                this.Status = TChunkStatus.cs_InvalidDataOnServer
                this.Events.EmitEvent(TDataArrayEvents.de_LoadingErrorHappened, this, message)
                throw new InvalidDataError(message)
            }
        }
    }

    private GetChunkDataFromMessagePack(data: any): TBarRecord[] {
        const deserializedData = data as ServerDayChunkDataMSGPACK[]

        return Bars_converter.ConvertServerBarsToBarRecordsMSGPACK(deserializedData)
    }

    public GetMinMaxValuesForRange(rangeStartForChunk: TDateTime, rangeEndForChunk: TDateTime): IMinMaxBidValues {
        const result = EMPTY_MIN_MAX_BID_VALUES

        let firstRelevantLocalIndex = this.GetLocalIndexByDateBin(
            rangeStartForChunk,
            TNoExactMatchBehavior.nemb_ReturnNearestLower
        )

        if (firstRelevantLocalIndex < 0) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                'GetMinMaxValuesForRange: firstRelevantLocalIndex < 0'
            )
            firstRelevantLocalIndex = 0
        }

        for (let i = firstRelevantLocalIndex; i < this._data.length; i++) {
            const record = this._data[i]
            if (record.DateTime > rangeEndForChunk) {
                break
            }

            if (record.low < result.minBidPrice) {
                result.minBidPrice = record.low
            }

            if (record.high > result.maxBidPrice) {
                result.maxBidPrice = record.high
            }
        }

        result.wasFound = true

        return result
    }

    public CorrectFirstDateByFirstBar(): void {
        if (this._data && this._data.length > 0) {
            this._firstDate = this._data[0].DateTime
        }
    }
}
