import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { Flex } from '@fto/ui'
import { ViewsSettingsProps, SettingsTabRef } from '../../types'
import { OptionSettingRef } from '../../../../components/types/options.types'
import { AvailableOptionsSettings } from '../../../../components/types/options.types'
import getOptionSettingByConfig from '../../../../components/utils/getOptionSettingByConfig'
import OptionsConfig from './config'
import useChangesObserver from '../../../../hooks/useChangesObserver'

const ViewsSettings = forwardRef<SettingsTabRef, ViewsSettingsProps>(
    ({ chart, applyToAll }, ref) => {
        const optionsRefs = useRef<{[key: string]: OptionSettingRef}>({})
        const { onDifferentSettingsUpdate, executeRequestAndClear } = useChangesObserver(chart, {
            mixPanelEventName: 'chart_settings_updated',
            optionsConfig: OptionsConfig,
        })

        useEffect(() => {
            executeRequestAndClear()
        }, [chart])

        const loadOptionsSetting = useCallback((data: { [key: string]: any }, shouldUpdateConfig = true) => {
            Object.keys(data).forEach((key) => {
                const ref = optionsRefs.current[key]
                if(ref) ref.loadOptionSetting(data[key], shouldUpdateConfig)
            })
        }, [])

        const addRef = useCallback((ref: OptionSettingRef) => {
            if(!ref) return
            optionsRefs.current[ref.name] = ref
        }, [])

        const optionSettingByKey = useCallback(
            (option: AvailableOptionsSettings) => {
                return getOptionSettingByConfig(
                    {
                        tab: 'views',
                        chart,
                        option: option,
                        applyToAll,
                        onDifferentSettingsUpdate,
                    },
                    addRef
                )
            },
            [chart, applyToAll, onDifferentSettingsUpdate, addRef]
        )

        useImperativeHandle(ref, () => ({ loadOptionsSetting }))

        return (
            <Flex direction='column' block gap={8}>
                {optionSettingByKey(OptionsConfig['Volume'])}
                {optionSettingByKey(OptionsConfig['News'])}
                {optionSettingByKey(OptionsConfig['IndicatorValues'])}
                {optionSettingByKey(OptionsConfig['PeriodSeparators'])}
            </Flex>
        )
    }
)

export default ViewsSettings
