import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { Checkbox, Flex, SimpleDropdown, Typography } from '@fto/ui'
import { OptionSettingRef, CheckboxAndDropdownSettingType } from '../../types/options.types'
import checkboxStyles from '../CheckboxSetting/index.module.scss'
import dropdownStyles from '../DropdownSetting/index.module.scss'
import { t } from 'i18next'
import { getBooleanValue, getNumberValue, setBooleanValue, setNumberValue } from '../../utils'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

const CheckboxAndDropdownSetting = forwardRef<OptionSettingRef, CheckboxAndDropdownSettingType>(
    ({ tab, chart, option, applyToAll, shouldBeDisabled, onDifferentSettingsUpdate, setChildsDisabled }, ref) => {
        const [checked, setChecked] = useState<boolean>(() => {
            const value = getBooleanValue(chart, option.booleanConfigType, option.booleanKey)
            return option.invertValue ? !value : value
        })
        const [value, setValue] = useState<number>(() => getNumberValue(chart, option.valueConfigType, option.valueKey))

        const setValueAction = useCallback(
            (value: number, redraw = true, shouldUpdateConfig = true, shouldSendNewSettingtoServer = true) => {
                setValue(value)

                shouldUpdateConfig &&
                    setNumberValue(
                        chart,
                        option.valueConfigType,
                        option.valueKey,
                        value,
                        applyToAll,
                        shouldSendNewSettingtoServer
                    )

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [chart, option, applyToAll]
        )

        const handleChange = useCallback(
            (newValue: number) => {
                onDifferentSettingsUpdate?.(tab, newValue, option.invertValue ? !value : value, option.valueKey)
                setValueAction(newValue)
            },
            [tab, option, value, setValueAction, onDifferentSettingsUpdate]
        )

        const setCheckboxValue = useCallback(
            (value: boolean, redraw = true, shouldUpdateConfig = true, shouldSendNewSettingtoServer = true) => {
                setChecked(value)
                setChildsDisabled(!value)

                shouldUpdateConfig &&
                    setBooleanValue(
                        chart,
                        option.booleanConfigType,
                        option.booleanKey,
                        option.invertValue ? !value : value,
                        applyToAll,
                        shouldSendNewSettingtoServer
                    )

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [chart, option, applyToAll, setChildsDisabled]
        )

        const handleCheckboxChange = useCallback(
            (value: boolean) => {
                onDifferentSettingsUpdate?.(tab, value, checked, option.valueKey)
                setCheckboxValue(value)
            },
            [tab, option, checked, setCheckboxValue, onDifferentSettingsUpdate]
        )

        const loadOptionSetting = useCallback(
            (data: any, shouldUpdateConfig = true) => {
                setValueAction(data.color.value, false, shouldUpdateConfig, false)
                setCheckboxValue(data.checked, false, shouldUpdateConfig, false)
            },
            [setValueAction, setCheckboxValue]
        )

        useImperativeHandle(ref, () => ({ name: option.key, loadOptionSetting }), [option.key, loadOptionSetting])

        return (
            <Flex gap={4} justifyContent='space-between' alignItems='center'>
                <Checkbox
                    className={checkboxStyles.checkbox}
                    label={
                        <Typography
                            className={checkboxStyles.checkboxLabel}
                            type='interface-regular'
                            color={shouldBeDisabled ? 'gray-600' : 'gray-1000'}
                        >
                            {t(option.name)}
                        </Typography>
                    }
                    block
                    inputControlVariant={'row-reverse'}
                    checked={checked}
                    onChange={handleCheckboxChange}
                    disabled={shouldBeDisabled}
                ></Checkbox>
                <SimpleDropdown
                    classNames={{ header: dropdownStyles.dropdown }}
                    options={option.options}
                    value={value}
                    onChange={(value) => handleChange(Number(value))}
                    disabled={shouldBeDisabled}
                />
            </Flex>
        )
    }
)

export default CheckboxAndDropdownSetting
