import { SymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/SymbolCalculationStrategy'
import { TSymbolData } from '@fto/lib/ft_types/data/SymbolData'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'

export class CrossSymbolCalculationStrategy extends SymbolCalculationStrategy {
    public readonly USDConversationSymbol: TSymbolData
    private readonly isUsdConversationSymbolBaseSymbol: boolean
    private readonly isNeedConversionToSymbolQuoteCurrency: boolean

    constructor(
        symbolData: TSymbolData,
        usdConversationSymbol: TSymbolData,
        isUsdConversationBaseCurrency: boolean,
        isNeedConversionToSymbolQuoteCurrency: boolean
    ) {
        super(symbolData)

        if (!usdConversationSymbol) {
            throw new StrangeError('USD conversion symbol must be provided.')
        }

        this.USDConversationSymbol = usdConversationSymbol
        this.isUsdConversationSymbolBaseSymbol = isUsdConversationBaseCurrency
        this.isNeedConversionToSymbolQuoteCurrency = isNeedConversionToSymbolQuoteCurrency
    }

    public getPointCostForOneStandardLot(
        operationType: TTradePositionType,
        openPrice: number | null,
        lotSize?: number
    ): number {
        const pointsInBaseCurrency = this.getSymbolContractSize(lotSize) * this.symbolData.symbolInfo.MinPoint

        const conversionPrice = this.getConversationRate(operationType)

        return pointsInBaseCurrency / conversionPrice
    }

    public calculateMarginRequirementForSymbol(
        operationType: TTradePositionType,
        openPrice: number | null,
        lotSize?: number
    ): number {
        const creditShoulder = GlobalProjectInfo.ProjectInfo.leverage // Leverage

        const conversionPrice = this.getConversationRate(operationType)

        const marginInBaseCurrency = this.getSymbolContractSize(lotSize) / creditShoulder

        if (this.isNeedConversionToSymbolQuoteCurrency) {
            const symbolPrice = openPrice || this.symbolData.getCurrentOpenPriceByOrderType(operationType)
            return (marginInBaseCurrency * symbolPrice) / conversionPrice
        }

        return marginInBaseCurrency / conversionPrice
    }

    private getConversationRate(operationType: TTradePositionType): number {
        const price = this.USDConversationSymbol.getCurrentOpenPriceByOrderType(operationType)

        return this.isUsdConversationSymbolBaseSymbol ? price : 1 / price
    }
}
