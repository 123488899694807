import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { BooleanProperties, ConfigType, NumberProperties, StringProperties, FunctionProperties } from '../types/options.types'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChartOptions } from '@fto/lib/charting/TChartOptions'
import { TOptions } from '@fto/lib/ft_types/common/OptionsUnit'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import GlobalSettings from '@fto/lib/globals/GlobalSettings'
import { TColorScheme } from '@fto/lib/charting/auxiliary_classes_charting/TColorScheme'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import { TProjectInfo } from '@fto/lib/ft_types/common/BasicClasses/ProjectInfo'

export function getBooleanValue(chart: TChartWindow, configType: string, key: string) {
    switch (configType) {
        case ConfigType['ColorScheme']: {
            return chart.ChartOptions.ColorScheme[key as keyof BooleanProperties<TColorScheme>]
        }
        case ConfigType['ChartOptions']: {
            return chart.ChartOptions[key as keyof BooleanProperties<TChartOptions>]
        }
        case ConfigType['GlobalOptions']: {
            return GlobalOptions.Options[key as keyof BooleanProperties<TOptions>]
        }
        case ConfigType['ProjectInfo']: {
            return GlobalProjectInfo.ProjectInfo[key as keyof BooleanProperties<TProjectInfo>]
        }
        // No default
    }
    throw new StrangeError('Unknown configType')
}

export function getStringValue(chart: TChartWindow, configType: string, key: string) {
    switch (configType) {
        case ConfigType['ColorScheme']: {
            return chart.ChartOptions.ColorScheme[key as keyof StringProperties<TColorScheme>]
        }
        case ConfigType['ChartOptions']: {
            return chart.ChartOptions[key as keyof StringProperties<TChartOptions>]
        }
        case ConfigType['GlobalOptions']: {
            return GlobalOptions.Options[key as keyof StringProperties<TOptions>]
        }
        case ConfigType['ProjectInfo']: {
            return GlobalProjectInfo.ProjectInfo[key as keyof StringProperties<TProjectInfo>]
        }
        // No default
    }
    throw new StrangeError('Unknown configType')
}

export function getNumberValue(chart: TChartWindow, configType: string, key: string) {
    switch (configType) {
        case ConfigType['ColorScheme']: {
            return chart.ChartOptions.ColorScheme[key as keyof NumberProperties<TColorScheme>]
        }
        case ConfigType['ChartOptions']: {
            return chart.ChartOptions[key as keyof NumberProperties<TChartOptions>]
        }
        case ConfigType['GlobalOptions']: {
            return GlobalOptions.Options[key as keyof NumberProperties<TOptions>]
        }
        case ConfigType['ProjectInfo']: {
            return GlobalProjectInfo.ProjectInfo[key as keyof NumberProperties<TProjectInfo>]
        }
        // No default
    }
    throw new StrangeError('Unknown configType')
}

export function setBooleanValue(
    chart: TChartWindow,
    configType: string,
    key: string,
    value: boolean,
    applyToAllCharts = false,
    shouldSendNewSettingtoServer = true
) {
    const charts = applyToAllCharts ? GlobalChartsController.Instance.getAllCharts() : [chart]

    switch (configType) {
        case ConfigType['ColorScheme']: {
            for (const chart of charts) {
                if (key in chart.ChartOptions.ColorScheme) {
                    // TODO code better
                    ;(chart.ChartOptions.ColorScheme as any)[key as keyof BooleanProperties<TColorScheme>] = value
                }
            }
            return
        }
        case ConfigType['ChartOptions']: {
            for (const chart of charts) {
                chart.ChartOptions[key as keyof BooleanProperties<TChartOptions>] = value
            }
            return
        }
        case ConfigType['GlobalOptions']: {
            GlobalOptions.Options[key as keyof BooleanProperties<TOptions>] = value
            shouldSendNewSettingtoServer && GlobalSettings.Instance.updateGlobalSettingsRequest()
            return
        }
        case ConfigType['ProjectInfo']: {
            GlobalProjectInfo.ProjectInfo[key as keyof BooleanProperties<TProjectInfo>] = value
            return
        }
        // No default
    }
    throw new StrangeError('Unknown configType')
}

export function setStringValue(
    chart: TChartWindow,
    configType: string,
    key: string,
    value: string,
    applyToAllCharts = false,
    shouldSendNewSettingtoServer = true
) {
    const charts = applyToAllCharts ? GlobalChartsController.Instance.getAllCharts() : [chart]

    switch (configType) {
        case ConfigType['ColorScheme']: {
            for (const chart of charts) {
                chart.ChartOptions.ColorScheme[key as keyof StringProperties<TColorScheme>] = value
            }
            return
        }
        case ConfigType['ChartOptions']: {
            for (const chart of charts) {
                if (key in chart.ChartOptions) {
                    ;(chart.ChartOptions as any)[key as keyof StringProperties<TChartOptions>] = value
                }
            }
            return
        }
        case ConfigType['GlobalOptions']: {
            GlobalOptions.Options[key as keyof StringProperties<TOptions>] = value
            shouldSendNewSettingtoServer && GlobalSettings.Instance.updateGlobalSettingsRequest()
            return
        }
        case ConfigType['ProjectInfo']: {
            GlobalProjectInfo.ProjectInfo[key as keyof StringProperties<TProjectInfo>] = value
            return
        }
        // No default
    }
    throw new StrangeError('Unknown configType')
}

export function setNumberValue(
    chart: TChartWindow,
    configType: string,
    key: string,
    value: number,
    applyToAllCharts = false,
    shouldSendNewSettingtoServer = true
) {
    const charts = applyToAllCharts ? GlobalChartsController.Instance.getAllCharts() : [chart]

    switch (configType) {
        case ConfigType['ColorScheme']: {
            for (const chart of charts) {
                if (key in chart.ChartOptions.ColorScheme) {
                    (chart.ChartOptions.ColorScheme as any)[key as keyof NumberProperties<TColorScheme>] = value
                }
            }
            return
        }
        case ConfigType['ChartOptions']: {
            for (const chart of charts) {
                chart.ChartOptions[key as keyof NumberProperties<TChartOptions>] = value
            }
            return
        }
        case ConfigType['GlobalOptions']: {
            GlobalOptions.Options[key as keyof NumberProperties<TOptions>] = value
            shouldSendNewSettingtoServer && GlobalSettings.Instance.updateGlobalSettingsRequest()
            return
        }
        case ConfigType['ProjectInfo']: {
            (GlobalProjectInfo.ProjectInfo as any)[key as keyof NumberProperties<TProjectInfo>] = value
            return
        }
        // No default
    }
    throw new StrangeError('Unknown configType')
}

export function executeMethod(
    chart: TChartWindow,
    configType: string,
    methodName: string,
    value: any,
    applyToAllCharts = false,
    shouldSendNewSettingtoServer = true
) {
    const charts = applyToAllCharts ? GlobalChartsController.Instance.getAllCharts() : [chart]

    switch (configType) {
        case ConfigType['ColorScheme']: {
            for (const chart of charts) {
                const object = chart.ChartOptions.ColorScheme
                object[methodName as keyof FunctionProperties<TColorScheme>] &&
                    (object[methodName as keyof FunctionProperties<TColorScheme>] as (...args: any) => {})(
                        value as never
                    )
            }
            return
        }
        case ConfigType['ChartOptions']: {
            for (const chart of charts) {
                const object = chart.ChartOptions
                object[methodName as keyof FunctionProperties<TChartOptions>] &&
                    (object[methodName as keyof FunctionProperties<TChartOptions>] as (...args: any) => {})(
                        value as never
                    )
            }
            return
        }
        case ConfigType['GlobalOptions']: {
            const object = GlobalOptions.Options
            object[methodName as keyof FunctionProperties<TOptions>] &&
                (object[methodName as keyof FunctionProperties<TOptions>] as (...args: any) => {})(value)
            shouldSendNewSettingtoServer && GlobalSettings.Instance.updateGlobalSettingsRequest()
            return
        }
        case ConfigType['ProjectInfo']: {
            const object = GlobalProjectInfo.ProjectInfo
            object[methodName as keyof FunctionProperties<TProjectInfo>] &&
                (object[methodName as keyof FunctionProperties<TProjectInfo>] as (...args: any) => {})(value)
            return
        }
        // No default
    }
    throw new StrangeError('Unknown configType')
}
