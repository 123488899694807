import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { Flex, useModalState } from '@fto/ui'

import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'

import { MODAL_NAMES } from '@root/constants/modalNames'
import { ChangeSymbolModal } from '@root/pages/ChartPage/components/ChangeSymbolModal'
import Terminal from '@root/pages/ChartPage/components/Terminal'
import NewsStore from '@fto/lib/store/news'

import MultiChartComponent from '../MultiCharting'
import { ChartInfoType } from '../types/chartTypes'
import Analytics from './components/Analytics'
import Ribbon from './components/Ribbon'
import TestingPanel from './components/TestingPanel'
import GraphToolPanelWrapper from './components/GraphToolPanelWrapper'
import { ToolsSidebar } from './components/ToolsSidebar'

import News from '@fto/chart_components/ProjectInterface/components/News'
import ContextMenuWrapper from './components/ContextMenus/ContextMenuWrapper'
import useChartInitialization from '@root/pages/ChartPage/hooks/useChartInitialization'

import styles from './index.module.scss'
import { getLocalStorage } from '@root/utils/localStorage'
import { PROJECT_STARTED_KEY } from '@root/constants/localStorageKeys'
import { fireMixpanelEvent } from '@root/utils/api'
import { ProjectType } from '@root/types/projects'
import SettingsTab from '@root/pages/ChartPage/components/SettingsTab'
import { AVAILABLE_TABS } from '@root/pages/ChartPage/components/SettingsTab/types/tabs.types'
import KeyboardShortcutsTab from '@root/pages/ChartPage/components/KeyboardShortcutsTab'
import WatchlistTab from '@root/pages/ChartPage/components/WatchlistTab'
import useInitializeSettings from '@root/pages/ChartPage/hooks/useInitializeSettings'
import { TabsSettingsType } from '@fto/chart_components/ProjectInterface/types'
import watchlistStore from '@fto/lib/store/watchlistStore'
import TAB_NAMES from '@fto/chart_components/ProjectInterface/constants/tabsNames'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

const Project: FC = () => {
    const [selectedChartInfo, setSelectedChartInfo] = useState<ChartInfoType>(
        GlobalProjectJSONAdapter.Instance.getLayoutSettings()
    )

    useEffect(() => {
        GlobalChartsController.Instance.setChartSelectionCallback(setSelectedChartInfo)
    }, [setSelectedChartInfo])

    const terminalWindowRef = useRef<HTMLDivElement>(null)

    const [tabsSettings, setTabsSettings] = useState<TabsSettingsType>({
        tabName: '',
        isOpen: false
    })
    const [settingPage, setSettingPage] = useState<AVAILABLE_TABS | null>(null)

    const { open: isChangeSymbolModalOpen, props: changeSymbolModalProps } = useModalState(
        MODAL_NAMES.chart.changeSymbol
    )

    useChartInitialization()
    useInitializeSettings()

    useEffect(() => {
        setSelectedChartInfo(GlobalProjectJSONAdapter.Instance.getLayoutSettings())

        // Fire mixpanel event if there is localstorage key to calculate time of load

        const projectStartedPreselectedEventData: { projectStartedTime: number; projectData: ProjectType } | null =
            getLocalStorage(PROJECT_STARTED_KEY)

        if (projectStartedPreselectedEventData) {
            fireMixpanelEvent('project_started', {
                ...projectStartedPreselectedEventData.projectData,
                project_load_time: (Date.now() - projectStartedPreselectedEventData.projectStartedTime) / 1000,
                total_symbols: projectStartedPreselectedEventData.projectData.Symbols.length
            }).finally(() => {
                localStorage.removeItem(PROJECT_STARTED_KEY)
            })
        }
    }, [])

    useEffect(() => {
        NewsStore.handleNewsTabOpen(tabsSettings.isOpen && tabsSettings.tabName === TAB_NAMES.NEWS)
        watchlistStore.updateIsWatchlistOpen(tabsSettings.isOpen && tabsSettings.tabName === TAB_NAMES.WATCH_LIST)
    }, [tabsSettings])

    const openTab = useCallback((settings: TabsSettingsType) => {
        setTabsSettings({ isOpen: settings.isOpen, tabName: settings.tabName })

        if(settings.tabName === TAB_NAMES.SETTINGS) {
            setSettingPage(settings.settingsPage as AVAILABLE_TABS)
        } else {
            setSettingPage(null)
        }
    }, [])


    const closeTabs = useCallback(() => {
        setTabsSettings({ isOpen: false, tabName: '' })
    }, [])

    return (
        <Flex direction='column' flexGrow={1} className={styles.project} gap={4}>
            <div className={styles.PanelWrapper}>
                <TestingPanel />
                <GraphToolPanelWrapper />
            </div>
            <Ribbon
                selectedChartInfo={selectedChartInfo}
                setSelectedChartInfo={setSelectedChartInfo}
                openTab={openTab}
            />
            <Flex>
                <ToolsSidebar />
                <Flex
                    ref={terminalWindowRef}
                    flexGrow={1}
                    direction='column'
                    className={styles.workspace}
                    justifyContent='space-between'
                >
                    <MultiChartComponent selectedChartInfo={selectedChartInfo} />
                    <Terminal terminalWindowRef={terminalWindowRef} openTab={openTab} />
                </Flex>
                {tabsSettings.tabName === TAB_NAMES.ANALYTICS && (
                    <Flex className={styles.wrapper}>
                        <Analytics closeTabs={closeTabs} />
                    </Flex>
                )}
                {tabsSettings.tabName === TAB_NAMES.NEWS && <News closeTabs={closeTabs} />}
                {tabsSettings.tabName === TAB_NAMES.SETTINGS && settingPage && <SettingsTab tab={settingPage} openTab={openTab} closeTabs={closeTabs} />}
                {tabsSettings.tabName === TAB_NAMES.KEYBOARD_SHORTCUTS && (
                    <KeyboardShortcutsTab closeTabs={closeTabs} />
                )}
                {isChangeSymbolModalOpen && (
                    <ChangeSymbolModal {...changeSymbolModalProps} setSelectedChartInfo={setSelectedChartInfo} />
                )}
                {tabsSettings.tabName === TAB_NAMES.WATCH_LIST && <WatchlistTab closeTabs={closeTabs} />}
                <ContextMenuWrapper openTab={openTab} />
            </Flex>
        </Flex>
    )
}

export default Project
