import { DoubleValidationStrategy } from '../DoubleValidationStrategy'
import { MarketValues, OrderValues } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { ValidationErrorType } from '../ValidationErrorType'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class LotValidationStrategy extends DoubleValidationStrategy {
    private m_pOrderValues: OrderValues
    private m_pMarketValues: MarketValues
    private digitsValidator = new LotDigitsValidation()

    constructor(marketValues: MarketValues, orderValues: OrderValues) {
        super()
        this.m_pOrderValues = orderValues
        this.m_pMarketValues = marketValues
    }

    public isValid(value: string): boolean {
        let result = this.digitsValidator.isValid(value)

        let marginNeeded: number | null = null

        if (this.m_pMarketValues.symbol && this.m_pOrderValues.operationType !== null) {
            const openPrice = null
            const symbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(this.m_pMarketValues.symbol)
            marginNeeded =
                symbol.symbolInfo
                    .getSymbolCalculationStrategy()
                    .calculateMarginRequirementForSymbol(
                        this.m_pOrderValues.operationType,
                        openPrice,
                        this.m_pOrderValues.lotSize
                    ) * parseFloat(value)
        } else {
            throw new StrangeError('MarketValues.symbol is null')
        }

        if (marginNeeded && marginNeeded >= GlobalProcessingCore.ProcessingCore.FreeMargin) {
            result = false
            const errorText = 'notEnoughFreeMargin'
            this.errorDescription = {
                type: ValidationErrorType.WARNING,
                description: errorText
            }
        }

        return result
    }
}

export class LotDigitsValidation extends DoubleValidationStrategy {
    public isValid(value: string): boolean {
        let result = false
        if (super.isValid(value)) {
            const pattern = /^([+-]?(?:\d+\.?|\d*\.\d{1,2}))(?:[Ee][+-]?\d+)?$/
            if (pattern.test(value)) {
                const valueNum = parseFloat(value)
                if (valueNum <= 1000000000.0) {
                    if (valueNum >= 0.01) {
                        result = true
                    } else {
                        const errorText = 'minLotValue'
                        this.errorDescription = {
                            type: ValidationErrorType.CRITICAL,
                            description: errorText
                        }
                    }
                } else {
                    const errorText = 'maxLotValue'
                    this.errorDescription = {
                        type: ValidationErrorType.CRITICAL,
                        description: errorText
                    }
                }
            } else {
                const errorText = 'lotShouldBeMultipleOf'
                this.errorDescription = {
                    type: ValidationErrorType.CRITICAL,
                    description: errorText
                }
            }
        }

        return result
    }
}
