import React, { FC, useState } from 'react'

import { Flex, Typography } from '@fto/ui'

import { Icon } from '@fto/icons'

import styles from './index.module.scss'
import { useDispatch } from 'react-redux'
import { SET_QUERY_PARAM } from '@root/store/projects/project.slice'
import { OrderByType, SortByType } from '@root/utils/query-params.util'
import classNames from 'classnames'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getProjectQueryParams } from '@root/store/projects/projects.selector'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@fto/ui/lib/theme/ThemeProvider'

const OptionsRenderer: FC = () => {
    const { t } = useTranslation()
    const { isDark } = useTheme()

    const query = useAppSelector($getProjectQueryParams)
    const dispatch = useDispatch()
    const [sortBy, setSortBy] = useState<SortByType>(query.SortBy || 'ProjectName')
    const [order, setOrder] = useState<OrderByType>(query.OrderBy || 'asc')

    const sortByOptions = [
        { value: 'ProjectName', label: t('dashboard.toolbar.lastView.options.alphabetical') },
        { value: 'created', label: t('dashboard.toolbar.lastView.options.dateCreated') },
        { value: 'lastUse', label: t('dashboard.toolbar.lastView.options.dayTested') }
        // { value: 'lastViewed', label: t('dashboard.toolbar.lastView.options.lastViewed') }
    ]

    const viewOptions = [
        { value: 'asc', label: t('dashboard.toolbar.lastView.options.orderFirst') },
        { value: 'desc', label: t('dashboard.toolbar.lastView.options.newestFirst') }
    ]

    const onChangeSortBy = (value: SortByType) => {
        setSortBy(value)
        dispatch(
            SET_QUERY_PARAM({
                SortBy: value
            })
        )
    }

    const onChangeOrder = (value: OrderByType) => {
        setOrder(value)
        dispatch(
            SET_QUERY_PARAM({
                OrderBy: value
            })
        )
    }

    return (
        <div className={styles.Root}>
            <Flex direction='column' gap={4} className={styles.OptionsWrapper}>
                <Typography type='tiny-regular' color='gray-600' className={styles.Title}>
                    {t('global.sort')}
                </Typography>
                {sortByOptions.map(({ value, label }) => {
                    const isActive = sortBy === value
                    const selectedColor = isDark ? 'gray-1000' : 'white'

                    return (
                        <Flex
                            onClick={() => onChangeSortBy(value as SortByType)}
                            key={value}
                            gap={8}
                            alignItems='center'
                            className={classNames(styles.Option, { [styles.OptionActive]: isActive })}
                        >
                            <Typography type='interface-regular' color={isActive ? selectedColor : 'gray-1000'}>
                                {label}
                            </Typography>
                        </Flex>
                    )
                })}
            </Flex>
            <Flex direction='column' gap={4} className={styles.OptionsWrapper}>
                <Typography type='tiny-regular' color='gray-600' className={styles.Title}>
                    {t('global.order')}
                </Typography>
                {viewOptions.map(({ value, label }) => {
                    const isActive = order === value
                    const selectedColor = isDark ? 'gray-1000' : 'white'

                    return (
                        <Flex
                            onClick={() => onChangeOrder(value as OrderByType)}
                            key={value}
                            gap={8}
                            alignItems='center'
                            className={classNames(styles.Option, { [styles.OptionActive]: isActive })}
                        >
                            <Typography type='interface-regular' color={isActive ? selectedColor : 'gray-1000'}>
                                {label}
                            </Typography>
                        </Flex>
                    )
                })}
            </Flex>
        </div>
    )
}

export default OptionsRenderer
