import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { MODAL_NAMES } from '@root/constants/modalNames'
import { addModal, Flex, Typography } from '@fto/ui'

import styles from './index.module.scss'

type Props = {
    close: () => void
}

const HistoricalMomentsButton: FC<Props> = ({ close }) => {
    const { t } = useTranslation()

    const openModal = useCallback(() => {
        addModal(MODAL_NAMES.chart.historicalMoments)
        close()
    }, [close])

    return (
        <Flex direction='column' className={styles.historicalMomentsButton} onClick={openModal} gap={4}>
            <Typography type='interface-medium'>{t('ribbon.jumpTo.historicalMoments')}</Typography>
            <Typography type='subtext-regular' color='gray-800'>
                {t('ribbon.jumpTo.historicalDescription')}
            </Typography>
        </Flex>
    )
}

export default HistoricalMomentsButton
