import { ObservableTemplateItem, ObserverTemplate } from './ObserverTemplate'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TRect } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { IChart } from '@fto/lib/charting/chart_classes/IChart'
import ICanvasLayer from '@fto/lib/charting/auxiliary_classes_charting/Layers/ICanvasLayer'
import { ChartControlParams } from './ChartControlParams'
import { IGPFont, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { IChartWindow } from '@fto/lib/charting/chart_windows/IChartWindow'

export enum chartControlEvent {
    SYMBOL_CHANGE_MODAL,
    TIMEFRAME_CHANGE_DROPDOWN,
    INDICATOR_SHOW_INDICATOR_SETTINGS,
    INDICATOR_VISIBILITY_CHANGE,
    INDICATOR_DELETE,
    NEED_REDRAW,
    INDICATOR_SELECTED_BY_CONF_CONTROL,
    BUTTON_PRESSED,
    CONTROL_SELECTED,
    CONTROL_DESELECTED,
    CHART_DELETE,
    NEED_REDRAW_OSC_INDICATOR_CONTROL
}

export enum ChartControlId {
    UNKNOWN,
    INDICATOR_SETTINGS,
    INDICATOR_VISIBILITY,
    INDICATOR_DELETE,
    BAR_INFO,
    INDICATOR_COLLAPSE_CONTROL,
    GROUP,
    INDICATOR_MOVE_TO_LAST_BAR,
    BUTTON_TRY_AGAIN,
    BUTTON_CHANGE_SYMBOL,
    TRY_AGAIN,
    ONE_CLICK_SELL,
    ONE_CLICK_BUY,
    ONE_CLICK_SPREAD,
    CHART_DELETE
}

export enum TooltipPosition {
    Top = 'top',
    Bottom = 'bottom',
    Left = 'left',
    Right = 'right'
}

export class ChartControl {
    protected observableItem: ObservableTemplateItem<
        chartControlEvent,
        ChartControl,
        ObserverTemplate<chartControlEvent, ChartControl>
    >

    private _chartControlParams!: ChartControlParams
    private _isMouseInside = false
    private _isVisible = true
    private _isEnabled = true
    private _parentControl: ChartControl | null = null
    private _isCaptured = false
    public tooltip: string | null = null
    private tooltipOptions = {
        fontSize: 12,
        font: new IGPFont(new TGPFontFamily('Roboto Flex'), 12, []),
        position: TooltipPosition.Top
    }

    public setToolTip(tooltip: string): void {
        this.tooltip = tooltip
    }

    public setTooltipPosition(position: TooltipPosition): void {
        this.tooltipOptions.position = position
    }

    get tooltipBackgroundColor(): string {
        return this.ChartWindow.ChartOptions.ControlsColorScheme.tooltipBackground || '#101828'
    }

    get tooltipTextBrush(): IGPSolidBrush {
        return new IGPSolidBrush(this.ChartWindow.ChartOptions.ControlsColorScheme.tooltipText || '#FFFFFF')
    }

    public showTooltip(canvas: TGdiPlusCanvas, coords: TRect | null = null): void {
        if (!this.tooltip) {
            return
        }

        canvas.graphics.Context.save()

        let location = this.getLocation()
        if (coords) {
            location = coords
        }

        const context = canvas.graphics.Context

        const fontSize = this.tooltipOptions.fontSize
        const position = this.tooltipOptions.position
        const textWidth = canvas.TextWidth(this.tooltip, this.tooltipOptions.font, false)
        const textHeight = fontSize
        const boxWidth = textWidth + 16
        const boxHeight = textHeight + 10
        const centerX = location.Left + location.Width / 2
        const centerY = location.Top + location.Height / 2
        let boxX, boxY, arrowX, arrowY, arrowWidth, arrowHeight

        const radius = 5

        switch (position) {
            case TooltipPosition.Bottom: {
                boxX = centerX - boxWidth / 2
                boxY = location.Bottom + 10
                arrowX = centerX - 5
                arrowY = boxY
                arrowWidth = 10
                arrowHeight = 5
                break
            }
            case TooltipPosition.Left: {
                boxX = location.Left - boxWidth - 10
                boxY = centerY - boxHeight / 2
                arrowX = location.Left - 10
                arrowY = centerY - 5
                arrowWidth = 5
                arrowHeight = 10
                break
            }
            case TooltipPosition.Right: {
                boxX = location.Right + 10
                boxY = centerY - boxHeight / 2
                arrowX = location.Right
                arrowY = centerY - 5
                arrowWidth = 5
                arrowHeight = 10
                break
            }
            case TooltipPosition.Top: {
                boxX = centerX - boxWidth / 2
                boxY = location.Top - boxHeight - 10
                arrowX = centerX - 5
                arrowY = boxY + boxHeight
                arrowWidth = 10
                arrowHeight = 5
                break
            }
        }

        context.fillStyle = this.tooltipBackgroundColor
        context.beginPath()
        context.moveTo(boxX + radius, boxY)
        context.arcTo(boxX + boxWidth, boxY, boxX + boxWidth, boxY + boxHeight, radius)
        context.arcTo(boxX + boxWidth, boxY + boxHeight, boxX, boxY + boxHeight, radius)
        context.arcTo(boxX, boxY + boxHeight, boxX, boxY, radius)
        context.arcTo(boxX, boxY, boxX + boxWidth, boxY, radius)
        context.closePath()
        context.fill()

        context.fillStyle = this.tooltipBackgroundColor
        context.beginPath()
        context.moveTo(arrowX, arrowY)
        if (position === TooltipPosition.Top || position === TooltipPosition.Bottom) {
            context.lineTo(
                arrowX + arrowWidth / 2,
                arrowY + (position === TooltipPosition.Top ? arrowHeight : -arrowHeight)
            )
            context.lineTo(arrowX + arrowWidth, arrowY)
        } else {
            context.lineTo(
                arrowX + (position === TooltipPosition.Left ? arrowWidth : -arrowWidth),
                arrowY + arrowHeight / 2
            )
            context.lineTo(arrowX, arrowY + arrowHeight)
        }
        context.closePath()
        context.fill()

        context.textAlign = 'center'
        context.textBaseline = 'middle'
        canvas.textOut(
            centerX,
            boxY + boxHeight / 2,
            this.tooltip,
            this.tooltipOptions.font,
            this.tooltipTextBrush,
            false
        )

        canvas.graphics.Context.restore()
    }

    public setParentControl(control: ChartControl): void {
        this._parentControl = control
    }

    public getParentControl(): ChartControl | null {
        return this._parentControl
    }

    public isMouseInside(): boolean {
        return this._isMouseInside
    }

    protected onMouseEnterControl(): void {
        this._isMouseInside = true
    }

    protected onMouseLeaveControl(): void {
        this._isMouseInside = false
    }

    setIsMouseInside(value: boolean): void {
        this._isMouseInside = value
    }

    public Show() {
        this._isVisible = true
    }

    public Hide(): void {
        this._isVisible = false
    }

    public Enable(): void {
        this._isEnabled = true
    }

    public Disable(): void {
        this._isEnabled = false
    }

    IsEnabled(): boolean {
        return this._isEnabled
    }

    IsVisible(): boolean {
        return this._isVisible
    }

    Capture(): void {
        this._isCaptured = true
    }

    setCapture(value: boolean): void {
        this._isCaptured = value
    }

    ReleaseCapture(): void {
        this._isCaptured = false
    }

    IsCaptured(): boolean {
        return this._isCaptured
    }

    IsDraggable(): boolean {
        return this._chartControlParams.isDraggable
    }

    capturedMouseMove(event: MouseEvent, sender: IChart): ChartControl | null {
        return this.onMouseMove(event, sender)
    }

    constructor(chartControlParams: ChartControlParams) {
        this.observableItem = new ObservableTemplateItem<
            chartControlEvent,
            ChartControl,
            ObserverTemplate<chartControlEvent, ChartControl>
        >()
        this._chartControlParams = chartControlParams
    }

    public getOwnerLayer(): ICanvasLayer | null {
        return this._chartControlParams.ownerLayer
    }

    public attachObserver(observer: ObserverTemplate<chartControlEvent, ChartControl>): void {
        this.observableItem.attachObserver(observer)
    }

    public detachObserver(observer: ObserverTemplate<chartControlEvent, ChartControl>): void {
        this.observableItem.detachObserver(observer)
    }

    public detachAllObservers(): void {
        this.observableItem.detachAllObservers()
    }

    public notify(event: chartControlEvent): void {
        this.observableItem.notify(event, this)
    }

    onMouseDown(event: MouseEvent, sender: IChart): ChartControl | null {
        const x = event.clientX
        const y = event.clientY
        if (this.IsEnabled() && this.IsVisible() && this.isPointInside(x, y)) {
            this.onMouseEnterControl()
            return this
        }
        return null
    }

    onMouseMove(event: MouseEvent, sender: IChart): ChartControl | null {
        const x = event.clientX
        const y = event.clientY
        if (this.IsEnabled() && this.IsVisible() && this.isPointInside(x, y)) {
            this.onMouseEnterControl()
            return this
        } else {
            this.onMouseLeaveControl()
        }
        return null
    }

    onMouseLeave(event: MouseEvent): ChartControl | null {
        this.onMouseLeaveControl()
        return null
    }

    draw(canvas: TGdiPlusCanvas): void {}

    getLocation(): TRect {
        return this._chartControlParams.getLocation()
    }

    get controlId(): ChartControlId {
        return this._chartControlParams.controlId
    }

    set controlId(id: ChartControlId) {
        this._chartControlParams.controlId = id
    }

    get ChartWindow(): IChartWindow {
        if (!this._chartControlParams.ownerLayer)
            throw new StrangeError('ChartControlParams ownerLayer is null. We cannot get ChartWindow')

        return this._chartControlParams.ownerLayer?.getChartWindow()
    }

    protected isPointInside(x: number, y: number): boolean {
        const location = this.getLocation()
        return x >= location.Left && x <= location.Right && y >= location.Top && y <= location.Bottom
    }

    public isPointInsidePublic(x: number, y: number): boolean {
        return this.isPointInside(x, y)
    }

    setLocation(location: TRect): void {
        this._chartControlParams.setLocation(location)
    }

    getHeight(): number {
        return this._chartControlParams.getLocation().Bottom - this._chartControlParams.getLocation().Top
    }

    getWidth(): number {
        return this._chartControlParams.getLocation().Right - this._chartControlParams.getLocation().Left
    }

    onBrowserWndSizing(): void {}

    onMouseUp(event: MouseEvent, sender: IChart) {}

    onDblClick(event: MouseEvent, sender: IChart): ChartControl | null {
        const x = event.clientX
        const y = event.clientY

        if (this.IsEnabled() && this.IsVisible() && this.isPointInside(x, y)) {
            return this
        }

        return null
    }
}
