export enum TTradePositionStatus {
    tps_Cancelled,
    tps_Filled,
    tps_None
}

export enum TOperationType {
    ot_Buy,
    ot_Sell
}

export enum TPendingOpType {
    pt_BuyLimit,
    pt_BuyStop,
    pt_SellLimit,
    pt_SellStop
}

export enum TTrailingStopType {
    ts_Immediate,
    ts_OnProfit
}

export enum TTestingMethod {
    tm_FirstDate = 0,
    tm_DefineDate = 1
}

export enum TChartType {
    ct_Normal,
    ct_RangeBars,
    ct_RenkoBars,
    ct_Profit
}

export enum TCommApplyType {
    ca_OpenClose,
    ca_Open,
    ca_Close
}

export enum TSymbolType {
    st_CurrencyPair = 0,
    st_Stock = 1,
    st_Crypto = 2,
    st_Index = 3
}

export enum MarginCalculationMode {
    Forex = 0,
    CFD = 1,
    Futures = 2,
    CFD_Indices = 3
}

export enum ProfitCalculationMode {
    Forex = 0,
    CFD = 1,
    Futures = 2
}

export enum SymbolEvent {
    UPDATED_SYMBOL_VALUE
}
