import { AvailableOptionsSettings, ConfigType } from '../../../../components/types/options.types'

const ViewsConfig: { [key: string]: AvailableOptionsSettings } = {
    Volume: {
        key: 'Volume',
        type: 'checkboxAndColorSetting',
        name: 'settings.chart.rows.volume',
        colorKey: 'VolumeColor',
        colorConfigType: ConfigType.ColorScheme,
        booleanKey: 'ShowVolume',
        booleanConfigType: ConfigType.ChartOptions
    },
    News: {
        key: 'News',
        type: 'checkboxSetting',
        name: 'settings.chart.rows.news',
        booleanKey: 'ShowNews',
        booleanConfigType: ConfigType.ChartOptions
    },
    IndicatorValues: {
        key: 'IndicatorValues',
        type: 'checkboxSetting',
        name: 'settings.chart.rows.indicators',
        booleanKey: 'ShowIndicatorValues',
        booleanConfigType: ConfigType.ChartOptions
    },
    PeriodSeparators: {
        key: 'PeriodSeparators',
        type: 'checkboxAndColorSetting',
        name: 'settings.chart.rows.periodSeparators',
        colorKey: 'PeriodSeparatorsColor',
        colorConfigType: ConfigType.ColorScheme,
        booleanKey: 'ShowPeriodSeparators',
        booleanConfigType: ConfigType.ChartOptions
    }
}

export default ViewsConfig
