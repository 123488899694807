import { MarketValues, OrderValues } from '../OrderWndStructs'
import { DoubleValidationStrategy } from './DoubleValidationStrategy'
import { ValidationErrorType } from './ValidationErrorType'

export class PriceValidationStrategy extends DoubleValidationStrategy {
    protected orderValues: OrderValues
    protected marketValues: MarketValues

    protected getMinDistanceToPrice(): number {
        return this.orderValues.minDistanceToPrice ?? this.marketValues.minimumDistanceToPrice
    }

    protected getMinimumDistanceToPriceStr(): string {
        return Math.round(this.getMinDistanceToPrice()).toString()
    }

    public isValid(value: string): boolean {
        let result = false

        if (super.isValid(value)) {
            const valueNum = parseFloat(value)

            if (valueNum > 1000000.0) {
                const errorText = 'maxPriceTooBig'
                this.errorDescription = {
                    type: ValidationErrorType.CRITICAL,
                    description: errorText
                }
            } else if (valueNum <= 0.0) {
                const errorText = 'priceLessOrEqualZero'
                this.errorDescription = {
                    type: ValidationErrorType.CRITICAL,
                    description: errorText
                }
            } else {
                result = true
            }
        }
        return result
    }

    constructor(marketValues: MarketValues, orderValues: OrderValues) {
        super()
        this.marketValues = marketValues
        this.orderValues = orderValues
    }
}
