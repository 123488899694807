import { NotImplementedError } from '@fto/lib/utils/common_utils'
import { ITechnicalIndicatorsProcRec } from '../CommonProcRecInterfaces/ITechnicalIndicatorsProcRec'
import { TPriceType, MacdMode, E_MAType } from '../CommonTypes'
import IFMBarsArray from '@fto/lib/ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import { ICurrentSymbolAndTFInfoProcRec } from '../CommonProcRecInterfaces/ICurrentSymbolInfoProcRec'
import { API_InvalidInitialization } from '../errors/API_InvalidInitialization'
import ISymbolData from '@fto/lib/ft_types/data/ISymbolData'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'
import { IProcRecsEveryImplementationNeeds } from './IProcRecsEveryImplementationNeeds'
import CommonMACalculations from '@fto/lib/common/commonCalculations/commonMACalculations'

export class TechnicalIndicatorsImplementation
    extends BasicProcRecImplementation
    implements ITechnicalIndicatorsProcRec
{
    public GetImplementation(): ITechnicalIndicatorsProcRec {
        return {
            iMACD: this.iMACD.bind(this),
            GetMA: this.GetMA.bind(this),
            LRCChannelParams: this.LRCChannelParams.bind(this),
            iMA: this.iMA.bind(this)
        }
    }

    protected override generateDName(): string {
        return `API_TechnicalIndicators_${super.generateDName()}`
    }

    private currSymbolAndTFInfoProcRec: ICurrentSymbolAndTFInfoProcRec

    private barsArrayGetter: () => IFMBarsArray
    private symbolDataGetter: () => ISymbolData

    private get bars() {
        if (!this.barsArrayGetter) {
            throw new API_InvalidInitialization('barsArrayGetter is not assigned.')
        }
        const bars = this.barsArrayGetter()
        if (!bars) {
            throw new API_InvalidInitialization('barsArrayGetter returned null.')
        }
        return bars
    }

    private get symbolData() {
        if (!this.symbolDataGetter) {
            throw new API_InvalidInitialization('symbolDataGetter is not assigned.')
        }
        const symbolData = this.symbolDataGetter()
        if (!symbolData) {
            throw new API_InvalidInitialization('symbolDataGetter returned null.')
        }
        return symbolData
    }

    constructor(
        procRecsEveryoneNeeds: IProcRecsEveryImplementationNeeds,
        currSymbolAndTFInfoProcRec: ICurrentSymbolAndTFInfoProcRec,
        barsArrayGetter: () => IFMBarsArray,
        symbolDataGetter: () => ISymbolData
    ) {
        super(procRecsEveryoneNeeds)
        this.currSymbolAndTFInfoProcRec = currSymbolAndTFInfoProcRec
        this.barsArrayGetter = barsArrayGetter
        this.symbolDataGetter = symbolDataGetter
    }

    private GetPrice(index: number, priceType: TPriceType): number {
        return this.currSymbolAndTFInfoProcRec.GetPrice(index, priceType)
    }

    private get timeframe(): number {
        return this.bars.DataDescriptor.timeframe
    }

    public LRCChannelParams(
        shift: number,
        period: number,
        priceType: TPriceType
    ): { StartValue: number; EndValue: number; Height: number; Top: number; Bottom: number } {
        let sum_x = 0,
            sum_y = 0,
            sum_xy = 0,
            sum_x2 = 0,
            y = 0

        let i = shift

        // Calculating sums for regression line
        for (let x = 0; x < period; x++) {
            y = this.GetPrice(i, priceType)
            sum_x += x
            sum_y += y
            sum_xy += x * y
            sum_x2 += x * x
            i++
        }

        // Calculating regression line
        const b = (period * sum_xy - sum_x * sum_y) / (period * sum_x2 - sum_x * sum_x)
        const a = (sum_y - b * sum_x) / period

        // Calculating channel height
        i = shift
        let max = 0

        for (let x = 0; x < period; x++) {
            y = a + b * x
            const z = Math.abs(this.GetPrice(i, priceType) - y)
            if (z > max) max = z
            i++
        }

        return { StartValue: a + b * period, EndValue: a, Height: max, Top: a + max, Bottom: a - max }
    }

    public iMACD(
        symbol: string | null,
        timeframe: number | null,
        fastEmaPeriod: number,
        slowEmaPeriod: number,
        signalPeriod: number,
        appliedPrice: TPriceType,
        mode: MacdMode,
        index: number
    ): number {
        throw new NotImplementedError('Method not implemented. iMACD')
    }

    public iMA(
        symbol: string,
        timeframe: number,
        period: number,
        ma_shift: number,
        maMethod: E_MAType,
        appliedPrice: TPriceType,
        shift: number
    ): number {
        return CommonMACalculations.calculateMA(symbol, timeframe, period, ma_shift, maMethod, appliedPrice, shift)
    }

    public GetMA(
        index: number,
        ma_shift: number,
        period: number,
        maMethod: E_MAType,
        appliedPrice: TPriceType,
        prev?: number
    ): number {
        return CommonMACalculations.calculateMA(
            this.symbolData.symbolInfo.SymbolName,
            this.timeframe,
            period,
            ma_shift,
            maMethod,
            appliedPrice,
            index,
            prev
        )
    }
}
