import { FC, ReactNode, useCallback, useRef, useEffect, useState, useMemo } from 'react'
import cx from 'classnames'

import { Icon } from '@fto/icons'
import { useTheme } from '@fto/ui'

import { checkIsThereScrollLeft, checkIsThereScrollRight } from './utils'
import { OrientationType } from './types'

import styles from './index.module.scss'

type Props = {
    children: ReactNode
    orientation?: OrientationType
    dependencyListToCheckScroll?: any[]
    classNames?: {
        root?: string
        container?: string
        controllerLeft?: string
        controllerRight?: string
    }
    step?: number
}

const Slider: FC<Props> = ({
    children,
    dependencyListToCheckScroll = [],
    classNames = {},
    step,
    orientation = 'horizontal'
}) => {
    const [hasScrollLeft, setHasScrollLeft] = useState(false)
    const [hasScrollRight, setHasScrollRight] = useState(false)
    const sliderRef = useRef<HTMLDivElement>(null)

    const { isLight } = useTheme()

    const isHorizontal = useMemo(() => orientation === 'horizontal', [orientation])

    const checkScroll = useCallback(() => {
        if (sliderRef.current) {
            setHasScrollLeft(checkIsThereScrollLeft(sliderRef.current, isHorizontal))
            setHasScrollRight(checkIsThereScrollRight(sliderRef.current, isHorizontal))
        }
    }, [hasScrollLeft, hasScrollRight, isHorizontal])

    useEffect(() => {
        // Initial scroll check
        checkScroll()

        // Add event listeners for window resize
        const handleResize = () => checkScroll()
        window.addEventListener('resize', handleResize)

        // Create a ResizeObserver to track changes in the size of the slider element
        const sliderElement = sliderRef.current
        let resizeObserver: ResizeObserver | null = null
        const handleScroll = () => checkScroll()

        if (sliderElement) {
            sliderElement.addEventListener('scroll', handleScroll)

            resizeObserver = new ResizeObserver(() => {
                checkScroll() // Update scroll controls when size changes
            })
            resizeObserver.observe(sliderElement)
        }

        // Cleanup listeners on unmount
        return () => {
            window.removeEventListener('resize', handleResize)
            if (sliderElement) {
                sliderElement.removeEventListener('scroll', handleScroll)
            }
            if (resizeObserver && sliderElement) {
                resizeObserver.unobserve(sliderElement)
                resizeObserver.disconnect()
            }
        }
    }, [checkScroll, ...dependencyListToCheckScroll])

    const handleScrollButtonClick = (isLeft: boolean) => {
        const defaultStep = isHorizontal
            ? window.innerWidth - window.innerWidth * 0.25
            : window.innerHeight - window.innerHeight * 0.1
        const scrollStep = step || defaultStep

        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                [isHorizontal ? 'left' : 'top']: isLeft ? -scrollStep : scrollStep,
                behavior: 'smooth'
            })

            // After scrolling, check the scroll state again
            setTimeout(checkScroll, 300) // Ensure the scroll state updates after the smooth scroll
        }
    }

    const arrowsColor = isLight ? 'var(--color-white)' : 'var(--color-gray-1000)'

    return (
        <div className={cx(styles.Root, classNames.root, { [styles.VerticalOrientation]: orientation === 'vertical' })}>
            {hasScrollLeft && (
                <div
                    onClick={() => handleScrollButtonClick(true)}
                    className={cx(styles.Controller, classNames.controllerLeft, {
                        [styles.VerticalOrientationController]: !isHorizontal
                    })}
                >
                    <Icon name='arrow-left' size={15} color={arrowsColor} />
                </div>
            )}
            <div className={cx(styles.Slider, classNames.container)} ref={sliderRef}>
                {children}
            </div>
            {hasScrollRight && (
                <div
                    onClick={() => handleScrollButtonClick(false)}
                    className={cx(styles.ControllerRight, classNames.controllerRight, {
                        [styles.VerticalOrientationControllerRight]: !isHorizontal
                    })}
                >
                    <Icon name='arrow-left' size={15} color={arrowsColor} />
                </div>
            )}
        </div>
    )
}

export default Slider
