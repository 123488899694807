import React, { FC, useCallback, useMemo, useState } from 'react'
import { Resizable } from 're-resizable'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import { addContextMenu, Flex } from '@fto/ui'

import globalChartsStore from '@fto/lib/store/globalChartsStore'
import OrdersStore from '@fto/lib/store/ordersStore'
import { terminalOrderHistoryPositionType } from '@fto/lib/store/ordersStore/types'

import {
    HISTORY_CELLS_LIST,
    OPEN_POSITION_CELLS_LIST,
    PENDING_ORDERS_CELLS_LIST
} from '@root/pages/ChartPage/components/Terminal/components/Table/constants/defaultCellsList'
import {
    filterHistoryOrdersByDate,
    filterOrdersBySymbolSelection
} from '@root/pages/ChartPage/components/Terminal/utils'

import InfoPanel from './components/InfoPanel'
import Table from './components/Table'
import Tabs from './components/Tabs'
import Toolbar from './components/Toolbar'
import { ColumnsListType, DateFilterParamsType, DateIncludesType, FilterBySymbolType, TabType } from './types/index'

import { TerminalSortingFunction } from '@root/pages/ChartPage/components/Terminal/components/Table/utils'
import useTableSettingsHook from '@root/pages/ChartPage/components/Terminal/hooks/table-settings.hook'
import terminalResizingHook from '@root/pages/ChartPage/components/Terminal/hooks/terminal-resizing'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'
import { useAppSelector } from '@root/hooks/useStoreHook'
import { $getUser } from '@root/store/auth/selectors'
import { TERMINAL_COLUMNS_SIZE } from '@root/constants/localStorageKeys'
import { SELECTORS } from '@fto/ui/lib/constants/controledNodes'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import { TabsSettingsType } from '@fto/chart_components/ProjectInterface/types'
import Slider from '@root/components/Slider'

import styles from './index.module.scss'

type Props = {
    terminalWindowRef: React.RefObject<HTMLDivElement>
    openTab: ({ isOpen, tabName }: TabsSettingsType) => void
}

const Terminal: FC<Props> = observer(({ openTab, terminalWindowRef }) => {
    const { userId } = useAppSelector($getUser)
    const settingsKey = `${userId}_${TERMINAL_COLUMNS_SIZE}`
    const [activeTab, setActiveTab] = useState<TabType>('open')
    const [isOpen, setIsOpen] = useState(false)
    const [openPositionCellsList, setOpenPositionCellsList] = useState<ColumnsListType>(OPEN_POSITION_CELLS_LIST)
    const [pendingOrderCellsList, setPendingOrderCellsList] = useState<ColumnsListType>(PENDING_ORDERS_CELLS_LIST)
    const [historyCellsList, setHistoryCellsList] = useState<ColumnsListType>(HISTORY_CELLS_LIST)
    const [searchValue, setSearchValue] = useState('')
    const [searchBySymbol, setSearchByFilter] = useState<FilterBySymbolType>({
        open: 'all',
        pending: 'all',
        history: 'all'
    })

    const { terminalSize, terminalMinHeight, onResize, onResizeStop } = terminalResizingHook({
        terminalWindowRef,
        isOpen,
        setIsOpen,
        settingsKey
    })

    const [dateFilterParams, setDateFitterParams] = useState<DateFilterParamsType>({
        rangeType: 'hole',
        selectedHole: 'all',
        rangeData: { from: new Date(), to: new Date() }
    })
    const { tableSettings, setTableSettings } = useTableSettingsHook()

    const [filterByDateParamsIncludes, setFilerByDateParamsIncludes] = useState<DateIncludesType>('openAndCloseDate')

    const { data } = OrdersStore
    const {
        data: { activeChartSymbol }
    } = globalChartsStore

    const { orders, pnl, equity, balance, selectedPositionsIds } = data

    const positionsList = useMemo(() => {
        const list = orders[activeTab]
        const sortingRules = tableSettings[activeTab].sorting
        const isHistoryTab = activeTab === 'history'

        if (!searchValue && !isHistoryTab && searchBySymbol[activeTab] === 'all') {
            return TerminalSortingFunction(list, sortingRules)
        }

        const filteredList = list.filter((order) => {
            const isMatchedWithID = String(order.id) === searchValue || !searchValue

            const isMatchedWithSymbol = filterOrdersBySymbolSelection(
                order.symbol,
                activeChartSymbol,
                searchBySymbol[activeTab]
            )

            if (isHistoryTab) {
                return (
                    isMatchedWithID &&
                    filterHistoryOrdersByDate(
                        order as terminalOrderHistoryPositionType,
                        dateFilterParams,
                        filterByDateParamsIncludes
                    ) &&
                    isMatchedWithSymbol
                )
            }
            return isMatchedWithID && isMatchedWithSymbol
        })
        return TerminalSortingFunction(filteredList, sortingRules)
    }, [
        orders,
        activeTab,
        searchValue,
        dateFilterParams,
        activeChartSymbol,
        searchBySymbol,
        filterByDateParamsIncludes,
        tableSettings
    ])

    const columnsParams = useMemo(() => {
        const params = {
            open: {
                cellsList: openPositionCellsList,
                setCellsList: setOpenPositionCellsList
            },
            pending: {
                cellsList: pendingOrderCellsList,
                setCellsList: setPendingOrderCellsList
            },
            history: {
                cellsList: historyCellsList,
                setCellsList: setHistoryCellsList
            }
        }

        return params[activeTab]
    }, [
        activeTab,
        openPositionCellsList,
        setOpenPositionCellsList,
        pendingOrderCellsList,
        setPendingOrderCellsList,
        historyCellsList,
        setHistoryCellsList
    ])

    const handleTerminalToggle = useCallback(() => {
        setIsOpen((prev) => !prev)
    }, [])

    const handleContextMenu = useCallback(
        (event: { clientX: number; clientY: number }) => {
            if (activeTab === 'history') return
            addContextMenu(CONTEXT_MENU_NAMES.terminal, {
                anchorX: event.clientX,
                anchorY: event.clientY,
                additionalProps: { activeTab }
            })
        },
        [activeTab]
    )

    const maxTerminalHeight = terminalWindowRef.current?.clientHeight || 0

    return (
        <Resizable
            className={classNames(SELECTORS.TERMINAL.className, styles.TerminalResizerElem)}
            size={{ width: '100%', height: terminalSize.height }}
            defaultSize={terminalSize}
            onResize={onResize}
            onResizeStop={onResizeStop}
            minHeight={terminalMinHeight}
            maxHeight={maxTerminalHeight}
            enable={{
                top: true,
                right: false,
                bottom: false,
                left: false,
                topRight: false,
                bottomRight: false
            }}
        >
            <div className={styles.TerminalResizerLine} />
            <Flex className={styles.Terminal} direction='column' gap={8} onContextMenu={handleContextMenu}>
                <Slider classNames={{ root: styles.SliderRoot }} step={150}>
                    <Flex alignItems='center' justifyContent='space-between' gap={8}>
                        <Tabs
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            isOpen={isOpen}
                            handleTerminalToggle={handleTerminalToggle}
                            openOrdersLength={orders.open.length}
                            pendingOrdersLength={orders.pending.length}
                        />
                        <InfoPanel
                            balance={balance}
                            equity={equity}
                            pnl={pnl}
                            time={GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTimeWithTimezoneAndDST()}
                            openTab={openTab}
                        />
                    </Flex>
                </Slider>

                {isOpen && (
                    <>
                        <Toolbar
                            dateFilterParams={dateFilterParams}
                            setDateFitterParams={setDateFitterParams}
                            activeTab={activeTab}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            searchBySymbol={searchBySymbol}
                            setSearchByFilter={setSearchByFilter}
                            filterByDateParamsIncludes={filterByDateParamsIncludes}
                            setFilerByDateParamsIncludes={setFilerByDateParamsIncludes}
                            dataToExport={positionsList}
                        />

                        <Table
                            selectedOrders={selectedPositionsIds}
                            tableSettings={tableSettings}
                            setTableSettings={setTableSettings}
                            activeTab={activeTab}
                            positions={positionsList}
                            {...columnsParams}
                        />
                    </>
                )}
            </Flex>
        </Resizable>
    )
})

export default Terminal
