import { FC, useCallback, useEffect, useState } from 'react'

import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TBasicPaintTool } from '@fto/lib/charting/paint_tools/BasicPaintTool'

import { addModal, Button, Flex, Typography, Separator } from '@fto/ui'
import { Icon } from '@fto/icons'

import styles from '../common.module.scss'
import { useTranslation } from 'react-i18next'
import { TPtRiskReward } from '@fto/lib/charting/paint_tools/SpecificTools/ptRiskReward'
import { MODAL_NAMES } from '@root/constants/modalNames'
import CreateOrderStore, { registerOnInitOnce } from '@fto/lib/store/createOrder'
import { determineOrderTypeFromRR, getMarketValuesFromChart } from '@fto/lib/store/createOrder/helper'
import { OrderType } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import { ORDER_TYPE_TO_LOCAL_KEY_MAP } from '@root/pages/ChartPage/components/OrderModal/constants/tabs'
import GraphToolPanelStore from '@fto/lib/store/graphToolPanelStore'

type Props = {
    chart: TChartWindow
    tools: TBasicPaintTool[]
    removeMenu: () => void
}

function getPriceAndTypeOfOrder(tool: TPtRiskReward): {
    orderType: OrderType
    openPrice: number
} {
    const marketValues = getMarketValuesFromChart(tool.chart.ChartWindow)
    const orderType = determineOrderTypeFromRR(tool, marketValues)

    return {
        orderType,
        openPrice: tool.OpenPrice
    }
}

const isSomeVisibleTool = (tools: TBasicPaintTool[]) =>
    tools.some((tool) => tool.IsVisible() && tool.IsVisibleOnCurrTimeframe())

const ToolMenuContent: FC<Props> = ({ chart, tools, removeMenu }) => {
    const { updateParams } = GraphToolPanelStore

    useEffect(() => {
        updateParams((prevSettings) => ({ ...prevSettings, isOpen: !prevSettings.isOpen }))
    }, [updateParams])

    const { t } = useTranslation()

    const [isVisible, setIsVisible] = useState(isSomeVisibleTool(tools))
    const [isLocked, setIsLocked] = useState(GraphToolPanelStore.areToolsLocked())

    const singleTool = tools[0]
    const isRRTool = tools.length === 1 && singleTool instanceof TPtRiskReward

    const isMultipleTools = tools.length > 1

    const priceAndType = isRRTool ? getPriceAndTypeOfOrder(singleTool) : null

    const handleNewOrder = useCallback(() => {
        const { importFromRRTool } = CreateOrderStore
        if (tools.length !== 1) return
        const tool = tools[0]
        if (!(tool instanceof TPtRiskReward)) return
        addModal(MODAL_NAMES.chart.orderModal)

        registerOnInitOnce(() => {
            importFromRRTool(tool)
        })
        removeMenu()
    }, [removeMenu, tools])

    const handleDuplicate = useCallback(() => {
        chart.duplicateSelectedPaintTools()
        removeMenu()
    }, [chart, removeMenu])

    const handleLockToggle = useCallback(() => {
        const lockedValue = GraphToolPanelStore.toggleLockTools(isLocked, tools)
        setIsLocked(lockedValue)
    }, [tools, isLocked])

    const handleSettings = useCallback(() => {
        tools[0].EditTool()
        removeMenu()
    }, [tools, removeMenu])

    const handleDelete = useCallback(() => {
        chart.deleteSelectedPaintTools()
        removeMenu()
    }, [chart, removeMenu])

    const handleShowHide = useCallback(() => {
        if (isVisible) {
            chart.HideSelectedTools()
            setIsVisible(false)
        } else {
            chart.ShowSelectedTools()
            setIsVisible(true)
        }

        removeMenu()
    }, [isVisible, chart, removeMenu])

    const handleCopy = useCallback(() => {
        for (const tool of tools) {
            tool.Selected = true
        }
        chart.copyPaintTools()
        removeMenu()
    }, [tools, chart, removeMenu])

    const bringToFront = useCallback(() => {
        chart.bringToolsToFront(tools)
        removeMenu()
    }, [chart, tools])

    const sendToBack = useCallback(() => {
        chart.sendToolsToBack(tools)
        removeMenu()
    }, [tools, chart])

    return (
        <Flex direction='column' gap={4} className={styles.content}>
            <Typography className={styles.header} type='tiny-regular' color='gray-600'>
                {isMultipleTools
                    ? t('contextMenu.selectedGraphicTools', { count: tools.length })
                    : t(`graphicTools.toolNames.${tools[0]?.name}`)}
            </Typography>
            <Button
                before={<Icon name='bring-to-front' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('graphToolPanel.bringToFront')}
                onClick={bringToFront}
                block
            />

            <Button
                before={<Icon name='send-to-back' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('graphToolPanel.sendToBack')}
                onClick={sendToBack}
                block
            />
            <Separator width='90%' />
            <Button
                before={<Icon name='duplicate' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('global.duplicate')}
                onClick={handleDuplicate}
                block
            />
            <Button
                before={<Icon name='copy' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('contextMenu.copy')}
                onClick={handleCopy}
                block
            />
            <div className={styles.separator} />
            <Separator width='90%' />
            <Button
                before={<Icon name={isVisible ? 'eye-off' : 'eye'} size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t(isVisible ? 'contextMenu.hide' : 'contextMenu.show')}
                onClick={handleShowHide}
                block
            />
            <Button
                before={<Icon name={isLocked ? 'unlocked' : 'locked'} size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t(isLocked ? 'contextMenu.unlock' : 'contextMenu.lock')}
                onClick={handleLockToggle}
                block
            />
            <Button
                before={<Icon name='delete' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('global.delete')}
                onClick={handleDelete}
                block
            />
            <Separator width='90%' />
            {isRRTool && priceAndType && (
                <>
                    <Button
                        before={<Icon name='new-order' size={16} />}
                        classNames={{ content: styles.text }}
                        size='tiny'
                        type='tetriatry-white'
                        label={t('global.openPendingOrder', {
                            price: chart.SymbolData.FormatPriceToStr(priceAndType.openPrice),
                            orderType: t(ORDER_TYPE_TO_LOCAL_KEY_MAP[priceAndType.orderType])
                        })}
                        onClick={handleNewOrder}
                        block
                    />
                    <Separator width='90%' />
                </>
            )}
            {!isMultipleTools && (
                <Button
                    before={<Icon name='settings' size={16} />}
                    classNames={{ content: styles.text }}
                    size='tiny'
                    type='tetriatry-white'
                    label={t('global.settings')}
                    onClick={handleSettings}
                    block
                />
            )}
        </Flex>
    )
}

export default ToolMenuContent
