export const highlightSearchText = (text: string, searchValue: string, fontWeight: number) => {
    if (!searchValue) return text

    const regex = new RegExp(`(${searchValue})`, 'gi')
    return text.split(regex).map((part, index) =>
        regex.test(part) ? (
            <span key={index} style={{ color: 'var(--color-primary-500)', fontWeight: fontWeight }}>
                {part}
            </span>
        ) : (
            part
        )
    )
}
