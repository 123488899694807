import { IMockMap } from '@fto/lib/mocks/MocksForTesting/MockDataInterfaces'
import { DateUtils } from '../../../delphi_compatibility/DateUtils'
import { TBarChunk } from '../chunks/BarChunk'
import { TListOfChunks } from '../chunks/ListOfChunks'
import { TDataDescriptor } from '../data_arrays/DataDescriptionTypes'
import { TServerChunkInMapJSON, TServerChunkInMapMSGPACK } from '../data_downloading/ServerDataClasses'
import { TimeframeUtils } from '../../common/TimeframeUtils'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import CommonUtils from '../../common/BasicClasses/CommonUtils'

export default class Map_converter {
    public static ConvertMockServerChunksToEmptyChunks(
        chunksMapMockData: IMockMap,
        DataDescriptor: TDataDescriptor
    ): TListOfChunks<TBarChunk> {
        const newEmptyChunks = new TListOfChunks<TBarChunk>()

        for (let i = 0; i < chunksMapMockData.chunkInfos.length; i++) {
            const mockMapRecord = chunksMapMockData.chunkInfos[i]
            const newChunk = this.InitNewChunk_basedOnServerData(
                DateUtils.toUnixTimeSeconds(mockMapRecord.Start),
                DateUtils.toUnixTimeSeconds(mockMapRecord.End),
                mockMapRecord.BarsCount,
                mockMapRecord.FirstBarIndex,
                DataDescriptor
            )
            newEmptyChunks.push(newChunk)
        }

        return newEmptyChunks
    }

    public static ConvertMSGPackServerChunksToEmptyChunks(
        chunksMapServerData: TServerChunkInMapMSGPACK[],
        dataDescriptor: TDataDescriptor
    ): TListOfChunks<TBarChunk> {
        const newEmptyChunks = new TListOfChunks<TBarChunk>()

        this.__validateServerChunks_andApplyHotfixes(chunksMapServerData)

        let cumulativeGlobalIndex = 0

        // eslint-disable-next-line unicorn/no-for-loop
        for (let i = 0; i < chunksMapServerData.length; i++) {
            const serverChunkInfo = chunksMapServerData[i]
            const startDateUnixSeconds = serverChunkInfo.s
            let endDateUnixSeconds
            if (serverChunkInfo.n > 0) {
                endDateUnixSeconds = serverChunkInfo.n
            } else {
                endDateUnixSeconds = serverChunkInfo.e
            }
            const count = serverChunkInfo.c
            //FIXME: return the original logic once server is fixed
            // const firstGlobalIndex = serverChunkInfo.i

            const newChunk = this.InitNewChunk_basedOnServerData(
                startDateUnixSeconds,
                endDateUnixSeconds,
                count,
                cumulativeGlobalIndex,
                dataDescriptor
            )

            newEmptyChunks.push(newChunk)
            cumulativeGlobalIndex += count
        }

        return newEmptyChunks
    }

    private static __validateServerChunks_andApplyHotfixes(chunksMapServerData: TServerChunkInMapMSGPACK[]): void {
        if (chunksMapServerData.length === 0) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Empty chunks map')
        }

        if (chunksMapServerData[0].i !== 0) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation('FirstBarIndex is not 0 in the map')
            //Hotfix for the server bug
            if (chunksMapServerData.length === 1) {
                chunksMapServerData[0].i = 0
            } else {
                throw new StrangeError('FirstBarIndex is not 0 in the map')
            }
        }

        let cumulativeGlobalIndex = 0

        for (const serverChunk of chunksMapServerData) {
            if (serverChunk.i !== cumulativeGlobalIndex) {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Chunks are not consecutive')
                return
            }
            if (serverChunk.c < 0) {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Negative count in server chunk')
            }
            if (serverChunk.i < 0) {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Negative first bar index in server chunk')
            }
            if (!CommonUtils.IsGoodUnixDateSeconds(chunksMapServerData[0].s)) {
                throw new StrangeError(`Bad start date in chunk ${chunksMapServerData}`)
            }
            if (!CommonUtils.IsGoodUnixDateSeconds(chunksMapServerData[0].e)) {
                throw new StrangeError(`Bad end date in chunk ${chunksMapServerData}`)
            }
            if (!CommonUtils.IsGoodUnixDateSeconds(serverChunk.n)) {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation(`Bad next date in chunk ${chunksMapServerData}`)
                //hotfix for the server bug
                if (chunksMapServerData.length === 1) {
                    serverChunk.n = serverChunk.e
                }
            }
            cumulativeGlobalIndex += serverChunk.c
        }
    }

    static ConvertJSONServerChunksToEmptyChunks(
        ChunksMapStr: string,
        dataDescriptor: TDataDescriptor
    ): TListOfChunks<TBarChunk> {
        const chunksMapServerData: TServerChunkInMapJSON[] = JSON.parse(ChunksMapStr)

        const newEmptyChunks = new TListOfChunks<TBarChunk>()

        for (const serverChunk of chunksMapServerData) {
            const newChunk = this.InitNewChunk_basedOnServerData(
                serverChunk.Start,
                serverChunk.End,
                serverChunk.BarsCount,
                serverChunk.FirstBarIndex,
                dataDescriptor
            )
            newEmptyChunks.push(newChunk)
        }

        return newEmptyChunks
    }

    private static InitNewChunk_basedOnServerData(
        startDate_unixSeconds: number,
        endDate_unixSeconds: number,
        count: number,
        firstGlobalIndex: number,
        dataDescriptor: TDataDescriptor
    ): TBarChunk {
        const barChunk = new TBarChunk(
            dataDescriptor,
            DateUtils.fromUnixTimeSeconds(startDate_unixSeconds),
            DateUtils.fromUnixTimeSeconds(endDate_unixSeconds),
            count,
            firstGlobalIndex
        )
        return barChunk
    }
}
