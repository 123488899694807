/* eslint-disable @typescript-eslint/no-shadow */
//MQL-like constants:

//imitation of the MQL4 values
export const __DATE__ = '2024.09.16 00:00:00'
export const __DATETIME__ = '2024.09.16 19:09:47'
export const __FILE__ = 'N/A'
export const __FUNCSIG__ = 'N/A'
export const __FUNCTION__ = 'N/A'
export const __LINE__ = 0
export const __MQLBUILD__ = 2409
export const __MQL4BUILD__ = 2409

export const MODE_FAST = 2
export const MODE_SLOW = 3

//ENUM_APPLIED_PRICE
export const PRICE_CLOSE = 0
export const PRICE_OPEN = 1
export const PRICE_HIGH = 2
export const PRICE_LOW = 3
export const PRICE_MEDIAN = 4
export const PRICE_TYPICAL = 5
export const PRICE_WEIGHTED = 6
export const cl_GridColor = 0x01000000

export const tlTop = 0
export const tlCenter = 1
export const tlBottom = 2

export const taLeftJustify = 0
export const taRightJustify = 1
export const taCenter = 2

//unchangeable constants

export const ALIGN_CENTER = 2
export const ALIGN_LEFT = 1
export const ALIGN_RIGHT = 0
export const ANCHOR_BOTTOM = 1
export const ANCHOR_CENTER = 8
export const ANCHOR_LEFT = 1
export const ANCHOR_LEFT_LOWER = 2
export const ANCHOR_LEFT_UPPER = 0
export const ANCHOR_LOWER = 3
export const ANCHOR_RIGHT = 5
export const ANCHOR_RIGHT_LOWER = 4
export const ANCHOR_RIGHT_UPPER = 6
export const ANCHOR_TOP = 0
export const ANCHOR_UPPER = 7
export const BORDER_FLAT = 0
export const BORDER_RAISED = 1
export const BORDER_SUNKEN = 2
export const CHAR_MAX = 127
export const CHAR_MIN = -128

export const clrNONE = 'clrNONE'
export const CLR_NONE = clrNONE

export const CORNER_LEFT_LOWER = 2
export const CORNER_LEFT_UPPER = 0
export const CORNER_RIGHT_LOWER = 3
export const CORNER_RIGHT_UPPER = 1
export const CP_ACP = 0
export const CP_OEMCP = 1
export const CP_SYMBOL = 42
export const CP_THREAD_ACP = 3
export const CP_UTF7 = 65000
export const CP_UTF8 = 65001
export const CRYPT_AES128 = 1
export const CRYPT_AES256 = 2
export const CRYPT_ARCH_ZIP = 7
export const CRYPT_BASE64 = 0
export const CRYPT_DES = 3
export const CRYPT_HASH_MD5 = 6
export const CRYPT_HASH_SHA1 = 4
export const CRYPT_HASH_SHA256 = 5
export const DBL_DIG = 15
export const DBL_EPSILON = Number.EPSILON
export const DBL_MANT_DIG = 53
export const DBL_MAX = Number.MAX_VALUE
export const DBL_MAX_10_EXP = 308
export const DBL_MAX_EXP = 1024
export const DBL_MIN = Number.MIN_VALUE
export const DBL_MIN_10_EXP = -307
export const DBL_MIN_EXP = -1021
export const DRAW_ARROW = 3
export const DRAW_HISTOGRAM = 2
export const DRAW_LINE = 0
export const DRAW_NONE = 12
export const DRAW_SECTION = 1
export const DRAW_ZIGZAG = 4
export const EMPTY = -1
export const EMPTY_VALUE = Number.MAX_SAFE_INTEGER
export const FLT_DIG = 6
export const FLT_EPSILON = Number.EPSILON
export const FLT_MANT_DIG = 24
export const FLT_MAX = 3.402823466e38
export const FLT_MAX_10_EXP = 38
export const FLT_MAX_EXP = 128
export const FLT_MIN = 0.0
export const FLT_MIN_10_EXP = -37
export const FLT_MIN_EXP = -125
export const GANN_DOWN_TREND = 1
export const GANN_UP_TREND = 0
export const IDABORT = 3
export const IDCANCEL = 2
export const IDCONTINUE = 11
export const IDIGNORE = 5
export const IDNO = 7
export const IDOK = 1
export const IDRETRY = 4
export const IDTRYAGAIN = 10
export const IDYES = 6
export const INT_MAX = 2147483647
export const INT_MIN = -2147483648
export const INVALID_HANDLE = -1
export const LONG_MAX = Number.MAX_SAFE_INTEGER
export const LONG_MIN = Number.MIN_SAFE_INTEGER
export const M_1_PI = 0.3183098861837907
export const M_2_PI = 0.6366197723675814
export const M_2_SQRTPI = 1.128379167095513
export const M_E = 2.718281828459045
export const M_LN10 = 2.302585092994046
export const M_LN2 = 0.6931471805599453
export const M_LOG10E = 0.4342944819032518
export const M_LOG2E = 1.442695040888963
export const M_PI = 3.141592653589793
export const M_PI_2 = 1.570796326794897
export const M_PI_4 = 0.7853981633974483
export const M_SQRT1_2 = 0.7071067811865476
export const M_SQRT2 = 1.414213562373095
export const MB_ABORTRETRYIGNORE = 2
export const MB_CANCELTRYCONTINUE = 6
export const MB_DEFBUTTON1 = 0
export const MB_DEFBUTTON2 = 256
export const MB_DEFBUTTON3 = 512
export const MB_DEFBUTTON4 = 768
export const MB_ICONEXCLAMATION = 48
export const MB_ICONWARNING = 48
export const MB_ICONINFORMATION = 64
export const MB_ICONASTERISK = 64
export const MB_ICONQUESTION = 32
export const MB_ICONSTOP = 16
export const MB_ICONERROR = 16
export const MB_ICONHAND = 16
export const MB_OK = 0
export const MB_OKCANCEL = 1
export const MB_RETRYCANCEL = 5
export const MB_YESNO = 4
export const MB_YESNOCANCEL = 3
export const NULL = null
export const OP_BUY = 0
export const OP_BUYLIMIT = 2
export const OP_BUYSTOP = 4
export const OP_SELL = 1
export const OP_SELLLIMIT = 3
export const OP_SELLSTOP = 5
export const PERIOD_CURRENT = 0
export const PERIOD_D1 = 1440
export const PERIOD_H1 = 60
export const PERIOD_H12 = 720
export const PERIOD_H2 = 120
export const PERIOD_H3 = 180
export const PERIOD_H4 = 240
export const PERIOD_H6 = 360
export const PERIOD_H8 = 480
export const PERIOD_M1 = 1
export const PERIOD_M10 = 10
export const PERIOD_M12 = 12
export const PERIOD_M15 = 15
export const PERIOD_M2 = 2
export const PERIOD_M20 = 20
export const PERIOD_M3 = 3
export const PERIOD_M30 = 30
export const PERIOD_M4 = 4
export const PERIOD_M5 = 5
export const PERIOD_M6 = 6
export const PERIOD_MN1 = 43200
export const PERIOD_W1 = 10080
export const POINTER_AUTOMATIC = 2
export const POINTER_DYNAMIC = 1
export const POINTER_INVALID = 0
export const SATURDAY = 6
export const SUNDAY = 0
export const SYMBOL_ARROWDOWN = 242
export const SYMBOL_ARROWUP = 241
export const SYMBOL_CHECKSIGN = 252
export const SYMBOL_LEFTPRICE = 5
export const SYMBOL_RIGHTPRICE = 6
export const SYMBOL_STOPSIGN = 251
export const SYMBOL_THUMBSDOWN = 68
export const SYMBOL_THUMBSUP = 67
export const THURSDAY = 4
export const TUESDAY = 2
export const UCHAR_MAX = 255
export const UINT_MAX = 4294967295
export const ULONG_MAX = Number.MAX_SAFE_INTEGER
export const USHORT_MAX = 65535
export const WHOLE_ARRAY = 0
export const WRONG_VALUE = -1

export const CHART_AUTOSCROLL = 4
export const CHART_BARS = 0
export const CHART_BEGIN = 0
export const CHART_BRING_TO_TOP = 35
export const CHART_CANDLES = 1
export const CHART_COLOR_ASK = 31
export const CHART_COLOR_BACKGROUND = 21
export const CHART_COLOR_BID = 30
export const CHART_COLOR_CANDLE_BEAR = 29
export const CHART_COLOR_CANDLE_BULL = 28
export const CHART_COLOR_CHART_DOWN = 26
export const CHART_COLOR_CHART_LINE = 27
export const CHART_COLOR_CHART_UP = 25
export const CHART_COLOR_FOREGROUND = 22
export const CHART_COLOR_GRID = 23
export const CHART_COLOR_LAST = 32
export const CHART_COLOR_STOP_LEVEL = 33
export const CHART_COLOR_VOLUME = 24
export const CHART_COMMENT = 20
export const CHART_CURRENT_POS = 1
export const CHART_DRAG_TRADE_LEVELS = 43
export const CHART_END = 2
export const CHART_EVENT_MOUSE_MOVE = 40
export const CHART_EVENT_OBJECT_CREATE = 38
export const CHART_EVENT_OBJECT_DELETE = 39
export const CHART_FIRST_VISIBLE_BAR = 104
export const CHART_FIXED_MAX = 8
export const CHART_FIXED_MIN = 9
export const CHART_FIXED_POSITION = 41
export const CHART_FOREGROUND = 1
export const CHART_HEIGHT_IN_PIXELS = 107
export const CHART_IS_OFFLINE = 112
export const CHART_LINE = 2
export const CHART_MODE = 0
export const CHART_MOUSE_SCROLL = 42
export const CHART_POINTS_PER_BAR = 11
export const CHART_PRICE_MAX = 109
export const CHART_PRICE_MIN = 108
export const CHART_SCALE = 5
export const CHART_SCALE_PT_PER_BAR = 10
export const CHART_SCALEFIX = 6
export const CHART_SCALEFIX_11 = 7
export const CHART_SHIFT = 2
export const CHART_SHIFT_SIZE = 3
export const CHART_SHOW_ASK_LINE = 14
export const CHART_SHOW_BID_LINE = 13
export const CHART_SHOW_DATE_SCALE = 36
export const CHART_SHOW_GRID = 17
export const CHART_SHOW_LAST_LINE = 15
export const CHART_SHOW_OBJECT_DESCR = 19
export const CHART_SHOW_OHLC = 12
export const CHART_SHOW_PERIOD_SEP = 16
export const CHART_SHOW_PRICE_SCALE = 37
export const CHART_SHOW_TRADE_LEVELS = 34
export const CHART_SHOW_VOLUMES = 18
export const CHART_VISIBLE_BARS = 100
export const CHART_VOLUME_HIDE = 0
export const CHART_VOLUME_TICK = 1
export const CHART_WIDTH_IN_BARS = 105
export const CHART_WIDTH_IN_PIXELS = 106
export const CHART_WINDOW_HANDLE = 103
export const CHART_WINDOW_IS_VISIBLE = 102
export const CHART_WINDOW_YDISTANCE = 110
export const CHART_WINDOWS_TOTAL = 101
export const CHARTEVENT_CHART_CHANGE = 9
export const CHARTEVENT_CLICK = 4
export const CHARTEVENT_CUSTOM = 1000
export const CHARTEVENT_CUSTOM_LAST = 66534
export const CHARTEVENT_KEYDOWN = 0
export const CHARTEVENT_MOUSE_MOVE = 10
export const CHARTEVENT_OBJECT_CHANGE = 8
export const CHARTEVENT_OBJECT_CLICK = 1
export const CHARTEVENT_OBJECT_CREATE = 7
export const CHARTEVENT_OBJECT_DELETE = 6
export const CHARTEVENT_OBJECT_DRAG = 2
export const CHARTEVENT_OBJECT_ENDEDIT = 3
export const CHARTS_MAX = 100

export const ERR_ACCOUNT_DISABLED = 64
export const ERR_ARRAY_AS_PARAMETER_EXPECTED = 4065
export const ERR_ARRAY_INDEX_OUT_OF_RANGE = 4002
export const ERR_ARRAY_INVALID = 4029
export const ERR_BROKER_BUSY = 137
export const ERR_CANNOT_CALL_FUNCTION = 4019
export const ERR_CANNOT_LOAD_LIBRARY = 4018
export const ERR_CANNOT_OPEN_FILE = 4103
export const ERR_CHART_NOREPLY = 4030
export const ERR_CHART_NOT_FOUND = 4211
export const ERR_CHART_PROP_INVALID = 4210
export const ERR_CHARTINDICATOR_NOT_FOUND = 4213
export const ERR_CHARTWINDOW_NOT_FOUND = 4212
export const ERR_COMMON_ERROR = 2
export const ERR_CUSTOM_INDICATOR_ERROR = 4055
export const ERR_DLL_CALLS_NOT_ALLOWED = 4017
export const ERR_DLLFUNC_CRITICALERROR = 4023
export const ERR_DOUBLE_PARAMETER_EXPECTED = 4064
export const ERR_END_OF_FILE = 4099
export const ERR_EXTERNAL_CALLS_NOT_ALLOWED = 4020
export const ERR_FILE_ARRAYRESIZE_ERROR = 5027
export const ERR_FILE_BIN_STRINGSIZE = 5017
export const ERR_FILE_BUFFER_ALLOCATION_ERROR = 5005
export const ERR_FILE_CANNOT_CLEAN_DIRECTORY = 5026
export const ERR_FILE_CANNOT_DELETE = 5006
export const ERR_FILE_CANNOT_DELETE_DIRECTORY = 5025
export const ERR_FILE_CANNOT_OPEN = 5004
export const ERR_FILE_CANNOT_REWRITE = 5021
export const ERR_FILE_DIRECTORY_NOT_EXIST = 5023
export const ERR_FILE_INCOMPATIBLE = 5018
export const ERR_FILE_INVALID_HANDLE = 5007
export const ERR_FILE_IS_DIRECTORY = 5019
export const ERR_FILE_NOT_BIN = 5011
export const ERR_FILE_NOT_CSV = 5014
export const ERR_FILE_NOT_DIRECTORY = 5024
export const ERR_FILE_NOT_EXIST = 5020
export const ERR_FILE_NOT_TOREAD = 5010
export const ERR_FILE_NOT_TOWRITE = 5009
export const ERR_FILE_NOT_TXT = 5012
export const ERR_FILE_NOT_TXTORCSV = 5013
export const ERR_FILE_READ_ERROR = 5015
export const ERR_FILE_STRINGRESIZE_ERROR = 5028
export const ERR_FILE_STRUCT_WITH_OBJECTS = 5029
export const ERR_FILE_TOO_LONG_FILENAME = 5003
export const ERR_FILE_TOO_MANY_OPENED = 5001
export const ERR_FILE_WRITE_ERROR = 5016
export const ERR_FILE_WRONG_DIRECTORYNAME = 5022
export const ERR_FILE_WRONG_FILENAME = 5002
export const ERR_FILE_WRONG_HANDLE = 5008
export const ERR_FORMAT_TOO_MANY_FORMATTERS = 4027
export const ERR_FORMAT_TOO_MANY_PARAMETERS = 4028
export const ERR_FUNC_NOT_ALLOWED_IN_TESTING = 4059
export const ERR_FUNCTION_NOT_CONFIRMED = 4060
export const ERR_GLOBAL_VARIABLE_NOT_FOUND = 4058
export const ERR_GLOBAL_VARIABLES_PROCESSING = 4057
export const ERR_HISTORY_WILL_UPDATED = 4066
export const ERR_INCOMPATIBLE_ARRAYS = 4056
export const ERR_INCOMPATIBLE_FILEACCESS = 4104
export const ERR_INCORRECT_SERIESARRAY_USING = 4054
export const ERR_INDICATOR_CANNOT_INIT = 4071
export const ERR_INDICATOR_CANNOT_LOAD = 4072
export const ERR_INTEGER_PARAMETER_EXPECTED = 4063
export const ERR_INTERNAL_ERROR = 4024
export const ERR_INVALID_ACCOUNT = 65
export const ERR_INVALID_FUNCTION_PARAMSCNT = 4050
export const ERR_INVALID_FUNCTION_PARAMVALUE = 4051
export const ERR_INVALID_POINTER = 4026
export const ERR_INVALID_PRICE = 129
export const ERR_INVALID_PRICE_PARAM = 4107
export const ERR_INVALID_STOPS = 130
export const ERR_INVALID_TICKET = 4108
export const ERR_INVALID_TRADE_PARAMETERS = 3
export const ERR_INVALID_TRADE_VOLUME = 131
export const ERR_LONG_POSITIONS_ONLY_ALLOWED = 140
export const ERR_LONGS_NOT_ALLOWED = 4110
export const ERR_MALFUNCTIONAL_TRADE = 9
export const ERR_MARKET_CLOSED = 132
export const ERR_NO_CONNECTION = 6
export const ERR_NO_ERROR = 0
export const ERR_NO_HISTORY_DATA = 4073
export const ERR_NO_MEMORY_FOR_ARRAYSTRING = 4010
export const ERR_NO_MEMORY_FOR_CALL_STACK = 4003
export const ERR_NO_MEMORY_FOR_HISTORY = 4074
export const ERR_NO_MEMORY_FOR_PARAM_STRING = 4006
export const ERR_NO_MEMORY_FOR_RETURNED_STR = 4021
export const ERR_NO_MEMORY_FOR_TEMP_STRING = 4007
export const ERR_NO_MQLERROR = 4000
export const ERR_NO_OBJECT_NAME = 4204
export const ERR_NO_ORDER_SELECTED = 4105
export const ERR_NO_RESULT = 1
export const ERR_NO_SPECIFIED_SUBWINDOW = 4206
export const ERR_NOT_ENOUGH_MONEY = 134
export const ERR_NOT_ENOUGH_RIGHTS = 7
export const ERR_NOT_ENOUGH_STACK_FOR_PARAM = 4005
export const ERR_NOT_INITIALIZED_ARRAY = 4016
export const ERR_NOT_INITIALIZED_ARRAYSTRING = 4009
export const ERR_NOT_INITIALIZED_STRING = 4008
export const ERR_NOTIFICATION_ERROR = 4250
export const ERR_NOTIFICATION_PARAMETER = 4251
export const ERR_NOTIFICATION_SETTINGS = 4252
export const ERR_NOTIFICATION_TOO_FREQUENT = 4253
export const ERR_OBJECT_ALREADY_EXISTS = 4200
export const ERR_OBJECT_COORDINATES_ERROR = 4205
export const ERR_OBJECT_DOES_NOT_EXIST = 4202
export const ERR_OFF_QUOTES = 136
export const ERR_OLD_VERSION = 5
export const ERR_ORDER_LOCKED = 139
export const ERR_OUT_OF_MEMORY = 4025
export const ERR_PRICE_CHANGED = 135
export const ERR_RECURSIVE_STACK_OVERFLOW = 4004
export const ERR_REMAINDER_FROM_ZERO_DIVIDE = 4012
export const ERR_REQUOTE = 138
export const ERR_RESOURCE_DUPLICATED = 4070
export const ERR_RESOURCE_NOT_FOUND = 4068
export const ERR_RESOURCE_NOT_SUPPORTED = 4069
export const ERR_SEND_MAIL_ERROR = 4061
export const ERR_SERVER_BUSY = 4
export const ERR_SHORTS_NOT_ALLOWED = 4111
export const ERR_SOME_ARRAY_ERROR = 4053
export const ERR_SOME_FILE_ERROR = 4100
export const ERR_SOME_OBJECT_ERROR = 4207
export const ERR_STRING_FUNCTION_INTERNAL = 4052
export const ERR_STRING_PARAMETER_EXPECTED = 4062
export const ERR_SYMBOL_SELECT = 4220
export const ERR_SYSTEM_BUSY = 4022
export const ERR_TOO_FREQUENT_REQUESTS = 8
export const ERR_TOO_LONG_STRING = 4011
export const ERR_TOO_MANY_OPENED_FILES = 4102
export const ERR_TOO_MANY_REQUESTS = 141
export const ERR_TRADE_CONTEXT_BUSY = 146
export const ERR_TRADE_DISABLED = 133
export const ERR_TRADE_ERROR = 4067
export const ERR_TRADE_EXPERT_DISABLED_BY_SERVER = 4112
export const ERR_TRADE_EXPIRATION_DENIED = 147
export const ERR_TRADE_HEDGE_PROHIBITED = 149
export const ERR_TRADE_MODIFY_DENIED = 145
export const ERR_TRADE_NOT_ALLOWED = 4109
export const ERR_TRADE_PROHIBITED_BY_FIFO = 150
export const ERR_TRADE_TIMEOUT = 128
export const ERR_TRADE_TOO_MANY_ORDERS = 148
export const ERR_UNKNOWN_COMMAND = 4014
export const ERR_UNKNOWN_OBJECT_PROPERTY = 4201
export const ERR_UNKNOWN_OBJECT_TYPE = 4203
export const ERR_UNKNOWN_SYMBOL = 4106
export const ERR_USER_ERROR_FIRST = 65536
export const ERR_WEBREQUEST_CONNECT_FAILED = 5201
export const ERR_WEBREQUEST_INVALID_ADDRESS = 5200
export const ERR_WEBREQUEST_REQUEST_FAILED = 5203
export const ERR_WEBREQUEST_TIMEOUT = 5202
export const ERR_WRONG_FILE_NAME = 4101
export const ERR_WRONG_FUNCTION_POINTER = 4001
export const ERR_WRONG_JUMP = 4015
export const ERR_ZERO_DIVIDE = 4013
export const FILE_ACCESS_DATE = 4
export const FILE_ANSI = 32
export const FILE_BIN = 4
export const FILE_COMMON = 4096
export const FILE_CREATE_DATE = 2
export const FILE_CSV = 8
export const FILE_END = 7
export const FILE_EXISTS = 1
export const FILE_IS_ANSI = 13
export const FILE_IS_BINARY = 11
export const FILE_IS_COMMON = 9
export const FILE_IS_CSV = 12
export const FILE_IS_READABLE = 14
export const FILE_IS_TEXT = 10
export const FILE_IS_WRITABLE = 15
export const FILE_LINE_END = 8
export const FILE_MODIFY_DATE = 3
export const FILE_POSITION = 6
export const FILE_READ = 1
export const FILE_REWRITE = 512
export const FILE_SHARE_READ = 128
export const FILE_SHARE_WRITE = 256
export const FILE_SIZE = 5
export const FILE_TXT = 16
export const FILE_UNICODE = 64
export const FILE_WRITE = 2
export const FRIDAY = 5
export const INDICATOR_DIGITS = 0
export const INDICATOR_HEIGHT = 10
export const INDICATOR_LEVELCOLOR = 5
export const INDICATOR_LEVELS = 4
export const INDICATOR_LEVELSTYLE = 6
export const INDICATOR_LEVELTEXT = 9
export const INDICATOR_LEVELVALUE = 8
export const INDICATOR_LEVELWIDTH = 7
export const INDICATOR_MAXIMUM = 3
export const INDICATOR_MINIMUM = 2
export const INDICATOR_SHORTNAME = 1
export const IS_DEBUG_MODE = 0
export const IS_PROFILE_MODE = 0
export const MODE_ASK = 10
export const MODE_BID = 9
export const MODE_CHIKOUSPAN = 5
export const MODE_CLOSE = 3
export const MODE_DIGITS = 12
export const MODE_EMA = 1
export const MODE_EXPIRATION = 21
export const MODE_FREEZELEVEL = 33
export const MODE_GATORJAW = 1
export const MODE_GATORLIPS = 3
export const MODE_GATORTEETH = 2
export const MODE_HIGH = 2
export const MODE_HISTORY = 1
export const MODE_KIJUNSEN = 2
export const MODE_LOTSIZE = 15
export const MODE_LOTSTEP = 24
export const MODE_LOW = 1
export const MODE_LOWER = 2
export const MODE_LWMA = 3
export const MODE_MAIN = 0
export const MODE_MARGINCALCMODE = 28
export const MODE_MARGINHEDGED = 31
export const MODE_MARGININIT = 29
export const MODE_MARGINMAINTENANCE = 30
export const MODE_MARGINREQUIRED = 32
export const MODE_MAXLOT = 25
export const MODE_MINLOT = 23
export const MODE_MINUSDI = 2
export const MODE_OPEN = 0
export const MODE_PLUSDI = 1
export const MODE_POINT = 11
export const MODE_PROFITCALCMODE = 27
export const MODE_SENKOUSPANA = 3
export const MODE_SENKOUSPANB = 4
export const MODE_SIGNAL = 1
export const MODE_SMA = 0
export const MODE_SMMA = 2
export const MODE_SPREAD = 13
export const MODE_STARTING = 20
export const MODE_STOPLEVEL = 14
export const MODE_SWAPLONG = 18
export const MODE_SWAPSHORT = 19
export const MODE_SWAPTYPE = 26
export const MODE_TENKANSEN = 1
export const MODE_TICKSIZE = 17
export const MODE_TICKVALUE = 16
export const MODE_TIME = 5
export const MODE_TRADEALLOWED = 22
export const MODE_TRADES = 0
export const MODE_UPPER = 1
export const MODE_VOLUME = 4
export const MONDAY = 1
export const MQL_CODEPAGE = 15
export const MQL_DEBUG = 5
export const MQL_DLLS_ALLOWED = 3
export const MQL_LICENSE_TYPE = 9
export const MQL_OPTIMIZATION = 7
export const MQL_PROFILER = 10
export const MQL_PROGRAM_NAME = 0
export const MQL_PROGRAM_PATH = 1
export const MQL_PROGRAM_TYPE = 2
export const MQL_SIGNALS_ALLOWED = 14
export const MQL_TESTER = 6
export const MQL_TRADE_ALLOWED = 4
export const MQL_VISUAL_MODE = 8
export const OBJ_ALL_PERIODS = 511
export const OBJ_ARROW = 22
export const OBJ_ARROW_BUY = 37
export const OBJ_ARROW_CHECK = 34
export const OBJ_ARROW_DOWN = 32
export const OBJ_ARROW_LEFT_PRICE = 35
export const OBJ_ARROW_RIGHT_PRICE = 36
export const OBJ_ARROW_SELL = 38
export const OBJ_ARROW_STOP = 33
export const OBJ_ARROW_THUMB_DOWN = 30
export const OBJ_ARROW_THUMB_UP = 29
export const OBJ_ARROW_UP = 31
export const OBJ_BITMAP = 26
export const OBJ_BITMAP_LABEL = 24
export const OBJ_BUTTON = 25
export const OBJ_CHANNEL = 5
export const OBJ_CYCLES = 20
export const OBJ_EDIT = 27
export const OBJ_ELLIPSE = 18
export const OBJ_EVENT = 42
export const OBJ_EXPANSION = 14
export const OBJ_FIBO = 10
export const OBJ_FIBOARC = 13
export const OBJ_FIBOCHANNEL = 15
export const OBJ_FIBOFAN = 12
export const OBJ_FIBOTIMES = 11
export const OBJ_GANNFAN = 8
export const OBJ_GANNGRID = 9
export const OBJ_GANNLINE = 7
export const OBJ_HLINE = 1
export const OBJ_LABEL = 23
export const OBJ_NO_PERIODS = -1
export const OBJ_PERIOD_D1 = 64
export const OBJ_PERIOD_H1 = 16
export const OBJ_PERIOD_H4 = 32
export const OBJ_PERIOD_M1 = 1
export const OBJ_PERIOD_M15 = 4
export const OBJ_PERIOD_M30 = 8
export const OBJ_PERIOD_M5 = 2
export const OBJ_PERIOD_MN1 = 256
export const OBJ_PERIOD_W1 = 128
export const OBJ_PITCHFORK = 19
export const OBJ_RECTANGLE = 16
export const OBJ_RECTANGLE_LABEL = 28
export const OBJ_REGRESSION = 4
export const OBJ_STDDEVCHANNEL = 6
export const OBJ_TEXT = 21
export const OBJ_TREND = 2
export const OBJ_TRENDBYANGLE = 3
export const OBJ_TRIANGLE = 17
export const OBJ_VLINE = 0
export const OBJPROP_ALIGN = 1036
export const OBJPROP_ANCHOR = 1011
export const OBJPROP_ANGLE = 13
export const OBJPROP_ARROWCODE = 14
export const OBJPROP_BACK = 9
export const OBJPROP_BGCOLOR = 1025
export const OBJPROP_BMPFILE = 1017
export const OBJPROP_BORDER_COLOR = 1035
export const OBJPROP_BORDER_TYPE = 1029
export const OBJPROP_COLOR = 6
export const OBJPROP_CORNER = 101
export const OBJPROP_CREATETIME = 998
export const OBJPROP_DEVIATION = 16
export const OBJPROP_ELLIPSE = 11
export const OBJPROP_FIBOLEVELS = 200
export const OBJPROP_FIRSTLEVEL = 210
export const OBJPROP_FONT = 1001
export const OBJPROP_FONTSIZE = 100
export const OBJPROP_HIDDEN = 208
export const OBJPROP_LEVELCOLOR = 201
export const OBJPROP_LEVELS = 200
export const OBJPROP_LEVELSTYLE = 202
export const OBJPROP_LEVELTEXT = 205
export const OBJPROP_LEVELVALUE = 204
export const OBJPROP_LEVELWIDTH = 203
export const OBJPROP_NAME = 1037
export const OBJPROP_PRICE = 20
export const OBJPROP_PRICE1 = 1
export const OBJPROP_PRICE2 = 3
export const OBJPROP_PRICE3 = 5
export const OBJPROP_RAY = 10
export const OBJPROP_RAY_RIGHT = 1004
export const OBJPROP_READONLY = 1028
export const OBJPROP_SCALE = 12
export const OBJPROP_SELECTABLE = 1000
export const OBJPROP_SELECTED = 17
export const OBJPROP_STATE = 1018
export const OBJPROP_STYLE = 7
export const OBJPROP_SYMBOL = 1021
export const OBJPROP_TEXT = 999
export const OBJPROP_TIME = 19
export const OBJPROP_TIME1 = 0
export const OBJPROP_TIME2 = 2
export const OBJPROP_TIME3 = 4
export const OBJPROP_TIMEFRAMES = 15
export const OBJPROP_TOOLTIP = 206
export const OBJPROP_TYPE = 18
export const OBJPROP_WIDTH = 8
export const OBJPROP_XDISTANCE = 102
export const OBJPROP_XOFFSET = 1033
export const OBJPROP_XSIZE = 1019
export const OBJPROP_YDISTANCE = 103
export const OBJPROP_YOFFSET = 1034
export const OBJPROP_YSIZE = 1020
export const OBJPROP_ZORDER = 207
export const REASON_ACCOUNT = 6
export const REASON_CHARTCHANGE = 3
export const REASON_CHARTCLOSE = 4
export const REASON_CLOSE = 9
export const REASON_INITFAILED = 8
export const REASON_PARAMETERS = 5
export const REASON_PROGRAM = 0
export const REASON_RECOMPILE = 2
export const REASON_REMOVE = 1
export const REASON_TEMPLATE = 7
export const SEEK_CUR = 1
export const SEEK_END = 2
export const SEEK_SET = 0
export const SELECT_BY_POS = 0
export const SELECT_BY_TICKET = 1
export const SERIES_BARS_COUNT = 0
export const SERIES_FIRSTDATE = 1
export const SERIES_LASTBAR_DATE = 5
export const SERIES_SERVER_FIRSTDATE = 2
export const SHORT_MAX = 32767
export const SHORT_MIN = -32768
export const SIGNAL_BASE_AUTHOR_LOGIN = 13
export const SIGNAL_BASE_BALANCE = 17
export const SIGNAL_BASE_BROKER = 10
export const SIGNAL_BASE_BROKER_SERVER = 11
export const SIGNAL_BASE_CURRENCY = 14
export const SIGNAL_BASE_DATE_PUBLISHED = 1
export const SIGNAL_BASE_DATE_STARTED = 2
export const SIGNAL_BASE_EQUITY = 19
export const SIGNAL_BASE_GAIN = 16
export const SIGNAL_BASE_ID = 0
export const SIGNAL_BASE_LEVERAGE = 6
export const SIGNAL_BASE_MAX_DRAWDOWN = 18
export const SIGNAL_BASE_NAME = 12
export const SIGNAL_BASE_PIPS = 8
export const SIGNAL_BASE_PRICE = 15
export const SIGNAL_BASE_RATING = 9
export const SIGNAL_BASE_ROI = 20
export const SIGNAL_BASE_SUBSCRIBERS = 4
export const SIGNAL_BASE_TRADE_MODE = 5
export const SIGNAL_BASE_TRADES = 7
export const SIGNAL_INFO_CONFIRMATIONS_DISABLED = 4
export const SIGNAL_INFO_COPY_SLTP = 3
export const SIGNAL_INFO_DEPOSIT_PERCENT = 5
export const SIGNAL_INFO_EQUITY_LIMIT = 8
export const SIGNAL_INFO_ID = 0
export const SIGNAL_INFO_NAME = 6
export const SIGNAL_INFO_SLIPPAGE = 9
export const SIGNAL_INFO_SUBSCRIPTION_ENABLED = 1
export const SIGNAL_INFO_TERMS_AGREE = 2
export const SIGNAL_INFO_VOLUME_PERCENT = 7
export const STAT_BALANCE_DD = 12
export const STAT_BALANCE_DD_RELATIVE = 14
export const STAT_BALANCE_DDREL_PERCENT = 15
export const STAT_BALANCEDD_PERCENT = 13
export const STAT_BALANCEMIN = 11
export const STAT_CONLOSSMAX = 9
export const STAT_CONLOSSMAX_TRADES = 37
export const STAT_CONPROFITMAX = 7
export const STAT_CONPROFITMAX_TRADES = 35
export const STAT_CUSTOM_ONTESTER = 26
export const STAT_DEALS = 27
export const STAT_EQUITY_DD = 17
export const STAT_EQUITY_DD_RELATIVE = 19
export const STAT_EQUITY_DDREL_PERCENT = 20
export const STAT_EQUITYDD_PERCENT = 18
export const STAT_EQUITYMIN = 16
export const STAT_EXPECTED_PAYOFF = 21
export const STAT_GROSS_LOSS = 4
export const STAT_GROSS_PROFIT = 3
export const STAT_INITIAL_DEPOSIT = 0
export const STAT_LONG_TRADES = 32
export const STAT_LOSS_TRADES = 30
export const STAT_LOSSTRADES_AVGCON = 40
export const STAT_MAX_CONLOSS_TRADES = 38
export const STAT_MAX_CONLOSSES = 10
export const STAT_MAX_CONPROFIT_TRADES = 36
export const STAT_MAX_CONWINS = 8
export const STAT_MAX_LOSSTRADE = 6
export const STAT_MAX_PROFITTRADE = 5
export const STAT_MIN_MARGINLEVEL = 25
export const STAT_PROFIT = 2
export const STAT_PROFIT_FACTOR = 22
export const STAT_PROFIT_LONGTRADES = 34
export const STAT_PROFIT_SHORTTRADES = 33
export const STAT_PROFIT_TRADES = 29
export const STAT_PROFITTRADES_AVGCON = 39
export const STAT_RECOVERY_FACTOR = 23
export const STAT_SHARPE_RATIO = 24
export const STAT_SHORT_TRADES = 31
export const STAT_TRADES = 28
export const STAT_WITHDRAWAL = 1
export const STO_CLOSECLOSE = 1
export const STO_LOWHIGH = 0
export const STYLE_DASH = 1
export const STYLE_DASHDOT = 3
export const STYLE_DASHDOTDOT = 4
export const STYLE_DOT = 2
export const STYLE_SOLID = 0
export const SYMBOL_ASK = 4
export const SYMBOL_ASKHIGH = 5
export const SYMBOL_ASKLOW = 6
export const SYMBOL_BID = 1
export const SYMBOL_BIDHIGH = 2
export const SYMBOL_BIDLOW = 3
export const SYMBOL_CURRENCY_BASE = 22
export const SYMBOL_CURRENCY_MARGIN = 24
export const SYMBOL_CURRENCY_PROFIT = 23
export const SYMBOL_DESCRIPTION = 20
export const SYMBOL_DIGITS = 17
export const SYMBOL_EXPIRATION_MODE = 49
export const SYMBOL_EXPIRATION_TIME = 52
export const SYMBOL_FILLING_MODE = 50
export const SYMBOL_LAST = 7
export const SYMBOL_LASTHIGH = 8
export const SYMBOL_LASTLOW = 9
export const SYMBOL_MARGIN_INITIAL = 42
export const SYMBOL_MARGIN_LIMIT = 46
export const SYMBOL_MARGIN_LONG = 44
export const SYMBOL_MARGIN_MAINTENANCE = 43
export const SYMBOL_MARGIN_SHORT = 45
export const SYMBOL_MARGIN_STOP = 47
export const SYMBOL_MARGIN_STOPLIMIT = 48
export const SYMBOL_ORDER_MODE = 71
export const SYMBOL_PATH = 21
export const SYMBOL_POINT = 16
export const SYMBOL_SELECT = 0
export const SYMBOL_SESSION_AW = 66
export const SYMBOL_SESSION_BUY_ORDERS = 60
export const SYMBOL_SESSION_BUY_ORDERS_VOLUME = 61
export const SYMBOL_SESSION_CLOSE = 65
export const SYMBOL_SESSION_DEALS = 56
export const SYMBOL_SESSION_INTEREST = 59
export const SYMBOL_SESSION_OPEN = 64
export const SYMBOL_SESSION_PRICE_LIMIT_MAX = 69
export const SYMBOL_SESSION_PRICE_LIMIT_MIN = 68
export const SYMBOL_SESSION_PRICE_SETTLEMENT = 67
export const SYMBOL_SESSION_SELL_ORDERS = 62
export const SYMBOL_SESSION_SELL_ORDERS_VOLUME = 63
export const SYMBOL_SESSION_TURNOVER = 58
export const SYMBOL_SESSION_VOLUME = 57
export const SYMBOL_SPREAD = 18
export const SYMBOL_SPREAD_FLOAT = 41
export const SYMBOL_START_TIME = 51
export const SYMBOL_SWAP_LONG = 38
export const SYMBOL_SWAP_MODE = 37
export const SYMBOL_SWAP_ROLLOVER3DAYS = 40
export const SYMBOL_SWAP_SHORT = 39
export const SYMBOL_TIME = 15
export const SYMBOL_TRADE_CALC_MODE = 29
export const SYMBOL_TRADE_CONTRACT_SIZE = 28
export const SYMBOL_TRADE_EXECUTION_EXCHANGE = 3
export const SYMBOL_TRADE_EXECUTION_INSTANT = 1
export const SYMBOL_TRADE_EXECUTION_MARKET = 2
export const SYMBOL_TRADE_EXECUTION_REQUEST = 0
export const SYMBOL_TRADE_EXEMODE = 33
export const SYMBOL_TRADE_FREEZE_LEVEL = 32
export const SYMBOL_TRADE_MODE = 30
export const SYMBOL_TRADE_MODE_CLOSEONLY = 1
export const SYMBOL_TRADE_MODE_DISABLED = 0
export const SYMBOL_TRADE_MODE_FULL = 2
export const SYMBOL_TRADE_MODE_LONGONLY = 3
export const SYMBOL_TRADE_MODE_SHORTONLY = 4
export const SYMBOL_TRADE_STOPS_LEVEL = 31
export const SYMBOL_TRADE_TICK_SIZE = 27
export const SYMBOL_TRADE_TICK_VALUE = 26
export const SYMBOL_TRADE_TICK_VALUE_LOSS = 54
export const SYMBOL_TRADE_TICK_VALUE_PROFIT = 53
export const SYMBOL_VOLUME = 10
export const SYMBOL_VOLUME_LIMIT = 55
export const SYMBOL_VOLUME_MAX = 35
export const SYMBOL_VOLUME_MIN = 34
export const SYMBOL_VOLUME_STEP = 36
export const SYMBOL_VOLUMEHIGH = 11
export const SYMBOL_VOLUMELOW = 12
export const TERMINAL_BUILD = 5
export const TERMINAL_CODEPAGE = 12
export const TERMINAL_COMMONDATA_PATH = 4
export const TERMINAL_COMMUNITY_ACCOUNT = 23
export const TERMINAL_COMMUNITY_BALANCE = 25
export const TERMINAL_COMMUNITY_CONNECTION = 24
export const TERMINAL_COMPANY = 0
export const TERMINAL_CONNECTED = 6
export const TERMINAL_CPU_CORES = 21
export const TERMINAL_DATA_PATH = 3
export const TERMINAL_DISK_SPACE = 20
export const TERMINAL_DLLS_ALLOWED = 7
export const TERMINAL_EMAIL_ENABLED = 9
export const TERMINAL_FTP_ENABLED = 10
export const TERMINAL_LANGUAGE = 13
export const TERMINAL_MAXBARS = 11
export const TERMINAL_MEMORY_AVAILABLE = 16
export const TERMINAL_MEMORY_PHYSICAL = 14
export const TERMINAL_MEMORY_TOTAL = 15
export const TERMINAL_MEMORY_USED = 17
export const TERMINAL_MQID = 22
export const TERMINAL_NAME = 1
export const TERMINAL_NOTIFICATIONS_ENABLED = 26
export const TERMINAL_PATH = 2
export const TERMINAL_PING_LAST = 28
export const TERMINAL_SCREEN_DPI = 27
export const TERMINAL_TRADE_ALLOWED = 8
export const WEDNESDAY = 3

export const clrAliceBlue = '#F0F8FF'
export const clrAntiqueWhite = '#FAEBD7'
export const clrAqua = '#00FFFF'
export const clrAquamarine = '#7FFFD4'
export const clrBeige = '#F5F5DC'
export const clrBisque = '#FFE4C4'
export const clrBlack = '#000000'
export const clrBlanchedAlmond = '#FFEBCD'
export const clrBlue = '#0000FF'
export const clrBlueViolet = '#8A2BE2'
export const clrBrown = '#A52A2A'
export const clrBurlyWood = '#DEB887'
export const clrCadetBlue = '#5F9EA0'
export const clrChartreuse = '#7FFF00'
export const clrChocolate = '#D2691E'
export const clrCoral = '#FF7F50'
export const clrCornflowerBlue = '#6495ED'
export const clrCornsilk = '#FFF8DC'
export const clrCrimson = '#DC143C'
export const clrDarkBlue = '#00008B'
export const clrDarkGoldenrod = '#B8860B'
export const clrDarkGray = '#A9A9A9'
export const clrDarkGreen = '#006400'
export const clrDarkKhaki = '#BDB76B'
export const clrDarkOliveGreen = '#556B2F'
export const clrDarkOrange = '#FF8C00'
export const clrDarkOrchid = '#9932CC'
export const clrDarkSalmon = '#E9967A'
export const clrDarkSeaGreen = '#8FBC8F'
export const clrDarkSlateBlue = '#483D8B'
export const clrDarkSlateGray = '#2F4F4F'
export const clrDarkTurquoise = '#00CED1'
export const clrDarkViolet = '#9400D3'
export const clrDeepPink = '#FF1493'
export const clrDeepSkyBlue = '#00BFFF'
export const clrDimGray = '#696969'
export const clrDodgerBlue = '#1E90FF'
export const clrFireBrick = '#B22222'
export const clrForestGreen = '#228B22'
export const clrGainsboro = '#DCDCDC'
export const clrGold = '#FFD700'
export const clrGoldenrod = '#DAA520'
export const clrGray = '#808080'
export const clrGreen = '#008000'
export const clrGreenYellow = '#ADFF2F'
export const clrHoneydew = '#F0FFF0'
export const clrHotPink = '#FF69B4'
export const clrIndianRed = '#CD5C5C'
export const clrIndigo = '#4B0082'
export const clrIvory = '#FFFFF0'
export const clrKhaki = '#F0E68C'
export const clrLavender = '#E6E6FA'
export const clrLavenderBlush = '#FFF0F5'
export const clrLawnGreen = '#7CFC00'
export const clrLemonChiffon = '#FFFACD'
export const clrLightBlue = '#ADD8E6'
export const clrLightCoral = '#F08080'
export const clrLightCyan = '#E0FFFF'
export const clrLightGoldenrod = '#FAFAD2'
export const clrLightGray = '#D3D3D3'
export const clrLightGreen = '#90EE90'
export const clrLightPink = '#FFB6C1'
export const clrLightSalmon = '#FFA07A'
export const clrLightSeaGreen = '#20B2AA'
export const clrLightSkyBlue = '#87CEFA'
export const clrLightSlateGray = '#778899'
export const clrLightSteelBlue = '#B0C4DE'
export const clrLightYellow = '#FFFFE0'
export const clrLime = '#00FF00'
export const clrLimeGreen = '#32CD32'
export const clrLinen = '#FAF0E6'
export const clrMagenta = '#FF00FF'
export const clrMaroon = '#800000'
export const clrMediumAquamarine = '#66CDAA'
export const clrMediumBlue = '#0000CD'
export const clrMediumOrchid = '#BA55D3'
export const clrMediumPurple = '#9370DB'
export const clrMediumSeaGreen = '#3CB371'
export const clrMediumSlateBlue = '#7B68EE'
export const clrMediumSpringGreen = '#00FA9A'
export const clrMediumTurquoise = '#48D1CC'
export const clrMediumVioletRed = '#C71585'
export const clrMidnightBlue = '#191970'
export const clrMintCream = '#F5FFFA'
export const clrMistyRose = '#FFE4E1'
export const clrMoccasin = '#FFE4B5'
export const clrNavajoWhite = '#FFDEAD'
export const clrNavy = '#000080'
export const clrOldLace = '#FDF5E6'
export const clrOlive = '#808000'
export const clrOliveDrab = '#6B8E23'
export const clrOrange = '#FFA500'
export const clrOrangeRed = '#FF4500'
export const clrOrchid = '#DA70D6'
export const clrPaleGoldenrod = '#EEE8AA'
export const clrPaleGreen = '#98FB98'
export const clrPaleTurquoise = '#AFEEEE'
export const clrPaleVioletRed = '#DB7093'
export const clrPapayaWhip = '#FFEFD5'
export const clrPeachPuff = '#FFDAB9'
export const clrPeru = '#CD853F'
export const clrPink = '#FFC0CB'
export const clrPlum = '#DDA0DD'
export const clrPowderBlue = '#B0E0E6'
export const clrPurple = '#800080'
export const clrRed = '#FF0000'
export const clrRosyBrown = '#BC8F8F'
export const clrRoyalBlue = '#4169E1'
export const clrSaddleBrown = '#8B4513'
export const clrSalmon = '#FA8072'
export const clrSandyBrown = '#F4A460'
export const clrSeaGreen = '#2E8B57'
export const clrSeashell = '#FFF5EE'
export const clrSienna = '#A0522D'
export const clrSilver = '#C0C0C0'
export const clrSkyBlue = '#87CEEB'
export const clrSlateBlue = '#6A5ACD'
export const clrSlateGray = '#708090'
export const clrSnow = '#FFFAFA'
export const clrSpringGreen = '#00FF7F'
export const clrSteelBlue = '#4682B4'
export const clrTan = '#D2B48C'
export const clrTeal = '#008080'
export const clrThistle = '#D8BFD8'
export const clrTomato = '#FF6347'
export const clrTurquoise = '#40E0D0'
export const clrViolet = '#EE82EE'
export const clrWheat = '#F5DEB3'
export const clrWhite = '#FFFFFF'
export const clrWhiteSmoke = '#F5F5F5'
export const clrYellow = '#FFFF00'
export const clrYellowGreen = '#9ACD32'

export class APIConstants {
    public static readonly __DATE__ = '2024.09.16 00:00:00'
    public static readonly __DATETIME__ = '2024.09.16 19:09:47'
    public static readonly __FILE__ = 'N/A'
    public static readonly __FUNCSIG__ = 'N/A'
    public static readonly __FUNCTION__ = 'N/A'
    public static readonly __LINE__ = 0
    public static readonly __MQLBUILD__ = 2409
    public static readonly __MQL4BUILD__ = 2409

    public static readonly MODE_FAST = 2
    public static readonly MODE_SLOW = 3

    //ENUM_APPLIED_PRICE
    public static readonly PRICE_CLOSE = 0
    public static readonly PRICE_OPEN = 1
    public static readonly PRICE_HIGH = 2
    public static readonly PRICE_LOW = 3
    public static readonly PRICE_MEDIAN = 4
    public static readonly PRICE_TYPICAL = 5
    public static readonly PRICE_WEIGHTED = 6
    public static readonly cl_GridColor = 0x01000000

    public static readonly tlTop = 0
    public static readonly tlCenter = 1
    public static readonly tlBottom = 2

    public static readonly taLeftJustify = 0
    public static readonly taRightJustify = 1
    public static readonly taCenter = 2

    //unchangeable constants

    public static readonly ALIGN_CENTER = 2
    public static readonly ALIGN_LEFT = 1
    public static readonly ALIGN_RIGHT = 0
    public static readonly ANCHOR_BOTTOM = 1
    public static readonly ANCHOR_CENTER = 8
    public static readonly ANCHOR_LEFT = 1
    public static readonly ANCHOR_LEFT_LOWER = 2
    public static readonly ANCHOR_LEFT_UPPER = 0
    public static readonly ANCHOR_LOWER = 3
    public static readonly ANCHOR_RIGHT = 5
    public static readonly ANCHOR_RIGHT_LOWER = 4
    public static readonly ANCHOR_RIGHT_UPPER = 6
    public static readonly ANCHOR_TOP = 0
    public static readonly ANCHOR_UPPER = 7
    public static readonly BORDER_FLAT = 0
    public static readonly BORDER_RAISED = 1
    public static readonly BORDER_SUNKEN = 2
    public static readonly CHAR_MAX = 127
    public static readonly CHAR_MIN = -128

    public static readonly clrNONE = 'clrNONE'
    public static readonly CLR_NONE = clrNONE

    public static readonly CORNER_LEFT_LOWER = 2
    public static readonly CORNER_LEFT_UPPER = 0
    public static readonly CORNER_RIGHT_LOWER = 3
    public static readonly CORNER_RIGHT_UPPER = 1
    public static readonly CP_ACP = 0
    public static readonly CP_OEMCP = 1
    public static readonly CP_SYMBOL = 42
    public static readonly CP_THREAD_ACP = 3
    public static readonly CP_UTF7 = 65000
    public static readonly CP_UTF8 = 65001
    public static readonly CRYPT_AES128 = 1
    public static readonly CRYPT_AES256 = 2
    public static readonly CRYPT_ARCH_ZIP = 7
    public static readonly CRYPT_BASE64 = 0
    public static readonly CRYPT_DES = 3
    public static readonly CRYPT_HASH_MD5 = 6
    public static readonly CRYPT_HASH_SHA1 = 4
    public static readonly CRYPT_HASH_SHA256 = 5
    public static readonly DBL_DIG = 15
    public static readonly DBL_EPSILON = Number.EPSILON
    public static readonly DBL_MANT_DIG = 53
    public static readonly DBL_MAX = Number.MAX_VALUE
    public static readonly DBL_MAX_10_EXP = 308
    public static readonly DBL_MAX_EXP = 1024
    public static readonly DBL_MIN = Number.MIN_VALUE
    public static readonly DBL_MIN_10_EXP = -307
    public static readonly DBL_MIN_EXP = -1021
    public static readonly DRAW_ARROW = 3
    public static readonly DRAW_HISTOGRAM = 2
    public static readonly DRAW_LINE = 0
    public static readonly DRAW_NONE = 12
    public static readonly DRAW_SECTION = 1
    public static readonly DRAW_ZIGZAG = 4
    public static readonly EMPTY = -1
    public static readonly EMPTY_VALUE = Number.MAX_SAFE_INTEGER
    public static readonly FLT_DIG = 6
    public static readonly FLT_EPSILON = Number.EPSILON
    public static readonly FLT_MANT_DIG = 24
    public static readonly FLT_MAX = 3.402823466e38
    public static readonly FLT_MAX_10_EXP = 38
    public static readonly FLT_MAX_EXP = 128
    public static readonly FLT_MIN = 0.0
    public static readonly FLT_MIN_10_EXP = -37
    public static readonly FLT_MIN_EXP = -125
    public static readonly GANN_DOWN_TREND = 1
    public static readonly GANN_UP_TREND = 0
    public static readonly IDABORT = 3
    public static readonly IDCANCEL = 2
    public static readonly IDCONTINUE = 11
    public static readonly IDIGNORE = 5
    public static readonly IDNO = 7
    public static readonly IDOK = 1
    public static readonly IDRETRY = 4
    public static readonly IDTRYAGAIN = 10
    public static readonly IDYES = 6
    public static readonly INT_MAX = 2147483647
    public static readonly INT_MIN = -2147483648
    public static readonly INVALID_HANDLE = -1
    public static readonly LONG_MAX = Number.MAX_SAFE_INTEGER
    public static readonly LONG_MIN = Number.MIN_SAFE_INTEGER
    public static readonly M_1_PI = 0.3183098861837907
    public static readonly M_2_PI = 0.6366197723675814
    public static readonly M_2_SQRTPI = 1.128379167095513
    public static readonly M_E = 2.718281828459045
    public static readonly M_LN10 = 2.302585092994046
    public static readonly M_LN2 = 0.6931471805599453
    public static readonly M_LOG10E = 0.4342944819032518
    public static readonly M_LOG2E = 1.442695040888963
    public static readonly M_PI = 3.141592653589793
    public static readonly M_PI_2 = 1.570796326794897
    public static readonly M_PI_4 = 0.7853981633974483
    public static readonly M_SQRT1_2 = 0.7071067811865476
    public static readonly M_SQRT2 = 1.414213562373095
    public static readonly MB_ABORTRETRYIGNORE = 2
    public static readonly MB_CANCELTRYCONTINUE = 6
    public static readonly MB_DEFBUTTON1 = 0
    public static readonly MB_DEFBUTTON2 = 256
    public static readonly MB_DEFBUTTON3 = 512
    public static readonly MB_DEFBUTTON4 = 768
    public static readonly MB_ICONEXCLAMATION = 48
    public static readonly MB_ICONWARNING = 48
    public static readonly MB_ICONINFORMATION = 64
    public static readonly MB_ICONASTERISK = 64
    public static readonly MB_ICONQUESTION = 32
    public static readonly MB_ICONSTOP = 16
    public static readonly MB_ICONERROR = 16
    public static readonly MB_ICONHAND = 16
    public static readonly MB_OK = 0
    public static readonly MB_OKCANCEL = 1
    public static readonly MB_RETRYCANCEL = 5
    public static readonly MB_YESNO = 4
    public static readonly MB_YESNOCANCEL = 3
    public static readonly NULL = null
    public static readonly OP_BUY = 0
    public static readonly OP_BUYLIMIT = 2
    public static readonly OP_BUYSTOP = 4
    public static readonly OP_SELL = 1
    public static readonly OP_SELLLIMIT = 3
    public static readonly OP_SELLSTOP = 5
    public static readonly PERIOD_CURRENT = 0
    public static readonly PERIOD_D1 = 1440
    public static readonly PERIOD_H1 = 60
    public static readonly PERIOD_H12 = 720
    public static readonly PERIOD_H2 = 120
    public static readonly PERIOD_H3 = 180
    public static readonly PERIOD_H4 = 240
    public static readonly PERIOD_H6 = 360
    public static readonly PERIOD_H8 = 480
    public static readonly PERIOD_M1 = 1
    public static readonly PERIOD_M10 = 10
    public static readonly PERIOD_M12 = 12
    public static readonly PERIOD_M15 = 15
    public static readonly PERIOD_M2 = 2
    public static readonly PERIOD_M20 = 20
    public static readonly PERIOD_M3 = 3
    public static readonly PERIOD_M30 = 30
    public static readonly PERIOD_M4 = 4
    public static readonly PERIOD_M5 = 5
    public static readonly PERIOD_M6 = 6
    public static readonly PERIOD_MN1 = 43200
    public static readonly PERIOD_W1 = 10080
    public static readonly POINTER_AUTOMATIC = 2
    public static readonly POINTER_DYNAMIC = 1
    public static readonly POINTER_INVALID = 0
    public static readonly SATURDAY = 6
    public static readonly SUNDAY = 0
    public static readonly SYMBOL_ARROWDOWN = 242
    public static readonly SYMBOL_ARROWUP = 241
    public static readonly SYMBOL_CHECKSIGN = 252
    public static readonly SYMBOL_LEFTPRICE = 5
    public static readonly SYMBOL_RIGHTPRICE = 6
    public static readonly SYMBOL_STOPSIGN = 251
    public static readonly SYMBOL_THUMBSDOWN = 68
    public static readonly SYMBOL_THUMBSUP = 67
    public static readonly THURSDAY = 4
    public static readonly TUESDAY = 2
    public static readonly UCHAR_MAX = 255
    public static readonly UINT_MAX = 4294967295
    public static readonly ULONG_MAX = Number.MAX_SAFE_INTEGER
    public static readonly USHORT_MAX = 65535
    public static readonly WHOLE_ARRAY = 0
    public static readonly WRONG_VALUE = -1

    public static readonly ACCOUNT_BALANCE = 37
    public static readonly ACCOUNT_COMPANY = 2
    public static readonly ACCOUNT_CREDIT = 38
    public static readonly ACCOUNT_CURRENCY = 36
    public static readonly ACCOUNT_EQUITY = 40
    public static readonly ACCOUNT_MARGIN_FREE = 42
    public static readonly ACCOUNT_LEVERAGE = 35
    public static readonly ACCOUNT_LIMIT_ORDERS = 47
    public static readonly ACCOUNT_LOGIN = 0
    public static readonly ACCOUNT_MARGIN = 41
    public static readonly ACCOUNT_MARGIN_LEVEL = 43
    public static readonly ACCOUNT_MARGIN_SO_CALL = 45
    public static readonly ACCOUNT_MARGIN_SO_MODE = 44
    public static readonly ACCOUNT_MARGIN_SO_SO = 46
    public static readonly ACCOUNT_NAME = 1
    public static readonly ACCOUNT_PROFIT = 39
    public static readonly ACCOUNT_SERVER = 3
    public static readonly ACCOUNT_STOPOUT_MODE_MONEY = 1
    public static readonly ACCOUNT_STOPOUT_MODE_PERCENT = 0
    public static readonly ACCOUNT_TRADE_ALLOWED = 33
    public static readonly ACCOUNT_TRADE_EXPERT = 34
    public static readonly ACCOUNT_TRADE_MODE = 32
    public static readonly ACCOUNT_TRADE_MODE_CONTEST = 1
    public static readonly ACCOUNT_TRADE_MODE_DEMO = 0
    public static readonly ACCOUNT_TRADE_MODE_REAL = 2
    public static readonly CHART_AUTOSCROLL = 4
    public static readonly CHART_BARS = 0
    public static readonly CHART_BEGIN = 0
    public static readonly CHART_BRING_TO_TOP = 35
    public static readonly CHART_CANDLES = 1
    public static readonly CHART_COLOR_ASK = 31
    public static readonly CHART_COLOR_BACKGROUND = 21
    public static readonly CHART_COLOR_BID = 30
    public static readonly CHART_COLOR_CANDLE_BEAR = 29
    public static readonly CHART_COLOR_CANDLE_BULL = 28
    public static readonly CHART_COLOR_CHART_DOWN = 26
    public static readonly CHART_COLOR_CHART_LINE = 27
    public static readonly CHART_COLOR_CHART_UP = 25
    public static readonly CHART_COLOR_FOREGROUND = 22
    public static readonly CHART_COLOR_GRID = 23
    public static readonly CHART_COLOR_LAST = 32
    public static readonly CHART_COLOR_STOP_LEVEL = 33
    public static readonly CHART_COLOR_VOLUME = 24
    public static readonly CHART_COMMENT = 20
    public static readonly CHART_CURRENT_POS = 1
    public static readonly CHART_DRAG_TRADE_LEVELS = 43
    public static readonly CHART_END = 2
    public static readonly CHART_EVENT_MOUSE_MOVE = 40
    public static readonly CHART_EVENT_OBJECT_CREATE = 38
    public static readonly CHART_EVENT_OBJECT_DELETE = 39
    public static readonly CHART_FIRST_VISIBLE_BAR = 104
    public static readonly CHART_FIXED_MAX = 8
    public static readonly CHART_FIXED_MIN = 9
    public static readonly CHART_FIXED_POSITION = 41
    public static readonly CHART_FOREGROUND = 1
    public static readonly CHART_HEIGHT_IN_PIXELS = 107
    public static readonly CHART_IS_OFFLINE = 112
    public static readonly CHART_LINE = 2
    public static readonly CHART_MODE = 0
    public static readonly CHART_MOUSE_SCROLL = 42
    public static readonly CHART_POINTS_PER_BAR = 11
    public static readonly CHART_PRICE_MAX = 109
    public static readonly CHART_PRICE_MIN = 108
    public static readonly CHART_SCALE = 5
    public static readonly CHART_SCALE_PT_PER_BAR = 10
    public static readonly CHART_SCALEFIX = 6
    public static readonly CHART_SCALEFIX_11 = 7
    public static readonly CHART_SHIFT = 2
    public static readonly CHART_SHIFT_SIZE = 3
    public static readonly CHART_SHOW_ASK_LINE = 14
    public static readonly CHART_SHOW_BID_LINE = 13
    public static readonly CHART_SHOW_DATE_SCALE = 36
    public static readonly CHART_SHOW_GRID = 17
    public static readonly CHART_SHOW_LAST_LINE = 15
    public static readonly CHART_SHOW_OBJECT_DESCR = 19
    public static readonly CHART_SHOW_OHLC = 12
    public static readonly CHART_SHOW_PERIOD_SEP = 16
    public static readonly CHART_SHOW_PRICE_SCALE = 37
    public static readonly CHART_SHOW_TRADE_LEVELS = 34
    public static readonly CHART_SHOW_VOLUMES = 18
    public static readonly CHART_VISIBLE_BARS = 100
    public static readonly CHART_VOLUME_HIDE = 0
    public static readonly CHART_VOLUME_TICK = 1
    public static readonly CHART_WIDTH_IN_BARS = 105
    public static readonly CHART_WIDTH_IN_PIXELS = 106
    public static readonly CHART_WINDOW_HANDLE = 103
    public static readonly CHART_WINDOW_IS_VISIBLE = 102
    public static readonly CHART_WINDOW_YDISTANCE = 110
    public static readonly CHART_WINDOWS_TOTAL = 101
    public static readonly CHARTEVENT_CHART_CHANGE = 9
    public static readonly CHARTEVENT_CLICK = 4
    public static readonly CHARTEVENT_CUSTOM = 1000
    public static readonly CHARTEVENT_CUSTOM_LAST = 66534
    public static readonly CHARTEVENT_KEYDOWN = 0
    public static readonly CHARTEVENT_MOUSE_MOVE = 10
    public static readonly CHARTEVENT_OBJECT_CHANGE = 8
    public static readonly CHARTEVENT_OBJECT_CLICK = 1
    public static readonly CHARTEVENT_OBJECT_CREATE = 7
    public static readonly CHARTEVENT_OBJECT_DELETE = 6
    public static readonly CHARTEVENT_OBJECT_DRAG = 2
    public static readonly CHARTEVENT_OBJECT_ENDEDIT = 3
    public static readonly CHARTS_MAX = 100

    public static readonly ERR_ACCOUNT_DISABLED = 64
    public static readonly ERR_ARRAY_AS_PARAMETER_EXPECTED = 4065
    public static readonly ERR_ARRAY_INDEX_OUT_OF_RANGE = 4002
    public static readonly ERR_ARRAY_INVALID = 4029
    public static readonly ERR_BROKER_BUSY = 137
    public static readonly ERR_CANNOT_CALL_FUNCTION = 4019
    public static readonly ERR_CANNOT_LOAD_LIBRARY = 4018
    public static readonly ERR_CANNOT_OPEN_FILE = 4103
    public static readonly ERR_CHART_NOREPLY = 4030
    public static readonly ERR_CHART_NOT_FOUND = 4211
    public static readonly ERR_CHART_PROP_INVALID = 4210
    public static readonly ERR_CHARTINDICATOR_NOT_FOUND = 4213
    public static readonly ERR_CHARTWINDOW_NOT_FOUND = 4212
    public static readonly ERR_COMMON_ERROR = 2
    public static readonly ERR_CUSTOM_INDICATOR_ERROR = 4055
    public static readonly ERR_DLL_CALLS_NOT_ALLOWED = 4017
    public static readonly ERR_DLLFUNC_CRITICALERROR = 4023
    public static readonly ERR_DOUBLE_PARAMETER_EXPECTED = 4064
    public static readonly ERR_END_OF_FILE = 4099
    public static readonly ERR_EXTERNAL_CALLS_NOT_ALLOWED = 4020
    public static readonly ERR_FILE_ARRAYRESIZE_ERROR = 5027
    public static readonly ERR_FILE_BIN_STRINGSIZE = 5017
    public static readonly ERR_FILE_BUFFER_ALLOCATION_ERROR = 5005
    public static readonly ERR_FILE_CANNOT_CLEAN_DIRECTORY = 5026
    public static readonly ERR_FILE_CANNOT_DELETE = 5006
    public static readonly ERR_FILE_CANNOT_DELETE_DIRECTORY = 5025
    public static readonly ERR_FILE_CANNOT_OPEN = 5004
    public static readonly ERR_FILE_CANNOT_REWRITE = 5021
    public static readonly ERR_FILE_DIRECTORY_NOT_EXIST = 5023
    public static readonly ERR_FILE_INCOMPATIBLE = 5018
    public static readonly ERR_FILE_INVALID_HANDLE = 5007
    public static readonly ERR_FILE_IS_DIRECTORY = 5019
    public static readonly ERR_FILE_NOT_BIN = 5011
    public static readonly ERR_FILE_NOT_CSV = 5014
    public static readonly ERR_FILE_NOT_DIRECTORY = 5024
    public static readonly ERR_FILE_NOT_EXIST = 5020
    public static readonly ERR_FILE_NOT_TOREAD = 5010
    public static readonly ERR_FILE_NOT_TOWRITE = 5009
    public static readonly ERR_FILE_NOT_TXT = 5012
    public static readonly ERR_FILE_NOT_TXTORCSV = 5013
    public static readonly ERR_FILE_READ_ERROR = 5015
    public static readonly ERR_FILE_STRINGRESIZE_ERROR = 5028
    public static readonly ERR_FILE_STRUCT_WITH_OBJECTS = 5029
    public static readonly ERR_FILE_TOO_LONG_FILENAME = 5003
    public static readonly ERR_FILE_TOO_MANY_OPENED = 5001
    public static readonly ERR_FILE_WRITE_ERROR = 5016
    public static readonly ERR_FILE_WRONG_DIRECTORYNAME = 5022
    public static readonly ERR_FILE_WRONG_FILENAME = 5002
    public static readonly ERR_FILE_WRONG_HANDLE = 5008
    public static readonly ERR_FORMAT_TOO_MANY_FORMATTERS = 4027
    public static readonly ERR_FORMAT_TOO_MANY_PARAMETERS = 4028
    public static readonly ERR_FUNC_NOT_ALLOWED_IN_TESTING = 4059
    public static readonly ERR_FUNCTION_NOT_CONFIRMED = 4060
    public static readonly ERR_GLOBAL_VARIABLE_NOT_FOUND = 4058
    public static readonly ERR_GLOBAL_VARIABLES_PROCESSING = 4057
    public static readonly ERR_HISTORY_WILL_UPDATED = 4066
    public static readonly ERR_INCOMPATIBLE_ARRAYS = 4056
    public static readonly ERR_INCOMPATIBLE_FILEACCESS = 4104
    public static readonly ERR_INCORRECT_SERIESARRAY_USING = 4054
    public static readonly ERR_INDICATOR_CANNOT_INIT = 4071
    public static readonly ERR_INDICATOR_CANNOT_LOAD = 4072
    public static readonly ERR_INTEGER_PARAMETER_EXPECTED = 4063
    public static readonly ERR_INTERNAL_ERROR = 4024
    public static readonly ERR_INVALID_ACCOUNT = 65
    public static readonly ERR_INVALID_FUNCTION_PARAMSCNT = 4050
    public static readonly ERR_INVALID_FUNCTION_PARAMVALUE = 4051
    public static readonly ERR_INVALID_POINTER = 4026
    public static readonly ERR_INVALID_PRICE = 129
    public static readonly ERR_INVALID_PRICE_PARAM = 4107
    public static readonly ERR_INVALID_STOPS = 130
    public static readonly ERR_INVALID_TICKET = 4108
    public static readonly ERR_INVALID_TRADE_PARAMETERS = 3
    public static readonly ERR_INVALID_TRADE_VOLUME = 131
    public static readonly ERR_LONG_POSITIONS_ONLY_ALLOWED = 140
    public static readonly ERR_LONGS_NOT_ALLOWED = 4110
    public static readonly ERR_MALFUNCTIONAL_TRADE = 9
    public static readonly ERR_MARKET_CLOSED = 132
    public static readonly ERR_NO_CONNECTION = 6
    public static readonly ERR_NO_ERROR = 0
    public static readonly ERR_NO_HISTORY_DATA = 4073
    public static readonly ERR_NO_MEMORY_FOR_ARRAYSTRING = 4010
    public static readonly ERR_NO_MEMORY_FOR_CALL_STACK = 4003
    public static readonly ERR_NO_MEMORY_FOR_HISTORY = 4074
    public static readonly ERR_NO_MEMORY_FOR_PARAM_STRING = 4006
    public static readonly ERR_NO_MEMORY_FOR_RETURNED_STR = 4021
    public static readonly ERR_NO_MEMORY_FOR_TEMP_STRING = 4007
    public static readonly ERR_NO_MQLERROR = 4000
    public static readonly ERR_NO_OBJECT_NAME = 4204
    public static readonly ERR_NO_ORDER_SELECTED = 4105
    public static readonly ERR_NO_RESULT = 1
    public static readonly ERR_NO_SPECIFIED_SUBWINDOW = 4206
    public static readonly ERR_NOT_ENOUGH_MONEY = 134
    public static readonly ERR_NOT_ENOUGH_RIGHTS = 7
    public static readonly ERR_NOT_ENOUGH_STACK_FOR_PARAM = 4005
    public static readonly ERR_NOT_INITIALIZED_ARRAY = 4016
    public static readonly ERR_NOT_INITIALIZED_ARRAYSTRING = 4009
    public static readonly ERR_NOT_INITIALIZED_STRING = 4008
    public static readonly ERR_NOTIFICATION_ERROR = 4250
    public static readonly ERR_NOTIFICATION_PARAMETER = 4251
    public static readonly ERR_NOTIFICATION_SETTINGS = 4252
    public static readonly ERR_NOTIFICATION_TOO_FREQUENT = 4253
    public static readonly ERR_OBJECT_ALREADY_EXISTS = 4200
    public static readonly ERR_OBJECT_COORDINATES_ERROR = 4205
    public static readonly ERR_OBJECT_DOES_NOT_EXIST = 4202
    public static readonly ERR_OFF_QUOTES = 136
    public static readonly ERR_OLD_VERSION = 5
    public static readonly ERR_ORDER_LOCKED = 139
    public static readonly ERR_OUT_OF_MEMORY = 4025
    public static readonly ERR_PRICE_CHANGED = 135
    public static readonly ERR_RECURSIVE_STACK_OVERFLOW = 4004
    public static readonly ERR_REMAINDER_FROM_ZERO_DIVIDE = 4012
    public static readonly ERR_REQUOTE = 138
    public static readonly ERR_RESOURCE_DUPLICATED = 4070
    public static readonly ERR_RESOURCE_NOT_FOUND = 4068
    public static readonly ERR_RESOURCE_NOT_SUPPORTED = 4069
    public static readonly ERR_SEND_MAIL_ERROR = 4061
    public static readonly ERR_SERVER_BUSY = 4
    public static readonly ERR_SHORTS_NOT_ALLOWED = 4111
    public static readonly ERR_SOME_ARRAY_ERROR = 4053
    public static readonly ERR_SOME_FILE_ERROR = 4100
    public static readonly ERR_SOME_OBJECT_ERROR = 4207
    public static readonly ERR_STRING_FUNCTION_INTERNAL = 4052
    public static readonly ERR_STRING_PARAMETER_EXPECTED = 4062
    public static readonly ERR_SYMBOL_SELECT = 4220
    public static readonly ERR_SYSTEM_BUSY = 4022
    public static readonly ERR_TOO_FREQUENT_REQUESTS = 8
    public static readonly ERR_TOO_LONG_STRING = 4011
    public static readonly ERR_TOO_MANY_OPENED_FILES = 4102
    public static readonly ERR_TOO_MANY_REQUESTS = 141
    public static readonly ERR_TRADE_CONTEXT_BUSY = 146
    public static readonly ERR_TRADE_DISABLED = 133
    public static readonly ERR_TRADE_ERROR = 4067
    public static readonly ERR_TRADE_EXPERT_DISABLED_BY_SERVER = 4112
    public static readonly ERR_TRADE_EXPIRATION_DENIED = 147
    public static readonly ERR_TRADE_HEDGE_PROHIBITED = 149
    public static readonly ERR_TRADE_MODIFY_DENIED = 145
    public static readonly ERR_TRADE_NOT_ALLOWED = 4109
    public static readonly ERR_TRADE_PROHIBITED_BY_FIFO = 150
    public static readonly ERR_TRADE_TIMEOUT = 128
    public static readonly ERR_TRADE_TOO_MANY_ORDERS = 148
    public static readonly ERR_UNKNOWN_COMMAND = 4014
    public static readonly ERR_UNKNOWN_OBJECT_PROPERTY = 4201
    public static readonly ERR_UNKNOWN_OBJECT_TYPE = 4203
    public static readonly ERR_UNKNOWN_SYMBOL = 4106
    public static readonly ERR_USER_ERROR_FIRST = 65536
    public static readonly ERR_WEBREQUEST_CONNECT_FAILED = 5201
    public static readonly ERR_WEBREQUEST_INVALID_ADDRESS = 5200
    public static readonly ERR_WEBREQUEST_REQUEST_FAILED = 5203
    public static readonly ERR_WEBREQUEST_TIMEOUT = 5202
    public static readonly ERR_WRONG_FILE_NAME = 4101
    public static readonly ERR_WRONG_FUNCTION_POINTER = 4001
    public static readonly ERR_WRONG_JUMP = 4015
    public static readonly ERR_ZERO_DIVIDE = 4013
    public static readonly FILE_ACCESS_DATE = 4
    public static readonly FILE_ANSI = 32
    public static readonly FILE_BIN = 4
    public static readonly FILE_COMMON = 4096
    public static readonly FILE_CREATE_DATE = 2
    public static readonly FILE_CSV = 8
    public static readonly FILE_END = 7
    public static readonly FILE_EXISTS = 1
    public static readonly FILE_IS_ANSI = 13
    public static readonly FILE_IS_BINARY = 11
    public static readonly FILE_IS_COMMON = 9
    public static readonly FILE_IS_CSV = 12
    public static readonly FILE_IS_READABLE = 14
    public static readonly FILE_IS_TEXT = 10
    public static readonly FILE_IS_WRITABLE = 15
    public static readonly FILE_LINE_END = 8
    public static readonly FILE_MODIFY_DATE = 3
    public static readonly FILE_POSITION = 6
    public static readonly FILE_READ = 1
    public static readonly FILE_REWRITE = 512
    public static readonly FILE_SHARE_READ = 128
    public static readonly FILE_SHARE_WRITE = 256
    public static readonly FILE_SIZE = 5
    public static readonly FILE_TXT = 16
    public static readonly FILE_UNICODE = 64
    public static readonly FILE_WRITE = 2
    public static readonly FRIDAY = 5
    public static readonly INDICATOR_DIGITS = 0
    public static readonly INDICATOR_HEIGHT = 10
    public static readonly INDICATOR_LEVELCOLOR = 5
    public static readonly INDICATOR_LEVELS = 4
    public static readonly INDICATOR_LEVELSTYLE = 6
    public static readonly INDICATOR_LEVELTEXT = 9
    public static readonly INDICATOR_LEVELVALUE = 8
    public static readonly INDICATOR_LEVELWIDTH = 7
    public static readonly INDICATOR_MAXIMUM = 3
    public static readonly INDICATOR_MINIMUM = 2
    public static readonly INDICATOR_SHORTNAME = 1
    public static readonly IS_DEBUG_MODE = 0
    public static readonly IS_PROFILE_MODE = 0
    public static readonly MODE_ASK = 10
    public static readonly MODE_BID = 9
    public static readonly MODE_CHIKOUSPAN = 5
    public static readonly MODE_CLOSE = 3
    public static readonly MODE_DIGITS = 12
    public static readonly MODE_EMA = 1
    public static readonly MODE_EXPIRATION = 21
    public static readonly MODE_FREEZELEVEL = 33
    public static readonly MODE_CLOSEBY_ALLOWED = 34
    public static readonly MODE_GATORJAW = 1
    public static readonly MODE_GATORLIPS = 3
    public static readonly MODE_GATORTEETH = 2
    public static readonly MODE_HIGH = 2
    public static readonly MODE_HISTORY = 1
    public static readonly MODE_KIJUNSEN = 2
    public static readonly MODE_LOTSIZE = 15
    public static readonly MODE_LOTSTEP = 24
    public static readonly MODE_LOW = 1
    public static readonly MODE_LOWER = 2
    public static readonly MODE_LWMA = 3
    public static readonly MODE_MAIN = 0
    public static readonly MODE_MARGINCALCMODE = 28
    public static readonly MODE_MARGINHEDGED = 31
    public static readonly MODE_MARGININIT = 29
    public static readonly MODE_MARGINMAINTENANCE = 30
    public static readonly MODE_MARGINREQUIRED = 32
    public static readonly MODE_MAXLOT = 25
    public static readonly MODE_MINLOT = 23
    public static readonly MODE_MINUSDI = 2
    public static readonly MODE_OPEN = 0
    public static readonly MODE_PLUSDI = 1
    public static readonly MODE_POINT = 11
    public static readonly MODE_PROFITCALCMODE = 27
    public static readonly MODE_SENKOUSPANA = 3
    public static readonly MODE_SENKOUSPANB = 4
    public static readonly MODE_SIGNAL = 1
    public static readonly MODE_SMA = 0
    public static readonly MODE_SMMA = 2
    public static readonly MODE_SPREAD = 13
    public static readonly MODE_STARTING = 20
    public static readonly MODE_STOPLEVEL = 14
    public static readonly MODE_SWAPLONG = 18
    public static readonly MODE_SWAPSHORT = 19
    public static readonly MODE_SWAPTYPE = 26
    public static readonly MODE_TENKANSEN = 1
    public static readonly MODE_TICKSIZE = 17
    public static readonly MODE_TICKVALUE = 16
    public static readonly MODE_TIME = 5
    public static readonly MODE_TRADEALLOWED = 22
    public static readonly MODE_TRADES = 0
    public static readonly MODE_UPPER = 1
    public static readonly MODE_VOLUME = 4
    public static readonly MONDAY = 1
    public static readonly MQL_CODEPAGE = 15
    public static readonly MQL_DEBUG = 5
    public static readonly MQL_DLLS_ALLOWED = 3
    public static readonly MQL_LICENSE_TYPE = 9
    public static readonly MQL_OPTIMIZATION = 7
    public static readonly MQL_PROFILER = 10
    public static readonly MQL_PROGRAM_NAME = 0
    public static readonly MQL_PROGRAM_PATH = 1
    public static readonly MQL_PROGRAM_TYPE = 2
    public static readonly MQL_SIGNALS_ALLOWED = 14
    public static readonly MQL_TESTER = 6
    public static readonly MQL_TRADE_ALLOWED = 4
    public static readonly MQL_VISUAL_MODE = 8
    public static readonly OBJ_ALL_PERIODS = 511
    public static readonly OBJ_ARROW = 22
    public static readonly OBJ_ARROW_BUY = 37
    public static readonly OBJ_ARROW_CHECK = 34
    public static readonly OBJ_ARROW_DOWN = 32
    public static readonly OBJ_ARROW_LEFT_PRICE = 35
    public static readonly OBJ_ARROW_RIGHT_PRICE = 36
    public static readonly OBJ_ARROW_SELL = 38
    public static readonly OBJ_ARROW_STOP = 33
    public static readonly OBJ_ARROW_THUMB_DOWN = 30
    public static readonly OBJ_ARROW_THUMB_UP = 29
    public static readonly OBJ_ARROW_UP = 31
    public static readonly OBJ_BITMAP = 26
    public static readonly OBJ_BITMAP_LABEL = 24
    public static readonly OBJ_BUTTON = 25
    public static readonly OBJ_CHANNEL = 5
    public static readonly OBJ_CYCLES = 20
    public static readonly OBJ_EDIT = 27
    public static readonly OBJ_ELLIPSE = 18
    public static readonly OBJ_EVENT = 42
    public static readonly OBJ_EXPANSION = 14
    public static readonly OBJ_FIBO = 10
    public static readonly OBJ_FIBOARC = 13
    public static readonly OBJ_FIBOCHANNEL = 15
    public static readonly OBJ_FIBOFAN = 12
    public static readonly OBJ_FIBOTIMES = 11
    public static readonly OBJ_GANNFAN = 8
    public static readonly OBJ_GANNGRID = 9
    public static readonly OBJ_GANNLINE = 7
    public static readonly OBJ_HLINE = 1
    public static readonly OBJ_LABEL = 23
    public static readonly OBJ_NO_PERIODS = -1
    public static readonly OBJ_PERIOD_D1 = 64
    public static readonly OBJ_PERIOD_H1 = 16
    public static readonly OBJ_PERIOD_H4 = 32
    public static readonly OBJ_PERIOD_M1 = 1
    public static readonly OBJ_PERIOD_M15 = 4
    public static readonly OBJ_PERIOD_M30 = 8
    public static readonly OBJ_PERIOD_M5 = 2
    public static readonly OBJ_PERIOD_MN1 = 256
    public static readonly OBJ_PERIOD_W1 = 128
    public static readonly OBJ_PITCHFORK = 19
    public static readonly OBJ_RECTANGLE = 16
    public static readonly OBJ_RECTANGLE_LABEL = 28
    public static readonly OBJ_REGRESSION = 4
    public static readonly OBJ_STDDEVCHANNEL = 6
    public static readonly OBJ_TEXT = 21
    public static readonly OBJ_TREND = 2
    public static readonly OBJ_TRENDBYANGLE = 3
    public static readonly OBJ_TRIANGLE = 17
    public static readonly OBJ_VLINE = 0
    public static readonly OBJPROP_ALIGN = 1036
    public static readonly OBJPROP_ANCHOR = 1011
    public static readonly OBJPROP_ANGLE = 13
    public static readonly OBJPROP_ARROWCODE = 14
    public static readonly OBJPROP_BACK = 9
    public static readonly OBJPROP_BGCOLOR = 1025
    public static readonly OBJPROP_BMPFILE = 1017
    public static readonly OBJPROP_BORDER_COLOR = 1035
    public static readonly OBJPROP_BORDER_TYPE = 1029
    public static readonly OBJPROP_COLOR = 6
    public static readonly OBJPROP_CORNER = 101
    public static readonly OBJPROP_CREATETIME = 998
    public static readonly OBJPROP_DEVIATION = 16
    public static readonly OBJPROP_ELLIPSE = 11
    public static readonly OBJPROP_FIBOLEVELS = 200
    public static readonly OBJPROP_FIRSTLEVEL = 210
    public static readonly OBJPROP_FONT = 1001
    public static readonly OBJPROP_FONTSIZE = 100
    public static readonly OBJPROP_HIDDEN = 208
    public static readonly OBJPROP_LEVELCOLOR = 201
    public static readonly OBJPROP_LEVELS = 200
    public static readonly OBJPROP_LEVELSTYLE = 202
    public static readonly OBJPROP_LEVELTEXT = 205
    public static readonly OBJPROP_LEVELVALUE = 204
    public static readonly OBJPROP_LEVELWIDTH = 203
    public static readonly OBJPROP_NAME = 1037
    public static readonly OBJPROP_PRICE = 20
    public static readonly OBJPROP_PRICE1 = 1
    public static readonly OBJPROP_PRICE2 = 3
    public static readonly OBJPROP_PRICE3 = 5
    public static readonly OBJPROP_RAY = 10
    public static readonly OBJPROP_RAY_RIGHT = 1004
    public static readonly OBJPROP_READONLY = 1028
    public static readonly OBJPROP_SCALE = 12
    public static readonly OBJPROP_SELECTABLE = 1000
    public static readonly OBJPROP_SELECTED = 17
    public static readonly OBJPROP_STATE = 1018
    public static readonly OBJPROP_STYLE = 7
    public static readonly OBJPROP_SYMBOL = 1021
    public static readonly OBJPROP_TEXT = 999
    public static readonly OBJPROP_TIME = 19
    public static readonly OBJPROP_TIME1 = 0
    public static readonly OBJPROP_TIME2 = 2
    public static readonly OBJPROP_TIME3 = 4
    public static readonly OBJPROP_TIMEFRAMES = 15
    public static readonly OBJPROP_TOOLTIP = 206
    public static readonly OBJPROP_TYPE = 18
    public static readonly OBJPROP_WIDTH = 8
    public static readonly OBJPROP_XDISTANCE = 102
    public static readonly OBJPROP_XOFFSET = 1033
    public static readonly OBJPROP_XSIZE = 1019
    public static readonly OBJPROP_YDISTANCE = 103
    public static readonly OBJPROP_YOFFSET = 1034
    public static readonly OBJPROP_YSIZE = 1020
    public static readonly OBJPROP_ZORDER = 207
    public static readonly REASON_ACCOUNT = 6
    public static readonly REASON_CHARTCHANGE = 3
    public static readonly REASON_CHARTCLOSE = 4
    public static readonly REASON_CLOSE = 9
    public static readonly REASON_INITFAILED = 8
    public static readonly REASON_PARAMETERS = 5
    public static readonly REASON_PROGRAM = 0
    public static readonly REASON_RECOMPILE = 2
    public static readonly REASON_REMOVE = 1
    public static readonly REASON_TEMPLATE = 7
    public static readonly SEEK_CUR = 1
    public static readonly SEEK_END = 2
    public static readonly SEEK_SET = 0
    public static readonly SELECT_BY_POS = 0
    public static readonly SELECT_BY_TICKET = 1
    public static readonly SERIES_BARS_COUNT = 0
    public static readonly SERIES_FIRSTDATE = 1
    public static readonly SERIES_LASTBAR_DATE = 5
    public static readonly SERIES_SERVER_FIRSTDATE = 2
    public static readonly SHORT_MAX = 32767
    public static readonly SHORT_MIN = -32768
    public static readonly SIGNAL_BASE_AUTHOR_LOGIN = 13
    public static readonly SIGNAL_BASE_BALANCE = 17
    public static readonly SIGNAL_BASE_BROKER = 10
    public static readonly SIGNAL_BASE_BROKER_SERVER = 11
    public static readonly SIGNAL_BASE_CURRENCY = 14
    public static readonly SIGNAL_BASE_DATE_PUBLISHED = 1
    public static readonly SIGNAL_BASE_DATE_STARTED = 2
    public static readonly SIGNAL_BASE_EQUITY = 19
    public static readonly SIGNAL_BASE_GAIN = 16
    public static readonly SIGNAL_BASE_ID = 0
    public static readonly SIGNAL_BASE_LEVERAGE = 6
    public static readonly SIGNAL_BASE_MAX_DRAWDOWN = 18
    public static readonly SIGNAL_BASE_NAME = 12
    public static readonly SIGNAL_BASE_PIPS = 8
    public static readonly SIGNAL_BASE_PRICE = 15
    public static readonly SIGNAL_BASE_RATING = 9
    public static readonly SIGNAL_BASE_ROI = 20
    public static readonly SIGNAL_BASE_SUBSCRIBERS = 4
    public static readonly SIGNAL_BASE_TRADE_MODE = 5
    public static readonly SIGNAL_BASE_TRADES = 7
    public static readonly SIGNAL_INFO_CONFIRMATIONS_DISABLED = 4
    public static readonly SIGNAL_INFO_COPY_SLTP = 3
    public static readonly SIGNAL_INFO_DEPOSIT_PERCENT = 5
    public static readonly SIGNAL_INFO_EQUITY_LIMIT = 8
    public static readonly SIGNAL_INFO_ID = 0
    public static readonly SIGNAL_INFO_NAME = 6
    public static readonly SIGNAL_INFO_SLIPPAGE = 9
    public static readonly SIGNAL_INFO_SUBSCRIPTION_ENABLED = 1
    public static readonly SIGNAL_INFO_TERMS_AGREE = 2
    public static readonly SIGNAL_INFO_VOLUME_PERCENT = 7
    public static readonly STAT_BALANCE_DD = 12
    public static readonly STAT_BALANCE_DD_RELATIVE = 14
    public static readonly STAT_BALANCE_DDREL_PERCENT = 15
    public static readonly STAT_BALANCEDD_PERCENT = 13
    public static readonly STAT_BALANCEMIN = 11
    public static readonly STAT_CONLOSSMAX = 9
    public static readonly STAT_CONLOSSMAX_TRADES = 37
    public static readonly STAT_CONPROFITMAX = 7
    public static readonly STAT_CONPROFITMAX_TRADES = 35
    public static readonly STAT_CUSTOM_ONTESTER = 26
    public static readonly STAT_DEALS = 27
    public static readonly STAT_EQUITY_DD = 17
    public static readonly STAT_EQUITY_DD_RELATIVE = 19
    public static readonly STAT_EQUITY_DDREL_PERCENT = 20
    public static readonly STAT_EQUITYDD_PERCENT = 18
    public static readonly STAT_EQUITYMIN = 16
    public static readonly STAT_EXPECTED_PAYOFF = 21
    public static readonly STAT_GROSS_LOSS = 4
    public static readonly STAT_GROSS_PROFIT = 3
    public static readonly STAT_INITIAL_DEPOSIT = 0
    public static readonly STAT_LONG_TRADES = 32
    public static readonly STAT_LOSS_TRADES = 30
    public static readonly STAT_LOSSTRADES_AVGCON = 40
    public static readonly STAT_MAX_CONLOSS_TRADES = 38
    public static readonly STAT_MAX_CONLOSSES = 10
    public static readonly STAT_MAX_CONPROFIT_TRADES = 36
    public static readonly STAT_MAX_CONWINS = 8
    public static readonly STAT_MAX_LOSSTRADE = 6
    public static readonly STAT_MAX_PROFITTRADE = 5
    public static readonly STAT_MIN_MARGINLEVEL = 25
    public static readonly STAT_PROFIT = 2
    public static readonly STAT_PROFIT_FACTOR = 22
    public static readonly STAT_PROFIT_LONGTRADES = 34
    public static readonly STAT_PROFIT_SHORTTRADES = 33
    public static readonly STAT_PROFIT_TRADES = 29
    public static readonly STAT_PROFITTRADES_AVGCON = 39
    public static readonly STAT_RECOVERY_FACTOR = 23
    public static readonly STAT_SHARPE_RATIO = 24
    public static readonly STAT_SHORT_TRADES = 31
    public static readonly STAT_TRADES = 28
    public static readonly STAT_WITHDRAWAL = 1
    public static readonly STO_CLOSECLOSE = 1
    public static readonly STO_LOWHIGH = 0
    public static readonly STYLE_DASH = 1
    public static readonly STYLE_DASHDOT = 3
    public static readonly STYLE_DASHDOTDOT = 4
    public static readonly STYLE_DOT = 2
    public static readonly STYLE_SOLID = 0
    public static readonly SYMBOL_ASK = 4
    public static readonly SYMBOL_ASKHIGH = 5
    public static readonly SYMBOL_ASKLOW = 6
    public static readonly SYMBOL_BID = 1
    public static readonly SYMBOL_BIDHIGH = 2
    public static readonly SYMBOL_BIDLOW = 3
    public static readonly SYMBOL_CURRENCY_BASE = 22
    public static readonly SYMBOL_CURRENCY_MARGIN = 24
    public static readonly SYMBOL_CURRENCY_PROFIT = 23
    public static readonly SYMBOL_DESCRIPTION = 20
    public static readonly SYMBOL_DIGITS = 17
    public static readonly SYMBOL_EXPIRATION_MODE = 49
    public static readonly SYMBOL_EXPIRATION_TIME = 52
    public static readonly SYMBOL_FILLING_MODE = 50
    public static readonly SYMBOL_LAST = 7
    public static readonly SYMBOL_LASTHIGH = 8
    public static readonly SYMBOL_LASTLOW = 9
    public static readonly SYMBOL_MARGIN_INITIAL = 42
    public static readonly SYMBOL_MARGIN_LIMIT = 46
    public static readonly SYMBOL_MARGIN_LONG = 44
    public static readonly SYMBOL_MARGIN_MAINTENANCE = 43
    public static readonly SYMBOL_MARGIN_SHORT = 45
    public static readonly SYMBOL_MARGIN_STOP = 47
    public static readonly SYMBOL_MARGIN_STOPLIMIT = 48
    public static readonly SYMBOL_ORDER_MODE = 71
    public static readonly SYMBOL_PATH = 21
    public static readonly SYMBOL_POINT = 16
    public static readonly SYMBOL_SELECT = 0
    public static readonly SYMBOL_SESSION_AW = 66
    public static readonly SYMBOL_SESSION_BUY_ORDERS = 60
    public static readonly SYMBOL_SESSION_BUY_ORDERS_VOLUME = 61
    public static readonly SYMBOL_SESSION_CLOSE = 65
    public static readonly SYMBOL_SESSION_DEALS = 56
    public static readonly SYMBOL_SESSION_INTEREST = 59
    public static readonly SYMBOL_SESSION_OPEN = 64
    public static readonly SYMBOL_SESSION_PRICE_LIMIT_MAX = 69
    public static readonly SYMBOL_SESSION_PRICE_LIMIT_MIN = 68
    public static readonly SYMBOL_SESSION_PRICE_SETTLEMENT = 67
    public static readonly SYMBOL_SESSION_SELL_ORDERS = 62
    public static readonly SYMBOL_SESSION_SELL_ORDERS_VOLUME = 63
    public static readonly SYMBOL_SESSION_TURNOVER = 58
    public static readonly SYMBOL_SESSION_VOLUME = 57
    public static readonly SYMBOL_SPREAD = 18
    public static readonly SYMBOL_SPREAD_FLOAT = 41
    public static readonly SYMBOL_START_TIME = 51
    public static readonly SYMBOL_SWAP_LONG = 38
    public static readonly SYMBOL_SWAP_MODE = 37
    public static readonly SYMBOL_SWAP_ROLLOVER3DAYS = 40
    public static readonly SYMBOL_SWAP_SHORT = 39
    public static readonly SYMBOL_TIME = 15
    public static readonly SYMBOL_TRADE_CALC_MODE = 29
    public static readonly SYMBOL_TRADE_CONTRACT_SIZE = 28
    public static readonly SYMBOL_TRADE_EXECUTION_EXCHANGE = 3
    public static readonly SYMBOL_TRADE_EXECUTION_INSTANT = 1
    public static readonly SYMBOL_TRADE_EXECUTION_MARKET = 2
    public static readonly SYMBOL_TRADE_EXECUTION_REQUEST = 0
    public static readonly SYMBOL_TRADE_EXEMODE = 33
    public static readonly SYMBOL_TRADE_FREEZE_LEVEL = 32
    public static readonly SYMBOL_TRADE_MODE = 30
    public static readonly SYMBOL_TRADE_MODE_CLOSEONLY = 1
    public static readonly SYMBOL_TRADE_MODE_DISABLED = 0
    public static readonly SYMBOL_TRADE_MODE_FULL = 2
    public static readonly SYMBOL_TRADE_MODE_LONGONLY = 3
    public static readonly SYMBOL_TRADE_MODE_SHORTONLY = 4
    public static readonly SYMBOL_TRADE_STOPS_LEVEL = 31
    public static readonly SYMBOL_TRADE_TICK_SIZE = 27
    public static readonly SYMBOL_TRADE_TICK_VALUE = 26
    public static readonly SYMBOL_TRADE_TICK_VALUE_LOSS = 54
    public static readonly SYMBOL_TRADE_TICK_VALUE_PROFIT = 53
    public static readonly SYMBOL_VOLUME = 10
    public static readonly SYMBOL_VOLUME_LIMIT = 55
    public static readonly SYMBOL_VOLUME_MAX = 35
    public static readonly SYMBOL_VOLUME_MIN = 34
    public static readonly SYMBOL_VOLUME_STEP = 36
    public static readonly SYMBOL_VOLUMEHIGH = 11
    public static readonly SYMBOL_VOLUMELOW = 12
    public static readonly TERMINAL_BUILD = 5
    public static readonly TERMINAL_CODEPAGE = 12
    public static readonly TERMINAL_COMMONDATA_PATH = 4
    public static readonly TERMINAL_COMMUNITY_ACCOUNT = 23
    public static readonly TERMINAL_COMMUNITY_BALANCE = 25
    public static readonly TERMINAL_COMMUNITY_CONNECTION = 24
    public static readonly TERMINAL_COMPANY = 0
    public static readonly TERMINAL_CONNECTED = 6
    public static readonly TERMINAL_CPU_CORES = 21
    public static readonly TERMINAL_DATA_PATH = 3
    public static readonly TERMINAL_DISK_SPACE = 20
    public static readonly TERMINAL_DLLS_ALLOWED = 7
    public static readonly TERMINAL_EMAIL_ENABLED = 9
    public static readonly TERMINAL_FTP_ENABLED = 10
    public static readonly TERMINAL_LANGUAGE = 13
    public static readonly TERMINAL_MAXBARS = 11
    public static readonly TERMINAL_MEMORY_AVAILABLE = 16
    public static readonly TERMINAL_MEMORY_PHYSICAL = 14
    public static readonly TERMINAL_MEMORY_TOTAL = 15
    public static readonly TERMINAL_MEMORY_USED = 17
    public static readonly TERMINAL_MQID = 22
    public static readonly TERMINAL_NAME = 1
    public static readonly TERMINAL_NOTIFICATIONS_ENABLED = 26
    public static readonly TERMINAL_PATH = 2
    public static readonly TERMINAL_PING_LAST = 28
    public static readonly TERMINAL_SCREEN_DPI = 27
    public static readonly TERMINAL_TRADE_ALLOWED = 8
    public static readonly WEDNESDAY = 3

    public static readonly clrAliceBlue = '#F0F8FF'
    public static readonly clrAntiqueWhite = '#FAEBD7'
    public static readonly clrAqua = '#00FFFF'
    public static readonly clrAquamarine = '#7FFFD4'
    public static readonly clrBeige = '#F5F5DC'
    public static readonly clrBisque = '#FFE4C4'
    public static readonly clrBlack = '#000000'
    public static readonly clrBlanchedAlmond = '#FFEBCD'
    public static readonly clrBlue = '#0000FF'
    public static readonly clrBlueViolet = '#8A2BE2'
    public static readonly clrBrown = '#A52A2A'
    public static readonly clrBurlyWood = '#DEB887'
    public static readonly clrCadetBlue = '#5F9EA0'
    public static readonly clrChartreuse = '#7FFF00'
    public static readonly clrChocolate = '#D2691E'
    public static readonly clrCoral = '#FF7F50'
    public static readonly clrCornflowerBlue = '#6495ED'
    public static readonly clrCornsilk = '#FFF8DC'
    public static readonly clrCrimson = '#DC143C'
    public static readonly clrDarkBlue = '#00008B'
    public static readonly clrDarkGoldenrod = '#B8860B'
    public static readonly clrDarkGray = '#A9A9A9'
    public static readonly clrDarkGreen = '#006400'
    public static readonly clrDarkKhaki = '#BDB76B'
    public static readonly clrDarkOliveGreen = '#556B2F'
    public static readonly clrDarkOrange = '#FF8C00'
    public static readonly clrDarkOrchid = '#9932CC'
    public static readonly clrDarkSalmon = '#E9967A'
    public static readonly clrDarkSeaGreen = '#8FBC8F'
    public static readonly clrDarkSlateBlue = '#483D8B'
    public static readonly clrDarkSlateGray = '#2F4F4F'
    public static readonly clrDarkTurquoise = '#00CED1'
    public static readonly clrDarkViolet = '#9400D3'
    public static readonly clrDeepPink = '#FF1493'
    public static readonly clrDeepSkyBlue = '#00BFFF'
    public static readonly clrDimGray = '#696969'
    public static readonly clrDodgerBlue = '#1E90FF'
    public static readonly clrFireBrick = '#B22222'
    public static readonly clrForestGreen = '#228B22'
    public static readonly clrGainsboro = '#DCDCDC'
    public static readonly clrGold = '#FFD700'
    public static readonly clrGoldenrod = '#DAA520'
    public static readonly clrGray = '#808080'
    public static readonly clrGreen = '#008000'
    public static readonly clrGreenYellow = '#ADFF2F'
    public static readonly clrHoneydew = '#F0FFF0'
    public static readonly clrHotPink = '#FF69B4'
    public static readonly clrIndianRed = '#CD5C5C'
    public static readonly clrIndigo = '#4B0082'
    public static readonly clrIvory = '#FFFFF0'
    public static readonly clrKhaki = '#F0E68C'
    public static readonly clrLavender = '#E6E6FA'
    public static readonly clrLavenderBlush = '#FFF0F5'
    public static readonly clrLawnGreen = '#7CFC00'
    public static readonly clrLemonChiffon = '#FFFACD'
    public static readonly clrLightBlue = '#ADD8E6'
    public static readonly clrLightCoral = '#F08080'
    public static readonly clrLightCyan = '#E0FFFF'
    public static readonly clrLightGoldenrod = '#FAFAD2'
    public static readonly clrLightGray = '#D3D3D3'
    public static readonly clrLightGreen = '#90EE90'
    public static readonly clrLightPink = '#FFB6C1'
    public static readonly clrLightSalmon = '#FFA07A'
    public static readonly clrLightSeaGreen = '#20B2AA'
    public static readonly clrLightSkyBlue = '#87CEFA'
    public static readonly clrLightSlateGray = '#778899'
    public static readonly clrLightSteelBlue = '#B0C4DE'
    public static readonly clrLightYellow = '#FFFFE0'
    public static readonly clrLime = '#00FF00'
    public static readonly clrLimeGreen = '#32CD32'
    public static readonly clrLinen = '#FAF0E6'
    public static readonly clrMagenta = '#FF00FF'
    public static readonly clrMaroon = '#800000'
    public static readonly clrMediumAquamarine = '#66CDAA'
    public static readonly clrMediumBlue = '#0000CD'
    public static readonly clrMediumOrchid = '#BA55D3'
    public static readonly clrMediumPurple = '#9370DB'
    public static readonly clrMediumSeaGreen = '#3CB371'
    public static readonly clrMediumSlateBlue = '#7B68EE'
    public static readonly clrMediumSpringGreen = '#00FA9A'
    public static readonly clrMediumTurquoise = '#48D1CC'
    public static readonly clrMediumVioletRed = '#C71585'
    public static readonly clrMidnightBlue = '#191970'
    public static readonly clrMintCream = '#F5FFFA'
    public static readonly clrMistyRose = '#FFE4E1'
    public static readonly clrMoccasin = '#FFE4B5'
    public static readonly clrNavajoWhite = '#FFDEAD'
    public static readonly clrNavy = '#000080'
    public static readonly clrOldLace = '#FDF5E6'
    public static readonly clrOlive = '#808000'
    public static readonly clrOliveDrab = '#6B8E23'
    public static readonly clrOrange = '#FFA500'
    public static readonly clrOrangeRed = '#FF4500'
    public static readonly clrOrchid = '#DA70D6'
    public static readonly clrPaleGoldenrod = '#EEE8AA'
    public static readonly clrPaleGreen = '#98FB98'
    public static readonly clrPaleTurquoise = '#AFEEEE'
    public static readonly clrPaleVioletRed = '#DB7093'
    public static readonly clrPapayaWhip = '#FFEFD5'
    public static readonly clrPeachPuff = '#FFDAB9'
    public static readonly clrPeru = '#CD853F'
    public static readonly clrPink = '#FFC0CB'
    public static readonly clrPlum = '#DDA0DD'
    public static readonly clrPowderBlue = '#B0E0E6'
    public static readonly clrPurple = '#800080'
    public static readonly clrRed = '#FF0000'
    public static readonly clrRosyBrown = '#BC8F8F'
    public static readonly clrRoyalBlue = '#4169E1'
    public static readonly clrSaddleBrown = '#8B4513'
    public static readonly clrSalmon = '#FA8072'
    public static readonly clrSandyBrown = '#F4A460'
    public static readonly clrSeaGreen = '#2E8B57'
    public static readonly clrSeashell = '#FFF5EE'
    public static readonly clrSienna = '#A0522D'
    public static readonly clrSilver = '#C0C0C0'
    public static readonly clrSkyBlue = '#87CEEB'
    public static readonly clrSlateBlue = '#6A5ACD'
    public static readonly clrSlateGray = '#708090'
    public static readonly clrSnow = '#FFFAFA'
    public static readonly clrSpringGreen = '#00FF7F'
    public static readonly clrSteelBlue = '#4682B4'
    public static readonly clrTan = '#D2B48C'
    public static readonly clrTeal = '#008080'
    public static readonly clrThistle = '#D8BFD8'
    public static readonly clrTomato = '#FF6347'
    public static readonly clrTurquoise = '#40E0D0'
    public static readonly clrViolet = '#EE82EE'
    public static readonly clrWheat = '#F5DEB3'
    public static readonly clrWhite = '#FFFFFF'
    public static readonly clrWhiteSmoke = '#F5F5F5'
    public static readonly clrYellow = '#FFFF00'
    public static readonly clrYellowGreen = '#9ACD32'
}

export enum TradeErrorCode {
    ERR_NO_ERROR = 0,
    ERR_NO_RESULT = 1,
    ERR_COMMON_ERROR = 2,
    ERR_INVALID_TRADE_PARAMETERS = 3,
    ERR_SERVER_BUSY = 4,
    ERR_OLD_VERSION = 5,
    ERR_NO_CONNECTION = 6,
    ERR_NOT_ENOUGH_RIGHTS = 7,
    ERR_TOO_FREQUENT_REQUESTS = 8,
    ERR_MALFUNCTIONAL_TRADE = 9,
    ERR_ACCOUNT_DISABLED = 64,
    ERR_INVALID_ACCOUNT = 65,
    ERR_TRADE_TIMEOUT = 128,
    ERR_INVALID_PRICE = 129,
    ERR_INVALID_STOPS = 130,
    ERR_INVALID_TRADE_VOLUME = 131,
    ERR_MARKET_CLOSED = 132,
    ERR_TRADE_DISABLED = 133,
    ERR_NOT_ENOUGH_MONEY = 134,
    ERR_PRICE_CHANGED = 135,
    ERR_OFF_QUOTES = 136,
    ERR_BROKER_BUSY = 137,
    ERR_REQUOTE = 138,
    ERR_ORDER_LOCKED = 139,
    ERR_LONG_POSITIONS_ONLY_ALLOWED = 140,
    ERR_TOO_MANY_REQUESTS = 141,
    ERR_TRADE_MODIFY_DENIED = 145,
    ERR_TRADE_CONTEXT_BUSY = 146,
    ERR_TRADE_EXPIRATION_DENIED = 147,
    ERR_TRADE_TOO_MANY_ORDERS = 148,
    ERR_TRADE_HEDGE_PROHIBITED = 149,
    ERR_TRADE_PROHIBITED_BY_FIFO = 150,
    ERR_NO_MQLERROR = 4000,
    ERR_WRONG_FUNCTION_POINTER = 4001,
    ERR_ARRAY_INDEX_OUT_OF_RANGE = 4002,
    ERR_NO_MEMORY_FOR_CALL_STACK = 4003,
    ERR_RECURSIVE_STACK_OVERFLOW = 4004,
    ERR_NOT_ENOUGH_STACK_FOR_PARAM = 4005,
    ERR_NO_MEMORY_FOR_PARAM_STRING = 4006,
    ERR_NO_MEMORY_FOR_TEMP_STRING = 4007,
    ERR_NOT_INITIALIZED_STRING = 4008,
    ERR_NOT_INITIALIZED_ARRAYSTRING = 4009,
    ERR_NO_MEMORY_FOR_ARRAYSTRING = 4010,
    ERR_TOO_LONG_STRING = 4011,
    ERR_REMAINDER_FROM_ZERO_DIVIDE = 4012,
    ERR_ZERO_DIVIDE = 4013,
    ERR_UNKNOWN_COMMAND = 4014,
    ERR_WRONG_JUMP = 4015,
    ERR_NOT_INITIALIZED_ARRAY = 4016,
    ERR_DLL_CALLS_NOT_ALLOWED = 4017,
    ERR_CANNOT_LOAD_LIBRARY = 4018,
    ERR_CANNOT_CALL_FUNCTION = 4019,
    ERR_EXTERNAL_CALLS_NOT_ALLOWED = 4020,
    ERR_NO_MEMORY_FOR_RETURNED_STR = 4021,
    ERR_SYSTEM_BUSY = 4022,
    ERR_DLLFUNC_CRITICALERROR = 4023,
    ERR_INTERNAL_ERROR = 4024,
    ERR_OUT_OF_MEMORY = 4025,
    ERR_INVALID_POINTER = 4026,
    ERR_FORMAT_TOO_MANY_FORMATTERS = 4027,
    ERR_FORMAT_TOO_MANY_PARAMETERS = 4028,
    ERR_ARRAY_INVALID = 4029,
    ERR_CHART_NOREPLY = 4030,
    ERR_INVALID_FUNCTION_PARAMSCNT = 4050,
    ERR_INVALID_FUNCTION_PARAMVALUE = 4051,
    ERR_STRING_FUNCTION_INTERNAL = 4052,
    ERR_SOME_ARRAY_ERROR = 4053,
    ERR_INCORRECT_SERIESARRAY_USING = 4054,
    ERR_CUSTOM_INDICATOR_ERROR = 4055,
    ERR_INCOMPATIBLE_ARRAYS = 4056,
    ERR_GLOBAL_VARIABLES_PROCESSING = 4057,
    ERR_GLOBAL_VARIABLE_NOT_FOUND = 4058,
    ERR_FUNC_NOT_ALLOWED_IN_TESTING = 4059,
    ERR_FUNCTION_NOT_CONFIRMED = 4060,
    ERR_SEND_MAIL_ERROR = 4061,
    ERR_STRING_PARAMETER_EXPECTED = 4062,
    ERR_INTEGER_PARAMETER_EXPECTED = 4063,
    ERR_DOUBLE_PARAMETER_EXPECTED = 4064,
    ERR_ARRAY_AS_PARAMETER_EXPECTED = 4065,
    ERR_HISTORY_WILL_UPDATED = 4066,
    ERR_TRADE_ERROR = 4067,
    ERR_RESOURCE_NOT_FOUND = 4068,
    ERR_RESOURCE_NOT_SUPPORTED = 4069,
    ERR_RESOURCE_DUPLICATED = 4070,
    ERR_INDICATOR_CANNOT_INIT = 4071,
    ERR_INDICATOR_CANNOT_LOAD = 4072,
    ERR_NO_HISTORY_DATA = 4073,
    ERR_NO_MEMORY_FOR_HISTORY = 4074,
    ERR_NO_MEMORY_FOR_INDICATOR = 4075,
    ERR_END_OF_FILE = 4099,
    ERR_SOME_FILE_ERROR = 4100,
    ERR_WRONG_FILE_NAME = 4101,
    ERR_TOO_MANY_OPENED_FILES = 4102,
    ERR_CANNOT_OPEN_FILE = 4103,
    ERR_INCOMPATIBLE_FILEACCESS = 4104,
    ERR_NO_ORDER_SELECTED = 4105,
    ERR_UNKNOWN_SYMBOL = 4106,
    ERR_INVALID_PRICE_PARAM = 4107,
    ERR_INVALID_TICKET = 4108,
    ERR_TRADE_NOT_ALLOWED = 4109,
    ERR_LONGS_NOT_ALLOWED = 4110,
    ERR_SHORTS_NOT_ALLOWED = 4111,
    ERR_TRADE_EXPERT_DISABLED_BY_SERVER = 4112,
    ERR_OBJECT_ALREADY_EXISTS = 4200,
    ERR_UNKNOWN_OBJECT_PROPERTY = 4201,
    ERR_OBJECT_DOES_NOT_EXIST = 4202,
    ERR_UNKNOWN_OBJECT_TYPE = 4203,
    ERR_NO_OBJECT_NAME = 4204,
    ERR_OBJECT_COORDINATES_ERROR = 4205,
    ERR_NO_SPECIFIED_SUBWINDOW = 4206,
    ERR_SOME_OBJECT_ERROR = 4207,
    ERR_CHART_PROP_INVALID = 4210,
    ERR_CHART_NOT_FOUND = 4211,
    ERR_CHARTWINDOW_NOT_FOUND = 4212,
    ERR_CHARTINDICATOR_NOT_FOUND = 4213,
    ERR_SYMBOL_SELECT = 4220,
    ERR_NOTIFICATION_ERROR = 4250,
    ERR_NOTIFICATION_PARAMETER = 4251,
    ERR_NOTIFICATION_SETTINGS = 4252,
    ERR_NOTIFICATION_TOO_FREQUENT = 4253,
    ERR_FTP_NOSERVER = 4260,
    ERR_FTP_NOLOGIN = 4261,
    ERR_FTP_CONNECT_FAILED = 4262,
    ERR_FTP_CLOSED = 4263,
    ERR_FTP_CHANGEDIR = 4264,
    ERR_FTP_FILE_ERROR = 4265,
    ERR_FTP_ERROR = 4266,
    ERR_FILE_TOO_MANY_OPENED = 5001,
    ERR_FILE_WRONG_FILENAME = 5002,
    ERR_FILE_TOO_LONG_FILENAME = 5003,
    ERR_FILE_CANNOT_OPEN = 5004,
    ERR_FILE_BUFFER_ALLOCATION_ERROR = 5005,
    ERR_FILE_CANNOT_DELETE = 5006,
    ERR_FILE_INVALID_HANDLE = 5007,
    ERR_FILE_WRONG_HANDLE = 5008,
    ERR_FILE_NOT_TOWRITE = 5009,
    ERR_FILE_NOT_TOREAD = 5010,
    ERR_FILE_NOT_BIN = 5011,
    ERR_FILE_NOT_TXT = 5012,
    ERR_FILE_NOT_TXTORCSV = 5013,
    ERR_FILE_NOT_CSV = 5014,
    ERR_FILE_READ_ERROR = 5015,
    ERR_FILE_WRITE_ERROR = 5016,
    ERR_FILE_BIN_STRINGSIZE = 5017,
    ERR_FILE_INCOMPATIBLE = 5018,
    ERR_FILE_IS_DIRECTORY = 5019,
    ERR_FILE_NOT_EXIST = 5020,
    ERR_FILE_CANNOT_REWRITE = 5021,
    ERR_FILE_WRONG_DIRECTORYNAME = 5022,
    ERR_FILE_DIRECTORY_NOT_EXIST = 5023,
    ERR_FILE_NOT_DIRECTORY = 5024,
    ERR_FILE_CANNOT_DELETE_DIRECTORY = 5025,
    ERR_FILE_CANNOT_CLEAN_DIRECTORY = 5026,
    ERR_FILE_ARRAYRESIZE_ERROR = 5027,
    ERR_FILE_STRINGRESIZE_ERROR = 5028,
    ERR_FILE_STRUCT_WITH_OBJECTS = 5029,
    ERR_WEBREQUEST_INVALID_ADDRESS = 5200,
    ERR_WEBREQUEST_CONNECT_FAILED = 5201,
    ERR_WEBREQUEST_TIMEOUT = 5202,
    ERR_WEBREQUEST_REQUEST_FAILED = 5203,
    // User Errors
    ERR_USER_ERROR_FIRST = 65536,
    // custom errors(from developer)
    ERR_INVALID_INDEX = 65537
}

export const TradeErrorMessages: Record<TradeErrorCode, string> = {
    [TradeErrorCode.ERR_NO_ERROR]: 'No error returned',
    [TradeErrorCode.ERR_NO_RESULT]: 'No error returned, but the result is unknown',
    [TradeErrorCode.ERR_COMMON_ERROR]: 'Common error',
    [TradeErrorCode.ERR_INVALID_TRADE_PARAMETERS]: 'Invalid trade parameters',
    [TradeErrorCode.ERR_SERVER_BUSY]: 'Trade server is busy',
    [TradeErrorCode.ERR_OLD_VERSION]: 'Old version of the client terminal',
    [TradeErrorCode.ERR_NO_CONNECTION]: 'No connection with trade server',
    [TradeErrorCode.ERR_NOT_ENOUGH_RIGHTS]: 'Not enough rights',
    [TradeErrorCode.ERR_TOO_FREQUENT_REQUESTS]: 'Too frequent requests',
    [TradeErrorCode.ERR_MALFUNCTIONAL_TRADE]: 'Malfunctional trade operation',
    [TradeErrorCode.ERR_ACCOUNT_DISABLED]: 'Account disabled',
    [TradeErrorCode.ERR_INVALID_ACCOUNT]: 'Invalid account',
    [TradeErrorCode.ERR_TRADE_TIMEOUT]: 'Trade timeout',
    [TradeErrorCode.ERR_INVALID_PRICE]: 'Invalid price',
    [TradeErrorCode.ERR_INVALID_STOPS]: 'Invalid stops',
    [TradeErrorCode.ERR_INVALID_TRADE_VOLUME]: 'Invalid trade volume',
    [TradeErrorCode.ERR_MARKET_CLOSED]: 'Market is closed',
    [TradeErrorCode.ERR_TRADE_DISABLED]: 'Trade is disabled',
    [TradeErrorCode.ERR_NOT_ENOUGH_MONEY]: 'Not enough money',
    [TradeErrorCode.ERR_PRICE_CHANGED]: 'Price changed',
    [TradeErrorCode.ERR_OFF_QUOTES]: 'Off quotes',
    [TradeErrorCode.ERR_BROKER_BUSY]: 'Broker is busy',
    [TradeErrorCode.ERR_REQUOTE]: 'Requote',
    [TradeErrorCode.ERR_ORDER_LOCKED]: 'Order is locked',
    [TradeErrorCode.ERR_LONG_POSITIONS_ONLY_ALLOWED]: 'Buy orders only allowed',
    [TradeErrorCode.ERR_TOO_MANY_REQUESTS]: 'Too many requests',
    [TradeErrorCode.ERR_TRADE_MODIFY_DENIED]: 'Modification denied because order is too close to market',
    [TradeErrorCode.ERR_TRADE_CONTEXT_BUSY]: 'Trade context is busy',
    [TradeErrorCode.ERR_TRADE_EXPIRATION_DENIED]: 'Expirations are denied by broker',
    [TradeErrorCode.ERR_TRADE_TOO_MANY_ORDERS]:
        'The amount of open and pending orders has reached the limit set by the broker',
    [TradeErrorCode.ERR_TRADE_HEDGE_PROHIBITED]:
        'An attempt to open an order opposite to the existing one when hedging is disabled',
    [TradeErrorCode.ERR_TRADE_PROHIBITED_BY_FIFO]: 'An attempt to close an order contravening the FIFO rule',
    [TradeErrorCode.ERR_NO_MQLERROR]: 'No error returned',
    [TradeErrorCode.ERR_WRONG_FUNCTION_POINTER]: 'Wrong function pointer',
    [TradeErrorCode.ERR_ARRAY_INDEX_OUT_OF_RANGE]: 'Array index is out of range',
    [TradeErrorCode.ERR_NO_MEMORY_FOR_CALL_STACK]: 'No memory for function call stack',
    [TradeErrorCode.ERR_RECURSIVE_STACK_OVERFLOW]: 'Recursive stack overflow',
    [TradeErrorCode.ERR_NOT_ENOUGH_STACK_FOR_PARAM]: 'Not enough stack for parameter',
    [TradeErrorCode.ERR_NO_MEMORY_FOR_PARAM_STRING]: 'No memory for parameter string',
    [TradeErrorCode.ERR_NO_MEMORY_FOR_TEMP_STRING]: 'No memory for temp string',
    [TradeErrorCode.ERR_NOT_INITIALIZED_STRING]: 'Not initialized string',
    [TradeErrorCode.ERR_NOT_INITIALIZED_ARRAYSTRING]: 'Not initialized string in array',
    [TradeErrorCode.ERR_NO_MEMORY_FOR_ARRAYSTRING]: 'No memory for array string',
    [TradeErrorCode.ERR_TOO_LONG_STRING]: 'Too long string',
    [TradeErrorCode.ERR_REMAINDER_FROM_ZERO_DIVIDE]: 'Remainder from zero divide',
    [TradeErrorCode.ERR_ZERO_DIVIDE]: 'Zero divide',
    [TradeErrorCode.ERR_UNKNOWN_COMMAND]: 'Unknown command',
    [TradeErrorCode.ERR_WRONG_JUMP]: 'Wrong jump (never generated error)',
    [TradeErrorCode.ERR_NOT_INITIALIZED_ARRAY]: 'Not initialized array',
    [TradeErrorCode.ERR_DLL_CALLS_NOT_ALLOWED]: 'DLL calls are not allowed',
    [TradeErrorCode.ERR_CANNOT_LOAD_LIBRARY]: 'Cannot load library',
    [TradeErrorCode.ERR_CANNOT_CALL_FUNCTION]: 'Cannot call function',
    [TradeErrorCode.ERR_EXTERNAL_CALLS_NOT_ALLOWED]: 'Expert function calls are not allowed',
    [TradeErrorCode.ERR_NO_MEMORY_FOR_RETURNED_STR]: 'Not enough memory for temp string returned from function',
    [TradeErrorCode.ERR_SYSTEM_BUSY]: 'System is busy (never generated error)',
    [TradeErrorCode.ERR_DLLFUNC_CRITICALERROR]: 'DLL-function call critical error',
    [TradeErrorCode.ERR_INTERNAL_ERROR]: 'Internal error',
    [TradeErrorCode.ERR_OUT_OF_MEMORY]: 'Out of memory',
    [TradeErrorCode.ERR_INVALID_POINTER]: 'Invalid pointer',
    [TradeErrorCode.ERR_FORMAT_TOO_MANY_FORMATTERS]: 'Too many formatters in the format function',
    [TradeErrorCode.ERR_FORMAT_TOO_MANY_PARAMETERS]: 'Parameters count exceeds formatters count',
    [TradeErrorCode.ERR_ARRAY_INVALID]: 'Invalid array',
    [TradeErrorCode.ERR_CHART_NOREPLY]: 'No reply from chart',
    [TradeErrorCode.ERR_INVALID_FUNCTION_PARAMSCNT]: 'Invalid function parameters count',
    [TradeErrorCode.ERR_INVALID_FUNCTION_PARAMVALUE]: 'Invalid function parameter value',
    [TradeErrorCode.ERR_STRING_FUNCTION_INTERNAL]: 'String function internal error',
    [TradeErrorCode.ERR_SOME_ARRAY_ERROR]: 'Some array error',
    [TradeErrorCode.ERR_INCORRECT_SERIESARRAY_USING]: 'Incorrect series array using',
    [TradeErrorCode.ERR_CUSTOM_INDICATOR_ERROR]: 'Custom indicator error',
    [TradeErrorCode.ERR_INCOMPATIBLE_ARRAYS]: 'Arrays are incompatible',
    [TradeErrorCode.ERR_GLOBAL_VARIABLES_PROCESSING]: 'Global variables processing error',
    [TradeErrorCode.ERR_GLOBAL_VARIABLE_NOT_FOUND]: 'Global variable not found',
    [TradeErrorCode.ERR_FUNC_NOT_ALLOWED_IN_TESTING]: 'Function is not allowed in testing mode',
    [TradeErrorCode.ERR_FUNCTION_NOT_CONFIRMED]: 'Function is not allowed for call',
    [TradeErrorCode.ERR_SEND_MAIL_ERROR]: 'Send mail error',
    [TradeErrorCode.ERR_STRING_PARAMETER_EXPECTED]: 'String parameter expected',
    [TradeErrorCode.ERR_INTEGER_PARAMETER_EXPECTED]: 'Integer parameter expected',
    [TradeErrorCode.ERR_DOUBLE_PARAMETER_EXPECTED]: 'Double parameter expected',
    [TradeErrorCode.ERR_ARRAY_AS_PARAMETER_EXPECTED]: 'Array as parameter expected',
    [TradeErrorCode.ERR_HISTORY_WILL_UPDATED]: 'Requested history data is in updating state',
    [TradeErrorCode.ERR_TRADE_ERROR]: 'Internal trade error',
    [TradeErrorCode.ERR_RESOURCE_NOT_FOUND]: 'Resource not found',
    [TradeErrorCode.ERR_RESOURCE_NOT_SUPPORTED]: 'Resource not supported',
    [TradeErrorCode.ERR_RESOURCE_DUPLICATED]: 'Duplicate resource',
    [TradeErrorCode.ERR_INDICATOR_CANNOT_INIT]: 'Custom indicator cannot initialize',
    [TradeErrorCode.ERR_INDICATOR_CANNOT_LOAD]: 'Cannot load custom indicator',
    [TradeErrorCode.ERR_NO_HISTORY_DATA]: 'No history data',
    [TradeErrorCode.ERR_NO_MEMORY_FOR_HISTORY]: 'No memory for history data',
    [TradeErrorCode.ERR_NO_MEMORY_FOR_INDICATOR]: 'Not enough memory for indicator calculation',
    [TradeErrorCode.ERR_END_OF_FILE]: 'End of file',
    [TradeErrorCode.ERR_SOME_FILE_ERROR]: 'Some file error',
    [TradeErrorCode.ERR_WRONG_FILE_NAME]: 'Wrong file name',
    [TradeErrorCode.ERR_TOO_MANY_OPENED_FILES]: 'Too many opened files',
    [TradeErrorCode.ERR_CANNOT_OPEN_FILE]: 'Cannot open file',
    [TradeErrorCode.ERR_INCOMPATIBLE_FILEACCESS]: 'Incompatible access to a file',
    [TradeErrorCode.ERR_NO_ORDER_SELECTED]: 'No order selected',
    [TradeErrorCode.ERR_UNKNOWN_SYMBOL]: 'Unknown symbol',
    [TradeErrorCode.ERR_INVALID_PRICE_PARAM]: 'Invalid price',
    [TradeErrorCode.ERR_INVALID_TICKET]: 'Invalid ticket',
    [TradeErrorCode.ERR_TRADE_NOT_ALLOWED]:
        'Trade is not allowed. Enable checkbox "Allow live trading" in the Expert Advisor properties',
    [TradeErrorCode.ERR_LONGS_NOT_ALLOWED]: 'Longs are not allowed. Check the Expert Advisor properties',
    [TradeErrorCode.ERR_SHORTS_NOT_ALLOWED]: 'Shorts are not allowed. Check the Expert Advisor properties',
    [TradeErrorCode.ERR_TRADE_EXPERT_DISABLED_BY_SERVER]:
        'Automated trading by Expert Advisors/Scripts disabled by trade server',
    [TradeErrorCode.ERR_OBJECT_ALREADY_EXISTS]: 'Object already exists',
    [TradeErrorCode.ERR_UNKNOWN_OBJECT_PROPERTY]: 'Unknown object property',
    [TradeErrorCode.ERR_OBJECT_DOES_NOT_EXIST]: 'Object does not exist',
    [TradeErrorCode.ERR_UNKNOWN_OBJECT_TYPE]: 'Unknown object type',
    [TradeErrorCode.ERR_NO_OBJECT_NAME]: 'No object name',
    [TradeErrorCode.ERR_OBJECT_COORDINATES_ERROR]: 'Object coordinates error',
    [TradeErrorCode.ERR_NO_SPECIFIED_SUBWINDOW]: 'No specified subwindow',
    [TradeErrorCode.ERR_SOME_OBJECT_ERROR]: 'Graphical object error',
    [TradeErrorCode.ERR_CHART_PROP_INVALID]: 'Unknown chart property',
    [TradeErrorCode.ERR_CHART_NOT_FOUND]: 'Chart not found',
    [TradeErrorCode.ERR_CHARTWINDOW_NOT_FOUND]: 'Chart subwindow not found',
    [TradeErrorCode.ERR_CHARTINDICATOR_NOT_FOUND]: 'Chart indicator not found',
    [TradeErrorCode.ERR_SYMBOL_SELECT]: 'Symbol select error',
    [TradeErrorCode.ERR_NOTIFICATION_ERROR]: 'Notification error',
    [TradeErrorCode.ERR_NOTIFICATION_PARAMETER]: 'Notification parameter error',
    [TradeErrorCode.ERR_NOTIFICATION_SETTINGS]: 'Notifications disabled',
    [TradeErrorCode.ERR_NOTIFICATION_TOO_FREQUENT]: 'Notification send too frequent',
    [TradeErrorCode.ERR_FTP_NOSERVER]: 'FTP server is not specified',
    [TradeErrorCode.ERR_FTP_NOLOGIN]: 'FTP login is not specified',
    [TradeErrorCode.ERR_FTP_CONNECT_FAILED]: 'FTP connection failed',
    [TradeErrorCode.ERR_FTP_CLOSED]: 'FTP connection closed',
    [TradeErrorCode.ERR_FTP_CHANGEDIR]: 'FTP path not found on server',
    [TradeErrorCode.ERR_FTP_FILE_ERROR]: `File not found in the "Files" directory to send on FTP server`,
    [TradeErrorCode.ERR_FTP_ERROR]: 'Common error during FTP data transmission',
    [TradeErrorCode.ERR_FILE_TOO_MANY_OPENED]: 'Too many opened files',
    [TradeErrorCode.ERR_FILE_WRONG_FILENAME]: 'Wrong file name',
    [TradeErrorCode.ERR_FILE_TOO_LONG_FILENAME]: 'Too long file name',
    [TradeErrorCode.ERR_FILE_CANNOT_OPEN]: 'Cannot open file',
    [TradeErrorCode.ERR_FILE_BUFFER_ALLOCATION_ERROR]: 'Text file buffer allocation error',
    [TradeErrorCode.ERR_FILE_CANNOT_DELETE]: 'Cannot delete file',
    [TradeErrorCode.ERR_FILE_INVALID_HANDLE]: 'Invalid file handle (file closed or was not opened)',
    [TradeErrorCode.ERR_FILE_WRONG_HANDLE]: 'Wrong file handle (handle index is out of handle table)',
    [TradeErrorCode.ERR_FILE_NOT_TOWRITE]: 'File must be opened with FILE_WRITE flag',
    [TradeErrorCode.ERR_FILE_NOT_TOREAD]: 'File must be opened with FILE_READ flag',
    [TradeErrorCode.ERR_FILE_NOT_BIN]: 'File must be opened with FILE_BIN flag',
    [TradeErrorCode.ERR_FILE_NOT_TXT]: 'File must be opened with FILE_TXT flag',
    [TradeErrorCode.ERR_FILE_NOT_TXTORCSV]: 'File must be opened with FILE_TXT or FILE_CSV flag',
    [TradeErrorCode.ERR_FILE_NOT_CSV]: 'File must be opened with FILE_CSV flag',
    [TradeErrorCode.ERR_FILE_READ_ERROR]: 'File read error',
    [TradeErrorCode.ERR_FILE_WRITE_ERROR]: 'File write error',
    [TradeErrorCode.ERR_FILE_BIN_STRINGSIZE]: 'String size must be specified for binary file',
    [TradeErrorCode.ERR_FILE_INCOMPATIBLE]: 'Incompatible file (for string arrays-TXT, for others-BIN)',
    [TradeErrorCode.ERR_FILE_IS_DIRECTORY]: 'File is directory not file',
    [TradeErrorCode.ERR_FILE_NOT_EXIST]: 'File does not exist',
    [TradeErrorCode.ERR_FILE_CANNOT_REWRITE]: 'File cannot be rewritten',
    [TradeErrorCode.ERR_FILE_WRONG_DIRECTORYNAME]: 'Wrong directory name',
    [TradeErrorCode.ERR_FILE_DIRECTORY_NOT_EXIST]: 'Directory does not exist',
    [TradeErrorCode.ERR_FILE_NOT_DIRECTORY]: 'Specified file is not directory',
    [TradeErrorCode.ERR_FILE_CANNOT_DELETE_DIRECTORY]: 'Cannot delete directory',
    [TradeErrorCode.ERR_FILE_CANNOT_CLEAN_DIRECTORY]: 'Cannot clean directory',
    [TradeErrorCode.ERR_FILE_ARRAYRESIZE_ERROR]: 'Array resize error',
    [TradeErrorCode.ERR_FILE_STRINGRESIZE_ERROR]: 'String resize error',
    [TradeErrorCode.ERR_FILE_STRUCT_WITH_OBJECTS]: 'Structure contains strings or dynamic arrays',
    [TradeErrorCode.ERR_WEBREQUEST_INVALID_ADDRESS]: 'Invalid URL',
    [TradeErrorCode.ERR_WEBREQUEST_CONNECT_FAILED]: 'Failed to connect to specified URL',
    [TradeErrorCode.ERR_WEBREQUEST_TIMEOUT]: 'Timeout exceeded',
    [TradeErrorCode.ERR_WEBREQUEST_REQUEST_FAILED]: 'HTTP request failed',
    // User Errors
    [TradeErrorCode.ERR_USER_ERROR_FIRST]: 'User defined errors start with this code',
    // custom errors(from developer)
    [TradeErrorCode.ERR_INVALID_INDEX]: 'Invalid index'
}

export const MODE_ASCEND = 1
export const MODE_DESCEND = -1

export const MODE_CLOSEBY_ALLOWED = 34

export enum ENUM_SYMBOL_INFO_DOUBLE {
    SYMBOL_BID = 0,
    SYMBOL_ASK = 1,
    SYMBOL_POINT = 2,
    SYMBOL_TRADE_TICK_VALUE = 3,
    SYMBOL_TRADE_TICK_SIZE = 4,
    SYMBOL_TRADE_CONTRACT_SIZE = 5,
    SYMBOL_VOLUME_MIN = 6,
    SYMBOL_VOLUME_MAX = 7,
    SYMBOL_VOLUME_STEP = 8,
    SYMBOL_SWAP_LONG = 9,
    SYMBOL_SWAP_SHORT = 10,
    SYMBOL_MARGIN_INITIAL = 11,
    SYMBOL_MARGIN_MAINTENANCE = 12
}

export enum ENUM_SYMBOL_INFO_INTEGER {
    SYMBOL_SELECT = 0,
    SYMBOL_VISIBLE = 1,
    SYMBOL_TIME = 2,
    SYMBOL_DIGITS = 3,
    SYMBOL_SPREAD_FLOAT = 4,
    SYMBOL_SPREAD = 5,
    SYMBOL_TRADE_CALC_MODE = 6,
    SYMBOL_TRADE_MODE = 7,
    SYMBOL_START_TIME = 8,
    SYMBOL_EXPIRATION_TIME = 9,
    SYMBOL_TRADE_STOPS_LEVEL = 10,
    SYMBOL_TRADE_FREEZE_LEVEL = 11,
    SYMBOL_SWAP_MODE = 12,
    SYMBOL_SWAP_ROLLOVER3DAYS = 13
}

export enum ENUM_DAY_OF_WEEK {
    SUNDAY = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6
}

export enum ENUM_SYMBOL_TRADE_EXECUTION {
    SYMBOL_TRADE_EXECUTION_REQUEST = 0,
    SYMBOL_TRADE_EXECUTION_INSTANT = 1,
    SYMBOL_TRADE_EXECUTION_MARKET = 2,
    SYMBOL_TRADE_EXECUTION_EXCHANGE = 3
}

export enum ENUM_SYMBOL_INFO_STRING {
    SYMBOL_CURRENCY_BASE = 0,
    SYMBOL_CURRENCY_PROFIT = 1,
    SYMBOL_CURRENCY_MARGIN = 2,
    SYMBOL_DESCRIPTION = 3,
    SYMBOL_PATH = 4
}

export interface MQLTick {
    time: number // TODO: Change to FTODate
    bid: number
    ask: number
    last: number // could we get it?
    volume: number // could we get it?
}

export enum SwapTypes {
    SWAP_TYPE_POINT = 0,
    SWAP_TYPE_BASE_CURRENCY = 1,
    SWAP_TYPE_BY_INTEREST = 2,
    SWAP_TYPE_MARGIN_CURRENCY = 3
}

//Symbol Properties
export enum ESymbolPropertiesType {
    MODE_LOW = APIConstants.MODE_LOW,
    MODE_HIGH = APIConstants.MODE_HIGH,
    MODE_BID = APIConstants.MODE_BID,
    MODE_ASK = APIConstants.MODE_ASK,
    MODE_POINT = APIConstants.MODE_POINT,
    MODE_DIGITS = APIConstants.MODE_DIGITS,
    MODE_SPREAD = APIConstants.MODE_SPREAD,
    MODE_STOPLEVEL = APIConstants.MODE_STOPLEVEL,
    MODE_LOTSIZE = APIConstants.MODE_LOTSIZE,
    MODE_TICKVALUE = APIConstants.MODE_TICKVALUE,
    MODE_TICKSIZE = APIConstants.MODE_TICKSIZE,
    MODE_SWAPLONG = APIConstants.MODE_SWAPLONG,
    MODE_SWAPSHORT = APIConstants.MODE_SWAPSHORT,
    MODE_STARTING = APIConstants.MODE_STARTING,
    MODE_EXPIRATION = APIConstants.MODE_EXPIRATION,
    MODE_TRADEALLOWED = APIConstants.MODE_TRADEALLOWED,
    MODE_MINLOT = APIConstants.MODE_MINLOT,
    MODE_LOTSTEP = APIConstants.MODE_LOTSTEP,
    MODE_MAXLOT = APIConstants.MODE_MAXLOT,
    MODE_SWAPTYPE = APIConstants.MODE_SWAPTYPE,
    MODE_PROFITCALCMODE = APIConstants.MODE_PROFITCALCMODE,
    MODE_MARGINCALCMODE = APIConstants.MODE_MARGINCALCMODE,
    MODE_MARGININIT = APIConstants.MODE_MARGININIT,
    MODE_MARGINMAINTENANCE = APIConstants.MODE_MARGINMAINTENANCE,
    MODE_MARGINHEDGED = APIConstants.MODE_MARGINHEDGED,
    MODE_MARGINREQUIRED = APIConstants.MODE_MARGINREQUIRED,
    MODE_FREEZELEVEL = APIConstants.MODE_FREEZELEVEL,
    MODE_CLOSEBY_ALLOWED = APIConstants.MODE_CLOSEBY_ALLOWED
    }
    
export enum ENUM_ACCOUNT_TRADE_MODE {
    DEMO = 0,
    REAL = 2,
    CONTEST = 1
}

export enum ENUM_ACCOUNT_STOPOUT_MODE {
    PERCENT = 0,
    MONEY = 1
}

export enum ENUM_ACCOUNT_INFO_INTEGER {
    ACCOUNT_LOGIN = 0,
    ACCOUNT_TRADE_MODE = 32,
    ACCOUNT_LEVERAGE = 35,
    ACCOUNT_LIMIT_ORDERS = 47,
    ACCOUNT_MARGIN_SO_MODE = 44,
    ACCOUNT_TRADE_ALLOWED = 33,
    ACCOUNT_TRADE_EXPERT = 34
}

export enum ENUM_ACCOUNT_INFO_DOUBLE {
    ACCOUNT_BALANCE = 37,
    ACCOUNT_CREDIT = 38,
    ACCOUNT_PROFIT = 39,
    ACCOUNT_EQUITY = 40,
    ACCOUNT_MARGIN = 41,
    ACCOUNT_MARGIN_FREE = 42,
    ACCOUNT_MARGIN_LEVEL = 43,
    ACCOUNT_MARGIN_SO_CALL = 45,
    ACCOUNT_MARGIN_SO_SO = 46,
    ACCOUNT_MARGIN_INITIAL = 19,
    ACCOUNT_MARGIN_MAINTENANCE = 20,
    ACCOUNT_ASSETS = 21,
    ACCOUNT_LIABILITIES = 22,
    ACCOUNT_COMMISSION_BLOCKED = 23
}

export enum ENUM_ACCOUNT_INFO_STRING {
    ACCOUNT_NAME = 1,
    ACCOUNT_SERVER = 3,
    ACCOUNT_CURRENCY = 36,
    ACCOUNT_COMPANY = 2
}

export enum ENUM_TIMEFRAMES {
    PERIOD_CURRENT = 0, // Current timeframe
    PERIOD_M1 = 1, // 1 minute
    PERIOD_M5 = 5, // 5 minutes
    PERIOD_M15 = 15, // 15 minutes
    PERIOD_M30 = 30, // 30 minutes
    PERIOD_H1 = 60, // 1 hour
    PERIOD_H4 = 240, // 4 hours
    PERIOD_D1 = 1440, // 1 day
    PERIOD_W1 = 10080, // 1 week
    PERIOD_MN1 = 43200, // 1 month
    PERIOD_M2 = 2, // 2 minutes
    PERIOD_M3 = 3, // 3 minutes
    PERIOD_M4 = 4, // 4 minutes
    PERIOD_M6 = 6, // 6 minutes
    PERIOD_M10 = 10, // 10 minutes
    PERIOD_M12 = 12, // 12 minutes
    PERIOD_M20 = 20, // 20 minutes
    PERIOD_H2 = 120, // 2 hours
    PERIOD_H3 = 180, // 3 hours
    PERIOD_H6 = 360, // 6 hours
    PERIOD_H8 = 480, // 8 hours
    PERIOD_H12 = 720 // 12 hours
}
