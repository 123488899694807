import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from '@fto/ui'
import { Icon } from '@fto/icons'

import { QueryParams } from '@root/utils/query-params.util'
import { $getProjects } from '@root/store/projects/projects.selector'
import { usePrevious } from '@root/hooks/usePrevious'
import LastViewContextMenu from '@root/pages/Dashboard/components/LastViewContextMenu'
import SymbolsContextMenu from '@root/pages/Dashboard/components/SymbolsContextMenu'
import CreateNewProjectButton from '@root/pages/Dashboard/components/ProjectsDashboard/components/CreateNewProjectButton'
import { ProjectState, SET_QUERY_PARAM } from '@root/store/projects/project.slice'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { useDebounceHook } from '@root/hooks/useDebounce'

import Input from '@fto/ui/lib/components/InputField/components/Input'

import styles from './index.module.scss'
import ProjectsTable from '../ProjectsTable/ProjectsTable'
import ProjectsPagination from '../ProjectsPagination/ProjectsPagination'

type Props = { query: QueryParams; status: ProjectState['loading'] } & PropsWithChildren

const ProjectsDashboard: FC<Props> = ({ children, query, status }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const [searchValue, setSearchValue] = useState<string>('')
    const previousQueryValue = usePrevious(query.Search)

    const debounce = useDebounceHook(searchValue, 200)

    const projects = useAppSelector($getProjects)

    const handleSearchChange = (value: string) => {
        setSearchValue(value)
    }

    useEffect(() => {
        if (previousQueryValue === query.Search && query.Search) {
            setSearchValue(query.Search)
        }
    }, [previousQueryValue])

    useEffect(() => {
        if (debounce !== null) {
            dispatch(SET_QUERY_PARAM({ Search: debounce, Offset: 0 }))
        }
    }, [debounce])

    if (status === 'failed' && projects?.length === 0) {
        return <div className={styles.FailedStateWrapper}>{children}</div>
    }

    return (
        <>
            <Flex className={styles.tableSearchBar} justifyContent={'space-between'} alignItems={'center'}>
                <Input
                    value={searchValue || ''}
                    onChange={handleSearchChange}
                    className={styles.searchInput}
                    placeholder={t('dashboard.search.placeholder')}
                    reset
                    withSearchSuffix
                />
                <Flex className={styles.selectBar} gap={8} alignItems={'center'}>
                    <Flex alignItems={'center'}>
                        <SymbolsContextMenu />
                        <LastViewContextMenu />
                    </Flex>
                    <CreateNewProjectButton />
                </Flex>
            </Flex>
            <ProjectsTable items={projects || []} searchValue={searchValue} />
            <ProjectsPagination query={query} />
            <CreateNewProjectButton className={styles.mobileButton} />
        </>
    )
}
export default ProjectsDashboard
