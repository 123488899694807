import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { SwapsCalculationStrategy } from './SwapsCalculationStrategy'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { EDayOfWeek } from '@fto/lib/delphi_compatibility/DateTimeAuxiliary/EDayOfWeek'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class WednesdayTripleSwapStrategy extends SwapsCalculationStrategy {
    private formatDates(
        previousDate: TDateTime,
        currentDate: TDateTime,
        positionOpenedDate: TDateTime
    ): [TDateTime, TDateTime, TDateTime] {
        // Use positionOpenedDate as previousDate if previousDate is greater than currentDate
        if (previousDate > currentDate && currentDate < positionOpenedDate)
            return [
                DateUtils.GetDateWithNoTime(previousDate),
                DateUtils.GetDateWithNoTime(positionOpenedDate),
                DateUtils.GetDateWithNoTime(positionOpenedDate)
            ]
        else
            return [
                DateUtils.GetDateWithNoTime(previousDate),
                DateUtils.GetDateWithNoTime(currentDate),
                DateUtils.GetDateWithNoTime(positionOpenedDate)
            ]
    }

    // day and positionOpenedDate should be integer values returned by DateUtils.GetDateWithNoTime
    private getSwapsCountForDay(day: TDateTime, positionOpenedDate: TDateTime): number {
        switch (DateUtils.DayOfWeek(day)) {
            case EDayOfWeek.Saturday:
            case EDayOfWeek.Sunday:
                return 0
            case EDayOfWeek.Monday:
            case EDayOfWeek.Tuesday:
            case EDayOfWeek.Wednesday:
            case EDayOfWeek.Friday:
                return 1
            case EDayOfWeek.Thursday:
                // return 3(Wednesday + Saturday + Sunday) if position was held on weekend
                if (positionOpenedDate === day - 1 || positionOpenedDate === day - 2) return 1
                else return 3
            default:
                throw new StrangeError('Unknown day of week')
        }
    }

    private getDaysArray(fromDate: TDateTime, count: number): TDateTime[] {
        return Array.from({ length: count }, (_, i) => fromDate + i)
    }

    public calculateSwapsUnitsForDateRange(
        previousDate: TDateTime,
        currentDate: TDateTime,
        posType: TTradePositionType,
        positionOpenedDate: TDateTime
    ): number {
        const [fromDate, toDate, openedDate] = this.formatDates(previousDate, currentDate, positionOpenedDate)
        const isForwardDirection = fromDate < toDate
        const calcFromData = isForwardDirection ? fromDate + 1 : toDate + 1
        const calcToData = isForwardDirection ? toDate : fromDate
        const diffInDays = Math.abs(calcToData - calcFromData) + 1

        // Calc and add swaps count for each day
        const totalSwapsCounted = this.getDaysArray(calcFromData, diffInDays).reduce(
            (acc, day) => acc + this.getSwapsCountForDay(day, openedDate),
            0
        )

        const swapsValue = isForwardDirection
            ? totalSwapsCounted * this.getSwapValueForPosType(posType)
            : -(totalSwapsCounted * this.getSwapValueForPosType(posType))

        return swapsValue
    }
}
