import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { AvailableOptionsSettings, ConfigType, Option } from '../types/options.types'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { setBooleanValue, setNumberValue, setStringValue } from '.'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'

export function isColorSettingOption(option: Option) {
    return option?.type === 'colorSetting'
}

export function isCheckboxAndColorSettingOption(option: Option) {
    return option?.type === 'checkboxAndColorSetting'
}

export function isSliderSettingOption(option: Option) {
    return option?.type === 'sliderSetting'
}

export function isInputSettingOption(option: Option) {
    return option?.type === 'inputSetting'
}

export function isCheckboxSettingOption(option: Option) {
    return option?.type === 'checkboxSetting'
}

export function isDropdownSettingOption(option: Option) {
    return option?.type === 'dropdownSetting'
}

export function isCheckboxAndDropdownSettingOption(option: Option) {
    return option?.type === 'checkboxAndDropdownSetting'
}

export function isRadioSettingOption(option: Option) {
    return option?.type === 'radioSetting'
}

export function getConfigUnit(chart: TChartWindow, optionType: ConfigType) {
    const chartOptions = chart.ChartOptions
    const colorScheme = chartOptions.ColorScheme

    if (optionType === ConfigType.ColorScheme) {
        return colorScheme
    }

    if (optionType === ConfigType.ChartOptions) {
        return chartOptions
    }

    if (optionType === ConfigType.GlobalOptions) {
        return GlobalOptions.Options
    }

    if (optionType === ConfigType.ProjectInfo) {
        return GlobalProjectInfo.ProjectInfo
    }

    throw new StrangeError('Unknown option config type')
}

export const getOptionState = (option: Option, chart: TChartWindow) => {
    if (isColorSettingOption(option)) {
        const ColorConfigUnit = getConfigUnit(chart, option.colorConfigType)
        return {
            type: option.type,
            color: {
                value: ColorConfigUnit[option.colorKey as keyof typeof ColorConfigUnit]
            }
        }
    }

    if (isCheckboxAndColorSettingOption(option)) {
        const ColorConfigUnit = getConfigUnit(chart, option.colorConfigType)
        const booleanConfigUnit = getConfigUnit(chart, option.booleanConfigType)
        return {
            type: option.type,
            color: {
                value: ColorConfigUnit[option.colorKey as keyof typeof ColorConfigUnit]
            },
            checked: booleanConfigUnit[option.booleanKey as keyof typeof booleanConfigUnit]
        }
    }

    if (isSliderSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        return {
            type: option.type,
            value: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit]
        }
    }

    if (isInputSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        return {
            type: option.type,
            value: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit]
        }
    }

    if (isCheckboxSettingOption(option)) {
        const booleanConfigUnit = getConfigUnit(chart, option.booleanConfigType)
        return {
            type: option.type,
            checked: booleanConfigUnit[option.booleanKey as keyof typeof booleanConfigUnit]
        }
    }

    if (isDropdownSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        return {
            type: option.type,
            value: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit]
        }
    }

    if(isCheckboxAndDropdownSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        const booleanConfigUnit = getConfigUnit(chart, option.booleanConfigType)
        return {
            type: option.type,
            value: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit],
            checked: booleanConfigUnit[option.booleanKey as keyof typeof booleanConfigUnit]
        }
    }

    if(isRadioSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        return {
            type: option.type,
            value: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit]
        }
    }

    return { notNow: true }
}

export const getOptionValues = (option: Option, chart: TChartWindow, withHumanFormatting = false) => {
    if (isColorSettingOption(option)) {
        const colorConfigUnit = getConfigUnit(chart, option.colorConfigType)
        return {
            [option.colorKey]: colorConfigUnit[option.colorKey as keyof typeof colorConfigUnit]
        }
    }

    if (isCheckboxAndColorSettingOption(option)) {
        const colorConfigUnit = getConfigUnit(chart, option.colorConfigType)
        const booleanConfigUnit = getConfigUnit(chart, option.booleanConfigType)
        return {
            [option.colorKey]: colorConfigUnit[option.colorKey as keyof typeof colorConfigUnit],
            [option.booleanKey]: booleanConfigUnit[option.booleanKey as keyof typeof booleanConfigUnit]
        }
    }

    if (isSliderSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        const divisionFactor: number = withHumanFormatting ? option['divisionFactor'] : 1
        return {
            [option.valueKey]: (valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit] as any) * divisionFactor
        }
    }

    if (isInputSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        return {
            [option.valueKey]: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit]
        }
    }

    if (isCheckboxSettingOption(option)) {
        const booleanConfigUnit = getConfigUnit(chart, option.booleanConfigType)
        return {
            [option.booleanKey]: booleanConfigUnit[option.booleanKey as keyof typeof booleanConfigUnit]
        }
    }

    if (isDropdownSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        return {
            [option.valueKey]: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit]
        }
    }

    if(isCheckboxAndDropdownSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        const booleanConfigUnit = getConfigUnit(chart, option.booleanConfigType)
        return {
            [option.valueKey]: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit],
            [option.booleanKey]: booleanConfigUnit[option.booleanKey as keyof typeof booleanConfigUnit]
        }
    }

    if(isRadioSettingOption(option)) {
        const valueConfigUnit = getConfigUnit(chart, option.valueConfigType)
        return {
            [option.valueKey]: valueConfigUnit[option.valueKey as keyof typeof valueConfigUnit]
        }
    }

    return { notNow: true }
}

export const getSeparatedOptionsStates = (
    optionsConfig: { [key: string]: AvailableOptionsSettings },
    chart: TChartWindow,
    optionsStates: { [key: string]: any } = {}
) => {
    for (const key in optionsConfig) {
        const option = optionsConfig[key]

        if (key in optionsStates) throw new StrangeError('Option state already exists')

        optionsStates[key] = getOptionState(option, chart)

        if ('subOptions' in option) {
            const subOptions = getSeparatedOptionsStates(option.subOptions!, chart)

            for (const subKey in subOptions) {
                if (subKey in optionsStates) throw new StrangeError('Option state already exists')
                optionsStates[subKey] = subOptions[subKey]
            }
        }
    }

    return optionsStates
}

export const setOptionState = (chart: TChartWindow, optionConfig: Option, savedOption: Option) => {
    if (!optionConfig) {
        // Ignore template option that is not in the config
        console.warn('The template has deprecated option')
        return
    }

    if (!savedOption) {
        // Ignore template option that is not in the saved options
        console.warn('The template has not new option')
        return
    }

    const applyToAllCharts = false;
    const shouldSendNewSettingtoServer = false;

    if (isColorSettingOption(savedOption)) {
        setStringValue(chart, optionConfig.colorConfigType, optionConfig.colorKey, savedOption.color.value, applyToAllCharts, shouldSendNewSettingtoServer)
        return
    }
    if (isCheckboxAndColorSettingOption(savedOption)) {
        setStringValue(chart, optionConfig.colorConfigType, optionConfig.colorKey, savedOption.color.value, applyToAllCharts, shouldSendNewSettingtoServer)
        setBooleanValue(chart, optionConfig.booleanConfigType, optionConfig.booleanKey, savedOption.checked, applyToAllCharts, shouldSendNewSettingtoServer)
        return
    }
    if (isSliderSettingOption(savedOption)) {
        setStringValue(chart, optionConfig.valueConfigType, optionConfig.valueKey, savedOption.value, applyToAllCharts, shouldSendNewSettingtoServer)
        return
    }
    if (isInputSettingOption(savedOption)) {
        setStringValue(chart, optionConfig.valueConfigType, optionConfig.valueKey, savedOption.value, applyToAllCharts, shouldSendNewSettingtoServer)
        return
    }
    if (isCheckboxSettingOption(savedOption)) {
        setBooleanValue(chart, optionConfig.booleanConfigType, optionConfig.booleanKey, savedOption.checked, applyToAllCharts, shouldSendNewSettingtoServer)
        return
    }
    if (isDropdownSettingOption(savedOption)) {
        setNumberValue(chart, optionConfig.valueConfigType, optionConfig.valueKey, savedOption.value, applyToAllCharts, shouldSendNewSettingtoServer)
        return
    }
    if(isCheckboxAndDropdownSettingOption(savedOption)) {
        setNumberValue(chart, optionConfig.valueConfigType, optionConfig.valueKey, savedOption.value, applyToAllCharts, shouldSendNewSettingtoServer)
        setBooleanValue(chart, optionConfig.booleanConfigType, optionConfig.booleanKey, savedOption.checked, applyToAllCharts, shouldSendNewSettingtoServer)
        return
    }
    if(isRadioSettingOption(savedOption)) {
        setNumberValue(chart, optionConfig.valueConfigType, optionConfig.valueKey, savedOption.value, applyToAllCharts, shouldSendNewSettingtoServer)
        return
    }

    throw new StrangeError('Unknown option type')
}
