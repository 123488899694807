import { DateUtils, TDateTime } from '../../../delphi_compatibility/DateUtils'
import { TCommApplyType } from './BasicEnums'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import { ProjectJSONfromServer } from '@fto/lib/ProjectAdapter/Types'

export class TProjectInfo {
    //name!: string
    private _name: string | undefined = undefined
    private _fromDate: TDateTime | undefined = undefined
    private _toDate: TDateTime | undefined = undefined
    private _startDate: TDateTime | undefined = undefined
    private _deposit: number | undefined = undefined
    private _forwardTestingOnly: boolean | undefined = undefined
    private _leverage = 1
    CommPerLot = 5
    CommissionApplyType: TCommApplyType = TCommApplyType.ca_OpenClose

    public get name(): string {
        if (this._name === undefined) {
            throw new StrangeError('Project name is not initialized')
        }
        return this._name
    }

    public set name(value: string) {
        this._name = value
    }

    public get FromDate(): TDateTime {
        if (this._fromDate === undefined) {
            throw new StrangeError('FromDate is not initialized')
        }
        return this._fromDate
    }

    public set FromDate(value: TDateTime) {
        this._fromDate = value
    }

    public get ToDate(): TDateTime | undefined {
        return this._toDate
    }

    public set ToDate(value: TDateTime) {
        this._toDate = value
    }

    public get StartDate(): TDateTime {
        if (this._startDate === undefined) {
            throw new StrangeError('StartDate is not initialized')
        }
        return this._startDate
    }

    public set StartDate(value: TDateTime) {
        this._startDate = value
    }

    public get deposit(): number {
        if (this._deposit === undefined) {
            throw new StrangeError('Deposit is not initialized')
        }
        return this._deposit
    }

    public set deposit(value: number) {
        this._deposit = value
    }

    public get ForwardTestingOnly(): boolean {
        if (this._forwardTestingOnly === undefined) {
            throw new StrangeError('ForwardTestingOnly is not initialized')
        }
        return this._forwardTestingOnly
    }

    public set ForwardTestingOnly(value: boolean) {
        this._forwardTestingOnly = value
    }

    public get leverage(): number {
        return this._leverage
    }

    public set leverage(value: number) {
        this._leverage = value
    }

    //DST: TDstType = TDstType.dst_European

    private _approximateLastTickTime: TDateTime | undefined = undefined //used while the Seek is still in progress

    public SetApproximateLastTickTime(value: TDateTime): void {
        this._approximateLastTickTime = value
    }

    private _lastTickTime: TDateTime | undefined = undefined

    public GetLastProcessedTickTimeWithTimezoneAndDST(): TDateTime {
        return GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(
            this.GetLastProcessedTickTime(true)
        )
    }

    getCommission() {
        return {
            Value: this.CommPerLot,
            Type: this.CommissionApplyType
        }
    }

    fromProjectJSON(projectJSON: ProjectJSONfromServer): void {
        this.StartDate = DateUtils.fromUnixTimeMilliseconds(projectJSON.StartDate)

        if (projectJSON.Commission) {
            this.CommPerLot = projectJSON.Commission.Value
            this.CommissionApplyType = projectJSON.Commission.Type
        }
    }

    public GetLastProcessedTickTime(allowApproximateIfSeekIsInProgress = false): TDateTime {
        if (this._lastTickTime === undefined || DateUtils.IsEmpty(this._lastTickTime)) {
            if (allowApproximateIfSeekIsInProgress && this._approximateLastTickTime !== undefined) {
                return this._approximateLastTickTime
            }
            throw new StrangeError('LastTickTime is empty in getter')
        }
        return this._lastTickTime
    }

    public SetPreciseLastTickTime(value: TDateTime): void {
        if (DateUtils.IsEmpty(value)) {
            throw new StrangeError('LastTickTime is empty in setter')
        }
        this._lastTickTime = value
    }
}
