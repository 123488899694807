import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Flex, InputRadio, Typography } from '@fto/ui'

import { HistoricalEvent } from '../../../../types'

import styles from './index.module.scss'
import { highlightSearchText } from '@root/utils/highlightSearchText'

type Props = {
    historicalMoment: HistoricalEvent
    isActive: boolean
    setActiveEvent: (eventName: string) => void
    searchValue: string
}

const HistoricalMomentSelector: FC<Props> = ({ historicalMoment, isActive, setActiveEvent, searchValue }) => {
    const { t } = useTranslation()

    const selectOption = useCallback(() => {
        setActiveEvent(historicalMoment.key)
    }, [historicalMoment.key, setActiveEvent])

    const formattedDate = useMemo(() => {
        const date = new Date(historicalMoment.date)
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        })
    }, [historicalMoment.date])

    const highlightedTitle = useMemo(() => {
        return highlightSearchText(t(`historicalMomentsModal.name.${historicalMoment.key}`), searchValue, 500)
    }, [historicalMoment.key, searchValue])

    const highlightedDescription = useMemo(() => {
        return highlightSearchText(t(`historicalMomentsModal.description.${historicalMoment.key}`), searchValue, 500)
    }, [historicalMoment.key, searchValue])

    return (
        <Flex gap={8} onClick={selectOption} className={cx(styles.selector, { [styles.selected]: isActive })}>
            <InputRadio checked={isActive} className={styles.radio} />
            <Flex direction='column' block>
                <Flex justifyContent='space-between' gap={8} block>
                    <Typography type='interface-regular'>{highlightedTitle}</Typography>
                    <Typography type='subtext-regular' color='gray-800'>
                        {formattedDate}
                    </Typography>
                </Flex>
                <Typography
                    type='subtext-regular'
                    color='gray-800'
                    className={styles.description}
                    title={t(`historicalMomentsModal.description.${historicalMoment.key}`)}
                >
                    {highlightedDescription}
                </Typography>
            </Flex>
        </Flex>
    )
}

export default HistoricalMomentSelector
