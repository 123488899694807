import ChartSettingsStore from '@fto/lib/store/chartSettings'
import { showWarningToast } from '@root/utils/toasts'
import { t } from 'i18next'

export const tryStopTesting = () => {
    const {
        settings: { isPlaying }
    } = ChartSettingsStore
    if (!isPlaying) return

    ChartSettingsStore.setSettings((prev) => ({ ...prev, isPlaying: false }))
    ChartSettingsStore.updateChartSettings()
    showWarningToast({ message: t('settings.toasts.weStopedTesting') })
}
