import { HistoricalEvent } from '../types'

export const HISTORICAL_EVENTS: HistoricalEvent[] = [
    { group: 'Global Economic Crises', key: 'covidCrash', date: '2020-02-20' },
    { group: 'Global Economic Crises', key: 'financialCrisis2008', date: '2008-09-15' },
    { group: 'Global Economic Crises', key: 'svbBankruptcy', date: '2023-03-10' },
    { group: 'Global Economic Crises', key: 'ratesPolicyChange2022', date: '2022-03-16' },
    { group: 'Commodity & Cryptocurrency Price Fluctuations', key: 'retailCryptoTurmoil', date: '2017-12-17' },
    { group: 'Global Economic Crises', key: 'housingMarketCollapse', date: '2007-08-09' },
    { group: 'Geopolitical Shocks', key: 'brexitReferendumShock', date: '2016-06-23' },
    { group: 'Currency and Forex Market Surprises', key: 'swissFrancUnpegCrisis', date: '2015-01-15' },
    { group: 'Geopolitical Shocks', key: 'eurozoneDebtCrisis', date: '2010-04-23' },
    { group: 'Geopolitical Shocks', key: 'flashCrash2010', date: '2010-05-06' },
    { group: 'Currency and Forex Market Surprises', key: 'chinaStockMarketCrash', date: '2015-06-12' },
    { group: 'Geopolitical Shocks', key: 'yuanRevaluationShock', date: '2005-07-21' },
    { group: 'Currency and Forex Market Surprises', key: 'usHousingMarketPeak', date: '2006-06-01' },
    { group: 'Global Economic Crises', key: 'quantQuake2007', date: '2007-08-07' },
    { group: 'Currency and Forex Market Surprises', key: 'bearStearnsCollapse', date: '2008-03-14' },
    { group: 'Commodity & Cryptocurrency Price Fluctuations', key: 'commoditiesBoomCrash', date: '2008-07-01' },
    { group: 'Global Economic Crises', key: 'fedQuantitativeEasing', date: '2008-11-25' },
    { group: 'Global Economic Crises', key: 'greeceAusterityCrisis', date: '2010-04-23' },
    { group: 'Geopolitical Shocks', key: 'japanEarthquakeDisruption', date: '2011-03-11' },
    { group: 'Geopolitical Shocks', key: 'usDebtCeilingCrisis', date: '2011-07-31' },
    { group: 'Commodity & Cryptocurrency Price Fluctuations', key: 'oilPriceCollapse', date: '2014-06-20' },
    { group: 'Currency and Forex Market Surprises', key: 'yuanDevaluationShock', date: '2015-08-11' },
    { group: 'Geopolitical Shocks', key: 'brexitAgreementCollapse', date: '2019-01-15' },
    { group: 'Geopolitical Shocks', key: 'usChinaTradeWar', date: '2019-08-01' },
    { group: 'Geopolitical Shocks', key: 'oilPriceWar', date: '2020-03-08' },
    { group: 'Geopolitical Shocks', key: 'usElectionVolatility', date: '2020-11-03' },
    { group: 'Global Economic Crises', key: 'gamestopShortSqueeze', date: '2021-01-27' },
    { group: 'Global Economic Crises', key: 'evergrandeDebtCrisis', date: '2021-09-20' },
    { group: 'Currency and Forex Market Surprises', key: 'russianRubleCollapse', date: '2014-12-16' },
    { group: 'Global Economic Crises', key: 'turkeyLiraCrisis', date: '2018-08-10' },
    { group: 'Currency and Forex Market Surprises', key: 'britishPoundFlashCrash', date: '2016-10-07' },
    { group: 'Currency and Forex Market Surprises', key: 'chinaCircuitBreakers', date: '2016-01-04' },
    { group: 'Commodity & Cryptocurrency Price Fluctuations', key: 'goldSurgeUSInflation', date: '2020-08-07' },
    { group: 'Geopolitical Shocks', key: 'russiaUkraineInvasion', date: '2022-02-24' },
    { group: 'Commodity & Cryptocurrency Price Fluctuations', key: 'cryptoCrash2021', date: '2021-05-12' },
    { group: 'Geopolitical Shocks', key: 'pandemicInflationSurge', date: '2021-10-13' },
    { group: 'Geopolitical Shocks', key: 'eurozoneInflationCrisis', date: '2022-06-01' },
    { group: 'Commodity & Cryptocurrency Price Fluctuations', key: 'elSalvadorBitcoinAdoption', date: '2021-09-07' },
    { group: 'Geopolitical Shocks', key: 'usDebtCeilingStandoff', date: '2021-10-01' },
    { group: 'Geopolitical Shocks', key: 'omicronMarketReaction', date: '2021-11-26' },
    { group: 'Geopolitical Shocks', key: 'usInflation40YearHigh', date: '2022-06-10' },
    { group: 'Geopolitical Shocks', key: 'ukMiniBudgetTurmoil', date: '2022-09-23' },
    { group: 'Commodity & Cryptocurrency Price Fluctuations', key: 'ftxCollapse', date: '2022-11-11' },
    { group: 'Geopolitical Shocks', key: 'boeBondIntervention', date: '2022-09-28' },
    { group: 'Global Economic Crises', key: 'usRegionalBankFailures', date: '2023-05-01' },
    { group: 'Commodity & Cryptocurrency Price Fluctuations', key: 'saudiOilCuts', date: '2023-04-02' },
    { group: 'Geopolitical Shocks', key: 'chinaEconomicSlowdown', date: '2023-08-01' },
    { group: 'Global Economic Crises', key: 'ubsCreditSuisseMerger', date: '2023-03-19' },
    { group: 'Commodity & Cryptocurrency Price Fluctuations', key: 'goldSurgeGlobalUncertainty', date: '2024-02-15' },
    { group: 'Commodity & Cryptocurrency Price Fluctuations', key: 'goldSurgeDollarWeakness', date: '2023-08-12' }
]
