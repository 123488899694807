import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Flex } from '@fto/ui'
import { Icon } from '@fto/icons'

import { AVAILABLE_TABS, TabType } from '../../types/tabs.types'

import styles from './index.module.scss'

interface Props {
    tabsList: Record<AVAILABLE_TABS, TabType>
    openSettingsPage: (tabName: AVAILABLE_TABS | null) => void
}

const AllSettingsTab: FC<Props> = ({ tabsList, openSettingsPage }) => {
    const { t } = useTranslation()

    const visibleTabs = useMemo(
        () => Object.keys(tabsList).filter((key) => tabsList[key as AVAILABLE_TABS].showInGlobalList),
        [tabsList]
    )

    return (
        <Flex className={styles.scrollableContainer}>
            <Flex direction='column' gap={8} block>
                {visibleTabs.map((key) => {
                    const tab = tabsList[key as AVAILABLE_TABS]

                    return (
                        <Button
                            key={key}
                            type='tetriatry-white'
                            label={t(tab.titleKey)}
                            classNames={{ content: styles.Content, label: styles.Label }}
                            before={<Icon name={tab.icon!} size={18} className={styles.contentIcon} />}
                            onClick={() => {
                                openSettingsPage(key as AVAILABLE_TABS)
                            }}
                            block
                        />
                    )
                })}
            </Flex>
        </Flex>
    )
}

export default AllSettingsTab
