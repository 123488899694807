import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { Button, Flex, Typography, Separator } from '@fto/ui'
import { TRuntimeIndicator } from '@fto/lib/extension_modules/indicators/RuntimeIndicator'

import styles from '../common.module.scss'
import { TOscChart } from '@fto/lib/charting/chart_classes/OscChartUnit'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

type Props = {
    chart: TChartWindow
    indicator: TRuntimeIndicator
    removeMenu: () => void
    oscChart?: TOscChart
}

const IndicatorMenuContent: FC<Props> = ({ chart, indicator, removeMenu, oscChart }) => {
    const { t } = useTranslation()

    const [isVisible, setIsVisible] = useState(indicator.IsVisible())

    const handleSettings = useCallback(() => {
        indicator.ExportData(true)
        removeMenu()
    }, [indicator, removeMenu])

    const handleDelete = useCallback(() => {
        chart.onIndicatorSelect(indicator)
        chart.DeleteSelectedIndicator()
        chart.Repaint()
        removeMenu()
    }, [chart, indicator, removeMenu])

    const handleShowHide = useCallback(() => {
        if (isVisible) {
            indicator.Hide()
            chart.Repaint()
            setIsVisible(false)
        } else {
            indicator.Show()
            chart.Repaint()
            setIsVisible(true)
        }

        chart.onIndicatorVisibilityChange()
        removeMenu()
    }, [isVisible, chart, removeMenu])

    const handlePaste = useCallback(() => {
        if (!oscChart) {
            throw new StrangeError('OscChart is not defined')
        }
        chart.pastePaintTools(oscChart)
        removeMenu()
    }, [chart, removeMenu])

    return (
        <Flex direction='column' gap={4} className={styles.content}>
            <Typography className={styles.header} type='tiny-regular' color='gray-600'>
                {indicator.ShortName}
            </Typography>
            <Button
                before={<Icon name={isVisible ? 'eye-off' : 'eye'} size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t(isVisible ? 'contextMenu.hide' : 'contextMenu.show')}
                onClick={handleShowHide}
                block
            />
            <Button
                before={<Icon name='delete' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('global.delete')}
                onClick={handleDelete}
                block
            />
            <Separator width='90%' />
            <Button
                before={<Icon name='settings' size={16} />}
                classNames={{ content: styles.text }}
                size='tiny'
                type='tetriatry-white'
                label={t('global.settings')}
                onClick={handleSettings}
                block
            />

            {oscChart && (
                <>
                    <Separator width='90%' />
                    <Button
                        classNames={{ content: styles.textNoIcon }}
                        size='tiny'
                        type='tetriatry-white'
                        label={t('contextMenu.paste')}
                        onClick={handlePaste}
                        block
                    />
                </>
            )}
        </Flex>
    )
}

export default IndicatorMenuContent
