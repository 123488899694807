import { chartControlEvent, ChartControl } from './ChartControl'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { TOscChart } from '@fto/lib/charting/chart_classes/OscChartUnit'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import {
    IGPFont,
    TGPFontFamily,
    IGPSolidBrush,
    TGpFontStyle,
    IGPPen
} from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import GlobalImageManager from '@fto/lib/globals/GlobalImageManager'
import { addContextMenu, removeContextMenu } from '@fto/ui'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'
import ProjectStore from '@fto/lib/store/projectStore'
import { TimeframeUtils } from '@fto/lib/ft_types/common/TimeframeUtils'
import { TPoint, TRect } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { ChartControlParams } from '@fto/chart_components/ChartControlParams'
import { changeImageColor } from '@fto/lib/utils/common_utils'

export class ChangeSymbolButton extends ChartControl {
    private symbolRect!: TRect
    private chartWindow: TChartWindow
    private canvas!: TGdiPlusCanvas
    private timeFrameControl!: ChartControl

    private image: HTMLImageElement | null = null
    private borderRadius = 4
    private padding = 4
    private x = 5
    private y = 5

    private isHovered = false

    private font = new IGPFont(new TGPFontFamily('Arial'), 14, [TGpFontStyle.FontStyleRegular])

    constructor(
        chartWindow: TChartWindow,
        chartControlParams: ChartControlParams,
        image: HTMLImageElement | null = null
    ) {
        super(chartControlParams)
        this.chartWindow = chartWindow

        this.image = image

        const chartControlParamsImage = chartControlParams.clone()
        const chartControlParamsImageRect = chartControlParamsImage.getLocation()
        chartControlParamsImageRect.Right = chartControlParamsImageRect.Left + 24
        chartControlParamsImageRect.Bottom = chartControlParamsImageRect.Top + 24
        chartControlParamsImage.setLocation(chartControlParamsImageRect)

        this.timeFrameControl = new ChartControl(chartControlParamsImage)
        this.timeFrameControl.attachObserver(chartWindow)
    }

    private get timeFrame(): number {
        return this.chartWindow.getCurrentTimeframe()
    }

    private get symbolName(): string {
        return this.chartWindow.SelectedSymbolName
    }

    public draw(canvas: TGdiPlusCanvas): void {
        if (!this.canvas) {
            this.canvas = canvas
        }
        this.drawSymbolButton(canvas)
        this.drawTimeFrameButton(canvas)

        this.updateSelfLocation()

        // canvas.FillRectRounded(this.getLocation(), new IGPSolidBrush('f55', 0.1), 5) // for debug
    }

    private updateSelfLocation(): void {
        const leftBoundary = this.symbolRect.Left
        const newLocation = new TRect(
            leftBoundary,
            this.symbolRect.Top,
            this.timeFrameControl.getLocation().Right,
            this.symbolRect.Bottom
        )

        if (newLocation.Width !== this.getWidth() || newLocation.Height !== this.getHeight()) {
            this.setLocation(newLocation)

            const oneClickTrading = this.chartWindow.getChartWindowLayers().getOneClickTradingControl()
            if (oneClickTrading && oneClickTrading.IsVisible()) {
                oneClickTrading.setStartLeftCoordinate(newLocation.Right + 10)
            }
        }
    }

    private drawTimeFrameButton(canvas: TGdiPlusCanvas): void {
        let imgWidth = GlobalImageManager.Instance.chevronDownBold?.width || 0
        const backgroundColor = this.isHovered
            ? this.chartWindow.ChartOptions.ControlsColorScheme.buttonHoveredBackground
            : this.chartWindow.ChartOptions.ControlsColorScheme.buttonBackground
        const textAndImageColor = this.chartWindow.ChartOptions.ControlsColorScheme.buttonText
        const borderColor = this.isHovered
            ? this.chartWindow.ChartOptions.ControlsColorScheme.buttonHoveredBorder
            : this.chartWindow.ChartOptions.ControlsColorScheme.buttonBorder

        const timeFrameRect = canvas.drawButton(
            TimeframeUtils.GetShortTimeframeName(this.timeFrame),
            this.padding,
            this.borderRadius,
            this.symbolRect.Right + 4,
            this.symbolRect.Top,
            this.font,
            new IGPPen(borderColor, 1),
            new IGPSolidBrush(backgroundColor),
            textAndImageColor,
            imgWidth + this.padding
        )

        if (this.image) {
            const dpr = window.devicePixelRatio || 1

            const coloredImage = changeImageColor(
                this.image,
                textAndImageColor,
                this.image.width * dpr,
                this.image.height * dpr
            )

            canvas.graphics.Context.drawImage(
                coloredImage,
                timeFrameRect.Left + timeFrameRect.Width - this.image.width - this.padding,
                timeFrameRect.Top + timeFrameRect.Height / 2 - 3,
                this.image.width,
                this.image.height
            )
            this.timeFrameControl.setLocation(timeFrameRect)
        }
    }

    private drawSymbolButton(canvas: TGdiPlusCanvas): void {
        const backgroundColor = this.isHovered
            ? this.chartWindow.ChartOptions.ControlsColorScheme.buttonHoveredBackground
            : this.chartWindow.ChartOptions.ControlsColorScheme.buttonBackground
        const textColor = this.chartWindow.ChartOptions.ControlsColorScheme.buttonText
        const borderColor = this.isHovered
            ? this.chartWindow.ChartOptions.ControlsColorScheme.buttonHoveredBorder
            : this.chartWindow.ChartOptions.ControlsColorScheme.buttonBorder

        const symbolRect = canvas.drawButton(
            this.symbolName,
            this.padding,
            this.borderRadius,
            this.x,
            this.y,
            this.font,
            new IGPPen(borderColor, 1),
            new IGPSolidBrush(backgroundColor),
            textColor,
            0
        )

        this.setLocation(symbolRect)
        this.symbolRect = symbolRect
    }

    public onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        const x = event.clientX
        const y = event.clientY

        if (!this.symbolRect || sender instanceof TOscChart) {
            return null
        }

        if (this.symbolRect.PtInRect_numbers(x, y)) {
            this.notify(chartControlEvent.SYMBOL_CHANGE_MODAL)
            return this
        }

        if ((this.timeFrameControl as ChangeSymbolButton).isPointInside(x, y)) {
            const { data, updateData } = ProjectStore

            if (data.isTimeframeDropdownOpen) {
                removeContextMenu(CONTEXT_MENU_NAMES.timeframeChange)
                updateData({ ...data, isTimeframeDropdownOpen: false })

                return this
            }

            const { x: relativeX, y: relativeY } = this.chartWindow.MainChart.getBoundingClientRect()
            this.notify(chartControlEvent.BUTTON_PRESSED)
            addContextMenu(CONTEXT_MENU_NAMES.timeframeChange, {
                anchorX: relativeX + this.padding * 2 + this.symbolRect.Width,
                anchorY: relativeY + this.padding + this.symbolRect.Height,
                additionalProps: { chartWindow: this.chartWindow }
            })
            updateData({ ...data, isTimeframeDropdownOpen: true })
            return this
        }

        return null
    }

    public onMouseMove(event: MouseEvent, sender: TChart): ChartControl | null {
        this.isHovered = false

        if (sender instanceof TOscChart) {
            return null
        }

        if (super.onMouseMove(event, sender) !== null) {
            const x = event.clientX
            const y = event.clientY

            if (this.symbolRect.PtInRect(new TPoint(x, y))) {
                this.isHovered = true
                return this
            }

            if ((this.timeFrameControl as ChangeSymbolButton).isPointInside(x, y)) {
                this.isHovered = true
                return this.timeFrameControl
            }
        } else {
            this.onMouseLeave(event)
        }
        return null
    }

    public onMouseLeave(event: MouseEvent): ChartControl | null {
        this.isHovered = false
        this.timeFrameControl.onMouseLeave(event)
        return null
    }
}
