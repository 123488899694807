import { FormattingConfig } from '../components/InputOption/types'

export const symbolsRowsConfig: { [key: string]: FormattingConfig } = {
    Lot: {
        min: 0.001,
        max: 100000,
        step: 0.001,
        decimals: 3
    },
    Spread: {
        min: 1,
        max: 10000,
        step: 1,
        isInteger: true
    },
    Decimals: {
        min: 0,
        max: 10,
        step: 1,
        isInteger: true
    },
    MinDistToPrice: {
        min: 0,
        max: 1000,
        step: 1,
        isInteger: true
    },
    SwapLong: {
        min: -20,
        max: 20,
        step: 0.0001,
        decimals: 4
    },
    SwapShort: {
        min: -20,
        max: 20,
        step: 0.0001,
        decimals: 4
    }
}
