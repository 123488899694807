import { TRect } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export class LocationParams {
    private x = 0
    private y = 0
    private width = 0
    private height = 0

    constructor(x: number, y: number, width: number, height: number) {
        this.x = x
        this.y = y
        this.width = width
        this.height = height
    }

    getLocation(): TRect {
        return new TRect(this.x, this.y, this.x + this.width, this.y + this.height)
    }

    setLocation(location: TRect): void {
        this.x = location.Left
        this.y = location.Top
        this.width = location.Right - location.Left
        this.height = location.Bottom - location.Top
    }

    clone(): LocationParams {
        return new LocationParams(this.x, this.y, this.width, this.height)
    }
}
