import { FC, useMemo, memo, useState, useCallback, useEffect } from 'react'
import { t } from 'i18next'
import cx from 'classnames'

import { ALL_COUNTRIES_WITH_EU } from '@root/constants/countries'

import { Flex, Typography, Tooltip } from '@fto/ui'
import { FilenameTypes, Icon, IconButton } from '@fto/icons'
import { News } from '@fto/lib/News/News'

import {
    generateDateLabel,
    getColorForNewsValues,
    getColorForPriority,
    getTimeAndDateFromUnix,
    getTimeTillUpcomingNew,
    EMPTY_STATE
} from '@fto/chart_components/ProjectInterface/components/ContextMenus/NewsContextMenu/helpers/newsHelpers'
import { highlightSearchText } from '@root/utils/highlightSearchText'

import styles from './index.module.scss'

type Props = {
    country: News['Country']
    actual: News['Actual']
    previews: News['Previews']
    priority: News['Priority']
    consensus: News['Consensus']
    title: News['Title']
    time: News['Time']
    searchValue: string
    isUpcoming?: boolean
    lastBarTime: number | null
    jumpTo?: () => void | null
}

const NewItem: FC<Props> = memo(
    ({
        country,
        actual,
        previews,
        priority,
        title,
        time,
        searchValue,
        consensus,
        isUpcoming = false,
        jumpTo,
        lastBarTime
    }) => {
        const [isSelected, setIsSelected] = useState(false)

        useEffect(() => {
            if (isSelected) {
                const timer = setTimeout(() => {
                    setIsSelected(false)
                }, 3000)

                return () => clearTimeout(timer)
            }
        }, [isSelected])

        const countryIconKey = useMemo(() => {
            return ALL_COUNTRIES_WITH_EU.find((item) => item.name === country)?.iconName || ''
        }, [country])

        const actualColor = useMemo(() => {
            return getColorForNewsValues(actual, previews)
        }, [actual, previews])

        const { time: timeValue, date } = useMemo(() => {
            return getTimeAndDateFromUnix(time)
        }, [time])

        const upcomingTimeParams = useMemo(() => {
            return isUpcoming ? getTimeTillUpcomingNew(lastBarTime || 0, time * 1000) : EMPTY_STATE
        }, [lastBarTime, time, isUpcoming])

        const upcomingTimeLabel = useMemo(() => {
            if (!isUpcoming) return ''

            const { days, hours, minutes, seconds } = upcomingTimeParams

            if (!days?.numerical && !hours?.numerical && !minutes?.numerical && !seconds?.numerical) {
                return ''
            }

            return `- ${generateDateLabel(days?.label, t('news.timeLabel.d'))} ${generateDateLabel(
                hours?.label,
                t('news.timeLabel.h')
            )} ${generateDateLabel(minutes?.label, t('news.timeLabel.m'))} ${generateDateLabel(
                seconds?.label,
                t('news.timeLabel.s')
            )}`
        }, [upcomingTimeParams, isUpcoming])

        const isNewSuperSoon = useMemo(() => {
            if (!isUpcoming) {
                return false
            }

            const { days, hours, minutes, seconds } = upcomingTimeParams

            return (
                (!days?.numerical && !hours?.numerical && (minutes?.numerical > 0 || seconds?.numerical > 0)) ||
                (!days.numerical && hours.numerical === 1 && !minutes?.numerical && !seconds?.numerical)
            )
        }, [isUpcoming, upcomingTimeParams])

        const handleJumpTo = useCallback(() => {
            setIsSelected(true)
            jumpTo?.()
        }, [jumpTo])

        const highlightedNewsTitle = useMemo(() => {
            return highlightSearchText(title, searchValue, 700)
        }, [title, searchValue])

        return (
            <Flex
                direction='column'
                gap={8}
                className={cx(styles.NewItem, { [styles.Selected]: isSelected })}
                onDoubleClick={handleJumpTo}
            >
                <Flex alignItems={isUpcoming ? 'flex-start' : 'center'} gap={4}>
                    <Flex direction='column' gap={4}>
                        <Typography type='subtext-semibold' color='gray-1000' className={styles.Time}>
                            {timeValue}
                        </Typography>
                        <Flex gap={4} alignItems='center'>
                            <Icon name={countryIconKey as (typeof FilenameTypes)[number]} size={18} />
                            <Icon name='importance' color={getColorForPriority(priority)} size={16} />
                        </Flex>
                    </Flex>
                    <Flex justifyContent='space-between' gap={8}></Flex>
                    <Flex direction='column' gap={4}>
                        {isUpcoming && upcomingTimeLabel && (
                            <div>
                                <Typography
                                    type='subtext-regular'
                                    color='gray-1000'
                                    className={cx(styles.UpcomingTime, { [styles.SuperSoon]: isNewSuperSoon })}
                                >
                                    {upcomingTimeLabel}
                                </Typography>
                            </div>
                        )}
                        <Flex alignItems='center' gap={4} flexGrow={1}>
                            <Typography
                                title={title}
                                truncate
                                type='text-semibold'
                                color='gray-1000'
                                block
                                className={styles.Title}
                            >
                                {highlightedNewsTitle}
                            </Typography>
                        </Flex>
                        <Typography type='subtext-regular' color='gray-800'>
                            {date}
                        </Typography>
                    </Flex>

                    <Flex gap={4} alignItems='flex-start' className={styles.Controls} justifyContent='flex-end'>
                        {/*<Tooltip content={t('news.tooltips.previousSimilarNews')} placement='top'>*/}
                        {/*    <IconButton name='arrow-steps' size={18} />*/}
                        {/*</Tooltip>*/}

                        {/*<Tooltip content={t('news.tooltips.upcomingSimilarNews')} placement='top'>*/}
                        {/*    <IconButton className={styles.ReverseArrow} name='arrow-steps' size={18} />*/}
                        {/*</Tooltip>*/}
                        <Tooltip content={t('news.tooltips.findNewsOnChart')} placement='top'>
                            <IconButton name='search' size={18} onClick={handleJumpTo} />
                        </Tooltip>
                    </Flex>
                </Flex>
                <div className={styles.GridWrapper}>
                    <Flex alignItems='center' gap={4}>
                        <Typography type='interface-regular'>{t('news.actual')}</Typography>
                        <Typography type='interface-semibold' color={isUpcoming ? 'gray-800' : actualColor}>
                            {isUpcoming ? '-' : actual}
                        </Typography>
                    </Flex>
                    <Flex alignItems='center' gap={4}>
                        <Typography type='interface-regular'>{t('news.forecast')}</Typography>
                        <Typography type='interface-regular'>{consensus}</Typography>
                    </Flex>
                    <Flex alignItems='center' gap={4}>
                        <Typography type='interface-regular'>{t('news.previous')}</Typography>
                        <Typography type='interface-regular'>{previews}</Typography>
                    </Flex>
                </div>
            </Flex>
        )
    }
)

export default NewItem
