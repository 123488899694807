import ColorSetting from '../options/ColorSetting'
import CheckboxAndColorSetting from '../options/CheckboxAndColorSetting'
import SliderSettings from '../options/SliderSetting'
import CheckboxSettings from '../options/CheckboxSetting'
import DropdownSettings from '../options/DropdownSetting'
import CheckboxAndDropdownSetting from '../options/CheckboxAndDropdownSetting'
import RadioSetting from '../options/RadioSetting'
import { useEffect, useState } from 'react'

import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'

import { AvailableOptionsSettings, Option, OptionSettingRef } from '../types/options.types'
import { getBooleanValue } from '.'

import {
    isColorSettingOption,
    isCheckboxSettingOption,
    isCheckboxAndColorSettingOption,
    isSliderSettingOption,
    isInputSettingOption,
    isDropdownSettingOption,
    isCheckboxAndDropdownSettingOption,
    isRadioSettingOption
} from './OptionStates'

import OptionContainer from '../OptionContainer'
import InputSetting from '@root/pages/ChartPage/components/SettingsTab/components/options/InputSetting'

function getParentDisableState(option: Option, chart: TChartWindow) {
    if (isCheckboxSettingOption(option) || isCheckboxAndColorSettingOption(option)) {
        return !getBooleanValue(chart, option.booleanConfigType, option.booleanKey)
    }

    return false
}

type OptionSettingByConfigProp = {
    tab: string
    chart: TChartWindow
    option: AvailableOptionsSettings
    applyToAll: boolean
    onDifferentSettingsUpdate?: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export const getOptionSettingByConfig = (
    { tab, chart, option, applyToAll, onDifferentSettingsUpdate }: OptionSettingByConfigProp,
    addRef?: (ref: OptionSettingRef) => void,
    level = 0,
    shouldBeDisabled: boolean = false
): React.ReactNode => {
    const { subOptions = null, ...mainOption } = option

    const elements = []
    const elementsKeys = []

    const [childDisabledState, setChildDisabledState] = useState<boolean>(
        shouldBeDisabled || getParentDisableState(mainOption, chart)
    )

    useEffect(() => {
        setChildDisabledState(shouldBeDisabled || getParentDisableState(mainOption, chart))
    }, [shouldBeDisabled])

    elements.push(
        getSingleOptionSettingByConfig(
            {
                tab,
                chart,
                option: mainOption,
                applyToAll,
                onDifferentSettingsUpdate
            },
            level,
            setChildDisabledState,
            shouldBeDisabled,
            addRef
        )
    )
    elementsKeys.push(mainOption.key)

    if (!subOptions) {
        return <>{elements}</>
    }

    for (let key in subOptions) {
        elements.push(
            getOptionSettingByConfig(
                {
                    tab,
                    chart,
                    option: subOptions[key],
                    applyToAll,
                    onDifferentSettingsUpdate
                },
                addRef,
                subOptions[key].ignoreSuboptionLevel ? level : level + 1,
                childDisabledState
            )
        )
        elementsKeys.push(subOptions[key].key)
    }

    return <>{elements}</>
}

export const getSingleOptionSettingByConfig = (
    { tab, chart, option, applyToAll, onDifferentSettingsUpdate }: OptionSettingByConfigProp,
    level = 0,
    setChildsDisabled: (val: boolean) => void,
    shouldBeDisabled: boolean,
    addRef?: (ref: OptionSettingRef) => void
) => {
    if (isColorSettingOption(option)) {
        return (
            <OptionContainer level={level} key={option.key + '_' + level}>
                <ColorSetting
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    ref={addRef}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    if (isCheckboxSettingOption(option)) {
        return (
            <OptionContainer level={level} key={option.key + '_' + level}>
                <CheckboxSettings
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    ref={addRef}
                    setChildsDisabled={setChildsDisabled}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    if (isCheckboxAndColorSettingOption(option)) {
        return (
            <OptionContainer level={level} key={option.key + '_' + level}>
                <CheckboxAndColorSetting
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    ref={addRef}
                    setChildsDisabled={setChildsDisabled}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    if (isSliderSettingOption(option)) {
        return (
            <OptionContainer level={level} key={option.key + '_' + level}>
                <SliderSettings
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    ref={addRef}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    if (isInputSettingOption(option)) {
        return (
            <OptionContainer level={level} key={option.key + '_' + level}>
                <InputSetting
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    ref={addRef}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    if (isDropdownSettingOption(option)) {
        return (
            <OptionContainer level={level} key={option.key + '_' + level}>
                <DropdownSettings
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    ref={addRef}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    if (isCheckboxAndDropdownSettingOption(option)) {
        return (
            <OptionContainer level={level} key={option.key + '_' + level}>
                <CheckboxAndDropdownSetting
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    ref={addRef}
                    setChildsDisabled={setChildsDisabled}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    if (isRadioSettingOption(option)) {
        return (
            <OptionContainer level={level} key={option.key + '_' + level}>
                <RadioSetting
                    tab={tab}
                    chart={chart}
                    option={option}
                    applyToAll={applyToAll}
                    onDifferentSettingsUpdate={onDifferentSettingsUpdate}
                    ref={addRef}
                    shouldBeDisabled={shouldBeDisabled}
                />
            </OptionContainer>
        )
    }

    throw new StrangeError('Unknown option type')
}

export default getOptionSettingByConfig
