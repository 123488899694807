import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { NotImplementedError } from '@fto/lib/utils/common_utils'
import { IAccountInfoProcRec } from '../CommonProcRecInterfaces/IAccountInfoProcRec'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'
import {
    ENUM_ACCOUNT_INFO_DOUBLE,
    ENUM_ACCOUNT_INFO_INTEGER,
    ENUM_ACCOUNT_INFO_STRING
} from '@fto/lib/extension_modules/common/CommonConstantsForExternalModules'
import CommonConstants from '@fto/lib/ft_types/common/CommonConstants'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'

export class AccountInfoProcRecImplementation extends BasicProcRecImplementation {
    public GetImplementation(): IAccountInfoProcRec {
        return {
            AccountBalance: this.AccountBalance.bind(this),
            AccountEquity: this.AccountEquity.bind(this),
            AccountMargin: this.AccountMargin.bind(this),
            AccountFreeMargin: this.AccountFreeMargin.bind(this),
            AccountLeverage: this.AccountLeverage.bind(this),
            AccountProfit: this.AccountProfit.bind(this),
            AccountInfoInteger: this.AccountInfoInteger.bind(this),
            AccountInfoDouble: this.AccountInfoDouble.bind(this),
            AccountInfoString: this.AccountInfoString.bind(this),
            AccountCompany: this.AccountCompany.bind(this),
            AccountCredit: this.AccountCredit.bind(this),
            AccountCurrency: this.AccountCurrency.bind(this),
            AccountFreeMarginCheck: this.AccountFreeMarginCheck.bind(this),
            AccountFreeMarginMode: this.AccountFreeMarginMode.bind(this),
            AccountName: this.AccountName.bind(this),
            AccountNumber: this.AccountNumber.bind(this),
            AccountServer: this.AccountServer.bind(this),
            AccountStopoutLevel: this.AccountStopoutLevel.bind(this),
            AccountStopoutMode: this.AccountStopoutMode.bind(this)
        }
    }

    protected override generateDName(): string {
        return `AccountInfoProcRec_${super.generateDName()}`
    }

    public AccountBalance(): number {
        return GlobalProcessingCore.ProcessingCore.Balance
    }

    public AccountEquity(): number {
        return GlobalProcessingCore.ProcessingCore.Equity
    }

    public AccountMargin(): number {
        return GlobalProcessingCore.ProcessingCore.Margin
    }

    public AccountFreeMargin(): number {
        return GlobalProcessingCore.ProcessingCore.FreeMargin
    }

    public AccountLeverage(): number {
        return GlobalProjectInfo.ProjectInfo.leverage
    }

    public AccountProfit(): number {
        return GlobalProcessingCore.ProcessingCore.Equity - GlobalProcessingCore.ProcessingCore.Deposit
    }

    public AccountInfoInteger(propertyId: number): number {
        switch (propertyId) {
            case ENUM_ACCOUNT_INFO_INTEGER.ACCOUNT_LOGIN: {
                throw new NotImplementedError('ACCOUNT_LOGIN is not implemented yet')
            }
            case ENUM_ACCOUNT_INFO_INTEGER.ACCOUNT_TRADE_MODE: {
                throw new NotImplementedError('ACCOUNT_TRADE_MODE is not implemented yet')
            }
            case ENUM_ACCOUNT_INFO_INTEGER.ACCOUNT_LEVERAGE: {
                return this.AccountLeverage()
            }
            case ENUM_ACCOUNT_INFO_INTEGER.ACCOUNT_LIMIT_ORDERS: {
                throw new NotImplementedError('ACCOUNT_LIMIT_ORDERS is not implemented yet')
            }
            case ENUM_ACCOUNT_INFO_INTEGER.ACCOUNT_MARGIN_SO_MODE: {
                throw new NotImplementedError('ACCOUNT_MARGIN_SO_MODE is not implemented yet')
            }
            case ENUM_ACCOUNT_INFO_INTEGER.ACCOUNT_TRADE_ALLOWED: {
                throw new NotImplementedError('ACCOUNT_TRADE_ALLOWED is not implemented yet')
            }
            case ENUM_ACCOUNT_INFO_INTEGER.ACCOUNT_TRADE_EXPERT: {
                throw new NotImplementedError('ACCOUNT_TRADE_EXPERT is not implemented yet')
            }
            default: {
                throw new Error('Invalid property ID')
            }
        }
    }

    // returns are left as examples
    public AccountInfoDouble(propertyId: number): number {
        switch (propertyId) {
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_BALANCE: {
                return this.AccountBalance()
            }
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_CREDIT: {
                throw new NotImplementedError('ACCOUNT_CREDIT is not implemented yet')
            }
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_PROFIT: {
                return this.AccountProfit()
            }
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_EQUITY: {
                return this.AccountEquity()
            }
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_MARGIN: {
                return this.AccountMargin()
            }
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_MARGIN_FREE: {
                return this.AccountFreeMargin()
            }
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_MARGIN_LEVEL: {
                throw new NotImplementedError('ACCOUNT_MARGIN_LEVEL is not implemented yet')
            }
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_MARGIN_SO_CALL: {
                throw new NotImplementedError('ACCOUNT_MARGIN_SO_CALL is not implemented yet')
            }
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_MARGIN_SO_SO: {
                throw new NotImplementedError('ACCOUNT_MARGIN_SO_SO is not implemented yet')
            }
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_MARGIN_INITIAL: {
                throw new NotImplementedError('ACCOUNT_MARGIN_INITIAL is not implemented yet')
            }
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_MARGIN_MAINTENANCE: {
                throw new NotImplementedError('ACCOUNT_MARGIN_MAINTENANCE is not implemented yet')
            }
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_ASSETS: {
                throw new NotImplementedError('ACCOUNT_ASSETS is not implemented yet')
            }
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_LIABILITIES: {
                throw new NotImplementedError('ACCOUNT_LIABILITIES is not implemented yet')
            }
            case ENUM_ACCOUNT_INFO_DOUBLE.ACCOUNT_COMMISSION_BLOCKED: {
                throw new NotImplementedError('ACCOUNT_COMMISSION_BLOCKED is not implemented yet')
            }
            default: {
                throw new Error('Invalid property ID')
            }
        }
    }

    // returns are left as examples
    public AccountInfoString(propertyId: number): string {
        switch (propertyId) {
            case ENUM_ACCOUNT_INFO_STRING.ACCOUNT_NAME: {
                return this.AccountName()
            }
            case ENUM_ACCOUNT_INFO_STRING.ACCOUNT_SERVER: {
                return this.AccountServer()
            }
            case ENUM_ACCOUNT_INFO_STRING.ACCOUNT_CURRENCY: {
                return this.AccountCurrency()
            }
            case ENUM_ACCOUNT_INFO_STRING.ACCOUNT_COMPANY: {
                return this.AccountCompany()
            }
            default: {
                throw new Error('Invalid property ID')
            }
        }
    }

    public AccountCompany(): string {
        return CommonConstants.DEFAULT_BROKER
    }

    public AccountCredit(): number {
        throw new NotImplementedError('AccountCredit is not implemented yet')
    }

    public AccountCurrency(): string {
        return 'USD'
    }

    public AccountFreeMarginCheck(symbol: string, operationId: number, volume: number): number {
        let marginNeeded: number | null = null
        const convertedOperationType = (operateId: number): TTradePositionType | null => {
            if (operateId === 0) {
                return TTradePositionType.tp_Buy
            } else if (operateId === 1) {
                return TTradePositionType.tp_Sell
            } else {
                return null // Return null if operateId is neither 0 nor 1
            }
        }
        const operationType = convertedOperationType(operationId)
        if (operationType === null) {
            throw new Error('Unsupported trade operation') // Unsupported operation
        }

        const targetSymbol = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol)

        if (targetSymbol) {
            // Calculate the margin needed for the specified operation and volume
            marginNeeded =
                targetSymbol.symbolInfo
                    .getSymbolCalculationStrategy()
                    .calculateMarginRequirementForSymbol(convertedOperationType(operationId), null) * volume

            // Check if there is enough free margin for the trade
            const freeMarginAfterTrade = GlobalProcessingCore.ProcessingCore.FreeMargin - marginNeeded
            if (freeMarginAfterTrade >= 0) {
                return freeMarginAfterTrade // Sufficient margin
            } else {
                throw new Error('Not enough money') // Not enough margin
            }
        } else {
            throw new Error('Symbol not found or is null')
        }
    }

    public AccountFreeMarginMode(): number {
        throw new NotImplementedError('AccountFreeMarginMode is not implemented yet')
    }

    public AccountName(): string {
        const projectName = GlobalProjectJSONAdapter.Instance.projectName
        if (projectName) {
            return projectName
        }
        throw new Error('Project name is null')
    }

    public AccountNumber(): number {
        throw new NotImplementedError('AccountNumber is not implemented yet')
    }

    public AccountServer(): string {
        throw new NotImplementedError('AccountServer is not implemented yet')
    }

    public AccountStopoutLevel(): number {
        throw new NotImplementedError('AccountStopoutLevel is not implemented yet')
    }

    public AccountStopoutMode(): number {
        throw new NotImplementedError('AccountStopoutMode is not implemented yet')
    }
}
