// IndicatorTitles.ts based on Indicator.FileName

export const IndicatorTitles: Record<string, string> = Object.freeze({
    "SMA": "SMA",
    "EMA": "EMA",
    "WMA": "WMA",
    "SSMA": "SSMA",
    "BollingerBands": "BB",
    "Ichimoku": "Ichimoku",
    "Inside_Bar": "Inside Bar",
    "Volume": "Volume",
    "OBV": "OBV",
    "MACD": "MACD",
    "Stochastic": "Stochastic",
    "ATR": "ATR",
    "ADX": "ADX",
    "DeMarker": "DeMarker",
    "CCI": "CCI",
    "Momentum": "Momentum",
    "RSI": "RSI",
    "WPR": "Williams %R",
    "MFI": "MFI",
    "iSession": "Sessions",
    "Fair_Value_Gap": "FVG",
    "Alligator": "Alligator",
    "BollingerBandsRatio": "BB Ratio",
    "Accelerator_Osc": "AC",
    "AwersomeOsc": "AO",
    "ZigZag": "ZigZag",
    "Heikin_Ashi": "Heikin Ashi",
    "Pivot_Points": "Pivot Points",
    "Parabolic_SAR": "Parabolic SAR",
    "Aroon": "Aroon",
} as const);
