import { SymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/SymbolCalculationStrategy'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'

export class USDQouteSymbolCalculationStrategy extends SymbolCalculationStrategy {
    public getPointCostForOneStandardLot(
        operationType: TTradePositionType,
        openPrice: number | null,
        lotSize?: number
    ): number {
        return this.getSymbolContractSize(lotSize) * this.symbolData.symbolInfo.MinPoint
    }

    public calculateMarginRequirementForSymbol(
        operationType: TTradePositionType,
        openPrice: number | null,
        lotSize?: number
    ): number {
        const priceForMarginCalculation = openPrice || this.symbolData.getCurrentOpenPriceByOrderType(operationType)

        return (
            (priceForMarginCalculation / GlobalProjectInfo.ProjectInfo.leverage) * this.getSymbolContractSize(lotSize)
        )
    }
}
