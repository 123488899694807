import { FC, useCallback } from 'react'

import styles from './index.module.scss'
import { Flex, Typography } from '@fto/ui'
import { useTranslation } from 'react-i18next'
import { AvailableOptionsSettings } from '../../components/types/options.types'
import getOptionSettingByConfig from '../../components/utils/getOptionSettingByConfig'
import globalChartsStore from '@fto/lib/store/globalChartsStore'
import OptionsConfig from './config'
import useChangesObserver from '../../hooks/useChangesObserver'

interface Props {}

const GeneralSettingsTab: FC<Props> = () => {
    const { t } = useTranslation()

    const { data } = globalChartsStore
    const chart = data.activeChart
    if (!chart) return <></>

    const { onDifferentSettingsUpdate } = useChangesObserver(chart, {
        mixPanelEventName: 'global_settings_updated',
        optionsConfig: OptionsConfig
    })

    const optionSettingByKey = useCallback(
        (option: AvailableOptionsSettings) => {
            return getOptionSettingByConfig({
                tab: 'general',
                chart,
                option: option,
                applyToAll: true,
                onDifferentSettingsUpdate
            })
        },
        [chart, onDifferentSettingsUpdate]
    )

    return (
        <Flex className={styles.scrollableContainer}>
            <Flex className={styles.content} direction='column' gap={12} block>
                <Typography color='gray-600' type='tiny-regular'>
                    {t('settings.general.titles.bidAskPanel')}
                </Typography>

                {optionSettingByKey(OptionsConfig['ShowBidAskPanel'])}

                <Typography color='gray-600' type='tiny-regular'>
                    {t('settings.general.titles.orderPlacement')}
                </Typography>

                {optionSettingByKey(OptionsConfig['OrderPlacementMode'])}

                <Typography color='gray-600' type='tiny-regular'>
                    {t('settings.general.titles.orders')}
                </Typography>

                {optionSettingByKey(OptionsConfig['ShowCloseOrderMarker'])}
                {optionSettingByKey(OptionsConfig['ShowOpenOrderMarker'])}
                {optionSettingByKey(OptionsConfig['ScrollingSpeed'])}

                <Typography color='gray-600' type='tiny-regular'>
                    {t('settings.general.titles.mouse')}
                </Typography>

                {optionSettingByKey(OptionsConfig['MouseSensitivity'])}
                {optionSettingByKey(OptionsConfig['MagnetSensitivity'])}
                {optionSettingByKey(OptionsConfig['MagnetCrossHair'])}

                <Typography color='gray-600' type='tiny-regular'>
                    {t('settings.general.titles.scale')}
                </Typography>

                {optionSettingByKey(OptionsConfig['LockTo'])}
                {optionSettingByKey(OptionsConfig['MultichartLockTo'])}
                {optionSettingByKey(OptionsConfig['AxisFontSize'])}
            </Flex>
        </Flex>
    )
}

export default GeneralSettingsTab
