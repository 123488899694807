import { FilenameTypes } from '@fto/icons'
export type AVAILABLE_TABS = 'all' | 'general' | 'project' | 'chart' | 'leverage' | 'symbol' | 'commissions'

export type TabType = {
    titleKey: string
    icon?: (typeof FilenameTypes)[number]
    backTo?: AVAILABLE_TABS
    showInGlobalList: boolean
}

export const TABS_LIST: Record<AVAILABLE_TABS, TabType> = {
    all: {
        titleKey: 'global.settings',
        showInGlobalList: false
    },
    general: {
        titleKey: 'settings.general.settingsTitle',
        icon: 'settings',
        backTo: 'all',
        showInGlobalList: true
    },
    project: {
        titleKey: 'settings.project.settingsTitle',
        icon: 'settings-burger',
        backTo: 'all',
        showInGlobalList: true
    },
    chart: {
        titleKey: 'settings.chart.settingsTitle',
        icon: 'candle-thin',
        backTo: 'all',
        showInGlobalList: true
    },
    leverage: {
        titleKey: 'settings.leverage.settingsTitle',
        icon: 'leverage',
        backTo: 'all',
        showInGlobalList: true
    },
    // tick: {
    //     titleKey: 'settings.tick.settingsTitle',
    //     icon: 'tick-level',
    //     backTo: 'all',
    //     showInGlobalList: true
    // },
    symbol: {
        titleKey: 'settings.symbol.settingsTitle',
        icon: 'symbol-stack',
        backTo: 'all',
        showInGlobalList: true
    },
    commissions: {
        titleKey: 'settings.commissions.settingsTitle',
        icon: 'percent',
        backTo: 'all',
        showInGlobalList: true
    }
}
