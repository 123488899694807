import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { ChartControl } from '@fto/chart_components/ChartControl'
import { ChartControlParams } from './ChartControlParams'
import { IGPFont, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import CommonDataUtils from '@fto/lib/ft_types/data/DataUtils/CommonDataUtils'
import { TBarRecord } from '@fto/lib/ft_types/data/DataClasses/TBarRecord'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { DelphiMathCompatibility } from '@fto/lib/delphi_compatibility/DelphiMathCompatibility'
import { TTestingManager } from '@fto/lib/processing_core/TestingManager'
import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

export class DebugLabelControl extends ChartControl {
    private barData: TBarRecord | null
    private chartWin: TChartWindow

    private font = new IGPFont(new TGPFontFamily('Roboto Flex'), 12, [])
    private colors = {
        key: new IGPSolidBrush('#8B0000'),
        value: new IGPSolidBrush('#FF6347'),
        background: new IGPSolidBrush('#ffffff', 0.85)
    }
    private valuesWidthByContext: Map<number, number> = new Map<number, number>()
    private gapAfterKey = 0
    private gapAfterValue = 3
    private debug = false

    constructor(chartControlParams: ChartControlParams, barData: TBarRecord | null, chartWin: TChartWindow) {
        super(chartControlParams)
        this.barData = barData
        this.chartWin = chartWin
    }

    public setBarInfo(barData: TBarRecord): void {
        if (CommonDataUtils.isBarEmpty(barData)) {
            this.barData = null
            return
        }
        this.barData = barData
    }

    private calculateTextWidths(canvas: TGdiPlusCanvas, text: string): number {
        return canvas.TextWidth(text, this.font)
    }

    public draw(canvas: TGdiPlusCanvas): void {
        // volume 999,9B
        if (!this.IsVisible() || !this.barData) {
            return
        }
        const rect = this.getLocation()
        canvas.graphics.Context.clearRect(rect.Left, rect.Top, canvas.graphics.Context.canvas.width, rect.Height)

        // const left: number = rect.Left + 5
        const top: number = rect.Top + 17

        // const horizScrollAnchorDate = this.chartWin.getHorizScrollAnchorDate().date
        // const barIndexUnderMouse = this.chartWin.MainChart.GetBarIndexUnderMouse()

        const texts: {
            text: string
            textTemplate: string
            color: IGPSolidBrush
            left: number
            gap: number
            width?: number
            oneChar?: number
        }[] = [
            // {
            //     text: 'actual testing speed:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: GlobalTestingManager.TestingManager.__debug_ActualTestingSpeed.toString(),
            //     textTemplate: '2020-00-00 00:00:00:000',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // {
            //     text: 'captured chart:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: GlobalChartsController.Instance.capturedChart?.DName || 'null',
            //     textTemplate: '',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // { text: 'First visible bar:', textTemplate: '', color: this.colors.key, left: 0, gap: this.gapAfterKey },
            // {
            //     text: DateUtils.DF(this.barData.DateTime),
            //     textTemplate: '2020-00-00 00:00:00',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // {
            //     text: 'paint rect right:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: this.chartWin.MainChart.GetPaintRect().Right.toString(),
            //     textTemplate: '',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // {
            //     text: 'x under mouse:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: this.chartWin.MainChart.GetCurrentMousePositionInLocalCoordinates().toString(),
            //     textTemplate: '',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // {
            //     text: 'bar date from x:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: DateUtils.DF(this.chartWin.GetBarDateUnderMouse()),
            //     textTemplate: '',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // {
            //     text: 'number of vis bars:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: this.chartWin.MainChart.NumberOfVisibleBars().toString(),
            //     textTemplate: '2020-00-00 00:00:00:000',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // {
            //     text: 'precise date under mouse:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: DateUtils.DF(this.chartWin.MainChart.getPreciseDateUnderMouse()),
            //     textTemplate: '2020-00-00 00:00:00:000',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // {
            //     text: 'GetCurrentMousePositionInLocalCoordinates:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: this.chartWin.MainChart.GetCurrentMousePositionInLocalCoordinates().toString(),
            //     textTemplate: '',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // { text: 'first visible date:', textTemplate: '', color: this.colors.key, left: 0, gap: this.gapAfterKey },
            // {
            //     text: DateUtils.DF(this.chartWin.MainChart.GetPreciseVisibleDateRange().start),
            //     textTemplate: '',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // { text: 'last visible date:', textTemplate: '', color: this.colors.key, left: 0, gap: this.gapAfterKey },
            // {
            //     text: DateUtils.DF(this.chartWin.MainChart.GetPreciseVisibleDateRange().end),
            //     textTemplate: '',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // {
            //     text: 'horizScrollAnchorDate:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: DateUtils.DF(horizScrollAnchorDate),
            //     textTemplate: '',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },

            // {
            //     text: 'zoom:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: DelphiMathCompatibility.RoundTo(this.chartWin.ChartOptions.HorzMagnifier, -2).toString(),
            //     textTemplate: '',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            {
                text: 'position:',
                textTemplate: '',
                color: this.colors.key,
                left: 0,
                gap: this.gapAfterKey
            },
            {
                text: this.chartWin.MainChart.position.toString(),
                textTemplate: '',
                color: this.colors.value,
                left: 0,
                gap: this.gapAfterValue
            }
            // {
            //     text: 'x offset:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: this.chartWin.MainChart.left_X_offset.toString(),
            //     textTemplate: '',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // {
            //     text: 'bar index under mouse:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: barIndexUnderMouse?.toString() || 'null',
            //     textTemplate: '',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // {
            //     text: 'last bar in testing index:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: this.chartWin.Bars.LastItemInTestingIndex.toString(),
            //     textTemplate: '',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // },
            // {
            //     text: 'last item in testing date:',
            //     textTemplate: '',
            //     color: this.colors.key,
            //     left: 0,
            //     gap: this.gapAfterKey
            // },
            // {
            //     text: DateUtils.DF(this.chartWin.Bars.LastItemInTesting.DateTime).toString(),
            //     textTemplate: '',
            //     color: this.colors.value,
            //     left: 0,
            //     gap: this.gapAfterValue
            // }
        ]
        let barInfoWidth = 9
        let currentWidth: number | undefined = 0

        // Calculate text widths
        for (const text of texts) {
            text.left = barInfoWidth
            currentWidth = this.valuesWidthByContext.get(text.text.length)

            if (!currentWidth) {
                if (text.textTemplate) {
                    currentWidth = this.calculateTextWidths(canvas, text.textTemplate) + text.gap
                } else {
                    currentWidth = this.calculateTextWidths(canvas, text.text.replace(/./g, '0')) + text.gap
                    this.valuesWidthByContext.set(text.text.length, currentWidth)
                }
            }
            barInfoWidth += currentWidth + text.gap

            if (this.debug) {
                text.width = currentWidth
                text.oneChar = currentWidth / text.text.length
            }
        }

        rect.Right = barInfoWidth + rect.Left
        canvas.FillRectRounded(rect, this.colors.background, 5)
        this.setLocation(rect)

        // Draw texts
        for (const text of texts) {
            canvas.textOut(text.left, top, text.text, this.font, text.color)
        }
    }

    public onBrowserWndSizing(): void {
        this.valuesWidthByContext.clear()
    }
}
