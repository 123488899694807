import { DelphiColors } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { TLevelsList } from '@fto/lib/drawing_interface/GraphicObjects/TLevelsList'
import { TLevelData } from '@fto/lib/drawing_interface/GraphicObjects/TLevelData'
import { LevelActiveType } from '@fto/lib/drawing_interface/GraphicObjects/LevelActiveType'
import { TLineStyle } from '@fto/lib/drawing_interface/VCLCanvas/TLineStyle'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'
import { TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export function calcLevels(fibo: number[], defaultColors: string[] | null = null, decimals = 3, precision = 5): string {
    const levels = new TLevelsList()
    let lineStyle = new TLineStyle(ColorHelperFunctions.MakeColor(DelphiColors.clGreen), TPenStyle.psSolid, 1)
    for (const [i, levelValue] of fibo.entries()) {
        if (defaultColors) {
            lineStyle = new TLineStyle(defaultColors[i], TPenStyle.psSolid, 1)
        }
        const levelText = StrsConv.StrDouble(levelValue, decimals)
        const levelData = TLevelData.Create_TLevelData_Style(levelValue, levelText, lineStyle, LevelActiveType.active)
        levels.Add(levelData)
    }
    return levels.ExportToStr(precision)
}
