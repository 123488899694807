import { SymbolCalculationStrategy } from '@fto/lib/processing_core/SymbolsCalculationStrategies/SymbolCalculationStrategy'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'

export class USDBaseSymbolCalculationStrategy extends SymbolCalculationStrategy {
    public getPointCostForOneStandardLot(
        operationType: TTradePositionType,
        price: number | null,
        lotSize?: number
    ): number {
        const closePrice = price || this.symbolData.getCurrentOpenPriceByOrderType(operationType)

        return (1 / closePrice) * this.getSymbolContractSize(lotSize) * this.symbolData.symbolInfo.MinPoint
    }

    public calculateMarginRequirementForSymbol(
        operationType: TTradePositionType,
        price: number | null,
        lotSize?: number
    ): number {
        return this.getSymbolContractSize(lotSize) / GlobalProjectInfo.ProjectInfo.leverage
    }
}
