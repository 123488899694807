import { TPngImage } from '../../delphi_compatibility/DelphiBasicTypes'

export class MarkerImagesManager {
    public static MarkerImgsLight: TPngImage[] = new Array(8)
    public static MarkerImgsDark: TPngImage[] = new Array(8)

    public static LoadImgs(): void {
        for (let i = 0; i <= 7; i++) {
            MarkerImagesManager.MarkerImgsLight[i] = new TPngImage(
                MarkerImagesManager.GetToolImageUrl(`light/MarkerImg_${(i + 1).toString().padStart(2, '0')}.png`)
            )
        }

        for (let i = 0; i <= 7; i++) {
            MarkerImagesManager.MarkerImgsDark[i] = new TPngImage(
                MarkerImagesManager.GetToolImageUrl(`dark/MarkerImg_${(i + 1).toString().padStart(2, '0')}.png`)
            )
        }
    }

    public static GetToolImageUrl(imageName: string): string {
        //FIXME: Change for real url
        return window.location.origin + '/icons/charting/paint_tools_icons/' + imageName
    }

    // Call LoadImgs statically
    private static _init = (() => {
        if (typeof window !== 'undefined') {
            MarkerImagesManager.LoadImgs()
        }
    })()
}
