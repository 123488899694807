import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TLineStyleRec } from '@fto/lib/drawing_interface/GraphicObjects/TLineStyleRec'
import { APIConstants } from './CommonConstantsForExternalModules'

export type TDateTime = number
export type TColor = string

export enum TObjectType {
    obj_AnyObject,
    obj_VLine,
    obj_HLine,
    obj_TrendLine,
    obj_Ray,
    obj_Polyline,
    obj_FiboRetracement,
    obj_FiboTimeZones,
    obj_FiboArc,
    obj_FiboFan,
    obj_AndrewsPitchfork,
    obj_Text,
    obj_TextLabel,
    obj_Rectangle,
    obj_Ellipse,
    obj_Triangle,
    obj_FiboChannel,
    obj_LRChannel,
    obj_FiboExtension,
    obj_GannBox
}

export enum TimeValue {
    '00:00' = '00:00',
    '00:15' = '00:15',
    '00:30' = '00:30',
    '00:45' = '00:45',
    '01:00' = '01:00',
    '01:15' = '01:15',
    '01:30' = '01:30',
    '01:45' = '01:45',
    '02:00' = '02:00',
    '02:15' = '02:15',
    '02:30' = '02:30',
    '02:45' = '02:45',
    '03:00' = '03:00',
    '03:15' = '03:15',
    '03:30' = '03:30',
    '03:45' = '03:45',
    '04:00' = '04:00',
    '04:15' = '04:15',
    '04:30' = '04:30',
    '04:45' = '04:45',
    '05:00' = '05:00',
    '05:15' = '05:15',
    '05:30' = '05:30',
    '05:45' = '05:45',
    '06:00' = '06:00',
    '06:15' = '06:15',
    '06:30' = '06:30',
    '06:45' = '06:45',
    '07:00' = '07:00',
    '07:15' = '07:15',
    '07:30' = '07:30',
    '07:45' = '07:45',
    '08:00' = '08:00',
    '08:15' = '08:15',
    '08:30' = '08:30',
    '08:45' = '08:45',
    '09:00' = '09:00',
    '09:15' = '09:15',
    '09:30' = '09:30',
    '09:45' = '09:45',
    '10:00' = '10:00',
    '10:15' = '10:15',
    '10:30' = '10:30',
    '10:45' = '10:45',
    '11:00' = '11:00',
    '11:15' = '11:15',
    '11:30' = '11:30',
    '11:45' = '11:45',
    '12:00' = '12:00',
    '12:15' = '12:15',
    '12:30' = '12:30',
    '12:45' = '12:45',
    '13:00' = '13:00',
    '13:15' = '13:15',
    '13:30' = '13:30',
    '13:45' = '13:45',
    '14:00' = '14:00',
    '14:15' = '14:15',
    '14:30' = '14:30',
    '14:45' = '14:45',
    '15:00' = '15:00',
    '15:15' = '15:15',
    '15:30' = '15:30',
    '15:45' = '15:45',
    '16:00' = '16:00',
    '16:15' = '16:15',
    '16:30' = '16:30',
    '16:45' = '16:45',
    '17:00' = '17:00',
    '17:15' = '17:15',
    '17:30' = '17:30',
    '17:45' = '17:45',
    '18:00' = '18:00',
    '18:15' = '18:15',
    '18:30' = '18:30',
    '18:45' = '18:45',
    '19:00' = '19:00',
    '19:15' = '19:15',
    '19:30' = '19:30',
    '19:45' = '19:45',
    '20:00' = '20:00',
    '20:15' = '20:15',
    '20:30' = '20:30',
    '20:45' = '20:45',
    '21:00' = '21:00',
    '21:15' = '21:15',
    '21:30' = '21:30',
    '21:45' = '21:45',
    '22:00' = '22:00',
    '22:15' = '22:15',
    '22:30' = '22:30',
    '22:45' = '22:45',
    '23:00' = '23:00',
    '23:15' = '23:15',
    '23:30' = '23:30',
    '23:45' = '23:45',
    '23:59' = '23:59'
}

export enum TOptionType {
    ot_Longword = 0,
    ot_Integer = 1,
    ot_double = 2,
    ot_String = 3,
    ot_Boolean = 4,
    ot_EnumType = 5,
    ot_Timeframe = 6,
    ot_Currency = 7,
    ot_LineStyle = 8,
    ot_Separator = 9,
    ot_Indicator = 10,
    ot_Color = 11,
    ot_DateTime = 12,
    at_Levels = 13,
    ot_Session = 14,
    ot_SessionsArray = 15
}

export enum E_MAType {
    ma_SMA = APIConstants.MODE_SMA,
    ma_EMA = APIConstants.MODE_EMA,
    ma_SMMA = APIConstants.MODE_SMMA,
    ma_LWMA = APIConstants.MODE_LWMA
}

export enum EErrorHandlingMode {
    ehm_FallSilentlyAndRememberLastError,
    ehm_ThrowError
}

export class TOptValue {}

export class TOptValue_empty extends TOptValue {
    constructor() {
        super()
    }
}

export class TOptValue_str extends TOptValue {
    value: string

    constructor(value: string) {
        super()
        this.value = value
    }
}

export class TOptValue_number extends TOptValue {
    protected fValue: number

    constructor(value: number) {
        super()
        this.fValue = value
    }

    public get value(): number {
        return this.fValue
    }

    public set value(value: number) {
        if (isNaN(value)) {
            throw new StrangeError(`Invalid number value: ${value}`)
        }
        this.fValue = value
    }
}

export class TOptValue_num_enum extends TOptValue_number {
    private enumType: { [key: number]: string }

    constructor(enumType: { [key: number]: string }, defaultValue: number | string) {
        if (typeof defaultValue === 'string') {
            if (!Object.values(enumType).includes(defaultValue)) {
                throw new Error(`Invalid default value for enum: ${defaultValue}`)
            }
            super(Object.values(enumType).indexOf(defaultValue))
        } else {
            super(defaultValue)
        }

        this.enumType = enumType
    }

    public set value(value: number) {
        if (!this.isValidEnumValue(value)) {
            throw new Error(`Invalid enum value: ${value}`)
        }
        this.fValue = value
    }

    protected isValidEnumValue(value: number): boolean {
        const enumValues = Object.values(this.enumType)
        return enumValues.includes(value.toString()) || enumValues.includes(value as any)
    }
}

export class TOptValue_MA_METHOD extends TOptValue_num_enum {
    constructor(defaultValue: number | string) {
        super(
            {
                [E_MAType.ma_SMA]: 'SMA',
                [E_MAType.ma_EMA]: 'EMA',
                [E_MAType.ma_SMMA]: 'SSMA',
                [E_MAType.ma_LWMA]: 'WMA'
            },
            defaultValue
        )
    }
}

export class TOptValue_PRICE_METHOD extends TOptValue_num_enum {
    constructor(defaultValue: number | string) {
        super(
            {
                [TPriceType.pt_Close]: 'Close',
                [TPriceType.pt_Open]: 'Open',
                [TPriceType.pt_High]: 'High',
                [TPriceType.pt_Low]: 'Low',
                [TPriceType.pt_HL2]: 'HL2',
                [TPriceType.pt_HLC3]: 'HLC3',
                [TPriceType.pt_HLCC4]: 'HLCC4'
            },
            defaultValue
        )
    }
}

export class TOptValue_num_enum_typed<TEnum> extends TOptValue_num_enum {
    constructor(enumType: { [key: number]: string }, defaultValue: number | string) {
        super(enumType, defaultValue)
    }

    public get enum_value(): TEnum {
        return this.fValue as TEnum
    }

    public set enum_value(value: TEnum) {
        if (!this.isValidEnumValue(value as unknown as number)) {
            throw new Error(`Invalid enum value: ${value}`)
        }
        this.fValue = value as unknown as number
    }
}

export class TOptValue_bool extends TOptValue {
    value: boolean

    constructor(value: boolean) {
        super()
        this.value = value
    }
}

//TOptValue_LineStyle
export class TOptValue_LineStyleRec extends TOptValue {
    value: TLineStyleRec

    constructor(value: TLineStyleRec) {
        super()
        this.value = value
    }
}

export class TOptValue_HotKey extends TOptValue {
    value: number

    constructor(value: number) {
        super()
        this.value = value
    }
}

export class TOptValue_DateTime extends TOptValue {
    value: TDateTime

    constructor(value: TDateTime) {
        super()
        this.value = value
    }
}

export enum TOutputWindow {
    ow_ChartWindow,
    ow_SeparateWindow
}

export enum MacdMode {
    MAIN,
    SIGNAL
}

export enum TPriceType {
    pt_Close = APIConstants.PRICE_CLOSE,
    pt_Open = APIConstants.PRICE_OPEN,
    pt_High = APIConstants.PRICE_HIGH,
    pt_Low = APIConstants.PRICE_LOW,
    pt_HL2 = APIConstants.PRICE_MEDIAN,
    pt_HLC3 = APIConstants.PRICE_TYPICAL,
    pt_HLCC4 = APIConstants.PRICE_WEIGHTED
}

export enum TValueType {
    vt_Open,
    vt_High,
    vt_Low,
    vt_Close,
    vt_Volume
}

export class TOptValue_SessionsArray extends TOptValue {
    sessions: TOptValue_Session[]

    constructor(sessions: TOptValue_Session[]) {
        super()
        this.sessions = sessions
    }

    addSession(session: TOptValue_Session): void {
        this.sessions.push(session)
    }

    getSessions(): TOptValue_Session[] {
        return this.sessions
    }

    toString(): string {
        return this.sessions.map((session) => session.toString()).join(';')
    }

    static copyFromString(str: string): TOptValue_SessionsArray {
        try {
            if (str.length === 0) {
                return new TOptValue_SessionsArray([])
            }
            const sessions = str.split(';').map((sessionStr) => TOptValue_Session.copyFromString(sessionStr))
            return new TOptValue_SessionsArray(sessions)
        } catch {
            throw new Error('Invalid string format')
        }
    }

    static updateFromString(str: string, opt: TOptValue_SessionsArray): void {
        try {
            if (str.length === 0) {
                opt.sessions = []
                return
            }
            const sessions = str.split(';').map((sessionStr) => TOptValue_Session.copyFromString(sessionStr))
            opt.sessions = sessions
        } catch {
            throw new Error('Invalid string format')
        }
    }
}

export class TOptValue_Session extends TOptValue {
    isEnabled: boolean
    name: string
    startTime: TimeValue
    endTime: TimeValue
    color: string

    constructor(isEnable: boolean, name: string, startTime: TimeValue, endTime: TimeValue, color: string) {
        super()
        this.isEnabled = isEnable
        this.name = name
        this.startTime = startTime
        this.endTime = endTime
        this.color = color
    }

    toString(): string {
        return `${this.isEnabled},${this.name},${this.startTime},${this.endTime},${this.color}`
    }

    static copyFromString(str: string): TOptValue_Session {
        const arr = str.split(',')
        if (arr.length !== 5) {
            throw new Error('Invalid string format')
        }
        if (!TimeValues.isValidTime(arr[2]) || !TimeValues.isValidTime(arr[3])) {
            throw new Error('Invalid time format')
        }

        return new TOptValue_Session(arr[0] === 'true', arr[1], arr[2] as TimeValue, arr[3] as TimeValue, arr[4])
    }

    static updateFromString(str: string, opt: TOptValue_Session): void {
        const arr = str.split(',')
        if (arr.length !== 5) {
            throw new Error('Invalid string format')
        }
        if (!TimeValues.isValidTime(arr[2]) || !TimeValues.isValidTime(arr[3])) {
            throw new Error('Invalid time format')
        }

        opt.isEnabled = arr[0] === 'true'
        opt.name = arr[1]
        opt.startTime = arr[2] as TimeValue
        opt.endTime = arr[3] as TimeValue
        opt.color = arr[4]
    }
}

export class TimeValues {
    public static readonly TIMES: { value: TimeValue; label: string; timeInSeconds: number }[] = []

    static {
        for (const key of Object.keys(TimeValue)) {
            const value = TimeValue[key as keyof typeof TimeValue]
            TimeValues.TIMES.push({
                value: value,
                label: value,
                timeInSeconds: TimeValues.timeToSeconds(value)
            })
        }
    }

    constructor() {}

    private static timeToSeconds(time: TimeValue): number {
        const [hours, minutes] = time.split(':').map(Number)
        return hours * 3600 + minutes * 60
    }

    public static isValidTime(value: string): value is TimeValue {
        return TimeValues.TIMES.some((time) => time.value === value)
    }

    public static getTimeInSeconds(value: TimeValue): number {
        const timeEntry = TimeValues.TIMES.find((entry) => entry.value === value)
        if (timeEntry) {
            return timeEntry.timeInSeconds
        } else {
            throw new StrangeError(`getTimeInSeconds - Invalid time value ${value}`)
        }
    }
}

export class CurrencyInfo {
    Symbol: string // currency name
    Digits: number // number of digits after '.'
    spread: number // spread in pips
    Point: number // minimal currency point
    lot: number // 1 lot cost
    curr: string // lot currency
    SwapLong: number // swap points long
    SwapShort: number // swap points short

    constructor(
        symbol: string,
        digits: number,
        spread: number,
        point: number,
        lot: number,
        curr: string,
        swapLong: number,
        swapShort: number
    ) {
        this.Symbol = symbol
        this.Digits = digits
        this.spread = spread
        this.Point = point
        this.lot = lot
        this.curr = curr
        this.SwapLong = swapLong
        this.SwapShort = swapShort
    }
}
