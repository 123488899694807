import IEventEmitter from '../../common/IEventEmitter'
import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import TEventsFunctionality from '../../utils/EventsFunctionality'
import CommonConstants from '../common/CommonConstants'
import NoExactMatchError from '../common/NoExactMatchError'
import { TChunkStatus, TNoExactMatchBehavior } from './chunks/ChunkEnums'
import { DateIsAfterChunkEnd, DateIsBeforeChunkStart } from './chunks/DateOutOfChunkBoundsErrors'
import { TBaseTickChunk } from './chunks/TickChunks/BaseTickChunk'
import { TFMTickArray } from './data_arrays/chunked_arrays/TicksArray'
import { TDataArrayEvents } from './data_downloading/DownloadRelatedEnums'
import DataNotDownloadedYetError from './data_errors/DataUnavailableError'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { DownloadController } from '@fto/lib/ft_types/data/data_downloading/DownloadController'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import { AfterEndOfHistoryError, BeforeStartOfHistoryError } from './data_errors/OutOfHistoryBoundsError'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import { ISeekable } from './ISeekable'
import { INamed } from '@fto/lib/utils/INamed'
import { TTickRecord } from './DataClasses/TTickRecord'
import CommonUtils from '../common/BasicClasses/CommonUtils'
import GlobalServerSymbolInfo from '@fto/lib/globals/GlobalServerSymbolInfo'
import { TDataDescriptor } from './data_arrays/DataDescriptionTypes'
import { ELoggingTopics } from '@fto/lib/utils/DebugEnums'
import { t } from 'i18next'

export enum Direction {
    NEXT = 'NEXT',
    PREV = 'PREV',
    CURRENT = 'CURRENT'
}

export class TTickData implements IEventEmitter, ISeekable, INamed {
    public fTicks: TFMTickArray
    private _positionNextTickDate: TDateTime
    private _lastProcessedTickTime: TDateTime = DateUtils.EmptyDate

    public get LastProcessedTickTime(): TDateTime {
        return this._lastProcessedTickTime
    }

    public set LastProcessedTickTime(value: TDateTime) {
        this._lastProcessedTickTime = value
    }

    private _currTickChunk: TBaseTickChunk | null = null
    private _prevTickChunk: TBaseTickChunk | null = null
    private _nextTickChunk: TBaseTickChunk | null = null

    private _nextChunksCount = 15
    private _prevChunksCount = 15
    private _loadedChunks: TBaseTickChunk[] = []

    private _startDayTickRecord: TTickRecord | null = null
    private _futureChunks: TBaseTickChunk[] = []
    private _pastChunks: TBaseTickChunk[] = []

    public Events = new TEventsFunctionality('TTickData')

    public IsSeeked = false
    private _seekedToDate: TDateTime | undefined = undefined
    private _loadingPriorityDirection: Direction = Direction.NEXT

    private _name: string

    public get DName(): string {
        return this._name
    }

    public toString(): string {
        return this.DName
    }

    private get DataDescriptor(): TDataDescriptor {
        return this.fTicks.DataDescriptor
    }

    private get SymbolName(): string {
        return this.DataDescriptor.symbolName
    }

    constructor(symbol: string, broker: string) {
        this.fTicks = new TFMTickArray(symbol, broker)
        this.fTicks.Events.on(TDataArrayEvents.de_ChunkLoaded, this.boundDownloadEventHandler)
        this._positionNextTickDate = DateUtils.EmptyDate
        this._name = `TickData_${symbol}_${broker}`
    }

    SetLoadingPriority(direction: Direction): void {
        this._loadingPriorityDirection = direction
    }

    public IsNextChunkPreloaded(): boolean {
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (
            this._nextTickChunk &&
            this._nextTickChunk.Status === TChunkStatus.cs_Loaded &&
            !this._nextTickChunk.IsEmptyOnServer()
        ) {
            return true
        }
        return false
    }

    isReadyToTick(): boolean {
        return (
            this.fTicks &&
            this.IsSeeked &&
            this._currTickChunk !== null &&
            this._currTickChunk.Status === TChunkStatus.cs_Loaded &&
            !this._currTickChunk.IsEmptyOnServer()
        )
    }

    public get VeryLastDateInHistory(): TDateTime {
        return GlobalServerSymbolInfo.Instance.getServerSymbolInfo(this.DataDescriptor.symbolName).EndDate
    }

    public get VeryFirstDateInHistory(): TDateTime {
        return GlobalServerSymbolInfo.Instance.getServerSymbolInfo(this.DataDescriptor.symbolName).StartDate
    }

    public isDateInAvailableRange(date: number): boolean {
        return this.fTicks.isDateInAvailableRange(date)
    }

    private updateLoadedChunkAndPreloadNext() {
        const lastLoadedTickChunk = DownloadController.Instance.getLastLoadedChunk()
        if (
            lastLoadedTickChunk &&
            lastLoadedTickChunk instanceof TBaseTickChunk &&
            lastLoadedTickChunk.DataDescriptor.symbolName === this.fTicks.DataDescriptor.symbolName
        ) {
            this._loadedChunks.push(lastLoadedTickChunk)

            if (
                this.isDateInAvailableRange(lastLoadedTickChunk.FirstDate) ||
                this.isDateInAvailableRange(lastLoadedTickChunk.LastPossibleDate)
            ) {
                this.makeSureNextChunkIsPreloaded()
            }
        }
    }

    private makeSureNextChunkIsPreloaded(): void {
        if (this._nextTickChunk && !this.isDateInAvailableRange(this._nextTickChunk.FirstDate)) {
            return
        }

        if (this._nextTickChunk && this._nextTickChunk.Status === TChunkStatus.cs_Loaded) {
            if (this._nextTickChunk.IsEmptyOnServer()) {
                // this.nextChunkPreloaded = false
                const nextChunk = this.getNeigbourChunkByTime(this._nextTickChunk.FirstDate, Direction.NEXT, false)
                if (
                    nextChunk &&
                    (this.isDateInAvailableRange(nextChunk.FirstDate) ||
                        this.isDateInAvailableRange(nextChunk.LastPossibleDate))
                ) {
                    this._nextTickChunk = nextChunk
                    if (this._nextTickChunk.Status !== TChunkStatus.cs_Loaded && !CommonUtils.IsInUnitTest) {
                        this.loadHistoryIfInRange(this._nextTickChunk)
                    }
                }
                //TODO: what to do if it is not in range??
            } else {
                const furtherChunkForLoading = this.getFurtherForLoadingAccordingToDirection()
                if (furtherChunkForLoading && !CommonUtils.IsInUnitTest) {
                    this.loadHistoryIfInRange(furtherChunkForLoading)
                }
            }
        }
    }

    private getFurtherForLoadingAccordingToDirection(): TBaseTickChunk | null {
        if (this._loadingPriorityDirection === Direction.NEXT) {
            for (let i = 0; i < this._futureChunks.length; i++) {
                if (
                    this._futureChunks[i].Status === TChunkStatus.cs_Empty &&
                    !this._futureChunks[i].IsEmptyOnServer()
                ) {
                    if (i === 0) {
                        return this._futureChunks[i]
                    } else {
                        if (this._futureChunks[i - 1].Status === TChunkStatus.cs_Loaded) {
                            return this._futureChunks[i]
                        }
                    }
                }
            }
        } else if (this._loadingPriorityDirection === Direction.PREV) {
            for (let i = 0; i < this._pastChunks.length; i++) {
                if (this._pastChunks[i].Status === TChunkStatus.cs_Empty && !this._pastChunks[i].IsEmptyOnServer()) {
                    if (i === 0) {
                        return this._pastChunks[i]
                    } else {
                        if (this._pastChunks[i - 1].Status === TChunkStatus.cs_Loaded) {
                            return this._pastChunks[i]
                        }
                    }
                }
            }
        }
        return null
    }

    private getNeigbourChunkByTime(
        dateTime: TDateTime,
        direction: Direction,
        needLoading: boolean
    ): TBaseTickChunk | null {
        let resultCandidate: TBaseTickChunk
        switch (direction) {
            case Direction.NEXT: {
                resultCandidate = this.fTicks.GetOrCreateNextChunk(dateTime, needLoading)
                break
            }
            case Direction.PREV: {
                resultCandidate = this.fTicks.GetOrCreatePrevChunk(dateTime, needLoading)
                break
            }
            case Direction.CURRENT: {
                resultCandidate = this.fTicks.GetOrCreateChunkByDate(dateTime, needLoading)
                break
            }
            default: {
                throw new StrangeError('Unknown direction')
            }
        }

        return this.findNonEmptyChunk(resultCandidate, Direction.NEXT)
    }

    private findNonEmptyChunk(baseChunk: TBaseTickChunk, direction: Direction): TBaseTickChunk {
        let currentChunk: TBaseTickChunk | null = baseChunk
        if (baseChunk) {
            let isEmptyOnServer = baseChunk.IsEmptyOnServer()
            let counter = 0
            while (isEmptyOnServer) {
                counter++
                if (counter > this.MAX_RECURSIVE_DAYS_DIFF) {
                    throw new StrangeError(
                        `findNonEmptyChunk - too many iterations and chunk with data not found ${baseChunk.DName}, direction: ${direction}`
                    )
                }
                switch (direction) {
                    case Direction.CURRENT:
                    case Direction.NEXT: {
                        currentChunk = this.fTicks.GetOrCreateNextChunk(currentChunk.LastPossibleDate, false)
                        break
                    }
                    case Direction.PREV: {
                        currentChunk = this.fTicks.GetOrCreatePrevChunk(currentChunk.FirstDate, false) as TBaseTickChunk
                        break
                    }
                    default: {
                        throw new StrangeError('findNonEmptyChunk - Unknown direction')
                    }
                }
                if (currentChunk) {
                    isEmptyOnServer = currentChunk.IsEmptyOnServer()
                } else {
                    throw new StrangeError(
                        `findNonEmptyChunk - Cannot get neighbouring chunk baseChunk: ${baseChunk.DName}, direction: ${direction}`
                    )
                }
            }
        }
        return currentChunk
    }

    getStartDayTickRecord(): TTickRecord | null {
        return this._startDayTickRecord
    }

    private boundDownloadEventHandler = this.HandleDownloadEvent.bind(this)

    private HandleDownloadEvent(chunk: TBaseTickChunk): void {
        if (this._currTickChunk && this._currTickChunk.IsEmptyOnServer()) {
            this.UpdateChunksInfo(GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false))
        } else {
            this.updateLoadedChunkAndPreloadNext()
        }

        this.Events.EmitEvent(TDataArrayEvents.de_ChunkLoaded, chunk)
    }

    private InitChunksData(testingTime: TDateTime) {
        if (!this.isDateInAvailableRange(testingTime)) {
            return
        }
        const currTickChunk = this.fTicks.GetOrCreateChunkByDate(testingTime)
        if (currTickChunk) {
            this._currTickChunk = currTickChunk
            if (!CommonUtils.IsInUnitTest) {
                this.InitNeighbourChunks(currTickChunk)
            }
        } else {
            throw new StrangeError('Ubnormal behaviour - not found current chunk by date. Data not initialized')
        }
    }

    private InitNeighbourChunks(currTickChunk: TBaseTickChunk) {
        if (!this.isDateInAvailableRange(currTickChunk.FirstDate)) {
            return
        }

        const nextTickChunk = this.getNeigbourChunkByTime(currTickChunk.FirstDate, Direction.NEXT, false)
        if (nextTickChunk) {
            this._nextTickChunk = nextTickChunk
            if (this.isDateInAvailableRange(nextTickChunk.FirstDate)) {
                this.loadHistoryIfInRange(this._nextTickChunk)
            }
        } else {
            throw new StrangeError('Ubnormal behaviour - not found next chunk by date.')
        }
        if (!this.IsFirstTickChunkInHistory(currTickChunk)) {
            const prevTickChunk = this.getNeigbourChunkByTime(currTickChunk.FirstDate, Direction.PREV, false)
            if (prevTickChunk) {
                this._prevTickChunk = prevTickChunk
                if (this.isDateInAvailableRange(prevTickChunk.LastPossibleDate)) {
                    this.loadHistoryIfInRange(this._prevTickChunk)
                }
            } else {
                throw new StrangeError('Ubnormal behaviour - not found prev chunk by date.')
            }
        }
        this.initFutureChunks(currTickChunk)
        this.initPastChunks(currTickChunk)
    }

    private initFutureChunks(currentChunk: TBaseTickChunk) {
        let nextChunk = currentChunk
        for (let i = 0; i < this._nextChunksCount; i++) {
            nextChunk = this.fTicks.GetOrCreateNextChunk(nextChunk.LastPossibleDate, false)
            if (nextChunk && this.isDateInAvailableRange(nextChunk.FirstDate)) {
                this._futureChunks.push(nextChunk)
            }
        }
    }

    private initPastChunks(currentChunk: TBaseTickChunk) {
        let prevChunk = currentChunk
        for (let i = 0; i < this._prevChunksCount; i++) {
            prevChunk = this.fTicks.GetOrCreatePrevChunk(prevChunk.FirstDate, false) as TBaseTickChunk
            if (prevChunk && this.isDateInAvailableRange(prevChunk.LastPossibleDate)) {
                this._pastChunks.push(prevChunk)
            }
        }
    }

    private UpdateChunksData(testingTime: TDateTime) {
        const currChunkStartDay = DateUtils.StartOfTheDay(testingTime)
        const lastPastChunkDay = currChunkStartDay - this._prevChunksCount
        const firstFutureChunkDay = currChunkStartDay + this._nextChunksCount
        const chunksForRemove: TBaseTickChunk[] = []
        for (const chunk of this._loadedChunks) {
            if (chunk.FirstDate < lastPastChunkDay || chunk.FirstDate > firstFutureChunkDay) {
                chunksForRemove.push(chunk)
            }
        }

        for (const chunk of chunksForRemove) {
            chunk.ClearDataAndResetStatus()
            this._loadedChunks.splice(this._loadedChunks.indexOf(chunk), 1)
        }

        const futureChunkForRemove: TBaseTickChunk[] = []
        const maxPossibleDateForFutureChunk: TDateTime = testingTime + DateUtils.OneDay * this._nextChunksCount
        for (const chunk of this._futureChunks) {
            if (chunk.FirstDate > maxPossibleDateForFutureChunk || chunk.LastPossibleDate < testingTime) {
                futureChunkForRemove.push(chunk)
            }
        }
        for (const chunk of futureChunkForRemove) {
            this._futureChunks.splice(this._futureChunks.indexOf(chunk), 1)
        }

        if (this._futureChunks.length === 0) {
            if (this._currTickChunk) {
                this.initFutureChunks(this._currTickChunk)
            } else {
                throw new StrangeError(
                    'Ubnormal behaviour - not found current chunk by date. Future chunks not initialized!!!'
                )
            }
        } else {
            let lastFutureChunk = this._futureChunks.at(-1)
            if (!lastFutureChunk) {
                throw new StrangeError('Ubnormal behaviour - last future chunk is undefined.')
            }
            while (lastFutureChunk.LastPossibleDate < maxPossibleDateForFutureChunk) {
                lastFutureChunk = this.fTicks.GetOrCreateNextChunk(lastFutureChunk.FirstDate, false)
                if (lastFutureChunk && !lastFutureChunk.DateInside(testingTime)) {
                    this._futureChunks.push(lastFutureChunk)
                }
            }

            let firstFutureChunk = this._futureChunks[0]
            while (firstFutureChunk.FirstDate > testingTime) {
                firstFutureChunk = this.fTicks.GetOrCreatePrevChunk(firstFutureChunk.FirstDate, false)
                if (firstFutureChunk && !firstFutureChunk.DateInside(testingTime)) {
                    this._futureChunks.unshift(firstFutureChunk)
                }
            }
        }

        const pastChunkForRemove: TBaseTickChunk[] = []
        const minPossibleDateForPastChunk: TDateTime = testingTime - DateUtils.OneDay * this._prevChunksCount
        for (const chunk of this._pastChunks) {
            if (chunk.LastPossibleDate < minPossibleDateForPastChunk || chunk.FirstDate > testingTime) {
                pastChunkForRemove.push(chunk)
            }
        }
        for (const chunk of pastChunkForRemove) {
            this._pastChunks.splice(this._pastChunks.indexOf(chunk), 1)
        }

        if (this._pastChunks.length === 0) {
            if (this._currTickChunk) {
                this.initPastChunks(this._currTickChunk)
            } else {
                throw new StrangeError(
                    'Ubnormal behaviour - not found current chunk by date. Past chunks not initialized!!!'
                )
            }
        } else {
            let lastPastChunk = this._pastChunks.at(-1)
            if (!lastPastChunk) {
                throw new StrangeError('Ubnormal behaviour - last past chunk is undefined.')
            }
            while (lastPastChunk.LastPossibleDate > minPossibleDateForPastChunk) {
                lastPastChunk = this.fTicks.GetOrCreatePrevChunk(lastPastChunk.FirstDate, false)
                if (lastPastChunk) {
                    this._pastChunks.push(lastPastChunk)
                }
            }

            let firstPastChunk = this._pastChunks[0]
            while (firstPastChunk.LastPossibleDate < testingTime) {
                firstPastChunk = this.fTicks.GetOrCreateNextChunk(firstPastChunk.FirstDate, false)
                if (firstPastChunk && !firstPastChunk.DateInside(testingTime)) {
                    this._pastChunks.unshift(firstPastChunk)
                }
            }
        }
    }

    public UpdateChunksInfo(testingTime: TDateTime): void {
        if (!this.isDateInAvailableRange(testingTime)) {
            //no need to do anything while we are out of range
            //TODO: is it ok if we won't init any chunks here while we are out of data range?
            return
        }
        if (this._currTickChunk) {
            if (!this._currTickChunk.DateInside(testingTime)) {
                const currTickChunk = this.getNeigbourChunkByTime(testingTime, Direction.CURRENT, false)
                if (currTickChunk) {
                    this._currTickChunk = currTickChunk
                    this.loadHistoryIfInRange(this._currTickChunk)
                    const nextTickChunk = this.getNeigbourChunkByTime(
                        this._currTickChunk.FirstDate,
                        Direction.NEXT,
                        false
                    )
                    if (nextTickChunk) {
                        this._nextTickChunk = nextTickChunk
                        if (!CommonUtils.IsInUnitTest && this.isDateInAvailableRange(this._nextTickChunk.FirstDate)) {
                            this.loadHistoryIfInRange(this._nextTickChunk)
                        }
                    }
                    const prevTickChunk = this.getNeigbourChunkByTime(
                        this._currTickChunk.FirstDate,
                        Direction.PREV,
                        false
                    )
                    if (prevTickChunk) {
                        this._prevTickChunk = prevTickChunk
                        if (
                            !CommonUtils.IsInUnitTest &&
                            this.isDateInAvailableRange(this._prevTickChunk.LastPossibleDate)
                        ) {
                            this.loadHistoryIfInRange(this._prevTickChunk)
                        }
                    }
                }
            }
            this.UpdateChunksData(testingTime)
        } else {
            this.InitChunksData(testingTime)
        }

        if (this.IsNextChunkPreloaded()) {
            const furtherChunkForLoading = this.getFurtherForLoadingAccordingToDirection()
            if (furtherChunkForLoading && !CommonUtils.IsInUnitTest) {
                this.loadHistoryIfInRange(furtherChunkForLoading)
            }
        }

        this.checkStartDayTickRecordAndUpdateIfPossible(testingTime)
    }

    private updateStartDayTickRecord(testingTime: TDateTime): void {
        try {
            this._startDayTickRecord = this.GetTickAtDate(
                DateUtils.StartOfTheDay(testingTime),
                TNoExactMatchBehavior.nemb_ReturnNearestHigher
            )
        } catch (error) {
            if (error instanceof DataNotDownloadedYetError) {
                return
            }
            throw error
        }
    }

    private checkStartDayTickRecordAndUpdateIfPossible(testingTime: TDateTime): void {
        if (
            !this._startDayTickRecord ||
            DateUtils.StartOfTheDay(this._startDayTickRecord.DateTime) !== DateUtils.StartOfTheDay(testingTime)
        ) {
            this.updateStartDayTickRecord(testingTime)
        }
    }

    private loadHistoryIfInRange(chunk: TBaseTickChunk): void {
        //TODO: refactor this when we can get history bounds from the symbol itself
        if (this.isDateInAvailableRange(chunk.FirstDate) || this.isDateInAvailableRange(chunk.LastPossibleDate)) {
            DownloadController.Instance.loadHistoryIfNecessary(chunk, true)
        } else {
            DebugUtils.logTopic(
                ELoggingTopics.lt_ChunkLoading,
                'Skipping loading tick chunk because it is out of symbol data range',
                chunk.FirstDate,
                chunk.LastPossibleDate,
                chunk.DName
            )
        }
    }

    //TODO: optimize this, add caching of the index in the data array
    //here we are not extracting the tick, we are just getting it to process later, so the tick here is NOT the tick that is actually processed
    public GetNewTick(): TTickRecord | null {
        if (
            DateUtils.AreEqual(
                GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false),
                this.VeryFirstDateInHistory
            )
        ) {
            if (DebugUtils.DebugMode) {
                //investigate how this case works
                //can we get rid of it since the seeking works differently now?
                debugger
            }
            //FIXME: this is probably incorrect, we are not supposed to change LastProcessedTickTime in GetNewTick, this should be done in ExtractTick
            this.LastProcessedTickTime = this.VeryFirstDateInHistory
            this._positionNextTickDate = this.VeryFirstDateInHistory
        }

        if (
            DateUtils.LessOrEqual(this._positionNextTickDate, this.VeryLastDateInHistory) &&
            DateUtils.MoreOrEqual(this._positionNextTickDate, this.VeryFirstDateInHistory)
        ) {
            const theTick = this.fTicks.GetItemByDatePosition(
                this._positionNextTickDate,
                TNoExactMatchBehavior.nemb_ReturnNearestHigher
            )
            if (theTick) {
                return theTick
            } else {
                //TODO: revise this logic
                //probably the timestamp was incorrect and we are in the gap between ticks, let's try to get the next tick
                const nextPosition = this.GetNextPosition(this._positionNextTickDate)
                if (nextPosition <= this.VeryLastDateInHistory) {
                    const nextTick = this.fTicks.GetItemByDatePosition(
                        nextPosition,
                        TNoExactMatchBehavior.nemb_ReturnNearestHigher
                    )
                    if (nextTick) {
                        return nextTick
                    } else {
                        throw new StrangeError(
                            `Cannot find next tick for ${DateUtils.DF(nextPosition)} for symbol ${
                                this.fTicks.DataDescriptor.symbolName
                            }`
                        )
                    }
                }
            }
        }
        return null
    }

    private MAX_RECURSIVE_DAYS_DIFF = 30

    private ValidateDateForHistoryBounds(date: TDateTime, noExactMatchBehavior: TNoExactMatchBehavior) {
        if (
            date > this.VeryLastDateInHistory &&
            noExactMatchBehavior !== TNoExactMatchBehavior.nemb_ReturnNearestLower
        ) {
            throw new AfterEndOfHistoryError(
                `We have reached the end of history, ${DateUtils.DF(
                    date
                )} is more or equal to the last date in history ${DateUtils.DF(
                    this.VeryLastDateInHistory
                )} for symbol ${this.SymbolName}`
            )
        }

        if (
            date < this.VeryFirstDateInHistory &&
            noExactMatchBehavior !== TNoExactMatchBehavior.nemb_ReturnNearestHigher
        ) {
            throw new BeforeStartOfHistoryError(
                `We have reached the beginning of history, ${DateUtils.DF(
                    date
                )} is less or equal to the first date in history ${DateUtils.DF(
                    this.VeryFirstDateInHistory
                )} for symbol ${this.SymbolName}`
            )
        }
    }

    private ValidateDatesInTickSearch(
        dateTimeToSearch: TDateTime,
        recursiveSearchStartDate: TDateTime,
        noExactMatchBehavior: TNoExactMatchBehavior
    ) {
        this.ValidateDateForHistoryBounds(dateTimeToSearch, noExactMatchBehavior)

        const daysDiff = DateUtils.AbsDiffInDays(recursiveSearchStartDate, dateTimeToSearch)

        if (daysDiff > this.MAX_RECURSIVE_DAYS_DIFF) {
            if (dateTimeToSearch < this.VeryFirstDateInHistory) {
                this.throwBeforeStartOfHistoryError()
            } else if (dateTimeToSearch >= this.VeryLastDateInHistory) {
                this.throwAfterEndOfHistoryError()
            } else {
                this.throwAfterEndOfHistoryError()
                //FIXME: uncomment this and remove the line above when the server returns the correct end date
                // throw new StrangeError(
                //     `Recursive search for tick at date failed for ${recursiveSearchStartDate} -> ${DateUtils.DF(
                //         recursiveSearchStartDate
                //     )} for symbol ${
                //         this.fTicks.DataDescriptor.symbolName
                //     }. We are now searching ${dateTimeToSearch} -> ${DateUtils.DF(
                //         dateTimeToSearch
                //     )} The recursive difference in days is ${daysDiff}`
                // )
            }
        }
    }

    private throwAfterEndOfHistoryError(): void {
        throw new AfterEndOfHistoryError(
            t('testingManager.toasts.the-end-of-history-is-reached-message', { symbol: this.DataDescriptor.symbolName })
        )
    }

    private throwBeforeStartOfHistoryError(): void {
        throw new BeforeStartOfHistoryError(
            t('testingManager.toasts.the-beginning-of-history-is-reached-message', {
                symbol: this.DataDescriptor.symbolName,
                first_date_str: DateUtils.DF(this.VeryFirstDateInHistory, 'YYYY-MM-DD')
            })
        )
    }

    public GetTickAtDate(
        dateTimeToSearch: TDateTime,
        noExactMatchBehavior: TNoExactMatchBehavior,
        recursiveSearchStartDate?: TDateTime
    ): TTickRecord | null {
        if (!recursiveSearchStartDate) {
            recursiveSearchStartDate = dateTimeToSearch
        }

        this.ValidateDatesInTickSearch(dateTimeToSearch, recursiveSearchStartDate, noExactMatchBehavior)

        if (
            dateTimeToSearch < this.VeryFirstDateInHistory &&
            noExactMatchBehavior !== TNoExactMatchBehavior.nemb_ReturnNearestHigher
        ) {
            throw new BeforeStartOfHistoryError('GetTickAtDate - date is before the start of history')
        }
        if (
            dateTimeToSearch > this.VeryLastDateInHistory &&
            noExactMatchBehavior !== TNoExactMatchBehavior.nemb_ReturnNearestLower
        ) {
            throw new AfterEndOfHistoryError('GetTickAtDate - date is after the end of history')
        }
        const thisChunk = this.fTicks.GetOrCreateChunkByDate(dateTimeToSearch)

        this.EnsureChunkIsLoadedAndThrowErrorIfNot(thisChunk)

        if (thisChunk.Count === 0 || thisChunk.IsEmptyOnServer()) {
            return this.GetTickFromOtherChunk(dateTimeToSearch, noExactMatchBehavior, recursiveSearchStartDate)
        }

        let localIndexOfThisTick

        try {
            localIndexOfThisTick = thisChunk.GetLocalIndexByDateBin(dateTimeToSearch, noExactMatchBehavior)
        } catch (error) {
            if (error instanceof DateIsBeforeChunkStart) {
                return this.GetTickFromPrevChunks(dateTimeToSearch, recursiveSearchStartDate)
            }
            if (error instanceof DateIsAfterChunkEnd) {
                return this.GetTickFromNextChunks(dateTimeToSearch, recursiveSearchStartDate)
            }
            throw error
        }

        const tickAtIndex = thisChunk.GetItemByLocalIndex(localIndexOfThisTick)

        if (!tickAtIndex) {
            throw new StrangeError('Unexpected case in GetTickAtDate, tickAtIndex is null')
        }

        const tickAtIndexDate = tickAtIndex.DateTime

        //what if we are at the edge
        if (
            localIndexOfThisTick === 0 &&
            tickAtIndexDate > dateTimeToSearch &&
            noExactMatchBehavior === TNoExactMatchBehavior.nemb_ReturnNearestLower
        ) {
            return this.GetTickFromPrevChunks(dateTimeToSearch, recursiveSearchStartDate)
        } else if (
            localIndexOfThisTick === thisChunk.Count - 1 &&
            tickAtIndexDate < dateTimeToSearch &&
            noExactMatchBehavior === TNoExactMatchBehavior.nemb_ReturnNearestHigher
        ) {
            return this.GetTickFromNextChunks(dateTimeToSearch, recursiveSearchStartDate)
        } else {
            return tickAtIndex
        }
    }

    private EnsureChunkIsLoadedAndThrowErrorIfNot(thisChunk: TBaseTickChunk) {
        if (thisChunk.Status !== TChunkStatus.cs_Loaded) {
            DownloadController.Instance.loadHistoryIfNecessary(thisChunk, true)
            throw new DataNotDownloadedYetError(
                `CheckIfChunkIsLoadedAndThrowErrorIfNot Tick chunk is not ready yet for ${
                    this.fTicks.DataDescriptor.symbolName
                } ${thisChunk.DName}`
            )
        }
    }

    private GetTickFromOtherChunk(
        DateTime: TDateTime,
        noExactMatchBehavior: TNoExactMatchBehavior,
        recursiveSearchStartDate?: TDateTime
    ) {
        switch (noExactMatchBehavior) {
            case TNoExactMatchBehavior.nemb_ReturnNearestHigher: {
                return this.GetTickFromNextChunks(DateTime, recursiveSearchStartDate)
            }
            case TNoExactMatchBehavior.nemb_ReturnNearestLower: {
                return this.GetTickFromPrevChunks(DateTime, recursiveSearchStartDate)
            }
            case TNoExactMatchBehavior.nemb_ThrowError: {
                throw new NoExactMatchError(
                    `Cannot find tick for ${DateUtils.DF(DateTime)} for symbol ${
                        this.fTicks.DataDescriptor.symbolName
                    } because the chunk is empty`
                )
            }
            default: {
                throw new StrangeError('Unknown NoExactMatchBehavior')
            }
        }
    }

    private GetTickFromNextChunks(DateTime: TDateTime, recursiveSearchStartDate?: TDateTime) {
        const nextPosition = this.GetNextPosition(DateTime, recursiveSearchStartDate)
        return this.GetTickAtDate(nextPosition, TNoExactMatchBehavior.nemb_ThrowError, recursiveSearchStartDate)
    }

    private GetTickFromPrevChunks(DateTime: TDateTime, recursiveSearchStartDate?: TDateTime) {
        const prevPosition = this.GetPrevPosition(DateTime, recursiveSearchStartDate)
        //TNoExactMatchBehavior.nemb_ThrowError here because if we have already found the position, then we should not have any problems with getting the tick at this position
        return this.GetTickAtDate(prevPosition, TNoExactMatchBehavior.nemb_ThrowError, recursiveSearchStartDate)
    }

    private GetPrevPosition(basePosition: TDateTime, recursiveSearchStartDate?: TDateTime): TDateTime {
        const thisChunk = this.fTicks.GetOrCreateChunkByDate(basePosition)

        this.EnsureChunkIsLoadedAndThrowErrorIfNot(thisChunk)

        if (thisChunk.Count === 0) {
            //This will call GetPrevPosition again until we find a tick
            return this.GetLastPositionOfPreviousChunk(basePosition, recursiveSearchStartDate)
        }

        const localIndexOfThisTick = thisChunk.GetLocalIndexByDateBin(
            basePosition,
            TNoExactMatchBehavior.nemb_ReturnNearestHigher
        )

        if (localIndexOfThisTick > 0) {
            //if base position index is greater than 0, then the previous position has index 0 or more
            const prevTick = thisChunk.GetItemByLocalIndex(localIndexOfThisTick - 1)
            if (prevTick) {
                return prevTick.DateTime
            } else {
                throw new StrangeError(
                    'Unexpected case 1 in GetPrevPosition - if the chunk is loaded, then we should always be able to find the tick'
                )
            }
        } else {
            if (localIndexOfThisTick === 0 || localIndexOfThisTick === CommonConstants.EMPTY_INDEX) {
                //either we are at the very first tick in the chunk or all the ticks are above the basePosition
                //This will call GetPrevPosition again until we find a tick
                // if (this.IsFirstTickChunkInHistory(thisChunk) && basePosition < thisChunk.FirstItem.DateTime) {
                //     basePosition = thisChunk.FirstItem.DateTime
                //     // this.LastProcessedTickTime = basePosition
                // }
                return this.GetLastPositionOfPreviousChunk(basePosition, recursiveSearchStartDate)
            }
            throw new StrangeError(
                'Unexpected case 2 in GetPrevPosition - we should not be here because negative index does not make sense unless it is EMPTY_INDEX'
            )
        }
    }

    private GetLastPositionOfPreviousChunk(basePosition: number, recursiveSearchStartDate?: TDateTime) {
        if (basePosition <= this.VeryFirstDateInHistory) {
            return this.VeryFirstDateInHistory
        }
        const prevChunk = this.fTicks.GetOrCreatePrevChunk(basePosition)

        if (
            prevChunk &&
            prevChunk instanceof TBaseTickChunk &&
            prevChunk &&
            prevChunk.Status === TChunkStatus.cs_Empty &&
            this.LastProcessedTickTime > this.VeryFirstDateInHistory &&
            this.LastProcessedTickTime <= this.VeryLastDateInHistory &&
            !CommonUtils.IsInUnitTest
        ) {
            DownloadController.Instance.loadHistoryIfNecessary(prevChunk, true)
        }

        const lastPossibleDateOfPrevChunk = prevChunk.LastPossibleDate
        //to make sure that we are not in the same chunk
        const positionToSearch =
            lastPossibleDateOfPrevChunk - CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_DATETIME * 2
        const tickAtPrevPosition = this.GetTickAtDate(
            positionToSearch,
            TNoExactMatchBehavior.nemb_ReturnNearestLower,
            recursiveSearchStartDate
        )
        if (tickAtPrevPosition) {
            return tickAtPrevPosition.DateTime
        } else {
            throw new StrangeError(
                'Unexpected case in GetLastPositionOfPreviousChunk we should always be able to find the tick'
            )
        }
    }

    private GetFirstPositionOfNextChunk(basePosition: TDateTime, recursiveSearchStartDate?: TDateTime) {
        const nextChunk = this.fTicks.GetOrCreateNextChunk(basePosition)

        const firstDateOfNextChunk = nextChunk.FirstDate
        const tickAtNextPosition = this.GetTickAtDate(
            firstDateOfNextChunk,
            TNoExactMatchBehavior.nemb_ReturnNearestHigher,
            recursiveSearchStartDate
        )
        if (tickAtNextPosition) {
            return tickAtNextPosition.DateTime
        } else {
            throw new StrangeError(
                'Unexpected case in GetFirstPositionOfNextChunk we should always be able to find the tick'
            )
        }
    }

    private IsFirstTickChunkInHistory(tickChunk: TBaseTickChunk): boolean {
        let result = false
        if (tickChunk.DateInside(this.VeryFirstDateInHistory)) {
            result = true
        }
        return result
    }

    //TODO: optimize this, add caching of the index in the data array
    public GetNextPosition(basePosition: TDateTime, recursiveSearchStartDate?: TDateTime): TDateTime {
        const thisChunk = this.fTicks.GetOrCreateChunkByDate(basePosition)
        if (thisChunk.Status === TChunkStatus.cs_Empty) {
            DownloadController.Instance.loadHistoryIfNecessary(thisChunk, true)
        }
        this.EnsureChunkIsLoadedAndThrowErrorIfNot(thisChunk)

        if (thisChunk.Count === 0 || thisChunk.IsEmptyOnServer()) {
            //This will call GetNextPosition again until we find a tick
            return this.GetFirstPositionOfNextChunk(basePosition, recursiveSearchStartDate)
        }

        if (this.IsFirstTickChunkInHistory(thisChunk) && basePosition < thisChunk.FirstItem.DateTime) {
            basePosition = thisChunk.FirstItem.DateTime
            // this.LastProcessedTickTime = basePosition
        }

        const localIndexOfThisTick = thisChunk.GetLocalIndexByDateBin(basePosition)

        if (localIndexOfThisTick < thisChunk.Count - 1 && localIndexOfThisTick !== CommonConstants.EMPTY_INDEX) {
            //meaning we are still within the same chunk and this chunk has some data (for empty chunk the condition above will be false)
            const nextTick = thisChunk.GetItemByLocalIndex(localIndexOfThisTick + 1)
            if (nextTick) {
                return nextTick.DateTime
            } else {
                throw new StrangeError(
                    'Unexpected case in GetNextPosition, if the chunk is loaded, then we should always be able to find the tick'
                )
            }
        } else {
            if (
                localIndexOfThisTick === thisChunk.Count - 1 || //last item in the chunk
                localIndexOfThisTick === CommonConstants.EMPTY_INDEX //or maybe all the ticks in this chunk are below the basePosition
            ) {
                //This will call GetNextPosition again until we find a tick
                return this.GetFirstPositionOfNextChunk(basePosition, recursiveSearchStartDate)
            }
            //localIndexOfThisTick is above Count - 1
            throw new StrangeError('Unexpected case in GetNextPosition')
        }
    }

    public GetLastProcessedTick(): TTickRecord | null {
        //TODO: test edge case of the very last tick that has the same date as the previous tick and last date in history
        const prevPosition: TDateTime = this.GetPrevPosition(this._positionNextTickDate)
        if (prevPosition <= this.VeryFirstDateInHistory) {
            return this.fTicks.GetOrCreateChunkByDate(this.VeryFirstDateInHistory).FirstItem
        }
        if (prevPosition >= this.VeryFirstDateInHistory && prevPosition < this.VeryLastDateInHistory) {
            return this.fTicks.GetItemByDatePosition(prevPosition, TNoExactMatchBehavior.nemb_ReturnNearestLower)
        }
        StrangeSituationNotifier.NotifyAboutUnexpectedSituation(`Cannot get prev tick, prev position = ${prevPosition}`)
        return null
    }

    protected GetFirstPossiblePositionInHistory(): TDateTime {
        return this.VeryFirstDateInHistory
    }

    protected GetLastPossiblePositionInHistory(): TDateTime {
        return this.VeryLastDateInHistory
    }

    //this method can throw DataUnavailableError to defer the seeking until the data is available
    public Seek(seekToThisDate: TDateTime): TDateTime {
        DebugUtils.logTopic(ELoggingTopics.lt_Seek, 'Seeking TickData to', seekToThisDate, DateUtils.DF(seekToThisDate))

        if (this.IsSeeked && this._seekedToDate && DateUtils.AreEqual(this._seekedToDate, seekToThisDate)) {
            DebugUtils.logTopic(
                ELoggingTopics.lt_Seek,
                'TickData already seeked to',
                seekToThisDate,
                'with last processed tick time',
                this.LastProcessedTickTime
            )
            if (!this.LastProcessedTickTime) {
                throw new StrangeError('LastProcessedTickTime is not set after seeking to the same date')
            }
            return this.LastProcessedTickTime
        }

        this.IsSeeked = false

        if (DateUtils.LessOrEqual(seekToThisDate, this.VeryFirstDateInHistory)) {
            const firstTickInHistory = this.GetTickAtDate(
                this.VeryFirstDateInHistory,
                TNoExactMatchBehavior.nemb_ReturnNearestHigher
            )

            if (!firstTickInHistory) {
                throw new StrangeError(
                    `GetTickAtDate Cannot find FIRST tick for ${DateUtils.DF(seekToThisDate)} for symbol ${
                        this.fTicks.DataDescriptor.symbolName
                    }`
                )
            }

            this.LastProcessedTickTime = firstTickInHistory.DateTime

            this._positionNextTickDate = this.GetNextPosition(this.LastProcessedTickTime)
            this._seekedToDate = seekToThisDate
            this.IsSeeked = true
            return this._positionNextTickDate
        }

        const lastProcessedTick = this.GetTickAtDate(seekToThisDate, TNoExactMatchBehavior.nemb_ReturnNearestLower)
        if (!lastProcessedTick) {
            throw new DataNotDownloadedYetError(
                `GetTickAtDate Cannot find PROCESSED tick for ${DateUtils.DF(seekToThisDate)} for symbol ${
                    this.fTicks.DataDescriptor.symbolName
                }`
            )
        }
        this.LastProcessedTickTime = lastProcessedTick.DateTime
        this._positionNextTickDate = this.GetNextPosition(lastProcessedTick.DateTime)
        this.IsSeeked = true
        DebugUtils.logTopic(
            ELoggingTopics.lt_Seek,
            'Seeking TickData completed successfully to',
            lastProcessedTick.DateTime,
            DateUtils.DF(lastProcessedTick.DateTime),
            this.DName
        )
        this._seekedToDate = seekToThisDate

        return lastProcessedTick.DateTime
    }

    public PreloadDataIfNecessary(startDate: TDateTime, endDate: TDateTime): void {
        //TODO: implement using endDate as well
        this.fTicks.PreloadDataIfNecessary(startDate, endDate)
    }

    public ResetSeekStatus(): void {
        this.IsSeeked = false
    }

    public markLastTickAsProcessed(tickDateTime: TDateTime, isEmulatedTick: boolean): void {
        //if the tick is emulated, then we don't really need to do that since Seek already did everything and set the correct next position
        if (!isEmulatedTick) {
            const tick = this.GetNewTick()
            if (tick) {
                if (!DateUtils.AreEqual(tick.DateTime, tickDateTime)) {
                    throw new StrangeError('Cannot mark last tick as processed, tick date is different')
                }
                this._positionNextTickDate = this.GetNextPosition(this._positionNextTickDate)
                this.LastProcessedTickTime = tick.DateTime
                this._seekedToDate = this.LastProcessedTickTime
            } else {
                throw new StrangeError('Cannot mark last tick as processed, the tick is null')
            }
        }
    }

    public clearTickData(): void {
        for (const chunk of this._loadedChunks) {
            chunk.ClearDataAndResetStatus()

            DownloadController.Instance.loadHistoryIfNecessary(chunk, true)
        }

        this._loadedChunks = []
        this.fTicks.ClearDataInChunks()
        this._currTickChunk = null
        this._nextTickChunk = null
        this._prevTickChunk = null

        this._futureChunks = []
        this._pastChunks = []

        this.UpdateChunksInfo(GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime())
    }
}
