export const checkIsThereScrollLeft = (element: HTMLDivElement | null, isHorizontal: boolean) => {
    if (!element) return false

    if (!isHorizontal) {
        return element.scrollTop > 0
    }
    return element.scrollLeft > 0
}

export const checkIsThereScrollRight = (element: HTMLDivElement | null, isHorizontal: boolean) => {
    if (!element) return false

    if (!isHorizontal) {
        return Math.ceil(element.scrollTop + element.offsetHeight) < element.scrollHeight
    }

    return Math.ceil(element.scrollLeft + element.offsetWidth) < element.scrollWidth
}
