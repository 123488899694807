import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { SelectableControl } from '@fto/lib/processing_core/TradePositionClasses/SelectableControl'
import { getTextWidth } from '@fto/lib/globals/GlobalTextSizeCache'
import { TTradePos } from '@fto/lib/processing_core/TradePositionClasses/TradePosition'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { ChartControlParams } from '@fto/chart_components/ChartControlParams'
import { formatCurrency } from '@fto/lib/utils/ordersUtils'
import { TCursor } from '@fto/lib/ft_types/common/CursorPointers'
import { IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { TRect } from '@fto/lib/extension_modules/common/CommonExternalInterface'

export class SellBuyConnectorLine extends SelectableControl {
    public isProfitable = false
    public markerOpen: HTMLImageElement | HTMLCanvasElement | null = null
    public markerClose: HTMLImageElement | HTMLCanvasElement | null = null
    public openOrderInfo: {
        posType: string
        openPrice: string
        lot: string
        coords: { x: number; y: number; height: number; width: number } | null
    } | null = null
    public closeOrderInfo: {
        posType: string
        closePrice: string
        lot: string
        profit: string
        points: number
        coords: { x: number; y: number; height: number; width: number } | null
    } | null = null
    public owner: TTradePos
    public chartWindow: TChartWindow
    private isHide = false

    constructor(chartControlParams: ChartControlParams, owner: TTradePos, chartWindow: TChartWindow) {
        super(chartControlParams)

        this.owner = owner
        this.chartWindow = chartWindow
    }

    public draw(canvas: TGdiPlusCanvas): void {
        const ctx = canvas.graphics.Context

        const { Left: leftCornerPoint, Right: rightCornerPoint, Top: yOpen, Bottom: yClose } = this.getLocation()
        const isProfitable = this.isProfitable

        const profitColor = this.chartWindow.ChartOptions.ColorScheme.ProfitTransactionColor
        const lossColor = this.chartWindow.ChartOptions.ColorScheme.LossTransactionColor

        const lineColor = isProfitable ? profitColor : lossColor
        const borderColor = isProfitable ? `${profitColor}4D` : `${lossColor}4D`

        // eslint-disable-next-line no-undef
        const drawLine = (color: string, width: number, lineCap: CanvasLineCap = 'butt') => {
            ctx.save()
            ctx.strokeStyle = color
            ctx.lineWidth = width
            ctx.lineCap = lineCap
            ctx.beginPath()
            ctx.moveTo(leftCornerPoint, yOpen)
            ctx.lineTo(rightCornerPoint, yClose)
            ctx.stroke()
            ctx.restore()
        }

        if (this.isMouseInside() || this.isSelected) {
            drawLine(borderColor, 10, 'round')
            drawLine(lineColor, 1)
        } else {
            drawLine(lineColor, 1)
        }

        const drawMarker = (marker: HTMLImageElement | HTMLCanvasElement, x: number, y: number) => {
            ctx.drawImage(marker, x - marker.width / 2, y - marker.height / 2)
        }

        if (this.markerOpen) {
            drawMarker(this.markerOpen, leftCornerPoint, yOpen)
        }

        if (this.markerClose) {
            drawMarker(this.markerClose, rightCornerPoint, yClose)
        }
    }

    public drawInfoBoxes(canvas: TGdiPlusCanvas): void {
        this.drawOpenInfoBox(canvas)
        this.drawCloseInfoBox(canvas)
    }

    private drawOpenInfoBox(canvas: TGdiPlusCanvas): void {
        if (!this.openOrderInfo) {
            return
        }
        const dpr = window.devicePixelRatio || 1
        const { posType, openPrice, lot } = this.openOrderInfo
        const backgroundColor = this.chartWindow.ChartOptions.ControlsColorScheme.ordersBackground
        const buyColor = this.chartWindow.ChartOptions.ColorScheme.BuyMarkerColor
        const sellColor = this.chartWindow.ChartOptions.ColorScheme.SellMarkerColor

        const baseColor = posType === 'Buy ' ? buyColor : sellColor

        const ctx = canvas.graphics.Context
        const { Left: leftCornerPoint, Top: yOpen } = this.getLocation()
        const fontSize = 12 * dpr
        const font = `${fontSize}px Roboto Flex`

        ctx.font = font

        // Calculate text widths and heights
        const posTypeTextWidth = getTextWidth(ctx, posType, font)
        const openPriceTextWidth = getTextWidth(ctx, openPrice.toString(), font)
        const lotTextWidth = getTextWidth(ctx, `${lot} Lot`, font)

        // Calculate box dimensions
        const padding = 4 * dpr
        const boxWidth = posTypeTextWidth + openPriceTextWidth + lotTextWidth + 6 * padding
        const boxHeight = fontSize + padding * 2

        // Box position
        const boxX = leftCornerPoint - boxWidth - padding
        const boxY = yOpen - boxHeight / 2

        const borderRadius = 2 * dpr
        // Draw main box with rounded corners
        this.drawRoundedRect(ctx, boxX, boxY, boxWidth, boxHeight, borderRadius, baseColor)

        // Draw left colored rectangle with rounded corners
        const leftRectWidth = posTypeTextWidth + 2 * padding
        canvas.FillRectRounded(
            new TRect(boxX, boxY, boxX + leftRectWidth, boxY + boxHeight),
            new IGPSolidBrush(baseColor),
            borderRadius,
            true,
            false,
            false,
            true
        )

        // Draw text
        ctx.textBaseline = 'middle'
        ctx.fillStyle = backgroundColor
        ctx.fillText(posType, boxX + padding, yOpen)

        ctx.fillStyle = baseColor
        ctx.fillText(openPrice.toString(), boxX + leftRectWidth + padding, yOpen)

        // Draw partition between price and lot
        const partitionX = boxX + leftRectWidth + openPriceTextWidth + 2 * padding
        ctx.fillStyle = baseColor
        ctx.fillRect(partitionX, boxY, 1, boxHeight)

        ctx.fillText(`${lot} Lot`, partitionX + padding, yOpen)

        ctx.restore()
    }

    private drawCloseInfoBox(canvas: TGdiPlusCanvas): void {
        if (!this.closeOrderInfo) {
            return
        }
        const dpr = window.devicePixelRatio || 1
        const { posType, closePrice, lot, profit, points } = this.closeOrderInfo
        const backgroundColor = this.chartWindow.ChartOptions.ControlsColorScheme.ordersBackground
        const buyColor = this.chartWindow.ChartOptions.ColorScheme.BuyMarkerColor
        const sellColor = this.chartWindow.ChartOptions.ColorScheme.SellMarkerColor

        const baseColor = posType === 'Buy ' ? buyColor : sellColor

        const ctx = canvas.graphics.Context
        const { Right: rightCornerPoint, Bottom: yClose } = this.getLocation()
        const fontSize = 12 * dpr
        const font = `${fontSize}px Roboto Flex`

        ctx.font = font

        // Calculate text widths and heights
        const posTypeTextWidth = getTextWidth(ctx, posType, font)
        const closePriceTextWidth = getTextWidth(ctx, closePrice.toString(), font, true)
        const lotTextWidth = getTextWidth(ctx, `${lot}Lot `, font)
        const profitTextWidth = getTextWidth(ctx, `+$ ${profit.toString()}`, font)
        const pointsTextWidth = getTextWidth(ctx, `${points} points`, font)

        // Calculate box dimensions
        const padding = 4 * dpr
        const boxWidth =
            posTypeTextWidth + closePriceTextWidth + lotTextWidth + profitTextWidth + pointsTextWidth + 10 * padding
        const boxHeight = fontSize + padding * 2

        // Box position
        const boxX = rightCornerPoint + padding // Positioned to the right of closeOrder
        const boxY = yClose - boxHeight / 2

        const borderRadius = 2 * dpr

        // Draw main box with rounded corners
        this.drawRoundedRect(ctx, boxX, boxY, boxWidth, boxHeight, borderRadius, baseColor)

        // Draw left colored rectangle with rounded corners
        const leftRectWidth = posTypeTextWidth + 2 * padding
        canvas.FillRectRounded(
            new TRect(boxX, boxY, boxX + leftRectWidth, boxY + boxHeight),
            new IGPSolidBrush(baseColor),
            borderRadius,
            true,
            false,
            false,
            true
        )

        // Draw text
        ctx.textBaseline = 'middle'
        ctx.fillStyle = backgroundColor
        ctx.fillText(posType, boxX + padding, yClose)

        ctx.fillStyle = baseColor
        ctx.fillText(closePrice.toString(), boxX + leftRectWidth + padding, yClose)

        // Draw partition lines
        let partitionX = boxX + leftRectWidth + closePriceTextWidth + 2 * padding
        ctx.fillStyle = baseColor
        ctx.fillRect(partitionX, boxY, 1, boxHeight)

        ctx.fillText(`${lot} Lot`, partitionX + padding, yClose)

        partitionX += lotTextWidth + 2 * padding
        ctx.fillRect(partitionX, boxY, 1, boxHeight)

        ctx.fillText(formatCurrency(Number(profit)), partitionX + padding, yClose)

        partitionX += profitTextWidth + padding
        ctx.fillRect(partitionX, boxY, 1, boxHeight)

        ctx.fillText(`${points} points`, partitionX + padding, yClose)

        ctx.restore()
    }

    protected drawRoundedRect(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        borderRadius: number,
        color: string
    ): void {
        const backgroundColor = this.chartWindow.ChartOptions.ControlsColorScheme.ordersBackground
        ctx.save()
        ctx.fillStyle = backgroundColor
        ctx.strokeStyle = color
        ctx.lineWidth = 1
        ctx.shadowColor = 'rgba(0, 29, 67, 0.05)'
        ctx.shadowBlur = 2
        ctx.shadowOffsetY = 1
        ctx.globalAlpha = this.owner.isTransparent ? 0.5 : 1

        this.roundRect(ctx, x, y, width, height, borderRadius)

        ctx.fill()
        ctx.stroke()
        ctx.restore()
    }

    protected roundRect(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        borderRadius: number = 0
    ) {
        ctx.beginPath()
        ctx.moveTo(x + borderRadius, y)
        ctx.lineTo(x + width - borderRadius, y)
        ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius)
        ctx.lineTo(x + width, y + height - borderRadius)
        ctx.quadraticCurveTo(x + width, y + height, x + width - borderRadius, y + height)
        ctx.lineTo(x + borderRadius, y + height)
        ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius)
        ctx.lineTo(x, y + borderRadius)
        ctx.quadraticCurveTo(x, y, x + borderRadius, y)
        ctx.closePath()
    }

    // remove it to utils file
    public distanceFromPointToLine(px: number, py: number, x1: number, y1: number, x2: number, y2: number): number {
        const A = px - x1
        const B = py - y1
        const C = x2 - x1
        const D = y2 - y1

        const dot = A * C + B * D
        const len_sq = C * C + D * D
        const param = len_sq === 0 ? -1 : dot / len_sq

        let xx, yy

        if (param < 0) {
            xx = x1
            yy = y1
        } else if (param > 1) {
            xx = x2
            yy = y2
        } else {
            xx = x1 + param * C
            yy = y1 + param * D
        }

        const dx = px - xx
        const dy = py - yy
        return Math.hypot(dx, dy)
    }

    protected isPointInside(x: number, y: number): boolean {
        if (this.isHide) {
            return false
        }

        const location = this.getLocation()
        const distance = this.distanceFromPointToLine(
            x,
            y,
            location.Left,
            location.Top,
            location.Right,
            location.Bottom
        )

        let result = distance < 2

        if (!result && !this.isHide) {
            if (this.openOrderInfo && this.openOrderInfo.coords) {
                const { x: xOpen, y: yOpen, width: openWidth, height: openHeight } = this.openOrderInfo.coords
                if (x >= xOpen && x <= xOpen + openWidth && y >= yOpen && y <= yOpen + openHeight) {
                    result = true
                }
            }

            if (this.closeOrderInfo && this.closeOrderInfo.coords) {
                const { x: xClose, y: yClose, width: closeWidth, height: closeHeight } = this.closeOrderInfo.coords
                if (x >= xClose && x <= xClose + closeWidth && y >= yClose && y <= yClose + closeHeight) {
                    result = true
                }
            }
        }

        if (result) {
            this.chartWindow.SetCursor(TCursor.crHandPoint)
        }

        return result
    }

    public setOpenOrderInfo(posType: string, openPrice: string, lot: string): void {
        if (this.openOrderInfo) {
            this.openOrderInfo = { posType, openPrice, lot, coords: this.openOrderInfo.coords }
        } else {
            this.openOrderInfo = { posType, openPrice, lot, coords: { x: 0, y: 0, height: 0, width: 0 } }
        }
    }

    public setCloseOrderInfo(posType: string, closePrice: string, lot: string, profit: string, points: number): void {
        if (this.closeOrderInfo) {
            this.closeOrderInfo = { posType, closePrice, lot, profit, points, coords: this.closeOrderInfo.coords }
        } else {
            this.closeOrderInfo = {
                posType,
                closePrice,
                lot,
                profit,
                points,
                coords: { x: 0, y: 0, height: 0, width: 0 }
            }
        }
    }

    public onSelect(): void {
        for (const orderLevel of this.owner.orderLevels) {
            if (orderLevel[1].sellBuyConnectorLine) {
                orderLevel[1].sellBuyConnectorLine.isSelected = true
            }
        }
    }

    public onDeselect(): void {
        for (const orderLevel of this.owner.orderLevels) {
            if (orderLevel[1].sellBuyConnectorLine) {
                orderLevel[1].sellBuyConnectorLine.isSelected = false
            }
        }
    }

    public Capture(): void {
        for (const levels of this.owner.orderLevels) {
            if (levels[1].sellBuyConnectorLine) {
                levels[1].sellBuyConnectorLine.setCapture(true)
            }
        }
    }

    public ReleaseCapture(): void {
        for (const levels of this.owner.orderLevels) {
            if (levels[1].takeProfitOrderLevel) {
                levels[1].takeProfitOrderLevel.setCapture(false)
            }
        }
    }

    protected onMouseLeaveControl(): void {
        for (const levels of this.owner.orderLevels) {
            if (levels[1].sellBuyConnectorLine) {
                levels[1].sellBuyConnectorLine.setIsMouseInside(false)
            }
        }
    }

    protected onMouseEnterControl(): void {
        for (const levels of this.owner.orderLevels) {
            if (levels[1].sellBuyConnectorLine) {
                levels[1].sellBuyConnectorLine.setIsMouseInside(true)
                this.chartWindow.controlsManager.HoverControl = this
            }
        }
    }

    public hide(): void {
        if (this.openOrderInfo) {
            this.openOrderInfo.coords = null
        }

        if (this.closeOrderInfo) {
            this.closeOrderInfo.coords = null
        }

        this.isHide = true
    }

    public show(): void {
        this.isHide = false
    }
}
