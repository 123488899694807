import * as Sentry from '@sentry/browser'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import StrangeErrorWithTopic from '@fto/lib/common/common_errors/StrangeErrorWithTopic'

// const DSN_OLD = 'https://e3ee7ef0ffca9c8cb10d0d724b432551@o4508336893329408.ingest.de.sentry.io/4508336895295568'
const DSN = import.meta.env.VITE_SENTRY_DSN

Sentry.init({
    dsn: DSN,
    environment: import.meta.env.VITE_ENV_TYPE,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        Sentry.breadcrumbsIntegration({
            console: true,
            dom: true,
            fetch: true,
            history: true,
            xhr: true
        })
        // Sentry.captureConsoleIntegration(
        //
        // {
        //     levels: ['error']
        //     // levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
        // }),

        // todo: because of canvas fingerprinting,
        //  we have poor performance, investigate real reason later
        // Sentry.httpClientIntegration(),
        // Sentry.replayIntegration({
        //     maskAllText: false
        // })
        // Sentry.replayCanvasIntegration()
    ],
    sendDefaultPii: true,

    beforeSend(event, hint) {
        event.extra = event.extra || {}

        const error = hint.originalException
        if (error instanceof StrangeErrorWithTopic) {
            event.tags = {
                ...event.tags,
                topic: error.topic
            }
        }

        if (performance && performance.memory) {
            const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } = performance.memory

            event.extra = {
                ...event.extra,
                memoryUsage: {
                    jsHeapSizeLimit: byteToMB(jsHeapSizeLimit),
                    totalJSHeapSize: byteToMB(totalJSHeapSize),
                    usedJSHeapSize: byteToMB(usedJSHeapSize),
                    unit: 'MB'
                }
            }
        } else {
            event.extra = {
                ...event.extra,
                memoryUsage: 'performance.memory API is not supported in this browser.'
            }
        }

        event.extra.logs = DebugUtils.Instance.getLogString()

        // todo: cut string here if it will be needed

        return event
    }
})

function byteToMB(byte: number) {
    return byte / 1024 / 1024
}
