import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, IconButton } from '@fto/icons'
import { DEFAULT_HOTKEYS, HotkeyGroup } from '@fto/lib/utils/Hotkeys'
import { highlightSearchText } from '@root/utils/highlightSearchText'
import { Button, EmptyState, Flex, InputField, Separator, Typography } from '@fto/ui'

import styles from './index.module.scss'

type Props = {
    closeTabs: () => void
}

const KeyboardShortcutsTab: FC<Props> = ({ closeTabs }) => {
    const [searchValue, setSearchValue] = useState('')
    const { t } = useTranslation()

    const handleClose = useCallback(() => {
        closeTabs()
    }, [closeTabs])

    const filteredHotkeys = useMemo(
        () =>
            searchValue === ''
                ? DEFAULT_HOTKEYS.filter((hotkey) => hotkey.group !== 'Debug')
                : DEFAULT_HOTKEYS.filter(
                      (hotkey) =>
                          hotkey.label.toLowerCase().includes(searchValue.toLowerCase()) && hotkey.group !== 'Debug'
                  ),
        [searchValue]
    )

    const groupedHotkeys = useMemo(
        () =>
            Object.entries(
                filteredHotkeys.reduce(
                    (acc, hotkey) => {
                        const { group, label, keyCode, isCtrl, isShift, isAlt, isMeta } = hotkey
                        if (!acc[group]) {
                            acc[group] = {}
                        }

                        // groups hotkeys and combines them if they have the same label
                        if (!acc[group][label]) {
                            acc[group][label] = {
                                label,
                                keyCodes: [],
                                modifiers: {
                                    isCtrl,
                                    isShift,
                                    isAlt,
                                    isMeta
                                }
                            }
                        }
                        keyCode && acc[group][label].keyCodes.push(keyCode)
                        return acc
                    },
                    {} as {
                        [key in HotkeyGroup]: { [label: string]: { label: string; keyCodes: string[]; modifiers: any } }
                    }
                )
            ),
        [filteredHotkeys]
    )

    const highlightedHotkeyName = useCallback(
        (name: string) => {
            return highlightSearchText(name, searchValue, 600)
        },
        [searchValue]
    )

    return (
        <Flex direction='column' className={styles.container} gap={12}>
            <Flex className={styles.header} justifyContent='space-between' alignItems='center'>
                <Typography type='h4-semibold'>{t('shortcuts.header')}</Typography>
                <Button
                    onClick={handleClose}
                    type='tetriatry-white'
                    size='compact'
                    disableFixedSize
                    label={<Icon name='close' color={'var(--color-gray-1000)'} className={styles.icon} size={16} />}
                />
            </Flex>
            <Separator width='100%' />
            <div className={styles.wrapper}>
                <InputField
                    value={searchValue}
                    onChange={setSearchValue}
                    block
                    prefix={<Icon name='search' size={13} />}
                    suffix={
                        searchValue ? <IconButton name='close' size={10} onClick={() => setSearchValue('')} /> : null
                    }
                    placeholder={t('general.search')}
                />
            </div>
            {filteredHotkeys.length > 0 ? (
                <Flex direction='column' className={styles.content}>
                    {groupedHotkeys.map(([group, hotkeys]) => (
                        <Flex direction='column' key={group} gap={8}>
                            <Typography className={styles.header} type='text-semibold'>
                                {group}
                            </Typography>
                            {Object.values(hotkeys).map((hotkey, index) => (
                                <>
                                    <Flex
                                        className={styles.hotkeyRow}
                                        justifyContent='space-between'
                                        key={hotkey.label}
                                        alignItems='center'
                                    >
                                        <Typography type='interface-medium'>
                                            {highlightedHotkeyName(hotkey.label)}
                                        </Typography>
                                        <Flex gap={4} justifyContent='center'>
                                            {hotkey.modifiers.isCtrl && (
                                                <Typography className={styles.hotkey}>{t('shortcuts.Ctrl')}</Typography>
                                            )}
                                            {hotkey.modifiers.isShift && (
                                                <Typography className={styles.hotkey}>
                                                    {t('shortcuts.Shift')}
                                                </Typography>
                                            )}
                                            {hotkey.modifiers.isAlt && (
                                                <Typography className={styles.hotkey}>{t('shortcuts.Alt')}</Typography>
                                            )}
                                            {hotkey.modifiers.isMeta && (
                                                <Typography className={styles.hotkey}>{t('shortcuts.Meta')}</Typography>
                                            )}
                                            {hotkey.keyCodes.map((keyCode, index) => (
                                                <Flex key={keyCode} alignItems='center' gap={4}>
                                                    <Typography className={styles.hotkey}>
                                                        {t(`shortcuts.${keyCode}`)}
                                                    </Typography>
                                                    {index < hotkey.keyCodes.length - 1 && <Typography>or</Typography>}
                                                </Flex>
                                            ))}
                                        </Flex>
                                    </Flex>
                                    {index < Object.values(hotkeys).length - 1 && (
                                        <Separator width='90%' color='gray-400' />
                                    )}
                                </>
                            ))}
                        </Flex>
                    ))}
                </Flex>
            ) : (
                <EmptyState title={t('shortcuts.emptyTitle')} description={t('shortcuts.emptyDescription')} />
            )}
        </Flex>
    )
}

export default KeyboardShortcutsTab
