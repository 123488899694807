import { DateUtils, TDateTime } from '../../../delphi_compatibility/DateUtils'
import {
    ServerDayChunkData_Ticks_JSON,
    ServerDayTickDataMSGPACK,
    TServerTicksOfASecondJSON,
    TServerTicksOfASecondMSGPACK
} from '../data_downloading/ServerDataClasses'
import CommonConstants from '@fto/lib/ft_types/common/CommonConstants'
import { IMockTickChunk, IMockTickRecord } from '@fto/lib/mocks/MocksForTesting/MockDataInterfaces'
import { TTickRecord } from '../DataClasses/TTickRecord'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'

export default class Ticks_converter {
    public static ConvertFromCSV_MT_ToMockTickChunks(ticks_csv: string): IMockTickChunk[] {
        const lines = ticks_csv.split('\n')
        const tickRecords: IMockTickRecord[] = []

        for (const line of lines) {
            const trimmedLine = line.trim()

            // Skip empty lines and comments
            if (trimmedLine === '' || trimmedLine.startsWith('//')) continue
            if (trimmedLine.startsWith('Year')) continue // Skip header line

            const fields = trimmedLine.split(',')

            // Ensure there are enough fields
            if (fields.length < 8) continue

            // Parse date and time components
            const year = parseInt(fields[0], 10)
            const month = parseInt(fields[1], 10)
            const day = parseInt(fields[2], 10)
            const hour = parseInt(fields[3], 10)
            const minute = parseInt(fields[4], 10)
            const second = parseInt(fields[5], 10)

            // Parse financial data
            const bid = parseFloat(fields[6])
            const ask = parseFloat(fields[7])

            // Parse volume if available
            let volume: number | undefined
            if (fields.length >= 9) {
                const volumeField = fields[8]
                if (volumeField !== undefined && volumeField !== '') {
                    volume = parseFloat(volumeField)
                }
            }

            // Create Date object in UTC to avoid timezone issues
            const dateTime = DateUtils.EncodeDate(year, month, day, hour, minute, second)

            const tickRecord: IMockTickRecord = {
                DateTime: dateTime,
                Ask: ask,
                Bid: bid
            }

            if (volume !== undefined) {
                tickRecord.Volume = volume
            }

            tickRecords.push(tickRecord)
        }

        // Group tick records by day
        const tickChunksMap: { [dateKey: string]: IMockTickRecord[] } = {}

        for (const tick of tickRecords) {
            const dateOnly = DateUtils.EncodeDate(
                DateUtils.YearOf(tick.DateTime),
                DateUtils.MonthOf(tick.DateTime),
                DateUtils.DayOfMonth(tick.DateTime)
            )
            const dateKey = DateUtils.FormatDateTime('YYYY-MM-DD', dateOnly)
            if (!tickChunksMap[dateKey]) {
                tickChunksMap[dateKey] = []
            }
            tickChunksMap[dateKey].push(tick)
        }

        // Create tick chunks for each day
        const tickChunks: IMockTickChunk[] = []
        for (const dateKey in tickChunksMap) {
            const ticks = tickChunksMap[dateKey]

            if (ticks.length > 0) {
                ticks.sort((a, b) => a.DateTime - b.DateTime)

                const chunk: IMockTickChunk = {
                    firstDateTime: DateUtils.GetDateWithNoTime(ticks[0].DateTime),
                    ticks: ticks
                }
                tickChunks.push(chunk)
            }
        }

        // Sort tick chunks by date
        tickChunks.sort((a, b) => a.firstDateTime - b.firstDateTime)

        return tickChunks
    }

    static ConvertFromMocksToTicks(data: IMockTickChunk): TTickRecord[] {
        const result: TTickRecord[] = []
        for (const mockTick of data.ticks) {
            result.push(this.ConvertMockTick_2_TTickRecord(mockTick, 'EURUSD'))
        }
        return result
    }

    static ConvertMockTick_2_TTickRecord(mockTick: IMockTickRecord, symbolName: string) {
        return new TTickRecord(mockTick.DateTime, mockTick.Bid, mockTick.Ask, 0)
    }

    static ConvertFromJSONToTicks(dataString: string, symbolName: string): TTickRecord[] {
        const serverTickDays: ServerDayChunkData_Ticks_JSON[] = JSON.parse(dataString)

        if (serverTickDays.length > 1) {
            // Data length mismatch
            throw new StrangeError('more than 1 day of data were downloaded for the chunk')
        }

        return Ticks_converter.ConvertServerTicksToTickRecordsJSON(serverTickDays[0], symbolName)
    }

    private static ConvertServerTicksToTickRecordsJSON(
        serverTicks: ServerDayChunkData_Ticks_JSON,
        symbolName: string
    ): TTickRecord[] {
        const fakeMmStart = 10 //to aviod having several ticks from different currencies at the same time
        const fakeMsIncrement = CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_MILLISECONDS * 2 //to aviod having several ticks from different currencies at the same time
        //10 is the smallest number because we do not have enough precision to go lower than that

        const result: TTickRecord[] = []
        for (const serverTicksOfASecond of serverTicks.Ticks) {
            result.push(
                ...Ticks_converter.ConvertServerTicksOfASecondJSON_2_TTickRecord(
                    serverTicksOfASecond,
                    serverTicks.Day,
                    fakeMmStart,
                    fakeMsIncrement,
                    symbolName
                )
            ) //TODO: optimize this?
        }
        return result
    }

    private static ConvertServerTicksOfASecondJSON_2_TTickRecord(
        serverTicksOfASecond: TServerTicksOfASecondJSON,
        dayStart: number,
        firstTickOffset: number,
        fakeMsIncrement: number,
        symbolName: string
    ): TTickRecord[] {
        const result: TTickRecord[] = []

        //FIXME: temprorary solution to avoid having several ticks from different currencies at the same time
        //TODO: change this once the server has ticks in milliseconds
        let fakeMilliseconds = firstTickOffset
        let millisecondsIncrement = fakeMsIncrement //add a fake 20 milliseconds to the time to make sure that ticks are not on top of each other.
        //if we add just 1 millisecond, then we do not have enough precision of the DateTime type to distinguish ticks that are on top of each other

        const startTimeOfThisSecondInMilliseconds = (serverTicksOfASecond.Time + dayStart) * 1000
        const symbolInfo = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbolName).symbolInfo

        for (const serverTick of serverTicksOfASecond.Ticks) {
            const tickDateTime: TDateTime = DateUtils.fromUnixTimeMilliseconds(
                startTimeOfThisSecondInMilliseconds + fakeMilliseconds
            )
            fakeMilliseconds += millisecondsIncrement
            if (fakeMilliseconds > 700) {
                DebugUtils.error(`too many ticks in a second 1 ${tickDateTime}`)
                millisecondsIncrement = 5 //reduce the increment to have more ticks in a second.
            }

            if (fakeMilliseconds >= 1000) {
                DebugUtils.error(`too many ticks in a second 2 ${tickDateTime}`)
                continue
            }

            if (symbolInfo.UseFloatingSpread) {
                result.push(new TTickRecord(tickDateTime, serverTick.Bid, serverTick.Ask, 0))
            } else {
                const ask = serverTick.Bid + Number(symbolInfo.spreadAsPriceValue)
                result.push(new TTickRecord(tickDateTime, ask, serverTick.Bid, 0))
            }
        }
        return result
    }

    static ConvertServerTicksToTickRecordsMSGPACK(
        serverTicks: ServerDayTickDataMSGPACK,
        symbolName: string
    ): TTickRecord[] {
        const fakeMmStart = 10 //to aviod having several ticks from different currencies at the same time
        const fakeMsIncrement = CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_MILLISECONDS * 2 //to aviod having several ticks from different currencies at the same time
        //10 is the smallest number because we do not have enough precision to go lower than that

        const result: TTickRecord[] = []
        for (const serverTicksOfASecond of serverTicks.t) {
            result.push(
                ...Ticks_converter.ConvertServerTicksOfASecondMSGPACK_2_TTickRecord(
                    serverTicksOfASecond,
                    serverTicks.d,
                    fakeMmStart,
                    fakeMsIncrement,
                    symbolName
                )
            ) //TODO: optimize this?
        }
        return result
    }

    private static ConvertServerTicksOfASecondMSGPACK_2_TTickRecord(
        serverTicksOfASecond: TServerTicksOfASecondMSGPACK,
        dayStart: number,
        firstTickOffset: number,
        fakeMsIncrement: number,
        symbolName: string
    ): TTickRecord[] {
        const result: TTickRecord[] = []
        //TODO: change this when the server is able to give us the data in the correct format (ticks in milliseconds)
        let fakeMilliseconds = firstTickOffset
        let millisecondsIncrement = fakeMsIncrement //add a fake 20 milliseconds to the time to make sure that ticks are not on top of each other.
        //if we add just 1 millisecond, then we do not have enough precision of the DateTime type to distinguish ticks that are on top of each other

        const symbolInfo = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbolName).symbolInfo

        const startTimeOfThisSecondInMilliseconds = (serverTicksOfASecond.t + dayStart) * 1000
        for (const serverTick of serverTicksOfASecond.d) {
            const tickDateTime: TDateTime = DateUtils.fromUnixTimeMilliseconds(
                startTimeOfThisSecondInMilliseconds + fakeMilliseconds
            )
            fakeMilliseconds += millisecondsIncrement
            if (fakeMilliseconds >= 700) {
                DebugUtils.error(`too many ticks in a second 3 ${tickDateTime}`)
                millisecondsIncrement = 10 //reduce the increment to have more ticks in a second.
            }
            if (symbolInfo.UseFloatingSpread) {
                //TODO: should we get volume somewhere?
                result.push(new TTickRecord(tickDateTime, Number(serverTick.b), Number(serverTick.a), 0))
            } else {
                const ask = Number(serverTick.b) + Number(symbolInfo.spreadAsPriceValue)
                result.push(new TTickRecord(tickDateTime, ask, Number(serverTick.b), 0))
            }
        }
        return result
    }

    private static ConvertServerTicksOfASecondBINARY_TTickRecord(
        serverTicksOfASecond: any,
        dayStart: number,
        firstTickOffset: number,
        fakeMsIncrement: number
    ): TTickRecord[] {
        const result: TTickRecord[] = []
        let fakeMilliseconds = firstTickOffset
        let millisecondsIncrement = fakeMsIncrement

        const startTimeOfThisSecondInMilliseconds = (serverTicksOfASecond.time + dayStart) * 1000
        for (const serverTick of serverTicksOfASecond.ticks) {
            const tickDateTime: TDateTime = DateUtils.fromUnixTimeMilliseconds(
                startTimeOfThisSecondInMilliseconds + fakeMilliseconds
            )
            fakeMilliseconds += millisecondsIncrement
            if (fakeMilliseconds >= 700) {
                DebugUtils.error(`too many ticks in a second 4 ${tickDateTime}`)
                millisecondsIncrement = 10 //reduce the increment to have more ticks in a second.
            }

            result.push(new TTickRecord(tickDateTime, Number(serverTick.bid), Number(serverTick.ask), 0))
        }
        return result
    }

    static ConvertServerTicksToTickRecordsBINARY(serverTicks: any): TTickRecord[] {
        const fakeMmStart = 10
        const fakeMsIncrement = CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_MILLISECONDS * 2

        const result: TTickRecord[] = []
        for (const serverTicksOfASecond of serverTicks[0].ticks) {
            result.push(
                ...Ticks_converter.ConvertServerTicksOfASecondBINARY_TTickRecord(
                    serverTicksOfASecond,
                    serverTicks[0].day,
                    fakeMmStart,
                    fakeMsIncrement
                )
            )
        }
        return result
    }
}
