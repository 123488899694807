import { Button, Flex, Modal, Typography, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { FC, useCallback } from 'react'

import { t } from 'i18next'
import { Icon } from '@fto/icons'

import styles from './index.module.scss'

type Props = {
    onCancel: () => void
    onApply: () => void
}

export const ApplyThemeChangingModal: FC<Props> = ({ onCancel, onApply }) => {
    const onReject = useCallback(() => {
        onCancel()
        removeModal(MODAL_NAMES.all.applyThemeChanging)
    }, [onCancel])

    const onAgree = useCallback(() => {
        onApply()
        removeModal(MODAL_NAMES.all.applyThemeChanging)
    }, [onApply])

    return (
        <Modal size='xs' name={MODAL_NAMES.all.applyThemeChanging} withClickOutside={false}>
            <Modal.Content>
                <Flex direction='column' alignItems='center' gap={12}>
                    <Icon name='attention-colored' size={56} />
                    <Typography type='h5-semibold' color='gray-1000'>
                        {t('applyThemeChangingModal.title')}
                    </Typography>
                    <Typography type='interface-regular' align='center'>
                        {t('applyThemeChangingModal.question')}
                    </Typography>
                </Flex>
            </Modal.Content>
            <Modal.Controls className={styles.Controls}>
                <div className={styles.Grid}>
                    <Button type='secondary' label={t('applyThemeChangingModal.no')} onClick={onReject} block />
                    <Button type='primary' label={t('applyThemeChangingModal.yes')} onClick={onAgree} block />
                </div>
            </Modal.Controls>
        </Modal>
    )
}
