import { RefObject } from 'react'

export const calculatePosition = (
    mouseX: number,
    mouseY: number,
    startPosition: { x: number; y: number },
    defaultElemX: number,
    defaultElemY: number,
    boundaries: DOMRect
) => {
    let newX = mouseX - startPosition.x
    let newY = mouseY - startPosition.y

    if (boundaries.right >= defaultElemX) {
        newX = newX > defaultElemX - boundaries.width ? defaultElemX - boundaries.width : newX
    }
    if (boundaries.left <= 0) {
        newX = newX < 0 ? 0 : newX
    }
    if (boundaries.bottom >= defaultElemY) {
        newY = newY > defaultElemY - boundaries.height ? defaultElemY - boundaries.height : newY
    }
    if (boundaries.top <= 0) {
        newY = newY < 0 ? 0 : newY
    }

    return { x: newX, y: newY }
}

export const MIN_INDENTS = {
    left: 30,
    right: 10,
    top: 0,
    bottom: 0
}

export const getElementWindowPositionData = (element: RefObject<HTMLDivElement>) => {
    if (!element.current) {
        return {
            isOutOfRightSide: false,
            isOutOfBottomSide: false,
            isOutOfLeftSide: false,
            isOutOfTopSide: false,
            elementDataRect: {}
        }
    }
    const elementDataRect = element.current.getBoundingClientRect()

    const isOutOfRightSide = window.innerWidth - elementDataRect.right < 0
    const isOutOfBottomSide = window.innerHeight - elementDataRect.bottom < 0
    const isOutOfLeftSide = elementDataRect.left < MIN_INDENTS.left
    const isOutOfTopSide = elementDataRect.top < MIN_INDENTS.top

    return { isOutOfRightSide, isOutOfBottomSide, isOutOfLeftSide, isOutOfTopSide, elementDataRect }
}
