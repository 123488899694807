import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, EmptyState, Flex, InputField, Modal, removeModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import GlobalTestingManager from '@fto/lib/globals/GlobalTestingManager'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'

import SortButton from './components/SortButton'
import CategoryDropdown from './components/CategoryDropdown'
import { HISTORICAL_EVENTS } from './constants'
import { HistoricalEvent, OrderBy, SortType } from './types'

import styles from './index.module.scss'
import { usePersistedState } from '@root/hooks/usePersistedState'
import useLocalStorageKey from '@root/hooks/useLocalStorageKey'

const HistoricalMomentsModal: FC = () => {
    const { t } = useTranslation()

    const sortingKey = useLocalStorageKey('historicalMomentsSortingPreferences')
    const [sortingPreferences, setSortingPreferences] = usePersistedState(sortingKey, {
        sortType: 'alphabetical' as SortType,
        orderBy: 'asc' as OrderBy
    })
    const [searchValue, setSearchValue] = useState('')
    const [activeEvent, setActiveEvent] = useState('')

    const groups = useMemo(() => {
        const filteredEvents =
            searchValue === ''
                ? HISTORICAL_EVENTS
                : HISTORICAL_EVENTS.filter(
                      (item) =>
                          t(`historicalMomentsModal.name.${item.key}`)
                              .toLowerCase()
                              .includes(searchValue.toLowerCase()) ||
                          t(`historicalMomentsModal.description.${item.key}`)
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                  )

        const groupedEvents = filteredEvents.reduce<Record<string, HistoricalEvent[]>>((acc, item) => {
            const key = item.group || 'other'
            if (!acc[key]) acc[key] = []
            acc[key].push(item)
            return acc
        }, {})

        const isAscending = sortingPreferences.orderBy === 'asc'
        const isAlphabetical = sortingPreferences.sortType === 'alphabetical'

        Object.keys(groupedEvents).forEach((key) => {
            groupedEvents[key].sort((a, b) => {
                if (isAlphabetical) {
                    const nameA = t('historicalMomentsModal.name.' + a.key)
                    const nameB = t('historicalMomentsModal.name.' + b.key)
                    return isAscending ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA)
                }
                return isAscending
                    ? new Date(a.date).getTime() - new Date(b.date).getTime()
                    : new Date(b.date).getTime() - new Date(a.date).getTime()
            })
        })

        return groupedEvents
    }, [sortingPreferences, searchValue])

    const groupsKeys = useMemo(() => Object.keys(groups), [groups])
    const groupsOpenKey = useLocalStorageKey('historicalMomentsGroupsOpen')
    const [groupsOpen, setGroupsOpen] = usePersistedState(
        groupsOpenKey,
        Object.fromEntries(groupsKeys.map((key) => [key, true]))
    )

    useEffect(() => {
        if (searchValue === '') return
        setGroupsOpen(Object.fromEntries(groupsKeys.map((key) => [key, true])))
    }, [groupsKeys, searchValue])

    const jumpToMoment = useCallback(() => {
        const event = HISTORICAL_EVENTS.find((event) => event.key === activeEvent)
        if (!event) return
        const date = DateUtils.fromUnixTimeMilliseconds(new Date(`${event.date} GMT`).getTime())

        GlobalTestingManager.TestingManager.RollTestingTo(date)

        removeModal(MODAL_NAMES.chart.historicalMoments)
    }, [activeEvent])

    const handleClose = useCallback(() => {
        removeModal(MODAL_NAMES.chart.historicalMoments)
    }, [])

    return (
        <Modal name={MODAL_NAMES.chart.historicalMoments} withCloseIcon size='lg'>
            <Modal.Header withBorderBottom>{t('historicalMomentsModal.title')}</Modal.Header>
            <Modal.Content className={styles.container} withBorderBottom>
                <Flex direction='column' gap={8}>
                    <Flex gap={8} alignItems='center'>
                        <InputField
                            value={searchValue}
                            onChange={setSearchValue}
                            reset
                            withSearchSuffix
                            placeholder={t('general.search')}
                            className={styles.searchInput}
                            block
                            id='historical-moment-search'
                        />
                        <SortButton
                            sortingPreferences={sortingPreferences}
                            setSortingPreferences={setSortingPreferences}
                        />
                    </Flex>
                    <Flex direction='column' gap={8} className={styles.content}>
                        {groupsKeys.length > 0 ? (
                            <>
                                {groupsKeys.map((group) => (
                                    <CategoryDropdown
                                        key={group}
                                        label={group}
                                        events={groups[group] as HistoricalEvent[]}
                                        activeEvent={activeEvent}
                                        setActiveEvent={setActiveEvent}
                                        groupsOpen={groupsOpen}
                                        setGroupsOpen={setGroupsOpen}
                                        searchValue={searchValue}
                                    />
                                ))}
                            </>
                        ) : (
                            <EmptyState
                                title={t('historicalMomentsModal.emptyTitle')}
                                description={t('historicalMomentsModal.emptyDescription')}
                            />
                        )}
                    </Flex>
                </Flex>
            </Modal.Content>
            <Modal.Controls>
                <Button
                    label={t('global.cancel')}
                    onClick={handleClose}
                    type='secondary'
                    classNames={{ content: styles.cancel }}
                />
                <Button
                    onClick={jumpToMoment}
                    label={t('historicalMomentsModal.jumpto')}
                    disabled={activeEvent === ''}
                    withTooltip={activeEvent === ''}
                    tooltipText={t('historicalMomentsModal.choose')}
                    tooltipPlacement='top'
                    shouldShowTooltipArrow={false}
                />
            </Modal.Controls>
        </Modal>
    )
}

export default HistoricalMomentsModal
