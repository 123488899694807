import { fetchAllGlobalSettings, updateGlobalSettings } from '@root/utils/api'
import { DebugUtils } from '../utils/DebugUtils'
import StrangeError from '../common/common_errors/StrangeError'
import GlobalOptions from './GlobalOptions'
import debounce from 'lodash/debounce'
import { ELoggingTopics } from '../utils/DebugEnums'

export default class GlobalSettings {
    private shouldUpdateSettings = true
    private static instance: GlobalSettings

    public static get Instance(): GlobalSettings {
        if (!GlobalSettings.instance) {
            GlobalSettings.instance = new GlobalSettings()
        }

        return GlobalSettings.instance
    }

    public async DownloadGlobalSettings() {
        try {
            const { data, status } = await fetchAllGlobalSettings()

            if (status === 200) {
                GlobalOptions.Options.ApplyGlobalOptions(data)
            } else {
                DebugUtils.logTopic(ELoggingTopics.lt_Settings, 'General settings not found. Using default settings.')
            }
            this.shouldUpdateSettings = true
        } catch (error: any) {
            this.shouldUpdateSettings = false

            if ('status' in error) {
                throw new StrangeError(error.message || 'General settings. Error message not provided.')
            } else {
                DebugUtils.error('Network error or no response from server:', error)
                throw new StrangeError('Network error or no response from server.')
            }
        }
    }

    public updateGlobalSettingsRequest = debounce(this._updateGlobalSettingsRequest, 1500)
    public updateGlobalSettingsRequestStrict = this._updateGlobalSettingsRequest

    private async _updateGlobalSettingsRequest(): Promise<void> {
        if (!this.shouldUpdateSettings) return Promise.resolve()

        try {
            await updateGlobalSettings(GlobalOptions.Options.GlobalOptionsToJSON())
        } catch (error: any) {
            // Change requests error response format to status code
            if ('message' in error) {
                throw new StrangeError(error.message)
            } else {
                DebugUtils.error('Network error or no response from server:', error)
                throw new StrangeError('Network error or no response from server.')
            }
        }
    }
}
