import { FC, useCallback, useEffect } from 'react'

import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { ContextMenu, removeContextMenu } from '@fto/ui'
import { CONTEXT_MENU_NAMES } from '@root/constants/contextMenuNames'

import ChartMenuContent from '../components/ChartMenuContent'
import { AVAILABLE_TABS } from '@root/pages/ChartPage/components/SettingsTab/types/tabs.types'
import { TabsSettingsType } from '@fto/chart_components/ProjectInterface/types'

type Props = {
    anchorX: number
    anchorY: number
    additionalProps: {
        chart: TChartWindow
    }
    openTab: (val: TabsSettingsType ) => void
}

const ChartContextMenu: FC<Props> = ({ anchorX, anchorY, additionalProps, openTab }) => {
    const removeMenu = useCallback(() => {
        removeContextMenu(CONTEXT_MENU_NAMES.chart)
        GlobalChartsController.Instance.enableMouseEvents()
    }, [])

    useEffect(() => {
        return () => {
            removeMenu()
        }
    }, [removeMenu])

    return (
        <ContextMenu name={CONTEXT_MENU_NAMES.chart} anchorX={anchorX} anchorY={anchorY}>
            <ChartMenuContent chart={additionalProps.chart} removeMenu={removeMenu} openTab={openTab} />
        </ContextMenu>
    )
}

export default ChartContextMenu
