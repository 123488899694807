import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import { IDateTimeProcRec } from '../CommonProcRecInterfaces/IDateTimeProcRec'
import GlobalProjectInfo from '@fto/lib/globals/GlobalProjectInfo'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'
import { FTODate, TimeUnit } from '@fto/lib/FTODate/FTODate'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'

export class DateTimeProcRecImplementation extends BasicProcRecImplementation implements IDateTimeProcRec {
    public GetImplementation(): IDateTimeProcRec {
        return {
            GetDayOfMonthByDateTime: this.GetDayOfMonthByDateTime.bind(this),
            GetWeekByDateTime: this.GetWeekByDateTime.bind(this),
            GetMonthByDateTime: this.GetMonthByDateTime.bind(this),
            GetYearByDateTime: this.GetYearByDateTime.bind(this),
            TimeCurrent: this.TimeCurrent.bind(this),
            Day: this.Day.bind(this),
            DayOfWeek: this.DayOfWeek.bind(this),
            DayOfYear: this.DayOfYear.bind(this),
            Hour: this.Hour.bind(this),
            Minute: this.Minute.bind(this),
            Month: this.Month.bind(this),
            Second: this.Second.bind(this),
            Year: this.Year.bind(this),
            StructToTime: this.StructToTime.bind(this),
            TimeDay: this.TimeDay.bind(this),
            TimeDaylightSavings: this.TimeDaylightSavings.bind(this),
            TimeDayOfWeek: this.TimeDayOfWeek.bind(this),
            TimeDayOfYear: this.TimeDayOfYear.bind(this),
            TimeGMT: this.TimeGMT.bind(this),
            TimeLocal: this.TimeLocal.bind(this),
            TimeGMTOffset: this.TimeGMTOffset.bind(this),
            TimeHour: this.TimeHour.bind(this),
            TimeMinute: this.TimeMinute.bind(this),
            TimeMonth: this.TimeMonth.bind(this),
            TimeSeconds: this.TimeSeconds.bind(this),
            TimeYear: this.TimeYear.bind(this)
        }
    }

    protected override generateDName(): string {
        return `API_DateTime_${super.generateDName()}`
    }

    public GetDayOfMonthByDateTime(ftoDate: FTODate): number {
        return ftoDate.dayOfMonth()
    }

    public GetMonthByDateTime(ftoDate: FTODate): number {
        return ftoDate.monthOf()
    }

    public GetWeekByDateTime(ftoDate: FTODate): number {
        return ftoDate.weekOf()
    }

    public GetYearByDateTime(ftoDate: FTODate): number {
        return ftoDate.yearOf()
    }

    public TimeCurrent(): FTODate {
        const timeInMs = DateUtils.toUnixTimeMilliseconds(GlobalProjectInfo.ProjectInfo.GetLastProcessedTickTime(false))
        return new FTODate(timeInMs, TimeUnit.MILLISECONDS)
    }

    public Day(): number {
        return this.TimeCurrent().dayOfMonth()
    }

    public DayOfWeek(): number {
        return this.TimeCurrent().dayOfWeek()
    }

    public DayOfYear(): number {
        return this.TimeCurrent().dayOfYear()
    }

    public Hour(): number {
        return this.TimeCurrent().hour()
    }

    public Minute(): number {
        return this.TimeCurrent().minute()
    }

    public Month(): number {
        return this.TimeCurrent().monthOf()
    }

    public Second(): number {
        return this.TimeCurrent().second()
    }

    public Year(): number {
        return this.TimeCurrent().yearOf()
    }

    public StructToTime(ftoDate: FTODate): number {
        return ftoDate.valueOf()
    }

    public TimeDay(unixTimeSeconds: number): number {
        return new FTODate(unixTimeSeconds, TimeUnit.SECONDS).dayOfMonth()
    }

    public TimeDaylightSavings(): number {
        return GlobalTimezoneDSTController.Instance.getTimeZoneSeconds()
    }

    public TimeDayOfWeek(unixTimeSeconds: number): number {
        return new FTODate(unixTimeSeconds, TimeUnit.SECONDS).dayOfWeek()
    }

    public TimeDayOfYear(unixTimeSeconds: number): number {
        return new FTODate(unixTimeSeconds, TimeUnit.SECONDS).dayOfYear()
    }

    public TimeGMT(): number {
        return this.TimeCurrent().valueOf()
    }

    public TimeLocal(): number {
        return this.TimeCurrent().valueOf()
    }

    public TimeGMTOffset(): number {
        return this.TimeGMT() - this.TimeLocal()
    }

    public TimeHour(unixTimeSeconds: number): number {
        return new FTODate(unixTimeSeconds, TimeUnit.SECONDS).hour()
    }

    public TimeMinute(unixTimeSeconds: number): number {
        return new FTODate(unixTimeSeconds, TimeUnit.SECONDS).minute()
    }

    public TimeMonth(unixTimeSeconds: number): number {
        return new FTODate(unixTimeSeconds, TimeUnit.SECONDS).monthOf()
    }

    public TimeSeconds(unixTimeSeconds: number): number {
        return new FTODate(unixTimeSeconds, TimeUnit.SECONDS).second()
    }

    public TimeYear(unixTimeSeconds: number): number {
        return new FTODate(unixTimeSeconds, TimeUnit.SECONDS).yearOf()
    }

    public TimeToStruct(unixTimeSeconds: number): FTODate {
        return new FTODate(unixTimeSeconds, TimeUnit.SECONDS)
    }
}
