import { FC, useEffect } from 'react'

import styles from './index.module.scss'
import { Flex, Typography } from '@fto/ui'
import { useTranslation } from 'react-i18next'
import ProjectInfoStore from '@fto/lib/store/projectInfoStore'
import { observer } from 'mobx-react-lite'

interface Props {}

const ProjectSettingsTab: FC<Props> = () => {
    const { t } = useTranslation()
    const { data } = ProjectInfoStore

    return (
        <Flex className={styles.scrollableContainer}>
            <Flex direction='column' gap={16} className={styles.content} block>
                {/* TIMEZONE */}
                <Typography type='text-semibold' block>
                    {t('settings.project.timeZone')}
                </Typography>
                <Flex justifyContent='space-between'>
                    <Typography type='interface-regular'>{t('settings.project.timezone')}:</Typography>
                    <Typography type='interface-medium'>{data.timeZone}</Typography>
                </Flex>
                <Flex justifyContent='space-between'>
                    <Typography type='interface-regular'>{t('settings.project.sessionCloseTime')}:</Typography>
                    <Typography type='interface-medium'>{data.sessionCloseTime}</Typography>
                </Flex>
                <Flex justifyContent='space-between'>
                    <Typography type='interface-regular'>{t('settings.project.daylightSavingTime')}:</Typography>
                    <Typography type='interface-medium'>{data.daylightSavingTime} DST</Typography>
                </Flex>

                {/* BALANCE */}
                <Typography type='text-semibold' block>
                    {t('settings.project.balance')}
                </Typography>
                <Flex justifyContent='space-between' alignItems='center'>
                    <Typography type='interface-regular'>${data.initialBalance}</Typography>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default observer(ProjectSettingsTab)
