import { ChartControl } from '@fto/chart_components/ChartControl'
import { SelectableControl } from '@fto/lib/processing_core/TradePositionClasses/SelectableControl'
import { OrderLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/OrderLevel'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { MarketLevel } from '@fto/lib/processing_core/TradePositionClasses/OrderLevels/MarketLevel'
import { isCtrlEvent } from '@fto/lib/utils/eventsUtils'
import IMainChart from '@fto/lib/charting/chart_classes/IMainChart'
import IControlsManager from './IControlsManager'

export class ControlsManager implements IControlsManager {
    private controls: ChartControl[] = []
    private capturedControl: ChartControl | null = null
    private hoveredControl: ChartControl | null = null
    private selectedControls: ChartControl[] = []
    public isModalOpened = false

    public deselectAllControls(): void {
        for (const control of this.selectedControls) {
            if (control instanceof SelectableControl) {
                control.onDeselect()
            }
        }

        this.selectedControls = []
    }

    private selectControl(control: ChartControl, multiSelect: boolean): void {
        if (!(control instanceof SelectableControl)) {
            return
        }

        if (multiSelect) {
            if (this.selectedControls.includes(control)) {
                this.selectedControls = this.selectedControls.filter((c) => c !== control)
                control.onDeselect()
                GlobalChartsController.Instance.clearSelectedControls()
            } else {
                this.selectedControls.push(control)
                control.onSelect(multiSelect)
            }
        } else {
            this.deselectAllControls()
            GlobalChartsController.Instance.clearSelectedControls()
            this.selectedControls.push(control)
            control.onSelect(multiSelect)
        }
    }

    addControl(control: ChartControl): void {
        this.controls.push(control)
    }

    removeControl(control: ChartControl): void {
        const index = this.controls.indexOf(control)
        if (index >= 0) {
            this.hoveredControl = null
            this.selectedControls = []
            this.capturedControl = null

            this.controls.splice(index, 1)
        }
    }

    getControls(): ChartControl[] {
        return this.controls
    }

    clear(): void {
        this.controls = []
        this.hoveredControl = null
        this.selectedControls = []
        this.capturedControl = null
    }

    get HoveredControl(): ChartControl | null {
        return this.hoveredControl
    }

    get SelectedControls(): ChartControl[] {
        return this.selectedControls
    }

    private mouseToLocal(event: MouseEvent, sender: IMainChart): MouseEvent {
        const dpr = window.devicePixelRatio || 1
        const rect = sender.HTML_Canvas.getBoundingClientRect()
        const x = event.clientX - rect.left
        const y = event.clientY - rect.top
        return new MouseEvent(event.type, {
            metaKey: event.metaKey,
            shiftKey: event.shiftKey,
            ctrlKey: event.ctrlKey,
            clientX: x * dpr,
            clientY: y * dpr,
            movementY: event.movementY,
            movementX: event.movementX
        })
    }

    onMouseMove(event: MouseEvent, sender: IMainChart): ChartControl | null {
        let result: ChartControl | null = null

        if (this.isModalOpened) {
            return result
        }

        if (this.capturedControl) {
            this.capturedControl.onMouseMove(this.mouseToLocal(event, sender), sender)
            return this.capturedControl
        }

        for (let i = 0; i < this.controls.length; i++) {
            result = this.controls[i].onMouseMove(this.mouseToLocal(event, sender), sender)
            if (result) {
                if (this.hoveredControl && this.hoveredControl !== result) {
                    this.hoveredControl.onMouseLeave(this.mouseToLocal(event, sender))
                }
                this.hoveredControl = result
                if (this.hoveredControl instanceof OrderLevel) {
                    this.hoveredControl.syncLevelsInsideOwner(true, false)
                }
                return result
            }

            if (this.controls[i] instanceof OrderLevel) {
                const control = this.controls[i] as OrderLevel
                result = (this.controls[i] as OrderLevel).closeBtn?.onMouseMove(
                    this.mouseToLocal(event, sender),
                    sender
                ) as ChartControl | null

                if (!result) {
                    result = (this.controls[i] as MarketLevel).modifyBtn?.onMouseMove(
                        this.mouseToLocal(event, sender),
                        sender
                    ) as ChartControl | null
                }

                if (result) {
                    if (this.hoveredControl && this.hoveredControl !== control) {
                        this.hoveredControl.onMouseLeave(this.mouseToLocal(event, sender))
                    }
                    control.syncLevelsInsideOwner(true, false)
                    this.hoveredControl = control
                    return control
                }
            }
        }

        if (!result && this.hoveredControl) {
            this.hoveredControl.onMouseLeave(this.mouseToLocal(event, sender))
            this.hoveredControl = null
            GlobalChartsController.Instance.clearHoveredControls()
        }

        return result
    }

    onMouseDown(event: MouseEvent, sender: IMainChart): ChartControl | null {
        let result: ChartControl | null = null
        if (this.isModalOpened) {
            return result
        }

        for (let i = 0; i < this.controls.length; i++) {
            result = this.controls[i].onMouseDown(this.mouseToLocal(event, sender), sender)
            if (result) {
                break
            }
        }
        const multiSelect = isCtrlEvent(event)
        const skipDeselect = result && result instanceof SelectableControl && multiSelect

        if (result) {
            if (this.capturedControl && this.capturedControl !== result) {
                this.capturedControl.ReleaseCapture()
            }
            this.capturedControl = result
        } else {
            if (this.capturedControl) {
                this.capturedControl.ReleaseCapture()
                this.capturedControl = null
            }
        }

        if (result) {
            if (!skipDeselect) {
                this.deselectAllControls()
            }
            this.selectControl(result, multiSelect)
        } else {
            this.deselectAllControls()
        }

        return result
    }

    onMouseUp(event: MouseEvent, sender: IMainChart): void {
        for (let i = 0; i < this.controls.length; i++) {
            this.controls[i].onMouseUp(event, sender)
        }
        this.capturedControl?.ReleaseCapture()
        this.capturedControl = null
    }

    onMouseLeave(event: MouseEvent): void {}

    hasControl(control: ChartControl): boolean {
        return this.controls.includes(control)
    }

    set HoverControl(control: ChartControl | null) {
        this.hoveredControl = control
    }

    set CaptureControl(control: ChartControl | null) {
        this.capturedControl = control
    }

    onDblClick(event: MouseEvent, sender: IMainChart): ChartControl | null {
        let result: ChartControl | null = null

        if (this.isModalOpened) {
            return result
        }

        for (let i = 0; i < this.controls.length; i++) {
            result = this.controls[i].onDblClick(this.mouseToLocal(event, sender), sender)
            if (result) {
                break
            }
        }

        return result
    }
}
