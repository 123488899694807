import { FC, useCallback, useMemo } from 'react'
import { t } from 'i18next'

import { FilenameTypes, Icon } from '@fto/icons'
import { Flex, Typography, Checkbox } from '@fto/ui'
import { highlightSearchText } from '@root/utils/highlightSearchText'

import styles from './index.module.scss'

type Props = {
    localeKey: string
    iconName: string
    countryName: string
    isSelected: boolean
    toggleCountry: (name: string) => void
    searchValue: string
}

const Row: FC<Props> = ({ localeKey, iconName, isSelected, toggleCountry, countryName, searchValue }) => {
    const toggleSelectedState = useCallback(() => {
        toggleCountry(countryName)
    }, [countryName, toggleCountry])

    const highlightedCountryName = useMemo(
        () => highlightSearchText(t(localeKey), searchValue, 600),
        [localeKey, searchValue]
    )

    return (
        <Flex alignItems='center' gap={8} className={styles.Row} onClick={toggleSelectedState}>
            <Checkbox checked={isSelected} />
            <Icon name={iconName as (typeof FilenameTypes)[number]} size={18} />
            <Typography type='interface-medium' color='gray-1000'>
                {highlightedCountryName}
            </Typography>
        </Flex>
    )
}

export default Row
