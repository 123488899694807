import { BasicProcRecImplementation } from '@fto/lib/extension_modules/common/CommonProcRecImplementations/BasicProcRecImplementation'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import {
    ENUM_DAY_OF_WEEK,
    ENUM_SYMBOL_INFO_DOUBLE,
    ENUM_SYMBOL_INFO_INTEGER,
    ENUM_SYMBOL_INFO_STRING,
    ENUM_SYMBOL_TRADE_EXECUTION,
    MODE_ASK,
    MODE_BID,
    MODE_CLOSEBY_ALLOWED,
    MODE_DIGITS,
    MODE_EXPIRATION,
    MODE_FREEZELEVEL,
    MODE_HIGH,
    MODE_LOTSIZE,
    MODE_LOTSTEP,
    MODE_LOW,
    MODE_MARGINCALCMODE,
    MODE_MARGINHEDGED,
    MODE_MARGININIT,
    MODE_MARGINMAINTENANCE,
    MODE_MARGINREQUIRED,
    MODE_MAXLOT,
    MODE_MINLOT,
    MODE_POINT,
    MODE_PROFITCALCMODE,
    MODE_SPREAD,
    MODE_STARTING,
    MODE_STOPLEVEL,
    MODE_SWAPLONG,
    MODE_SWAPSHORT,
    MODE_SWAPTYPE,
    MODE_TICKSIZE,
    MODE_TICKVALUE,
    MODE_TRADEALLOWED,
    MQLTick,
    SwapTypes,
    TradeErrorCode,
    TradeErrorMessages
} from '@fto/lib/extension_modules/common/CommonConstantsForExternalModules'
import WatchlistStore from '@fto/lib/store/watchlistStore'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { ConversionProcRecImplementation } from '@fto/lib/extension_modules/common/CommonProcRecImplementations/ConversionProcRecImplementation'
import { IProcRecsEveryImplementationNeeds } from '@fto/lib/extension_modules/common/CommonProcRecImplementations/IProcRecsEveryImplementationNeeds'
import { TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { IMarketInfoProcRec } from '@fto/lib/extension_modules/common/CommonProcRecInterfaces/IMarketInfoProcRec'
import { NotImplementedError } from '@fto/lib/utils/common_utils'
import { TTradePositionType } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import GlobalStrategiesManager from '@fto/lib/globals/Strategies/GlobalStrategiesManager'
import { FTODate } from '@fto/lib/FTODate/FTODate'

export class MarketInfoProcRecImplementation extends BasicProcRecImplementation {
    private conversionProcRec: ConversionProcRecImplementation

    constructor(procRecsEveryoneNeeds: IProcRecsEveryImplementationNeeds) {
        super(procRecsEveryoneNeeds)

        this.conversionProcRec = new ConversionProcRecImplementation(procRecsEveryoneNeeds)
    }

    public GetImplementation(): IMarketInfoProcRec {
        return {
            MarketInfo: this.MarketInfo.bind(this),
            SymbolsTotal: this.SymbolsTotal.bind(this),
            SymbolName: this.SymbolName.bind(this),
            SymbolSelect: this.SymbolSelect.bind(this),
            SymbolInfoDouble: this.SymbolInfoDouble.bind(this),
            SymbolInfoInteger: this.SymbolInfoInteger.bind(this),
            SymbolInfoString: this.SymbolInfoString.bind(this),
            SymbolInfoTick: this.SymbolInfoTick.bind(this),
            SymbolInfoSessionQuote: this.SymbolInfoSessionQuote.bind(this),
            SymbolInfoSessionTrade: this.SymbolInfoSessionTrade.bind(this),
            getLastProcessedTickTime: this.getLastProcessedTickTime.bind(this)
        }
    }

    public MarketInfo(symbol: string, type: number, positionType?: TTradePositionType): number {
        switch (type) {
            case MODE_LOW: {
                return this.getLow(symbol)
            }
            case MODE_HIGH: {
                return this.getHigh(symbol)
            }
            case MODE_BID: {
                return this.getBid(symbol)
            }
            case MODE_ASK: {
                return this.getAsk(symbol)
            }
            case MODE_POINT: {
                return this.getPoint(symbol)
            }
            case MODE_DIGITS: {
                return this.getDigits(symbol)
            }
            case MODE_SPREAD: {
                return this.getSpread(symbol)
            }
            case MODE_STOPLEVEL: {
                return this.getStopLevel(symbol)
            }
            case MODE_LOTSIZE: {
                return this.getLotSize(symbol)
            }
            case MODE_TICKVALUE: {
                return this.getTickValue(symbol)
            }
            case MODE_TICKSIZE: {
                return this.getTickSize(symbol)
            }
            case MODE_SWAPLONG: {
                return this.getSwapLong(symbol)
            }
            case MODE_SWAPSHORT: {
                return this.getSwapShort(symbol)
            }
            case MODE_STARTING: {
                return this.getStartingTime(symbol)
            }
            case MODE_EXPIRATION: {
                return this.getExpirationTime(symbol)
            }
            case MODE_TRADEALLOWED: {
                return this.getTradeAllowed(symbol) ? 1 : 0
            }
            case MODE_MINLOT: {
                return this.getMinLotSize(symbol)
            }
            case MODE_LOTSTEP: {
                return this.getLotStep(symbol)
            }
            case MODE_MAXLOT: {
                return this.getMaxLotSize(symbol)
            }
            case MODE_SWAPTYPE: {
                return this.getSwapType(symbol)
            }
            case MODE_PROFITCALCMODE: {
                return this.getProfitCalcMode(symbol)
            }
            case MODE_MARGINCALCMODE: {
                return this.getMarginCalcMode(symbol)
            }
            case MODE_MARGININIT: {
                if (!positionType && typeof positionType !== 'number') {
                    throw new Error("positionType can't be undefined in MarketInfo")
                }
                return this.getMarginInit(symbol, positionType)
            }
            case MODE_MARGINMAINTENANCE: {
                return this.getMarginMaintenance(symbol)
            }
            case MODE_MARGINHEDGED: {
                return this.getMarginHedged(symbol)
            }
            case MODE_MARGINREQUIRED: {
                return this.getMarginRequired(symbol)
            }
            case MODE_FREEZELEVEL: {
                return this.getFreezeLevel(symbol)
            }
            case MODE_CLOSEBY_ALLOWED: {
                return this.getCloseByAllowed(symbol) ? 1 : 0
            }
            default: {
                throw new Error('Unknown type in MarketInfo')
            }
        }
    }

    private getBid(symbol: string): number {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).bid
    }

    private getAsk(symbol: string): number {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).ask
    }

    private getLow(symbol: string): number {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).getLowestAndHighPriceInDay()
            .minBidPrice
    }

    private getHigh(symbol: string): number {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).getLowestAndHighPriceInDay()
            .maxBidPrice
    }

    private getPoint(symbol: string): number {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.MinPoint
    }

    private getDigits(symbol: string): number {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.decimals
    }

    private getSpread(symbol: string): number {
        // need to fix it, and return spread in points
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.spreadAsPriceValue
    }

    private getStopLevel(symbol: string): number {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.MinDistToPrice
    }

    private getLotSize(symbol: string): number {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.lot
    }

    private getTickValue(symbol: string): number {
        // in our case, it's the same as Point cost for one standard lot
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol)
            .symbolInfo.getSymbolCalculationStrategy()
            .getPointCostForOneStandardLot(0, null)
    }

    private getTickSize(symbol: string): number {
        // static value, min price change in FTO
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.MinPoint
    }

    private getSwapLong(symbol: string): number {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.SwapLong
    }

    private getSwapShort(symbol: string): number {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.SwapShort
    }

    private getStartingTime(symbol: string): number {
        return 0
    }

    private getExpirationTime(symbol: string): number {
        return 0
    }

    private getTradeAllowed(symbol: string): boolean {
        return GlobalStrategiesManager.Instance.isTradeAllowed
    }

    private getMinLotSize(symbol: string): number {
        return 0.01
    }

    private getLotStep(symbol: string): number {
        return 0.01
    }

    private getMaxLotSize(symbol: string): number {
        return 10000
    }

    private getSwapType(symbol: string): SwapTypes {
        // 0 - in points; 1 - in the symbol base currency; 2 - by interest; 3 - in the margin currency
        // in FTO we couldn't change swap type?
        return SwapTypes.SWAP_TYPE_POINT
    }

    private getProfitCalcMode(symbol: string): number {
        // 0 - Forex; 1 - CFD; 2 - Futures
        // in FTO we have only Forex for now
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.profitCalculationMode
    }

    private getMarginCalcMode(symbol: string): number {
        // 0 - Forex; 1 - CFD; 2 - Futures; 3 - CFD_Indices
        // in FTO we have only Forex for now
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.marginClaculationMode
    }

    private getMarginInit(symbol: string, positionType: TTradePositionType): number {
        // add new param
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol)
            .symbolInfo.getSymbolCalculationStrategy()
            .calculateMarginRequirementForSymbol(positionType, null)
    }

    private getMarginMaintenance(symbol: string): number {
        // need to research
        throw new NotImplementedError('For now we do not have this value in FTO')
    }

    private getMarginHedged(symbol: string): number {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.HedgedMargin
    }

    private getMarginRequired(symbol: string): number {
        // need to research
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol)
            .symbolInfo.getSymbolCalculationStrategy()
            .calculateMarginRequirementForSymbol(0, null)
    }

    private getFreezeLevel(symbol: string): number {
        throw new NotImplementedError('For now we do not have this value in FTO')
    }

    private getCloseByAllowed(symbol: string): boolean {
        // For now we could always close by(?)
        return true
    }

    public SymbolsTotal(): number {
        return GlobalSymbolList.SymbolList.Symbols.length
    }

    public SymbolName(index: number): string {
        if (index < 0 || index >= GlobalSymbolList.SymbolList.Symbols.length) {
            this.errorHandlingImplementation.SetLastError(
                `SymbolName: index out of range in SymbolName: ${index}. Total symbols: ${GlobalSymbolList.SymbolList.Symbols.length}`
            )
            return ''
        }
        try {
            return GlobalSymbolList.SymbolList.Symbols[index].symbolInfo.SymbolName
        } catch (error) {
            this.errorHandlingImplementation.SetLastError((error as Error).message)
            return ''
        }
    }

    public SymbolSelect(name: string, select: boolean): boolean {
        if (select) {
            if (WatchlistStore.symbols.includes(name)) {
                return false
            }

            WatchlistStore.updateSymbols([...WatchlistStore.symbols, name])
            return true
        } else {
            // Check if symbol is used in charts or open positions
            if (
                GlobalChartsController.Instance.isSymbolInCharts(name) ||
                GlobalProcessingCore.ProcessingCore.isSymbolUsedInOpenPositions(name)
            ) {
                return false
            } else {
                WatchlistStore.updateSymbols(WatchlistStore.symbols.filter((symbol) => symbol !== name))
                return true
            }
        }
    }

    public SymbolInfoDouble(
        name: string,
        prop_id: ENUM_SYMBOL_INFO_DOUBLE,
        positionType: TTradePositionType = 0
    ): number {
        let value: number
        switch (prop_id) {
            case ENUM_SYMBOL_INFO_DOUBLE.SYMBOL_BID: {
                value = this.conversionProcRec.NormalizeDouble(this.getBid(name), this.getDigits(name))
                break
            }
            case ENUM_SYMBOL_INFO_DOUBLE.SYMBOL_ASK: {
                value = this.conversionProcRec.NormalizeDouble(this.getAsk(name), this.getDigits(name))
                break
            }
            case ENUM_SYMBOL_INFO_DOUBLE.SYMBOL_POINT: {
                value = this.getPoint(name)
                break
            }
            case ENUM_SYMBOL_INFO_DOUBLE.SYMBOL_TRADE_TICK_VALUE: {
                value = this.getTickValue(name)
                break
            }
            case ENUM_SYMBOL_INFO_DOUBLE.SYMBOL_TRADE_TICK_SIZE: {
                value = this.getTickSize(name)
                break
            }
            case ENUM_SYMBOL_INFO_DOUBLE.SYMBOL_TRADE_CONTRACT_SIZE: {
                value = this.getLotSize(name)
                break
            }
            case ENUM_SYMBOL_INFO_DOUBLE.SYMBOL_SWAP_LONG: {
                value = this.getSwapLong(name)
                break
            }
            case ENUM_SYMBOL_INFO_DOUBLE.SYMBOL_SWAP_SHORT: {
                value = this.getSwapShort(name)
                break
            }
            case ENUM_SYMBOL_INFO_DOUBLE.SYMBOL_MARGIN_INITIAL: {
                value = this.getMarginInit(name, positionType)
                break
            }
            case ENUM_SYMBOL_INFO_DOUBLE.SYMBOL_MARGIN_MAINTENANCE: {
                value = this.getMarginMaintenance(name)
                break
            }
            case ENUM_SYMBOL_INFO_DOUBLE.SYMBOL_VOLUME_MIN: {
                value = this.getMinLotSize(name)
                break
            }
            case ENUM_SYMBOL_INFO_DOUBLE.SYMBOL_VOLUME_MAX: {
                value = this.getMaxLotSize(name)
                break
            }
            case ENUM_SYMBOL_INFO_DOUBLE.SYMBOL_VOLUME_STEP: {
                value = this.getLotStep(name)
                break
            }
            default: {
                throw new Error('Unknown prop_id in SymbolInfoDouble')
            }
        }
        return value
    }

    public SymbolInfoInteger(name: string, prop_id: ENUM_SYMBOL_INFO_INTEGER): number {
        let value: number
        switch (prop_id) {
            case ENUM_SYMBOL_INFO_INTEGER.SYMBOL_SELECT: {
                value = this.getSymbolSelect(name) ? 1 : 0
                break
            }
            case ENUM_SYMBOL_INFO_INTEGER.SYMBOL_VISIBLE: {
                value = this.getSymbolVisible(name) ? 1 : 0
                break
            }
            case ENUM_SYMBOL_INFO_INTEGER.SYMBOL_SPREAD: {
                value = this.getSpread(name)
                break
            }
            case ENUM_SYMBOL_INFO_INTEGER.SYMBOL_TRADE_CALC_MODE: {
                value = this.getProfitCalcMode(name)
                break
            }
            case ENUM_SYMBOL_INFO_INTEGER.SYMBOL_TRADE_MODE: {
                value = this.getTradeMode(name)
                break
            }
            case ENUM_SYMBOL_INFO_INTEGER.SYMBOL_START_TIME: {
                value = this.getStartingTime(name)
                break
            }
            case ENUM_SYMBOL_INFO_INTEGER.SYMBOL_EXPIRATION_TIME: {
                value = this.getExpirationTime(name)
                break
            }
            case ENUM_SYMBOL_INFO_INTEGER.SYMBOL_TRADE_STOPS_LEVEL: {
                value = this.getStopLevel(name)
                break
            }
            case ENUM_SYMBOL_INFO_INTEGER.SYMBOL_TRADE_FREEZE_LEVEL: {
                value = this.getFreezeLevel(name)
                break
            }
            case ENUM_SYMBOL_INFO_INTEGER.SYMBOL_DIGITS: {
                value = this.getDigits(name)
                break
            }
            case ENUM_SYMBOL_INFO_INTEGER.SYMBOL_SPREAD_FLOAT: {
                value = this.getSpreadFloat(name) ? 1 : 0
                break
            }
            case ENUM_SYMBOL_INFO_INTEGER.SYMBOL_SWAP_MODE: {
                value = this.getSwapType(name)
                break
            }
            case ENUM_SYMBOL_INFO_INTEGER.SYMBOL_SWAP_ROLLOVER3DAYS: {
                value = this.getSwapRollover3Days(name)
                break
            }

            default: {
                throw new Error('Unknown prop_id in SymbolInfoInteger')
            }
        }

        return value
    }

    private getSymbolSelect(name: string): boolean {
        return WatchlistStore.symbols.includes(name)
    }

    private getSymbolVisible(name: string): boolean {
        return (
            WatchlistStore.symbols.includes(name) &&
            GlobalSymbolList.SymbolList.Symbols.some((symbol) => symbol.symbolInfo.SymbolName === name)
        )
    }

    public getLastProcessedTickTime(name: string): FTODate {
        return new FTODate(GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(name).LastProcessedTickTime)
    }

    private getSpreadFloat(name: string): boolean {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(name).symbolInfo.UseFloatingSpread
    }

    private getSwapRollover3Days(name: string): number {
        return ENUM_DAY_OF_WEEK.WEDNESDAY
    }

    private getTradeMode(name: string): number {
        // In FTO is always instant execution?
        return ENUM_SYMBOL_TRADE_EXECUTION.SYMBOL_TRADE_EXECUTION_INSTANT
    }

    public SymbolInfoString(name: string, prop_id: ENUM_SYMBOL_INFO_STRING): string {
        let value: string
        switch (prop_id) {
            case ENUM_SYMBOL_INFO_STRING.SYMBOL_CURRENCY_BASE: {
                value = this.getCurrencyBase(name)
                break
            }
            case ENUM_SYMBOL_INFO_STRING.SYMBOL_CURRENCY_PROFIT: {
                value = this.getCurrencyProfit(name)
                break
            }
            case ENUM_SYMBOL_INFO_STRING.SYMBOL_CURRENCY_MARGIN: {
                value = this.getCurrencyMargin(name)
                break
            }
            case ENUM_SYMBOL_INFO_STRING.SYMBOL_DESCRIPTION: {
                value = this.getDescription(name)
                break
            }
            case ENUM_SYMBOL_INFO_STRING.SYMBOL_PATH: {
                value = this.getSymbolPath(name)
                break
            }
            default: {
                throw new Error('Unknown prop_id in SymbolInfoString')
            }
        }

        return value
    }

    private getCurrencyBase(symbol: string): string {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.BaseCurrency
    }

    private getCurrencyProfit(symbol: string): string {
        // in FTO we use only USD for profit?
        return 'USD'
    }

    private getCurrencyMargin(symbol: string): string {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.MarginCurrency
    }

    private getDescription(symbol: string): string {
        return GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol).symbolInfo.description
    }

    private getSymbolPath(symbol: string): string {
        throw new Error('We do not have SymbolPath in FTO')
    }

    public SymbolInfoTick(symbol: string, tickData: MQLTick): boolean {
        const symbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol_ThrowErrorIfNull(symbol)
        tickData.bid = symbolData.bid
        tickData.ask = symbolData.ask
        tickData.time = symbolData.LastProcessedTickTime
        tickData.last = (tickData.bid + tickData.ask) / 2 // need to research and improve it
        tickData.volume = symbolData.getLastVolumeValue()
        return true
    }

    public SymbolInfoSessionQuote(
        symbol: string,
        dayOfWeek: ENUM_DAY_OF_WEEK,
        sessionIndex: number,
        from: TDateTime,
        to: TDateTime
    ): boolean {
        throw new Error('We do not have SymbolInfoSessionQuote in FTO')
    }

    public SymbolInfoSessionTrade(
        symbol: string,
        dayOfWeek: ENUM_DAY_OF_WEEK,
        sessionIndex: number,
        from: TDateTime,
        to: TDateTime
    ): boolean {
        throw new Error('We do not have SymbolInfoSessionTrade in FTO')
    }
}
