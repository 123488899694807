import { Command } from './Command'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

class ExtendToolRightCommand extends Command {
    public execute(): void {
        const activeChart = GlobalChartsController.Instance.getActiveChart()
        if (activeChart) {
            const selectedTools = activeChart.MainChart.PaintTools.getAllSelectedTools()
            for (const tool of selectedTools) {
                tool.extendsRay.right = !tool.extendsRay.right
            }

            for (const oscWin of activeChart.OscWins) {
                for (const tool of oscWin.chart.PaintTools.getAllSelectedTools()) {
                    tool.extendsRay.right = !tool.extendsRay.right
                }
            }

            activeChart.Repaint()
            activeChart.updateLinkedTool()
        } else {
            throw new StrangeError('no active chart')
        }
    }
}

export { ExtendToolRightCommand }
