import { t } from 'i18next'
import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import { ProjectJSON, ProjectJSONfromServer } from '@fto/lib/ProjectAdapter/Types'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { changeProjectInitialBalance, secureApi } from '@root/utils/api'
import { showErrorToast } from '@root/utils/toasts'
import axios, { AxiosError } from 'axios'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import TEventsFunctionality from '@fto/lib/utils/EventsFunctionality'
import ProjectStore from '@fto/lib/store/projectStore'
import { ProjectEvents } from '@fto/lib/ProjectAdapter/ProjectEvents'
import GlobalSettings from '@fto/lib/globals/GlobalSettings'

export class SyncProjectManager {
    public Events: TEventsFunctionality = new TEventsFunctionality('SyncProjectManager')
    private static instance: SyncProjectManager
    private static TIME_TO_SAVE_MS = 120000 // 2 minutes
    private intervalId: NodeJS.Timeout | null = null
    private attemptsToSave = 0
    private _isSavingNow = false
    /**
     * String is serialized ProjectJSON
     * @private
     */
    private lastSavedStateString: string | null = null
    private errorFlag = false
    private lastSaveTime = 0

    private constructor() {
        this.SaveProject = this.SaveProject.bind(this)
        this.DownloadProject = this.DownloadProject.bind(this)
    }

    public static get Instance(): SyncProjectManager {
        if (!SyncProjectManager.instance) {
            SyncProjectManager.instance = new SyncProjectManager()
        }

        return SyncProjectManager.instance
    }

    private async retryOperation<T>(operation: () => Promise<T>, retries: number, delay: number): Promise<T> {
        this.attemptsToSave = 0
        while (this.attemptsToSave < retries) {
            try {
                return await operation()
            } catch (error) {
                this.attemptsToSave++
                if (this.attemptsToSave >= retries) {
                    throw error
                }
                await new Promise((resolve) => setTimeout(resolve, delay))
            }
        }
        throw new Error('Operation failed after maximum retries')
    }

    public isSavingNow(): boolean {
        return this._isSavingNow
    }

    public async SaveProject(isSaveOnRestart: boolean = false) {
        if ((this.errorFlag || this.isSavingNow()) && !isSaveOnRestart) {
            return
        }

        let path = `projects/api/Projects/${GlobalProjectJSONAdapter.Instance.projectId}`
        if (isSaveOnRestart) {
            path += '?overwrite=true'
        }
        const { updateData } = ProjectStore

        this._isSavingNow = true

        try {
            updateData((prevData) => ({ ...prevData, saveState: 'saving' }))
            const projectJSON = await this.DownloadProject(GlobalProjectJSONAdapter.Instance.projectId)
            GlobalProjectJSONAdapter.Instance.setDownloadedJSON(projectJSON)

            const projectToSave = GlobalProjectJSONAdapter.Instance.getJSON()

            if (JSON.stringify(projectToSave, null, 2) === this.lastSavedStateString) {
                this.lastSaveTime = Date.now()
                updateData((prevData) => ({ ...prevData, saveState: 'ok' }))
                return
            }

            await this.retryOperation(
                () => {
                    return Promise.all([
                        GlobalSettings.Instance.updateGlobalSettingsRequestStrict(),
                        secureApi.put(path, projectToSave)
                    ])
                },
                3,
                2000 // 2 seconds delay
            )

            this.lastSavedStateString = JSON.stringify(projectToSave, null, 2)
            updateData((prevData) => ({ ...prevData, saveState: 'ok' }))
            this.lastSaveTime = Date.now()
            this.Events.EmitEvent(ProjectEvents.PROJECT_SAVED)
        } catch (error) {
            updateData((prevData) => ({ ...prevData, saveState: 'error' }))
            await this.handleSaveError(error)
            this.Events.EmitEvent(ProjectEvents.PROJECT_SAVED)
            throw new StrangeError('Failed to save project after 3 attempts.')
        } finally {
            this._isSavingNow = false
        }
    }

    private handleAxiosError(error: AxiosError): void {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    this.errorFlag = true
                    throw new StrangeError('Invalid project data provided.')
                case 401:
                    throw new StrangeError('Unauthorized access attempted.')
                case 403:
                    throw new StrangeError('Access to the project is denied.')
                case 404:
                    this.errorFlag = true
                    throw new StrangeError('The project with the provided ID was not found.')
                case 409:
                    throw new StrangeError('Project edit conflict. Please try again.')
                default:
                    throw new StrangeError(`An unexpected error occurred. ${error.response.status}`)
            }
        } else {
            throw new StrangeError('Network error or no response from server.')
        }
    }

    private async handleSaveError(error: unknown): Promise<void> {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError

            showErrorToast({
                title: t('project.toasts.savingProjectError'),
                message: t('project.toasts.savingProjectErrorMessage', {
                    count: Math.round(this.getTimeIntervalInMinutes())
                })
            })
            this.attemptsToSave = 0
            this.handleAxiosError(axiosError)
        } else {
            console.error('An unexpected error occurred during saving.', error)
            //throw new StrangeError('An unexpected error occurred during saving.', error)
        }
    }

    private getTimeIntervalInMinutes(): number {
        return SyncProjectManager.TIME_TO_SAVE_MS / 60000
    }

    public getLastSaveTime(): number {
        return this.lastSaveTime
    }

    public async DownloadProject(projectId: string | null = null): Promise<ProjectJSONfromServer> {
        if (!projectId) {
            projectId = GlobalProjectJSONAdapter.Instance.projectId
        }

        const path = `projects/api/Projects/${projectId}`

        try {
            const { data } = await secureApi.get<ProjectJSONfromServer>(path)
            return data
        } catch (error) {
            const axiosError = error as AxiosError
            if (axiosError && axiosError.response) {
                switch (axiosError.response.status) {
                    case 400: {
                        this.errorFlag = true
                        throw new StrangeError('Invalid project ID.')
                    }
                    case 401: {
                        throw new StrangeError('Unauthorized. Please check your credentials.')
                    }
                    case 403: {
                        throw new StrangeError('Access denied to this project.')
                    }
                    case 404: {
                        this.errorFlag = true
                        throw new StrangeError('The project with the provided ID was not found.')
                    }
                    default: {
                        throw new StrangeError('An unexpected error occurred.')
                    }
                }
            } else {
                DebugUtils.error('Network error or no response from server:', axiosError)
                throw new StrangeError('Network error or no response from server.')
            }
        }
    }

    public stopAutoSaving(): void {
        if (this.intervalId) {
            clearTimeout(this.intervalId)
            this.intervalId = null
        }
    }

    public startAutoSaving(): void {
        if (!GlobalProjectJSONAdapter.Instance.projectName) {
            throw new StrangeError('Project name is empty')
        }

        if (!this.intervalId) {
            const saveFunction = async () => {
                await this.SaveProject()
                this.intervalId = setTimeout(saveFunction, SyncProjectManager.TIME_TO_SAVE_MS)
            }

            this.intervalId = setTimeout(saveFunction, SyncProjectManager.TIME_TO_SAVE_MS)
        }
    }

    public forceProjectSave(project: ProjectJSON) {
        const path = `projects/api/Projects/${GlobalProjectJSONAdapter.Instance.projectId}`

        secureApi
            .put<ProjectJSON>(path, project)
            .then(() => {
                DebugUtils.log('Project saved')
            })
            .catch((error) => {
                StrangeSituationNotifier.NotifyAboutUnexpectedSituation('Failed to save project:', error)
            })
    }

    public async changeInitialBalance(projectId: string | null, balance: number) {
        if (!projectId) {
            projectId = GlobalProjectJSONAdapter.Instance.projectId
        }

        if (!projectId) {
            throw new StrangeError('Project ID is empty')
        }

        try {
            const { data } = await changeProjectInitialBalance(projectId, balance)
            return data
        } catch (error) {
            const axiosError = error as AxiosError
            if (axiosError && axiosError.response) {
                switch (axiosError.response.status) {
                    case 401: {
                        throw new StrangeError('Unauthorized. Please check your credentials.')
                    }
                    case 403: {
                        throw new StrangeError('Access denied to this project.')
                    }
                    case 404: {
                        throw new StrangeError('The project with the provided ID was not found.')
                    }
                    case 409: {
                        throw new StrangeError('Project partial update failed')
                    }
                    default: {
                        throw new StrangeError('An unexpected error occurred.')
                    }
                }
            } else {
                DebugUtils.error('Network error or no response from server:', axiosError)
                throw new StrangeError('Network error or no response from server.')
            }
        }
    }
}
