import IFMBarsArray from '@fto/lib/ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import { TBarRecord } from '@fto/lib/ft_types/data/DataClasses/TBarRecord'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import { API_IndexOutOfRangeError } from './errors/API_IndexOutOfRangeError'
import { MODE_OPEN, MODE_LOW, MODE_HIGH, MODE_CLOSE, MODE_VOLUME } from './CommonConstantsForExternalModules'
import DataNotDownloadedYetError from '@fto/lib/ft_types/data/data_errors/DataUnavailableError'
import { TDateTime, TPriceType } from './CommonTypes'

export class APIHelperFunctions {
    public static ValidateIndexForTestingRange(
        bars: IFMBarsArray,
        index: number,
        symbol: string,
        timeFrame: number
    ): void {
        if (!bars.IsIndexWithinTestingRange(index)) {
            throw new API_IndexOutOfRangeError(
                `Index is out of testing range: ${index} for ${symbol} timeframe: ${timeFrame}`
            )
        }
    }

    public static ValidateIndexForWholeHistory(
        bars: IFMBarsArray,
        index: number,
        symbol: string,
        timeFrame: number
    ): void {
        if (!bars.IsIndexWithinWholeHistory(index)) {
            throw new API_IndexOutOfRangeError(`Index is out of range: ${index} for ${symbol} timeframe: ${timeFrame}`)
        }
    }
    // GetBar function retrieves the bar record for a given symbol, timeframe, and index.
    // It returns a reference to an empty bar if the symbol data is not found or if there are no bars.
    public static GetBar(Symbol: string, TimeFrame: number, index_lastItem0: number): TBarRecord {
        // Default result is a reference to an empty bar.
        let result: TBarRecord = new TBarRecord(0, 0, 0, 0, 0, 0)

        // Retrieve symbol data from the SymbolList.
        const data = GlobalSymbolList.SymbolList.GetOrCreateSymbol(Symbol, true)
        if (data === null) {
            return result
        }

        // Get bars array for the specified timeframe.
        const bars = data.GetOrCreateBarArray(TimeFrame)
        if (bars === null || !bars.LastItemInTestingIndexAvailable) {
            return result
        }

        // Adjust the index to fit within the bars array.
        index_lastItem0 = bars.FitIndex(index_lastItem0)
        const index_firstItem0 = this.Reverse_from_lastItem0_to_firstItem0(bars, index_lastItem0)

        // Return the bar at the adjusted index.
        const foundBar = bars.GetItemByGlobalIndex(index_firstItem0)
        if (foundBar) {
            result = foundBar
        }
        return result
    }

    public static getValueByType(barRecord: TBarRecord, type: number): number {
        switch (type) {
            case MODE_OPEN: {
                return barRecord.open
            }
            case MODE_LOW: {
                return barRecord.low
            }
            case MODE_HIGH: {
                return barRecord.high
            }
            case MODE_CLOSE: {
                return barRecord.close
            }
            case MODE_VOLUME: {
                return barRecord.volume
            }
            default: {
                return barRecord.DateTime
            }
        }
    }

    public static GetBar_lastItem0_based_index(barsArray: IFMBarsArray, lastItem0_based_index: number): TBarRecord {
        if (!barsArray.IsSeeked) {
            throw new DataNotDownloadedYetError('bars array is not seeked - GetBar_lastItem0_based_index')
        }

        let firstItem0_based_index = this.Reverse_from_lastItem0_to_firstItem0(barsArray, lastItem0_based_index)
        firstItem0_based_index = barsArray.FitIndex(firstItem0_based_index)
        const bar = barsArray.GetItemByGlobalIndex(firstItem0_based_index)
        if (!bar) {
            throw new DataNotDownloadedYetError(
                `GetBar_lastItem0_based_index - Bar at index ${lastItem0_based_index} is not available`
            )
        }
        return bar
    }

    public static ReverseFrom_firstItem0_to_lastItem0(barsArray: IFMBarsArray, firstItem0_index: number): number {
        return barsArray.LastItemInTestingIndex - firstItem0_index
    }

    public static Reverse_from_lastItem0_to_firstItem0(barsArray: IFMBarsArray, lastItem0_index: number): number {
        return barsArray.LastItemInTestingIndex - lastItem0_index
    }

    public static Low(lastItem0_based_index: number, barsArray: IFMBarsArray): number {
        const bar = APIHelperFunctions.GetBar_lastItem0_based_index(barsArray, lastItem0_based_index)
        return bar.low
    }

    public static Open(lastItem0_based_index: number, barsArray: IFMBarsArray): number {
        const bar = APIHelperFunctions.GetBar_lastItem0_based_index(barsArray, lastItem0_based_index)
        return bar.open
    }

    public static High(lastItem0_based_index: number, barsArray: IFMBarsArray): number {
        const bar = APIHelperFunctions.GetBar_lastItem0_based_index(barsArray, lastItem0_based_index)
        return bar.high
    }

    public static Time(lastItem0_based_index: number, barsArray: IFMBarsArray): TDateTime {
        const bar = APIHelperFunctions.GetBar_lastItem0_based_index(barsArray, lastItem0_based_index)
        return bar.DateTime
    }

    public static Close(lastItem0_based_index: number, barsArray: IFMBarsArray): number {
        const bar = APIHelperFunctions.GetBar_lastItem0_based_index(barsArray, lastItem0_based_index)
        return bar.close
    }

    public static Volume(lastItem0_based_index: number, barsArray: IFMBarsArray): number {
        const bar = APIHelperFunctions.GetBar_lastItem0_based_index(barsArray, lastItem0_based_index)
        return bar.volume
    }

    public static GetPrice(lastItem_0_based_index: number, PriceType: TPriceType, barsArray: IFMBarsArray): number {
        let high: number, low: number, close: number

        switch (PriceType) {
            case TPriceType.pt_Close: {
                return this.Close(lastItem_0_based_index, barsArray)
            }
            case TPriceType.pt_Open: {
                return this.Open(lastItem_0_based_index, barsArray)
            }
            case TPriceType.pt_High: {
                return this.High(lastItem_0_based_index, barsArray)
            }
            case TPriceType.pt_Low: {
                return this.Low(lastItem_0_based_index, barsArray)
            }
            case TPriceType.pt_HL2: {
                high = this.High(lastItem_0_based_index, barsArray)
                low = this.Low(lastItem_0_based_index, barsArray)
                return (high + low) / 2
            }
            case TPriceType.pt_HLC3: {
                high = this.High(lastItem_0_based_index, barsArray)
                low = this.Low(lastItem_0_based_index, barsArray)
                close = this.Close(lastItem_0_based_index, barsArray)
                return (high + low + close) / 3
            }
            case TPriceType.pt_HLCC4: {
                high = this.High(lastItem_0_based_index, barsArray)
                low = this.Low(lastItem_0_based_index, barsArray)
                close = this.Close(lastItem_0_based_index, barsArray)
                return (high + low + close * 2) / 4
            }
            default: {
                return 0
            }
        }
    }
}
