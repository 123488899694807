import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, Button, removeModal } from '@fto/ui'
import { Icon } from '@fto/icons'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { fireMixpanelEvent } from '@root/utils/api'

import { TEXT_LOCALES } from '../../constants/texts'

type Props = {
    onSubmit?: () => void
    disabled?: boolean
}

const Controls: FC<Props> = ({ onSubmit, disabled = false }) => {
    const { t } = useTranslation()

    const submitForm = useCallback(() => {
        onSubmit?.()
    }, [onSubmit])

    const onCancel = useCallback(() => {
        removeModal(MODAL_NAMES.userProfile.suspendSubscription)
    }, [])

    const contactSupport = useCallback(() => {
        if (window.Tawk_API) {
            window.Tawk_API.start()
            window.Tawk_API.maximize()

            fireMixpanelEvent('subscription_suspend_contact_support')
            removeModal(MODAL_NAMES.userProfile.suspendSubscription)
        }
    }, [])

    return (
        <>
            <Modal.Footer>
                <Button
                    type='secondary'
                    label={t(TEXT_LOCALES.controls.contactSupport)}
                    before={<Icon size={18} name='contact-support' color='var(--color-gray-1000)' />}
                    onClick={contactSupport}
                    block
                />
            </Modal.Footer>
            <Modal.Controls
                cancelText={t(TEXT_LOCALES.controls.cancel)}
                submitText={t(TEXT_LOCALES.controls.continue)}
                onSubmit={submitForm}
                onClose={onCancel}
                disabled={disabled}
            ></Modal.Controls>
        </>
    )
}

export default Controls
