import IBasicPaintTool from '../IBasicPaintTool'

class PaintToolsListMemento {
    public static mergeMementos(mementos: PaintToolsListMemento[]): PaintToolsListMemento {
        const tools: IBasicPaintTool[] = []
        for (const memento of mementos) {
            tools.push(...memento.getTools())
        }
        return new PaintToolsListMemento(tools)
    }

    private tools: IBasicPaintTool[]

    constructor(tools: IBasicPaintTool[]) {
        this.tools = tools
    }

    getTools(): IBasicPaintTool[] {
        return [...this.tools]
    }
}

export default PaintToolsListMemento
