import { useCallback, useEffect, useRef } from 'react'
import { getOptionValues } from '../components/utils/OptionStates'
import { AvailableOptionsSettings } from '../components/types/options.types'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { fireMixpanelEvent } from '@root/utils/api'

export type ChangesObserverProps = {
    mixPanelEventName: string
    optionsConfig: { [key: string]: AvailableOptionsSettings }
}

const useChangesObserver = (chart: TChartWindow, { mixPanelEventName, optionsConfig }: ChangesObserverProps) => {
    const initialSettings = useRef<{ [key: string]: any }>({})
    const differentSettings = useRef<{ [key: string]: any }>({})

    const onDifferentSettingsUpdate = useCallback((tab: string, newValue: any, prevValue: any, key: string) => {
        const newValueKey = `${tab}_new_${key}`

        if (newValue !== prevValue) {
            differentSettings.current = {
                ...differentSettings.current,
                [newValueKey]: newValue
            }
        }
    }, [])

    const getInitialSettings = useCallback(
        (options?: { [key: string]: AvailableOptionsSettings }): { [key: string]: any } => {
            if (!options) return {}

            let values = {}

            Object.keys(options).forEach((key) => {
                const option = options[key]
                values = {
                    ...values,
                    ...getOptionValues(option, chart, true),
                    ...getInitialSettings(option.subOptions)
                }
            })

            return values
        },
        [chart]
    )

    const prepareObserver = useCallback(() => {
        initialSettings.current = getInitialSettings(optionsConfig)
        differentSettings.current = {}
    }, [getInitialSettings])

    const executeRequest = useCallback(() => {
        const keysOfChanges = Object.keys(differentSettings.current)

        if (keysOfChanges.length === 0) return

        const changes = keysOfChanges.reduce(
            (acc, changesKey: string) => {
                const [tab, key] = changesKey.split('_new_')
                const value = differentSettings.current[changesKey]

                const existsInitialValue = initialSettings.current[key] !== undefined
                const valuesAraNotEqual = existsInitialValue && initialSettings.current[key] !== value

                if (existsInitialValue && valuesAraNotEqual) {
                    return {
                        ...acc,
                        [changesKey]: value,
                        [`${tab}_old_${key}`]: initialSettings.current[key]
                    }
                }

                return acc
            },
            {} as Record<string, any>
        )

        fireMixpanelEvent(mixPanelEventName, changes)
    }, [])

    const executeRequestAndClear = useCallback(() => {
        executeRequest()
        prepareObserver()
    }, [executeRequest, prepareObserver])

    useEffect(() => {
        prepareObserver()

        return () => {
            executeRequest()
        }
    }, [])

    return {
        onDifferentSettingsUpdate,
        executeRequestAndClear
    }
}

export default useChangesObserver
