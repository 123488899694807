import { TCommApplyType } from '@fto/lib/ft_types/common/BasicClasses/BasicEnums'
import { AvailableOptionsSettings, ConfigType } from '../../components/types/options.types'

const CommissionsConfig: { [key: string]: AvailableOptionsSettings } = {
    CommPerLot: {
        key: 'CommPerLot',
        type: 'inputSetting',
        name: 'settings.commissions.rows.commissionPer1lot',
        valueKey: 'CommPerLot',
        valueConfigType: ConfigType.ProjectInfo,
        warningKey: 'settings.commissions.warnings.commissions',
        warningTooltip: 'settings.commissions.warnings.commissionsTooltip',
        warningMessage: 'settings.commissions.warnings.commissionsMessage'
    },
    CommissionApplyType: {
        key: 'CommissionApplyType',
        type: 'radioSetting',
        valueKey: 'CommissionApplyType',
        valueConfigType: ConfigType.ProjectInfo,
        options: [
            { value: TCommApplyType.ca_OpenClose, titleKey: 'settings.commissions.rows.applyWhenOpenAndClose' },
            { value: TCommApplyType.ca_Open, titleKey: 'settings.commissions.rows.applyWhenOpen' },
            { value: TCommApplyType.ca_Close, titleKey: 'settings.commissions.rows.applyWhenClose' }
        ],
        warningMessage: 'settings.commissions.warnings.commissionsTypesMessage'
    }
}

export default CommissionsConfig
