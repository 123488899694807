export enum TDataTypes {
    dt_Bars,
    dt_Ticks,
    dt_Empty,
    dt_IndicatorBuffer
}

export interface TDataDescriptor {
    broker: string
    symbolName: string
    symbolCode: string
    dataType: TDataTypes
    timeframe: number
}

export const EmptyDataDescriptor: TDataDescriptor = {
    broker: 'Deliberately empty',
    symbolName: 'Deliberately empty',
    dataType: TDataTypes.dt_Empty,
    timeframe: -1,
    symbolCode: 'Deliberately empty'
}
