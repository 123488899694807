import { useModalState } from '@fto/ui'
import { ApplyThemeChangingModal } from '@root/components/ApplyThemeChangingModal'
import { MODAL_NAMES } from '@root/constants/modalNames'

const GlobalsModalsWrapper = () => {
    const { open: isApplyThemeChangingModalOpen, props: applyThemeChangingModalProps } = useModalState(
        MODAL_NAMES.all.applyThemeChanging
    )

    return <>{isApplyThemeChangingModalOpen && <ApplyThemeChangingModal {...applyThemeChangingModalProps} />}</>
}

export default GlobalsModalsWrapper
