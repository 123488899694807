import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { IndicatorDescriptor } from '../../IndicatorDescriptor'
import TIndicatorDescriptorList from '../../IndicatorDescriptorList'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'

export default class BuiltInIndicatorDescriptors extends TIndicatorDescriptorList {
    constructor() {
        super()
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.DeMarker, 'DeMarker'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.Volume, 'Volume'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.Momentum, 'Momentum'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.CCI, 'CCI'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.RSI, 'RSI'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.WPR, 'Williams Percent Range'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.PreviousLevels, 'Previous Levels'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.OBV, 'OBV'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.ATR, 'ATR'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.Ichimoku, 'Ichimoku'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.Stochastic, 'Stochastic'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.ADX, 'ADX'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.MovingAverage, 'Moving Average'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.SMA, 'SMA'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.EMA, 'EMA'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.WMA, 'WMA'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.SSMA, 'SSMA'))

        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.BollingerBands, 'Bollinger Bands'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.BollingerBandsRatio, 'Bollinger Bands Ratio'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.MACD, 'MACD'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.MFI, 'MFI'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.iSession, 'Trading Sessions'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.AwesomeOsc, 'Awesome Oscillator (B. Williams)'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.Alligator, 'Alligator (B. Williams)'))

        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.FVG, 'Fair Value Gap'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.InsideBar, 'Inside Bar'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.Aroon, 'Aroon'))
        this.push(new IndicatorDescriptor(BuiltInIndicatorFiles.AcceleratorOsc, 'Accelerator Oscillator (B. Williams)'))
        // this.push(new TIndicatorDescriptor('ZigZag'))
        // this.push(new TIndicatorDescriptor('Heikin Ashi'))
        // this.push(new TIndicatorDescriptor('Pivot Points'))
        // this.push(new TIndicatorDescriptor('Parabolic SAR'))
    }

    findByName(name: string): IndicatorDescriptor {
        DebugUtils.log('BuiltInIndicatorDescriptors -> findByName -> name', name)
        for (let i = 0; i < this.length; i++) {
            DebugUtils.log(this[i].shortName)
            if (this[i].shortName.toLowerCase() === name.toLowerCase()) {
                return this[i]
            }
        }
        throw new StrangeError('Indicator not found.')
    }
}
export const BuiltInIndicatorFiles = {
    DeMarker: 'DeMarker',
    Volume: 'Volume',
    Momentum: 'Momentum',
    CCI: 'CCI',
    RSI: 'RSI',
    WPR: 'WPR',
    PreviousLevels: 'PreviousLevels',
    OBV: 'OBV',
    ATR: 'ATR',
    Ichimoku: 'Ichimoku',
    Stochastic: 'Stochastic',
    ADX: 'ADX',
    MovingAverage: 'MovingAverage',
    SMA: 'SMA',
    EMA: 'EMA',
    WMA: 'WMA',
    SSMA: 'SSMA',
    BollingerBands: 'BollingerBands',
    BollingerBandsRatio: 'BollingerBandsRatio',
    MACD: 'MACD',
    MFI: 'MFI',
    iSession: 'iSession',
    AwesomeOsc: 'AwersomeOsc',
    Alligator: 'Alligator',
    FVG: 'Fair_Value_Gap',
    InsideBar: 'Inside_Bar',
    AcceleratorOsc: 'Accelerator_Osc',
    ZigZag: 'ZigZag',
    HeikinAshi: 'Heikin_Ashi',
    PivotPoints: 'Pivot_Points',
    ParabolicSAR: 'Parabolic_SAR',
    Aroon: 'Aroon'
}
