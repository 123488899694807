import { BasicProcRecImplementation } from '@fto/lib/extension_modules/common/CommonProcRecImplementations/BasicProcRecImplementation'
import { IConversionProcRec } from '@fto/lib/extension_modules/common/CommonProcRecInterfaces/IConversionProcRec'

export const ColorConstants: { [key: string]: string } = {
    clrBlack: '#000000',
    clrDarkGreen: '#006400',
    clrDarkSlateGray: '#2f4f4f',
    clrOlive: '#808000',
    clrGreen: '#008000',
    clrTeal: '#008080',
    clrNavy: '#000080',
    clrPurple: '#800080',
    clrMaroon: '#800000',
    clrIndigo: '#4b0082',
    clrMidnightBlue: '#191970',
    clrDarkBlue: '#00008b',
    clrDarkOliveGreen: '#556b2f',
    clrSaddleBrown: '#8b4513',
    clrForestGreen: '#228b22',
    clrOliveDrab: '#6b8e23',
    clrSeaGreen: '#2e8b57',
    clrDarkGoldenrod: '#b8860b',
    clrDarkSlateBlue: '#483d8b',
    clrSienna: '#a0522d',
    clrMediumBlue: '#0000cd',
    clrBrown: '#a52a2a',
    clrDarkTurquoise: '#00ced1',
    clrDimGray: '#696969',
    clrLightSeaGreen: '#20b2aa',
    clrDarkViolet: '#9400d3',
    clrFireBrick: '#b22222',
    clrMediumVioletRed: '#c71585',
    clrMediumSeaGreen: '#3cb371',
    clrChocolate: '#d2691e',
    clrCrimson: '#dc143c',
    clrSteelBlue: '#4682b4',
    clrGoldenrod: '#daa520',
    clrMediumSpringGreen: '#00fa9a',
    clrLawnGreen: '#7cfc00',
    clrCadetBlue: '#5f9ea0',
    clrDarkOrchid: '#9932cc',
    clrYellowGreen: '#9acd32',
    clrLimeGreen: '#32cd32',
    clrOrangeRed: '#ff4500',
    clrDarkOrange: '#ff8c00',
    clrOrange: '#ffa500',
    clrGold: '#ffd700',
    clrYellow: '#ffff00',
    clrChartreuse: '#7fff00',
    clrLime: '#00ff00',
    clrSpringGreen: '#00ff7f',
    clrAqua: '#00ffff',
    clrDeepSkyBlue: '#00bfff',
    clrBlue: '#0000ff',
    clrMagenta: '#ff00ff',
    clrRed: '#ff0000',
    clrGray: '#808080',
    clrSlateGray: '#708090',
    clrPeru: '#cd853f',
    clrBlueViolet: '#8a2be2',
    clrLightSlateGray: '#778899',
    clrDeepPink: '#ff1493',
    clrMediumTurquoise: '#48d1cc',
    clrDodgerBlue: '#1e90ff',
    clrTurquoise: '#40e0d0',
    clrRoyalBlue: '#4169e1',
    clrSlateBlue: '#6a5acd',
    clrDarkKhaki: '#bdb76b',
    clrIndianRed: '#cd5c5c',
    clrMediumOrchid: '#ba55d3',
    clrGreenYellow: '#adff2f',
    clrMediumAquamarine: '#66cdaa',
    clrDarkSeaGreen: '#8fbc8f',
    clrTomato: '#ff6347',
    clrRosyBrown: '#bc8f8f',
    clrOrchid: '#da70d6',
    clrMediumPurple: '#9370db',
    clrPaleVioletRed: '#db7093',
    clrCoral: '#ff7f50',
    clrCornflowerBlue: '#6495ed',
    clrDarkGray: '#a9a9a9',
    clrSandyBrown: '#f4a460',
    clrMediumSlateBlue: '#7b68ee',
    clrTan: '#d2b48c',
    clrDarkSalmon: '#e9967a',
    clrBurlyWood: '#deb887',
    clrHotPink: '#ff69b4',
    clrSalmon: '#fa8072',
    clrViolet: '#ee82ee',
    clrLightCoral: '#f08080',
    clrSkyBlue: '#87ceeb',
    clrLightSalmon: '#ffa07a',
    clrPlum: '#dda0dd',
    clrKhaki: '#f0e68c',
    clrLightGreen: '#90ee90',
    clrAquamarine: '#7fffd4',
    clrSilver: '#c0c0c0',
    clrLightSkyBlue: '#87cefa',
    clrLightSteelBlue: '#b0c4de',
    clrLightBlue: '#add8e6',
    clrPaleGreen: '#98fb98',
    clrThistle: '#d8bfd8',
    clrPowderBlue: '#b0e0e6',
    clrPaleGoldenrod: '#eee8aa',
    clrPaleTurquoise: '#afeeee',
    clrLightGray: '#d3d3d3',
    clrWheat: '#f5deb3',
    clrNavajoWhite: '#ffdead',
    clrMoccasin: '#ffe4b5',
    clrLightPink: '#ffb6c1',
    clrGainsboro: '#dcdcdc',
    clrPeachPuff: '#ffdab9',
    clrPink: '#ffc0cb',
    clrBisque: '#ffe4c4',
    clrLightGoldenrod: '#fafad2',
    clrBlanchedAlmond: '#ffebcd',
    clrLemonChiffon: '#fffacd',
    clrBeige: '#f5f5dc',
    clrAntiqueWhite: '#faebd7',
    clrPapayaWhip: '#ffefd5',
    clrCornsilk: '#fff8dc',
    clrLightYellow: '#ffffe0',
    clrLightCyan: '#e0ffff',
    clrLinen: '#faf0e6',
    clrLavender: '#e6e6fa',
    clrMistyRose: '#ffe4e1',
    clrOldLace: '#fdf5e6',
    clrWhiteSmoke: '#f5f5f5',
    clrSeashell: '#fff5ee',
    clrIvory: '#fffff0',
    clrHoneydew: '#f0fff0',
    clrAliceBlue: '#f0f8ff',
    clrLavenderBlush: '#fff0f5',
    clrMintCream: '#f5fffa',
    clrSnow: '#fffafa',
    clrWhite: '#ffffff'
}

export class ConversionProcRecImplementation extends BasicProcRecImplementation {
    static CP_ACP = 'windows-1252'
    static CP_UTF8 = 'utf-8'

    public static TIME_DATE = 1
    public static TIME_MINUTES = 2
    public static TIME_SECONDS = 4

    public GetImplementation(): IConversionProcRec {
        return {
            CharToString: this.CharToString.bind(this),
            CharArrayToString: this.CharArrayToString.bind(this),
            ColorToARGB: this.ColorToARGB.bind(this),
            ColorToString: this.ColorToString.bind(this),
            DoubleToString: this.DoubleToString.bind(this),
            EnumToString: this.EnumToString.bind(this),
            IntegerToString: this.IntegerToString.bind(this),
            ShortToString: this.ShortToString.bind(this),
            ShortArrayToString: this.ShortArrayToString.bind(this),
            TimeToString: this.TimeToString.bind(this),
            NormalizeDouble: this.NormalizeDouble.bind(this),
            StringToCharArray: this.StringToCharArray.bind(this),
            StringToColor: this.StringToColor.bind(this),
            StringToDouble: this.StringToDouble.bind(this),
            StringToInteger: this.StringToInteger.bind(this),
            StringToShortArray: this.StringToShortArray.bind(this),
            StringToTime: this.StringToTime.bind(this),
            StringFormat: this.StringFormat.bind(this),
            CharToStr: this.CharToString.bind(this),
            DoubleToStr: this.DoubleToString.bind(this),
            StrToDouble: this.StringToDouble.bind(this),
            StrToInteger: this.StringToInteger.bind(this),
            StrToTime: this.StringToTime.bind(this),
            TimeToStr: this.TimeToString.bind(this)
        }
    }

    public CharToString(char_code: number): string {
        if (!Number.isInteger(char_code) || char_code < 0 || char_code > 255) {
            throw new Error('char_code must be an integer between 0 and 255')
        }

        return String.fromCharCode(char_code)
    }

    public CharArrayToString(
        array: number[],
        start: number = 0,
        count: number = -1,
        codepage: string = 'windows-1252'
    ): string {
        if (!Array.isArray(array)) {
            throw new TypeError('array must be an array of numbers')
        }

        if (!Number.isInteger(count)) {
            throw new TypeError('count must be an integer')
        }

        if (count <= -2) {
            return ''
        }

        let end: number
        if (count === -1) {
            end = array.length
            for (let i = start; i < array.length; i++) {
                if (array[i] === 0) {
                    end = i
                    break
                }
            }
        } else {
            end = start + count
            if (end > array.length) {
                end = array.length
            }
        }

        const slicedArray = array.slice(start, end)

        const uint8Array = Uint8Array.from(slicedArray)

        try {
            const decoder = new TextDecoder(codepage)
            return decoder.decode(uint8Array)
        } catch (e) {
            throw new Error(`Unsupported encoding: ${codepage}`)
        }
    }

    public ColorToARGB(color: number | string, alpha: number = 255): number {
        let red: number, green: number, blue: number

        if (typeof color === 'number') {
            // Extract red, green, and blue values from the number.
            red = (color >> 16) & 0xff
            green = (color >> 8) & 0xff
            blue = color & 0xff
        } else if (typeof color === 'string') {
            // Handle ARGB input as a string.
            if (color.startsWith('#')) {
                color = color.slice(1)
            }

            if (color.length === 8) {
                // If the string is in ARGB format (e.g., #AARRGGBB)
                const parsedAlpha = parseInt(color.slice(0, 2), 16)
                red = parseInt(color.slice(2, 4), 16)
                green = parseInt(color.slice(4, 6), 16)
                blue = parseInt(color.slice(6, 8), 16)

                // Override the alpha if a custom one is provided
                alpha = parsedAlpha
            } else if (color.length === 6) {
                // If the string is in RGB format (e.g., #RRGGBB)
                red = parseInt(color.slice(0, 2), 16)
                green = parseInt(color.slice(2, 4), 16)
                blue = parseInt(color.slice(4, 6), 16)
            } else {
                throw new Error("Invalid color format. Must be '#RRGGBB' or '#AARRGGBB'.")
            }
        } else {
            throw new Error("Invalid color format. Must be a number or a string in '#RRGGBB' or '#AARRGGBB' format.")
        }

        const clampedAlpha = Math.min(255, Math.max(0, alpha))

        return ((clampedAlpha << 24) | (red << 16) | (green << 8) | blue) >>> 0
    }

    public ColorToString(color_value: number | string, color_name = false): string {
        const color = typeof color_value === 'number' ? color_value : this.parseColorString(color_value)

        const r = (color >> 16) & 0xff
        const g = (color >> 8) & 0xff
        const b = color & 0xff

        if (color_name) {
            const colorName = Object.keys(ColorConstants).find((key) => ColorConstants[key] === this.rgbToHex(r, g, b))
            if (colorName) {
                return colorName
            }
        }

        return `${r},${g},${b}`
    }

    private rgbToHex(r: number, g: number, b: number): string {
        r = Math.max(0, Math.min(255, r))
        g = Math.max(0, Math.min(255, g))
        b = Math.max(0, Math.min(255, b))

        const redHex = r.toString(16).padStart(2, '0')
        const greenHex = g.toString(16).padStart(2, '0')
        const blueHex = b.toString(16).padStart(2, '0')

        return `#${redHex}${greenHex}${blueHex}`
    }

    private parseColorString(colorString: string): number {
        const matches = colorString.match(/C'(\d+),(\d+),(\d+)'/)
        if (matches) {
            const r = parseInt(matches[1])
            const g = parseInt(matches[2])
            const b = parseInt(matches[3])
            return (r << 16) | (g << 8) | b
        } else {
            // should be 255,0,0 by default
            return 0xff0000
        }
    }

    public DoubleToString(value: number, digits: number = 8): string {
        digits = Math.trunc(digits)

        if (digits >= 0 && digits <= 16) {
            if (Math.abs(value) < 1e-6 && value !== 0) {
                return value.toExponential(digits)
            } else {
                return value.toFixed(digits)
            }
        } else if (digits >= -16 && digits <= -1) {
            const absDigits = -digits
            return value.toExponential(absDigits)
        } else {
            return value.toFixed(8)
        }
    }

    public EnumToString<T>(enumType: T, value: number, enumTypeName: string): string {
        const enumName = (enumType as any)[value]

        if (enumName !== undefined) {
            return enumName
        } else {
            return `${enumTypeName}::${value}=${value}`
        }
    }

    public IntegerToString(number: number, str_len: number = 0, fill_symbol: number | string = 32): string {
        let str = Math.trunc(number).toString()

        let fillChar: string

        if (typeof fill_symbol === 'number') {
            fillChar = String.fromCharCode(fill_symbol)
        } else if (typeof fill_symbol === 'string') {
            fillChar = fill_symbol
        } else {
            throw new Error('fill_symbol must be a number or a string')
        }

        if (fillChar.length !== 1) {
            throw new Error('fill_symbol must be a single character')
        }

        if (str_len > 0 && str.length < str_len) {
            const paddingLength = str_len - str.length
            const padding = fillChar.repeat(paddingLength)
            str = padding + str
        }

        return str
    }

    public ShortToString(symbol_code: number | string): string {
        let codePoint: number

        if (typeof symbol_code === 'number') {
            codePoint = symbol_code
        } else if (typeof symbol_code === 'string') {
            if (symbol_code.length === 1) {
                return symbol_code
            } else {
                let hexString = symbol_code.trim()

                if (hexString.startsWith('0x') || hexString.startsWith('0X')) {
                    hexString = hexString.slice(2)
                }

                codePoint = parseInt(hexString, 16)

                if (isNaN(codePoint)) {
                    throw new Error('Invalid symbol_code string')
                }
            }
        } else {
            throw new Error('symbol_code must be a number or a string')
        }

        if (!Number.isInteger(codePoint) || codePoint < 0 || codePoint > 0xffff) {
            throw new Error('Invalid Unicode code point')
        }

        return String.fromCharCode(codePoint)
    }

    public ShortArrayToString(array: number[], start: number = 0, count: number = -1): string {
        if (!Array.isArray(array)) {
            throw new TypeError('array must be an array of numbers')
        }

        if (!Number.isInteger(start) || start < 0 || start >= array.length) {
            throw new RangeError('Invalid start index')
        }

        if (!Number.isInteger(count)) {
            throw new TypeError('count must be an integer')
        }

        let end: number
        if (count === -1) {
            end = array.length
            for (let i = start; i < array.length; i++) {
                if (array[i] === 0) {
                    end = i
                    break
                }
            }
        } else {
            end = start + count
            if (end > array.length) {
                end = array.length
            }
        }

        const slicedArray = array.slice(start, end)

        return String.fromCharCode(...slicedArray)
    }

    public TimeToString(
        value: number,
        mode: number = ConversionProcRecImplementation.TIME_DATE | ConversionProcRecImplementation.TIME_MINUTES
    ): string {
        const date = new Date(value * 1000)

        const parts: string[] = []

        if ((mode & ConversionProcRecImplementation.TIME_DATE) !== 0) {
            const year = date.getUTCFullYear().toString().padStart(4, '0')
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
            const day = date.getUTCDate().toString().padStart(2, '0')
            parts.push(`${year}.${month}.${day}`)
        }

        if (
            (mode & ConversionProcRecImplementation.TIME_MINUTES) !== 0 ||
            (mode & ConversionProcRecImplementation.TIME_SECONDS) !== 0
        ) {
            const hours = date.getUTCHours().toString().padStart(2, '0')
            const minutes = date.getUTCMinutes().toString().padStart(2, '0')
            let timeString = `${hours}:${minutes}`

            if ((mode & ConversionProcRecImplementation.TIME_SECONDS) !== 0) {
                const seconds = date.getUTCSeconds().toString().padStart(2, '0')
                timeString += `:${seconds}`
            }

            parts.push(timeString)
        }

        return parts.join(' ').trim()
    }

    public StringToCharArray(
        text_string: string,
        array: number[],
        start: number = 0,
        count: number = -1,
        codepage: string = 'windows-1252'
    ): number {
        if (typeof text_string !== 'string') {
            throw new TypeError('text_string must be a string')
        }

        if (!Array.isArray(array)) {
            throw new TypeError('array must be an array of numbers')
        }

        if (!Number.isInteger(start) || start < 0) {
            throw new RangeError('Invalid start index')
        }

        if (!Number.isInteger(count)) {
            throw new TypeError('count must be an integer')
        }

        if (codepage !== 'utf-8') {
            console.warn(`Codepage '${codepage}' is not supported in browsers. Defaulting to 'utf-8'.`)
            codepage = 'utf-8'
        }

        let encodedArray: Uint8Array
        try {
            const encoder = new TextEncoder()
            encodedArray = encoder.encode(text_string)
        } catch (e) {
            throw new Error(`Encoding failed: ${e}`)
        }

        const encodedBytes = Array.from(encodedArray)

        let numBytesToCopy: number
        if (count === -1) {
            numBytesToCopy = encodedBytes.length + 1
        } else {
            numBytesToCopy = Math.min(count, encodedBytes.length)
        }

        const requiredLength = start + numBytesToCopy
        if (array.length < requiredLength) {
            array.length = requiredLength
        }

        for (let i = 0; i < numBytesToCopy; i++) {
            if (i < encodedBytes.length) {
                array[start + i] = encodedBytes[i]
            } else {
                array[start + i] = 0
            }
        }

        if (count === -1) {
            array[start + numBytesToCopy - 1] = 0
        }

        return numBytesToCopy
    }

    public StringToColor(color_string: string): string {
        const input = color_string.trim().toLowerCase()

        const colorValue = ColorConstants[color_string]

        if (colorValue !== undefined) {
            return colorValue
        }

        // Split the input by commas and trim each part
        const parts = input.split(',').map((part) => part.trim())

        // Check if the number of parts is between 1 and 3
        if (parts.length >= 1 && parts.length <= 3) {
            // Parse each part to an integer
            const rgb = parts.map((part) => parseInt(part, 10))

            // Check for any NaN values (non-numeric inputs)
            if (rgb.some((value) => isNaN(value))) {
                throw new Error('RGB values must be numeric')
            }

            // Assign default values if g or b are missing
            const [r, g = 0, b = 0] = rgb

            // Validate that r, g, and b are within the 0-255 range
            if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255) {
                throw new Error('RGB values must be between 0 and 255')
            }

            // Now, create the color string in "#RRGGBB" format
            return '#' + [r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('')
        }

        throw new Error(`Invalid color string: '${color_string}'`)
    }

    public StringToDouble(value: string): number {
        if (typeof value !== 'string') {
            throw new TypeError('value must be a string')
        }

        const trimmedValue = value.trim()

        const parsedNumber = Number(trimmedValue)

        if (isNaN(parsedNumber)) {
            return 0.0
        }

        return parsedNumber
    }

    public StringToInteger(value: string): number {
        if (typeof value !== 'string') {
            throw new TypeError('value must be a string')
        }

        const trimmedValue = value.trim()

        const parsedNumber = parseInt(trimmedValue)

        if (isNaN(parsedNumber)) {
            return 0
        }

        return parsedNumber
    }

    public StringToShortArray(text_string: string, array: number[], start: number = 0, count: number = -1): number {
        if (typeof text_string !== 'string') {
            throw new TypeError('text_string must be a string')
        }

        if (!Array.isArray(array)) {
            throw new TypeError('array must be an array of numbers')
        }

        if (!Number.isInteger(start) || start < 0) {
            throw new RangeError('Invalid start index')
        }

        if (!Number.isInteger(count)) {
            throw new TypeError('count must be an integer')
        }

        const codeUnits = Array.from(text_string, (char) => char.charCodeAt(0))

        let numToCopy: number
        if (count === -1) {
            numToCopy = codeUnits.length + 1
        } else {
            numToCopy = Math.min(count, codeUnits.length)
        }

        const requiredLength = start + numToCopy
        if (array.length < requiredLength) {
            array.length = requiredLength
        }

        for (let i = 0; i < numToCopy; i++) {
            if (i < codeUnits.length) {
                array[start + i] = codeUnits[i]
            } else {
                array[start + i] = 0
            }
        }

        if (count === -1) {
            array[start + numToCopy - 1] = 0
        }

        return numToCopy
    }

    public StringToTime(value: string): number {
        if (typeof value !== 'string') {
            throw new TypeError('value must be a string')
        }

        const trimmedValue = value.trim()

        const dateTimeRegex = /^(\d{4})\.(\d{1,2})\.(\d{1,2})(?:\s+(\d{1,2}):(\d{2}))?$/
        const timeOnlyRegex = /^(\d{1,2}):(\d{2})$/

        let year: number,
            month: number,
            day: number,
            hours: number = 0,
            minutes: number = 0

        let match = dateTimeRegex.exec(trimmedValue)
        if (match) {
            year = parseInt(match[1], 10)
            month = parseInt(match[2], 10) - 1
            day = parseInt(match[3], 10)

            if (match[4] !== undefined && match[5] !== undefined) {
                hours = parseInt(match[4], 10)
                minutes = parseInt(match[5], 10)
            }
        } else {
            match = timeOnlyRegex.exec(trimmedValue)
            if (match) {
                const now = new Date()
                year = now.getUTCFullYear()
                month = now.getUTCMonth()
                day = now.getUTCDate()
                hours = parseInt(match[1], 10)
                minutes = parseInt(match[2], 10)
            } else {
                throw new Error(`Invalid date string: '${value}'`)
            }
        }

        const date = new Date(Date.UTC(year, month, day, hours, minutes, 0, 0))

        return Math.floor(date.getTime() / 1000)
    }

    public StringFormat(format: string, args: any[]): string {
        if (typeof format !== 'string') {
            throw new TypeError('format must be a string')
        }

        let argIndex = 0

        const formatSpecifierRegex = /%([+-]?)(\d+)?(\.\d+)?([dfgsi%])/g

        return format.replace(formatSpecifierRegex, (match, sign, width, precision, specifier) => {
            if (specifier === '%') {
                return '%'
            }

            if (argIndex >= args.length) {
                throw new Error('Insufficient arguments for format string')
            }

            let arg = args[argIndex++]
            let formattedArg = ''

            switch (specifier) {
                case 'd':
                case 'i':
                    formattedArg = this.formatInteger(arg, sign, width)
                    break
                case 'f':
                    formattedArg = this.formatFloat(arg, sign, width, precision)
                    break
                case 'g':
                    formattedArg = this.formatGeneralFloat(arg, sign, width, precision)
                    break
                case 's':
                    formattedArg = this.formatString(arg, width)
                    break
                default:
                    throw new Error(`Unsupported format specifier: ${match}`)
            }

            return formattedArg
        })
    }

    private formatInteger(value: any, sign: string, width: string | undefined): string {
        const num = parseInt(value, 10)

        if (isNaN(num)) {
            throw new Error(`Invalid integer value: ${value}`)
        }

        let formatted = num.toString()

        if (sign === '+') {
            formatted = (num >= 0 ? '+' : '') + formatted
        }

        if (width !== undefined) {
            const widthNum = parseInt(width, 10)
            formatted = formatted.padStart(widthNum, ' ')
        }

        return formatted
    }

    private formatFloat(value: any, sign: string, width: string | undefined, precision: string | undefined): string {
        const num = parseFloat(value)

        if (isNaN(num)) {
            throw new Error(`Invalid floating-point value: ${value}`)
        }

        let prec = 6
        if (precision !== undefined) {
            prec = parseInt(precision.slice(1), 10)
        }

        let formatted = num.toFixed(prec)

        if (sign === '+') {
            formatted = (num >= 0 ? '+' : '') + formatted
        }

        if (width !== undefined) {
            const widthNum = parseInt(width, 10)
            formatted = formatted.padStart(widthNum, ' ')
        }

        return formatted
    }

    private formatGeneralFloat(
        value: any,
        sign: string,
        width: string | undefined,
        precision: string | undefined
    ): string {
        const num = parseFloat(value)

        if (isNaN(num)) {
            throw new Error(`Invalid floating-point value: ${value}`)
        }

        let prec = 6
        if (precision !== undefined) {
            prec = parseInt(precision.slice(1), 10)
        }

        let formatted = num.toPrecision(prec)

        if (sign === '+') {
            formatted = (num >= 0 ? '+' : '') + formatted
        }

        formatted = formatted.replace(/(\.\d*?[1-9])0+$/, '$1').replace(/\.0+$/, '')

        if (width !== undefined) {
            const widthNum = parseInt(width, 10)
            formatted = formatted.padStart(widthNum, ' ')
        }

        return formatted
    }

    private formatString(value: any, width: string | undefined): string {
        const str = String(value)

        if (width !== undefined) {
            const widthNum = parseInt(width, 10)
            return str.padStart(widthNum, ' ')
        }

        return str
    }

    public CharToStr(char_code: number): string {
        if (!Number.isInteger(char_code) || char_code < 0 || char_code > 255) {
            throw new RangeError('char_code must be an integer between 0 and 255')
        }

        return String.fromCharCode(char_code)
    }

    public DoubleToStr(value: number, digits: number): string {
        if (!Number.isInteger(digits) || digits < 0 || digits > 8) {
            throw new Error('digits must be an integer between 0 and 8')
        }

        return value.toFixed(digits)
    }

    public StrToDouble(value: string): number {
        if (typeof value !== 'string') {
            throw new TypeError('value must be a string')
        }

        const trimmedValue = value.trim()

        const floatRegex = /^[+-]?(\d+(\.\d*)?|\.\d+)([eE][+-]?\d+)?$/

        if (!floatRegex.test(trimmedValue)) {
            throw new Error(`Invalid double string: '${value}'`)
        }

        const parsedNumber = parseFloat(trimmedValue)

        if (isNaN(parsedNumber)) {
            throw new Error(`Cannot convert string to double: '${value}'`)
        }

        return parsedNumber
    }

    public StrToInteger(value: string): number {
        return this.StringToInteger(value)
    }

    public StrToTime(value: string): number {
        if (typeof value !== 'string') {
            throw new TypeError('value must be a string')
        }

        const trimmedValue = value.trim()

        const dateTimeRegex = /^(\d{4})\.(\d{1,2})\.(\d{1,2})\s+(\d{1,2}):(\d{2})$/
        const dateOnlyRegex = /^(\d{4})\.(\d{1,2})\.(\d{1,2})$/
        const timeOnlyRegex = /^(\d{1,2}):(\d{2})$/

        let year: number,
            month: number,
            day: number,
            hours: number = 0,
            minutes: number = 0

        let match = dateTimeRegex.exec(trimmedValue)
        if (match) {
            year = parseInt(match[1], 10)
            month = parseInt(match[2], 10) - 1
            day = parseInt(match[3], 10)
            hours = parseInt(match[4], 10)
            minutes = parseInt(match[5], 10)
        } else {
            match = dateOnlyRegex.exec(trimmedValue)
            if (match) {
                year = parseInt(match[1], 10)
                month = parseInt(match[2], 10) - 1
                day = parseInt(match[3], 10)
                hours = 0
                minutes = 0
            } else {
                match = timeOnlyRegex.exec(trimmedValue)
                if (match) {
                    const now = new Date()
                    year = now.getUTCFullYear()
                    month = now.getUTCMonth()
                    day = now.getUTCDate()
                    hours = parseInt(match[1], 10)
                    minutes = parseInt(match[2], 10)
                } else {
                    throw new Error(`Invalid date/time string: '${value}'`)
                }
            }
        }

        if (!this.isValidDateTime(year, month, day, hours, minutes)) {
            throw new Error(`Invalid date/time components in string: '${value}'`)
        }

        const date = new Date(Date.UTC(year, month, day, hours, minutes, 0, 0))

        const timestamp = Math.floor(date.getTime() / 1000)

        return timestamp
    }

    private isValidDateTime(year: number, month: number, day: number, hours: number, minutes: number): boolean {
        if (
            year < 1970 ||
            month < 0 ||
            month > 11 ||
            day < 1 ||
            day > 31 ||
            hours < 0 ||
            hours > 23 ||
            minutes < 0 ||
            minutes > 59
        ) {
            return false
        }

        const date = new Date(Date.UTC(year, month, day, hours, minutes, 0, 0))

        return (
            date.getUTCFullYear() === year &&
            date.getUTCMonth() === month &&
            date.getUTCDate() === day &&
            date.getUTCHours() === hours &&
            date.getUTCMinutes() === minutes
        )
    }

    public TimeToStr(
        value: number,
        mode: number = ConversionProcRecImplementation.TIME_DATE | ConversionProcRecImplementation.TIME_MINUTES
    ): string {
        if (typeof value !== 'number' || value < 0) {
            throw new TypeError('value must be a positive number representing seconds since 01.01.1970')
        }

        const date = new Date(value * 1000)

        const components: string[] = []

        if (mode & ConversionProcRecImplementation.TIME_DATE) {
            const year = date.getFullYear()
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const day = date.getDate().toString().padStart(2, '0')

            components.push(`${year}.${month}.${day}`)
        }

        if (mode & (ConversionProcRecImplementation.TIME_MINUTES | ConversionProcRecImplementation.TIME_SECONDS)) {
            const hours = date.getHours().toString().padStart(2, '0')
            const minutes = date.getMinutes().toString().padStart(2, '0')
            let timeStr = `${hours}:${minutes}`

            if (mode && ConversionProcRecImplementation.TIME_SECONDS) {
                const seconds = date.getSeconds().toString().padStart(2, '0')
                timeStr += `:${seconds}`
            }

            components.push(timeStr)
        }

        return components.join(' ').trim()
    }
}
