import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { ChartControl } from '@fto/chart_components/ChartControl'
import { ChartControlParams } from './ChartControlParams'
import { IGPFont, IGPSolidBrush, TGPFontFamily } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import CommonDataUtils from '@fto/lib/ft_types/data/DataUtils/CommonDataUtils'
import { TBarRecord } from '@fto/lib/ft_types/data/DataClasses/TBarRecord'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import CommonUtils from '@fto/lib/ft_types/common/BasicClasses/CommonUtils'

export class BarInfoControl extends ChartControl {
    private barData: TBarRecord | null
    private decimals = 5

    private font = new IGPFont(new TGPFontFamily('Roboto Flex'), 12, [])

    private valuesWidthByContext: Map<number, number> = new Map<number, number>()
    private gapAfterK = 0
    private gapAfterV = 3
    private debug = false

    constructor(chartControlParams: ChartControlParams, barData: TBarRecord | null) {
        super(chartControlParams)
        this.barData = barData
    }

    public setBarInfo(barData: TBarRecord, decimals: number): void {
        if (CommonDataUtils.isBarEmpty(barData)) {
            this.barData = null
            return
        }
        this.barData = barData
        this.decimals = decimals
    }

    private calculateTextWidths(canvas: TGdiPlusCanvas, text: string): number {
        return canvas.TextWidth(text, this.font)
    }

    public draw(canvas: TGdiPlusCanvas): void {
        // volume 999,9B
        if (!this.IsVisible() || !this.barData) {
            return
        }
        const rect = this.getLocation()
        canvas.graphics.Context.clearRect(rect.Left, rect.Top, canvas.graphics.Context.canvas.width, rect.Height)

        // const left: number = rect.Left + 5
        const top: number = rect.Top + 17

        const keyColorBrush = new IGPSolidBrush(this.ChartWindow.ChartOptions.ControlsColorScheme.infoText)
        const valueColorBrush = new IGPSolidBrush(this.ChartWindow.ChartOptions.ControlsColorScheme.infoValueText)
        const backgroundColorBrush = new IGPSolidBrush(
            this.ChartWindow.ChartOptions.ControlsColorScheme.infoBackground,
            0.85
        )

        const volumeText = { text: 'Vol:', textTemplate: '', color: keyColorBrush, left: 0, gap: this.gapAfterK }
        const volumeValue = {
            text: CommonUtils.FormatNumberWithSuffix(this.barData.volume, 1),
            textTemplate: '',
            color: valueColorBrush,
            left: 0,
            gap: this.gapAfterV
        }
        const texts: {
            text: string
            textTemplate: string
            color: IGPSolidBrush
            left: number
            gap: number
            width?: number
            oneChar?: number
        }[] = [
            { text: 'Date:', textTemplate: '', color: keyColorBrush, left: 0, gap: this.gapAfterK },
            {
                text: DateUtils.DF(
                    GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(
                        this.barData.DateTime
                    )
                ),
                textTemplate: '2020-00-00 00:00:00',
                color: valueColorBrush,
                left: 0,
                gap: this.gapAfterV
            },
            { text: 'O:', textTemplate: '', color: keyColorBrush, left: 0, gap: this.gapAfterK },
            {
                text: this.barData.open.toFixed(this.decimals),
                textTemplate: '',
                color: valueColorBrush,
                left: 0,
                gap: this.gapAfterV
            },
            { text: 'H:', textTemplate: '', color: keyColorBrush, left: 0, gap: this.gapAfterK },
            {
                text: this.barData.high.toFixed(this.decimals),
                textTemplate: '',
                color: valueColorBrush,
                left: 0,
                gap: this.gapAfterV
            },
            { text: 'L:', textTemplate: '', color: keyColorBrush, left: 0, gap: this.gapAfterK },
            {
                text: this.barData.low.toFixed(this.decimals),
                textTemplate: '',
                color: valueColorBrush,
                left: 0,
                gap: this.gapAfterV
            },
            { text: 'C:', textTemplate: '', color: keyColorBrush, left: 0, gap: this.gapAfterK },
            {
                text: this.barData.close.toFixed(this.decimals),
                textTemplate: '',
                color: valueColorBrush,
                left: 0,
                gap: this.gapAfterV
            },
            volumeText,
            volumeValue
        ]
        let barInfoWidth = 5
        let currentWidth: number | undefined = 0

        // Calculate text widths
        for (const text of texts) {
            text.left = barInfoWidth + rect.Left
            currentWidth = this.valuesWidthByContext.get(text.text.length)

            if (!currentWidth) {
                if (text.textTemplate) {
                    currentWidth = canvas.TextWidth(text.textTemplate, this.font, false) + text.gap
                } else {
                    currentWidth = canvas.TextWidth(text.text.replace(/./g, '0'), this.font, false) + text.gap
                    this.valuesWidthByContext.set(text.text.length, currentWidth)
                }
            }
            barInfoWidth += currentWidth + text.gap

            if (this.debug) {
                text.width = currentWidth
                text.oneChar = currentWidth / text.text.length
            }
        }

        if (this.debug) {
            // eslint-disable-next-line no-console
            console.log('BarInfo in debug', texts)
        }

        rect.Right = barInfoWidth + rect.Left
        canvas.FillRectRounded(rect, backgroundColorBrush, 5)
        this.setLocation(rect)

        // Draw texts
        for (let i = 0; i < texts.length - 2; i++) {
            canvas.textOut(texts[i].left, top, texts[i].text, this.font, texts[i].color)
        }

        if (this.barData.volume > 0) {
            canvas.textOut(volumeText.left, top, volumeText.text, this.font, volumeText.color)
            canvas.textOut(volumeValue.left, top, volumeValue.text, this.font, volumeValue.color)
        }
    }

    public onBrowserWndSizing(): void {
        this.valuesWidthByContext.clear()
    }
}
