import { FC, useMemo } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import { addModal, Button } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { JumpToController } from '@fto/lib/JumpTo/JumpToController'
import useLocalStorageKey from '@root/hooks/useLocalStorageKey'
import { JUMP_TO_OPTIONS } from '@root/constants/localStorageKeys'
import { DROPDOWN_OPTIONS } from '@root/pages/ChartPage/components/JumpToSettingsModal/constants'
import { usePersistedState } from '@root/hooks/usePersistedState'
import { Icon } from '@fto/icons'

import { RibbonOptionType } from '../../types'
import RibbonDropdown from '../../RibbonDropdown'
import JumpToDropdownContent from '../components/JumpToDropdownContent'

import styles from '../../../index.module.scss'

const JumpToDropdown: FC = () => {
    const { t } = useTranslation()

    const key = useLocalStorageKey(JUMP_TO_OPTIONS)
    const [dropdownVisibleOptions, setDropdownVisibleOptions] = usePersistedState(key, DROPDOWN_OPTIONS)

    const generalVisibleOptions = useMemo(() => {
        return dropdownVisibleOptions.general.filter(
            (item: { value: string; isShown: boolean }) => item.value !== 'historicalMoments'
        )
    }, [dropdownVisibleOptions])

    const jumptoController = useMemo(() => JumpToController.Instance(), [])

    const jumpToOptions = useMemo(
        () =>
            [
                {
                    value: 'nextDayOpen',
                    name: t('ribbon.jumpTo.nextDayOpen'),
                    action: () => jumptoController.jumpToNextDayOpen()
                },
                {
                    value: 'nextDayClose',
                    name: t('ribbon.jumpTo.nextDayClose'),
                    action: () => jumptoController.jumpToNextDayClose()
                },
                {
                    value: 'customDate',
                    name: t('ribbon.jumpTo.customDate'),
                    action: () => addModal(MODAL_NAMES.chart.jumpToDate)
                }
            ] as RibbonOptionType[],
        [jumptoController]
    )

    const filteredOptions = useMemo(() => {
        return jumpToOptions.filter((option) => {
            return generalVisibleOptions.find(
                (item: { value: string; isShown: boolean }) => item.value === option.value && item.isShown
            )
        })
    }, [jumpToOptions, generalVisibleOptions])

    return (
        <RibbonDropdown
            tooltip={t('ribbon.tooltips.jumpTo')}
            renderer={(close) => (
                <JumpToDropdownContent
                    close={close}
                    options={filteredOptions}
                    dropdownVisibleOptions={dropdownVisibleOptions}
                    setDropdownVisibleOptions={setDropdownVisibleOptions}
                />
            )}
        >
            <Button
                classNames={{ content: cx(styles.orderButton, styles.smallButton) }}
                onClick={() => {}}
                before={<Icon name='jump-to' />}
                size='compact'
                type='tetriatry-white'
                label={t('ribbon.jumpTo.jumpTo')}
            />
        </RibbonDropdown>
    )
}

export default JumpToDropdown
