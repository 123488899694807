import { ChartControl } from '@fto/chart_components/ChartControl'
import { CanvasLayer } from './Layers'
import { LayerType } from './LayerType'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { LayerBlockingChart } from '@fto/lib/charting/auxiliary_classes_charting/Layers/LayerBlockingChart'
import { IChartWindow } from '../../chart_windows/IChartWindow'
import { IChart } from '../../chart_classes/IChart'

export class ManagerLayers {
    private chartWindow: IChartWindow
    private layers: CanvasLayer[] = []

    private layersNodeHtml: HTMLDivElement | null = null
    private mainCanvas: HTMLCanvasElement

    private layerBlockingChart: LayerBlockingChart | null = null
    private layerBlockingChartHtml: HTMLCanvasElement | null = null
    private layerBlockingChartSuffix = '-full-size-chart' //translation-ignore

    constructor(mainCanvas: HTMLCanvasElement, chartWindow: IChartWindow) {
        this.chartWindow = chartWindow
        this.mainCanvas = mainCanvas
    }

    private createContainer(): void {
        this.layersNodeHtml = document.createElement('div')
        this.layersNodeHtml.id = `${this.mainCanvas.id}-layers` //translation-ignore

        if (this.mainCanvas && this.mainCanvas.parentNode) {
            this.mainCanvas.parentNode.insertBefore(this.layersNodeHtml, this.mainCanvas.parentNode.lastChild)
        }
    }

    addLayer(layerSize: LayerType = LayerType.MAIN_CHART_ONLY, name: string): CanvasLayer {
        let layer
        switch (layerSize) {
            case LayerType.ENTIRE_CHART:
            case LayerType.MAIN_CHART_ONLY: {
                layer = new CanvasLayer(this.mainCanvas, this.chartWindow, layerSize, name)
                this.getOrCreateLayersNodeHtml()
                this.layers.push(layer)
                if (this.layersNodeHtml) {
                    this.layersNodeHtml.append(layer.canvas)
                }
                break
            }
            case LayerType.ENTIRE_CHART_BLOCKING: {
                layer = new CanvasLayer(this.mainCanvas, this.chartWindow, layerSize, name)
                const existingCanvas = document.getElementById(this.mainCanvas.id + this.layerBlockingChartSuffix)
                if (this.layerBlockingChartHtml && existingCanvas) {
                    break
                }
                if (this.layerBlockingChartHtml) {
                    this.layerBlockingChartHtml.remove()
                    this.layerBlockingChartHtml = null
                }
                if (existingCanvas) {
                    existingCanvas.remove()
                }
                this.addLayerBlockingChart()
                break
            }
            default: {
                throw new StrangeError('Invalid layer type')
            }
        }

        return layer
    }

    private getOrCreateLayersNodeHtml(): HTMLDivElement {
        if (!this.layersNodeHtml) {
            this.createContainer()
        }
        if (!this.layersNodeHtml) {
            throw new StrangeError('Could not create layers node')
        }
        return this.layersNodeHtml
    }

    removeLayer(layer: CanvasLayer): void {
        const index = this.layers.indexOf(layer)
        if (index > -1) {
            this.layers.splice(index, 1)
            if (this.layersNodeHtml && layer.canvas.parentNode === this.layersNodeHtml) {
                layer.canvas.remove()
            }
        }
    }

    getLayerByIndex(index: number): CanvasLayer | undefined {
        if (index < 0 || index >= this.layers.length) {
            return undefined
        }
        return this.layers[index]
    }

    addLayerSizeBars(): CanvasLayer {
        return this.addLayer(LayerType.MAIN_CHART_ONLY, `layer-${this.layers.length}`)
    }

    addLayerSizeFull(): CanvasLayer {
        return this.addLayer(LayerType.ENTIRE_CHART, `layer-${this.layers.length}`)
    }

    getLayerSizeFullBlocking(): CanvasLayer {
        return this.addLayer(LayerType.ENTIRE_CHART_BLOCKING, `layer-${this.layers.length}`)
    }

    getLayerBlockingChart(): LayerBlockingChart | null {
        if (!this.layerBlockingChart) {
            return this.addLayerBlockingChart()
        }
        return this.layerBlockingChart
    }

    private addLayerBlockingChart(): LayerBlockingChart | null {
        const layer = new LayerBlockingChart(
            this.mainCanvas,
            this.chartWindow,
            LayerType.ENTIRE_CHART_BLOCKING,
            this.mainCanvas.id + this.layerBlockingChartSuffix
        )
        if (!layer) {
            return null
        }
        this.layerBlockingChart = layer
        this.layerBlockingChartHtml = layer.canvas
        this.layerBlockingChartHtml.id = this.mainCanvas.id + this.layerBlockingChartSuffix

        const parentElement = this.mainCanvas.parentElement
        if (parentElement) {
            parentElement.append(this.layerBlockingChartHtml)
        }
        return layer
    }

    getLayerByName(name: string): CanvasLayer | undefined {
        return this.layers.find((layer) => layer.name === name)
    }

    getLayers(): CanvasLayer[] {
        return this.layers
    }

    moveLayer(layer: CanvasLayer, newPosition: number): void {
        const oldPosition = this.layers.indexOf(layer)
        if (oldPosition === -1) {
            throw new StrangeError('Layer not found in the list')
        }

        this.layers.splice(oldPosition, 1)
        this.layers.splice(newPosition, 0, layer)

        if (this.layersNodeHtml) {
            if (newPosition >= 0 && newPosition < this.layers.length) {
                const nextLayer = this.layers[newPosition + 1]
                if (nextLayer) {
                    this.layersNodeHtml.insertBefore(layer.canvas, nextLayer.canvas)
                } else {
                    this.layersNodeHtml.append(layer.canvas)
                }
            } else {
                throw new StrangeError('Invalid position for layer')
            }
        }
    }

    resize(doDraw = true): void {
        if (this.layerBlockingChart) {
            this.layerBlockingChart.resize()
        }

        for (const layer of this.layers) {
            layer.resize(doDraw)
        }
    }

    public onMouseDown(event: MouseEvent, sender: IChart): ChartControl | null {
        let result: ChartControl | null = null

        if (this.layerBlockingChart) {
            result = this.layerBlockingChart.onMouseDown(event, sender)
            if (result) {
                return result
            }
        }

        for (let i = 0; i < this.layers.length; i++) {
            result = this.layers[i].onMouseDown(event, sender)
            if (result) {
                break
            }
        }
        return result
    }

    public onMouseMove(event: MouseEvent, sender: IChart): ChartControl | null {
        let result: ChartControl | null = null

        if (this.layerBlockingChart) {
            result = this.layerBlockingChart.onMouseMove(event, sender)
            if (result) {
                return result
            }
        }

        for (let i = 0; i < this.layers.length; i++) {
            result = this.layers[i].onMouseMove(event, sender)
            if (result) {
                break
            }
        }
        return result
    }

    public onMouseLeave(event: MouseEvent): ChartControl | null {
        let result: ChartControl | null = null
        for (const layer of this.layers) {
            result = layer.onMouseLeave(event)
            if (result) {
                return result
            }
        }
        return result
    }

    public onMouseUp(event: MouseEvent): void {
        for (const layer of this.layers) {
            layer.onMouseUp(event)
        }
    }

    draw(layer: CanvasLayer): void {
        if (layer) {
            layer.draw()
        }
    }

    reDrawAll(): void {
        if (this.layerBlockingChart) {
            this.layerBlockingChart.draw()
        }
        for (const layer of this.layers) {
            layer.draw()
        }
    }

    deleteControl(control: ChartControl): void {
        const layer = control.getOwnerLayer()
        if (layer) {
            layer.deleteControl(control)
        }
    }
}
