import { TDataDescriptor, TDataTypes } from '@fto/lib/ft_types/data/data_arrays/DataDescriptionTypes'
import CommonConstants from '../../common/CommonConstants'
import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import { TBarRecord } from '../DataClasses/TBarRecord'

export default class CommonDataUtils {
    static isDataDescriptorEqual(left: TDataDescriptor, right: TDataDescriptor): boolean {
        return (
            left.broker === right.broker &&
            left.timeframe === right.timeframe &&
            left.symbolName === right.symbolName &&
            left.dataType === right.dataType &&
            left.symbolCode === right.symbolCode
        )
    }

    static GetBarsDescriptor(
        symbolName: string,
        timeframe: number,
        dataType: TDataTypes = TDataTypes.dt_Bars,
        broker = CommonConstants.DEFAULT_BROKER,
        symbolCode = symbolName
    ): TDataDescriptor {
        return {
            broker,
            symbolName,
            dataType: dataType,
            symbolCode: symbolCode,
            timeframe
        }
    }

    static GetBarEndDate(bar: TBarRecord, timeframe: number): TDateTime {
        return DateUtils.IncMinute(bar.DateTime, timeframe)
    }

    static isBarEmpty(bar: TBarRecord): boolean {
        return bar.open === 0
    }
}
