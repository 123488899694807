import { t } from 'i18next'

import { showErrorToast, showSuccessToast } from '@root/utils/toasts'
import {
    createChartTemplate,
    createIndicatorTemplate,
    createToolTemplate,
    deleteChartTemplate,
    deleteIndicatorTemplate,
    deleteToolTemplate,
    updateChartTemplate,
    updateIndicatorTemplate,
    updateToolTemplate
} from '@root/utils/api'
import {
    AllTemplates,
    ChartTemplate,
    ChartTemplateReadyToServer,
    IndicatorTemplate,
    Template,
    TemplateMap,
    ToolTemplate
} from '@fto/lib/globals/TemplatesManager/Types'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import {
    getDefaultTemplateByToolName,
    SupportedPaintTools,
    ToolProperties
} from './default_templates/DefaultToolsTemplates'
import type { AxiosResponse } from 'axios'

export class GlobalTemplatesManager {
    private static instance: GlobalTemplatesManager
    private toolTemplates: TemplateMap<ToolTemplate> = new Map()
    private indicatorTemplates: TemplateMap<IndicatorTemplate> = new Map()
    private chartTemplates: Map<string, ChartTemplate[]> = new Map()

    public static get Instance(): GlobalTemplatesManager {
        if (!GlobalTemplatesManager.instance) {
            GlobalTemplatesManager.instance = new GlobalTemplatesManager()
        }
        return GlobalTemplatesManager.instance
    }

    public getToolTemplate(toolType: string, templateName: string): ToolTemplate | undefined {
        return this.getTemplate(this.toolTemplates, toolType, templateName)
    }

    public getToolDefaultTemplate<T extends SupportedPaintTools>(toolType: T): ToolProperties[T] {
        const defaultTemplate = this.fetchToolDefaultTemplate(toolType) || getDefaultTemplateByToolName(toolType)
        if (!defaultTemplate) {
            this.throwDefaultTemplateNotFound()
        }
        return defaultTemplate as ToolProperties[T]
    }

    public fetchToolDefaultTemplate<T extends SupportedPaintTools>(toolType: T): ToolProperties[T] | null {
        return null // TODO: Implement server fetch logic
    }

    public getIndicatorTemplate(indicatorType: string, templateName: string): IndicatorTemplate | undefined {
        return this.getTemplate(this.indicatorTemplates, indicatorType, templateName)
    }

    public addChartTemplate(
        templateName: string,
        chartTemplateJSON: string,
        isRewrite = false,
        saveToServer = true
    ): Promise<string | number | void> {
        const existingTemplates = this.chartTemplates.get(templateName) || []
        const parsedTemplate = JSON.parse(chartTemplateJSON)
        const chartTemplate = new ChartTemplate(templateName, chartTemplateJSON, parsedTemplate.id)
        const serverTemplateData = this.createChartTemplateServerData(templateName, parsedTemplate)

        const templateIndex = existingTemplates.findIndex((t) => t.templateName === templateName)
        const isExistingTemplate = templateIndex !== -1
        return this.handleTemplateAddition(
            isExistingTemplate,
            isRewrite,
            () => this.createAndSaveChartTemplate(chartTemplate, serverTemplateData, existingTemplates, saveToServer),
            () =>
                this.updateAndSaveChartTemplate(
                    chartTemplate,
                    serverTemplateData,
                    existingTemplates,
                    templateIndex,
                    saveToServer
                )
        )
    }

    public removeChartTemplate(templateName: string): Promise<string | number | void> {
        const templates = this.chartTemplates.get(templateName)
        if (!templates) {
            throw new StrangeError('Template with this name does not exist')
        }

        const templateIndex = templates.findIndex((t) => t.templateName === templateName)
        if (templateIndex === -1) {
            throw new StrangeError('Template with this name does not exist')
        }

        const template = templates[templateIndex]
        if (template.id) {
            return deleteChartTemplate(template.id)
                .then(() => {
                    this.chartTemplates.delete(templateName)
                    showSuccessToast({ message: t('templates.toasts.chart.deletedSuccess.message') })
                })
                .catch(() => showErrorToast({ message: t('templates.toasts.chart.deletedError.message') }))
        }
        this.chartTemplates.delete(templateName)
        return Promise.resolve()
    }

    public getChartTemplate(templateName: string): ChartTemplate | undefined {
        return this.chartTemplates.get(templateName)?.find((template) => template.templateName === templateName)
    }

    public parseJSON(json: AllTemplates): void {
        for (const chartTemplate of json.charts) {
            this.addChartTemplate(chartTemplate.name, JSON.stringify(chartTemplate), false, false)
        }

        for (const [toolType, templates] of Object.entries(json.tools)) {
            for (const template of templates) {
                this.addToolTemplate(template.name, toolType, template.template, false, false, template.id)
            }
        }

        for (const [indicatorType, templates] of Object.entries(json.indicators)) {
            for (const template of templates) {
                this.addIndicatorTemplate(template.name, indicatorType, template.template, false, false, template.id)
            }
        }
    }

    public addToolTemplate(
        templateName: string,
        toolName: string,
        toolTemplateJSON: string,
        isRewrite = false,
        saveToServer = true,
        templateID?: string
    ): Promise<string | number | void> {
        const toolTemplate = new ToolTemplate(templateName, toolTemplateJSON)
        const serverTemplateData = { name: templateName, template: toolTemplateJSON }

        const isExistingTemplate = this.getTemplate(this.toolTemplates, toolName, templateName)

        return this.handleTemplateAddition(
            isExistingTemplate,
            isRewrite,
            () => this.createAndSaveToolTemplate(toolTemplate, toolName, serverTemplateData, saveToServer, templateID),
            () => this.updateAndSaveToolTemplate(toolTemplate, toolName, serverTemplateData, saveToServer)
        )
    }

    public removeToolTemplate(toolType: string, templateName: string): Promise<string | number | void> {
        return this.removeTemplate(this.toolTemplates, toolType, templateName, deleteToolTemplate)
    }

    public addIndicatorTemplate(
        templateName: string,
        indicatorName: string,
        indicatorTemplateJSON: string,
        isRewrite = false,
        saveToServer = true,
        templateID?: string
    ): Promise<string | number | void> {
        const indicatorTemplate = new IndicatorTemplate(templateName, indicatorTemplateJSON)
        const serverTemplateData = { name: templateName, template: indicatorTemplateJSON }

        const isExist = this.getTemplate(this.indicatorTemplates, indicatorName, templateName)

        return this.handleTemplateAddition(
            isExist,
            isRewrite,
            () =>
                this.createAndSaveIndicatorTemplate(
                    indicatorTemplate,
                    indicatorName,
                    serverTemplateData,
                    saveToServer,
                    templateID
                ),
            () =>
                this.updateAndSaveIndicatorTemplate(indicatorTemplate, indicatorName, serverTemplateData, saveToServer)
        )
    }

    public removeIndicatorTemplate(indicatorType: string, templateName: string): Promise<string | number | void> {
        return this.removeTemplate(this.indicatorTemplates, indicatorType, templateName, deleteIndicatorTemplate)
    }

    public getToolTemplatesNames(toolType: string): string[] {
        return [...(this.toolTemplates.get(toolType)?.keys() || [])]
    }

    public getIndicatorTemplatesNames(indicatorType: string): string[] {
        return [...(this.indicatorTemplates.get(indicatorType)?.keys() || [])]
    }

    public getChartTemplatesNames(): string[] {
        return [...this.chartTemplates.keys()]
    }

    private getTemplate<T extends Template>(
        templatesMap: TemplateMap<T>,
        type: string,
        templateName: string
    ): T | undefined {
        return templatesMap.get(type)?.get(templateName)
    }

    private getTemplates<T extends Template>(
        templatesMap: TemplateMap<T>,
        type: string,
        templateName: string
    ): T | undefined {
        return templatesMap.get(type)?.get(templateName) || undefined
    }

    private createChartTemplateServerData(templateName: string, parsedTemplate: any): ChartTemplateReadyToServer {
        return {
            name: templateName,
            canvas: JSON.stringify(parsedTemplate.canvas),
            views: JSON.stringify(parsedTemplate.views),
            general: JSON.stringify(parsedTemplate.general)
        }
    }

    private createAndSaveChartTemplate(
        chartTemplate: ChartTemplate,
        serverTemplateData: ChartTemplateReadyToServer,
        templates: ChartTemplate[],
        saveToServer: boolean
    ): Promise<string | number | void> {
        if (saveToServer) {
            return createChartTemplate(serverTemplateData)
                .then((response) => {
                    chartTemplate.id = response.data.id
                    templates.push(chartTemplate)
                    this.chartTemplates.set(chartTemplate.templateName, templates)
                    showSuccessToast({ message: t('templates.toasts.chart.savedSuccess.message') })
                })
                .catch(() => showErrorToast({ message: t('templates.toasts.chart.savedError.message') }))
        }
        templates.push(chartTemplate)
        this.chartTemplates.set(chartTemplate.templateName, templates)
        return Promise.resolve()
    }

    private updateAndSaveChartTemplate(
        chartTemplate: ChartTemplate,
        serverTemplateData: ChartTemplateReadyToServer,
        templates: ChartTemplate[],
        index: number,
        saveToServer: boolean
    ): Promise<string | number | void> {
        const existingTemplate = templates[index]
        if (saveToServer && existingTemplate.id) {
            return updateChartTemplate(existingTemplate.id, serverTemplateData)
                .then(() => {
                    templates[index] = chartTemplate
                    this.chartTemplates.set(chartTemplate.templateName, templates)
                    showSuccessToast({ message: t('templates.toasts.chart.updatedSuccess.message') })
                })
                .catch(() => showErrorToast({ message: t('templates.toasts.chart.updatedError.message') }))
        }
        templates[index] = chartTemplate
        this.chartTemplates.set(chartTemplate.templateName, templates)
        return Promise.resolve()
    }

    private createAndSaveToolTemplate(
        toolTemplate: ToolTemplate,
        toolName: string,
        serverTemplateData: { name: string; template: string },
        saveToServer: boolean,
        templateID?: string
    ): Promise<string | number | void> {
        if (saveToServer) {
            return createToolTemplate(toolName, serverTemplateData)
                .then((response) => {
                    toolTemplate.id = response.data.id
                    const existingMap = this.toolTemplates.get(toolName) || new Map<string, ToolTemplate>()
                    existingMap.set(toolTemplate.templateName, toolTemplate)
                    this.toolTemplates.set(toolName, existingMap) // Use the existing map
                    showSuccessToast({ message: t('templates.toasts.tools.savedSuccess.message') })
                })
                .catch(() => showErrorToast({ message: t('templates.toasts.tools.savedError.message') }))
        }
        toolTemplate.id = templateID || null
        const existingMap = this.toolTemplates.get(toolName) || new Map<string, ToolTemplate>()
        existingMap.set(toolTemplate.templateName, toolTemplate)
        this.toolTemplates.set(toolName, existingMap)
        return Promise.resolve()
    }

    private updateAndSaveToolTemplate(
        toolTemplate: ToolTemplate,
        toolName: string,
        serverTemplateData: { name: string; template: string },
        saveToServer: boolean
    ): Promise<string | number | void> {
        const existingTemplate = this.getTemplates(this.toolTemplates, toolName, toolTemplate.templateName)
        if (saveToServer && existingTemplate && existingTemplate.id) {
            return updateToolTemplate(toolName, existingTemplate.id, serverTemplateData)
                .then(() => {
                    toolTemplate.id = existingTemplate.id
                    const existingMap = this.toolTemplates.get(toolName) || new Map<string, ToolTemplate>()
                    existingMap.set(toolTemplate.templateName, toolTemplate)
                    this.toolTemplates.set(toolName, existingMap)
                    showSuccessToast({ message: t('templates.toasts.tools.updatedSuccess.message') })
                })
                .catch(() => showErrorToast({ message: t('templates.toasts.tools.updatedError.message') }))
        }
        toolTemplate.id = existingTemplate?.id || null
        const existingMap = this.toolTemplates.get(toolName) || new Map<string, ToolTemplate>()
        existingMap.set(toolTemplate.templateName, toolTemplate)
        this.toolTemplates.set(toolName, existingMap)
        return Promise.resolve()
    }

    private createAndSaveIndicatorTemplate(
        indicatorTemplate: IndicatorTemplate,
        indicatorName: string,
        serverTemplateData: { name: string; template: string },
        saveToServer: boolean,
        templateID?: string
    ): Promise<string | number | void> {
        if (saveToServer) {
            return createIndicatorTemplate(indicatorName, serverTemplateData)
                .then((response) => {
                    indicatorTemplate.id = response.data.id
                    const existingMap =
                        this.indicatorTemplates.get(indicatorName) || new Map<string, IndicatorTemplate>()
                    existingMap.set(indicatorTemplate.templateName, indicatorTemplate)
                    this.indicatorTemplates.set(indicatorName, existingMap) // Use the existing map
                    showSuccessToast({ message: t('templates.toasts.indicators.savedSuccess.message') })
                })
                .catch(() => showErrorToast({ message: t('templates.toasts.indicators.savedError.message') }))
        }
        indicatorTemplate.id = templateID || null
        const existingMap = this.indicatorTemplates.get(indicatorName) || new Map<string, IndicatorTemplate>()
        existingMap.set(indicatorTemplate.templateName, indicatorTemplate)
        this.indicatorTemplates.set(indicatorName, existingMap)
        return Promise.resolve()
    }

    private updateAndSaveIndicatorTemplate(
        indicatorTemplate: IndicatorTemplate,
        indicatorName: string,
        serverTemplateData: { name: string; template: string },
        saveToServer: boolean
    ): Promise<string | number | void> {
        const existingTemplate = this.getTemplates(
            this.indicatorTemplates,
            indicatorName,
            indicatorTemplate.templateName
        )
        if (saveToServer && existingTemplate && existingTemplate.id) {
            return updateIndicatorTemplate(indicatorName, existingTemplate.id, serverTemplateData)
                .then(() => {
                    indicatorTemplate.id = existingTemplate.id
                    const existingMap =
                        this.indicatorTemplates.get(indicatorName) || new Map<string, IndicatorTemplate>()
                    existingMap.set(indicatorTemplate.templateName, indicatorTemplate)
                    this.indicatorTemplates.set(indicatorName, existingMap) // Use the existing map
                    showSuccessToast({ message: t('templates.toasts.indicators.updatedSuccess.message') })
                })
                .catch((error) => {
                    showErrorToast({ message: t('templates.toasts.indicators.updatedError.message') })
                })
        }
        indicatorTemplate.id = existingTemplate?.id || null
        const existingMap = this.indicatorTemplates.get(indicatorName) || new Map<string, IndicatorTemplate>()
        existingMap.set(indicatorTemplate.templateName, indicatorTemplate)
        this.indicatorTemplates.set(indicatorName, existingMap)
        return Promise.resolve()
    }

    private removeTemplate<T extends Template>(
        templatesMap: TemplateMap<T>,
        type: string,
        templateName: string,
        deleteFunction: (templateName: string, templateID: string) => Promise<AxiosResponse<any, any>>
    ): Promise<string | number | void> {
        const template = this.getTemplates(templatesMap, type, templateName)

        if (!template) {
            throw new StrangeError('Template with this name does not exist')
        }

        if (template.id) {
            return deleteFunction(type, template.id)
                .then(() => {
                    templatesMap.get(type)?.delete(templateName)
                    showSuccessToast({ message: t('templates.toasts.general.deletedSuccess.message') })
                })
                .catch(() => {
                    showErrorToast({ message: t('templates.toasts.general.deletedError.message') })
                })
        }

        throw new StrangeError('No template ID found')
    }

    private handleTemplateAddition<T>(
        isExist: T | undefined,
        isRewrite: boolean,
        createFunc: () => Promise<string | number | void>,
        updateFunc: () => Promise<string | number | void>
    ): Promise<string | number | void> {
        if (!isExist) {
            return createFunc()
        } else if (isRewrite) {
            return updateFunc()
        }
        return this.noOpPromise() as Promise<string | number | void>
    }

    private noOpPromise<T = void>(): Promise<T | void> {
        return new Promise<T | void>((resolve) => resolve())
    }

    private throwDefaultTemplateNotFound(): never {
        throw new StrangeError('Default template not found')
    }
}
