import { THEME_KEY } from '@fto/ui/lib/theme/ThemeProvider'
import { TChartWindow } from '../charting/chart_windows/ChartWindow'
import { ColorTheme } from '../charting/auxiliary_classes_charting/TColorScheme'
import StrangeError from '../common/common_errors/StrangeError'
import GlobalChartsController from '../globals/GlobalChartsController'
import { LastPaintToolStyleManager } from '../charting/paint_tools/LastPaintToolStyleManager'

export const THEME_FORCE_UPDATE_KEY = 'FTO_THEME_FORCE_UPDATE'
export const THEME_FORCE_UPDATED_PROJECTS_KEY = 'THEME_FORCE_UPDATED_PROJECTS'

export default class ForceSchemesUpdateHelper {
    static updatedForceMode(enable: boolean) {
        localStorage.setItem(THEME_FORCE_UPDATE_KEY, enable.toString())
        ForceSchemesUpdateHelper.clearUpdatedProjectsList()
    }

    static clearUpdatedProjectsList() {
        localStorage.setItem(THEME_FORCE_UPDATED_PROJECTS_KEY, '[]')
    }

    static processChartThemeUpdating(chartWindow: TChartWindow) {
        const shouldUpdate = (localStorage.getItem(THEME_FORCE_UPDATE_KEY) || '').toLowerCase() === 'true'

        if (!shouldUpdate) {
            return
        }

        const theme = localStorage.getItem(THEME_KEY)
        chartWindow.changeColorSchemeByTheme(theme as ColorTheme)
    }

    static processUpdateCurrentChartsSchemes(isDark: boolean, shouldChangeChartsColorSchemes: boolean) {
        GlobalChartsController.Instance.changedColorTheme(
            isDark ? ColorTheme.Dark : ColorTheme.Light,
            shouldChangeChartsColorSchemes
        )

        if (shouldChangeChartsColorSchemes) {
            try {
                LastPaintToolStyleManager.deleteAllToolProperties() // TODO FOR ALL PROJECTS
            } catch (e) {
                // Doesn't matter error. We dont have project id in dashboard
            }
        }
    }

    static markProjectAsUpdated() {
        const updatedProjects = localStorage.getItem(THEME_FORCE_UPDATED_PROJECTS_KEY) || '[]'
        const updatedProjectsList = JSON.parse(updatedProjects)
        const params = new URLSearchParams(window.location.search)
        const projectId = params.get('projectId')

        if (projectId === null) {
            throw new StrangeError('Project id is not defined')
        }

        if (!projectId.length) {
            throw new StrangeError('Project id is empty')
        }

        if (!updatedProjectsList.includes(projectId)) {
            updatedProjectsList.push(projectId)
        }
        localStorage.setItem(THEME_FORCE_UPDATED_PROJECTS_KEY, JSON.stringify(updatedProjectsList))
    }
}
