import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'

export enum TimeUnit {
    SECONDS,
    MILLISECONDS
}

export enum TimeZoneMode {
    PROJECT,
    UTC
}

export class FTODate {
    private _date: Date
    private _timeZoneMode: TimeZoneMode
    constructor(
        value: string | number | Date,
        unit: TimeUnit = TimeUnit.SECONDS,
        timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT
    ) {
        this._timeZoneMode = timeZoneMode
        let valueInMilliseconds = 0
        if (typeof value === 'string') {
            const date = new Date(value)
            if (isNaN(date.getTime())) {
                throw new TypeError('Invalid date string')
            }
            valueInMilliseconds = date.getTime()
        } else if (typeof value === 'number') {
            if (unit === TimeUnit.SECONDS) {
                valueInMilliseconds = value * 1000
            } else if (unit === TimeUnit.MILLISECONDS) {
                valueInMilliseconds = value
            } else {
                throw new Error('Invalid unit type for FTODate')
            }
        } else {
            throw new TypeError('Invalid input type for FTODate')
        }
        this._date = new Date(valueInMilliseconds)
    }

    public valueOf(timeUnit: TimeUnit = TimeUnit.MILLISECONDS) {
        if (timeUnit === TimeUnit.MILLISECONDS) {
            return this._date.getTime()
        }
        return this._date.getTime() / 1000
    }

    public toMilliseconds(timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT) {
        if (timeZoneMode === this._timeZoneMode) {
            return this._date.getTime()
        }

        return this.convertByTimeZoneModeToMilliseconds(timeZoneMode)
    }

    public toSeconds(timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT) {
        if (timeZoneMode === this._timeZoneMode) {
            return this._date.getTime() / 1000
        }

        return this.convertByTimeZoneModeToMilliseconds(timeZoneMode) / 1000
    }

    public toString(timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT) {
        if (timeZoneMode === TimeZoneMode.UTC) {
            return this._date.toISOString()
        }
        return new Date(this.convertByTimeZoneModeToMilliseconds(timeZoneMode)).toISOString()
    }

    public toJSDate(timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT) {
        if (timeZoneMode === this._timeZoneMode) {
            return new Date(this._date.getTime())
        }
        return new Date(this.convertByTimeZoneModeToMilliseconds(timeZoneMode))
    }

    public dayOfMonth(timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT) {
        if (timeZoneMode === this._timeZoneMode) {
            return this._date.getUTCDate()
        }
        return new Date(this.convertByTimeZoneModeToMilliseconds(timeZoneMode)).getUTCDate()
    }

    public monthOf(timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT) {
        if (timeZoneMode === this._timeZoneMode) {
            return this._date.getUTCMonth() + 1
        }
        return new Date(this.convertByTimeZoneModeToMilliseconds(timeZoneMode)).getUTCMonth() + 1
    }

    public weekOf(timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT) {
        const date =
            timeZoneMode === this._timeZoneMode
                ? this._date
                : new Date(this.convertByTimeZoneModeToMilliseconds(timeZoneMode))

        return this.calculateWeekOfYear(date)
    }

    public yearOf(timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT) {
        if (timeZoneMode === this._timeZoneMode) {
            return this._date.getUTCFullYear()
        }
        return new Date(this.convertByTimeZoneModeToMilliseconds(timeZoneMode)).getUTCFullYear()
    }

    public dayOfYear(timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT): number {
        const date =
            timeZoneMode === this._timeZoneMode
                ? this._date
                : new Date(this.convertByTimeZoneModeToMilliseconds(timeZoneMode))

        const start = new Date(Date.UTC(date.getUTCFullYear(), 0, 0)) // Use UTC for "start"

        const diff = date.getTime() - start.getTime()
        const oneDay = 1000 * 60 * 60 * 24
        return Math.floor(diff / oneDay)
    }

    public dayOfWeek(timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT): number {
        if (timeZoneMode === this._timeZoneMode) {
            return this._date.getUTCDay()
        }
        return new Date(this.convertByTimeZoneModeToMilliseconds(timeZoneMode)).getUTCDay()
    }

    public hour(timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT): number {
        if (timeZoneMode === this._timeZoneMode) {
            return this._date.getUTCHours()
        }
        return new Date(this.convertByTimeZoneModeToMilliseconds(timeZoneMode)).getUTCHours()
    }

    public minute(timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT): number {
        if (timeZoneMode === this._timeZoneMode) {
            return this._date.getUTCMinutes()
        }
        return new Date(this.convertByTimeZoneModeToMilliseconds(timeZoneMode)).getUTCMinutes()
    }

    public second(timeZoneMode: TimeZoneMode = TimeZoneMode.PROJECT): number {
        if (timeZoneMode === this._timeZoneMode) {
            return this._date.getUTCSeconds()
        }
        return new Date(this.convertByTimeZoneModeToMilliseconds(timeZoneMode)).getUTCSeconds()
    }

    private convertByTimeZoneModeToMilliseconds(timeZoneMode: TimeZoneMode): number {
        switch (timeZoneMode) {
            case TimeZoneMode.UTC: {
                return GlobalTimezoneDSTController.Instance.convertToInnerlibUnixMilisecondsByTimezoneAndDst(
                    this._date.getTime()
                )
            }

            case TimeZoneMode.PROJECT: {
                return GlobalTimezoneDSTController.Instance.convertFromInnerLibUnixMilisecondsByTimezoneAndDst(
                    this._date.getTime()
                )
            }

            default: {
                throw new Error('Unsupported TimeZoneMode')
            }
        }
    }

    private calculateWeekOfYear(date: Date): number {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1)
        const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000 // 86400000 = миллисекунды в дне
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
    }
}
