import { ChartControlParams } from './ChartControlParams'
import { ImageControl } from '@fto/chart_components/ImageControl'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

export class CloseChartButton extends ImageControl {
    constructor(
        ownerChart: TChartWindow,
        chartControlParams: ChartControlParams,
        image: HTMLImageElement,
        tooltip: string = ''
    ) {
        super(ownerChart, chartControlParams, image, tooltip)
    }

    draw(canvas: TGdiPlusCanvas) {
        if (this.isCloseChartControlVisible()) {
            super.draw(canvas)
        }
    }

    private isCloseChartControlVisible(): boolean {
        return GlobalChartsController.Instance.getChartCount() > 1
    }
}
