import TDownloadableChunk from '@fto/lib/ft_types/data/chunks/DownloadableChunk/DownloadableChunk'
import { TBaseTickChunk } from '@fto/lib/ft_types/data/chunks/TickChunks/BaseTickChunk'
import IDownloadController from '@fto/lib/ft_types/data/data_downloading/IDownloadController'
import MockDownloadTaskQueue from './MockDownloadTaskQueue'
import { TMockDownloadBehavior } from './MockDataDownloadEnums'
import { DateUtils } from '@fto/lib/delphi_compatibility/DateUtils'
import { TDataFormat } from '@fto/lib/ft_types/data/DataEnums'
import { TBarChunk } from '@fto/lib/ft_types/data/chunks/BarChunk'
import MockServerDataImporter from './MockServerDataImporter'
import { TDataRecordWithDate } from '@fto/lib/ft_types/data/DataClasses/TDataRecordWithDate'
import IDateRange from '@fto/lib/delphi_compatibility/DateTimeAuxiliary/IDateRange'

export default class MockDownloadController implements IDownloadController {
    private _taskQueue: MockDownloadTaskQueue = new MockDownloadTaskQueue()

    public IsMock = true
    public WasDownloadMethodCalled = false

    private _ignoreDataOutsideOfRange = true
    public Range: IDateRange = { start: DateUtils.MinPossibleDate, end: DateUtils.MaxPossibleDate }

    public set MockDownloadBehavior(value: TMockDownloadBehavior) {
        this._taskQueue.MockDownloadBehavior = value
    }

    public Reset(): void {
        this.WasDownloadMethodCalled = false
        this._ignoreDataOutsideOfRange = false
    }

    IgnoreDataOutsideOfRange(range: IDateRange) {
        this._ignoreDataOutsideOfRange = true
        this.Range = range
    }

    onProjectClosing() {
        //do nothing
    }

    onProjectLoading() {
        //do nothing
    }

    onLastTickTimeChanged(date: number): void {
        //do nothing
    }

    public onVisibleRangeChanged(startVisibleDate: number, endVisibleDate: number): void {
        //do nothing
    }
    public onTimeframeChanged(newTimeframe: number): void {
        //do nothing
    }

    loadHistoryIfNecessary(chunk: TDownloadableChunk<TDataRecordWithDate>, forceLoading?: boolean | undefined): void {
        this.WasDownloadMethodCalled = true
        if (MockServerDataImporter.HasDataForChunk(chunk)) {
            this._taskQueue.createTask(chunk)
        } else {
            this.importEmptyData(chunk)
        }
    }

    importEmptyData(chunk: TDownloadableChunk<TDataRecordWithDate>) {
        chunk.ImportChunkData([], TDataFormat.df_ActualRecords)
    }

    isAllDataLoaded(): boolean {
        return true
    }
    getLastLoadedChunk(): TBaseTickChunk | TBarChunk | null {
        return null
    }
    ReplaceWithMocks(): void {
        //do nothing
    }
    ResetWasDownloadMethodCalled() {
        this.WasDownloadMethodCalled = false
    }
}
