import { OrderValues, MarketValues } from '../../OrderWndStructs'
import { PriceValidationStrategy } from '../PriceValidationStrategy'
import { ValidationErrorType } from '../ValidationErrorType'

export class StoplossValidationBuyStop extends PriceValidationStrategy {
    constructor(marketValues: MarketValues, orderValues: OrderValues) {
        super(marketValues, orderValues)
    }

    public isValid(value: string): boolean {
        let result = false

        if (super.isValid(value)) {
            const valueNum = parseFloat(value)

            if (valueNum >= this.orderValues.atPrice) {
                const errorText = 'stoplossPriceMustBeLowerForBuyStop'
                this.errorDescription = {
                    type: ValidationErrorType.WARNING,
                    description: errorText
                }
            } else if (
                valueNum >
                this.orderValues.atPrice - this.getMinDistanceToPrice() * this.marketValues.minimumPriceChange
            ) {
                const errorText = `stoplossPriceMustBeLowerForBuyStopMinimumBy`
                this.errorDescription = {
                    type: ValidationErrorType.WARNING,
                    description: errorText,
                    errorValue: this.getMinimumDistanceToPriceStr()
                }
            } else {
                result = true
            }
        }

        return result
    }
}
