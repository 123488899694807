import { initialData } from './constants'
import {
    GroupsType,
    LimitOrderParams,
    MarketOrderParams,
    orderModelType,
    PikerPrice,
    StopOrderParams,
    UpdaterType
} from './types'
import { OrderModel } from '@fto/lib/OrderModalClasses/OrderModel'
import { EOperationType, MarketValues, OrderType } from '@fto/lib/OrderModalClasses/OrderWndStructs'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import GlobalProcessingCore from '@fto/lib/globals/GlobalProcessingCore'
import { OrderDataType } from '@fto/lib/store/ordersStore/types'
import { makeAutoObservable } from 'mobx'
import { TPtRiskReward, TRiskToolType } from '@fto/lib/charting/paint_tools/SpecificTools/ptRiskReward'
import { determineOrderTypeFromRR, getMarketValuesFromActiveChart } from './helper'
import { addModal } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { OneClickTradingMode } from '@fto/lib/ft_types/common/OptionsUnit'

/**
 * NOTE:
 * onInitCallbacks stored not in CreateOrderStore, because Mobx modifies objects
 * CAUSE: mobx modifies pointer to callback, so in registerOnInitOnce callback is not removed
 */
let onInitCallbacks: Array<() => void> = []

export function registerOnInit(callback: () => void) {
    onInitCallbacks.push(callback)
}

export function registerOnInitOnce(callback: () => void) {
    const wrappedCallback = () => {
        try {
            callback()
        } finally {
            onInitCallbacks = onInitCallbacks.filter((cb) => cb !== wrappedCallback)
        }
    }
    onInitCallbacks.push(wrappedCallback)
}

function executeOnInitCallbacks() {
    for (const callback of onInitCallbacks) callback()
}

class CreateOrderStore {
    shouldReinitializeModel: boolean
    data: orderModelType

    private orderModalInstance
    public marketValues

    constructor() {
        makeAutoObservable(this)

        this.shouldReinitializeModel = false
        this.data = initialData

        this.updateData = this.updateData.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onInputFocus = this.onInputFocus.bind(this)
        this.onInputBlur = this.onInputBlur.bind(this)
        this.handleOrderCreate = this.handleOrderCreate.bind(this)
        this.initializeModel = this.initializeModel.bind(this)
        this.resetStore = this.resetStore.bind(this)
        this.onPricePick = this.onPricePick.bind(this)
        this.modifyOrder = this.modifyOrder.bind(this)
        this.editOrder = this.editOrder.bind(this)
        this.updateLoadingState = this.updateLoadingState.bind(this)
        this.setNewSymbol = this.setNewSymbol.bind(this)
        this.onModalClose = this.onModalClose.bind(this)
        this.importFromRRTool = this.importFromRRTool.bind(this)

        this.startOrderProcessByPlacementMode = this.startOrderProcessByPlacementMode.bind(this)
        this.openNewOrderModal = this.openNewOrderModal.bind(this)
        this.placeOrder = this.placeOrder.bind(this)
        this.prepareOrder = this.prepareOrder.bind(this)

        this.marketValues = new MarketValues()
        this.orderModalInstance = new OrderModel(this.marketValues)

        try {
            GlobalChartsController.Instance.attachObserver(this.orderModalInstance)
        } catch {
            //this is ok, during the initialization some objects are not created yet and can throw error
            // StrangeSituationNotifier.NotifyAboutUnexpectedSituation(e as Error)
        }
    }

    initializeModel() {
        this.shouldReinitializeModel = false

        this.marketValues = getMarketValuesFromActiveChart(this.marketValues)
        this.orderModalInstance.initialize()
        this.updateData((prevSettings) => ({
            ...prevSettings,
            ...this.orderModalInstance.getOrderModelData()
        }))

        GlobalChartsController.Instance.setIsModalOpenToControlsManager(true)
        GlobalProcessingCore.ProcessingCore.makeAllOpenPositionsTransparent(true)

        executeOnInitCallbacks()
    }

    public readyToInit() {
        this.shouldReinitializeModel = true
    }

    public importFromRRTool(tool: TPtRiskReward) {
        // always first set symbol, since it resets all other values
        this.setNewSymbol(tool.chart.ChartWindow.SelectedSymbolName)
        this.orderModalInstance.onChangeOrderType(determineOrderTypeFromRR(tool, this.marketValues))
        this.orderModalInstance.onChangeOperationType(
            tool.RiskToolType === TRiskToolType.rt_Buy ? EOperationType.BUY : EOperationType.SELL
        )
        this.orderModalInstance.onStoplossEnabled()
        this.orderModalInstance.onTakeprofitEnabled()
        this.orderModalInstance.onChangeAtPrice(tool.OpenPrice.toString())
        this.orderModalInstance.onChangeLot(tool.lot.toString())
        this.orderModalInstance.onChangeTakeprofitPrice(tool.TakeProfit.toString())
        this.orderModalInstance.onChangeStoplossPrice(tool.StopLoss.toString())
        this.orderModalInstance.onChangeRiskRewardRatio()
        this.onChange('m_lot', tool.lot)

        this.updateData((prevSettings) => ({
            ...prevSettings,
            ...this.orderModalInstance.getOrderModelData()
        }))
        this.shouldReinitializeModel = true
    }

    public startOrderProcessByPlacementMode(
        placementMode: OneClickTradingMode,
        symbol: string,
        orderParams: LimitOrderParams | MarketOrderParams | StopOrderParams
    ) {
        if (placementMode === OneClickTradingMode.Instant) this.placeOrder(symbol, orderParams)
        else this.openNewOrderModal(symbol, orderParams)
    }

    public openNewOrderModal(symbol: string, orderParams: LimitOrderParams | MarketOrderParams | StopOrderParams) {
        this.resetStore()
        this.setNewSymbol(symbol)

        this.prepareOrder(orderParams)

        this.updateData((prevSettings) => ({
            ...prevSettings,
            ...this.orderModalInstance.getOrderModelData()
        }))

        this.shouldReinitializeModel = true
        addModal(MODAL_NAMES.chart.orderModal, { source: 'one_click_trading' })
    }

    public placeOrder(symbol: string, orderParams: LimitOrderParams | MarketOrderParams | StopOrderParams) {
        this.initializeModel()

        this.prepareOrder(orderParams)

        this.handleOrderCreate({ comment: '' })
        this.updateData((prevSettings) => ({
            ...prevSettings,
            ...this.orderModalInstance.getOrderModelData()
        }))
    }

    public prepareOrder(orderParams: LimitOrderParams | MarketOrderParams | StopOrderParams) {
        // Order types
        this.orderModalInstance.onChangeOrderType(orderParams.orderType)
        this.orderModalInstance.onChangeOperationType(orderParams.operationType)

        // Price
        const price =
            orderParams.orderType === OrderType.LIMIT || orderParams.orderType === OrderType.STOP
                ? orderParams.price
                : this.marketValues.ask
        this.orderModalInstance.onChangeAtPrice(price.toString())

        // Lot
        this.orderModalInstance.onChangeLot(orderParams.lot.toString())
        this.onChange('m_lot', orderParams.lot)

        // Stoploss and Takeprofit
        if (orderParams.orderType === OrderType.LIMIT || orderParams.orderType === OrderType.STOP) {
            this.orderModalInstance.onStoplossDisabled()
            this.orderModalInstance.onTakeprofitDisabled()
        } else {
            const stopLoss = orderParams.stopLoss
            const takeProfit = orderParams.takeProfit

            if (orderParams.operationType === EOperationType.BUY) {
                if (stopLoss === undefined) {
                } // do nothing
                else if (stopLoss < price) {
                    this.orderModalInstance.onStoplossEnabled()
                    this.orderModalInstance.onChangeStoplossPrice(stopLoss.toString())
                } else if (stopLoss > price) {
                    this.orderModalInstance.onTakeprofitEnabled()
                    this.orderModalInstance.onChangeTakeprofitPrice(stopLoss.toString())
                } else {
                    throw new Error('Stoploss price cannot be equal to the market price.')
                }

                if (takeProfit === undefined) {
                } // do nothing
                else if (takeProfit > price) {
                    this.orderModalInstance.onTakeprofitEnabled()
                    this.orderModalInstance.onChangeTakeprofitPrice(takeProfit.toString())
                } else if (takeProfit < price) {
                    this.orderModalInstance.onStoplossEnabled()
                    this.orderModalInstance.onChangeStoplossPrice(takeProfit.toString())
                } else {
                    throw new Error('Takeprofit price cannot be equal to the market price.')
                }
            } else {
                if (stopLoss === undefined) {
                } // do nothing
                else if (stopLoss > price) {
                    this.orderModalInstance.onStoplossEnabled()
                    this.orderModalInstance.onChangeStoplossPrice(stopLoss.toString())
                } else if (stopLoss < price) {
                    this.orderModalInstance.onTakeprofitEnabled()
                    this.orderModalInstance.onChangeTakeprofitPrice(stopLoss.toString())
                } else {
                    throw new Error('Stoploss price cannot be equal to the market price.')
                }

                if (takeProfit === undefined) {
                } // do nothing
                else if (takeProfit < price) {
                    this.orderModalInstance.onTakeprofitEnabled()
                    this.orderModalInstance.onChangeTakeprofitPrice(takeProfit.toString())
                } else if (takeProfit > price) {
                    this.orderModalInstance.onStoplossEnabled()
                    this.orderModalInstance.onChangeStoplossPrice(takeProfit.toString())
                } else {
                    throw new Error('Takeprofit price cannot be equal to the market price.')
                }
            }
        }
    }

    resetStore() {
        this.shouldReinitializeModel = false
        this.data = initialData
        this.orderModalInstance.resetInitialization()
        onInitCallbacks = []
    }

    updateData(updater: UpdaterType) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.data = updater(this.data)
        } else {
            // If updater is a value, directly apply the updates
            this.data = updater
        }
    }

    onChange(value: any, key: any, group: GroupsType = '') {
        this.updateData((prevSettings) => ({
            ...prevSettings,
            ...this.orderModalInstance.processInputChanged(this.data, value, key, group)
        }))
    }

    onInputFocus(value: any, key: any, group: GroupsType = '') {
        this.updateData((prevSettings) => ({
            ...prevSettings,
            ...this.orderModalInstance.processFocusChanged(this.data, value, key, group)
        }))
    }

    onInputBlur(value: any, key: any, group: GroupsType = '') {
        this.updateData((prevSettings) => ({
            ...prevSettings,
            ...this.orderModalInstance.processOnBlur(this.data, value, key, group)
        }))
    }

    handleOrderCreate(params: { comment: string; source?: string }) {
        this.orderModalInstance.placeOrder(params)
    }

    onPricePick(key: PikerPrice) {
        this.updateData((prevSettings) => ({
            ...prevSettings,
            ...this.orderModalInstance.onPricePick(this.data, key)
        }))
    }

    editOrder(order: OrderDataType) {
        // parse order data and set up data into order model
        this.updateData((prevSettings) => ({
            ...prevSettings,
            ...this.orderModalInstance.editOrder(order)
        }))
    }

    modifyOrder(comment: string) {
        this.orderModalInstance.modifyOrder(comment)
    }

    reverseOrder(order: OrderDataType) {
        this.orderModalInstance.reverseOrder(order)
    }

    moveToBrakeEven(order: OrderDataType) {
        this.orderModalInstance.moveToBreakEven(order)
    }

    doublePosition(order: OrderDataType) {
        this.orderModalInstance.doublePosition(order)
    }

    updateLoadingState(isLoading: boolean) {
        this.updateData((prev) => ({
            ...prev,
            isLoading
        }))
    }

    setNewSymbol(symbol: string) {
        this.orderModalInstance.setNewSymbol(symbol)
    }

    onModalClose() {
        this.orderModalInstance.onOrderModalClose()
    }
}
export default new CreateOrderStore()
