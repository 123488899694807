import ICommonFunctionsProcRec from '../CommonProcRecInterfaces/ICommonFunctionsProcRec'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'

export class CommonFunctionsProcRecImplementation
    extends BasicProcRecImplementation
    implements ICommonFunctionsProcRec
{
    public Alert(...args: any[]): void {
        alert(args.join(' '))
    }

    public Print(...args: any[]): void {
        // eslint-disable-next-line no-console
        console.log(...args)
    }

    public is_A_lessThan_b(a: number, b: number): boolean {
        return this.NormalizeDouble(a, 8) < this.NormalizeDouble(b, 8)
    }

    public is_A_greaterThan_b(a: number, b: number): boolean {
        return this.NormalizeDouble(a, 8) > this.NormalizeDouble(b, 8)
    }

    public GetImplementation(): ICommonFunctionsProcRec {
        return {
            Alert: this.Alert.bind(this),
            Print: this.Print.bind(this),
            is_A_lessThan_b: this.is_A_lessThan_b.bind(this),
            is_A_greaterThan_b: this.is_A_greaterThan_b.bind(this)
        }
    }
}
