import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { addModal, Button, Flex, Separator } from '@fto/ui'
import { JumpToController } from '@fto/lib/JumpTo/JumpToController'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { DROPDOWN_OPTIONS } from '@root/pages/ChartPage/components/JumpToSettingsModal/constants'

import { RibbonInnerDropdownType, RibbonOptionType } from '../../../../types'
import InnerDropdown from '../../../../InnerDropdown'

import styles from '../../../../../index.module.scss'

type Props = {
    close: () => void
    dropdownVisibleOptions: typeof DROPDOWN_OPTIONS
    setDropdownVisibleOptions: Dispatch<SetStateAction<typeof DROPDOWN_OPTIONS>>
}

const AdditionalJumpToDropdownOptions: FC<Props> = ({ close, dropdownVisibleOptions, setDropdownVisibleOptions }) => {
    const { t } = useTranslation()

    const jumptoController = useMemo(() => JumpToController.Instance(), [])

    const sessionOptions = useMemo(
        () =>
            [
                {
                    value: 'asianOpen',
                    name: t('ribbon.jumpTo.asianOpen'),
                    action: () => jumptoController.jumpToAsianSessionOpen()
                },
                {
                    value: 'asianClose',
                    name: t('ribbon.jumpTo.asianClose'),
                    action: () => jumptoController.jumpToAsianSessionClose()
                },
                {
                    value: 'NYOpen',
                    name: t('ribbon.jumpTo.NYOpen'),
                    action: () => jumptoController.jumpToNewYorkSessionOpen()
                },
                {
                    value: 'NYClose',
                    name: t('ribbon.jumpTo.NYClose'),
                    action: () => jumptoController.jumpToNewYorkSessionClose()
                },
                {
                    value: 'londonOpen',
                    name: t('ribbon.jumpTo.londonOpen'),
                    action: () => jumptoController.jumpToLondonSessionOpen()
                },
                {
                    value: 'londonClose',
                    name: t('ribbon.jumpTo.londonClose'),
                    action: () => jumptoController.jumpToLondonSessionClose()
                },
                {
                    value: 'silverBullet',
                    name: t('ribbon.jumpTo.silver'),
                    action: () => jumptoController.jumpToSilverBulletSessionOpen()
                }
            ] as RibbonOptionType[],
        [jumptoController]
    )

    const filteredSessionOptions = useMemo(() => {
        return sessionOptions.filter((option) => {
            const foundOption = dropdownVisibleOptions.sessions.find(
                (visible: { value: string; isShown: boolean }) => visible.value === option.value && visible.isShown
            )
            return !!foundOption
        })
    }, [sessionOptions, dropdownVisibleOptions.sessions])

    const sessionOptionsDropdown: RibbonInnerDropdownType = useMemo(
        () => ({
            value: 'nextSessions',
            name: t('ribbon.jumpTo.nextSessions'),
            options: filteredSessionOptions
        }),
        [filteredSessionOptions]
    )

    const handleOpenSettings = useCallback(() => {
        addModal(MODAL_NAMES.chart.jumpToSettings, { setDropdownVisibleOptions: setDropdownVisibleOptions })
        close()
    }, [close, setDropdownVisibleOptions])

    return (
        <Flex direction='column' gap={4}>
            {filteredSessionOptions.length > 0 && (
                <>
                    <Separator width='95%' color='gray-400' />
                    <InnerDropdown dropdown={sessionOptionsDropdown} close={close} />
                </>
            )}
            <Separator width='95%' color='gray-400' />
            <Button
                block
                classNames={{ content: styles.buttonContent }}
                type='tetriatry-white'
                size='compact'
                label={t('global.settings')}
                onClick={handleOpenSettings}
            />
        </Flex>
    )
}

export default AdditionalJumpToDropdownOptions
