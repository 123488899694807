import { AvailableOptionsSettings, ConfigType } from '../../components/types/options.types'

const GeneralConfig: { [key: string]: AvailableOptionsSettings } = {
    Leverage: {
        key: 'Leverage',
        type: 'sliderSetting',
        name: 'settings.leverage.rows.leverage',
        valueConfigType: ConfigType.ProjectInfo,
        valueKey: 'leverage',
        maxValue: 1000,
        divisionFactor: 1,
        step: 1,
        withInputField: true,
        pointsToShow: [1, 250, 500, 750, 1000],
        formatLabel: (val) => {
            return val + 'X'
        },
        warningTooltip: 'settings.leverage.warnings.leverageTooltip',
        warningMessage: 'settings.leverage.warnings.leverageMessage'
    }
}

export default GeneralConfig
