import { APIConstants } from '../../common/CommonConstantsForExternalModules'
import { TIndexBuffer } from '../api/IIndicatorApi'
import { IndicatorImplementation } from '../api/IndicatorImplementation'
import { TDrawStyle, TPenStyle } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { TOutputWindow } from '../IndicatorTypes'

export default class PreviousLevels extends IndicatorImplementation {
    // Buffers
    PDH!: TIndexBuffer
    PDL!: TIndexBuffer
    PWH!: TIndexBuffer
    PWL!: TIndexBuffer
    PMH!: TIndexBuffer
    PML!: TIndexBuffer

    Init(): void {
        // Recalculate indicator on every tick
        this.api.RecalculateMeAlways()
        // Set the name of the indicator
        this.api.IndicatorShortName('PreviousLevels')
        // Set the indicator to be displayed in the chart window
        this.api.SetOutputWindow(TOutputWindow.ow_ChartWindow)
        // Define the empty value for buffers
        this.api.SetEmptyValue(0)

        // Define the number of indicator buffers
        this.api.IndicatorBuffers(6)

        // Initialize PDH Buffer
        this.PDH = this.api.CreateIndexBuffer()
        this.api.SetIndexBuffer(0, this.PDH)
        this.api.SetIndexStyle(0, TDrawStyle.ds_Line, TPenStyle.psDash, 2, '#0000FF') // Blue
        this.api.SetIndexLabel(0, 'Previous Day High')

        // Initialize PDL Buffer
        this.PDL = this.api.CreateIndexBuffer()
        this.api.SetIndexBuffer(1, this.PDL)
        this.api.SetIndexStyle(1, TDrawStyle.ds_Line, TPenStyle.psDash, 2, '#FF0000') // Red
        this.api.SetIndexLabel(1, 'Previous Day Low')

        // Initialize PWH Buffer
        this.PWH = this.api.CreateIndexBuffer()
        this.api.SetIndexBuffer(2, this.PWH)
        this.api.SetIndexStyle(2, TDrawStyle.ds_Line, TPenStyle.psDash, 2, '#00FF00') // Green
        this.api.SetIndexLabel(2, 'Previous Week High')

        // Initialize PWL Buffer
        this.PWL = this.api.CreateIndexBuffer()
        this.api.SetIndexBuffer(3, this.PWL)
        this.api.SetIndexStyle(3, TDrawStyle.ds_Line, TPenStyle.psDash, 2, '#FFA500') // Orange
        this.api.SetIndexLabel(3, 'Previous Week Low')

        // Initialize PMH Buffer
        this.PMH = this.api.CreateIndexBuffer()
        this.api.SetIndexBuffer(4, this.PMH)
        this.api.SetIndexStyle(4, TDrawStyle.ds_Line, TPenStyle.psDash, 2, '#FF00FF') // Magenta
        this.api.SetIndexLabel(4, 'Previous Month High')

        // Initialize PML Buffer
        this.PML = this.api.CreateIndexBuffer()
        this.api.SetIndexBuffer(5, this.PML)
        this.api.SetIndexStyle(5, TDrawStyle.ds_Line, TPenStyle.psDash, 2, '#00FFFF') // Cyan
        this.api.SetIndexLabel(5, 'Previous Month Low')
    }

    Calculate(index: number): void {
        // Get the previous day high and low
        const PDH = this.api.iHigh(this.api.Symbol(), APIConstants.PERIOD_D1, 1)
        const PDL = this.api.iLow(this.api.Symbol(), APIConstants.PERIOD_D1, 1)

        // Get the previous week high and low
        const PWH = this.api.iHigh(this.api.Symbol(), APIConstants.PERIOD_W1, 1)
        const PWL = this.api.iLow(this.api.Symbol(), APIConstants.PERIOD_W1, 1)

        // Get the previous month high and low
        const PMH = this.api.iHigh(this.api.Symbol(), APIConstants.PERIOD_MN1, 1)
        const PML = this.api.iLow(this.api.Symbol(), APIConstants.PERIOD_MN1, 1)

        // Set the buffer values for the current index
        this.PDH.setValue(index, PDH)
        this.PDL.setValue(index, PDL)
        this.PWH.setValue(index, PWH)
        this.PWL.setValue(index, PWL)
        this.PMH.setValue(index, PMH)
        this.PML.setValue(index, PML)
    }
}
