import { COLOR_NAMES } from '@fto/ui'
import { EOperationType } from '@fto/lib/OrderModalClasses/OrderWndStructs'

export const getTabTextColor = (
    type: EOperationType,
    activeTab: EOperationType,
    isLight: boolean
): (typeof COLOR_NAMES)[number] => {
    const isActive = type === activeTab

    if (isActive) {
        return isLight ? 'white' : 'gray-1000'
    }

    const isSell = type === EOperationType.SELL

    return isSell ? 'red-500' : 'primary-500'
}
