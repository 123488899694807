import { TChartOptions } from '@fto/lib/charting/TChartOptions'
import { TColorScheme } from '@fto/lib/charting/auxiliary_classes_charting/TColorScheme'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TProjectInfo } from '@fto/lib/ft_types/common/BasicClasses/ProjectInfo'
import { TOptions } from '@fto/lib/ft_types/common/OptionsUnit'
import { OptionType as DropdownOption } from '@fto/ui/lib/components/SimpleDropdown'

type StringPropertyNames<T> = {
    [K in keyof T]: T[K] extends string ? K : never
}[keyof T]
export type StringProperties<T> = Pick<T, StringPropertyNames<T>>

type NumberPropertyNames<T> = {
    [K in keyof T]: T[K] extends number ? K : never
}[keyof T]
export type NumberProperties<T> = Pick<T, NumberPropertyNames<T>>

type BooleanPropertyNames<T> = {
    [K in keyof T]: T[K] extends boolean ? K : never
}[keyof T]
export type BooleanProperties<T> = Pick<T, BooleanPropertyNames<T>>

type FunctionPropertyNames<T> = {
    [K in keyof T]: T[K] extends (...args: any[]) => any ? K : never
}[keyof T]
export type FunctionProperties<T> = Pick<T, FunctionPropertyNames<T>>

export type OptionsType = keyof TChartOptions | keyof TOptions

export type Option = { [key: string]: any }

export type RadioOption = {
    value: any
    titleKey: string
    descriptionKey?: string
}

export interface OptionSettingRef {
    name: string
    loadOptionSetting: (data: any, shouldUpdateConfig: boolean) => any
}

export enum ConfigType {
    ColorScheme = 'ColorScheme',
    ChartOptions = 'ChartOptions',
    GlobalOptions = 'GlobalOptions',
    ProjectInfo = 'ProjectInfo'
}

export const ConfigTypes = {
    [ConfigType.ColorScheme]: TColorScheme,
    [ConfigType.ChartOptions]: TChartOptions,
    [ConfigType.GlobalOptions]: TOptions,
    [ConfigType.ProjectInfo]: TProjectInfo
}

export type SliderSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate?: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type InputSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    maxValue?: number
    minValue?: number
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate?: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type DropdownSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate?: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type ColorSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate?: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type CheckboxSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate?: (tab: string, newValue: any, prevValue: any, key: string) => void
    setChildsDisabled: (state: boolean) => void
}

export type CheckboxAndColorSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate?: (tab: string, newValue: any, prevValue: any, key: string) => void
    setChildsDisabled: (state: boolean) => void
}

export type CheckboxAndDropdownSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate?: (tab: string, newValue: any, prevValue: any, key: string) => void
    setChildsDisabled: (state: boolean) => void
}

export type RadioSettingType = {
    tab: string
    chart: TChartWindow
    option: Option
    applyToAll: boolean
    shouldBeDisabled: boolean
    onDifferentSettingsUpdate?: (tab: string, newValue: any, prevValue: any, key: string) => void
}

export type OptionSetting = {
    key: string
    type: string
    subOptions?: { [key: string]: AvailableOptionsSettings }
    ignoreSuboptionLevel?: boolean
    image?: string
    description?: string
    warningKey?: string
    warningTooltip?: string
    warningMessage?: string
    stopTestingOnChange?: boolean
}

export type SliderSetting = {
    type: 'sliderSetting'
    name: string
    valueKey: string
    valueConfigType: ConfigType
    maxValue: number
    divisionFactor: number
    withInputField: boolean
    step?: number
    withPoints?: boolean
    pointsToShow?: number[]
    formatLabel?: (value: number) => string | number
} & OptionSetting

export type ColorSetting = {
    type: 'colorSetting'
    name: string
    colorKey: string
    colorConfigType: ConfigType
} & OptionSetting

export type CheckboxSetting = {
    type: 'checkboxSetting'
    name: string
    booleanKey: string
    booleanConfigType: ConfigType
    invertValue?: boolean
} & OptionSetting

export type CheckboxAndColorSetting = {
    type: 'checkboxAndColorSetting'
    name: string
    colorKey: string
    colorConfigType: ConfigType
    booleanKey: string
    booleanConfigType: ConfigType
    invertValue?: boolean
} & OptionSetting

export type CheckboxAndDropdownSetting = {
    type: 'checkboxAndDropdownSetting'
    name: string
    valueKey: string
    valueConfigType: ConfigType
    booleanKey: string
    booleanConfigType: ConfigType
    options: DropdownOption[]
} & OptionSetting

export type InputSetting = {
    type: 'inputSetting'
    name: string
    valueKey: string
    valueConfigType: ConfigType
} & OptionSetting

export type DropdownSetting = {
    type: 'dropdownSetting'
    name: string
    valueKey: string
    valueKeyMethod?: string
    valueConfigType: ConfigType
    options: DropdownOption[]
} & OptionSetting

export type RadioSetting = {
    type: 'radioSetting'
    valueKey: string
    valueKeyMethod?: string
    valueConfigType: ConfigType
    options: RadioOption[]
} & OptionSetting

export type AvailableOptionsSettings =
    | SliderSetting
    | ColorSetting
    | CheckboxSetting
    | CheckboxAndColorSetting
    | InputSetting
    | DropdownSetting
    | CheckboxAndDropdownSetting
    | RadioSetting
