import StrangeError from '../common/common_errors/StrangeError'
import CommonUtils from '../ft_types/common/BasicClasses/CommonUtils'
import { TSymbolList } from '../ft_types/data/SymbolList'
import MockSymbolList from '../mocks/MocksForTesting/MockSymbolList'

export default class GlobalSymbolList {
    private static _symbolList: TSymbolList | undefined

    public static Init(): void {
        GlobalSymbolList._symbolList = new TSymbolList()
    }

    public static Destroy(): void {
        if (GlobalSymbolList._symbolList) {
            GlobalSymbolList._symbolList.ClearAll()
            GlobalSymbolList._symbolList.ClearQueuedTick()
            GlobalSymbolList._symbolList = undefined
        }
    }

    public static get SymbolList(): TSymbolList {
        if (GlobalSymbolList._symbolList) {
            return GlobalSymbolList._symbolList
        } else {
            if (CommonUtils.IsInUnitTest) {
                GlobalSymbolList.InitForUnitTests()
            } else {
                GlobalSymbolList.Init()
            }
            if (!GlobalSymbolList._symbolList) {
                throw new StrangeError('GlobalSymbolList._symbolList is still not initialized')
            }
            return GlobalSymbolList._symbolList
        }
    }

    public static InitForUnitTests(): void {
        this._symbolList = new MockSymbolList()
    }
}
