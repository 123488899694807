export enum TDataArrayEvents {
    de_ChunkLoaded = 'de_ChunkLoaded',
    de_MapDownloaded = 'de_MapDownloaded',
    de_SeekCompleted = 'de_SeekCompleted',
    de_SymbolInfoLoaded = 'de_SymbolInfoLoaded',
    de_LoadingErrorHappened = 'de_LoadingErrorHappened',
    de_LastBarDownloaded = 'de_LastBarDownloaded',
    de_LastBarDownloadError = 'de_LastBarDownloadError',
    de_OutOfHistoryBoundsError = 'de_OutOfHistoryBoundsError'
}

export enum TTaskStatus {
    ts_WaitingToStart = 'ts_WaitingToStart',
    ts_Working = 'ts_Working',
    ts_Completed = 'ts_Completed',
    ts_Completed_WithError = 'ts_Completed_WithError'
}

export enum TDataAvailability {
    da_NoMapYet = 'da_NoMapYet',
    da_MissingForThisDateRange = 'da_MissingForThisDateRange',
    da_SomeAvailable = 'da_SomeAvailable',
    da_AllAvailable = 'da_AllAvailable',
    da_NotSeekedYet = 'da_NoLastItemYet'
}
