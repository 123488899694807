import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TChunkMapStatus } from '@fto/lib/ft_types/data/TChunkMapStatus'
import { TDataFormat } from '@fto/lib/ft_types/data/DataEnums'
import { TFMBarsArray } from '@fto/lib/ft_types/data/data_arrays/chunked_arrays/BarsArray/BarsArray'
import { TBarsMapDownloadTask } from '@fto/lib/ft_types/data/data_downloading/DownloadTasks/BarMapDownloadTask'
import { TBasicDownloadTask } from '@fto/lib/ft_types/data/data_downloading/DownloadTasks/BasicDownloadTask'
import IDataDownloader from '@fto/lib/ft_types/data/data_downloading/IDataDownloader'
import { IMockSymbolData } from '../MocksForTesting/MockDataInterfaces'
import GlobalSymbolList from '@fto/lib/globals/GlobalSymbolList'
import MockServerDataImporter from './MockServerDataImporter'

export default class MockDataDownloader implements IDataDownloader {
    public IsMock = true
    public WasDownloadMethodCalled = false

    public Reset(): void {
        this.WasDownloadMethodCalled = false
    }

    public SetMockDataForSymbol(symbolName: string, mockSymbolData: IMockSymbolData): void {
        MockServerDataImporter.MockData[symbolName] = mockSymbolData
        if (!this.IsTimeframesListEmpty(mockSymbolData)) {
            this.ActivateAppropriateTimeframes(symbolName, mockSymbolData)
        }
    }

    private IsTimeframesListEmpty(mockData: IMockSymbolData) {
        return Object.keys(mockData.timeframes).length === 0
    }

    private ActivateAppropriateTimeframes(symbolName: string, mockData: IMockSymbolData): void {
        const symbolData = GlobalSymbolList.SymbolList.GetOrCreateSymbol(symbolName)
        if (!symbolData) throw new StrangeError('Symbol not found')
        //get keys from mockData.timeframes
        const timeframeKeys = Object.keys(mockData.timeframes)
        //activate each timeframe
        for (const timeframe of timeframeKeys) {
            symbolData.EnsureTimeframeIsActive(Number(timeframe))
        }
    }

    private MockDownloadMap(barsArray: TFMBarsArray) {
        const symbolName = barsArray.DataDescriptor.symbolName
        const symbolMockData = MockServerDataImporter.MockData[symbolName]
        if (!symbolMockData) {
            if (MockServerDataImporter.NoDataNeeded) {
                return
            } else {
                throw new StrangeError(`No mock data for symbol ${symbolName}`)
            }
        }

        const timeframeMockData = symbolMockData.timeframes[barsArray.DataDescriptor.timeframe]
        if (!timeframeMockData) {
            if (MockServerDataImporter.NoDataNeeded) {
                return
            } else {
                throw new StrangeError(`No mock MAP data for timeframe ${barsArray.DataDescriptor.timeframe}`)
            }
        }

        barsArray.ImportServerDataIntoChunkMap(
            timeframeMockData.map,
            TChunkMapStatus.cms_Loaded,
            TDataFormat.df_MockObjects
        )
    }

    AddTaskIfNotInQueue(task: TBasicDownloadTask): void {
        this.WasDownloadMethodCalled = true

        // if (task instanceof TBarChunkDownloadTask) {
        //     this.MockDownloadChunk_Bars((task as TBaseChunkDownloadTask<TBarChunk>).Chunk)
        // }
        // if (task instanceof TTickChunkDownloadTask) {
        //     this.MockDownloadChunk_Ticks((task as TBaseChunkDownloadTask<TTickChunk>).Chunk)
        // }
        if (task instanceof TBarsMapDownloadTask) {
            this.MockDownloadMap((task as TBarsMapDownloadTask).DataArray)
        } else {
            throw new StrangeError(`Not implemented for ${task}`)
        }
    }
}
