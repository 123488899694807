import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { Flex, InputField, RangeSlider, Tooltip, Typography } from '@fto/ui'
import { SliderSettingType, OptionSettingRef } from '../../types/options.types'

import styles from './index.module.scss'
import { getNumberValue, setNumberValue } from '../../utils'
import { t } from 'i18next'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { tryStopTesting } from '../../../components/utils/tryStopTesting'
import WarningBlockMessage from '../../WarningBlockMessage'
import { Icon } from '@fto/icons'

const SliderSetting = forwardRef<OptionSettingRef, SliderSettingType>(
    ({ tab, chart, option, applyToAll, shouldBeDisabled, onDifferentSettingsUpdate }, ref) => {
        const [value, setValue] = useState<number>(
            () => getNumberValue(chart, option.valueConfigType, option.valueKey) * (option.divisionFactor || 1)
        )

        const formatValue = useCallback(
            (value: number) => {
                if (value < 1) return 1
                if (value > option.maxValue) return option.maxValue as number
                return value
            },
            [option]
        )

        const updateValue = useCallback(
            (value: number, redraw = true, shouldUpdateConfig = true, shouldSendNewSettingtoServer = true) => {
                setValue(value)

                shouldUpdateConfig &&
                    setNumberValue(
                        chart,
                        option.valueConfigType,
                        option.valueKey,
                        value / (option.divisionFactor || 1),
                        applyToAll,
                        shouldSendNewSettingtoServer
                    )

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [option, chart, applyToAll]
        )

        const onFocus = useCallback(() => {
            option.stopTestingOnChange ?? tryStopTesting()
        }, [option])

        const handleChange = useCallback(
            (newValue: number) => {
                option.stopTestingOnChange ?? tryStopTesting()

                newValue = formatValue(newValue)
                onDifferentSettingsUpdate?.(tab, newValue, value, option.valueKey)
                updateValue(newValue)
            },
            [tab, option, value, formatValue, updateValue, onDifferentSettingsUpdate]
        )

        const loadOptionSetting = useCallback(
            (data: any, shouldUpdateConfig = true) => {
                updateValue(data.value * (option.divisionFactor || 1), false, shouldUpdateConfig, false)
            },
            [option, updateValue]
        )

        useImperativeHandle(ref, () => ({ name: option.key, loadOptionSetting }), [option.key, loadOptionSetting])

        return (
            <>
                <Flex alignItems='center'>
                    <Typography color='gray-600' type='tiny-regular'>
                        {t(option.name)}
                    </Typography>
                </Flex>
                <Flex alignItems='center' justifyContent='space-between' className={styles.container} gap={4}>
                    <div className={styles.sliderWrap}>
                        <RangeSlider
                            min={1}
                            max={option.maxValue}
                            value={value}
                            onChange={handleChange}
                            step={option.step}
                            withPoints={option.withPoints}
                            pointsToShow={option.pointsToShow}
                            formatLabel={option.formatLabel}
                        />
                    </div>

                    <Flex alignItems='center'>
                        {option.warningTooltip && (
                            <Tooltip
                                content={t(option.warningTooltip)}
                                placement='top'
                                classNames={{ content: styles.WarningTooltip }}
                            >
                                <Icon
                                    name='error'
                                    size={16}
                                    color={shouldBeDisabled ? 'var(--color-gray-500)' : 'var(--color-orange-400)'}
                                />
                            </Tooltip>
                        )}

                        {option.withInputField ? (
                            <InputField
                                className={styles.input}
                                step={1}
                                max={option.maxValue}
                                value={value}
                                onChange={(value) => handleChange(Number(value))}
                                onFocus={onFocus}
                                type={'number'}
                                disabled={shouldBeDisabled}
                            />
                        ) : (
                            <div className={styles.textbox}>x{value}</div>
                        )}
                    </Flex>
                </Flex>
                <WarningBlockMessage text={t(option.warningMessage)} observableValue={value} />
            </>
        )
    }
)

export default SliderSetting
