import { useTranslation } from 'react-i18next'
import { FC, useCallback, useEffect } from 'react'
import { Flex, InputField, Tooltip, Typography } from '@fto/ui'
import { FormattingConfig } from './types'
import styles from './index.module.scss'
import { formatValue } from './utils'
import { Icon } from '@fto/icons'
import { tryStopTesting } from '../../../../components/utils/tryStopTesting'
import WarningBlockMessage from '../../../../components/WarningBlockMessage'

export type InputOptionProps = {
    value: number
    defaultValue: number
    optionConfig: FormattingConfig
    title: string
    disabled?: boolean
    warningTooltip?: string
    warningBlockMessage?: string
    activeSymbolName?: string
    onInput: (value: string) => void
    onChanged: (value: number) => void
}

const InputOption: FC<InputOptionProps> = ({
    value,
    defaultValue,
    optionConfig,
    title,
    disabled = false,
    warningTooltip,
    warningBlockMessage,
    activeSymbolName,
    onInput,
    onChanged
}) => {
    const { t } = useTranslation()

    const onChangeValue = useCallback(
        (value: string) => {
            onInput(value)
        },
        [onInput]
    )

    const onFocus = useCallback(() => {
        tryStopTesting()
    }, [])

    const onBlur = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChanged(formatValue(event.target.value, defaultValue, optionConfig))
        },
        [defaultValue, optionConfig, onChanged]
    )

    useEffect(() => {
        onChanged(formatValue(value, defaultValue, optionConfig))
    }, [optionConfig, defaultValue])

    return (
        <Flex direction='column'>
            <Flex justifyContent='space-between' alignItems='center'>
                <Typography type='interface-regular' color={disabled ? 'gray-600' : 'gray-1000'}>
                    {title}:
                </Typography>
                <Flex alignItems='center'>
                    {warningTooltip && (
                        <Tooltip
                            content={warningTooltip}
                            placement='top'
                            classNames={{ content: styles.WarningTooltip }}
                        >
                            <Icon
                                name='error'
                                size={16}
                                color={disabled ? 'var(--color-gray-500)' : 'var(--color-orange-400)'}
                            />
                        </Tooltip>
                    )}
                    <Flex className={styles.field} alignItems='center'>
                        <InputField
                            type='number'
                            value={value}
                            onFocus={onFocus}
                            onChange={onChangeValue}
                            onBlur={onBlur}
                            min={optionConfig.min}
                            max={optionConfig.max}
                            step={optionConfig.step}
                            disabled={disabled}
                        />
                    </Flex>
                </Flex>
            </Flex>
            <WarningBlockMessage
                text={warningBlockMessage}
                observableValue={value}
                resetObservableItem={activeSymbolName}
            />
        </Flex>
    )
}

export default InputOption
