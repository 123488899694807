import ICanvasLayer from '@fto/lib/charting/auxiliary_classes_charting/Layers/ICanvasLayer'
import { TRect } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { ChartControlId } from './ChartControl'
import { LocationParams } from './LocationParams'

export class ChartControlParams {
    private _ownerLayer: ICanvasLayer | null = null
    private locationParams: LocationParams = new LocationParams(0, 0, 0, 0)
    private id: ChartControlId = ChartControlId.UNKNOWN
    private _isDraggable: boolean

    constructor(
        ownerLayer: ICanvasLayer | null,
        locationParams: LocationParams,
        controlId: ChartControlId = ChartControlId.UNKNOWN,
        isDraggable = false
    ) {
        this._ownerLayer = ownerLayer
        this.locationParams = locationParams
        this.id = controlId
        this._isDraggable = isDraggable
    }

    get controlId(): ChartControlId {
        return this.id
    }

    set controlId(id: ChartControlId) {
        this.id = id
    }

    get ownerLayer(): ICanvasLayer | null {
        return this._ownerLayer
    }

    get isDraggable(): boolean {
        return this._isDraggable
    }

    getLocation(): TRect {
        return this.locationParams.getLocation()
    }

    setLocation(location: TRect): void {
        this.locationParams.setLocation(location)
    }

    clone(): ChartControlParams {
        return new ChartControlParams(this.ownerLayer, this.locationParams.clone(), this.controlId)
    }
}
