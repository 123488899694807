import { ChartControl } from '@fto/chart_components/ChartControl'
import { ChartControlParams } from './ChartControlParams'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import { TRect } from '@fto/lib/extension_modules/common/CommonExternalInterface'
import { TMainChart } from '@fto/lib/charting/chart_classes/MainChartUnit'
import { IGPPen, IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export class GroupChartControls extends ChartControl {
    private growth = 'bottom' // left, right, top, bottom
    private alignment = 'left' // left, right, top, bottom
    private controls: ChartControl[] = []
    private hoveredControl: ChartControl | null = null
    private gapBetweenControls = 5
    private rightOffset = 0
    private bottomOffset = 0
    private canvas!: TGdiPlusCanvas

    constructor(
        chartControlParams: ChartControlParams,
        canvas: TGdiPlusCanvas,
        growthDirection = 'bottom',
        alignDirection = 'left',
        rightOffset = -1,
        bottomOffset = -1,
        priceColumnWidth = 0
    ) {
        super(chartControlParams)
        this.canvas = canvas
        this.growth = growthDirection
        this.alignment = alignDirection
        this.rightOffset = rightOffset
        this.bottomOffset = bottomOffset
        this.setLocationGroup(priceColumnWidth)
    }

    public draw(canvas: TGdiPlusCanvas): void {
        if (!this.IsVisible()) {
            return
        }
        for (const control of this.controls) {
            control.draw(this.canvas)
        }
        // debug - show group border
        // this.canvas.strokeRect(this.getLocation(), TRoundRectType.BOTH, 5, false, new IGPPen('#ff0000'), new IGPSolidBrush('#ff0000'))
    }

    public Show(): void {
        for (const control of this.controls) {
            control.Show()
        }
        super.Show()
    }

    public Hide(): void {
        for (const control of this.controls) {
            control.Hide()
        }
        super.Hide()
    }

    public ShowContent(): void {
        for (const control of this.controls) {
            control.Show()
        }
    }

    public HideContent(): void {
        for (const control of this.controls) {
            control.Hide()
        }
    }

    public setGapBetweenControls(gap: number): void {
        this.gapBetweenControls = gap
    }

    public getControls(): ChartControl[] {
        return this.controls
    }

    public addControl(control: ChartControl): void {
        const layer = this.getOwnerLayer()
        if (layer) {
            control.attachObserver(layer.getChartWindow())
            this.addControlToEndOfGroup(control)
        }
    }

    public removeControl(control: ChartControl): void {
        this.controls = this.controls.filter((item) => item !== control)
    }

    public getLastControlByLocation(): ChartControl | null {
        let result: ChartControl | null = null
        let extremeCoord: number

        switch (this.growth) {
            case 'bottom': {
                extremeCoord = -Infinity
                for (const control of this.controls) {
                    if (control.IsVisible()) {
                        const rect = control.getLocation()
                        if (rect.Bottom > extremeCoord) {
                            extremeCoord = rect.Bottom
                            result = control
                        }
                    }
                }
                break
            }
            case 'top': {
                extremeCoord = Infinity
                for (const control of this.controls) {
                    if (control.IsVisible()) {
                        const rect = control.getLocation()
                        if (rect.Top < extremeCoord) {
                            extremeCoord = rect.Top
                            result = control
                        }
                    }
                }
                break
            }
            case 'right': {
                extremeCoord = -Infinity
                for (const control of this.controls) {
                    if (control.IsVisible()) {
                        const rect = control.getLocation()
                        if (rect.Right > extremeCoord) {
                            extremeCoord = rect.Right
                            result = control
                        }
                    }
                }
                break
            }
            case 'left': {
                extremeCoord = Infinity
                for (const control of this.controls) {
                    if (control.IsVisible()) {
                        const rect = control.getLocation()
                        if (rect.Left < extremeCoord) {
                            extremeCoord = rect.Left
                            result = control
                        }
                    }
                }
                break
            }
            default: {
                throw new StrangeError('Unknown growth direction')
            }
        }
        return result
    }

    public addControlToEndOfGroup(control: ChartControl, updateGroupLocation = true): void {
        const lastControl = this.getLastControlByLocation()
        let lastControlLocation: TRect
        if (lastControl) {
            lastControlLocation = lastControl.getLocation()
        } else {
            lastControlLocation = this.getLocation()
        }

        const newControlLocation = control.getLocation()

        switch (this.growth) {
            case 'bottom': {
                if (lastControl) {
                    newControlLocation.Top = lastControlLocation.Bottom + this.gapBetweenControls
                    newControlLocation.Bottom = newControlLocation.Top + control.getHeight()
                } else {
                    newControlLocation.Top = lastControlLocation.Top
                    newControlLocation.Bottom = newControlLocation.Top + control.getHeight()
                }
                break
            }
            case 'top': {
                if (lastControl) {
                    newControlLocation.Bottom = lastControlLocation.Top - this.gapBetweenControls
                    newControlLocation.Top = newControlLocation.Bottom - control.getHeight()
                } else {
                    newControlLocation.Bottom = lastControlLocation.Bottom
                    newControlLocation.Top = newControlLocation.Bottom - control.getHeight()
                }
                break
            }
            case 'right': {
                if (lastControl) {
                    newControlLocation.Left = lastControlLocation.Right + this.gapBetweenControls
                    newControlLocation.Right = newControlLocation.Left + control.getWidth()
                } else {
                    newControlLocation.Left = lastControlLocation.Left
                    newControlLocation.Right = newControlLocation.Left + control.getWidth()
                }
                break
            }
            case 'left': {
                if (lastControl) {
                    newControlLocation.Right = lastControlLocation.Left - this.gapBetweenControls
                    newControlLocation.Left = newControlLocation.Right - control.getWidth()
                } else {
                    newControlLocation.Right = lastControlLocation.Right
                    newControlLocation.Left = newControlLocation.Right - control.getWidth()
                }
                break
            }
            default: {
                throw new StrangeError('Unknown growth direction')
            }
        }

        switch (this.alignment) {
            case 'left': {
                newControlLocation.Left = lastControlLocation.Left
                newControlLocation.Right = newControlLocation.Left + control.getWidth()
                break
            }
            case 'right': {
                newControlLocation.Right = lastControlLocation.Right
                newControlLocation.Left = newControlLocation.Right - control.getWidth()
                break
            }
            case 'top': {
                newControlLocation.Top = lastControlLocation.Top
                newControlLocation.Bottom = newControlLocation.Top + control.getHeight()
                break
            }
            case 'bottom': {
                newControlLocation.Bottom = lastControlLocation.Bottom
                newControlLocation.Top = newControlLocation.Bottom - control.getHeight()
                break
            }
            default: {
                throw new StrangeError('Unknown alignment direction')
            }
        }

        control.setLocation(newControlLocation)

        this.controls.push(control)

        if (updateGroupLocation) {
            this.updateGroupLocation()
        }
    }

    public ddControlToStartOfGroup(control: ChartControl): void {
        this.controls.unshift(control)
        this.repositionControls()
    }

    public deleteControl(control: ChartControl): void {
        const index = this.controls.indexOf(control)
        if (index > -1) {
            this.controls.splice(index, 1)
        }
        this.repositionControls()
    }

    private setLocationGroup(priceColumnWidth = 0): void {
        if (this.rightOffset >= 0 || this.bottomOffset >= 0) {
            const dpr = window.devicePixelRatio || 1

            const location = this.getLocation()
            if (this.rightOffset >= 0) {
                switch (this.growth) {
                    case 'bottom': {
                        if (this.alignment === 'right') {
                            location.Right = (this.canvas.graphics.Context.canvas.width - priceColumnWidth - this.rightOffset) / dpr
                        }
                        break
                    }
                    case 'top': {
                        if (this.alignment === 'right') {
                            location.Right = (this.canvas.graphics.Context.canvas.width - priceColumnWidth - this.rightOffset) / dpr
                        }
                        break
                    }
                    case 'right': {
                        break
                    }
                    case 'left': {
                        location.Right = (this.canvas.graphics.Context.canvas.width - priceColumnWidth - this.rightOffset) / dpr
                        break
                    }
                    default: {
                        throw new StrangeError('Unknown growth direction')
                    }
                }
            }
            if (this.bottomOffset >= 0) {
                switch (this.growth) {
                    case 'bottom': {
                        break
                    }
                    case 'top': {
                        location.Bottom = this.canvas.graphics.Context.canvas.height / dpr - this.bottomOffset / dpr
                        break
                    }
                    case 'right': {
                        if (this.alignment === 'bottom') {
                            location.Bottom = this.canvas.graphics.Context.canvas.height / dpr - this.bottomOffset / dpr
                        }
                        break
                    }
                    case 'left': {
                        if (this.alignment === 'bottom') {
                            location.Bottom = this.canvas.graphics.Context.canvas.height / dpr - this.bottomOffset / dpr
                        }
                        break
                    }
                    default: {
                        throw new StrangeError('Unknown growth direction')
                    }
                }
            }

            this.setLocation(location)
        }
    }

    public updateLocationOnCanvasResize(priceColumnWidth: number): void {
        this.setLocationGroup(priceColumnWidth)
        this.repositionControls()
    }

    public repositionControls(): void {
        const controlsCopy = [...this.controls]
        this.controls = []
        for (const control of controlsCopy) {
            this.addControlToEndOfGroup(control, false)
        }
        this.updateGroupLocation()
    }

    public updateGroupLocation(): void {
        if (this.controls.length > 0) {
            let minX = Infinity,
                minY = Infinity,
                maxX = -Infinity,
                maxY = -Infinity
            for (const control of this.controls) {
                const location = control.getLocation()
                minX = Math.min(minX, location.Left)
                minY = Math.min(minY, location.Top)
                maxX = Math.max(maxX, location.Right)
                maxY = Math.max(maxY, location.Bottom)
            }
            this.setLocation(new TRect(minX, minY, maxX, maxY))
        }
    }

    public onBrowserWndSizing(): void {
        for (const control of this.controls) {
            control.onBrowserWndSizing()
        }
    }

    public onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        let result: ChartControl | null = null
        for (let i = 0; i < this.controls.length; i++) {
            result = this.controls[i].onMouseDown(event, sender)
            if (result) {
                break
            }
        }
        return result
    }

    public onMouseMove(event: MouseEvent, sender: TChart): ChartControl | null {
        let result: ChartControl | null = null
        for (let i = 0; i < this.controls.length; i++) {
            result = this.controls[i].onMouseMove(event, sender)
            if (result) {
                if (this.hoveredControl && this.hoveredControl !== result) {
                    this.hoveredControl.onMouseLeave(event)
                }
                this.hoveredControl = result
                return result
            }
        }

        if (!result && this.hoveredControl) {
            this.hoveredControl.onMouseLeave(event)
            this.hoveredControl = null
        }

        return result
    }

    public onMouseLeave(event: MouseEvent): ChartControl | null {
        this.hoveredControl = null
        let result: ChartControl | null = null
        for (let i = 0; i < this.controls.length; i++) {
            result = this.controls[i].onMouseLeave(event)
            if (result) {
                break
            }
        }
        return result
    }

    public onMouseUp(event: MouseEvent, sender: TMainChart): void {
        for (let i = 0; i < this.controls.length; i++) {
            this.controls[i].onMouseUp(event, sender)
        }
    }
}
