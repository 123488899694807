import { FC, useMemo } from 'react'
import { Icon, FilenameTypes } from '@fto/icons'

import { useAppSelector } from '@root/hooks/useStoreHook'
import { COUNTRY_FLAG_BY_CURRENCY } from '@root/constants/currencyToFragIcon'
import { SYMBOL_ICONS } from '@root/constants/symbolIcons'

import { $getSymbols } from '@root/store/symbols/symbol.selectors'

import styles from './index.module.scss'

type Props = {
    symbol: string
    symbolSize?: number
}

const SymbolPair: FC<Props> = ({ symbol, symbolSize = 12 }) => {
    const symbolsList = useAppSelector($getSymbols)

    const symbolData = useMemo(() => {
        return symbolsList.find((item) => item.Symbol === symbol)
    }, [symbolsList, symbol])

    const pair = useMemo(() => {
        if (!symbolData) {
            return []
        }

        const { Category } = symbolData

        if (['Majors', 'Crosses', 'Exotic'].includes(Category)) {
            const baseCurrency = symbol.substring(0, 3)
            return [baseCurrency, symbol.replace(baseCurrency, '')]
        }

        return [symbol, 'USD']
    }, [symbol, symbolData])

    const baseCurrencyIcon = useMemo(() => {
        const [baseCurrency] = pair
        const iconSize = symbolSize + 2 // NOTE: calculation border size

        if (!baseCurrency) {
            return <Icon size={iconSize} className={styles.BaseCurrency} name='symbol-not-found' />
        }

        const iconsList = { ...COUNTRY_FLAG_BY_CURRENCY, ...SYMBOL_ICONS }
        const iconKey = baseCurrency.toLowerCase().replace('#', '') as keyof typeof COUNTRY_FLAG_BY_CURRENCY
        const iconName = baseCurrency ? iconsList[iconKey] : ''
        const hasIcon = FilenameTypes.includes(iconName as (typeof FilenameTypes)[number])

        if (!hasIcon) {
            return <Icon size={iconSize} className={styles.BaseCurrency} name='symbol-not-found' />
        }

        return (
            <Icon size={iconSize} className={styles.BaseCurrency} name={iconName as (typeof FilenameTypes)[number]} />
        )
    }, [pair, symbolSize])

    const reverseCurrencyIcon = useMemo(() => {
        const [_, reverseCurrency] = pair

        if (!reverseCurrency) {
            return <Icon size={symbolSize} className={styles.BaseCurrency} name='symbol-not-found' />
        }

        const iconsList = { ...COUNTRY_FLAG_BY_CURRENCY, ...SYMBOL_ICONS }
        const iconKey = reverseCurrency.toLowerCase().replace('#', '') as keyof typeof COUNTRY_FLAG_BY_CURRENCY
        const iconName = reverseCurrency ? iconsList[iconKey] : ''
        const hasIcon = FilenameTypes.includes(iconName as (typeof FilenameTypes)[number])

        if (!hasIcon) {
            return <Icon size={symbolSize} className={styles.BaseCurrency} name='symbol-not-found' />
        }

        return (
            <Icon
                size={symbolSize}
                className={styles.ReverseCurrency}
                name={iconName as (typeof FilenameTypes)[number]}
            />
        )
    }, [pair, symbolSize])

    const rootSize = useMemo(() => {
        const base = symbolSize * 2 + 2
        return base - symbolSize / 2
    }, [symbolSize])

    return (
        <div className={styles.SymbolPair} style={{ width: rootSize, height: rootSize }} title={symbol}>
            {baseCurrencyIcon}
            {reverseCurrencyIcon}
        </div>
    )
}

export default SymbolPair
