import { ChartControl, chartControlEvent } from '@fto/chart_components/ChartControl'
import { TChart } from '@fto/lib/charting/chart_classes/BasicChart'
import GlobalImageManager from '@fto/lib/globals/GlobalImageManager'
import { TGdiPlusCanvas, TRoundRectType } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { TChartWindow } from '@fto/lib/charting/chart_windows/ChartWindow'
import { TCursor } from '@fto/lib/ft_types/common/CursorPointers'
import { changeImageColor, formatImageSize } from '@fto/lib/utils/common_utils'
import { IGPPen, IGPSolidBrush } from '@fto/lib/delphi_compatibility/DelphiGDICompatibility'
import { DebugUtils } from '@fto/lib/utils/DebugUtils'
import { ChartControlParams } from '@fto/chart_components/ChartControlParams'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'

export default class RemoveButton extends ChartControl {
    private ownerChart: TChartWindow

    constructor(ownerChart: TChartWindow, chartControlParams: ChartControlParams) {
        super(chartControlParams)
        this.ownerChart = ownerChart
    }

    protected get borderPen(): IGPPen {
        if (this.isMouseInside()) {
            return new IGPPen(this.ownerChart.ChartOptions.ControlsColorScheme.buttonHoveredBorder)
        } else {
            return new IGPPen(this.ownerChart.ChartOptions.ControlsColorScheme.buttonBorder)
        }
    }

    protected get imageColor(): string {
        return this.ownerChart.ChartOptions.ControlsColorScheme.buttonText
    }

    protected get backgroundColorBrush(): IGPSolidBrush {
        return new IGPSolidBrush(this.ownerChart.ChartOptions.ControlsColorScheme.buttonBackground)
    }

    protected get backgroundHoveredColorBrush(): IGPSolidBrush {
        return new IGPSolidBrush(this.ownerChart.ChartOptions.ControlsColorScheme.buttonHoveredBackground)
    }

    protected getColoredImage(image: HTMLImageElement): HTMLCanvasElement {
        const dpr = window.devicePixelRatio || 1
        return changeImageColor(image, this.imageColor, image.width * dpr, image.height * dpr)
    }

    draw(canvas: TGdiPlusCanvas, isIndicatorVisible = true) {
        const dpr = window.devicePixelRatio || 1
        const ctx = canvas.graphics.Context
        const image = GlobalImageManager.Instance.closeImage

        const location = this.getLocation()
        const { Left, Top, Right, Bottom } = location
        const BorderRadius = 5 * dpr
        const padding = BorderRadius * 0.75
        const buttonSize = Bottom - Top - padding * 2

        const brush = this.isMouseInside() ? this.backgroundHoveredColorBrush : this.backgroundColorBrush

        canvas.strokeRect(location, TRoundRectType.BOTH, BorderRadius, true, brush, this.borderPen)

        if (this.isMouseInside() && this.tooltip) {
            this.showTooltip(canvas)
        }

        if (!image) {
            throw new StrangeError('RemoveButton: image is not loaded')
        }

        const coloredImage = this.getColoredImage(image!)
        ctx.drawImage(coloredImage, Left + padding, Top + padding, buttonSize, buttonSize)
    }

    onMouseDown(event: MouseEvent, sender: TChart): ChartControl | null {
        if (super.onMouseDown(event, sender)) {
            DebugUtils.log('clicked RemoveButton')
            return this
        }

        return null
    }

    onMouseMove(event: MouseEvent, sender: TChart): ChartControl | null {
        super.onMouseMove(event, sender)

        let result = null
        if (this.isPointInside(event.offsetX, event.offsetY)) {
            ;(sender.ChartWindow as TChartWindow).SetCursor(TCursor.crHandPoint)
            result = this
            this.notify(chartControlEvent.BUTTON_PRESSED)
            return this
        }
        return null
    }
}
