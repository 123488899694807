import { TColor } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { TGdiPlusCanvas } from '@fto/lib/drawing_interface/GdiPlusCanvas'
import { DateUtils, TDateTime } from '@fto/lib/delphi_compatibility/DateUtils'
import GlobalOptions from '@fto/lib/globals/GlobalOptions'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ChartControl } from '../ChartControl'
import { ChartControlParams } from '../ChartControlParams'
import { DelphiFormsBuiltIns, TMouseButton } from '@fto/lib/delphi_compatibility/DelphiFormsBuiltIns'
import { ChartEvent } from '@fto/lib/charting/auxiliary_classes_charting/ChartingEnums'
import { CrosshairStrategy } from '@fto/chart_components/crosshair/strategies/common'
import { CrosshairWithRooler } from '@fto/chart_components/crosshair/strategies/CrosshairWithRooler'
import { TCrossHair, TCursorMode } from '@fto/lib/charting/ChartBasicClasses'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { crosshairManager } from '@fto/lib/globals/CrosshairManager'
import { GlobalTimezoneDSTController } from '@fto/lib/Timezones&DST/GlobalTimezoneDSTController'
import { t } from 'i18next'
import { IChartWindow } from '@fto/lib/charting/chart_windows/IChartWindow'
import { IChart } from '@fto/lib/charting/chart_classes/IChart'
import { TPenStyle, TRect } from '@fto/lib/extension_modules/common/CommonExternalInterface'

dayjs.extend(utc)

export class CrosshairControl extends ChartControl {
    private strategy: CrosshairStrategy | undefined

    constructor(chartControlParams: ChartControlParams) {
        super(chartControlParams)
    }

    private get chartWindow(): IChartWindow {
        const ownerLayer = this.getOwnerLayer()
        if (ownerLayer) {
            return ownerLayer.getChartWindow()
        } else {
            throw new StrangeError('Owner layer is not defined')
        }
    }

    private get chart(): IChart {
        return this.chartWindow.MainChart
    }

    private get dateTimeBar(): IChart {
        return this.chartWindow.TimeBar
    }

    public draw(canvas: TGdiPlusCanvas): void {
        const crosshair = crosshairManager.getCrosshairState()
        if (!this.IsEnabled() || !crosshair) return

        this.drawOnChart(crosshair, this.chart.PaintContextCache, this.chart, canvas)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onMouseLeave(event: MouseEvent): ChartControl | null {
        this.hide()
        return null
    }

    public hide(): void {
        if (this.chartWindow.cursorMode === TCursorMode.cm_CrossHairRooler) {
            return
        }

        crosshairManager.resetToDefaults()
        this.chartWindow.notifyFromThisChartWindow(ChartEvent.UPDATE_LINKED_CROSS_HAIRS)
    }

    public onMouseUp(event: MouseEvent): void {
        if (!this.IsEnabled()) return
        this.strategy?.onMouseUp(event)
    }

    public onMouseDown(event: MouseEvent): ChartControl | null {
        if (!this.IsEnabled()) return null

        let shouldReturnControl = false

        if (this.enableCrosshairWithRoolerIfNeed(event) && !shouldReturnControl) {
            shouldReturnControl = true
        }

        if (this.strategy?.onMouseDown(event) && !shouldReturnControl) {
            shouldReturnControl = true
        }

        if (shouldReturnControl) {
            return this
        }

        return null
    }

    /**
     * @returns flag was event handled
     */
    private enableCrosshairWithRoolerIfNeed(event: MouseEvent): boolean {
        const button = DelphiFormsBuiltIns.ExtractTMouseButton(event)
        const isMMBClick = button === TMouseButton.mbMiddle

        if (isMMBClick && !this.strategy) {
            this.startCrosshairMode()
            this.updCrosshair()
            return true
        } else if (isMMBClick && this.strategy instanceof CrosshairWithRooler) {
            this.stopCrosshairMode()
            return true
        }

        return false
    }

    public startCrosshairMode(): void {
        this.chartWindow.notifyFromThisChartWindow(ChartEvent.CROSS_HAIR_MODE_START_ROOLER)
    }

    public enableCrosshairWithRooler(): void {
        this.strategy = new CrosshairWithRooler(this.chartWindow, this)
    }

    public stopCrosshairMode(): void {
        this.chartWindow.notifyFromThisChartWindow(ChartEvent.CROSS_HAIR_MODE_END)
    }

    public dispose(): void {
        this.strategy = undefined
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onMouseMove(event: MouseEvent, sender: IChart): ChartControl | null {
        if (!this.IsEnabled()) return null
        this.updCrosshair()
        this.switchChartIfNeed()
        this.strategy?.onMouseMove(event)
        return null
    }

    private switchChartIfNeed(): void {
        if (!crosshairManager.isChartSource(this.chartWindow) && crosshairManager.isCrosshairEnabled()) {
            this.chartWindow.notifyFromThisChartWindow(ChartEvent.UPDATE_CROSS_HAIR_ACTIVE_CHART)
        }
    }

    private drawOnChart(
        crossHair: TCrossHair,
        PaintContextCache: IChart['PaintContextCache'],
        chart: IChart,
        canvasToDraw: TGdiPlusCanvas
    ) {
        const firstVisibleDate = chart.GetDateByIndex(PaintContextCache.firstVisibleIndex)
        const lastVisibleDate = chart.GetDateByIndex(
            PaintContextCache.firstVisibleIndex + PaintContextCache.NumberOfVisibleBars
        )

        const isOutOfDates = !DateUtils.InRange(crossHair.DateTime, firstVisibleDate, lastVisibleDate)
        const hasNoDateTime = crossHair.DateTime === -1

        if (hasNoDateTime || isOutOfDates) {
            return
        }

        const time = crossHair.DateTime
        const x = chart.GetXFromDate(time)

        const R = PaintContextCache.PaintRect
        const p = crosshairManager.getProjectedPointOnChart(chart.ChartWindow)
        const value = crosshairManager.getCrosshairState()?.Price ?? 0

        const oldTransform = canvasToDraw.ctx.getTransform()
        const currentTransform = this.chartWindow.MainChart.HTML_Canvas.getContext('2d')?.getTransform()

        if (currentTransform) {
            canvasToDraw.ctx.setTransform(currentTransform)
        }

        canvasToDraw.SetPen(
            1,
            TPenStyle.psDash,
            ColorHelperFunctions.MakeColor(chart.ChartOptions.ColorScheme.FrameAndTextColor, 255)
        )

        if (x < PaintContextCache.PaintRect.Right) {
            canvasToDraw.MoveTo(x, 0)
            canvasToDraw.LineTo(x, canvasToDraw.canvas.height)
        }

        canvasToDraw.MoveTo(R.Left, p.y)
        canvasToDraw.LineTo(R.Right, p.y)

        if (DateUtils.InRange(p.y, R.Top, R.Bottom)) {
            this.PaintRightMarker(
                value,
                this.chartWindow.MainChart.ChartOptions.ColorScheme.FrameAndTextColor,
                false,
                canvasToDraw
            )
        }
        this.PaintMarker(time, this.chartWindow.ChartOptions.ColorScheme.FrameAndTextColor, canvasToDraw)

        if (currentTransform) {
            canvasToDraw.ctx.setTransform(oldTransform)
        }
    }

    protected PaintRightMarker(value: number, color: TColor, drawArrow: boolean, canvasToDraw: TGdiPlusCanvas): void {
        const y: number = this.chartWindow.MainChart.GetY(value)
        const text: string = this.chartWindow.MainChart.FormatNumberWithSuffix(value)
        const dpr = window.devicePixelRatio

        const canvas = canvasToDraw

        const context = canvasToDraw.ctx
        const paintRect = this.chartWindow.MainChart.PaintContextCache.PaintRect

        const textX = paintRect.Right + 5
        const textY = y + canvas.TextHeight('0', GlobalOptions.Options.TEXT_AXIS_FONT, false) / 2

        const labelRectY = y - (canvas.TextHeight('0', GlobalOptions.Options.TEXT_AXIS_FONT, false) + 6 * dpr) / 2
        const labelRectH = canvas.TextHeight('0', GlobalOptions.Options.TEXT_AXIS_FONT, false) + 6 * dpr

        context.fillStyle = color
        context.fillRect(
            paintRect.Right,
            labelRectY,
            this.chartWindow.MainChart.getBoundingClientRect().right * dpr - paintRect.Right,
            labelRectH
        )
        if (drawArrow) {
            context.beginPath()
            context.moveTo(paintRect.Right - labelRectH / 2, y)
            context.lineTo(paintRect.Right, y - labelRectH / 2)
            context.lineTo(paintRect.Right, y + labelRectH / 2)
            context.closePath()
            context.fill()
        }

        context.beginPath()
        context.strokeStyle = color
        context.lineWidth = 1
        context.moveTo(paintRect.Right, y)
        context.lineTo(paintRect.Right + 4, y)
        context.stroke()

        // Text
        context.font = GlobalOptions.Options.TEXT_AXIS_FONT
        context.fillStyle = ColorHelperFunctions.GetAntiColor(color)
        context.fillText(text, textX, textY)
    }

    protected PaintMarker(DateTime: TDateTime, color: TColor, canvasToDraw: TGdiPlusCanvas): void {
        color = ColorHelperFunctions.BasicColor(color)
        const x: number = this.dateTimeBar.GetXFromDate(DateTime)

        const context = canvasToDraw.ctx
        const paintRect = this.chart.PaintContextCache.PaintRect
        const dateTimeBarRect = this.dateTimeBar.PaintContextCache.PaintRect
        const timBarHeight = this.dateTimeBar.PaintContextCache.PaintRect.Height

        context.beginPath()
        context.strokeStyle = color
        context.lineWidth = 1
        context.moveTo(x, paintRect.Top)
        context.lineTo(x, paintRect.Top + 3)
        context.stroke()

        const textColor = ColorHelperFunctions.GetAntiColor(color)
        const textFont = GlobalOptions.Options.TEXT_AXIS_FONT
        context.font = textFont
        context.fillStyle = textColor

        let timeInMilliseconds = DateUtils.toUnixTimeMilliseconds(
            GlobalTimezoneDSTController.Instance.convertFromInnerLibDateTimeByTimezoneAndDst(DateTime)
        )
        let dateFormat = t('charting.dateTimeBar.markerDateTimeFormat')
        if (this.chartWindow && this.chartWindow.Bars && this.chartWindow.Bars.DataDescriptor.timeframe >= 1440) {
            timeInMilliseconds = DateUtils.toUnixTimeMilliseconds(Math.trunc(DateTime))
            dateFormat = t('charting.dateTimeBar.markerDateTimeFormatShort')
        }

        const s = dayjs.utc(timeInMilliseconds).format(dateFormat)
        let totalOscWinsHeight = 0
        for (const oscWin of this.chartWindow.OscWins) {
            totalOscWinsHeight += oscWin.oscInfo.oscContainer.offsetHeight
        }

        totalOscWinsHeight *= window.devicePixelRatio

        const textWidth = this.chart.PaintContextCache.GdiCanvas.TextWidth(s, textFont, false)
        const textHeight = this.chart.PaintContextCache.GdiCanvas.TextHeight(s, textFont, false)
        const textY = paintRect.Height + textHeight + (dateTimeBarRect.Height - textHeight) / 2 + totalOscWinsHeight
        const dpr = window.devicePixelRatio

        const rectForFillWidth = textWidth + 4 * 2 * dpr
        context.fillStyle = color
        const left: number = x - rectForFillWidth / 2
        const top: number = canvasToDraw.canvas.height - timBarHeight
        const right: number = left + rectForFillWidth
        const bottom: number = top + timBarHeight
        const markRc: TRect = new TRect(left, top, right, bottom)

        if (x >= this.chart.GetPaintRect().Left && x <= this.chart.GetPaintRect().Right) {
            if (markRc.Left < paintRect.Left) {
                const diff = Math.abs(paintRect.Left - markRc.Left)
                markRc.Left = markRc.Left + diff
                markRc.Right = markRc.Right + diff
            }
            const textX = markRc.Left + (markRc.Right - markRc.Left) / 2 - textWidth / 2

            context.fillRect(markRc.Left, markRc.Top, markRc.Width, markRc.Height)

            context.font = textFont
            context.fillStyle = textColor
            context.fillText(s, textX, textY)
        }
    }

    public updCrosshair(): void {
        const priceDate = GlobalOptions.Options.magnetCrossHair
            ? this.chartWindow.getMagnetPointLocal(true)
            : this.chartWindow.getPriceDatePointUnderMouse()

        switch (this.chartWindow.cursorMode) {
            case TCursorMode.cm_Default: {
                break
            }
            case TCursorMode.cm_CrossHair: {
                crosshairManager.updateCrosshairState(this.chartWindow, priceDate.x, priceDate.y)
                break
            }
            case TCursorMode.cm_CrossHairRooler: {
                crosshairManager.updateRulerDateTime(this.chartWindow, priceDate.x, priceDate.y)
                this.chartWindow.invalidate()
                break
            }
            default: {
                throw new StrangeError(`Invalid cursor mode in UpdateCrossHair ${this.chartWindow.cursorMode}`)
            }
        }

        this.chartWindow.notifyFromThisChartWindow(ChartEvent.UPDATE_LINKED_CROSS_HAIRS)
    }

    private isCroshairCursor(): boolean {
        return (
            this.chartWindow.cursorMode === TCursorMode.cm_CrossHair ||
            this.chartWindow.cursorMode === TCursorMode.cm_CrossHairRooler
        )
    }
}
