import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { FormattingConfig } from '../types'

export const formatValue = (value: number | string, defaultValue: number, config: FormattingConfig) => {
    if (!config) throw new StrangeError('Formatting config is not provided')

    if (typeof value === 'string' && !value.length) {
        return defaultValue
    }

    let formattedValue = typeof value === 'string' ? parseFloat(value) : value

    if (formattedValue < config.min) {
        formattedValue = config.min
    } else if (formattedValue > config.max) {
        formattedValue = config.max
    }

    if (config.isInteger || config.decimals === 0) {
        formattedValue = Math.trunc(formattedValue)
    } else if (config.decimals !== undefined) {
        const factor = Math.pow(10, config.decimals)
        formattedValue = Math.trunc(formattedValue * factor) / factor
    }

    return formattedValue
}
