import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Typography } from '@fto/ui'
import { CanvasSettingsProps, SettingsTabRef } from '../../types'
import { OptionSettingRef } from '../../../../components/types/options.types'
import { AvailableOptionsSettings } from '../../../../components/types/options.types'
import getOptionSettingByConfig from '../../../../components/utils/getOptionSettingByConfig'
import OptionsConfig from './config'
import useChangesObserver from '../../../../hooks/useChangesObserver'

const CanvasSettings = forwardRef<SettingsTabRef, CanvasSettingsProps>(({ chart, applyToAll }, ref) => {
    const { t } = useTranslation()
    const optionsRefs = useRef<{ [key: string]: OptionSettingRef }>({})
    const { onDifferentSettingsUpdate, executeRequestAndClear } = useChangesObserver(chart, {
        mixPanelEventName: 'chart_settings_updated',
        optionsConfig: OptionsConfig
    })

    useEffect(() => {
        executeRequestAndClear()
    }, [chart])

    const loadOptionsSetting = useCallback((data: { [key: string]: any }, shouldUpdateConfig = true) => {
        Object.keys(data).forEach((key) => {
            const ref = optionsRefs.current[key]
            if (ref) ref.loadOptionSetting(data[key], shouldUpdateConfig)
        })
    }, [])

    const addRef = useCallback((ref: OptionSettingRef) => {
        if (!ref) return
        optionsRefs.current[ref.name] = ref
    }, [])

    const optionSettingByKey = useCallback(
        (option: AvailableOptionsSettings) => {
            return getOptionSettingByConfig(
                {
                    tab: 'canvas',
                    chart,
                    option: option,
                    applyToAll,
                    onDifferentSettingsUpdate
                },
                addRef
            )
        },
        [chart, applyToAll, onDifferentSettingsUpdate, addRef]
    )

    useImperativeHandle(ref, () => ({ loadOptionsSetting }))

    return (
        <Flex direction='column' block gap={12} justifyContent='flex-start'>
            {optionSettingByKey(OptionsConfig['OffsetPercentage'])}

            <Flex justifyContent='space-between'>
                <Typography color='gray-600' type='tiny-regular'>
                    {t('settings.chart.titles.chartBasicStyle')}
                </Typography>
                <Typography color='gray-600' type='subtext-regular'>
                    {t('settings.chart.titles.color')}
                </Typography>
            </Flex>

            {optionSettingByKey(OptionsConfig['BackgroundColor'])}
            {optionSettingByKey(OptionsConfig['FrameAndTextColor'])}
            {optionSettingByKey(OptionsConfig['Grid'])}

            <Flex block>
                <Typography color='gray-600' type='tiny-regular'>
                    {t('settings.chart.titles.candles')}
                </Typography>
            </Flex>

            {optionSettingByKey(OptionsConfig['CandleUp'])}
            {optionSettingByKey(OptionsConfig['CandleDown'])}
            {optionSettingByKey(OptionsConfig['FillerUp'])}
            {optionSettingByKey(OptionsConfig['FillerDown'])}
            {optionSettingByKey(OptionsConfig['CandleUpShadowColor'])}
            {optionSettingByKey(OptionsConfig['CandleDownShadowColor'])}

            <Flex block>
                <Typography color='gray-600' type='tiny-regular'>
                    {t('settings.chart.titles.orders')}
                </Typography>
            </Flex>

            {optionSettingByKey(OptionsConfig['BuyMarker'])}
            {optionSettingByKey(OptionsConfig['SellMarker'])}
            {optionSettingByKey(OptionsConfig['StopLoss'])}
            {optionSettingByKey(OptionsConfig['TakeProfit'])}
            {optionSettingByKey(OptionsConfig['ProfitTransaction'])}
            {optionSettingByKey(OptionsConfig['LossTransaction'])}

            <Flex block>
                <Typography color='gray-600' type='tiny-regular'>
                    {t('settings.chart.titles.price')}
                </Typography>
            </Flex>

            {optionSettingByKey(OptionsConfig['AskLevel'])}
            {optionSettingByKey(OptionsConfig['MatchPriceWithBarsColor'])}
        </Flex>
    )
})

export default CanvasSettings
