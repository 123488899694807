import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Icon } from '@fto/icons'
import { Button, Flex, TriggerOverlay, Typography, HoverElement } from '@fto/ui'
import { useTheme } from '@fto/ui/lib/theme/ThemeProvider'

import { useAppSelector } from '@root/hooks/useStoreHook'
import { DASHBOARD_ROUTE, makeRoute } from '@root/constants/routes'
import { $getUser } from '@root/store/auth/selectors'

import { optionsRenderer } from './helpers/ProfileContextMenuRenderer'
import LanguagesSelector from './components/LanguagesSelector'

import styles from './index.module.scss'

type Props = {
    isCheckout?: boolean
}

export const AppHeader: FC<Props> = ({ isCheckout = false }) => {
    const { displayName, email } = useAppSelector($getUser)
    const navigate = useNavigate()

    const { isDark } = useTheme()

    const { t } = useTranslation()

    const navigateToDashboard = useCallback(() => {
        navigate(makeRoute(DASHBOARD_ROUTE))
    }, [navigate])

    return (
        <Flex className={styles.Header} justifyContent='space-between' gap={24} alignItems='center'>
            <Flex alignItems='center' gap={24}>
                <Icon className={styles.logo} name='logo' onClick={navigateToDashboard} />
                <Icon className={styles.logoSmall} size={40} name='logo-small' onClick={navigateToDashboard} />
                {!isCheckout && (
                    <Button
                        block
                        type='tetriatry-white'
                        label={
                            <Flex alignItems='center' gap={8}>
                                <Icon name='home' color='var(--color-gray-1000)' size={18} />
                                <Typography type='interface-regular' color='gray-1000'>
                                    {t('profile.myProjects')}
                                </Typography>
                            </Flex>
                        }
                        onClick={navigateToDashboard}
                    ></Button>
                )}
            </Flex>
            <Flex gap={16} alignItems='center'>
                {!isCheckout && (
                    <Button
                        before={
                            <Icon
                                name='contact'
                                size={18}
                                color={isDark ? 'var(--color-gray-1000)' : 'var(--color-white)'}
                            />
                        }
                        data-featurebase-feedback-portal='true'
                        label={t('profile.shareOpinion')}
                        type='gold'
                    />
                )}
                <LanguagesSelector />
                <div className={styles.ButtonWrapper}>
                    <TriggerOverlay optionsRenderer={optionsRenderer(email, navigate, isCheckout)}>
                        <HoverElement classNames={{ root: styles.ProfileIcon }} size={28}>
                            <Icon name='account' color='var(--color-gray-1000)' size={18} />
                        </HoverElement>

                        <Button
                            classNames={{ root: styles.ProfileButton }}
                            type='tetriatry-white'
                            before={<Icon name='account' color='var(--color-gray-1000)' size={18} />}
                            label={displayName || t('profile.userProfile')}
                        />
                    </TriggerOverlay>
                </div>
            </Flex>
        </Flex>
    )
}
