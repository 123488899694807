import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { Icon } from '@fto/icons'
import { Button, Flex, TriggerOverlay, Typography } from '@fto/ui'

import { OrderBy, SortType } from '../../types'

import styles from './index.module.scss'

type Props = {
    sortingPreferences: {
        sortType: SortType
        orderBy: OrderBy
    }
    setSortingPreferences: (preferences: { sortType: SortType; orderBy: OrderBy }) => void
}

const sortTypes = ['alphabetical', 'date']
const orderByTypes = ['asc', 'desc']

const SortButton: FC<Props> = ({ sortingPreferences, setSortingPreferences }) => {
    const { t } = useTranslation()

    return (
        <TriggerOverlay
            optionsRenderer={
                <Flex direction='column' gap={4}>
                    <Typography type='tiny-regular' color='gray-600'>
                        {t('historicalMomentsModal.sortButton.sortBy')}
                    </Typography>
                    {sortTypes.map((label) => (
                        <Button
                            key={label}
                            size='compact'
                            type={label === sortingPreferences.sortType ? 'primary' : 'tetriatry-white'}
                            label={t(`historicalMomentsModal.sortButton.${label}`)}
                            classNames={{ content: styles.optionButton }}
                            onClick={() =>
                                setSortingPreferences({ ...sortingPreferences, sortType: label as SortType })
                            }
                        />
                    ))}
                    <Typography type='tiny-regular' color='gray-600'>
                        {t('historicalMomentsModal.sortButton.sortOrder')}
                    </Typography>
                    {orderByTypes.map((label) => (
                        <Button
                            key={label}
                            size='compact'
                            type={label === sortingPreferences.orderBy ? 'primary' : 'tetriatry-white'}
                            label={t(`historicalMomentsModal.sortButton.${label}`)}
                            classNames={{ content: styles.optionButton }}
                            onClick={() => setSortingPreferences({ ...sortingPreferences, orderBy: label as OrderBy })}
                        />
                    ))}
                </Flex>
            }
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Button
                block
                before={<Icon size={24} name='sorting' />}
                label={t(`historicalMomentsModal.sortButton.${sortingPreferences.sortType}`)}
                size='compact'
                type='tetriatry-white'
                classNames={{
                    content: cx(styles.sortButton, { [styles.rotate]: sortingPreferences.orderBy === 'desc' })
                }}
            />
        </TriggerOverlay>
    )
}

export default SortButton
