import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { Flex, Typography } from '@fto/ui'
import { OptionSettingRef, RadioOption, RadioSettingType } from '../../types/options.types'

import styles from './index.module.scss'
import { getNumberValue, setNumberValue, executeMethod } from '../../utils'
import { t } from 'i18next'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import RadioButton from '@fto/ui/lib/components/RadioButton'

import WarningBlockMessage from '../../WarningBlockMessage'
import { tryStopTesting } from '../../../components/utils/tryStopTesting'

const RadioSetting = forwardRef<OptionSettingRef, RadioSettingType>(
    ({ tab, chart, option, applyToAll, shouldBeDisabled, onDifferentSettingsUpdate }, ref) => {
        const [value, setValue] = useState<number>(() => getNumberValue(chart, option.valueConfigType, option.valueKey))

        const updateValue = useCallback(
            (value: number, redraw = true, shouldUpdateConfig = true, shouldSendNewSettingtoServer = true) => {
                setValue(value)

                if (shouldUpdateConfig) {
                    if (option.valueKeyMethod) {
                        executeMethod(
                            chart,
                            option.valueConfigType,
                            option.valueKeyMethod,
                            value,
                            shouldSendNewSettingtoServer
                        )
                    } else {
                        setNumberValue(
                            chart,
                            option.valueConfigType,
                            option.valueKey,
                            value,
                            applyToAll,
                            shouldSendNewSettingtoServer
                        )
                    }
                }

                if (redraw) {
                    if (applyToAll) {
                        GlobalChartsController.Instance.updateCharts()
                    } else {
                        chart.Repaint()
                    }
                }
            },
            [chart, option, applyToAll]
        )

        const handleChange = useCallback(
            (newValue: number) => {
                option.stopTestingOnChange ?? tryStopTesting()
                onDifferentSettingsUpdate?.(tab, newValue, value, option.valueKey)
                updateValue(newValue)
            },
            [tab, option, value, updateValue, onDifferentSettingsUpdate]
        )

        const loadOptionSetting = useCallback(
            (data: any, shouldUpdateConfig = true) => {
                updateValue(data.value, false, shouldUpdateConfig, false)
            },
            [updateValue]
        )

        useImperativeHandle(ref, () => ({ name: option.key, loadOptionSetting }), [option.key, loadOptionSetting])

        return (
            <Flex direction='column'>
                <Flex direction='column' alignItems='flex-start' gap={16}>
                    {option.options.map((opt: RadioOption) => (
                        <Flex
                            direction='column'
                            className={styles.optionContainer}
                            onClick={() => handleChange(opt.value)}
                            key={opt.value}
                        >
                            <RadioButton
                                checked={value === opt.value}
                                classNames={{ root: styles.radioRoot, content: styles.radioContent }}
                                disabled={shouldBeDisabled}
                                variant='column'
                                block
                            >
                                {t(opt.titleKey)}
                            </RadioButton>

                            {opt.descriptionKey && (
                                <Typography
                                    className={styles.description}
                                    type='subtext-regular'
                                    color={shouldBeDisabled ? 'gray-600' : 'gray-800'}
                                    block
                                >
                                    {t(opt.descriptionKey)}
                                </Typography>
                            )}
                        </Flex>
                    ))}
                </Flex>
                <WarningBlockMessage text={t(option.warningMessage)} observableValue={value} />
            </Flex>
        )
    }
)

export default RadioSetting
