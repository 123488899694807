export const SYMBOL_ICONS = {
    xagusd: 'symbol-xagusd',
    xauusd: 'symbol-xauusd',
    xpdusd: 'symbol-xpdusd',
    xptusd: 'symbol-xptusd',
    btcusd: 'symbol-btcusd',
    dji: 'symbol-dji',
    ng: 'symbol-ng',
    '100': 'symbol-100',
    nas100: 'symbol-nas',
    ethusd: 'symbol-eth',
    gas: 'symbol-gas',
    light: 'symbol-light',
    germany30: 'country-germany',
    hongkong40: 'country-hong-kong',
    uk100: 'country-united-kingdom',
    silver: 'symbol-silver',
    aapl: 'symbol-aapl',
    amzn: 'symbol-amzn',
    msft: 'symbol-msft',
    googl: 'symbol-googl',
    fb: 'symbol-fb',
    tsla: 'symbol-tsla',
    brkb: 'symbol-brkb',
    nflx: 'symbol-nflx',
    jnj: 'symbol-jnj',
    jpm: 'symbol-jpm',
    v: 'symbol-visa',
    xom: 'symbol-xom',
    pg: 'symbol-pg',
    dis: 'symbol-dis',
    bac: 'symbol-bac',
    vz: 'symbol-vz',
    ko: 'symbol-ko',
    pfe: 'symbol-pfe',
    csco: 'symbol-csco',
    mcd: 'symbol-mcd',
    intc: 'symbol-intc',
    baba: 'symbol-baba',
    usdollar: 'dollar',
    us30: 'symbol-30',
    spx500: 'symbol-500',
    usa500: 'symbol-500',
    japan225: 'symbol-225'
}
