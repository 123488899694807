import { TColor } from '@fto/lib/extension_modules/common/CommonTypes'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'

export enum ColorTheme {
    Light = 'light',
    Dark = 'dark'
}

export class TControlsColorScheme {
    public buttonBackground!: TColor
    public buttonHoveredBackground!: TColor // deprecated?
    public buttonText!: TColor
    public buttonBorder!: TColor
    public buttonHoveredBorder!: TColor
    public infoBackground!: TColor
    public infoHoveredBackground!: TColor // deprecated?
    public infoText!: TColor
    public infoTextHidden!: TColor
    public infoTextBackground!: TColor
    public infoValueText!: TColor
    public infoBorder!: TColor
    public infoBorderSelected!: TColor
    public oneClickTradingMiddleBackground!: TColor
    public oneClickTradingMiddleText!: TColor
    public tooltipBackground!: TColor
    public tooltipText!: TColor

    public chunkLoaderBackground!: TColor
    public chunkLoaderText!: TColor

    public ordersBackground!: TColor

    public blockingLayerElipse!: TColor
    public blockingLayerAxisBorder!: TColor
    public blockingLayerErrorBackground!: TColor
    public blockingLayerText!: TColor
    public blockingLayerErrorButtonBackground!: TColor

    public highlightToolsLines!: TColor

    constructor(params: {
        buttonBackground: TColor
        buttonHoveredBackground: TColor
        buttonText: TColor
        buttonBorder: TColor
        buttonHoveredBorder: TColor
        infoBackground: TColor
        infoHoveredBackground: TColor
        infoText: TColor
        infoTextHidden: TColor
        infoTextBackground: TColor
        infoValueText: TColor
        infoBorder: TColor
        infoBorderSelected: TColor
        oneClickTradingMiddleBackground: TColor
        oneClickTradingMiddleText: TColor
        tooltipBackground: TColor
        tooltipText: TColor
        chunkLoaderBackground: TColor
        chunkLoaderText: TColor
        ordersBackground: TColor
        blockingLayerElipse: TColor
        blockingLayerAxisBorder: TColor
        blockingLayerErrorBackground: TColor
        blockingLayerText: TColor
        blockingLayerErrorButtonBackground: TColor
        highlightToolsLines: TColor
    }) {
        this.buttonBackground = params.buttonBackground
        this.buttonHoveredBackground = params.buttonHoveredBackground
        this.buttonText = params.buttonText
        this.buttonBorder = params.buttonBorder
        this.buttonHoveredBorder = params.buttonHoveredBorder
        this.infoBackground = params.infoBackground
        this.infoHoveredBackground = params.buttonHoveredBackground
        this.infoText = params.buttonText
        this.infoTextHidden = params.infoTextHidden
        this.infoTextBackground = params.infoTextBackground
        this.infoValueText = params.infoValueText
        this.infoBorder = params.buttonBorder
        this.infoBorderSelected = params.infoBorderSelected
        this.oneClickTradingMiddleBackground = params.oneClickTradingMiddleBackground
        this.oneClickTradingMiddleText = params.oneClickTradingMiddleText
        this.tooltipBackground = params.tooltipBackground
        this.tooltipText = params.tooltipText
        this.chunkLoaderBackground = params.chunkLoaderBackground
        this.chunkLoaderText = params.chunkLoaderText
        this.ordersBackground = params.ordersBackground
        this.blockingLayerElipse = params.blockingLayerElipse
        this.blockingLayerAxisBorder = params.blockingLayerAxisBorder
        this.blockingLayerErrorBackground = params.blockingLayerErrorBackground
        this.blockingLayerText = params.blockingLayerText
        this.blockingLayerErrorButtonBackground = params.blockingLayerErrorButtonBackground
        this.highlightToolsLines = params.highlightToolsLines
    }

    public static getDefault(): TControlsColorScheme {
        const scheme =
            GlobalChartsController.Instance.currentColorTheme === ColorTheme.Light ? LightColorScheme : DarkColorScheme
        return new TControlsColorScheme(scheme)
    }

    public reset() {
        const defaultScheme = TControlsColorScheme.getDefault()
        this.copyFrom(defaultScheme)
    }

    public copyFrom(other: TControlsColorScheme) {
        this.buttonBackground = other.buttonBackground
        this.buttonHoveredBackground = other.buttonHoveredBackground
        this.buttonText = other.buttonText
        this.buttonBorder = other.buttonBorder
        this.buttonHoveredBorder = other.buttonHoveredBorder
        this.infoBackground = other.infoBackground
        this.infoHoveredBackground = other.infoHoveredBackground
        this.infoText = other.infoText
        this.infoTextHidden = other.infoTextHidden
        this.infoTextBackground = other.infoTextBackground
        this.infoValueText = other.infoValueText
        this.infoBorder = other.infoBorder
        this.infoBorderSelected = other.infoBorderSelected
        this.oneClickTradingMiddleBackground = other.oneClickTradingMiddleBackground
        this.oneClickTradingMiddleText = other.oneClickTradingMiddleText
        this.tooltipBackground = other.tooltipBackground
        this.tooltipText = other.tooltipText
        this.chunkLoaderBackground = other.chunkLoaderBackground
        this.chunkLoaderText = other.chunkLoaderText
        this.ordersBackground = other.ordersBackground
        this.blockingLayerElipse = other.blockingLayerElipse
        this.blockingLayerAxisBorder = other.blockingLayerAxisBorder
        this.blockingLayerErrorBackground = other.blockingLayerErrorBackground
        this.blockingLayerText = other.blockingLayerText
        this.blockingLayerErrorButtonBackground = other.blockingLayerErrorButtonBackground
        this.highlightToolsLines = other.highlightToolsLines
    }

    public Clone(): TControlsColorScheme {
        const cloneObj = TControlsColorScheme.getDefault()
        cloneObj.copyFrom(this)
        return cloneObj
    }

    public processDefaultValuesByVersion(version: number | undefined): void {
        if (version === undefined) {
            // TODO in next versions we can add some default values for new fields
        }
    }

    public SaveToStr(): string {
        return JSON.stringify(this)
    }

    public LoadFromStr(s: string): void {
        try {
            const obj = JSON.parse(s)

            this.buttonBackground = obj.buttonBackground
            this.buttonHoveredBackground = obj.buttonHoveredBackground
            this.buttonText = obj.buttonText
            this.buttonBorder = obj.buttonBorder
            this.buttonHoveredBorder = obj.buttonHoveredBorder
            this.infoBackground = obj.infoBackground
            this.infoHoveredBackground = obj.infoHoveredBackground
            this.infoText = obj.infoText
            this.infoTextHidden = obj.infoTextHidden
            this.infoTextBackground = obj.infoTextBackground
            this.infoValueText = obj.infoValueText
            this.infoBorder = obj.infoBorder
            this.infoBorderSelected = obj.infoBorderSelected
            this.oneClickTradingMiddleBackground = obj.oneClickTradingMiddleBackground
            this.oneClickTradingMiddleText = obj.oneClickTradingMiddleText
            this.tooltipBackground = obj.tooltipBackground
            this.tooltipText = obj.tooltipText
            this.chunkLoaderBackground = obj.chunkLoaderBackground
            this.chunkLoaderText = obj.chunkLoaderText
            this.ordersBackground = obj.ordersBackground
            this.blockingLayerElipse = obj.blockingLayerElipse
            this.blockingLayerAxisBorder = obj.blockingLayerAxisBorder
            this.blockingLayerErrorBackground = obj.blockingLayerErrorBackground
            this.blockingLayerText = obj.blockingLayerText
            this.blockingLayerErrorButtonBackground = obj.blockingLayerErrorButtonBackground
            this.highlightToolsLines = obj.highlightToolsLines

            this.processDefaultValuesByVersion(obj.Version)
        } catch {
            // do nothing, for old projects if there is no color scheme yet
        }
    }
}

export const LightColorScheme = new TControlsColorScheme({
    buttonBackground: '#FFFFFF',
    buttonHoveredBackground: '#FFFFFF',
    buttonText: '#101828',
    buttonBorder: '#D0D5DD',
    buttonHoveredBorder: '#98A2B3',
    infoBackground: '#FFFFFF',
    infoHoveredBackground: '#EAECF0',
    infoText: '#101828',
    infoTextHidden: '#667085',
    infoTextBackground: '#FFFFFF',
    infoValueText: '#2F80ED',
    infoBorder: '#D0D5DD',
    infoBorderSelected: '#2F80ED',
    oneClickTradingMiddleBackground: '#FFFFFF',
    oneClickTradingMiddleText: '#101828',
    tooltipBackground: '#101828',
    tooltipText: '#FFFFFF',
    chunkLoaderBackground: '#DCEBFF',
    chunkLoaderText: '#101828',
    ordersBackground: '#FFFFFF',
    blockingLayerElipse: '#F2F4F7',
    blockingLayerAxisBorder: '#D0D5DD',
    blockingLayerErrorBackground: '#FFFFFF',
    blockingLayerText: '#101828',
    blockingLayerErrorButtonBackground: '#FFFFFF',
    highlightToolsLines: '#f1f1f195'
})

export const DarkColorScheme = new TControlsColorScheme({
    buttonBackground: '#1D2736',
    buttonHoveredBackground: '#1D2736',
    buttonText: '#FFFFFF',
    buttonBorder: '#475467',
    buttonHoveredBorder: '#98A2B3',
    infoBackground: '#101828',
    infoHoveredBackground: '#1D2736',
    infoText: '#FFFFFF',
    infoTextHidden: '#667085',
    infoTextBackground: '#1D2736',
    infoValueText: '#2F80ED',
    infoBorder: '#475467',
    infoBorderSelected: '#2F80ED',
    oneClickTradingMiddleBackground: '#101828',
    oneClickTradingMiddleText: '#FFFFFF',
    tooltipBackground: '#667085',
    tooltipText: '#FFFFFF',
    chunkLoaderBackground: '#1D2736',
    chunkLoaderText: '#D0D5DD',
    ordersBackground: '#FFFFFF',
    blockingLayerElipse: '#1D2736',
    blockingLayerAxisBorder: '#475467',
    blockingLayerErrorBackground: '#1D2736',
    blockingLayerText: '#FFFFFF',
    blockingLayerErrorButtonBackground: '#101828',
    highlightToolsLines: '#98a2b380'
})

export const ColorSchemes: TControlsColorScheme[] = [LightColorScheme, DarkColorScheme]
