import { INamed } from '@fto/lib/utils/INamed'
import { TDateTime } from '../../delphi_compatibility/DateUtils'
import { TTickRec } from './TickRec'

export abstract class TTicksSource implements INamed {
    fQueuedTick: TTickRec
    StartDateTime!: TDateTime

    public DName: string

    abstract GetQueuedTick(): TTickRec
    // abstract ExtractNewTick(): TTickRec
    abstract Seek(DateTime: TDateTime): void
    abstract RollToDate(DateTime: TDateTime): void

    constructor() {
        this.fQueuedTick = new TTickRec()
        this.DName = `TTicksSource ID=${Math.random().toString()}`
    }

    public toString(): string {
        return this.DName
    }

    ClearQueuedTick(): void {
        this.fQueuedTick = new TTickRec()
    }
}
